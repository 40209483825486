import React, { useState } from "react";
import { userVar } from "../../cache";
import ManagerDashboard from "./ManagerDashboard";
import EmployeeDashboard from "./EmployeeDashboard";

const Dashboard = (props) => {
  const user = userVar();
  if (user.isEmployee) {
    return <EmployeeDashboard />;
  } else {
    return <ManagerDashboard />;
  }
};

export default Dashboard;
