import React, { useEffect, useState } from "react";
import {
  Grid,
  Switch,
  FormControlLabel,
  Typography,
  Divider,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import {
  LOCATION,
  UPDATE_LOCATION,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import { locationConfigVar } from "../../cache";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  divider: {
    border: "0.5px solid thin #333333",
    verticalAlign: "bottom",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const ApplicationConfig = () => {
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const locationConfig = locationConfigVar();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const locationSettings = useQuery(LOCATION, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_LOCATION Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [analytics, SetAnalytics] = useState(locationConfig.analyticsTab);
  const [integrations, SetIntegrations] = useState(
    locationConfig.integrationsTab
  );
  // const [shiftBasedScheduling, SetShiftBasedScheduling] = useState(locationConfig.shiftBasedScheduling);
  const [shiftBasedDisplay, SetShiftBasedDisplay] = useState(
    locationConfig.shiftBasedDisplay
  );

  const [updateLocationSettings] = useMutation(UPDATE_LOCATION, {
    onCompleted(data) {
      locationSettings.refetch();
      console.log(data);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_LOCATION Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (!locationSettings.loading) {
      updateLocationSettings({
        variables: {
          id: parseInt(locationSettings.data.locations[0].id),
          input: {
            analyticsTab: analytics,
            integrationsTab: integrations,
            shiftBasedScheduling: shiftBasedDisplay,
            shiftBasedDisplay: shiftBasedDisplay,
          },
        },
      });
    }
  }, [analytics, integrations, shiftBasedDisplay]);

  if (locationSettings.loading) {
    return <CircularProgress color="primary" />;
  } else {
    console.log(analytics);
    return (
      <Grid
        container
        direction="row"
        spacing={4}
        className={classes.headerSpacing}
        data-testid="loctionGrid"
      >
        <ErrorSnackBar
          open={snackBarOpen}
          message={errorSnackBarMessage}
          close={() => setSnackBarOpen(false)}
        />
        <Grid item xs={12}>
          <Typography>
            Enable/Disable features for all departments of
            <b>{" " + locationSettings.data.locations[0].name}</b>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Analytics</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={analytics}
                onChange={() => {
                  SetAnalytics(!analytics);
                }}
                name="analytics"
                color="primary"
              />
            }
            label="Analytics"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>Integrations</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={integrations}
                onChange={() => {
                  SetIntegrations(!integrations);
                }}
                name="integrations"
                color="primary"
              />
            }
            label="Integrations"
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12} align="right">
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={3}>
          <Typography>Shift Based Scheduling</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={shiftBasedDisplay}
                onChange={() => {
                  SetShiftBasedDisplay(!shiftBasedDisplay);
                }}
                name="shiftBasedScheduling"
                color="primary"
              />
            }
            label="Shift Scheduling"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>Display Shifts</Typography>
          <FormControlLabel
            control={
              <Switch
                data-testid="shiftBasedDisplay"
                checked={shiftBasedDisplay}
                onChange={() => {
                  SetShiftBasedDisplay(shiftBasedDisplay);
                }}
                name="shiftBasedDisplay"
                color="primary"
              />
            }
            label="Shift Display"
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>Display Mission</Typography>
          <FormControlLabel
            control={
              <Switch
                data-testid="missionBasedDisplay"
                checked={!shiftBasedDisplay}
                onChange={() => {
                  SetShiftBasedDisplay(!shiftBasedDisplay);
                }}
                name="missionDisplay"
                color="primary"
              />
            }
            label="Mission Display"
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    );
  }
};

export default ApplicationConfig;
