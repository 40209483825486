import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  withStyles,
  Button,
  Box,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import { Formik, Form, FieldArray } from "formik";
import {
  PATIENT_LEVELS,
  MOST_RECENT_CENSUS,
  RECORD_CENSUS_DATA,
} from "../../api/gqlQueries";
import { useMutation, useQuery } from "@apollo/client";
import { userVar } from "../../cache";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    // textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    // textAlign: "center",
  },
}))(TableCell);

/*form to add the number of patients currently needing active RN service. 
Each patient is assigned a patient level and the number of RN's for each patient level is recorded for each day.
*/

const CensusDataForm = (props) => {
  const {
    closeDialog, 
    selectedDate,   
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
 } = props;

  const user = userVar();

  const patientLevels = useQuery(PATIENT_LEVELS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on PATIENT_LEVELS Query. Environment: " + environment + ". Graphql " + error}});
    },

  });

  const mostRecentCensus = useQuery(MOST_RECENT_CENSUS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on MOST_RECENT_CENSUS Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [recordCensusData] = useMutation(RECORD_CENSUS_DATA, {
    onCompleted(data) {
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on RECORD_CENSUS_DATA Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  if (patientLevels.loading || mostRecentCensus.loading) {
    return <CircularProgress color="primary" />;
  } else {
    let census = patientLevels.data.patientLevels.map((e) => {
      let censusData = {
        level: e.level,
        levelId: e.id,
        value: "",
        dateTimeStamp: selectedDate,
        AllowInput: true,
      };
      return censusData;
    });
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">
              Patient census data for {format(new Date(), "MM-dd-yyyy")}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            census: census,
            timeStamp: new Date(),
          }}
          onSubmit={(values) => {
            const inputData = values.census.map((e) => {
              return {
                office: parseInt(user.office.id),
                level: parseInt(e.level),
                count: e.value === "" ? 0 : parseInt(e.value),
              };
            });
            recordCensusData({
              variables: {
                input: inputData,
              },
            });
          }}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Form>
              <Grid container>
                <Grid item>
                  <Typography variant="h5">Time Stamp</Typography>
                  <TextField
                    variant="outlined"
                    id="TimeStamp"
                    name="TimeStamp"
                    value={values.timeStamp}
                    disabled
                  />
                </Grid>
                <Grid container item spacing={2} style={{ marginTop: 16 }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 16,
                      marginBottom: 16,
                      height: 280,
                      overflowY: "auto",
                    }}
                  >
                    <FieldArray name="census">
                      {({ push, remove }) => (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Patient Type</StyledTableCell>
                              <StyledTableCell>No. of Patients</StyledTableCell>
                              <StyledTableCell>N/A</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.census.map((e, index) => (
                              <TableRow key={index}>
                                <TableCell style={{ display: "none" }}>
                                  {e.levelId}
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    key={index}
                                    id={`census.${index}.level`}
                                    name={`census.${index}.level`}
                                    value={e.level}
                                  >
                                    {"level " + e.level}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    variant="outlined"
                                    id={`census.${index}.value`}
                                    name={`census.${index}.value`}
                                    value={!e.AllowInput ? 0 : e.value}
                                    disabled={!e.AllowInput ? true : false}
                                    onChange={handleChange}
                                    style={{ width: 50 }}
                                    type="number"
                                  />
                                </TableCell>
                                <TableCell>
                                  <Switch
                                    checked={e.AllowInput}
                                    onChange={(event) =>
                                      setFieldValue(
                                        `census.${index}.AllowInput`,
                                        event.target.checked
                                      )
                                    }
                                    name="N/A"
                                    label="N/A"
                                    color="primary"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            {/* <TableRow>
                              <StyledTableCell>
                                <Button
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  onClick={() => push({})}
                                >
                                  Add Another
                                </Button>
                              </StyledTableCell>
                            </TableRow> */}
                          </TableBody>
                        </Table>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    //disabled={procedureRequirement ? true : false}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    );
  }
};

export default CensusDataForm;
