import React, { useEffect, useState } from "react";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import {
  filterListVar,
  isLoggedInVar,
  userVar,
  initialScheduleStartDateVar,
  // initialScheduleStartTimeVar,
  newScheduleStartDateVar,
  selectedDateVar,
  appsettingsVar,
} from "../../cache";

import {
  useQuery,
  useReactiveVar,
  useMutation,
  useApolloClient,
} from "@apollo/client";
import { PUBLICLOCATION, VERIFY_TOKEN } from "../../api/gqlQueries";
import defaultTheme from "../../themes/default-theme.json";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import LogRocket from "logrocket";

function App() {
  const environment = process.env.NODE_ENV;
  const baseURL = window.location.origin;
  const history = useHistory();

  let appId;
  if (environment === "development") {
    appId = null;
  } else if (baseURL.includes("amplify")) {
    appId = "ix1pxb/test-dev-scheduler-ui";
  } else {
    appId = "ix1pxb/demo-scheduler-ui";
  }
  console.log(appId);

  appId && LogRocket.init(appId);

  const client = useApolloClient();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const publicLocationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.log(error);
    },
  });
  const appvar = useReactiveVar(appsettingsVar);
  appvar.color =
    !publicLocationSettings.loading &&
    publicLocationSettings.data != null &&
    publicLocationSettings.data.locationPublicSettings[0].hexcolor;
  const [clr, setclr] = useState(appvar.color);

  const myTheme = {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: { main: clr ? clr : defaultTheme.palette.primary.main },
    },
  };
  const theme = createTheme(myTheme);

  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      isLoggedInVar(false);
      sessionStorage.removeItem("jwt");
      sessionStorage.clear("jwt");
      client.cache.reset();
    },
  });
  useEffect(() => {
    if (sessionStorage.getItem("jwt")) {
      verifyToken({
        variables: {
          token: sessionStorage.getItem("jwt"),
        },
      });
    } else {
      let url = document.URL;
      console.log(url);
      handleLogout();
    }
  }, []);

  useEffect(() => {
    setclr(appvar.color);
  }, [appvar.color]);

  function handleLogout() {
    isLoggedInVar(false);
    userVar({});
    filterListVar({});
    selectedDateVar(new Date());
    initialScheduleStartDateVar(null);
    // initialScheduleStartTimeVar(null);
    newScheduleStartDateVar(false);
    client.cache.reset();
  }

  return (
    <BrowserRouter>
      <Route>
        <MuiThemeProvider theme={theme}>
          <Switch>
            {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
          </Switch>
        </MuiThemeProvider>
      </Route>
    </BrowserRouter>
  );
}

export default App;
