import React, { useEffect, useState } from "react";
import ParticipantDelete from "./ParticipantDelete";
import EventParticipant from "./EventParticipant";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarRateIcon from "@material-ui/icons/StarRate";
import {
  GET_ALL_USERS,
  ADD_AND_DROP_OPTIONS_FOR_SHIFT,
  GET_FLOAT_DETAILS,
  BATCH_CREATE_RESCHEDULE_OPTION,
  REQUEST_SINGLE_FLOAT,
  EXECUTE_OPTION,
  GET_EMPLOYEES_OF_CHILD_OFFICES,
} from "../../api/gqlQueries";
import { format, add, sub } from "date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { userVar } from "../../cache";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
  commentBox: {
    width: "80%",
  },
}));

/*remove an employee from a shift
add an employee to a shift
*/

const MangEditShift = (props) => {
  const classes = useStyles();

  const {
    shiftEvent,
    closeDialog,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    getVariables,
    selectedDate,
  } = props;

  const user = userVar();
  const officeId = user.office.id;

  // Get event ID of the event that needs to be edited
  const currentShiftId = shiftEvent.eventId;
  // list of current employees assigned to this shift
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState(
    shiftEvent.participants || shiftEvent.originalParticipants
  );
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [addOptions, setAddOptions] = useState([]);
  const [dropOptions, setDropOptions] = useState([]);
  const [acceptsFloats, setAcceptsFloats] = useState(false);
  const [showRequestFloats, setShowRequestFloats] = useState(false);
  const [floatComment, setFloatComment] = useState("");

  //get all employees of this office
  const { loading, error, data } = useQuery(GET_ALL_USERS, {
    variables: {
      id: parseInt(officeId),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const { loading: childOfficeEmployeesLoading, data: getChildOfficeData } =
    useQuery(GET_EMPLOYEES_OF_CHILD_OFFICES, {
      variables: {
        parent: parseInt(user.office.id),
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_EMPLOYEE_NAMES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  // show/hide float request form
  const toggleShowRequestFloats = () =>
    setShowRequestFloats(!showRequestFloats);

  // query for getting add employee and drop employee options for shift
  const [getRescheduleOptions] = useLazyQuery(ADD_AND_DROP_OPTIONS_FOR_SHIFT, {
    onCompleted(data) {
      const addOptions =
        data.rescheduleAdds.length > 0
          ? data.rescheduleAdds
              .filter((option) => option.status === "OPTION")
              .map((add) => {
                const employeeIdsAndBenefits = add.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: add.id,
                    };
                  }
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];

      const dropOptions =
        data.rescheduleDrops.length > 0
          ? data.rescheduleDrops
              .filter((option) => option.status === "OPTION")
              .map((drop) => {
                const employeeIdsAndBenefits = drop.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: drop.id,
                    };
                  }
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];
      setAddOptions(addOptions);
      setDropOptions(dropOptions);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_AND_DROP_OPTIONS_FOR_SHIFT Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // get reschedule add and drop option for current shift ID
  useEffect(() => {
    getRescheduleOptions({
      variables: {
        shifts: [parseInt(currentShiftId)],
      },
    });
  }, []);

  // get office float settings
  const { data: officeData } = useQuery(GET_FLOAT_DETAILS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("Error Edit Shift. Graphql " + error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_FLOAT_DETAILS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (officeData) {
      const currentOffice = officeData.offices.find(
        (office) => office.id === officeId
      );
      setAcceptsFloats(currentOffice.floatStatus === false);
    }
  }, [officeData]);

  const [batchCreateOptions, { data: batchCreateOptionsData }] = useMutation(
    BATCH_CREATE_RESCHEDULE_OPTION,
    {
      onCompleted(d) {
        refetch();
      },
    },
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("Error Edit Shift." + error);
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [executeOption, { data: executeOptionData }] = useMutation(
    EXECUTE_OPTION,
    {
      onCompleted(d) {
        refetch();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("Error Edit Shift. Graphql " + error);
        notifyDevelopers({
          variables: {
            message:
              "Error on EXECUTE_OPTION Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [requestFloat] = useMutation(REQUEST_SINGLE_FLOAT, {
    onCompleted(data) {
      setToast("Float Request");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("Error Edit Shift. Graphql " + error);
      notifyDevelopers({
        variables: {
          message:
            "Error on REQUEST_SINGLE_FLOAT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const renderParticipants = (newAddition) => {
    let participants;
    if (newAddition) {
      participants = employeesToAdd;
    } else {
      participants = shiftEvent.participants || shiftEvent.originalParticipants;
    }
    return participants.map((participant) => {
      const option = newAddition
        ? addOptions.find((option) => option.employeeId === participant.id)
        : dropOptions.find((option) => option.employeeId === participant.id);
      return (
        <Grid
          item
          container
          key={participant.id}
          xs={5}
          alignItems="center"
          spacing={1}
          style={{ margin: 5, marginRight: 25 }}
        >
          <Grid item xs={2}>
            {option && (
              <>
                {option.benefit <= -1 && (
                  <StarRateIcon className={classes.icon} />
                )}
                {option.benefit < 1 && option.benefit > -1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
                {option.benefit >= 1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
              </>
            )}
          </Grid>
          {newAddition ? (
            <ParticipantDelete
              participant={participant}
              newAddition={true}
              employeesToAdd={employeesToAdd}
              setEmployeesToAdd={setEmployeesToAdd}
              currentShiftEmployees={currentShiftEmployees}
              setCurrentShiftEmployees={setCurrentShiftEmployees}
              officeId={officeId}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          ) : (
            <ParticipantDelete
              participant={participant}
              employeesToDelete={employeesToDelete}
              setEmployeesToDelete={setEmployeesToDelete}
              officeId={officeId}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )}
        </Grid>
      );
    });
  };

  const [showLoader, SetShowLoader] = useState(false);

  const renderEmployeeOptions = (employees) => {
    if (showLoader) {
      return <CircularProgress color="primary" />;
    } else {
      if (employees && employees.length > 0) {
        let adds = [];
        employees.forEach((employee) => {
          const add = addOptions.find(
            (option) => option.employeeId === employee.id
          );
          if (add) {
            adds = [...adds, { ...add, ...employee }];
          }
          // else {
          //   if (!others.find((e) => e.id === employee.id)) {
          //     others = [...others, { ...employee }];
          //   } else {
          //     others = [...others];
          //   }
          // }
        });

        adds.sort((a, b) => b.benefit - a.benefit);

        adds = adds.map((employee, index) => {
          return (
            <MenuItem key={index} value={employee.id}>
              <EventParticipant
                participant={employee}
                showAvatar={false}
                showJobTitle={true}
                singleLine={true}
                addOption={true}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
              {employee.benefit <= -1 && (
                <StarRateIcon className={classes.icon} />
              )}
              {employee.benefit < 1 && employee.benefit > -1 && (
                <>
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                </>
              )}
              {employee.benefit >= 1 && (
                <>
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                </>
              )}
            </MenuItem>
          );
        });

        let uniqueEmployeeIds = [];

        let others = employees
          .filter(
            (e) =>
              !adds.find((x) => x.id === e.id) &&
              !uniqueEmployeeIds.includes(e.id)
          )
          .map((employee, index) => {
            uniqueEmployeeIds.push(employee.id);
            return (
              <MenuItem key={index} value={employee.id}>
                <EventParticipant
                  participant={employee}
                  showAvatar={false}
                  showJobTitle={false}
                  singleLine={true}
                  addOption={false}
                  setOpenSnackBar={setOpenSnackBar}
                  setSnackBarMessage={setSnackBarMessage}
                  notifyDevelopers={notifyDevelopers}
                  environment={environment}
                />
              </MenuItem>
            );
          });
        return [...adds, ...others];
      } else {
        return (
          <MenuItem value={-1}>
            <Typography>No Eligible Employees</Typography>
          </MenuItem>
        );
      }
    }
  };

  const handleAddEmployee = (e) => {
    setSelectedEmployeeToAdd(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedEmployeeToAdd) {
      setErrorMessage("Click Add button to save selected employee to shift");
      return;
    } else {
      let optionsToExecute = [];

      if (employeesToAdd.length > 0) {
        let manualAdds = [];

        employeesToAdd.forEach((employee) => {
          //find if employee selected was an algorithm-generated option
          const existingOption = addOptions.find(
            (option) => option.employeeId === employee.id
          );
          if (existingOption) {
            optionsToExecute.push(existingOption.optionId);
          } else {
            manualAdds.push({
              optionType: "ADD",
              status: "APPROVED",
              individuals: [
                {
                  employee: parseInt(employee.id),
                  cost: 0,
                  benefit: 0,
                  actions: [
                    {
                      actionType: "ADD",
                      shift: parseInt(currentShiftId),
                    },
                  ],
                },
              ],
              userGenerated: true,
            });
          }
        });
        // create a reschedule option with action type ADD to add employees to any shift
        if (manualAdds.length > 0) {
          batchCreateOptions({
            variables: { options: manualAdds },
          });
        }
      }

      if (employeesToDelete.length > 0) {
        let manualDrops = [];
        employeesToDelete.forEach((employee) => {
          //find if employee selected was an algorithm-generated option
          const existingOption = dropOptions.find(
            (option) => option.employeeId === employee.employeeId
          );
          if (existingOption) {
            optionsToExecute.push(existingOption.optionId);
          } else {
            manualDrops.push({
              optionType: "DROP",
              status: "APPROVED",
              individuals: [
                {
                  employee: parseInt(employee.employeeId),
                  cost: 0,
                  benefit: 0,
                  actions: [
                    {
                      actionType: "DROP",
                      shift: parseInt(currentShiftId),
                    },
                  ],
                },
              ],
              userGenerated: true,
            });
          }
        });
        //remove employees from a shift through DROP option
        if (manualDrops.length > 0) {
          console.log(manualDrops);
          batchCreateOptions({
            variables: { options: manualDrops },
          });
        }
      }

      //execute options that were selected
      if (optionsToExecute.length > 0) {
        optionsToExecute.forEach((optionId) => {
          executeOption({
            variables: {
              option: parseInt(optionId),
            },
          });
        });
      }
      setToast("Manager Edit Shift");
      setShowToast(true);
      closeDialog();
    }
  };

  // mutation to request for float employees to fill a shift
  const handleSendFloatRequest = () => {
    requestFloat({
      variables: {
        shift: currentShiftId,
        comments: floatComment,
      },
    });
  };

  // submit button is enabled only if there are employees to add or employees to be deleted from a shift
  const eligibleToSave = Boolean(
    employeesToAdd.length > 0 || employeesToDelete.length > 0
  );

  if (loading || childOfficeEmployeesLoading) {
    return <CircularProgress />;
  } else {
    let eligibleEmployees;
    let allUsers = user.isPrimaryParentOffice
      ? getChildOfficeData.getChildren.map((e) => e.employeeSet).flat()
      : data.offices[0].employeeSet;
    const allUsersIds = allUsers.map((user) => user.id);
    const currentShiftEmployeeIds = currentShiftEmployees.map(
      (user) => user.id
    );

    //get employees that are not currently in this shift
    const eligibleEmployeeIds = allUsersIds.filter(
      (id) => !currentShiftEmployeeIds.includes(id)
    );
    eligibleEmployees = allUsers.filter((user) =>
      eligibleEmployeeIds.includes(user.id)
    );

    const temporaryAdd = () => {
      if (selectedEmployeeToAdd) {
        const employeeToAdd = allUsers.find(
          (user) => user.id === selectedEmployeeToAdd
        );
        setEmployeesToAdd([...employeesToAdd, employeeToAdd]);
        setCurrentShiftEmployees([...currentShiftEmployees, employeeToAdd]);
        setSelectedEmployeeToAdd("");
        setErrorMessage("");
      } else {
        setErrorMessage("Must select employee to add");
      }
    };

    return (
      <>
        {showRequestFloats ? (
          <Grid item container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignContent="flex-start"
            >
              <Grid item>
                <Typography variant="h2">
                  Request Floats for {shiftEvent.shiftId}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={closeDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {format(new Date(shiftEvent.start), "dd MMM yyyy")}
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Typography variant="h5" style={{ marginBottom: 8 }}>
                Comment for Float Manager:
              </Typography>
              <TextField
                variant="outlined"
                id="float-comment"
                value={floatComment}
                onChange={(e) => setFloatComment(e.target.value)}
                multiline
                className={classes.commentBox}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item container direction="column" spacing={2}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignContent="flex-start"
            >
              <Grid item xs={10}>
                <Typography variant="h2">Edit {shiftEvent.shiftId}</Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={2}>
                <Grid item>
                  <IconButton color="secondary" onClick={closeDialog}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={4}>
                <Grid item>
                  <Typography variant="h5">
                    {format(new Date(shiftEvent.start), "dd MMM yyyy")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={8}>
                <RescheduleOptionsKey type="addDrop" />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h5">Employees Scheduled:</Typography>
            </Grid>
            <Grid item container justifyContent="space-between" wrap="wrap">
              {renderParticipants(false)}
              {renderParticipants(true)}
            </Grid>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <InputLabel id="select-employee-1-label">
                  <Typography variant="h5">Add Employee:</Typography>
                </InputLabel>
              </Grid>
              <Grid item>
                <Select
                  labelId="select-employee-1-label"
                  id="select-employee-1"
                  variant="outlined"
                  value={selectedEmployeeToAdd}
                  onChange={handleAddEmployee}
                  className={classes.select}
                >
                  {renderEmployeeOptions(eligibleEmployees)}
                </Select>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={temporaryAdd}
                  disabled={!selectedEmployeeToAdd}
                >
                  Add
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={() => setSelectedEmployeeToAdd("")}
                  disabled={!selectedEmployeeToAdd}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
            {errorMessage && (
              <Grid item>
                <Typography className={classes.error}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ marginTop: 15 }}
        >
          {acceptsFloats ? (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleShowRequestFloats}
              >
                {showRequestFloats ? "Cancel" : "Request Float Nurses"}
              </Button>
            </Grid>
          ) : (
            <Grid item></Grid>
          )}
          {showRequestFloats ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendFloatRequest}
              >
                Send Request
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!eligibleToSave}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
};

export default MangEditShift;
