import React, { useState } from 'react';
import { 
    Grid, Typography, Button,
    Paper, makeStyles, Dialog,
    DialogContent, TextField
} from '@material-ui/core';
import { format, isSameDay } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { 
    AVAILABILITY_TYPES,
    MANAGER_APPROVE_EMPLOYEE_AVAILABILITY, 
    MANAGER_DENY_EMPLOYEE_AVAILABILITY
} from '../../api/gqlQueries';
import { userVar } from '../../cache';

const useStyles = makeStyles((theme) => ({
    requestCard: {
        padding: 16,
        marginTop: 10, 
        marginBottom: 10,
        backgroundColor: theme.palette.background.default
    },
    button: {
        width: 75
    },
    input: {
        width: '100%'
    }
}));

const IncomingOffRequestItem = (props) => {
    const classes = useStyles();

    const {
        request,
        setToast,
        setShowToast,
        setErrorToast,
        setShowErrorToast,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment
    } = props;

    const user = userVar();

    // const {data} = useQuery(AVAILABILITY_TYPES);

    const [showDenialReason, setShowDenialReason] = useState(false);
    const [denialReason, setDenialReason] = useState('');
    // const [requestToDeny, setRequestToDeny] = useState();

    const [approveRequest] = useMutation(MANAGER_APPROVE_EMPLOYEE_AVAILABILITY, {
        onCompleted(data) {
            console.log(data);
            setToast('Manager Approve Time Off Request');
            setShowToast(true);
        },
        onError(error){
            console.log(error);
            // setErrorToast('Error Approve Time Off');
            // setShowErrorToast(true);
            setOpenSnackBar(true);
            setSnackBarMessage("Error Approve Time Off. Graphql " + error);
            notifyDevelopers({variables: {message: "Error on MANAGER_APPROVE_EMPLOYEE_AVAILABILITY Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const [denyRequest] = useMutation(MANAGER_DENY_EMPLOYEE_AVAILABILITY, {
        update(cache, { data: { denyTimeOffRequestWithNotifications }}) {
            cache.evict({
                id: `EmployeeAvailabilityNode:${denyTimeOffRequestWithNotifications.timeOffRequest.id}`
            });
        },
        onCompleted(data) {
            console.log(data);
            setToast('Manager Deny Time Off Request');
            setShowToast(true);
        },
        onError(error){
            console.log(error);
            // setErrorToast('Error Deny Time Off');
            // setShowErrorToast(true);
            setOpenSnackBar(true);
            setSnackBarMessage("Error Deny Time Off. Graphql " + error);
            notifyDevelopers({variables: {message: "Error on MANAGER_DENY_EMPLOYEE_AVAILABILITY Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const handleApproveRequest = (id) => {
        //sends notification to employee to say request approved
        //changes event in backend from pending to approved
        approveRequest({variables: {
            id: id, 
            approvingManager: parseInt(user.id)
        }});
    };

    const handleDenyRequest = (id) => {
        //sends notification to employee to say denied
        //deletes event in backend
        denyRequest({variables: {
            id: id,
            denyingManager: parseInt(user.id),
            deniedText: denialReason
        }});
    };

    // const typeName = data?.availabilityTypes.find(type => (
    //     type.id === request.typeId
    // ));
    
    return ( 
        <> 
            <Grid 
                item 
                container 
                justifyContent='space-between' 
                component={Paper} 
                xs={11}
                md={7} 
                className={classes.requestCard}
            >
                <Grid item xs={6}>
                    <Typography variant='h5'>
                        {request.eventTitle}
                    </Typography>
                </Grid>
                <Grid item container justifyContent='flex-end' xs={6} spacing={2}>
                    <Grid item>
                        <Button 
                            variant='outlined' 
                            color='primary' 
                            onClick={() => {
                                setShowDenialReason(true); 
                            }} 
                            className={classes.button}
                        >
                            Deny
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={() => handleApproveRequest(request.eventId)} 
                            className={classes.button}
                        >
                            Approve
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 8}}>
                    <Typography>
                        <i>Request was made on {format(new Date(request.createdAt), 'dd MMM yyyy')}</i>
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop: 8, marginBottom: 8}}>
                    {!isSameDay(request.start, request.end)
                        ? <>
                            <Typography>
                                Request dates: 
                                {format(request.start, ' dd MMM yyyy')} -
                                {format(request.end, ' dd MMM yyyy')}
                            </Typography>
                            <Typography>
                                All Day
                            </Typography>
                        </>
                        : <>
                            <Typography>
                                Request date: {format(request.start, ' dd MMM yyyy')}
                            </Typography>
                            {request.isAllDay
                                ? <Typography>
                                    All Day
                                </Typography>
                                : <Typography>
                                    {format(request.start, 'HH:mm')}{' - '}
                                    {format(request.end, 'HH:mm')}
                                </Typography>
                            }
                        </>
                    }
                    <Typography>Hours used: {request.workHours}</Typography>
                </Grid>
                {request.comment &&
                    <Grid item xs={12} style={{marginTop: 8}}>
                        <Typography>Comment: {request.comment}</Typography>
                    </Grid>
                }
            </Grid>
            <Dialog open={showDenialReason} fullWidth maxWidth='xs'>
                <DialogContent style={{ padding: 30 }}>
                    <Grid container direction='column' spacing={2}>
                        <Grid item >
                            <Typography variant='h2'>Reason for Denial</Typography>
                        </Grid>
                        <Grid item >
                            <TextField
                                variant='outlined'
                                value={denialReason}
                                onChange={e => setDenialReason(e.target.value)}
                                className={classes.input}
                                onKeyPress={e => {
                                    e.key === 'Enter' && 
                                    handleDenyRequest(request.eventId);
                                }}
                            />
                        </Grid>
                        <Grid item container justifyContent='flex-end' spacing={1}>
                            <Grid item>
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    className={classes.button} 
                                    onClick={() => {
                                        handleDenyRequest(request.eventId);
                                        setShowDenialReason(false);
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant='outlined' 
                                    color='primary' 
                                    className={classes.button} 
                                    onClick={() => {
                                        setShowDenialReason(false); 
                                        setDenialReason('');
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
 
export default IncomingOffRequestItem;