import React, { useState } from "react";
import {
  Button,
  Grid,
  Icon,
  Typography,
  Dialog,
  DialogContent,
  Box,
  makeStyles,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import UpdateProfileForm from "./UpdateProfileForm";
import AccountPreferences from "./AccountPreferences";
import { useQuery } from "@apollo/client";
import { GET_PROFILE } from "../../api/gqlQueries";
import { userVar } from "../../cache";

export const handlePhoneFormat = (phoneNumber) => {
  let input = phoneNumber.replace(/\D/g, "");
  if (input.length === 10) {
    input =
      "(" +
      input.slice(0, 3) +
      ") " +
      input.slice(3, 6) +
      "-" +
      input.slice(6, 10);
  } else if (input.length > 10 && input[0] === "1") {
    input =
      "+1 " +
      "(" +
      input.slice(1, 4) +
      ") " +
      input.slice(4, 7) +
      "-" +
      input.slice(7, 11);
  } else {
    input = phoneNumber;
  }

  return input;
};

const useStyles = makeStyles(() => ({
  header: {
    marginTop: 50,
    marginBottom: 30,
  },
  iconText: {
    marginLeft: 10,
  },
  box: {
    marginTop: 20,
    marginBottom: 20,
  },
  boxDetails: {
    marginLeft: 20,
  },
}));

const Profile = () => {
  const classes = useStyles();

  const currentUser = userVar();

  const { loading, error, data, refetch } = useQuery(GET_PROFILE);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const closeDialog = () => setShowUpdateForm(false);

  const mapShiftType = {
    Any: "Any Shift",
    "Days Only": "Day Shift",
    "Nights Only": "Night Shift",
    "Swing Only": "Swing Shift",
    "Weekends Only": "Weekend Shift",
    "Weekdays Only": "Weekday Shift",
    Rotation: "Rotating Shifts",
  };

  if (loading) {
    return <CircularProgress data-testid="profileLoading" color="primary" />;
  } else if (error) {
    console.log(error);
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const user = data.me;

    const primaryOffice = user.assignmentSet.find(
      (assignment) => assignment.primary === true
    )
      ? user.assignmentSet.find((assignment) => assignment.primary === true)
      : user.assignmentSet[0];

    // const jobTitle =
    //   primaryOffice.role.name.slice(0, 1) +
    //   primaryOffice.role.name.slice(1).toLowerCase();
    const secondaryOffices = user.assignmentSet.filter(
      (assignment) => assignment.primary === false
    );
    const employmentType = user.employeeType.name;
    const shiftType = currentUser.shiftType;
    // const shiftType = mapShiftType[primaryOffice.shiftType.name]
    //   ? mapShiftType[primaryOffice.shiftType.name]
    //   : "Any Shift";
    const jobType = user.skills
      .filter((skill) => skill.variety == "JOB_TYPE")
      .map((skill) => skill.name)
      .join(", ");
    const license = user.skills
      .filter((skill) => skill.variety === "LICENSE")
      .map((skill) => skill.name);
    const education = user.skills
      .filter((skill) => skill.variety === "EDUCATION")
      .map((skill) => skill.name);
    const licenseAndEducation = license.concat(education).join(", ");
    const trainings = user.skills
      .filter((skill) => skill.variety === "TRAINING")
      .map((skill) => skill.name)
      .join(", ");
    const certifications = user.skills
      .filter((skill) => skill.variety === "CERTIFICATION")
      .map((skill) => skill.name)
      .join(", ");

    const isAdmin = currentUser.isAdmin;

    return (
      <>
        <Typography
          id="userName"
          variant="h2"
          data-testid="username"
          className={classes.header}
        >
          {user.firstName} {user.lastName}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ paddingRight: 30 }}
          id="contactInfo"
          data-testid="contactInfo"
        >
          <Grid
            item
            xs={12}
            md={6}
            container
            direction="column"
            wrap="nowrap"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            style={{ marginBottom: 20 }}
            id="emailPhone"
            data-testid="emailPhone"
          >
            {user.email && (
              <Grid id="workemail" container item>
                <Icon>email</Icon>
                <Typography
                  id="workEmail"
                  data-testid="workEmail"
                  className={classes.iconText}
                >
                  Work: {user.email}
                </Typography>
              </Grid>
            )}
            {user.personalEmail && (
              <Grid id="personalemail" container item>
                <Icon>email</Icon>
                <Typography className={classes.iconText}>
                  Personal: {user.personalEmail}
                </Typography>
              </Grid>
            )}
            {user.primaryPhone && (
              <Grid id="primaryPhone" container item>
                <Icon>phone</Icon>
                <Typography
                  id="primaryPhone"
                  data-testid="primaryPhone"
                  className={classes.iconText}
                >
                  Primary: {handlePhoneFormat(user.primaryPhone)}
                </Typography>
              </Grid>
            )}
            {user.secondaryPhone && (
              <Grid container item>
                <Icon>phone</Icon>
                <Typography className={classes.iconText}>
                  Secondary: {handlePhoneFormat(user.secondaryPhone)}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowUpdateForm(true)}
              >
                Update Profile
              </Button>
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={10}
              spacing={2}
              component={Box}
              border={2}
              borderColor="primary.main"
              borderRadius={4}
              className={classes.box}
            >
              <Grid item className={classes.boxDetails}>
                <Typography variant="h4">Employee Details:</Typography>
              </Grid>
              <Grid item className={classes.boxDetails}>
                <Typography variant="h6" data-testid="primaryOffice">
                  <b>Primary Office : </b>
                  {primaryOffice.office.name}
                </Typography>
              </Grid>
              {secondaryOffices.length > 0 && (
                <Grid item className={classes.boxDetails}>
                  <Typography variant="h6" data-testid="secondaryOffices">
                    <b>Secondary Offices: </b>
                    {secondaryOffices.map((e) => e.office.name)}
                  </Typography>
                </Grid>
              )}
              {employmentType && (
                <Grid item className={classes.boxDetails}>
                  <Typography variant="h6" data-testid="empTypeShiftType">
                    <b>Employement Type - Shift Type : </b>
                    {employmentType} - {shiftType}
                  </Typography>
                </Grid>
              )}
              {jobType && (
                <Grid item className={classes.boxDetails}>
                  <Typography variant="h6" data-testid="jobType">
                    <b>Skills:</b>
                    {jobType}
                  </Typography>
                </Grid>
              )}
              {licenseAndEducation && (
                <Grid item className={classes.boxDetails}>
                  <Typography variant="h6">
                    <b>License and Education: </b>
                    {licenseAndEducation}
                  </Typography>
                </Grid>
              )}
              {trainings && (
                <Grid item className={classes.boxDetails}>
                  <Typography variant="h6">
                    <b>Training:</b> {trainings}
                  </Typography>
                </Grid>
              )}
              {certifications && (
                <Grid item className={classes.boxDetails}>
                  <Typography variant="h6">
                    <b>Certifications:</b> {certifications}
                  </Typography>
                </Grid>
              )}
              {/* <Grid item className={classes.boxDetails}>
                <Typography>
                  Required Weekly Hours:
                  {" " +
                    primaryOffice.office.constraintSet.find(
                      (constraint) => constraint.type.name === "FTEMINHRWK"
                    )?.value}
                </Typography>
              </Grid> */}
              <Grid item className={classes.boxDetails}>
                <Typography>
                  <b> Max Soft Requests Per Schedule:</b>
                  {" " + primaryOffice.office.maxGreyoutRequests}
                </Typography>
              </Grid>
              {/* <Grid item className={classes.boxDetails}>
                  <Typography variant='h6'>
                      Department Manager: {user.manager.firstName} {user.manager.lastName}
                  </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            direction="column"
            alignItems="flex-start"
            spacing={2}
            style={{ maxWidth: 350 }}
            data-testid="accountPreferences"
          >
            <AccountPreferences
              setShowToast={setShowToast}
              setShowErrorToast={setShowErrorToast}
            />
          </Grid>
        </Grid>
        <Dialog open={showUpdateForm} fullWidth maxWidth="sm">
          <DialogContent style={{ padding: 20, overflowX: "hidden" }}>
            <UpdateProfileForm
              closeDialog={closeDialog}
              user={user}
              crud={false}
              refetch={refetch}
              setShowToast={setShowToast}
              setShowErrorToast={setShowErrorToast}
            />
          </DialogContent>
        </Dialog>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>Profile updated!</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="error"
          >
            <Typography>Unable to update profile. Please try again.</Typography>
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
};

export default Profile;
