import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  Snackbar,
  CircularProgress,
  LinearProgress,
  IconButton,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import { format, isEqual } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { Formik, Form, FieldArray } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  BATCH_CREATE_PROCEDURE,
  BATCH_CREATE_PROCEDURE_REQUIREMENTS,
  BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  FIND_SKILL_TYPE,
  ALL_OFFICES,
} from "../../api/gqlQueries";
import { useMutation, useQuery } from "@apollo/client";
import { userVar } from "../../cache";

const SUPPORTED_FORMATS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
  table: {
    border: "none",
    boxSizing: "none",
    boxShadow: "none",
    borderRadius: "none",
    width: "100%",
  },
  deleteButton: {
    color: "#333333",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const ImportProcedures = (props) => {
  const {
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props
  const classes = useStyles();
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [vals, SetVals] = useState([]);
  const [errors, setErrors] = useState([]);
  // const [idtodelete, SetIdtodelete] = useState("");
  // const [filteredValues, SetFilteredValues] = useState([]);
  const user = userVar();

  const handleFileRead = (data, fileInfo) => {
    if (SUPPORTED_FORMATS.includes(fileInfo.type)) {
      setFileLoaded(true);
      setFileFormatError(false);
      SetVals(data);
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);
      setErrors([]);
      data.forEach((row, index) => {
        if (row.ShiftStart && row.ShiftEnd) {
          parseDate(row.ShiftStart, index, true);
          parseDate(row.ShiftEnd, index, false);
        }
      });
    } else {
      setFileFormatError(true);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const handleImportEmployees = () => {
    if (fileLoaded) {
      //setShowLoader(true);
    }
  };

  const formatDate = (value) => {
    let formatted = format(new Date(value), "M/d/yyyy HH:mm");
    return formatted;
  };

  const SkillType = useQuery(
    FIND_SKILL_TYPE,
    {
      variables: {
        office: parseInt(user.office.id),
      },
    },
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
        notifyDevelopers({variables: {message: "Error on FIND_SKILL_TYPE Query. Environment: " + environment + ". Graphql " + error}});
      },
    }
  );

  const allOffices = useQuery(ALL_OFFICES);

  const getSkillName = (value) => {
    const findSkill =
      !SkillType.loading &&
      SkillType.data.skills.find(
        (x) => x.name.toLowerCase() === value.toLowerCase()
      );
    if (findSkill) {
      return findSkill.id;
    } else {
      return "";
    }
  };

  const abbreviations = ["AM", "PM", "A", "P", "am", "pm", "a", "p"];

  function parseDate(timeDateString, row, start) {
    let dateNumber;
    let time;

    let timeDateArray = timeDateString.split(" ");
    timeDateArray = timeDateArray.filter(
      (string) => !abbreviations.includes(string)
    );

    if (timeDateArray.length > 0) {
      if (timeDateArray.length > 1) {
        time = timeDateArray.find((string) => string.includes(":"));
        const date = timeDateArray.find((string) => string.includes("/"));

        if (time) {
          if (date) {
            timeDateArray = date.split("/");
          }
        } else {
          timeDateArray = date.split("/");
          time = timeDateArray.find((string) => string.includes(":"));
          timeDateArray = timeDateArray.filter(
            (string) => !string.includes(":")
          );
        }
      } else {
        timeDateArray = timeDateArray[0].split("/");
        time = timeDateArray.find((string) => string.includes(":"));
        timeDateArray = timeDateArray.filter((string) => !string.includes(":"));
      }
    }

    //shortIndex will be the last short string in the array which should
    //correspond to the date number if the format is month/date
    let shortIndex;
    timeDateArray.forEach((string, index) => {
      if (string.length <= 2) {
        shortIndex = index;
      }
    });
    dateNumber = timeDateArray[shortIndex];

    if (time && time.length > 5) {
      const splitCharacters = time.split("");
      const indexOfFirstColon = splitCharacters.indexOf(":");
      time = splitCharacters.slice(0, indexOfFirstColon + 3).join("");
    }

    if (!dateNumber || !time) {
      setErrors([
        ...errors,
        `Invalid date format at row number ${row} in the ${
          start ? "shift start" : "shift end"
        } cell.`,
      ]);
    }

    return { dateNumber, time };
  }

  const [batchCreateProcedures] = useMutation(BATCH_CREATE_PROCEDURE, {
    onCompleted(data) {
      console.log(data);
      setErrors([]);
      let inputData = data.batchCreateProcedure.procedures.map((e) => {
        const rowIndices = [];
        let matchingRows = vals.rows.filter((row, index) => {
          const takeOff = zonedTimeToUtc(new Date(row.TOTimeZ), "Etc/GMT");
          const land = zonedTimeToUtc(new Date(row.LandZ), "Etc/GMT");
          if (
            isEqual(new Date(takeOff), new Date(e.start)) &&
            isEqual(new Date(land), new Date(e.end))
          ) {
            rowIndices.push(index);
            return true;
          } else {
            return false;
          }
        });

        let input = matchingRows.map((row, index) => {
          let trimmed = row.Skill.replace(/ /g, "");
          let skills = trimmed.split(",");

          let constraints = skills.map((skill) => {
            let constraintStartTime = row.ShiftStart;
            const startParsed = parseDate(
              constraintStartTime,
              rowIndices[index],
              true
            );
            const constraintStartDateNumber = startParsed.dateNumber;
            const constraintStartTimeHours = startParsed.time;

            const takeOff = zonedTimeToUtc(new Date(row.TOTimeZ), "Etc/GMT");
            const land = zonedTimeToUtc(new Date(row.LandZ), "Etc/GMT");

            const takeOffDateNumber = takeOff
              .toISOString()
              .split("-")[2]
              .slice(0, 2);
            const takeOffDateString = takeOff.toISOString().split("T")[0];
            const landDateString = land.toISOString().split("T")[0];

            const startOnTakeOffDay =
              parseInt(constraintStartDateNumber) ===
              parseInt(takeOffDateNumber);

            let constraintEndTime = row.ShiftEnd;
            const endParsed = parseDate(
              constraintEndTime,
              rowIndices[index],
              false
            );
            const constraintEndDateNumber = endParsed.dateNumber;
            const constraintEndTimeHours = endParsed.time;

            const endOnTakeOffDay =
              parseInt(constraintEndDateNumber) === parseInt(takeOffDateNumber);

            if (startOnTakeOffDay) {
              constraintStartTime = `${takeOffDateString} ${constraintStartTimeHours}`;
              constraintStartTime = zonedTimeToUtc(
                new Date(constraintStartTime),
                "Etc/GMT"
              );
            } else {
              constraintStartTime = `${landDateString} ${constraintStartTimeHours}`;
              constraintStartTime = zonedTimeToUtc(
                new Date(constraintStartTime),
                "Etc/GMT"
              );
            }

            if (endOnTakeOffDay) {
              constraintEndTime = `${takeOffDateString} ${constraintEndTimeHours}`;
              constraintEndTime = zonedTimeToUtc(
                new Date(constraintEndTime),
                "Etc/GMT"
              );
            } else {
              constraintEndTime = `${landDateString} ${constraintEndTimeHours}`;
              constraintEndTime = zonedTimeToUtc(
                new Date(constraintEndTime),
                "Etc/GMT"
              );
            }

            let skll = skill.replace(/ /g, "");

            return {
              value: 1,
              start: new Date(constraintStartTime).toISOString(),
              end: new Date(constraintEndTime).toISOString(),
              employeeCapability: parseInt(getSkillName(skll)),
              procedureRequirement: e.procedureRequirement.id,
            };
          });
          return constraints;
        });
        return input.flat();
      });

      batchCreateProcedureEmployeeConstraints({
        variables: {
          input: inputData.flat(),
        },
      });
    },
    onError(error) {
      console.log(error);
      setErrors([
        "Error creating procedures. Please check dates are in the proper format.",
      ]);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on BATCH_CREATE_PROCEDURE. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [batchCreateProcedureEmployeeConstraints] = useMutation(
    BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onCompleted(data) {
        console.log(data);
        setsnackBarMessage(
          data.batchCreateProcedureEmployeeConstraint
            ?.procedureEmployeeConstraints?.length +
            " Procedure Constraints Successfully Created"
        );
        setsnackbaropen(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
        notifyDevelopers({variables: {message: "Error on  BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " + environment + ". Graphql " + error}});
      },
    }
  );

  const [batchCreateProcedureRequirements] = useMutation(
    BATCH_CREATE_PROCEDURE_REQUIREMENTS,
    {
      onCompleted(data) {
        console.log(data);
        const inputData =
          data.batchCreateProcedureRequirement.procedureRequirements.map(
            (e) => {
              let matchingRow = vals.rows.find((row) => {
                const takeOff = zonedTimeToUtc(
                  new Date(row.TOTimeZ),
                  "Etc/GMT"
                );
                const land = zonedTimeToUtc(new Date(row.LandZ), "Etc/GMT");
                return (
                  format(takeOff, "yyyy-MM-dd") === e.earliestDate &&
                  format(land, "yyyy-MM-dd") === e.latestDate &&
                  row.MSN === e.name
                  // (row.MSN!=null || row.MSN!="")
                );
              });

              if (matchingRow) {
                const takeOff = zonedTimeToUtc(
                  new Date(matchingRow.TOTimeZ),
                  "Etc/GMT"
                );
                const land = zonedTimeToUtc(
                  new Date(matchingRow.LandZ),
                  "Etc/GMT"
                );

                return {
                  procedureRequirement: parseInt(e.id),
                  start: takeOff.toISOString(),
                  end: land.toISOString(),
                };
              }
            }
          );

        batchCreateProcedures({
          variables: {
            input: inputData,
          },
        });
      },
      onError(error) {
        console.log(error);
        setErrors([
          "Error creating procedures. Please check dates are in the proper format.",
        ]);
        seterrorsnackbaropen(true);
        notifyDevelopers({variables: {message: "Error on BATCH_CREATE_PROCEDURE_REQUIREMENTS Query. Environment: " + environment + ". Graphql " + error}});
      },
    }
  );

  const handleCreateProcedureRequirements = (values) => {
    let inputData = vals.map((e) => {
      if (e.MSN != null) {
        const takeOff = zonedTimeToUtc(new Date(e.TOTimeZ), "Etc/GMT");
        const land = zonedTimeToUtc(new Date(e.LandZ), "Etc/GMT");
        const office =
          !allOffices.loading &&
          allOffices.data.offices.find((x) => x.name === e.Department);
        if (office) {
          return {
            name: e.MSN,
            earliestDate: format(takeOff, "yyyy-MM-dd"),
            latestDate: format(land, "yyyy-MM-dd"),
            duration: parseInt(e.Duration),
            additiveConstraint: true,
            procedureType: null,
            office: parseInt(office.id),
          };
        } else {
          setsnackBarMessage(
            "Department name does not match any departments of this office. Please try again with the correct department name"
          );
          seterrorsnackbaropen(true);
        }
      }
    });
    SetVals(values);
    let input = inputData.filter((e) => e != null);
    batchCreateProcedureRequirements({
      variables: {
        input: input,
      },
    });
  };

  const headerData = [
    [
      "Status",
      "MSN",
      "Color",
      "TOTimeZ",
      "LandZ",
      "ShowL",
      "SOShowL",
      "TakeoffL",
      "LandL",
      "Duration",
      "ShiftStart",
      "ShiftEnd",
      "Skill",
      "Department",
    ],
  ];

  if (showLoader) {
    return <CircularProgress color="primary" />;
  } else {
    return (
      <Grid
        container
        direction="row"
        className={classes.headerSpacing}
        spacing={4}
      >
        <Snackbar
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={() => {
            setsnackbaropen(false);
            setShowLoader(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              setsnackbaropen(false);
              setsnackBarMessage("");
            }}
            severity="success"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={errorsnackbaropen}
          autoHideDuration={6000}
          onClose={() => {
            seterrorsnackbaropen(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => seterrorsnackbaropen(false)}
            severity="error"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Grid item xs={12}>
          <Typography variant="h3">Import Mission</Typography>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid item xs={6}>
            <Box
              m={2}
              p={2}
              style={{
                border: "1px solid gray",
                width: "100%",
                borderRadius: "5px",
              }}
            >
              <CSVReader
                onFileLoaded={handleFileRead}
                parserOptions={papaparseOptions}
              />
            </Box>

            <Typography color="error" variant="body2">
              {fileFormatError &&
                "File format not supported.Upload a .csv or excel file"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box m={2} p={2}>
              <Button
                color="secondary"
                component={CSVLink}
                data={headerData}
                target="_blank"
                color="primary"
                variant="outlined"
              >
                Download CSV Format
              </Button>
            </Box>
          </Grid>
          {showLoader ? (
            <Grid item xs={9}>
              <LinearProgress color="primary" />
            </Grid>
          ) : null}
          {errors && errors.length > 0 ? (
            <Grid item xs={9}>
              {errors.map((error) => (
                <Typography color="primary">{error}</Typography>
              ))}
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              initialValues={{
                rows: vals,
              }}
              onSubmit={(values) => {
                setErrors([]);
                values.rows.forEach((row, index) => {
                  if (row.ShiftStart && row.ShiftEnd) {
                    parseDate(row.ShiftStart, index, true);
                    parseDate(row.ShiftEnd, index, false);
                  }
                });
                if (errors && errors.length === 0) {
                  handleCreateProcedureRequirements(values);
                }
                //handleProcedureEmployeeConstraints(values);
              }}
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <Grid container direction="row" item xs={12} spacing={4}>
                    <Grid item xs={12}>
                      <Box m={4} style={{ textAlign: "right" }}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <FieldArray name="rows">
                        {({ push, remove }) => (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>MSN</StyledTableCell>
                                <StyledTableCell>Color</StyledTableCell>
                                <StyledTableCell>T/OTimeZ</StyledTableCell>
                                <StyledTableCell>LandZ</StyledTableCell>
                                <StyledTableCell>ShowL</StyledTableCell>
                                <StyledTableCell>SO ShowL</StyledTableCell>
                                <StyledTableCell>Takeoff L</StyledTableCell>
                                <StyledTableCell>Land L</StyledTableCell>
                                <StyledTableCell>Duration</StyledTableCell>
                                <StyledTableCell>Shift Start</StyledTableCell>
                                <StyledTableCell>Shift End</StyledTableCell>
                                <StyledTableCell>Skill</StyledTableCell>
                                <StyledTableCell>Department</StyledTableCell>
                                <StyledTableCell>Remove</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.rows.length > 0 &&
                                values.rows.map((e, index) => (
                                  <TableRow key={index}>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.Status`}
                                        name={`rows.${index}.Status`}
                                        value="added"
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.MSN`}
                                        name={`rows.${index}.MSN`}
                                        value={e.MSN}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.Color`}
                                        name={`rows.${index}.Color`}
                                        value={e.Color}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <Grid container justifyContent="space-around">
                                          <DatePicker
                                            variant="outlined"
                                            id={`rows.${index}.TOTimeZ`}
                                            name={`rows.${index}.TOTimeZ`}
                                            value={new Date(e.TOTimeZ)}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `rows.${index}.TOTimeZ`,
                                                e
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton>
                                                    <CalendarIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                      </MuiPickersUtilsProvider>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <Grid container justifyContent="space-around">
                                          <DatePicker
                                            variant="inline"
                                            id={`rows.${index}.LandZ`}
                                            name={`rows.${index}.LandZ`}
                                            value={new Date(e.LandZ)}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `rows.${index}.LandZ`,
                                                e
                                              );
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton>
                                                    <CalendarIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                      </MuiPickersUtilsProvider>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.ShowL`}
                                        name={`rows.${index}.ShowL`}
                                        value={e.ShowL}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.SOShowL`}
                                        name={`rows.${index}.SOShowL`}
                                        value={e.SOShowL}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.TakeoffL`}
                                        name={`rows.${index}.TakeoffL`}
                                        value={e.TakeoffL}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.LandL`}
                                        name={`rows.${index}.LandL`}
                                        value={e.LandL}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.Duration`}
                                        name={`rows.${index}.Duration`}
                                        value={e.Duration}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.ShiftStart`}
                                        name={`rows.${index}.ShiftStart`}
                                        value={e.ShiftStart}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`rows.${index}.ShiftEnd`}
                                        name={`rows.${index}.ShiftEnd`}
                                        value={e.ShiftEnd}
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        id={`rows.${index}.Skill`}
                                        name={`rows.${index}.Skill`}
                                      >
                                        {e.Skill}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        id={`rows.${index}.Department`}
                                        name={`rows.${index}.Department`}
                                      >
                                        {e.Department}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </StyledTableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <StyledTableCell>
                                  <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                      push({
                                        Status: "Added",
                                        MSN: "",
                                        Color: "",
                                        TOTimeZ: new Date(),
                                        LandZ: new Date(),
                                        ShowL: "",
                                        SOShowL: "",
                                        TakeoffL: "",
                                        LandL: "",
                                        Duration: 24,
                                        ShiftStart: "",
                                        ShiftEnd: "",
                                        Skill: "",
                                        Department: "",
                                      })
                                    }
                                  >
                                    Add Another
                                  </Button>
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
export default ImportProcedures;
