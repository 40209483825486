import React, { useState } from "react";
import {
  div,
  Typography,
  Box,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import {
  SCHEDULE_QUALITY_ANALYTICS,
  PREFERENCE_ANALYTICS,
  EMPLOYEE_PREFERENCE_SUMMARY,
  GET_SCHEDULE_PERIODS,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import { useLazyQuery, useQuery } from "@apollo/client";
import { scheduleQualityGrade } from "../../helpers/DashboardHelpers";

const PreferenceFeedback = (props) => {
  const user = userVar();
  const [empPrefSummary, SetEmpPrefSummary] = useState();
  const scheduleQualityAnalytics = useQuery(SCHEDULE_QUALITY_ANALYTICS, {
    variables: { employee: parseInt(user.id) },
  });

  const preferenceAnalytics = useQuery(PREFERENCE_ANALYTICS, {
    variables: {
      employee: parseInt(user.id),
    },
    onError(err) {
      console.log(err);
    },
  });

  const [employeeSummary] = useLazyQuery(EMPLOYEE_PREFERENCE_SUMMARY, {
    onCompleted(d) {
      SetEmpPrefSummary(d.employeeSummary);
    },
    onError(err) {
      console.log(err);
    },
  });

  const SchedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onCompleted(d) {
      let currentSchedulePeriod = d.schedulePeriods.find(
        (e) => new Date(e.start) <= new Date() && new Date(e.end) >= new Date()
      );

      currentSchedulePeriod &&
        employeeSummary({
          variables: {
            schedulePeriod: parseInt(currentSchedulePeriod.id),
            employee: parseInt(user.id),
          },
        });
    },
  });

  if (
    scheduleQualityAnalytics.loading ||
    preferenceAnalytics.loading ||
    SchedulePeriods.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const employeeScheduleQuality =
      scheduleQualityAnalytics.data.scheduleQualityAnalytics &&
      scheduleQualityAnalytics.data.scheduleQualityAnalytics.length > 0 &&
      (
        parseFloat(
          scheduleQualityAnalytics.data.scheduleQualityAnalytics
            .map((e) => parseFloat(e.score))
            .reduce((a, b) => a + b, 0) /
            scheduleQualityAnalytics.data.scheduleQualityAnalytics.length
        ) * 100
      ).toFixed();

    let colorandgrade = scheduleQualityGrade(employeeScheduleQuality);

    return (
      <div>
        <div>
          <Box m={2}>
            <Typography variant="h4">PREFERENCE FEEDBACK</Typography>
          </Box>
        </div>
        <div
          style={{
            margin: 10,
            background: user.isSchedulable
              ? colorandgrade
                ? colorandgrade.color
                : props.lightBackground
              : props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 5,
            cursor: "pointer",
          }}
        >
          {" "}
          <Typography variant="body1">
            Schedule Quality :
            {user.isSchedulable
              ? colorandgrade
                ? colorandgrade.grade
                : "N/A"
              : "N/A"}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
        >
          {" "}
          <Typography variant="body1">
            Best Performing Preference:{" "}
            {user.isSchedulable
              ? empPrefSummary &&
                empPrefSummary.length > 0 &&
                empPrefSummary[0].bestPerforming
              : "N/A"}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
        >
          {" "}
          <Typography variant="body1">
            Most UnderPerforming Preference:{" "}
            {user.isSchedulable
              ? empPrefSummary &&
                empPrefSummary.length > 0 &&
                empPrefSummary[0].mostUnderperforming
              : "N/A"}
          </Typography>
        </div>
      </div>
    );
  }
};

export default PreferenceFeedback;
