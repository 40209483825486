export const calculateMode = (arr) => {
    var mode = 0;
    var count = 0;
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < i; j++) {
        if (arr[j] === arr[i]) {
          mode = arr[j];
          count++;
        }
      }
    }
    return mode;
  }
  
  export const calculateMedian = (arr) => {
    const arrSort = arr.sort();
    var half = Math.floor(arrSort.length / 2);
    if (arrSort.length % 2) {
      return parseFloat(arrSort[half]);
    } else {
      return (
        (parseFloat(arrSort[half - 1]) + parseFloat(arrSort[half])) /
        2
      ).toFixed(2);
    }
  }
  
  export const calculateAverage = (arr) => {
    let av = 0;
    for (let i = 1; i < arr.length; i++) {
      av = av + parseFloat(arr[i]);
    }
    av = av / arr.length;
    return av.toFixed(2);
  }