const roles = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  LEAD: "MANAGER",
  EMPLOYEE: "EMPLOYEE",
  CNO: "Executive",
  EXECUTIVE: "Executive",
  SCHEDULER: "SCHEDULER"
};

export default roles;
