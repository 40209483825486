import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  Snackbar,
  CircularProgress,
  LinearProgress,
  IconButton,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  withStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import { Formik, Form, FieldArray } from "formik";

const SUPPORTED_FORMATS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
  table: {
    border: "none",
    boxSizing: "none",
    boxShadow: "none",
    borderRadius: "none",
    width: "100%",
  },
  deleteButton: {
    color: "#333333",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const DynamicGrid = ({ columns, values, classes }) => {
  return (
    <div style={{ height: 700 }}>
      <DataGrid
        className={classes.dt}
        columns={columns}
        rows={values}
        pageSize={10}
      />
    </div>
  );
};

const ImportTraining = (props) => {
  const classes = useStyles();
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [values, SetValues] = useState([]);
  const [idtodelete, SetIdtodelete] = useState("");

  const handleFileRead = (data, fileInfo) => {
    if (SUPPORTED_FORMATS.includes(fileInfo.type)) {
      setFileLoaded(true);
      setFileFormatError(false);
      SetValues(data);
    } else {
      setFileFormatError(true);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const handleImportEmployees = () => {
    if (fileLoaded) {
      //setShowLoader(true);
    }
  };

  const headerData = [
    ["id", "Skill", "Variety", "Department"],
  ];

  return (
    <Grid
      container
      direction="row"
      className={classes.headerSpacing}
      spacing={4}
    >
      <Snackbar
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={() => {
          setsnackbaropen(false);
          setShowLoader(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => {
            setsnackbaropen(false);
            setsnackBarMessage("");
          }}
          severity="success"
        >
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorsnackbaropen}
        autoHideDuration={6000}
        onClose={() => {
          seterrorsnackbaropen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert onClose={() => seterrorsnackbaropen(false)} severity="error">
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h3">Import Trainings</Typography>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid item xs={6}>
          <Box
            m={2}
            p={2}
            style={{
              border: "1px solid gray",
              width: "100%",
              borderRadius: "5px",
            }}
          >
            <CSVReader
              onFileLoaded={handleFileRead}
              parserOptions={papaparseOptions}
            />
          </Box>

          <Typography color="error" variant="body2">
            {fileFormatError &&
              "File format not supported.Upload a .csv or excel file"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box m={2} p={2}>
            <Button
              color="secondary"
              component={CSVLink}
              data={headerData}
              target="_blank"
              color="primary"
              variant="outlined"
            >
              Download CSV Format
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9}>
          {showLoader ? <LinearProgress color="primary" /> : null}
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={{
              rows: values,
            }}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ values, handleChange, dirty, setFieldValue }) => (
              <Form>
                <Grid container direction="row" item xs={12} spacing={4}>
                  <Grid item xs={12}>
                    <Box m={4} style={{ textAlign: "right" }}>
                      <Button type="submit" color="primary" variant="contained">
                        Submit
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="rows">
                      {({ push, remove }) => (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Training</StyledTableCell>
                              <StyledTableCell>Variety</StyledTableCell>
                              <StyledTableCell>Department</StyledTableCell>
                              <StyledTableCell>Remove</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.rows.length > 0 &&
                              values.rows.map((e, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Training`}
                                      name={`rows.${index}.Training`}
                                      variant="outlined"
                                      value={e.Training}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Variety`}
                                      name={`rows.${index}.Variety`}
                                      variant="outlined"
                                      value={e.Variety}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Department`}
                                      name={`rows.${index}.Department`}
                                      variant="outlined"
                                      value={e.Department}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <IconButton
                                      color="secondary"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            <TableRow>
                              <StyledTableCell>
                                <Button
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  onClick={() =>
                                    push({
                                      Training: "",
                                      Variety:"",
                                      Department:""
                                    })
                                  }
                                >
                                  Add Another
                                </Button>
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ImportTraining;
