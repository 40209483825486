import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  Snackbar,
  CircularProgress,
  LinearProgress,
  IconButton,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  withStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import { Formik, Form, FieldArray } from "formik";
import {
  BATCH_CREATE_EMPLOYEE_SKILLS,
  FIND_EMPLOYEE_BY_ID,
  ALL_OFFICES,
  FIND_SKILL_TYPE,
} from "../../api/gqlQueries";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";

const SUPPORTED_FORMATS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
  table: {
    border: "none",
    boxSizing: "none",
    boxShadow: "none",
    borderRadius: "none",
    width: "100%",
  },
  deleteButton: {
    color: "#333333",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const ImportEmployeeTrainings = (props) => {
  const {
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment
  } = props
  const classes = useStyles();
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [values, SetValues] = useState([]);
  const [idtodelete, SetIdtodelete] = useState("");

  const handleFileRead = (data, fileInfo) => {
    if (SUPPORTED_FORMATS.includes(fileInfo.type)) {
      setFileLoaded(true);
      setFileFormatError(false);
      SetValues(data);
    } else {
      setFileFormatError(true);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const headerData = [
    ["id", "Training", "Employee", "StartDate", "ExpirationDate", "Department"],
  ];

  const [batchCreateEmployeeSkills] = useMutation(BATCH_CREATE_EMPLOYEE_SKILLS, {
    onError(error){
      console.log(error);
    }
  });
  const [employees, SetEmployees] = useState([]);

  const findEmployeeByEmail = useQuery(FIND_EMPLOYEE_BY_ID, {
    onCompleted(data) {
      SetEmployees(data.employees);
    },
     onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on FIND_EMPLOYEE_BY_ID Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const getEmployeeID = (value) => {
    let emp = employees.length > 0 && employees.find((e) => e.email === value);
    console.log(emp)
    if (emp) {
      return emp.id;
    } else {
      return "";
    }
  };

  const SkillType = useQuery(FIND_SKILL_TYPE, {
     onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on FIND_SKILL_TYPE Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const getSkillID = (value) => {
    console.log(value)
    console.log(!SkillType.loading && SkillType.data)
    const findSkill =
      !SkillType.loading && SkillType.data.skills.find((x) => x.name === value);
      console.log(findSkill)
    if (findSkill) {
      return findSkill.id;
    } else {
      return "";
    }
  };

  return (
    <Grid
      container
      direction="row"
      className={classes.headerSpacing}
      spacing={4}
    >
      <Snackbar
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={() => {
          setsnackbaropen(false);
          setShowLoader(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => {
            setsnackbaropen(false);
            setsnackbarMessage("");
          }}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorsnackbaropen}
        autoHideDuration={6000}
        onClose={() => {
          seterrorsnackbaropen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert onClose={() => seterrorsnackbaropen(false)} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h3">Import Employee Trainings</Typography>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid item xs={6}>
          <Box
            m={2}
            p={2}
            style={{
              border: "1px solid gray",
              width: "100%",
              borderRadius: "5px",
            }}
          >
            <CSVReader
              onFileLoaded={handleFileRead}
              parserOptions={papaparseOptions}
            />
          </Box>

          <Typography color="error" variant="body2">
            {fileFormatError &&
              "File format not supported.Upload a .csv or excel file"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box m={2} p={2}>
            <Button
              color="secondary"
              component={CSVLink}
              data={headerData}
              target="_blank"
              color="primary"
              variant="outlined"
            >
              Download CSV Format
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9}>
          {showLoader ? <LinearProgress color="primary" /> : null}
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={{
              rows: values,
            }}
            onSubmit={(values) => {
              let inputData = values.rows.map((e) => {
                return {
                  employee: parseInt(getEmployeeID(e.EmployeeEmail)),
                  skill: getSkillID(e.Skill),
                  expiration: format(new Date(e.ExpirationDate),"yyyy-MM-dd"),
                };
              });
              batchCreateEmployeeSkills({
                variables: {
                  input: inputData,
                },
              });
            }}
          >
            {({ values, handleChange, dirty, setFieldValue }) => (
              <Form>
                <Grid container direction="row" item xs={12} spacing={4}>
                  <Grid item xs={12}>
                    <Box m={4} style={{ textAlign: "right" }}>
                      <Button type="submit" color="primary" variant="contained">
                        Submit
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="rows">
                      {({ push, remove }) => (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Training</StyledTableCell>
                              <StyledTableCell>Employee Email</StyledTableCell>
                              <StyledTableCell>Start Date</StyledTableCell>
                              <StyledTableCell>Expiration Date</StyledTableCell>
                              <StyledTableCell>Remove</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.rows.length > 0 &&
                              values.rows.map((e, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Skill`}
                                      name={`rows.${index}.Skill`}
                                      variant="outlined"
                                      value={e.Skill}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.EmployeeEmail`}
                                      name={`rows.${index}.EmployeeEmail`}
                                      variant="outlined"
                                      value={e.EmployeeEmail}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.StartDate`}
                                      name={`rows.${index}.StartDate`}
                                      variant="outlined"
                                      value={e.StartDate}
                                      onChange={handleChange}
                                    />{" "}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.ExpirationDate`}
                                      name={`rows.${index}.ExpirationDate`}
                                      variant="outlined"
                                      value={e.ExpirationDate}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <IconButton
                                      color="secondary"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            <TableRow>
                              <StyledTableCell>
                                <Button
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  onClick={() =>
                                    push({
                                      Skill: "",
                                      EmployeeEmail: "",
                                      StartDate: "",
                                      ExpirationDate: "",
                                    })
                                  }
                                >
                                  Add Another
                                </Button>
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ImportEmployeeTrainings;
