import React from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  GET_SCHEDULE_INFO,
  GET_SCHEDULE_PERIODS,
  GET_INCOMING_TO_REQUESTS,
  EMPLOYEE_GET_INCOMING_SS_REQUESTS,
} from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import { format, sub } from "date-fns";
import { userVar } from "../../cache";

function UpcomingTasks(props) {
  const user = userVar();
  const history = useHistory();
  const scheduleInfo = useQuery(GET_SCHEDULE_INFO, {
    variables: {
      id: parseInt(user.office.id),
    },
    onError(err) {
      console.log(err);
    },
  });

  const schedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(err) {
      console.log(err);
    },
  });

  const getManagerRequests = useQuery(GET_INCOMING_TO_REQUESTS, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onError(err) {
      console.log(err);
    },
  });

  const getEmployeeRequests = useQuery(EMPLOYEE_GET_INCOMING_SS_REQUESTS, {
    variables: {
      employee: parseInt(user.id),
    },
    onError(err) {
      console.log(err);
    },
  });

  if (
    scheduleInfo.loading ||
    getManagerRequests.loading ||
    getEmployeeRequests.loading ||
    schedulePeriods.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    let mostRecentSchedule =
      schedulePeriods.data.schedulePeriods &&
      schedulePeriods.data.schedulePeriods.length > 0 &&
      schedulePeriods.data.schedulePeriods.filter(
        (e) =>
          e.status === "PUBLISHED" ||
          e.status === "MANAGER_DRAFT" ||
          e.status === "MANAGER DRAFT" ||
          e.status === "DRAFT" ||
          e.status === "REQUESTING_FLOATS" ||
          e.status === "REQUESTING_ADDITIONAL_FLOATS"
      );

    let dayspreferencesDueBeforeScheduleDate =
      scheduleInfo.data.offices &&
      parseInt(scheduleInfo.data.offices[0].numberDaysBeforePreferencesDue);

    mostRecentSchedule &&
      mostRecentSchedule.length > 0 &&
      mostRecentSchedule.sort((a, b) => new Date(b.start) - new Date(a.start));

    let weeksBeforeScheduleGeneration =
      scheduleInfo.data.offices &&
      parseInt(scheduleInfo.data.offices[0].schedulePublishedBufferWeeks);

    let reviewWindowDays =
      scheduleInfo.data.offices &&
      parseInt(scheduleInfo.data.offices[0].reviewWindowDays);
    let generateNextScheduleBefore = mostRecentSchedule && mostRecentSchedule.length>0 
      ? sub(new Date(mostRecentSchedule[0].end), {
          weeks: weeksBeforeScheduleGeneration,
        })
      : format(new Date(), "MMM-dd-yyyy");

    generateNextScheduleBefore = format(
      sub(new Date(generateNextScheduleBefore), {
        days: reviewWindowDays,
      }),
      "MMM-dd-yyyy"
    );

    let preferencesDueBeforeScheduleDate = format(
      sub(new Date(generateNextScheduleBefore), {
        days: dayspreferencesDueBeforeScheduleDate,
      }),
      "MMM-dd-yyyy"
    );
    if (!user) {
      return <CircularProgress color="primary" />;
    } else {
      console.log(
        getManagerRequests.data.timeOff &&
          getManagerRequests.data.timeOff.length >= 0 &&
          getManagerRequests.data.timeOff.filter(
            (e) =>
              e.approvedby === null &&
              new Date(e.createdAt) >= sub(new Date(), { days: 7 }) &&
              e.userCancelled === false
          )
      );
      return (
        <div>
          <div>
            <Box m={2}>
              <Typography variant="h4">UPCOMING TASKS</Typography>
            </Box>
          </div>
          <div
            style={{
              margin: 10,
              background: props.lightBackground,
              border: "1px solid" + props.borderColor,
              padding: 8,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => history.push("/Requests")}
          >
            {" "}
            <Typography variant="body1">
              Requests to Approve:{" "}
              {user.isEmployee === true
                ? getEmployeeRequests.data.shiftSwitches &&
                  getEmployeeRequests.data.shiftSwitches.length >= 0 &&
                  getEmployeeRequests.data.shiftSwitches.filter(
                    (e) => new Date(e.createdAt) >= sub(new Date(), { days: 7 })
                  ).length
                : getManagerRequests.data.timeOff &&
                  getManagerRequests.data.timeOff.length >= 0 &&
                  getManagerRequests.data.timeOff.filter(
                    (e) =>
                      e.approvedby === null &&
                      new Date(e.createdAt) >= sub(new Date(), { days: 7 }) &&
                      e.userCancelled === false
                  ).length}
            </Typography>
          </div>
          <div
            style={{
              margin: 10,
              background: props.lightBackground,
              border: "1px solid" + props.borderColor,
              padding: 8,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => history.push("/Preferences")}
          >
            {" "}
            <Typography variant="body1">
              Preference Due : {preferencesDueBeforeScheduleDate}
            </Typography>
          </div>
          <div
            style={{
              margin: 10,
              background: props.lightBackground,
              border: "1px solid" + props.borderColor,
              padding: 8,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() =>
              !user.isEmployee
                ? history.push("/CreateSchedule")
                : history.push("/Schedule")
            }
          >
            {" "}
            <Typography variant="body1">
              {" "}
              Next Schedule : {generateNextScheduleBefore}
            </Typography>
          </div>
        </div>
      );
    }
  }
}

export default UpcomingTasks;
