import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  withStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { userVar, selectedDateVar } from "../../cache";
import {
  FIND_SKILL_TYPE,
  BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  BATCH_UPDATE_PROCEDURE,
  BATCH_UPDATE_PROCEDURE_REQUIREMENTS,
  BATCH_UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  BATCH_DELETE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
  GET_OFFICE_TIMEZONE,
  DELETE_PROCEDURE_REQUIREMENT,
  PROCEDURE_EMPLOYEE_CONSTRAINTS,
  CREATE_BOOKED_PROCEDURES,
  PROCEDURE_TYPES,
  GET_ALL_CHILD_OFFICES_NAMES,
} from "../../api/gqlQueries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  differenceInHours,
  format,
  isEqual,
  addHours,
  add,
  sub,
} from "date-fns";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import AddIcon from "@material-ui/icons/Add";
import MultiSelect from "../general/MultiSelectSkills";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 170,
    maxWidth: 275,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: "#8CADE1",
    fontWeight: 700,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
  },
}))(TableCell);

const validationSchema = Yup.object({
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
  duration: Yup.number().required("Required"),
  procedureType: Yup.string().required("Required"),
  shifts: Yup.array().of(
    Yup.object().shape({
      skills: Yup.string().required("Required"),
      ShiftStart: Yup.string().required("Required"),
      ShiftEnd: Yup.string().required("Required"),
    })
  ),
});

const ProcedureRequirementForm = (props) => {
  const classes = useStyles();
  const {
    closeDialog,
    procedureRequirement,
    setToast,
    setShowToast,
    setAddEmployees,
    setConstraints,
    refetch,
    scheduleEndDate,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    getVariables,
  } = props;

  const user = userVar();
  const selectedDate = selectedDateVar();
  const selectedDateEndDate = addHours(selectedDate, 4);

  const [vals, SetVals] = useState([]);
  const [showAddToast, setShowAddToast] = useState(false);
  const [showProcedureAddToast, setShowProcedureAddToast] = useState(false);

  const getSkills = useQuery(FIND_SKILL_TYPE);

  const getTimezone = useQuery(GET_OFFICE_TIMEZONE, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE_TIMEZONE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const procedureTypes = useQuery(PROCEDURE_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PROCEDURE_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const childOffices = useQuery(GET_ALL_CHILD_OFFICES_NAMES, {
    variables: {
      parent: parseInt(user.office.id),
    },
    onCompleted(d) {
      console.log(d);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PROCEDURE_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createProcedureRequirements] = useMutation(CREATE_BOOKED_PROCEDURES, {
    onCompleted(d) {
      refetch();
      setShowProcedureAddToast(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_BOOKED_PROCEDURES Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteProcedureRequirement] = useMutation(
    DELETE_PROCEDURE_REQUIREMENT,
    {
      onCompleted(data) {
        console.log(data);
        setToast("Delete Procedure");
        setShowToast(true);
        refetch();
        closeDialog();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on DELETE_PROCEDURE_REQUIREMENT Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const procedureEmployeeConstraints = useQuery(
    PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on PROCEDURE_EMPLOYEE_CONSTRAINTS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchDeleteProcedureEmployeeConstraint] = useMutation(
    BATCH_DELETE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_DELETE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchUpdateProcedures] = useMutation(BATCH_UPDATE_PROCEDURE, {
    onCompleted(data) {
      // var idsToDelete = [];
      let currentConstraints = [
        ...procedureRequirement?.procedureemployeeconstraintSet,
      ];

      let inputData = data.batchUpdateProcedure.procedures.map((procedure) => {
        let input = vals.shifts.map((shift) => {
          // var allIds = shift.skills.map((x) => x.id);
          let skills = shift.skills.map((skill) => {
            let existing =
              // procedureEmployeeConstraints.data.procedureEmployeeConstraints.find(
              currentConstraints.find(
                (constraint) => constraint.id === skill.constraintId
              );

            if (existing) {
              return {
                id: parseInt(existing.id),
                value: 1,
                start: shift.ShiftStart,
                end: shift.ShiftEnd,
                employeeCapability: parseInt(skill.id),
                procedureRequirement: procedure.procedureRequirement.id,
              };
            } else {
              return {
                value: 1,
                start: shift.ShiftStart,
                end: shift.ShiftEnd,
                employeeCapability: parseInt(skill.id),
                procedureRequirement: procedure.procedureRequirement.id,
              };
            }
          });
          return skills.flat();
        });
        return input.flat();
      });

      let input = inputData.flat();
      let createInput = input.flat().filter((e) => !e.id);
      let updateInput = input.flat().filter((e) => e.id);
      // .filter((e) => e.id != null && !idsToDelete.includes(e.id));
      // const allSkills = vals.shifts.map(shift => shift.skills).flat();
      let idsToDelete = currentConstraints
        .filter((constraint) => {
          const updateIds = updateInput.map((input) => parseInt(input.id));
          return !updateIds.includes(parseInt(constraint.id));
        })
        .map((constraint) => constraint.id);

      batchUpdateProcedureEmployeeConstraints({
        variables: {
          input: updateInput,
        },
      });
      batchCreateProcedureEmployeeConstraints({
        variables: {
          input: createInput,
        },
      });
      batchDeleteProcedureEmployeeConstraint({
        variables: {
          ids: idsToDelete,
        },
      });
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_UPDATE_PROCEDURE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateProcedureEmployeeConstraints] = useMutation(
    BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        setShowAddToast(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchUpdateProcedureEmployeeConstraints] = useMutation(
    BATCH_UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS,
    {
      onCompleted(data) {
        console.log(data);
        const start = sub(new Date(selectedDate), { months: 1 });
        const end = add(new Date(selectedDate), { months: 1 });
        const futureVariables = getVariables(start, end);
        refetch(futureVariables);
        setShowAddToast(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchUpdateProcedureRequirements] = useMutation(
    BATCH_UPDATE_PROCEDURE_REQUIREMENTS,
    {
      onCompleted(data) {
        const inputData =
          data.batchUpdateProcedureRequirement.procedureRequirements.map(
            (procedureReq) => {
              return {
                id: procedureReq.procedure.id,
                procedureRequirement: parseInt(procedureReq.id),
                start: vals.startDate.toISOString(),
                end: vals.endDate.toISOString(),
                notes: vals.notes,
              };
            }
          );

        batchUpdateProcedures({
          variables: {
            input: inputData,
          },
        });
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );

        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_UPDATE_PROCEDURE_REQUIREMENTS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const handleCreateProcedureRequirements = (values) => {
    console.log(values);
    let employeeRequirementsInput = values.shifts
      .map((e) => {
        let empInput = e.skills.map((s) => {
          return {
            start: e.ShiftStart,
            end: e.ShiftEnd,
            skill: parseInt(s.id),
            value: 1,
          };
        });
        return empInput.flat();
      })
      .flat();

    let inputData = [
      {
        start: values.startDate,
        end: values.endDate,
        office:
          user.isPrimaryParentOffice === true
            ? parseInt(values.office)
            : parseInt(user.office.id),
        procedureType: parseInt(values.procedureType.id), //this should be an ID
        name: values.procedureType.name,
        notes: values.notes,
        employeeRequirements: employeeRequirementsInput,
      },
    ];
    createProcedureRequirements({
      variables: {
        procedures: inputData,
      },
    });
  };

  const handleUpdateProcedureRequirements = (values) => {
    SetVals(values);
    let inputData = [
      {
        id: values.id,
        name: values.msn,
        earliestDate: format(values.startDate, "yyyy-MM-dd"),
        latestDate: format(values.endDate, "yyyy-MM-dd"),
        duration: parseInt(values.duration),
        additiveConstraint: true,
        procedureType: null,
        office: parseInt(user.office.id),
      },
    ];
    batchUpdateProcedureRequirements({
      variables: {
        input: inputData,
      },
    });
  };

  if (
    getSkills.loading ||
    getTimezone.loading ||
    procedureEmployeeConstraints.loading ||
    procedureTypes.loading ||
    childOffices.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    let formattedShifts = [];
    if (procedureRequirement) {
      procedureRequirement.procedureemployeeconstraintSet.forEach(
        (constraint) => {
          const matchingShift = formattedShifts.find(
            (shift) =>
              isEqual(shift.ShiftStart, new Date(constraint.start)) &&
              isEqual(shift.ShiftEnd, new Date(constraint.end))
          );
          if (matchingShift) {
            matchingShift.skills.push({
              ...constraint.employeeCapability,
              value: constraint.value,
              constraintId: constraint.id,
            });
          } else {
            formattedShifts.push({
              ShiftStart: new Date(constraint.start),
              ShiftEnd: new Date(constraint.end),
              skills: [
                {
                  ...constraint.employeeCapability,
                  value: constraint.value,
                  constraintId: constraint.id,
                },
              ],
            });
          }
        }
      );
    }

    const handleAddEmployees = () => {
      setConstraints(formattedShifts);
      setAddEmployees(true);
    };

    const handleDeleteClick = () => {
      deleteProcedureRequirement({
        variables: {
          id: parseInt(procedureRequirement.id),
        },
      });
    };

    const officeTimezone =
      getTimezone && getTimezone.data
        ? getTimezone.data.offices[0].timezone
        : "";

    const momentDate = moment().tz(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const officeTZDate = momentDate.clone().tz(officeTimezone);

    const sameTimezoneAsOffice =
      momentDate.format("z") === officeTZDate.format("z");

    const getOffsetTime = (date) => {
      const newDate = moment(date);
      const formattedToOfficeTimezone = newDate
        .clone()
        .tz(officeTimezone)
        .format("MM/DD/YYYY HH:mm z");

      return formattedToOfficeTimezone;
    };

    const handleChangeDate = (date, field, setFieldValue, values) => {
      if (date && !isNaN(date.getTime())) {
        setFieldValue(field, date);
        if (field === "startDate") {
          if (date > values.endDate) {
            setFieldValue("endDate", date);
            setFieldValue("duration", 0);
          } else {
            const duration = differenceInHours(values.endDate, date);
            setFieldValue("duration", duration);
          }
        } else if (field === "endDate") {
          if (date < values.startDate) {
            setFieldValue("startDate", date);
            setFieldValue("duration", 0);
          } else {
            const duration = differenceInHours(date, values.startDate);
            setFieldValue("duration", duration);
          }
        }
      }
    };

    const disableAddEmployees =
      !scheduleEndDate ||
      (scheduleEndDate &&
        procedureRequirement &&
        new Date(`${scheduleEndDate}T23:59:00`) <
          new Date(procedureRequirement.procedure.start));

    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <Grid
              container
              component="form"
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid container item justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2">Procedure Requirements</Typography>
                </Grid>
                <Grid item>
                  <IconButton color="secondary" onClick={closeDialog}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Formik
              enableReinitialize
              initialValues={{
                id: procedureRequirement ? procedureRequirement.id : "",
                startDate: procedureRequirement
                  ? new Date(procedureRequirement.procedure.start)
                  : selectedDate,
                endDate: procedureRequirement
                  ? new Date(procedureRequirement.procedure.end)
                  : selectedDateEndDate,
                procedureType: procedureRequirement
                  ? procedureRequirement.procedureType
                  : "",
                office: procedureRequirement
                  ? procedureRequirement.office.id
                  : "",
                duration: procedureRequirement
                  ? differenceInHours(
                      new Date(procedureRequirement.procedure.end),
                      new Date(procedureRequirement.procedure.start)
                    )
                  : 0,
                notes: procedureRequirement
                  ? procedureRequirement.procedure.notes
                  : "",
                shifts: procedureRequirement
                  ? formattedShifts
                  : [
                      {
                        id: "",
                        ShiftStart: selectedDate,
                        ShiftEnd: addHours(selectedDate, 4),
                        skills: [],
                      },
                    ],
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (values.id != "") {
                  handleUpdateProcedureRequirements(values);
                } else {
                  handleCreateProcedureRequirements(values);
                }
              }}
            >
              {({ values, handleChange, setFieldValue, errors, touched }) => (
                <Form>
                  <Grid container>
                    {!sameTimezoneAsOffice && (
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontWeight: "500" }}
                          className={classes.error}
                        >
                          **You are in a different timezone than your office.
                          Please enter the times in your local timezone and we
                          will display the time in your office timezone below
                          each field.
                        </Typography>
                      </Grid>
                    )}
                    <Grid container item spacing={2} style={{ marginTop: 16 }}>
                      <Grid item xs={4}>
                        <Typography variant="h5">Procedure Start</Typography>
                        <KeyboardDateTimePicker
                          // disableToolbar
                          // autoOk
                          // variant="inline"
                          inputVariant="outlined"
                          name="startDate"
                          format="MM/dd/yyyy HH:mm"
                          // id="current-date"
                          value={values.startDate}
                          onChange={(date) => {
                            handleChangeDate(
                              date,
                              "startDate",
                              setFieldValue,
                              values
                            );
                          }}
                          minDate={new Date()}
                          className={classes.input}
                          // ampm={false}
                          minutesStep={15}
                          //disabled={procedureRequirement ? true : false}
                        />
                        {!sameTimezoneAsOffice && (
                          <Typography style={{ fontWeight: "500" }}>
                            {getOffsetTime(values.startDate)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5">Procedure End</Typography>
                        <KeyboardDateTimePicker
                          // disableToolbar
                          // autoOk
                          // variant="inline"
                          inputVariant="outlined"
                          name="endDate"
                          format="MM/dd/yyyy HH:mm"
                          // id="current-date"
                          value={values.endDate}
                          onChange={(date) => {
                            handleChangeDate(
                              date,
                              "endDate",
                              setFieldValue,
                              values
                            );
                          }}
                          minDate={addHours(values.startDate, 4)}
                          className={classes.input}
                          // ampm={false}
                          minutesStep={15}
                          //disabled={procedureRequirement ? true : false}
                        />
                        {!sameTimezoneAsOffice && (
                          <Typography style={{ fontWeight: "500" }}>
                            {getOffsetTime(values.endDate)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {/* <Typography variant="h5">Timezone</Typography>
                        <Select 
                          variant="outlined"
                          id={'timezone'}
                          name={'timezone'}
                          value={values.timezone}
                          onChange={handleChange}
                          className={classes.input}
                          disabled={true}
                        >
                          {timezones.map(timezone => (
                            <MenuItem key={timezone} value={timezone}>
                              {timezone}
                            </MenuItem>
                          ))}
                        </Select> */}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5">
                          Select Mission Name
                        </Typography>
                        <Select
                          variant="outlined"
                          id="procedureType"
                          name="procedureType"
                          value={values.procedureType}
                          onChange={handleChange}
                          className={classes.input}
                        >
                          {procedureTypes.data.procedureTypes.map(
                            (type, index) => (
                              <MenuItem key={index} value={type}>
                                {type.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {errors && errors.procedureType && (
                          <Typography className="field-error" color="error">
                            {errors.procedureType}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5">Duration</Typography>
                        <TextField
                          variant="outlined"
                          name="Duration"
                          id="Duration"
                          type="number"
                          value={values.duration}
                          // onChange={handleChange}
                          disabled={true}
                          className={classes.input}
                          helperText="Calculated based on start and end times"
                        />
                        {errors && errors.duration && (
                          <Typography className="field-error" color="error">
                            {errors.duration}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}></Grid>

                      <Grid item xs={4}>
                        <Typography variant="h5">Office</Typography>

                        {user.isPrimaryParentOffice === true ? (
                          <Select
                            variant="outlined"
                            id="office"
                            name="office"
                            value={values.office}
                            onChange={handleChange}
                          >
                            <MenuItem
                              key={user.office.id}
                              value={user.office.id}
                            >
                              {user.office.name}
                            </MenuItem>
                            {childOffices.data &&
                              childOffices.data.getChildren.length > 0 &&
                              childOffices.data.getChildren.map((e, index) => (
                                <MenuItem key={index} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          <Typography variant="body1">
                            {user.office.name}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5">Notes</Typography>
                        <TextField
                          variant="outlined"
                          name="notes"
                          id="notes"
                          value={values.notes}
                          onChange={handleChange}
                          multiline
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: 16,
                        marginBottom: 16,
                        height: 280,
                        overflowY: "auto",
                      }}
                    >
                      <FieldArray name="shifts">
                        {({ push, remove }) => (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Shift Start</StyledTableCell>
                                <StyledTableCell>Shift End</StyledTableCell>
                                <StyledTableCell>Skills</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.shifts.length > 0 &&
                                values.shifts.map((row, index) => (
                                  <TableRow key={index}>
                                    <StyledTableCell
                                      style={{ display: "none" }}
                                    >
                                      <Typography
                                        id={`shifts.${index}.id`}
                                        name={`shifts.${index}.id`}
                                        value={row.id}
                                      >
                                        {row.id}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <KeyboardDateTimePicker
                                        // disableToolbar
                                        // autoOk
                                        // variant="inline"
                                        inputVariant="outlined"
                                        id={`shifts.${index}.ShiftStart`}
                                        name={`shifts.${index}.ShiftStart`}
                                        value={row.ShiftStart}
                                        format="MM/dd/yyyy HH:mm"
                                        // id="current-date"
                                        onChange={(date) => {
                                          handleChangeDate(
                                            date,
                                            `shifts.${index}.ShiftStart`,
                                            setFieldValue,
                                            values
                                          );
                                        }}
                                        minDate={values.startDate}
                                        maxDate={values.endDate}
                                        minDateMessage="Date cannot be before mission starts"
                                        maxDateMessage="Date cannot be after mission ends"
                                        className={classes.input}
                                        minutesStep={15}
                                      />
                                      {errors &&
                                        errors.shifts &&
                                        errors.shifts[index] &&
                                        errors.shifts[index].startDate && (
                                          <div className="field-error">
                                            {errors.shifts[index].startDate}
                                          </div>
                                        )}
                                      {!sameTimezoneAsOffice && (
                                        <Typography
                                          style={{ fontWeight: "500" }}
                                        >
                                          {getOffsetTime(row.ShiftStart)}
                                        </Typography>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <KeyboardDateTimePicker
                                        inputVariant="outlined"
                                        id={`shifts.${index}.ShiftEnd`}
                                        name={`shifts.${index}.ShiftEnd`}
                                        value={row.ShiftEnd}
                                        format="MM/dd/yyyy HH:mm"
                                        onChange={(date) => {
                                          handleChangeDate(
                                            date,
                                            `shifts.${index}.ShiftEnd`,
                                            setFieldValue,
                                            values
                                          );
                                        }}
                                        minDate={values.startDate}
                                        maxDate={values.endDate}
                                        minDateMessage="Date cannot be before mission starts or lesser than shift start time"
                                        maxDateMessage="Date cannot be after mission ends"
                                        className={classes.input}
                                        minutesStep={15}
                                      />
                                      {!sameTimezoneAsOffice && (
                                        <Typography
                                          style={{ fontWeight: "500" }}
                                        >
                                          {getOffsetTime(row.ShiftEnd)}
                                        </Typography>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <MultiSelect
                                        options={
                                          getSkills.data.skills.length > 0
                                            ? getSkills.data.skills
                                                .filter(
                                                  (e) =>
                                                    e.variety === "JOB_TYPE" ||
                                                    e.variety === "TRAINING"
                                                )
                                                .filter(
                                                  (e) =>
                                                    e.office === null ||
                                                    (e.office &&
                                                      e.office.id ===
                                                        user.office.id)
                                                )
                                            : [{ id: 0, name: "" }]
                                        }
                                        id={`shifts.${index}.skills`}
                                        name={`shifts.${index}.skills`}
                                        onChange={setFieldValue}
                                        val={row.skills}
                                        getOptionSelected={(option, value) =>
                                          option.name === value.name
                                        }
                                        getOptionLabel={(option) =>
                                          option?.name
                                        }
                                      />
                                      {errors &&
                                        errors.shifts &&
                                        errors.shifts[index] &&
                                        errors.shifts[index].skills && (
                                          <Typography
                                            className="field-error"
                                            color="error"
                                          >
                                            {errors.shifts[index].skills}
                                          </Typography>
                                        )}
                                    </StyledTableCell>
                                    {index !== 0 && (
                                      <StyledTableCell>
                                        <IconButton
                                          color="secondary"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </StyledTableCell>
                                    )}
                                  </TableRow>
                                ))}
                              <TableRow>
                                <StyledTableCell>
                                  <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                      push({
                                        id: "",
                                        ShiftStart:
                                          values.shifts.length > 0
                                            ? values.shifts[
                                                values.shifts.length - 1
                                              ].ShiftEnd
                                            : values.startDate,
                                        ShiftEnd:
                                          values.shifts.length > 0
                                            ? add(
                                                values.shifts[
                                                  values.shifts.length - 1
                                                ].ShiftEnd,
                                                { hours: 4 }
                                              )
                                            : values.startDate,
                                        skills: [],
                                      })
                                    }
                                  >
                                    Add Another
                                  </Button>
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                      {procedureRequirement ? (
                        <Grid item xs={4}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleAddEmployees}
                            disabled={disableAddEmployees}
                          >
                            Add Employees
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item xs={4}></Grid>
                      )}
                      <Grid
                        item
                        container
                        xs={6}
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        {procedureRequirement ? (
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={handleDeleteClick}
                            >
                              Delete
                            </Button>
                          </Grid>
                        ) : null}
                        <Grid item>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            {procedureRequirement ? "Update" : "Submit"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </MuiPickersUtilsProvider>
        <Snackbar
          open={showProcedureAddToast}
          autoHideDuration={2000}
          onClose={() => {
            setShowProcedureAddToast(false);
            closeDialog();
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => {
              setShowProcedureAddToast(false);
              closeDialog();
            }}
            severity="success"
          >
            <Typography>Procedure added!</Typography>
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
};

export default ProcedureRequirementForm;
