import React from 'react';
import { 
    Grid, Typography, Checkbox, 
    Button
} from '@material-ui/core';

const NotificationsFilterMenu = ({filters, handleFilterClick, handleResetFilters}) => {
    
    return ( 
        <Grid container direction='column' style={{width: 200}} spacing={1}>
            <Grid item align='right'>
                <Button color='primary' onClick={handleResetFilters}>Clear Filters</Button>
            </Grid>
            <Grid 
                item 
                container 
                alignItems='center' 
                onClick={() => handleFilterClick('Schedule')} 
                style={{marginTop: -5, marginBottom: -5}}
            >
                <Grid item>
                    <Checkbox checked={filters.indexOf('Schedule') !== -1}/>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>Schedule</Typography>
                </Grid>
            </Grid>
            <Grid 
                item 
                container 
                alignItems='center' 
                onClick={() => handleFilterClick('Shift Switch')} 
                style={{marginTop: -5, marginBottom: -5}}
            >
                <Grid item>
                    <Checkbox checked={filters.indexOf('Shift Switch') !== -1}/>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>Shift Switch</Typography>
                </Grid>
            </Grid>
            <Grid 
                item 
                container 
                alignItems='center' 
                onClick={() => handleFilterClick('Time Off')} 
                style={{marginTop: -5, marginBottom: -5}}
            >
                <Grid item>
                    <Checkbox checked={filters.indexOf('Time Off') !== -1}/>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>Time Off</Typography>
                </Grid>
            </Grid>
            <Grid 
                item 
                container 
                alignItems='center' 
                onClick={() => handleFilterClick('General')} 
                style={{marginTop: -5, marginBottom: -5}}
            >
                <Grid item>
                    <Checkbox checked={filters.indexOf('General') !== -1}/>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>General</Typography>
                </Grid>
            </Grid>
            <Grid 
                item 
                container 
                alignItems='center' 
                onClick={() => handleFilterClick('All')} 
                style={{marginTop: -5, marginBottom: -5}}
            >
                <Grid item>
                    <Checkbox checked={filters.indexOf('All') !== -1}/>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>All</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
 
export default NotificationsFilterMenu;