import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    disabled: {
        color: 'rgb(158,158,158)'
    }
}))

const PrefDesirability = ({value, indicator, disabled=false}) => {
    const classes = useStyles();

    let desirability;
    if (indicator) {
        if ((value < 0 && indicator.desirability === -2) ||
            (value > 0 && indicator.desirability === 2)
        ) {
            desirability = 'High';
        } else if ((value === -1 && indicator.desirability === -1) ||
            (value === 1 && indicator.desirability === 1) 
        ) {
            desirability = 'High';
        } else if ((value < 0 && indicator.desirability === -1) ||
            (value > 0 && indicator.desirability === 1) 
        ) {
            desirability = 'Moderate';
        } else if (indicator.desirability || indicator.desirability === 0) {
            desirability = 'Low';
        } else {
            desirability = 'Not enough information';
        }
    } else {
        desirability = 'Not enough information';
    }
    
    return (  
        <Grid container >
            <Grid item xs={12}>
                <Typography className={disabled ? classes.disabled : null}>
                    Preference Popularity: 
                    <span style={{fontWeight: 'bold'}}> {desirability}</span>
                </Typography>
            </Grid>
        </Grid>
    );
}
 
export default PrefDesirability;