import React, { useEffect, useState } from "react";
import {
  Grid,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Checkbox,
  CircularProgress,
  withStyles,
  Container,
  Snackbar,
} from "@material-ui/core";
import {
  PERMISSIONS,
  CREATE_ABILITIES,
  DELETE_ABILITIES,
  ROLES,
  ABILITIES,
  NOTIFY_DEVELOPERS
} from "../../api/gqlQueries";
import { useQuery, useMutation } from "@apollo/client";
import MuiAlert from "@material-ui/lab/Alert";
import ErrorSnackBar from "../errors/errorSnackBar"

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
    borderTop: "1px solid lightgrey",
  },
}))(TableCell);

const permExists = (userrole, abilities, permname) => {
  let exist =
    abilities &&
    abilities.abilities &&
    abilities.abilities.length > 0 &&
    abilities.abilities.find(
      (e) => e.role.name === userrole && e.permission.name === permname
    );
  return !exist ? false : true;
};

function RolesandPermissions() {
  const environment = process.env.NODE_ENV
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false)
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS,
    {
      onError(error) {
        console.log(error)
      }
    }
  );
  const roles = useQuery(ROLES, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true)
      setErrorSnackBarMessage("We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again.");
      notifyDevelopers({variables: {message: "Error on ROLES Query. Environment: " + environment + ". Graphql " + error}});
    },
  });
  const allpermissions = useQuery(PERMISSIONS, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true)
      setErrorSnackBarMessage("We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again.");
      notifyDevelopers({variables: {message: "Error on PERMISSIONS Query. Environment: " + environment + ". Graphql " + error}});
    },
  });
  const abilities = useQuery(ABILITIES, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true)
      setErrorSnackBarMessage("We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again.");
      notifyDevelopers({variables: {message: "Error on ABILITIES Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [createAbilities] = useMutation(CREATE_ABILITIES, {
    onCompleted() {
      abilities.refetch();
      setsnackBarMessage("Permission successfully updated!");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setsnackBarMessage("Something went wrong. Please try again.");
      seterrorsnackbaropen(true);
      notifyDevelopers({variables: {message: "Error on CREATE_ABILITIES Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [deleteAbilities] = useMutation(DELETE_ABILITIES, {
    onCompleted() {
      abilities.refetch();
      setsnackBarMessage("Permission successfully updated!");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setsnackBarMessage("Something went wrong. Please try again.");
      seterrorsnackbaropen(true);
      notifyDevelopers({variables: {message: "Error on DELETE_ABILITIES Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);

  const [adminCalendar, SetAdminCalendar] = useState({});
  const [adminNotifications, SetAdminNotifications] = useState({});
  const [adminpreferences, SetAdminpreferences] = useState({});
  const [adminallRequests, SetAdminallRequests] = useState({});
  const [adminusersRequests, SetAdminusersRequests] = useState({});
  const [adminmanageUsers, SetAdminmanageUsers] = useState({});
  const [adminmanageOffices, SetAdminmanageOffices] = useState({});
  const [admingenerateSchedule, SetAdmingenerateSchedule] = useState({});
  const [adminapplicationSettings, SetAdminapplicationSettings] = useState({});

  const [managerCalendar, SetManagerCalendar] = useState({});
  const [managernotifications, Setmanagernotifications] = useState({});
  const [managerpreferences, Setmanagerpreferences] = useState({});
  const [managerallRequests, SetmanagerallRequests] = useState({});
  const [managerusersRequests, SetmanagerusersRequests] = useState({});
  const [managermanageUsers, SetmanagermanageUsers] = useState({});
  const [managermanageOffices, SetmanagermanageOffices] = useState({});
  const [managergenerateSchedule, SetmanagergenerateSchedule] = useState({});
  const [managerapplicationSettings, SetmanagerapplicationSettings] = useState(
    {}
  );

  const [schedulerCalendar, SetSchedulerCalendar] = useState({});
  const [schedulernotifications, Setschedulernotifications] = useState({});
  const [schedulerpreferences, Setschedulerpreferences] = useState({});
  const [schedulerallRequests, SetschedulerallRequests] = useState({});
  const [schedulerusersRequests, SetschedulerusersRequests] = useState({});
  const [schedulermanageUsers, SetschedulermanageUsers] = useState({});
  const [schedulermanageOffices, SetschedulermanageOffices] = useState({});
  const [schedulergenerateSchedule, SetschedulergenerateSchedule] = useState(
    {}
  );
  const [
    schedulerapplicationSettings,
    SetschedulerapplicationSettings,
  ] = useState({});

  const [employeecalendar, SetEmployeecalendar] = useState({});
  const [employeenotifications, Setemployeenotifications] = useState({});
  const [employeepreferences, Setemployeepreferences] = useState({});
  const [employeeallRequests, SetemployeeallRequests] = useState({});
  const [employeeusersRequests, SetemployeeusersRequests] = useState({});
  const [employeemanageUsers, SetemployeemanageUsers] = useState({});
  const [employeemanageOffices, SetemployeemanageOffices] = useState({});
  const [employeegenerateSchedule, SetemployeegenerateSchedule] = useState({});
  const [
    employeeapplicationSettings,
    SetemployeeapplicationSettings,
  ] = useState({});

  useEffect(() => {
    SetAdminCalendar(permExists("ADMIN", abilities.data, "calendar"));
    SetAdminNotifications(permExists("ADMIN", abilities.data, "notifications"));
    SetAdminpreferences(permExists("ADMIN", abilities.data, "preferences"));
    SetAdminallRequests(permExists("ADMIN", abilities.data, "allRequests"));
    SetAdminusersRequests(permExists("ADMIN", abilities.data, "usersRequests"));
    SetAdminmanageUsers(permExists("ADMIN", abilities.data, "manageUsers"));
    SetAdminmanageOffices(permExists("ADMIN", abilities.data, "manageOffices"));
    SetAdmingenerateSchedule(
      permExists("ADMIN", abilities.data, "generateSchedule")
    );
    SetAdminapplicationSettings(
      permExists("ADMIN", abilities.data, "applicationSettings")
    );

    SetManagerCalendar(permExists("MANAGER", abilities.data, "calendar"));

    Setmanagernotifications(
      permExists("MANAGER", abilities.data, "notifications")
    );
    Setmanagerpreferences(permExists("MANAGER", abilities.data, "preferences"));
    SetmanagerallRequests(permExists("MANAGER", abilities.data, "allRequests"));
    SetmanagerusersRequests(
      permExists("MANAGER", abilities.data, "usersRequests")
    );
    SetmanagermanageUsers(permExists("MANAGER", abilities.data, "manageUsers"));
    SetmanagermanageOffices(
      permExists("MANAGER", abilities.data, "manageOffices")
    );
    SetmanagergenerateSchedule(
      permExists("MANAGER", abilities.data, "generateSchedule")
    );
    SetmanagerapplicationSettings(
      permExists("MANAGER", abilities.data, "applicationSettings")
    );

    SetSchedulerCalendar(permExists("SCHEDULER", abilities.data, "calendar"));

    Setschedulernotifications(
      permExists("SCHEDULER", abilities.data, "notifications")
    );
    Setschedulerpreferences(
      permExists("SCHEDULER", abilities.data, "preferences")
    );
    SetschedulerallRequests(
      permExists("SCHEDULER", abilities.data, "allRequests")
    );
    SetschedulerusersRequests(
      permExists("SCHEDULER", abilities.data, "usersRequests")
    );
    SetschedulermanageUsers(
      permExists("SCHEDULER", abilities.data, "manageUsers")
    );
    SetschedulermanageOffices(
      permExists("SCHEDULER", abilities.data, "manageOffices")
    );
    SetschedulergenerateSchedule(
      permExists("SCHEDULER", abilities.data, "generateSchedule")
    );
    SetschedulerapplicationSettings(
      permExists("SCHEDULER", abilities.data, "applicationSettings")
    );

    SetEmployeecalendar(permExists("EMPLOYEE", abilities.data, "calendar"));

    Setemployeenotifications(
      permExists("EMPLOYEE", abilities.data, "notifications")
    );
    Setemployeepreferences(
      permExists("EMPLOYEE", abilities.data, "preferences")
    );
    SetemployeeallRequests(
      permExists("EMPLOYEE", abilities.data, "allRequests")
    );
    SetemployeeusersRequests(
      permExists("EMPLOYEE", abilities.data, "usersRequests")
    );
    SetemployeemanageUsers(
      permExists("EMPLOYEE", abilities.data, "manageUsers")
    );
    SetemployeemanageOffices(
      permExists("EMPLOYEE", abilities.data, "manageOffices")
    );
    SetemployeegenerateSchedule(
      permExists("EMPLOYEE", abilities.data, "generateSchedule")
    );
    SetemployeeapplicationSettings(
      permExists("EMPLOYEE", abilities.data, "applicationSettings")
    );
  }, [!abilities.loading]);

  const handleCreatePermission = (role, permname) => {
    let roleid =
      !roles.loading && roles.data.roles.find((e) => e.name === role);
    let permissionid =
      !allpermissions.loading &&
      allpermissions.data.permissions.find((e) => e.name === permname);
    createAbilities({
      variables: {
        input: {
          role: parseInt(roleid.id),
          permission: parseInt(permissionid.id),
        },
      },
    });
  };

  const handleDeletePermission = (userrole, permname) => {
    let a =
      !abilities.loading &&
      abilities.data.abilities.find(
        (e) => e.role.name === userrole && e.permission.name === permname
      );
    deleteAbilities({
      variables: {
        id: parseInt(a.id),
      },
    });
  };
  return abilities.loading ? (
    <CircularProgress color="primary" />
  ) : (
    <Container>
      <ErrorSnackBar
        open={snackBarOpen}
        message={errorSnackBarMessage}
        close={() => setSnackBarOpen(false)}
      />
      <Snackbar
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={() => setsnackbaropen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => {
            setsnackbaropen(false);
            setsnackBarMessage("");
          }}
          severity="success"
        >
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorsnackbaropen}
        autoHideDuration={6000}
        onClose={() => seterrorsnackbaropen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert onClose={() => seterrorsnackbaropen(false)} severity="error">
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
      <Grid container direction="row" display="flex" spacing={2}>
        <Grid item xs={12}>
          <Box mt={3} mb={3}>
            <Typography variant="h3">Roles and Permissions</Typography>
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="subtitle1">User Role</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">Calendar</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">Notifications</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">Preferences</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">
                    Manage All Requests
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">
                    Only User's Requests
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">Manage Users</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">Manage Offices</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">Generate Schedule</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="subtitle1">
                    Application Settings
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="subtitle1">Admin</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminCalendar ? true : false}
                    onChange={(e) => {
                      SetAdminCalendar(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "calendar")
                        : handleDeletePermission("ADMIN", "calendar");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminNotifications ? true : false}
                    onChange={(e) => {
                      SetAdminNotifications(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "notifications")
                        : handleDeletePermission("ADMIN", "notifications");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminpreferences ? true : false}
                    onChange={(e) => {
                      SetAdminpreferences(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "preferences")
                        : handleDeletePermission("ADMIN", "preferences");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminallRequests ? true : false}
                    onChange={(e) => {
                      SetAdminallRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "allRequests")
                        : handleDeletePermission("ADMIN", "allRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminusersRequests ? true : false}
                    onChange={(e) => {
                      SetAdminusersRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "usersRequests")
                        : handleDeletePermission("ADMIN", "usersRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminmanageUsers ? true : false}
                    onChange={(e) => {
                      SetAdminmanageUsers(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "manageUsers")
                        : handleDeletePermission("ADMIN", "manageUsers");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminmanageOffices ? true : false}
                    onChange={(e) => {
                      SetAdminmanageOffices(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "manageOffices")
                        : handleDeletePermission("ADMIN", "manageOffices");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={admingenerateSchedule ? true : false}
                    onChange={(e) => {
                      SetAdmingenerateSchedule(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "generateSchedule")
                        : handleDeletePermission("ADMIN", "generateSchedule");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={adminapplicationSettings ? true : false}
                    onChange={(e) => {
                      SetAdminapplicationSettings(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("ADMIN", "applicationSettings")
                        : handleDeletePermission(
                            "ADMIN",
                            "applicationSettings"
                          );
                    }}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="subtitle1">Manager</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managerCalendar ? true : false}
                    onChange={(e) => {
                      SetManagerCalendar(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "calendar")
                        : handleDeletePermission("MANAGER", "calendar");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managernotifications ? true : false}
                    onChange={(e) => {
                      Setmanagernotifications(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "notifications")
                        : handleDeletePermission("MANAGER", "notifications");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managerpreferences ? true : false}
                    onChange={(e) => {
                      Setmanagerpreferences(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "preferences")
                        : handleDeletePermission("MANAGER", "preferences");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managerallRequests ? true : false}
                    onChange={(e) => {
                      SetmanagerallRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "allRequests")
                        : handleDeletePermission("MANAGER", "allRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managerusersRequests ? true : false}
                    onChange={(e) => {
                      SetmanagerusersRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "usersRequests")
                        : handleDeletePermission("MANAGER", "usersRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managermanageUsers ? true : false}
                    onChange={(e) => {
                      SetmanagermanageUsers(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "manageUsers")
                        : handleDeletePermission("MANAGER", "manageUsers");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managermanageOffices ? true : false}
                    onChange={(e) => {
                      SetmanagermanageOffices(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "manageOffices")
                        : handleDeletePermission("MANAGER", "manageOffices");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managergenerateSchedule ? true : false}
                    onChange={(e) => {
                      SetmanagergenerateSchedule(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("MANAGER", "generateSchedule")
                        : handleDeletePermission("MANAGER", "generateSchedule");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={managerapplicationSettings ? true : false}
                    onChange={(e) => {
                      SetmanagerapplicationSettings(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission(
                            "MANAGER",
                            "applicationSettings"
                          )
                        : handleDeletePermission(
                            "MANAGER",
                            "applicationSettings"
                          );
                    }}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="subtitle1">Scheduler</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulerCalendar ? true : false}
                    onChange={(e) => {
                      SetSchedulerCalendar(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "calendar")
                        : handleDeletePermission("SCHEDULER", "calendar");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulernotifications ? true : false}
                    onChange={(e) => {
                      Setschedulernotifications(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "notifications")
                        : handleDeletePermission("SCHEDULER", "notifications");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulerpreferences ? true : false}
                    onChange={(e) => {
                      Setschedulerpreferences(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "preferences")
                        : handleDeletePermission("SCHEDULER", "preferences");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulerallRequests ? true : false}
                    onChange={(e) => {
                      SetschedulerallRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "allRequests")
                        : handleDeletePermission("SCHEDULER", "allRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulerusersRequests ? true : false}
                    onChange={(e) => {
                      SetschedulerusersRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "usersRequests")
                        : handleDeletePermission("SCHEDULER", "usersRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulermanageUsers ? true : false}
                    onChange={(e) => {
                      SetschedulermanageUsers(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "manageUsers")
                        : handleDeletePermission("SCHEDULER", "manageUsers");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulermanageOffices ? true : false}
                    onChange={(e) => {
                      SetschedulermanageOffices(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("SCHEDULER", "manageOffices")
                        : handleDeletePermission("SCHEDULER", "manageOffices");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulergenerateSchedule ? true : false}
                    onChange={(e) => {
                      SetschedulergenerateSchedule(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission(
                            "SCHEDULER",
                            "generateSchedule"
                          )
                        : handleDeletePermission(
                            "SCHEDULER",
                            "generateSchedule"
                          );
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={schedulerapplicationSettings ? true : false}
                    onChange={(e) => {
                      SetschedulerapplicationSettings(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission(
                            "SCHEDULER",
                            "applicationSettings"
                          )
                        : handleDeletePermission(
                            "SCHEDULER",
                            "applicationSettings"
                          );
                    }}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="subtitle1">Employee</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeecalendar ? true : false}
                    onChange={(e) => {
                      SetEmployeecalendar(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "calendar")
                        : handleDeletePermission("EMPLOYEE", "calendar");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeenotifications ? true : false}
                    onChange={(e) => {
                      Setemployeenotifications(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "notifications")
                        : handleDeletePermission("EMPLOYEE", "notifications");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeepreferences ? true : false}
                    onChange={(e) => {
                      Setemployeepreferences(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "preferences")
                        : handleDeletePermission("EMPLOYEE", "preferences");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeeallRequests ? true : false}
                    onChange={(e) => {
                      SetemployeeallRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "allRequests")
                        : handleDeletePermission("EMPLOYEE", "allRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeeusersRequests ? true : false}
                    onChange={(e) => {
                      SetemployeeusersRequests(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "usersRequests")
                        : handleDeletePermission("EMPLOYEE", "usersRequests");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeemanageUsers ? true : false}
                    onChange={(e) => {
                      SetemployeemanageUsers(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "manageUsers")
                        : handleDeletePermission("EMPLOYEE", "manageUsers");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeemanageOffices ? true : false}
                    onChange={(e) => {
                      SetemployeemanageOffices(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "manageOffices")
                        : handleDeletePermission("EMPLOYEE", "manageOffices");
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeegenerateSchedule ? true : false}
                    onChange={(e) => {
                      SetemployeegenerateSchedule(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission("EMPLOYEE", "generateSchedule")
                        : handleDeletePermission(
                            "EMPLOYEE",
                            "generateSchedule"
                          );
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Checkbox
                    color="primary"
                    checked={employeeapplicationSettings ? true : false}
                    onChange={(e) => {
                      SetemployeeapplicationSettings(e.target.checked);
                      e.target.checked
                        ? handleCreatePermission(
                            "EMPLOYEE",
                            "applicationSettings"
                          )
                        : handleDeletePermission(
                            "EMPLOYEE",
                            "applicationSettings"
                          );
                    }}
                  />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RolesandPermissions;
