import React, { useState } from 'react';
import { 
    Grid, IconButton, Typography, 
    Button, MobileStepper, makeStyles 
} from '@material-ui/core';
import { mapPrefsToBackend, handleMostImportantChanges } from '../../helpers/mapPreferences';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import easybutton from '../../assets/quizImages/easybutton.png';
import { userVar } from '../../cache';
import ShiftQuestion from './quizScreens/ShiftQuestion';
import ShiftsLike from './quizScreens/ShiftsLike';
import ShiftsDislike from './quizScreens/ShiftsDislike';
import DaysOff from './quizScreens/DaysOff';
import DaysMostPrefer from './quizScreens/DaysMostPrefer';
import DaysLeastPrefer from './quizScreens/DaysLeastPrefer';
import Overtime from './quizScreens/Overtime';
import Summary from './quizScreens/Summary';
import Final from './quizScreens/Final';
import { Formik } from 'formik';
import MostImportantCategories from './quizScreens/MostImportantCategories';
import { useMutation } from '@apollo/client';
import { UPDATE_PREFS_SETTINGS } from '../../api/gqlQueries';

const useStyles = makeStyles(() => ({
    button: {
        width: 100
    }
}));

const PreferenceQuiz = (props) => {
    const classes = useStyles();

    const {
        closeDialog, 
        shiftPreferences, 
        batchUpdatePreferences, 
        batchCreatePreferences,
        originalPrefs, 
        prefTypes,
        notifyDevelopers,
        setOpenSnackBar,
        setSnackBarMessage,
        environment
    } = props;

    const user = userVar();

    const [updateAlwaysOpenGuide] = useMutation(UPDATE_PREFS_SETTINGS, {
        onCompleted(data) {
            console.log(data);
            userVar({...user, alwaysOpenGuide: data.updateEmployee.employee.alwaysOpenGuide});
        },
        onError(error) {
            console.log(error)
            setOpenSnackBar(true);
            setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
            notifyDevelopers({variables: {message: "Error on UPDATE_PREFS_SETTINGS Mutation. Environment: " + environment + " Graphql " + error}});
        }
    });

    // const hiredForShift = mapShiftType[user.shiftType] ? mapShiftType[user.shiftType] : 'Any Shift';
    const hiredForShift = user.shiftType;

    const categoriesInitial = hiredForShift === 'Any Shift'
        ? ['Shifts Like', 'Shifts Dislike', 'Days Off', 'Days Most Prefer', 'Days Least Prefer', 'Overtime']
        : ['Shift Question', 'Days Off', 'Days Most Prefer', 'Days Least Prefer', 'Overtime'];

    const [activeStep, setActiveStep] = useState(0);
    const [categories, setCategories] = useState(categoriesInitial);

    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    const stepOrder = {};

    const moveToStep = (category) => {
        const step = parseInt(stepOrder[category]);
        setActiveStep(step);
    };

    const handleWorkAdditionalClick = (value, setFieldValue) => {
        if (value) {
            let newSteps = [...categories];
            newSteps.splice(1, 0, 'Shifts Like');
            newSteps.splice(2, 0, 'Shifts Dislike');
            setCategories(newSteps);
            setFieldValue('workAdditionalShifts', true);
        } else {
            let newSteps = ['Shift Question', 'Days Off', 'Days Most Prefer', 'Days Least Prefer', 'Overtime'];
            setCategories(newSteps);
            setFieldValue('workAdditionalShifts', false);
        }
    }

    const getSteps = () => {
        return 4 + categories.length;
    };

    const getContent = (values, setFieldValue) => {
        const dynamicSteps = {};
        categories.forEach((category, index) => {
            if (category === 'Shift Question'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <ShiftQuestion 
                        hiredForShift={hiredForShift}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleWorkAdditionalClick={handleWorkAdditionalClick}
                    />
                );
            } else if (category === 'Shifts Like'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <ShiftsLike 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            } else if (category === 'Shifts Dislike'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <ShiftsDislike 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            } else if (category === 'Days Off'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <DaysOff 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            } else if (category === 'Days Most Prefer'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <DaysMostPrefer 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            } else if (category === 'Days Least Prefer'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <DaysLeastPrefer 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            } else if (category === 'Overtime'){
                stepOrder[category] = index + 1;
                dynamicSteps[index + 1] = (
                    <Overtime 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            }
        });

        switch (activeStep) {
            case 0:
                return (
                    <Grid container item direction='column' spacing={5} style={{padding: 25}}>
                        <Grid item>
                            <Typography style={{fontSize: 24}} align='center'>
                                Want the best possible schedule for you? We do, too.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{fontSize: 24}} align='center'>
                                We've made it easy by breaking your preferences down into a few questions. Click the easy button get started!
                            </Typography>
                        </Grid>
                        <Grid item align='center'>
                            <img 
                                alt='easy button' 
                                src={easybutton} 
                                style={{height: 175, cursor: 'pointer', marginTop: 48}}
                                onClick={handleNext}
                            />
                        </Grid>
                    </Grid>
                );
            case 1 + categories.length:
                return (
                    <Summary 
                        hiredForShift={hiredForShift}
                        values={values}
                        moveToStep={moveToStep}
                    />
                );
            case 2 + categories.length:
                return (
                    <MostImportantCategories 
                        values={values} 
                        setFieldValue={setFieldValue}
                    />
                );
            case 3 + categories.length:
                return (
                    <Final 
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            default:
                break;
        }

        for (let i=1; i<=categories.length; i++) {
            if (activeStep === i && dynamicSteps[i]) {
                return dynamicSteps[i];
            }
        }
    };

    const getNextButton = (handleSubmit) => {
        if (activeStep === 0) {
            return <Typography></Typography>;
        } else if (activeStep < getSteps() - 1){
            return <Button 
                color='primary' 
                variant='contained' 
                onClick={handleNext} 
                className={classes.button}
            >
                Next <KeyboardArrowRight />
            </Button>
        } else {
            return <Button 
                color='primary' 
                variant='contained' 
                onClick={handleSubmit} 
                className={classes.button}
            >
                Save
            </Button>
        }
    };

    return (
        <Formik
            initialValues={{
                workAdditionalShifts: shiftPreferences.workAdditionalShifts === 1 ? true : false,
                
                day: shiftPreferences.day || 0,
                night: shiftPreferences.night || 0,
                swing: shiftPreferences.swing || 0,
                
                monday: shiftPreferences.monday || 0,
                tuesday: shiftPreferences.tuesday || 0,
                wednesday: shiftPreferences.wednesday || 0,
                thursday: shiftPreferences.thursday || 0,
                friday: shiftPreferences.friday || 0,
                saturday: shiftPreferences.saturday || 0,
                sunday: shiftPreferences.sunday || 0,

                oneDay: shiftPreferences.oneDay || 0,
                twoDays: shiftPreferences.twoDays || 0,
                threeDays: shiftPreferences.threeDays || 0,
                fourDays: shiftPreferences.fourDays || 0,
                
                overtime: shiftPreferences.overtime || 0,

                alwaysOpenGuide: user.alwaysOpenGuide || false,
                mostImportant: []
            }}
            onSubmit={values => {
                const newValues = handleMostImportantChanges(values);
                newValues.workAdditionalShifts = values.workAdditionalShifts === true ? 1 : 0;
                newValues.overtime = values.overtime;

                const originalPrefsTypeIds = originalPrefs.map(preference => (
                    preference.type.id
                ));
                const prefTypesToBeCreated = prefTypes.filter(type => (
                    !originalPrefsTypeIds.includes(type.id)
                ));

                const backend = mapPrefsToBackend(
                    newValues, originalPrefs, prefTypesToBeCreated, parseInt(user.id)
                );

                if (user.alwaysOpenGuide !== values.alwaysOpenGuide) {
                    updateAlwaysOpenGuide({variables: {
                        id: parseInt(user.id), 
                        input: {
                            alwaysOpenGuide: values.alwaysOpenGuide
                        }
                    }});
                    userVar({...user, alwaysOpenGuide: values.alwaysOpenGuide});
                }

                if (backend.update.length > 0) {
                    batchUpdatePreferences({variables: {
                        input: backend.update
                    }});
                }
                
                if (backend.create.length > 0) {
                    batchCreatePreferences({variables: {
                        input: backend.create
                    }});
                }
            }}
            enableReinitialize
        >
            {({ handleSubmit, handleChange, values, errors, setFieldValue, handleReset }) => (
                <form >

                    <Grid container wrap='nowrap' direction='column' style={{height: 595, overflowY: 'auto', overflowX: 'hidden'}} spacing={1}>
                        <Grid item container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                {activeStep === getSteps() - 1
                                    ? <Typography variant='h3'>Preference Guide - Final Step</Typography>
                                    : <Typography variant='h3'>Preference Guide</Typography>
                                }
                            </Grid>
                            <Grid item>
                                <IconButton onClick={closeDialog}><CloseIcon /></IconButton>
                            </Grid>
                        </Grid>
                        {getContent(values, setFieldValue)}
                    </Grid>
                    <MobileStepper
                        variant="dots"
                        steps={getSteps()}
                        position="static"
                        activeStep={activeStep}
                        nextButton={getNextButton(handleSubmit)}
                        backButton={
                            activeStep === 0
                            ? <Typography></Typography>
                            : <Button color='primary' variant='outlined' onClick={handleBack} disabled={activeStep === 0} className={classes.button}>
                                <KeyboardArrowLeft /> Back
                            </Button>
                        }
                        style={{position: 'absolute', bottom: 10, width: '93%'}}
                    />
                </form>
            )}
        </Formik>
    );
};
 
export default PreferenceQuiz;