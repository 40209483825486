import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  Box,
  IconButton,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AnalyticsWidget from "./AnalyticsGraphWidget";
import { userVar } from "../../cache";
import {
  GET_SCHEDULE_PERIODS,
  NOTIFY_DEVELOPERS,
  GET_ALL_CHILD_OFFICES,
  SCHEDULE_QUALITY_ANALYTICS,
} from "../../api/gqlQueries";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";
import {
  calculateMode,
  calculateAverage,
  calculateMedian,
} from "../../helpers/analyticsHelpers";
import ScheduleQualityList from "./ScheduleQualityList";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 225,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
    borderTop: "1px solid lightgrey",
  },
}))(TableCell);

/* Displays schedule quality graphs for a given schedule period.*/

function AnalyticsOverview() {
  const classes = useStyles();

  const user = userVar();
  const environment = process.env.NODE_ENV;
  const [showDialog, SetShowDialog] = useState(false);
  const [averageScheduleQuality, SetAverageScheduleQuality] = useState(true);
  const [medianScheduleQuality, SetMedianScheduleQuality] = useState(true);
  const [modeScheduleQuality, SetModeScheduleQuality] = useState(true);
  const [scheduleQualityBoxChart, SetScheduleQualityBoxChart] = useState(true);
  const [employeeScheduleQuality, SetEmployeeScheduleQuality] = useState(false);
  const [officeId, SetOfficeId] = useState(user.office.id);
  const [allSchedulePeriods, SetallSchedulePeriods] = useState([]);
  const [selectSchedulePeriod, SetSelectSchedulePeriod] = useState("0");
  const [fitnesses, SetFitnesses] = useState([]);
  const [avgchartType, SetAvgChartType] = useState("0");
  const [medianchartType, SetMedianChartType] = useState("0");
  const [modechartType, SetModeChartType] = useState("0");
  const [empChartType, SetEmpChartType] = useState("1");
  const [widgetSize, SetWidgetSize] = useState(0);
  const [graphtoDisplay, SetGraphtoDisplay] = useState("0");
  const [selectedChildOffice, SetSelectedChildOffice] = useState("0");

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const allschedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onCompleted(d) {
      SetallSchedulePeriods(d.schedulePeriods);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SCHEDULE_PERIODS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const scheduleFitness = useQuery(SCHEDULE_QUALITY_ANALYTICS, {
    variables: {
      office:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onCompleted(d) {
      SetFitnesses(d.scheduleQualityAnalytics);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_FITNESS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allChildOffices = useQuery(GET_ALL_CHILD_OFFICES, {
    variables: {
      parent: user.office.id,
    },
    onCompleted(d) {
      console.log(d);
    },
  });

  if (
    allschedulePeriods.loading ||
    scheduleFitness.loading ||
    allChildOffices.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    let a = [];
    let b = [];
    let scores = [];
    if (selectSchedulePeriod === "0" || selectSchedulePeriod === 0) {
      const sorted = [
        ...allSchedulePeriods.filter(
          (e) => e.status != "READY" && e.status != "OPEN"
        ),
      ];
      sorted.sort((a, b) => new Date(a.start) - new Date(b.start));
      sorted.length > 0 &&
        sorted.map((x) => {
          a = [];
          b = [];
          if (fitnesses.length > 0) {
            fitnesses
              .filter(
                (m) => m.scheduleStart === x.start && m.scheduleEnd === x.end
              )
              .map((e) => {
                a.push(parseFloat(e.score).toFixed(2) * 100);
                // b.push(parseFloat(e.maxScore).toFixed(2));
              });
            scores.push({
              id: x.id,
              schedulePeriod:
                format(new Date(`${x.start}T08:00:00`), "dd MMM yyyy") +
                " to " +
                format(new Date(`${x.end}T08:00:00`), "dd MMM yyyy"),
              averageScore: calculateAverage(a),
              medianScore: calculateMedian(a),
              modeScore: calculateMode(a),
              //maxAverage: calculateAverage(b),
              scheduleQuality: a,
            });
          }
        });
      scores.sort((a, b) => parseInt(a.id) - parseInt(b.id));
    } else {
      let sp =
        allSchedulePeriods.length > 0 &&
        allSchedulePeriods
          .filter((e) => e.status != "READY" && e.status != "OPEN")
          .find((x) => x.id === selectSchedulePeriod);
      if (fitnesses.length > 0 && sp) {
        a = [];
        b = [];
        fitnesses
          .filter(
            (m) => m.scheduleStart === sp.start && m.scheduleEnd === sp.end
          )
          .map((e) => {
            a.push(parseFloat(e.score).toFixed(2) * 100);
          });
        scores.push({
          id: sp.id,
          schedulePeriod:
            format(new Date(`${sp.start}T08:00:00`), "dd MMM yyyy") +
            " to " +
            format(new Date(`${sp.end}T08:00:00`), "dd MMM yyyy"),
          averageScore: calculateAverage(a),
          medianScore: calculateMedian(a),
          modeScore: calculateMode(a),
          scheduleQuality: a,
        });
        scores.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      }
    }

    const averageFitnessData = [
      {
        marker: { color: "#f15a25" },
        responsive: true,
        type:
          avgchartType === "0" || avgchartType === "1" || avgchartType === "2"
            ? "scatter"
            : avgchartType === "3"
            ? "bar"
            : null,
        fill: avgchartType === "2" ? "tonexty" : null,
        x:
          scores.length > 0 &&
          scores.filter((x) => x.id != "").map((x) => x.schedulePeriod),
        y:
          scores.length > 0 &&
          scores.filter((x) => x.id != "").map((x) => x.averageScore),
      },
    ];

    const modeFitnessData = [
      {
        marker: { color: "#f15a25" },
        responsive: true,
        type:
          modechartType === "0" ||
          modechartType === "1" ||
          modechartType === "2"
            ? "scatter"
            : modechartType === "3"
            ? "bar"
            : null,
        fill: modechartType === "2" ? "tonexty" : null,
        x:
          scores.length > 0 &&
          scores.filter((x) => x.id != "").map((x) => x.schedulePeriod),
        y:
          scores.length > 0 &&
          scores.filter((x) => x.id != "").map((x) => x.modeScore),
      },
    ];

    const medianFitnessData = [
      {
        marker: { color: "#f15a25" },
        responsive: true,
        type:
          medianchartType === "0" ||
          medianchartType === "1" ||
          medianchartType === "2"
            ? "scatter"
            : medianchartType === "3"
            ? "bar"
            : null,
        fill: medianchartType === "2" ? "tonexty" : null,
        x:
          scores.length > 0 &&
          scores.filter((x) => x.id != "").map((x) => x.schedulePeriod),
        y:
          scores.length > 0 &&
          scores.filter((x) => x.id != "").map((x) => x.medianScore),
      },
    ];

    const boxandWhiskerData =
      scores.length > 0 &&
      scores
        .filter((m) => m.id != "")
        .map((e) => {
          return {
            type: "box",
            marker: { color: "#f15a25" },
            name: e.schedulePeriod,
            y: e.scheduleQuality,
            responsive: true,
          };
        });

    const employeeScheduleQData = [
      {
        marker: { color: "#f15a25" },
        type:
          empChartType === "0" || empChartType === "1" || empChartType === "2"
            ? "scatter"
            : empChartType === "3"
            ? "bar"
            : null,
        fill: empChartType === "2" ? "tonexty" : null,
        x:
          fitnesses.length > 0 &&
          fitnesses.map(
            (e) => e.employee.firstName + " " + e.employee.lastName
          ),
        y:
          fitnesses.length > 0 &&
          fitnesses.map((e) => parseFloat(e.ratio).toFixed(2) * 100),
      },
    ];

    const panels = [
      {
        id: 0,
        gridSize: 4,
        open: averageScheduleQuality,
        SetOpen: SetAverageScheduleQuality,
        data: averageFitnessData,
        chartType: avgchartType,
        SetChartType: SetAvgChartType,
        header: "Average Schedule Quality",
      },
      {
        id: 1,
        gridSize: 4,
        open: medianScheduleQuality,
        SetOpen: SetMedianScheduleQuality,
        data: medianFitnessData,
        chartType: medianchartType,
        SetChartType: SetMedianChartType,
        header: "Median Schedule Quality",
      },
      {
        id: 2,
        gridSize: 4,
        open: modeScheduleQuality,
        SetOpen: SetModeScheduleQuality,
        data: modeFitnessData,
        chartType: modechartType,
        SetChartType: SetModeChartType,
        header: "Schedule Quality - Mode",
      },
      {
        id: 3,
        gridSize: 8,
        open: scheduleQualityBoxChart,
        SetOpen: SetScheduleQualityBoxChart,
        data: boxandWhiskerData,
        chartType: "4",
        SetChartType: null,
        header: "Schedule Quality - Box Chart",
      },
      {
        id: 4,
        gridSize: 12,
        open: employeeScheduleQuality,
        SetOpen: SetEmployeeScheduleQuality,
        data: employeeScheduleQData,
        chartType: empChartType,
        SetChartType: SetEmpChartType,
        header: "Schedule Quality - Employees",
      },
    ];

    const handleAddWidget = () => {
      SetShowDialog(false);
      panels
        .filter((x) => x.id === graphtoDisplay)
        .map((e) => {
          e.SetOpen(true);
          if (widgetSize === 0) {
            e.gridSize = 4;
          } else {
            e.gridSize = widgetSize;
          }
        });
      SetWidgetSize(0);
      SetGraphtoDisplay("0");
    };

    return (
      <div>
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h3">Schedule Quality</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box m={2}>
              <Typography variant="h5">
                {user.isPrimaryParentOffice && selectedChildOffice != "0"
                  ? allChildOffices.data.getChildren.find(
                      (e) => e.id === selectedChildOffice
                    )?.name
                  : user.office.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box m={2}>
              <Select
                variant="outlined"
                value={selectSchedulePeriod}
                onChange={(e) => {
                  SetSelectSchedulePeriod(e.target.value);
                }}
                className={classes.select}
              >
                <MenuItem key="0" value="0">
                  All Schedule Periods
                </MenuItem>
                {allSchedulePeriods.length > 0 &&
                  allSchedulePeriods.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {format(new Date(`${e.start}T08:00:00`), "dd MMM yyyy") +
                        " to " +
                        format(new Date(`${e.end}T08:00:00`), "dd MMM yyyy")}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            {user.isPrimaryParentOffice && (
              <Box m={2}>
                <Select
                  variant="outlined"
                  value={selectedChildOffice}
                  onChange={(e) => {
                    SetSelectedChildOffice(e.target.value);
                  }}
                  className={classes.select}
                >
                  <MenuItem key="0" value="0">
                    Select Child Office
                  </MenuItem>
                  {allChildOffices.data.getChildren.length > 0 &&
                    allChildOffices.data.getChildren.map((e, index) => (
                      <MenuItem key={index} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}
          </Grid>
          <Grid item xs={4}></Grid>
          {/* <Grid item xs={2}>
            <Box style={{ textAlign: "right" }}>
              <Button
                color="primary"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => SetShowDialog(true)}
              >
                Add Panel
              </Button>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h5">Overview</Typography>
              <br />
              <br />
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Schedule Period</StyledTableCell>
                    <StyledTableCell>Schedule Quality</StyledTableCell>
                    <StyledTableCell>
                      % Change from the Last Schedule Period
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scores &&
                    scores.length > 0 &&
                    scores
                      // .slice(1)
                      .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                      .map((e, index, array) => {
                        const prevElement = array[index - 1];
                        const prevElementScheduleQuality =
                          prevElement &&
                          calculateAverage(prevElement.scheduleQuality);
                        const currentElementScheduleQuality = calculateAverage(
                          e.scheduleQuality
                        );
                        return (
                          <TableRow key={index}>
                            <StyledTableCell>
                              {e.schedulePeriod}
                            </StyledTableCell>
                            <StyledTableCell>
                              {calculateAverage(e.scheduleQuality) + " %"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {index > 0 &&
                                (
                                  currentElementScheduleQuality -
                                  prevElementScheduleQuality
                                ).toFixed(2) + " %"}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          {panels.map((e, index) => (
            <AnalyticsWidget
              key={index}
              gridSize={e.gridSize}
              open={e.open}
              SetOpen={e.SetOpen}
              data={e.data}
              chartType={e.chartType}
              SetChartType={e.SetChartType}
              header={e.header}
              layout={{
                showlegend: e.chartType === "4" && false,
                yaxis: {
                  rangemode: "tozero",
                },
              }}
            />
          ))}
          <ScheduleQualityList
            officeId={officeId}
            schedulePeriod={selectSchedulePeriod}
          />
        </Grid>

        <Dialog open={showDialog} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 20, overflowX: "hidden", textAlign: "center" }}
          >
            <Box style={{ textAlign: "right" }}>
              <IconButton onClick={() => SetShowDialog(false)} color="primary">
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Select
                  variant="outlined"
                  value={widgetSize}
                  onChange={(e) => SetWidgetSize(parseInt(e.target.value))}
                >
                  <MenuItem key={0} value={0}>
                    Select Size
                  </MenuItem>
                  <MenuItem key={1} value={4}>
                    Small
                  </MenuItem>
                  <MenuItem key={2} value={8}>
                    Medium
                  </MenuItem>
                  <MenuItem key={3} value={12}>
                    Large
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Select
                  variant="outlined"
                  value={graphtoDisplay}
                  onChange={(e) => SetGraphtoDisplay(e.target.value)}
                >
                  <MenuItem key="0" value="0">
                    Select Graph to display
                  </MenuItem>
                  {panels.length > 0 &&
                    panels
                      .filter((e) => !e.open)
                      .map((x, index) => (
                        <MenuItem key={index} value={x.id}>
                          {x.header}
                        </MenuItem>
                      ))}
                </Select>
              </Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddWidget}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AnalyticsOverview;
