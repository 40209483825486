import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { 
    GET_PROCEDURE_REQUIREMENT, GET_SHIFTS_WITH_RANGE 
} from '../../api/gqlQueries';
import MangAddEmployees from './MangAddEmployees';
import ProcedureRequirementForm from './ProcedureRequirementForm';
import { userVar } from '../../cache';
import { sub, add } from 'date-fns';

const AddProcedure = (props) => {
    const { 
        closeDialog,
        event,
        setProcedureRequirementEvent,
        refetch,
        setToast,
        setShowToast,
        setErrorToast,
        setShowErrorToast,
        scheduleEndDate,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,
    } = props;

    const user = userVar();

    const [addEmployees, setAddEmployees] = useState(false);
    const [availableShifts, setAvailableShifts] = useState([]);
    const [constraints, setConstraints] = useState([]);

    const [getShifts] = useLazyQuery(
        GET_SHIFTS_WITH_RANGE, {
            onCompleted(data) {
                console.log(data)
                const shifts = data.shifts.map(shift => {
                return {
                    id: shift.id,
                    start: new Date(shift.start),
                    end: new Date(shift.end)
                }
                });
                setAvailableShifts(shifts);
            },
            onError(error) {
                console.log(error);
                setOpenSnackBar(true);
                setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
                notifyDevelopers({variables: {message: "Error on GET_SHIFTS_WITH_RANGE lazyQuery. Environment: " + environment + ". Graphql " + error}});
            },

    });

    const [
        getProcedure, 
        {data, called, loading}
      ] = useLazyQuery(GET_PROCEDURE_REQUIREMENT, {
        onCompleted(data) {
            console.log(data);
            const procedure = data.procedureRequirements[0].procedure;
            const start = sub(new Date(procedure.start), {hours: 4}).toISOString();
            const end = add(new Date(procedure.end), {hours: 4}).toISOString();
        
            getShifts({variables: {
                officeId: parseInt(user.office.id),
                rangeStart: start,
                rangeEnd: end
            }});
        },
        onError(error) {
            console.log(error);
            setOpenSnackBar(true);
            setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
            notifyDevelopers({variables: {message: "Error on GET_PROCEDURE_REQUIREMENT lazyQuery. Environment: " + environment + ". Graphql " + error}});
        },
    });
    
    useEffect(() => {
        if (event && event.procedureRequirementId) {
            getProcedure({
                variables: {id: parseInt(event.procedureRequirementId)}
            });
        }
    }, []);

    const closeForm = () => {
        setProcedureRequirementEvent();
        closeDialog();
    };
    
    if(called && loading) {
        return <CircularProgress color='primary' />
    } else {
        const procedureRequirement = data
            ? data.procedureRequirements[0]
            : null;
        
        if (addEmployees) {
            return (
                <MangAddEmployees 
                    procedureRequirement={procedureRequirement}
                    availableShifts={availableShifts}
                    constraints={constraints}
                    gaps={event ? event.gaps : []}
                    closeDialog={closeForm}
                    setToast={setToast}
                    setShowToast={setShowToast}
                    setErrorToast={setErrorToast}
                    setShowErrorToast={setShowErrorToast}
                    refetch={refetch}
                    setOpenSnackBar={setOpenSnackBar}
                    setSnackBarMessage={setSnackBarMessage}
                    notifyDevelopers={notifyDevelopers}
                    environment={environment}
                />
            );
        } else {
            return (  
                <ProcedureRequirementForm
                    closeDialog={closeForm}
                    procedureRequirement={procedureRequirement}
                    setToast={setToast}
                    setShowToast={setShowToast}
                    setAddEmployees={setAddEmployees}
                    setConstraints={setConstraints}
                    refetch={refetch}
                    scheduleEndDate={scheduleEndDate}
                    setOpenSnackBar={setOpenSnackBar}
                    setSnackBarMessage={setSnackBarMessage}
                    notifyDevelopers={notifyDevelopers}
                    environment={environment}
                />
            );
        }
    }
    
}
 
export default AddProcedure;