import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  Snackbar,
  LinearProgress,
  Checkbox,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import { format, getDaysInMonth, sub } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { userVar } from "../../cache";
import { useMutation, useQuery } from "@apollo/client";
import {
  BATCH_CREATE_EMPLOYEE_AVAILABILITY,
  GET_EMPLOYEE_NAMES,
  AVAILABILITY_TYPES,
} from "../../api/gqlQueries";

const SUPPORTED_FORMATS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const useStyles = makeStyles(() => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  root: {
    margin: 0,
    padding: 0,
  },
  table: {
    border: "none",
    boxSizing: "none",
    boxShadow: "none",
    borderRadius: "none",
    width: "100%",
  },
  deleteButton: {
    color: "#333333",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const ImportAvailability = (props) => {
  const {
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props
  const user = userVar();
  const classes = useStyles();
  // const [values, SetValues] = useState({});
  // const [file, SetFile] = useState(null);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsWithErrors, setRowsWithErrors] = useState([]);

  const { data } = useQuery(GET_EMPLOYEE_NAMES, {
    variables: { office: parseInt(user.office.id) },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_EMPLOYEE_NAMES Query. Environment: " + environment + ". Graphql " + error}});
    },

  });

  const { data: availabilityData } = useQuery(AVAILABILITY_TYPES, {
    onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
        notifyDevelopers({variables: {message: "Error on AVAILABILITY_TYPES Query. Environment: " + environment + ". Graphql " + error}});
      },
  });

  const [batchCreateAvailability] = useMutation(
    BATCH_CREATE_EMPLOYEE_AVAILABILITY,
    {
      onCompleted() {
        setShowToast(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
        notifyDevelopers({variables: {message: "Error on BATCH_CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " + environment + ". Graphql " + error}});
      },
    }
  );

  let uptoId = "1";
  if (availabilityData) {
    const upto = availabilityData.availabilityTypes.find(
      (type) => type.name === "UPTO"
    );
    if (upto) {
      uptoId = upto.id;
    } else {
      uptoId = "1";
    }
  }

  const monthStringToNumber = {
    january: "01",
    february: "02",
    march: "03",
    april: "04",
    may: "05",
    june: "06",
    july: "07",
    august: "08",
    september: "09",
    october: "10",
    november: "11",
    december: "12",
    jan: "01",
    feb: "02",
    mar: "03",
    apr: "04",
    may: "05",
    jun: "06",
    jul: "07",
    aug: "08",
    sep: "09",
    oct: "10",
    nov: "11",
    dec: "12",
  };

  const handleFileRead = (data, fileInfo) => {
    if (SUPPORTED_FORMATS.includes(fileInfo.type)) {
      setFileFormatError(false);
      setFileLoaded(true);
      let month = data[0].month.toLowerCase();

      month = monthStringToNumber[month]
        ? monthStringToNumber[month]
        : monthStringToNumber[month.slice(0, 3)];
      const year = data[0].year;
      const dateString = `${year}-${month}-01T12:00:00`;
      const numberOfDays = getDaysInMonth(new Date(dateString));

      const dateColumns = [];
      for (let i = 1; i <= numberOfDays; i++) {
        dateColumns.push({
          field: i.toString(),
          headerName: i.toString(),
          sortable: false,
          filterable: false,
          width: 65,
          renderCell: (params) => <Checkbox checked={Boolean(params.value)} />,
        });
      }
      const newColumns = [
        {
          field: "Name",
          headerName: "Name",
          width: 125,
          editable: true,
        },
        ...dateColumns,
      ];
      setColumns(newColumns);
      const newRows = data.map((row) => {
        return {
          ...row,
          id: `${row.Name}`,
        };
      });
      setRows(newRows);
    } else {
      setFileFormatError(true);
      setFileLoaded(false);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const formatRequests = (row, index) => {
    console.log(row.Name);
    let firstName = row.Name.includes(",")
      ? row.Name.split(",")[0]
      : row.Name.split(" ")[1];
    if (row.Name.includes(",")) {
      firstName = firstName.split(" ")[1];
    }
    console.log(firstName);
    let lastName = row.Name.includes(",")
      ? row.Name.split(",")[0]
      : row.Name.split(" ")[0];
    if (row.Name.includes(",")) {
      lastName = lastName.split(" ")[0];
    }
    console.log(lastName);

    const employee = data.offices[0].employeeSet.find(
      (employee) =>
        employee.firstName === firstName && employee.lastName === lastName
    );
    console.log(employee);
    const indexInErrorsArray = rowsWithErrors.indexOf(index);

    if (employee) {
      if (indexInErrorsArray !== -1) {
        const newRowsWithErrors = [...rowsWithErrors];
        newRowsWithErrors.splice(indexInErrorsArray, 1);
        setRowsWithErrors(newRowsWithErrors);
      }

      const month = row.month.toLowerCase();
      const year = row.year;
      const monthNumber = monthStringToNumber[month]
        ? monthStringToNumber[month]
        : monthStringToNumber[month.slice(0, 3)];

      let emptyDates = Object.entries(row);
      emptyDates = emptyDates.filter((array) => !Boolean(array[1]));
      const dates = emptyDates.map((array) => {
        const dateNumber = array[0].length > 1 ? array[0] : "0" + array[0];
        return `${year}-${monthNumber}-${dateNumber}`;
      });

      const availabilityEvents = [];
      dates.forEach((date) => {
        const dateMinusDay = sub(new Date(`${date}T12:00:00`), { days: 1 });
        const existing = availabilityEvents.find(
          (event) => event.lastday === format(dateMinusDay, "yyyy-MM-dd")
        );
        if (existing) {
          existing.lastday = date;
        } else {
          availabilityEvents.push({
            employee: employee.id,
            office: user.office.id,
            firstday: date,
            lastday: date,
            type: uptoId,
            workHours: 0,
            approvedby: parseInt(user.id)
          });
        }
      });
      return { requests: availabilityEvents, error: !employee };
    } else {
      if (indexInErrorsArray === -1) {
        const newRowsWithErrors = [...rowsWithErrors];
        newRowsWithErrors.push(index);
        setRowsWithErrors(newRowsWithErrors);
      }
      return { requests: [], error: !employee };
    }
  };

  const handleImportAvailability = () => {
    const format = rows.map((row, index) => formatRequests(row, index));
    const requests = format.map((object) => object.requests).flat();
    const errors = format.find((object) => object.error);

    if (requests.length > 0 && !errors) {
      //batch create employee availability
      batchCreateAvailability({ variables: { input: requests } });
    } else {
      return;
    }
  };

  const headerData = [
    [
      "month",
      "year",
      "Name",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
  ];

  const handleCellChangeCommitted = (params) => {
    let newRows = [...rows];
    let indexToChange;
    let rowToChange = newRows.find((row, index) => {
      if (row.id === params.id) {
        indexToChange = index;
        return true;
      } else {
        return false;
      }
    });

    if (rowToChange) {
      rowToChange[params.field] = params.props.value;
      newRows.splice(indexToChange, 1, rowToChange);
    }
    setRows(newRows);
  };

  const handleCellClick = (params) => {
    const key = params.field;
    const currentValue = Boolean(params.formattedValue);
    if (key === "Name") {
      return;
    } else {
      let newRows = [...rows];
      let indexToChange = params.rowIndex;
      let rowToChange = newRows[params.rowIndex];

      if (rowToChange) {
        rowToChange[key] = !currentValue;
        newRows.splice(indexToChange, 1, rowToChange);
      }
      setRows(newRows);
    }
  };

  return (
    <Grid
      container
      direction="row"
      className={classes.headerSpacing}
      spacing={4}
    >
      <Grid item xs={12}>
        <Typography variant="h3">Availability</Typography>
      </Grid>
      <Grid direction="row" container spacing={2}>
        <Grid item xs={6}>
          <Box
            m={2}
            p={2}
            style={{
              border: "1px solid gray",
              width: "100%",
              borderRadius: "5px",
            }}
          >
            <CSVReader
              onFileLoaded={handleFileRead}
              parserOptions={papaparseOptions}
            />
          </Box>
          <Typography color="error" variant="body2">
            {fileFormatError &&
              "File format not supported. Upload a .csv or excel file"}
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={3} style={{ textAlign: "left" }}>
          <Box m={2} p={2}>
            <Button
              style={{ width: "100%" }}
              disabled={!fileLoaded || !data}
              onClick={handleImportAvailability}
              color="primary"
              variant="outlined"
            >
              Import Availability
            </Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box m={2} p={2}>
            <Button
              color="secondary"
              component={CSVLink}
              data={headerData}
              target="_blank"
              color="primary"
              variant="outlined"
            >
              Download CSV Format
            </Button>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Box m={2} p={2}>
            <Button onClick={handleClear} color="primary">
              clear
            </Button>
          </Box>
        </Grid> */}
        <Grid item xs={9}>
          {showLoader ? <LinearProgress color="primary" /> : null}
        </Grid>
        {rowsWithErrors.length > 0 && (
          <Grid item xs={12}>
            <Typography color="primary">
              We cannot find the following employees:
              {" " + rowsWithErrors.map((index) => rows[index].Name).join(", ")}
              .
            </Typography>
            <Typography color="primary">
              Please check the spelling of their names to continue.
            </Typography>
          </Grid>
        )}
        {fileLoaded && (
          <Grid item xs={12} style={{ height: 650 }}>
            {rows.length > 0 && (
              <Typography variant="h4" style={{ marginBottom: 16 }}>
                Availability for {rows[0].month} {rows[0].year}
              </Typography>
            )}
            <DataGrid
              className={classes.dt}
              rows={rows}
              columns={columns}
              onEditCellChangeCommitted={handleCellChangeCommitted}
              onCellClick={handleCellClick}
              // pageSize={10}
              // components={{
              //     Toolbar: CustomToolbar,
              // }}
            />
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => {
            setShowToast(false);
          }}
          severity="success"
        >
          Availability successfully imported!
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={showErrorToast}
        autoHideDuration={6000}
        onClose={() => {
          setShowErrorToast(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert onClose={() => setShowErrorToast(false)} severity="error">
          Something went wrong, please try again.
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};
export default ImportAvailability;
