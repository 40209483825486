import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Switch, Typography, Button, Grid, FormLabel, makeStyles } from '@material-ui/core';
import {GET_NOTIFICATION_PREFS, UPDATE_NOTIFICATION_PREFS} from '../../api/gqlQueries';

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.text.primary
    }
}));

const AccountPreferences = ({setShowToast, setShowErrorToast}) => {
    const classes = useStyles();

    const [canSavePreferences, setCanSavePreferences] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [textChecked, setTextChecked] = useState(false);
    const [scheduleFinalizedChecked, setScheduleFinalizedChecked] = useState(false);
    const [preferencesDueChecked, setPreferencesDueChecked] = useState(false);
    const [shiftSwitchChecked, setShiftSwitchChecked] = useState(false);
    const [scheduleReviewChecked, setScheduleReviewChecked] = useState(false);

    const { loading, error, data } = useQuery(GET_NOTIFICATION_PREFS, {
        onCompleted(data) {
            setEmailChecked(data.me.notificationByEmail);
            setTextChecked(data.me.notificationByText);
            setScheduleFinalizedChecked(data.me.notifyScheduleFinal);
            setPreferencesDueChecked(data.me.notifyPreferencesDue);
            setShiftSwitchChecked(data.me.notifyShiftSwitchRequest);
            setScheduleReviewChecked(data.me.notifyScheduleAvailable);
        }
    });
    
    const [updateNotificationPrefs] = useMutation(UPDATE_NOTIFICATION_PREFS, {
        onCompleted(data) {
            console.log(data);
            setShowToast(true);
            setCanSavePreferences(false);
        },
        onError(error) {
            console.log(error);
            setShowErrorToast(true);
        }
    });

    if(loading) {
        return <Typography>Loading...</Typography>;
    } else if(error) {
        console.log(error);
        return <Typography>Something went wrong. Please try again.</Typography>;
    } else {
        
        const handleCheckboxChange = (value, updater) => {
            updater(value);
            setCanSavePreferences(true);
        };

        const handleSavedPreferences = () => {
            const prefs = {
                notificationByEmail: emailChecked,
                notificationByText: textChecked,
                notifyScheduleFinal: scheduleFinalizedChecked,
                notifyPreferencesDue: preferencesDueChecked,
                notifyShiftSwitchRequest: shiftSwitchChecked,
                notifyScheduleAvailable: scheduleReviewChecked,
            };
            updateNotificationPrefs({variables: {
                id: data.me.id, 
                input: {...prefs}
            }});
        };
        return (
            <>
                <Grid item container justifyContent='flex-end'>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={handleSavedPreferences} 
                        disabled={!canSavePreferences}
                    >
                        Save
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        Notification Preferences
                    </Typography>
                </Grid>
                <Grid item container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={10}>
                        <FormLabel htmlFor='email-notifications' className={classes.label}>
                            Email Notifications
                        </FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <Switch
                            id='email-notifications'
                            checked={emailChecked}
                            onChange={e => handleCheckboxChange(e.target.checked, setEmailChecked)}
                            name="emailChecked"
                            color={emailChecked ? 'primary' : 'secondary'}
                        />
                    </Grid>
                </Grid>
                <Grid item container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={10}>
                        <FormLabel htmlFor='text-notifications' className={classes.label}>
                            Text Notifications
                        </FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <Switch
                            id='text-notifications'
                            checked={textChecked}
                            onChange={e => handleCheckboxChange(e.target.checked, setTextChecked)}
                            name="textChecked"
                            color={textChecked ? 'primary' : 'secondary'}
                        />
                    </Grid>
                </Grid>
                <br />
                {emailChecked || textChecked
                    ? <>
                        <Grid item>
                            <Typography variant="h6">Notify me when:</Typography>
                        </Grid>
                        <Grid item container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={10}>
                                <FormLabel htmlFor='schedule-finalized' className={classes.label}>
                                    Schedule is Finalized
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    id='schedule-finalized'
                                    checked={scheduleFinalizedChecked}
                                    onChange={e => handleCheckboxChange(e.target.checked, setScheduleFinalizedChecked)}
                                    name="scheduleFinalizedChecked"
                                    color={scheduleFinalizedChecked ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={10}>
                                <FormLabel htmlFor='preferences-due' className={classes.label}>
                                    Preferences Due
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    id='preferences-due'
                                    checked={preferencesDueChecked}
                                    onChange={e => handleCheckboxChange(e.target.checked, setPreferencesDueChecked)}
                                    name="preferencesDueChecked"
                                    color={preferencesDueChecked ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={10}>
                                <FormLabel htmlFor='shift-switch' className={classes.label}>
                                    Shift Switch Request
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    id='shift-switch'
                                    checked={shiftSwitchChecked}
                                    onChange={e => handleCheckboxChange(e.target.checked, setShiftSwitchChecked)}
                                    name="shiftSwitchChecked"
                                    color={shiftSwitchChecked ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={10}>
                                <FormLabel htmlFor='review-window' className={classes.label}>
                                    Schedule Review Window Open
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    id='review-window'
                                    checked={scheduleReviewChecked}
                                    onChange={e => handleCheckboxChange(e.target.checked, setScheduleReviewChecked)}
                                    name="scheduleReviewChecked"
                                    color={scheduleReviewChecked ? 'primary' : 'secondary'}
                                />
                            </Grid>
                        </Grid>
                    </>
                    : null
                }

            </>
        );
    }
};

export default AccountPreferences;