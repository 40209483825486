import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles,
  CircularProgress,
  List,
  ListItem,
} from "@material-ui/core";
import { format, startOfMonth, endOfMonth } from "date-fns";
import {
  GET_FLOAT_SHIFT_ASSIGNMENTS,
  GET_SHIFTS_WITH_RANGE_LENGTH,
  GET_RELEVANT_SKILLS,
  ALL_OFFICES,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import AnalyticsWidget from "./AnalyticsGraphWidget";
import { Year } from "@syncfusion/ej2-schedule";
import ErrorSnackBar from "../errors/errorSnackBar";
import { userVar } from "../../cache";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
    borderTop: "1px solid lightgrey",
  },
}))(TableCell);

function FloatAnalytics() {
  const user = userVar();
  const [skillsetGraph, SetskillsetGraph] = useState(true);
  const environment = process.env.NODE_ENV;
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const year = new Date().getFullYear();
  const month = new Date().getMonth();

  const shiftAssignmentData = useQuery(
    GET_FLOAT_SHIFT_ASSIGNMENTS,
    {
      variables: {
        year: year,
        month: month + 1, //for some reason month gives us 1 month past
      },
    },
    {
      onError(error) {
        console.log(error);
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_FLOAT_SHIFT_ASSIGNMENTS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const startOfSelectedMonth =
    format(startOfMonth(new Date()), "yyyy-MM-dd") + `T00:00:00`;
  const endOfSelectedMonth =
    format(endOfMonth(new Date()), "yyyy-MM-dd") + `T11:59:59`;

  const getAllShiftsForOffice = useQuery(GET_SHIFTS_WITH_RANGE_LENGTH, {
    variables: {
      rangeStart: startOfSelectedMonth,
      rangeEnd: endOfSelectedMonth,
    },
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SHIFTS_WITH_RANGE_LENGTH Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const getAllSkills = useQuery(GET_RELEVANT_SKILLS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const getAllOffices = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_OFFICES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  if (
    shiftAssignmentData.loading &&
    getAllSkills.loading &&
    getAllOffices.loading &&
    getAllShiftsForOffice.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    //this function takes in an array and returns the item in the array that occurs the most
    function returnMostOccuring(arr) {
      return arr
        .sort(
          (a, b) =>
            arr.filter((v) => v === a).length -
            arr.filter((v) => v === b).length
        )
        .pop();
    }

    // returning the employee scheduled the most
    const mostScheduledEmployee = () => {
      let array = [];
      let employeeName = [];
      shiftAssignmentData.data &&
        shiftAssignmentData.data.floatShiftAssignments.length > 0 &&
        shiftAssignmentData.data.floatShiftAssignments.map((e) =>
          array.push(e.employee.firstName + " " + e.employee.lastName)
        );
      employeeName = returnMostOccuring(array);
      return {
        employee: employeeName,
        number: 0,
      };
    };

    const mostUnderstaffedOffice = () => {
      let array = [];
      let officeName = [];
      getAllOffices.data &&
        getAllOffices.data.offices
          .filter((e) => e.floatStatus === false)
          .map(
            (o) =>
              shiftAssignmentData.data &&
              shiftAssignmentData.data.floatShiftAssignments.length > 0 &&
              shiftAssignmentData.data.floatShiftAssignments.map(
                (e) =>
                  e.shift.office.id === o.id && array.push(e.shift.office.name)
              )
          );
      officeName = returnMostOccuring(array);
      return {
        name: officeName,
        length:
          shiftAssignmentData.data &&
          shiftAssignmentData.data.floatShiftAssignments.length > 0 &&
          shiftAssignmentData.data.floatShiftAssignments.filter(
            (e) => e.shift.office.name === officeName
          ).length,
      };
    };

    const mostDemandedSkill = () => {
      let skill = [];
      let arrayName = [];
      getAllSkills.data &&
        getAllSkills.data.requiredSkills.length > 0 &&
        getAllSkills.data.requiredSkills.map(
          (e) =>
            shiftAssignmentData.data &&
            shiftAssignmentData.data.floatShiftAssignments.length > 0 &&
            shiftAssignmentData.data.floatShiftAssignments.map((o) =>
              o.employee.employeeskillSet.map(
                (i) => i.skill.id === e.id && arrayName.push(i.skill.name)
              )
            )
        );
      skill = returnMostOccuring(arrayName);
      return {
        name: skill,
      };
    };
    const getEmployeesWithSkill = (skill, office) => {
      let allEmps = [];
      let numberOfShifts = [];
      shiftAssignmentData.data &&
        shiftAssignmentData.data.floatShiftAssignments.length > 0 &&
        shiftAssignmentData.data.floatShiftAssignments
          .filter((e) => e.shift.office.id === office)
          .filter(
            (e) =>
              e.employee &&
              e.employee.employeeskillSet &&
              e.employee.employeeskillSet.filter((s) => s.skill.id === skill)
                .length > 0
          )
          .map(
            (e) =>
              !allEmps.includes(e.employee.id) &&
              allEmps.push(e.employee.id) &&
              !numberOfShifts.includes(e.shift.id) &&
              numberOfShifts.push(e.shift.id)
          )
          .filter(Boolean);

      return {
        numberOfEmpsWithSkill: allEmps && allEmps.length,
        numberOfFloatShiftsWithSkill: numberOfShifts && numberOfShifts.length,
      };
    };

    const getTotalShiftsForOffice = (office) => {
      //calculate number of shifts for selected office
      let totalOfficeShiftsForRange =
        getAllShiftsForOffice &&
        getAllShiftsForOffice.data &&
        getAllShiftsForOffice.data.shifts.filter((e) => e.office.id === office);

      return totalOfficeShiftsForRange && totalOfficeShiftsForRange.length;
    };

    const floatEmployeesBySkillGraphData =
      getAllOffices.data &&
      getAllOffices.data.offices
        .filter((e) => e.floatStatus === false)
        .map((o) => {
          return {
            type: "bar",
            x:
              getAllSkills.data &&
              getAllSkills.data.requiredSkills.map((s) =>
                getEmployeesWithSkill(s.skillId, o.id).numberOfEmpsWithSkill > 0
                  ? s.name
                  : ""
              ),
            y:
              getAllSkills.data &&
              getAllSkills.data.requiredSkills.map((s) =>
                getEmployeesWithSkill(s.skillId, o.id).numberOfEmpsWithSkill > 0
                  ? getEmployeesWithSkill(s.skillId, o.id).numberOfEmpsWithSkill
                  : ""
              ),
            name: o.name,
          };
        });

    const percentOfFloatEmployeesScheduled =
      getAllOffices.data &&
      getAllOffices.data.offices
        .filter((e) => e.floatStatus === false)
        .map((o) => {
          return {
            type: "bar",
            x:
              getAllSkills.data &&
              getAllSkills.data.requiredSkills.map((s) => {
                let data =
                  getTotalShiftsForOffice(o.id) > 0
                    ? (
                        (getEmployeesWithSkill(s.skillId, o.id)
                          .numberOfFloatShiftsWithSkill /
                          getTotalShiftsForOffice(o.id)) *
                        100
                      ).toFixed(2)
                    : 0;
                if (data > 0) {
                  return s.name;
                } else {
                  return "";
                }
              }),
            y:
              getAllSkills.data &&
              getAllSkills.data.requiredSkills.map((s) => {
                let data =
                  getTotalShiftsForOffice(o.id) > 0
                    ? (
                        (getEmployeesWithSkill(s.skillId, o.id)
                          .numberOfFloatShiftsWithSkill /
                          getTotalShiftsForOffice(o.id)) *
                        100
                      ).toFixed(2) + "%"
                    : 0;
                return data;
              }),
            name: o.name,
          };
        });

    const totalNumberOfShiftsCoveredByFloats =
      getAllOffices.data &&
      getAllOffices.data.offices
        .filter((e) => e.floatStatus === false)
        .map((o) => {
          return {
            type: "bar",
            x:
              getAllSkills.data &&
              getAllSkills.data.requiredSkills.map((s) =>
                getEmployeesWithSkill(s.skillId, o.id)
                  .numberOfFloatShiftsWithSkill > 0
                  ? s.name
                  : ""
              ),
            y:
              getAllSkills.data &&
              getAllSkills.data.requiredSkills.map((s) => {
                let data = getEmployeesWithSkill(
                  s.skillId,
                  o.id
                ).numberOfFloatShiftsWithSkill;
                return data > 0 ? data : "";
              }),
            name: o.name,
          };
        });
    return (
      <div>
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h3">
                Float Analytics: {format(new Date(), "MMM-yyyy")}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h5">Overview</Typography>

            <Table>
              <TableRow>
                <StyledTableCell>
                  <TableRow>
                    <StyledTableCell>
                      <b>Most In Demand Skill</b>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <b>Most Understaffed Office </b>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <b>Most Scheduled Employee</b>
                    </StyledTableCell>
                  </TableRow>
                </StyledTableCell>
                <StyledTableCell>
                  <TableRow>
                    <StyledTableCell>
                      {mostDemandedSkill().name}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      # Employees: {mostDemandedSkill().length}
                    </StyledTableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      {mostUnderstaffedOffice().name}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      # Employees: {mostUnderstaffedOffice().length}
                    </StyledTableCell> */}
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      {mostScheduledEmployee().employee}
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      # Shifts: {mostScheduledEmployee().number}
                    </StyledTableCell> */}
                  </TableRow>
                </StyledTableCell>
              </TableRow>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table style={{width:'40%'}}>
              <TableHead>
                <TableRow>
                  <StyledTableCell> Office</StyledTableCell>
                  {/* {getAllSkills.data &&
                    getAllSkills.data.requiredSkills
                    .map((skill, index) => (
                      <StyledTableCell key={index}>
                        {skill.name}
                      </StyledTableCell>
                    ))} */}
                  <StyledTableCell>Values for each Skill</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getAllOffices.data &&
                  getAllOffices.data.offices
                    .filter((e) => e.floatStatus === false)
                    .map((e, index) => (
                      <TableRow key={index}>
                        <StyledTableCell>
                          <b>{e.name}</b> <br /> # Employees <br /> % Scheduled
                        </StyledTableCell>
                        {getAllSkills.data &&
                          getAllSkills.data.requiredSkills
                            .filter(
                              (skill) =>
                                getEmployeesWithSkill(skill.skillId, e.id)
                                  .numberOfEmpsWithSkill > 0
                            )
                            .map((skill, i) => (
                              <StyledTableCell key={i}>
                                <b>{skill.name}</b>
                                <br />
                                {
                                  getEmployeesWithSkill(skill.skillId, e.id)
                                    .numberOfEmpsWithSkill
                                }
                                <br />
                                {getTotalShiftsForOffice(e.id) > 0
                                  ? (
                                      (getEmployeesWithSkill(
                                        skill.skillId,
                                        e.id
                                      ).numberOfFloatShiftsWithSkill /
                                        getTotalShiftsForOffice(e.id)) *
                                      100
                                    ).toFixed(2) + "%"
                                  : 0}
                              </StyledTableCell>
                            ))}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <AnalyticsWidget
              gridSize={10}
              open={skillsetGraph}
              SetOpen={SetskillsetGraph}
              data={floatEmployeesBySkillGraphData}
              chartType={4}
              SetChartType={null}
              header={
                "Number of Unique Float Employees Assigned to Each Office by Skill: " +
                format(new Date(), "MMM-yyyy")
              }
              layout={{
                yaxis: {
                  title: {
                    text: "# Float Employees",
                  },
                  rangemode: "tozero",
                },
                xaxis: {
                  title: {
                    text: "Skill Type",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <AnalyticsWidget
              gridSize={10}
              open={skillsetGraph}
              SetOpen={SetskillsetGraph}
              data={percentOfFloatEmployeesScheduled}
              chartType={4}
              SetChartType={null}
              header={
                "Percentage of Float Employees Scheduled to Office by Skill: " +
                format(new Date(), "MMM-yyyy")
              }
              layout={{
                yaxis: {
                  title: {
                    text: "% Float Employees Scheduled",
                  },
                  rangemode: "tozero",
                },
                xaxis: {
                  title: {
                    text: "Skill Type",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <AnalyticsWidget
              gridSize={10}
              open={skillsetGraph}
              SetOpen={SetskillsetGraph}
              data={totalNumberOfShiftsCoveredByFloats}
              chartType={4}
              SetChartType={null}
              header={
                "Total Number of Shifts Covered by Float Employees Not Originally Assigned to Corresponding Office: " +
                format(new Date(), "MMM-yyyy")
              }
              layout={{
                yaxis: {
                  title: {
                    text: "# Shifts Covered by Float Employees",
                  },
                  rangemode: "tozero",
                },
                xaxis: {
                  title: {
                    text: "Skill Type",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default FloatAnalytics;
