import React from 'react';
import { 
    Grid, Typography, Button,
    Paper, makeStyles
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { ACCEPT_OPTION, REJECT_OPTION } from '../../api/gqlQueries';
import { userVar } from '../../cache';

const useStyles = makeStyles((theme) => ({
    requestCard: {
        padding: 16,
        marginTop: 10, 
        marginBottom: 10,
        backgroundColor: theme.palette.background.default
    },
    button: {
        width: 75
    }
}));

const ShiftSwitchItem = (props) => {

    const {
        request,
        incoming,
        refetch,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,
    } = props;

    const classes = useStyles();

    const user = userVar();

    const [acceptOption] = useMutation(ACCEPT_OPTION, {
        onCompleted(data) {
            console.log(data);
            refetch();
        },
        onError(error) {
            console.log(error);
            setOpenSnackBar(true);
            setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
            notifyDevelopers({variables: {message: "Error on ACCEPT_OPTION Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });
    const [rejectOption] = useMutation(REJECT_OPTION, {
        onCompleted(data) {
            console.log(data);
            refetch();
        },
        onError(error) {
            console.log(error);
            setOpenSnackBar(true);
            setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
            notifyDevelopers({variables: {message: "Error on REJECT_OPTION Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const getShiftStart = (userShift) => {
        let shift = request.rescheduleindividualSet.find(individual => {
            if (userShift) {
                return parseInt(individual.employee.id) === parseInt(user.id);
            } else {
                return parseInt(individual.employee.id) !== parseInt(user.id);
            }
        });
        const shifts = shift 
            ? shift.rescheduleactionSet.filter(action => (
                action.shiftAssignment !== null
            ))
            : [];
        if (shifts.length > 1) {
            shifts.sort((a,b) => (
                parseInt(a.shiftAssignment) - parseInt(b.shiftAssignment)
            ));
            
            shift = shifts[0];
        } else if (shifts.length > 0) {
            shift = shifts[0];
        } else {
            shift = null;
        }
        
        shift = shift
            ? {start: shift.shift.start, end: shift.shift.end}
            : null
        
        return shift;
    };

    const formatDateAndShift = (start, end) => {
        const date = format(new Date(start), 'dd MMM yyyy ');
        const shift = format(new Date(start), 'HH:mm -') + format(new Date(end), ' HH:mm');
        return date + shift
    };

    const requestorShift = getShiftStart(false);
    const userShift = getShiftStart(true);
    const employeeNotUser = request.rescheduleindividualSet.find(individual => (
        parseInt(individual.employee.id) !== parseInt(user.id)
    ));
    const requestMadeDate = employeeNotUser ? employeeNotUser.updatedAt : null;
    const requestorName = employeeNotUser 
        ? `${employeeNotUser.employee.firstName} ${employeeNotUser.employee.lastName}` 
        : 'Unknown Name';

    const employeeRejected = request.rescheduleindividualSet.find(individual => (
        individual.employeeResponse === 'REJECTED'
    ));
    const userRejected = employeeRejected 
        ? parseInt(employeeRejected.employee.id) === parseInt(user.id)
        : false;

    const handleAccept = () => {
        acceptOption({variables: {
            acceptor: parseInt(user.id),
            option: parseInt(request.id)
        }});
    };

    const handleReject = () => {
        rejectOption({variables: {
            rejector: parseInt(user.id),
            option: parseInt(request.id)
        }});
    };

    const status = () => {
        const mapStatus = {
            'REQUESTED': `Pending acceptance by ${requestorName}`,
            'ACCEPTED': 'Accepted by both employees and pending manager approval',
            'REJECTED': `Rejected by ${userRejected ? 'you' : requestorName}`,
            'APPROVED': `Accepted by ${requestorName} and approved by manager`,
            'DENIED': 'Denied by manager'
        };
        return mapStatus[request.status];
    };
    
    if (userShift && requestorShift && requestMadeDate) {
        return (  
            <>
                {incoming 
                    ? <Grid 
                        item 
                        container 
                        justifyContent='space-between' 
                        component={Paper} 
                        xs={11}
                        md={7} 
                        className={classes.requestCard}
                    >
                        <Grid 
                            item 
                            container
                            xs={12} 
                            justifyContent='space-between'
                            style={{marginBottom: 16}}
                        >
                            <Grid item xs={6}>
                                <Typography variant='h5'>
                                    Shift Switch Request with {requestorName}
                                </Typography>
                            </Grid>
                            <Grid item container justifyContent='flex-end' xs={6} spacing={2}>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={handleReject}
                                    >
                                        Reject
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleAccept}
                                    >
                                        Accept
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: 8}}>
                            <Typography>
                                Scheduled date: {
                                    formatDateAndShift(userShift.start, userShift.end)
                                }
                            </Typography>
                            <Typography>
                                Requested date: {
                                    formatDateAndShift(
                                        requestorShift.start, requestorShift.end
                                    )
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 8, marginBottom: 8}}>
                            <Typography>
                                <i>Request was made on {format(new Date(requestMadeDate), 'dd MMM yyyy')}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                    : <Grid 
                        item 
                        container 
                        justifyContent='space-between' 
                        component={Paper} 
                        xs={12}
                        md={7} 
                        className={classes.requestCard}
                    >
                        <Grid item xs={12} style={{marginBottom: 16}}>
                            <Typography variant='h5'>
                                Shift Switch Request with {requestorName}
                            </Typography>
                        </Grid>
                        {request.status === 'APPROVED'
                            ? <Grid item xs={12} style={{marginTop: 8, marginBottom: 8}}>
                                <Typography>
                                    You have been taken off {
                                        formatDateAndShift(userShift.start, userShift.end)
                                    } and added to {
                                        formatDateAndShift(
                                            requestorShift.start, requestorShift.end
                                        )
                                    }.
                                </Typography>
                            </Grid>
                            : <Grid item xs={12} style={{marginTop: 8, marginBottom: 8}}>
                                <Typography>
                                    Scheduled date: {
                                        formatDateAndShift(userShift.start, userShift.end)
                                    }
                                </Typography>
                                <Typography>
                                    Requested date: {
                                        formatDateAndShift(
                                            requestorShift.start, requestorShift.end
                                        )
                                    }
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} style={{marginTop: 8}}>
                            <Typography variant='subtitle1'>
                                Status: {status()}
                            </Typography>
                            <Typography>
                                <i>Request was made on {format(new Date(requestMadeDate), 'dd MMM yyyy')}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </>
        );
    } else {
        return null;
    }
}
 
export default ShiftSwitchItem;