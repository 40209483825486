import React from "react";
import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import sun from "../../assets/quizImages/sun.png";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Clock from "@material-ui/icons/AccessTime";
import CheckIcon from "@material-ui/icons/Check";
import PersonIcon from "@material-ui/icons/Person";
import { userVar } from "../../cache";
import SkillColors from "../../helpers/SkillColors.json";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    minWidth: 450,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
  label: {
    height: "auto",
    wordWrap: "break-word",
  },
}));

const Label = ({ data, skills }) => {
  const classes = useStyles();
  const user = userVar();

  if (data.type === "softRequest") {
    return (
      <>
        {data.highPriority ? (
          <Grid
            container
            wrap="nowrap"
            className={classes.label}
            // style={{backgroundColor: data.color}}
          >
            <Grid item>
              <PriorityHighIcon
                fontSize="small"
                style={{ marginBottom: -1, marginRight: 5 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">{data.eventTitle}</Typography>
            </Grid>
            <Grid item>
              <PersonIcon
                style={{
                  width: 14,
                  marginTop: -4,
                  marginLeft: 5,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            wrap="nowrap"
            className={classes.label}
            // style={{backgroundColor: data.color}}
          >
            <Grid item>
              <Typography variant="body2">{data.eventTitle}</Typography>
            </Grid>
            <Grid item>
              <PersonIcon
                style={{
                  width: 14,
                  marginTop: -4,
                  marginLeft: 5,
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  } else if (data.type === "timeOff") {
    const personal = data.employeeIds.includes(user.id);
    return (
      <Grid
        container
        wrap="nowrap"
        className={classes.label}
        // style={{backgroundColor: data.color}}
      >
        {data.status === "approved" && (
          <Grid item>
            <CheckIcon
              fontSize="small"
              style={{ marginBottom: -1, marginRight: 5 }}
            />
          </Grid>
        )}
        <Grid item>
          <Typography variant="body2">{data.eventTitle}</Typography>
        </Grid>
        {personal && (
          <Grid item>
            <PersonIcon
              style={{
                width: 14,
                marginTop: -4,
                marginLeft: 5,
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  } else if (data.employeeIds) {
    const title = data.eventTitle.split("-");
    const start = title[0].split(":")[0];
    const end = title[1].split(":")[0].trim();
    const participantsCount = data.participants.length;
    const personal = data.employeeIds.includes(user.id);
    return (
      <Grid container wrap="nowrap" className={classes.label}>
        <Grid item>
          {data.description.includes("Day") ? (
            <img
              alt="day shift"
              src={sun}
              style={{
                width: 14,
                marginTop: 2,
                marginRight: 5,
              }}
            />
          ) : data.shiftType.includes("Night") ? (
            <Brightness3Icon
              style={{
                width: 14,
                marginTop: -3,
                marginRight: 5,
              }}
            />
          ) : data.shiftType.includes("Swing") ? (
            <Clock
              style={{
                width: 14,
                marginTop: -3,
                marginRight: 5,
              }}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item>
          {user.isPrimaryParentOffice === true || user.floatOffice === true ? (
            <Typography style={{ fontWeight: 600, fontSize: 12 }}>
              {!data.shiftType.includes("24-hr") &&
                data.office + "-" + participantsCount}
            </Typography>
          ) : (
            <Typography style={{ fontWeight: 600, fontSize: 12 }}>
              {data.shiftType + "-" + participantsCount}
            </Typography>
          )}
        </Grid>
        {personal && (
          <Grid item>
            <PersonIcon
              style={{
                width: 14,
                marginTop: -4,
                marginLeft: 5,
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  } else {
    if (data.participant) {
      let uniqueSkills = [];
      data.participant.skillSet &&
        data.participant.skillSet.length > 0 &&
        data.participant.skillSet.map((skill) => {
          if (!uniqueSkills.find((e) => e.name === skill.name)) {
            uniqueSkills.push({
              id: skill.skillId,
              name: skill.name,
              office: skill.office,
              skillColor: SkillColors.skills.find((e) => e.name === skill.name)
                ? SkillColors.skills.find((e) => e.name === skill.name).color
                : "none",
              isActive: skill.isActive,
            });
          }
        });
      uniqueSkills.sort(
        (a, b) => parseInt(a.displayPriority) - parseInt(b.displayPriority)
      );
      return (
        <Grid container style={{ marginLeft: 15 }}>
          <Grid item>
            <div>
              {data.participant.firstName[0] + " " + data.participant.lastName}
              {uniqueSkills.map((e, index) => (
                <span
                  style={{
                    background: e.skillColor,
                  }}
                  key={index}
                >
                  {" : " + e.isActive &&
                    e.isActive === true &&
                    e.name != "APP" &&
                    e.name}
                </span>
              ))}
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container style={{ backgroundColor: "none", marginLeft: 20 }}>
          <Grid item>
            <Typography variant="body2">{data.eventTitle}</Typography>
          </Grid>
        </Grid>
      );
    }
  }
};

export default Label;
