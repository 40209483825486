import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  makeStyles,
  Container,
  Snackbar,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { Formik, FieldArray, getIn, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CONSTRAINT_TYPE,
  EMPLOYEE_TYPES,
  GET_OFFICE,
  CREATE_EMPLOYEE_TYPE,
  BATCH_CREATE_CONSTRAINT,
  BATCH_UPDATE_CONSTRAINT,
  NOTIFY_DEVELOPERS,
  UPDATE_OFFICE,
} from "../../api/gqlQueries";
import {
  findemployeeTypeOfficeConstraint,
  getEmployeeTypeValues,
} from "../../helpers/officeHelpers";
import MuiAlert from "@material-ui/lab/Alert";
import { Prompt } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  inputWidth: {
    width: "100%",
  },
  mediumtextbox: {
    width: "50%",
  },
  smalltextbox: {
    width: "25%",
  },
  loading: {
    display: "block",
    margin: "auto",
  },
  textColor: {
    color: "#F15A29",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 80,
  },
  autocompleteInput: {
    width: "100%",
    padding: 0,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const ErrorMessage = ({ name }) => (
  <Field>
    {({ form }) => {
      const error = getIn(form.errors, name);
      return (
        <Typography variant="subtitle2" color="primary">
          {error ? error : null}
        </Typography>
      );
    }}
  </Field>
);

const validationSchema = Yup.object({
  // fulltimemin: Yup.number().required("Required"),
  // fulltimemax: Yup.number().required("Required"),
  // parttimemin: Yup.number().required("Required"),
  // parttimemax: Yup.number().required("Required"),
  // tempmin: Yup.number().required("Required"),
  // tempmax: Yup.number().required("Required"),
  // floatmin: Yup.number().required("Required"),
  // floatmax: Yup.number().required("Required"),
  // prnmin: Yup.number().required("Required"),
  // prnmax: Yup.number().required("Required"),
  // maxdaysOffwithoutPTO: Yup.number().required("Required"),
  // maxhoursperday: Yup.number().required("Required"),
  // maxconsecutivedays: Yup.number().required("Required"),
});

const PromptIfDirty = () => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes on this form."
    />
  );
};

const d = new Date();

function EmploymentTypeSettings(props) {
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [payPeriodWeeks, SetPayPeriodWeeks] = useState(1);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const history = useHistory();

  const { data, refetch, loading } = useQuery(GET_OFFICE, {
    variables: {
      id: parseInt(props.office),
    },
    onCompleted(d) {
      SetPayPeriodWeeks(d.offices[0].payPeriodWeeks);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onError(error) {
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_OFFICE mutation Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateConstraints] = useMutation(BATCH_CREATE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_CREATE_CONSTRAINT mutation Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchUpdateConstraints] = useMutation(BATCH_UPDATE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_UPDATE_CONSTRAINT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const allConstraintTypes = useQuery(GET_CONSTRAINT_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_CONSTRAINT_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createEmployeeType] = useMutation(CREATE_EMPLOYEE_TYPE, {
    onCompleted(dat) {
      const empType = dat.createEmployeeType.employeeType;
      //create employee type comstraints
      let filteredConstraintDef =
        allConstraintTypes.data.constraintDefinitions.filter(
          (constraintDef) =>
            constraintDef.name === "FTEMAXHRWK" ||
            constraintDef.name === "FTEMINHRWK" ||
            constraintDef.name === "MAXDAYS" ||
            constraintDef.name === "MAXDAILYHR" ||
            constraintDef.name === "MAXDAYSOFF"
        );
      let inputData = filteredConstraintDef.map((constraintDef) => {
        return {
          type: parseInt(constraintDef.id),
          value:
            constraintDef.name === "FTEMAXHRWK"
              ? empType.name === "FULL-TIME"
                ? 44
                : 24
              : constraintDef.name === "FTEMINHRWK"
              ? empType.name === "FULL-TIME"
                ? 40
                : 20
              : constraintDef.name === "MAXDAYS"
              ? 3
              : constraintDef.name === "MAXDAILYHR"
              ? 16
              : constraintDef.name === "MAXDAYSOFF"
              ? 2
              : 0,
          office: parseInt(props.office),
          employeetype: parseInt(empType.id),
          effectstart: format(d, "yyyy-MM-dd"),
        };
      });
      batchCreateConstraints({
        variables: {
          input: inputData.flat(),
        },
      });
      refetch();
      allEmployeeTypes.refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_EMPLOYEE_TYPE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allEmployeeTypes = useQuery(EMPLOYEE_TYPES, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);

  if (loading || allConstraintTypes.loading || allEmployeeTypes.loading) {
    return <CircularProgress color="primary" />;
  } else {
    const handleUpdateOffice = (values) => {
      updateOffice({
        variables: {
          id: parseInt(props.office),
          input: { payPeriodWeeks: parseInt(values.payPeriod) },
        },
      });
      values.employeeWorkingMaximums.map((element) => {
        if (element.id === "") {
          createEmployeeType({
            variables: {
              input: {
                name: element.employeeType,
              },
            },
          });
        } else {
          let filteredConstraintDef =
            allConstraintTypes.data.constraintDefinitions.filter(
              (constraintDef) =>
                constraintDef.name === "FTEMAXHRWK" ||
                constraintDef.name === "FTEMINHRWK" ||
                constraintDef.name === "MAXDAYS" ||
                constraintDef.name === "MAXDAILYHR" ||
                constraintDef.name === "MAXDAYSOFF"
            );
          let officeConstraints = data.offices[0].constraintSet.filter(
            (x) => x.employeetype != null
          );
          let updateInput = [];
          let createInput = [];
          filteredConstraintDef.map((constraintDef) => {
            let constraintId = findemployeeTypeOfficeConstraint(
              officeConstraints,
              constraintDef.name,
              element.employeeType
            );
            if (constraintId) {
              updateInput.push({
                id: parseInt(constraintId.id),
                type: parseInt(constraintDef.id),
                value:
                  constraintDef.name === "FTEMAXHRWK"
                    ? parseInt(element.maxWeeklyHrs) / parseInt(payPeriodWeeks)
                    : constraintDef.name === "FTEMINHRWK"
                    ? parseInt(element.requiredWeeklyHrs) /
                      parseInt(payPeriodWeeks)
                    : constraintDef.name === "MAXDAYS"
                    ? parseInt(element.maxConsecutiveDays)
                    : constraintDef.name === "MAXDAILYHR"
                    ? parseInt(element.maxHoursPerDay)
                    : constraintDef.name === "MAXDAYSOFF"
                    ? parseInt(element.maxDaysOff)
                    : 0,
                office: parseInt(data.offices[0].id),
                employeetype: parseInt(element.id),
                effectstart: format(d, "yyyy-MM-dd"),
              });
            } else {
              createInput.push({
                type: parseInt(constraintDef.id),
                value:
                  constraintDef.name === "FTEMAXHRWK"
                    ? parseInt(element.maxWeeklyHrs) / parseInt(payPeriodWeeks)
                    : constraintDef.name === "FTEMINHRWK"
                    ? parseInt(element.requiredWeeklyHrs) /
                      parseInt(payPeriodWeeks)
                    : constraintDef.name === "MAXDAYS"
                    ? parseInt(element.maxConsecutiveDays)
                    : constraintDef.name === "MAXDAILYHR"
                    ? parseInt(element.maxHoursPerDay)
                    : constraintDef.name === "MAXDAYSOFF"
                    ? parseInt(element.maxDaysOff)
                    : 0,
                office: parseInt(data.offices[0].id),
                employeetype: parseInt(element.id),
                effectstart: format(d, "yyyy-MM-dd"),
              });
            }
          });

          batchUpdateConstraints({
            variables: {
              input: updateInput,
            },
          });
          batchCreateConstraints({
            variables: {
              input: createInput,
            },
          });
        }
      });
      refetch();
      setsnackBarMessage("Department successfully updated");
      setsnackbaropen(true);
    };

    return (
      <main variant="body">
        <Container style={{ margin: 0, padding: 0 }}>
          <ErrorSnackBar
            open={snackBarOpen}
            message={errorSnackBarMessage}
            close={() => setSnackBarOpen(false)}
          />
          <Snackbar
            open={snackbaropen}
            autoHideDuration={2000}
            onClose={() => {
              setsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => {
                setsnackbaropen(false);
                setsnackBarMessage("");
              }}
              severity="success"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={errorsnackbaropen}
            autoHideDuration={6000}
            onClose={() => {
              seterrorsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => seterrorsnackbaropen(false)}
              severity="error"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <Formik
                enableReinitialize
                initialValues={{
                  id: data.offices[0].id,
                  departmentName: data.offices[0].name,
                  payPeriod: data.offices[0].payPeriodWeeks,
                  employeeWorkingMaximums:
                    !allEmployeeTypes.loading &&
                    getEmployeeTypeValues(
                      allEmployeeTypes.data.employeeTypes,
                      data.offices[0].constraintSet
                    ),
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleUpdateOffice(values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                  dirty,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {dirty && <PromptIfDirty />}
                    <Grid container direction="row" spacing={4}>
                      <Grid
                        item
                        container
                        xs={12}
                        className={classes.headerSpacing}
                      >
                        <Grid item xs={6} style={{ padding: 0 }}>
                          <Typography variant="h3">
                            Employment Type Settings
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography variant="h5">Department Name</Typography>
                        </Box>
                        <TextField
                          id="departmentName"
                          name="departmentName"
                          variant="outlined"
                          value={
                            values.departmentName ? values.departmentName : ""
                          }
                          className={classes.inputWidth}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography variant="h5">
                            Duration of Pay Period
                          </Typography>
                        </Box>
                        <TextField
                          id="payPeriod"
                          name="payPeriod"
                          variant="outlined"
                          value={values.payPeriod ? values.payPeriod : ""}
                          onChange={handleChange}
                          className={classes.mediumtextbox}
                        />
                        <Typography variant="subtitle2">(in weeks)</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                      <Grid item xs={10}>
                        <FieldArray name="employeeWorkingMaximums">
                          {({ push, remove }) => (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    colSpan={6}
                                    style={{ textAlign: "left" }}
                                  >
                                    Employee Type Requirements
                                  </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell>
                                    Employee Type
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    Required Hours for the duration of pay
                                    period
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    Max Hours allowed for the duration of pay
                                    period
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    Max Days Off per pay period
                                    <br />
                                    (without PTO)
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    Max Consecutive Days
                                    <br />
                                    (that can be worked per pay period)
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    Max Hours Per Day
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.employeeWorkingMaximums &&
                                  values.employeeWorkingMaximums.map(
                                    (e, index) => (
                                      <TableRow key={index}>
                                        <StyledTableCell>
                                          {e.id ? (
                                            e.employeeType
                                          ) : (
                                            <TextField
                                              id={`employeeWorkingMaximums.${index}.employeeType`}
                                              name={`employeeWorkingMaximums.${index}.employeeType`}
                                              variant="outlined"
                                              className={classes.inputWidth}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `employeeWorkingMaximums.${index}.employeeType`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <TextField
                                            id={`employeeWorkingMaximums.${index}.requiredWeeklyHrs`}
                                            name={`employeeWorkingMaximums.${index}.requiredWeeklyHrs`}
                                            variant="outlined"
                                            value={
                                              parseInt(e.requiredWeeklyHrs) *
                                              parseInt(values.payPeriod)
                                            }
                                            onChange={(e) => {
                                              let val =
                                                parseInt(e.target.value) *
                                                parseInt(values.payPeriod);
                                              setFieldValue(
                                                `employeeWorkingMaximums.${index}.requiredWeeklyHrs`,
                                                val
                                              );
                                            }}
                                            className={classes.mediumtextbox}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <TextField
                                            id={`employeeWorkingMaximums.${index}.maxWeeklyHrs`}
                                            name={`employeeWorkingMaximums.${index}.maxWeeklyHrs`}
                                            variant="outlined"
                                            value={
                                              parseInt(e.maxWeeklyHrs) *
                                              parseInt(values.payPeriod)
                                            }
                                            onChange={(e) => {
                                              let val =
                                                parseInt(e.target.value) *
                                                parseInt(values.payPeriod);
                                              setFieldValue(
                                                `employeeWorkingMaximums.${index}.maxWeeklyHrs`,
                                                val
                                              );
                                            }}
                                            className={classes.mediumtextbox}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <TextField
                                            id={`employeeWorkingMaximums.${index}.maxDaysOff`}
                                            name={`employeeWorkingMaximums.${index}.maxDaysOff`}
                                            variant="outlined"
                                            value={e.maxDaysOff}
                                            onChange={handleChange}
                                            className={classes.mediumtextbox}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <TextField
                                            id={`employeeWorkingMaximums.${index}.maxConsecutiveDays`}
                                            name={`employeeWorkingMaximums.${index}.maxConsecutiveDays`}
                                            variant="outlined"
                                            value={e.maxConsecutiveDays}
                                            onChange={handleChange}
                                            className={classes.mediumtextbox}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <TextField
                                            id={`employeeWorkingMaximums.${index}.maxHoursPerDay`}
                                            name={`employeeWorkingMaximums.${index}.maxHoursPerDay`}
                                            variant="outlined"
                                            value={e.maxHoursPerDay}
                                            onChange={handleChange}
                                            className={classes.mediumtextbox}
                                          />
                                        </StyledTableCell>
                                      </TableRow>
                                    )
                                  )}
                                <TableRow>
                                  <StyledTableCell
                                    style={{
                                      textAlign: "left",
                                      borderBottom: "none",
                                    }}
                                  >
                                    <Box ml={3}>
                                      <Button
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={() =>
                                          push({
                                            id: "",
                                            employeeType: "",
                                            requiredWeeklyHrs: "40",
                                            maxWeeklyHrs: "40",
                                            maxDaysOff: "2",
                                            maxConsecutiveDays: "5",
                                            maxHoursPerDay: "12",
                                          })
                                        }
                                      >
                                        Add Employee Type Requirements
                                      </Button>
                                    </Box>
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          )}
                        </FieldArray>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      xs={12}
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => history.push("/Admin")}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          className={classes.button}
                          disabled={!dirty}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </main>
    );
  }
}

export default EmploymentTypeSettings;
