import React from "react";
import {
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import AlertOutline from "mdi-material-ui/AlertOutline";
import { format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { userVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "rgba(241, 90, 41)",
  },
  darkText: {
    color: "rgba(241, 90, 41)",
  },
  underText: {
    color: "rgba(241, 90, 41)",
    fontWeight: "bold",
  },
  timeText: {
    color: "rgba(241, 90, 41)",
    width: 140,
    paddingRight: 0,
  },
  underTimeText: {
    color: "rgba(241, 90, 41)",
    fontWeight: "bold",
    width: 140,
    paddingRight: 0,
  },
  // table: {
  //     minWidth: 350
  // },
  icon: {
    padding: 0,
    marginRight: 5,
    marginBottom: -2,
    color: "rgba(241, 90, 41)",
  },
}));

const ShiftSlack = ({ startDate, slacks }) => {
  const classes = useStyles();
  const user = userVar();
  const slacksRows = () => {
    const sortedByTime = [...slacks];
    sortedByTime.sort(
      (a, b) => new Date(a.interval.start) - new Date(b.interval.start)
    );

    let aggregatedSlacks = [];
    sortedByTime.forEach((slack) => {
      const skill = slack.skill.name;
      const startTime = format(new Date(slack.interval.start), "HH:mm");
      const existsAndConsecutive = aggregatedSlacks.find(
        (existingSlack) =>
          existingSlack.skill === skill &&
          existingSlack.required === slack.required &&
          existingSlack.slack === slack.slack &&
          format(new Date(existingSlack.end), "HH:mm") === startTime
      );
      if (existsAndConsecutive) {
        existsAndConsecutive.end = new Date(slack.interval.end);
      } else {
        aggregatedSlacks.push({
          skill: skill,
          start: new Date(slack.interval.start),
          end: new Date(slack.interval.end),
          required: slack.required,
          assigned: slack.numAssigned,
          slack: slack.slack,
          id: slack.id,
          office: slack.interval.shifts[0].office.name,
        });
      }
    });
    aggregatedSlacks.sort(function (a, b) {
      if (a.skill < b.skill) {
        return -1;
      }
      if (a.skill > b.skill) {
        return 1;
      }
      return 0;
    });
    return aggregatedSlacks.map((slack, index, arr) => {
      const startTime = format(slack.start, "HH:mm");
      const endTime = format(slack.end, "HH:mm");

      if (slack.slack >= 0) {
        return null;
      } else {
        return (
          <TableRow key={index} className={classes.row}>
            <TableCell className={classes.underText}>
              {arr[index - 1] && arr[index - 1].skill === slack.skill
                ? ""
                : slack.skill}
            </TableCell>
            <TableCell className={classes.underText} align="left">
              {slack.office}
            </TableCell>
            <TableCell className={classes.underTimeText}>
              {startTime} - {endTime}
            </TableCell>
            <TableCell size="small">
              {slack.slack <= 0 ? (
                <WarningIcon fontSize="small" className={classes.icon} />
              ) : (
                ""
              )}
            </TableCell>
            <TableCell className={classes.underText} align="left">
              {slack.required}
            </TableCell>
            <TableCell className={classes.underText} align="left">
              {slack.assigned}
            </TableCell>
          </TableRow>
        );
      }
    });
  };

  return (
    <TableContainer>
      <Typography
        variant="h4"
        className={classes.text}
        style={{ marginLeft: 12, marginTop: 8 }}
      >
        {format(startDate, "dd MMM yyyy")}
      </Typography>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.text}>Skill</TableCell>
            <TableCell className={classes.text}>Office</TableCell>
            <TableCell className={classes.text}>Time</TableCell>
            <TableCell size="small"></TableCell>
            <TableCell className={classes.text}>Required</TableCell>
            <TableCell className={classes.text}>Scheduled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{slacksRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShiftSlack;
