import React, { useState } from 'react';
import { 
    Grid, Typography, Paper, 
    makeStyles 
} from '@material-ui/core';
import one from '../../../assets/quizImages/one.png';
import two from '../../../assets/quizImages/two.png';
import three from '../../../assets/quizImages/three.png';
import four from '../../../assets/quizImages/four.png';

const useStyles = makeStyles(() => ({
    tile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    clicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    },
    coloredText: {
        color: '#8CADE1'
    },
}));

const DaysOff = ({values, setFieldValue}) => {
    const classes = useStyles();

    const handleChange = (fieldName) => {
        if (values[fieldName] <= 0) {
            setFieldValue(fieldName, 1);
        } else {
            setFieldValue(fieldName, 0)
        }
    };
    
    return ( 
        <Grid container item direction='column' alignItems='center' spacing={5} style={{paddingTop: 25}}>
            {/* <Grid item>
                <Typography variant='h5'>Days off in a Row</Typography>
            </Grid> */}
            <Grid item align='center'>
                <Typography style={{fontSize: 24}}>
                    How many days <b className={classes.coloredText}>off</b> in a row do you normally like to have? 
                </Typography>
                <Typography style={{fontSize: 24}} >
                    You can choose more than one!
                </Typography>
            </Grid>
            <Grid item container justifyContent='center' style={{padding: 50, paddingTop: 0, paddingBottom: 0}}>
                <Grid 
                    component={Paper} 
                    className={values.oneDay > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('oneDay')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>One Day</Typography>
                    <img 
                        alt='one day' 
                        src={one} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.twoDays > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('twoDays')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Two Days</Typography>
                    <img 
                        alt='two days' 
                        src={two} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
            </Grid>
            <Grid item container justifyContent='center' style={{padding: 50, paddingTop: 0, paddingBottom: 0}}>
                <Grid 
                    component={Paper} 
                    className={values.threeDays > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('threeDays')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Three Days</Typography>
                    <img 
                        alt='three days' 
                        src={three} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.fourDays > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('fourDays')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Four Days</Typography>
                    <img 
                        alt='four days' 
                        src={four} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default DaysOff;