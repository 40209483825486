import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  CardActions,
  IconButton,
  Grid,
  Box,
  Select,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import Plot from "react-plotly.js";

const useStyles = makeStyles({
  root: {
    width: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

// displays graphs based on props. The graph libary we are using is Plotly.js

function AnalyticsWidget(props) {
  const classes = useStyles();
  const gridSize = parseInt(props.gridSize);
  const SetOpen = props.SetOpen;
  const SetChartType = props.SetChartType;
  const chartType = props.chartType;
  return props.open ? (
    <Grid item xs={gridSize}>
      <Card
        className={classes.root}
        variant="outlined"
        height="auto"
        component={Paper}
      >
        <Box style={{ textAlign: "right" }}>
          <IconButton color="secondary" onClick={() => SetOpen(false)}>
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>

        <CardContent>
          <Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography variant="h5">{props.header}</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {chartType != "4" && (
                      <Select
                        variant="outlined"
                        value={chartType}
                        onChange={(e) => SetChartType(e.target.value)}
                      >
                        <MenuItem key="0" value="0">
                          Select Chart Type
                        </MenuItem>
                        <MenuItem key="1" value="1">
                          Line
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          Area
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          Bar
                        </MenuItem>
                      </Select>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <div>
            <Plot
              data={props.data}
              layout={props.layout}
              style={{ display: "flex" }}
              useResizeHandler={true}
            />
          </div>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </Grid>
  ) : null;
}

export default AnalyticsWidget;
