import React, { useState } from 'react';
import { 
    Typography, Grid, IconButton, 
    Button, InputLabel, TextField, 
    makeStyles, Select, MenuItem,
    FormControlLabel, Switch
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { 
    CREATE_EMPLOYEE_AVAILABILITY, AVAILABILITY_TYPES,
    MANAGER_CREATE_EMPLOYEE_AVAILABILITY,
    BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY 
} from '../../api/gqlQueries';
import { useMutation, gql, useQuery } from '@apollo/client';
import { selectedDateVar, userVar } from '../../cache';
import Roles from '../../Roles/roles';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 138,
        maxWidth: 225,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }, 
    employeeInput: {
        width: 225,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }, 
    wideInput: {
        width: 575,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }, 
    textField: {
        width: 50
    },
    timeField: {
        width: 120,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.primary.main
    },
    helperText: {
        color: theme.palette.text.secondary
    },
    dateError: {
        color: theme.palette.primary.main,
        width: 225, 
        marginTop: -4,
        paddingLeft: 14,
        paddingRight: 14
    }
}));

const gqlFragment = gql`
    fragment NewEmployeeAvailability on EmployeeAvailabilityNode {
        id
        firstday
        lastday
        startTime
        endTime
        employee {
            id
            firstName
            lastName
        }
        approvedby {
            id
            firstName
            lastName
        }
        type {
            name
            id
        }
        office {
            id
        }
        workHours
        comment
        deniedText
        userCancelled
    }
`;

const TimeOffRequestForm = (props) => {
    const classes = useStyles();

    const {
        closeDialog, 
        invalidDates, 
        setToast, 
        setShowToast,
        employees,
        refetch,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,

    } = props;

    const user = userVar();
    const date = selectedDateVar();

    const managerAccess = user.role === Roles.MANAGER ||
        user.role === Roles.SCHEDULER || user.role === Roles.ADMIN;

    const [employee, setEmployee] = useState(user.id);
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(date);
    const [isAllDay, setIsAllDay] = useState(true);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [workHours, setWorkHours] = useState(0);
    const [type, setType] = useState('1');
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');
    const [endError, setEndError] = useState('');
    const [startError, setStartError] = useState('');

    const {data} = useQuery(AVAILABILITY_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on AVAILABILITY_TYPES Query. Environment: " + environment + ". Graphql " + error}});
    },

    });

    const [managerCreateTimeOff] = useMutation(MANAGER_CREATE_EMPLOYEE_AVAILABILITY, {
        update(cache, { data: { managerInitiateTimeOff } }) {
            cache.modify({
                fields: {
                    //availability comes from the field you called in your initial query; see GET_ALL_SHIFTS query
                    //existingEmployeeAvailability grabs all existing refs from cache
                    //EmployeeAvailabilityNode is typename of cached object; can view this from console.log when created
                    availability(existingEmployeeAvailability = []) {
                        const newAvailabilityRef = cache.writeFragment({
                            data: managerInitiateTimeOff.timeOffRequest,
                            fragment: gqlFragment
                        });
                        return [...existingEmployeeAvailability, newAvailabilityRef];
                    }
                }
            });
        },
        onCompleted(data) {
            console.log(data);
            setError('');
            setToast('New Time Off Request');
            setShowToast(true);
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Unable to create new time off request. Please check dates and try again.');
            notifyDevelopers({variables: {message: "Error on MANAGER_CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const [batchManagerCreateTimeOff] = useMutation(BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY, {
        onCompleted(data) {
            console.log(data);
            setError('');
            setToast('New Time Off Request');
            setShowToast(true);
            closeDialog();
            refetch();
        },
        onError(error) {
            console.log(error);
            setError('Unable to create new time off request. Please check dates and try again.');
            notifyDevelopers({variables: {message: "Error on BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const [createTimeOff] = useMutation(CREATE_EMPLOYEE_AVAILABILITY, {
        update(cache, { data: { createTimeOffRequestWithNotifications } }) {
            cache.modify({
                fields: {
                    //availability comes from the field you called in your initial query; see GET_ALL_SHIFTS query
                    //existingEmployeeAvailability grabs all existing refs from cache
                    //EmployeeAvailabilityNode is typename of cached object; can view this from console.log when created
                    availability(existingEmployeeAvailability = []) {
                        const newAvailabilityRef = cache.writeFragment({
                            data: createTimeOffRequestWithNotifications.timeOffRequest,
                            fragment: gqlFragment
                        });
                        return [...existingEmployeeAvailability, newAvailabilityRef];
                    }
                }
            });
        },
        onCompleted(data) {
            console.log(data);
            setError('');
            setToast('New Time Off Request');
            setShowToast(true);
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Unable to create new time off request. Please check dates and try again.');
            notifyDevelopers({variables: {message: "Error on CREATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const handleSubmit = () => {        
        let newEvent = {
            office: parseInt(user.office.id),
            type: type,
            workHours: parseInt(workHours),
            firstday: format(startDate, 'yyyy-MM-dd'),
            lastday: format(endDate, 'yyyy-MM-dd'),
            comment: comment,
        };
        if (startTime && endTime) {
            newEvent.startTime = `${startTime}:00`;
            newEvent.endTime = `${endTime}:00`;
        }

        if (managerAccess) {
            if (employee === 'All') {
                let timeOffInput = [];
                employees.forEach(employee => {
                    if (employee.id) {
                        const eventWithEmployee = {...newEvent};
                        console.log(employee.id)
                        eventWithEmployee.employee = parseInt(employee.id);
                        console.log(eventWithEmployee)
                        timeOffInput.push(eventWithEmployee);
                    }
                });
                
                batchManagerCreateTimeOff({variables: {
                    input: timeOffInput
                }});
            } else {
                newEvent.employee = parseInt(employee);
                managerCreateTimeOff({variables: {...newEvent}});
            }
        } else {
            newEvent.employee = parseInt(user.id);
            createTimeOff({variables: {...newEvent}});
        }
    };

    const checkInvalid = (date) => {
        if (employee !== user.id) {
            return false;
        } else {
            const formatted = format(date, 'MM/dd/yyyy');
            return invalidDates.includes(formatted);
        }
    };

    const eligibleToSave = Boolean(
        !checkInvalid(startDate) &&
        !checkInvalid(endDate) &&
        !endDate < startDate
    );

    const handleUpdateStart = (date) => {
        if(date && !isNaN(date.getTime())){
            const invalid = checkInvalid(date);
            if (!invalid || employee !== user.id) {
                setStartDate(date);
                setStartError('');
                if (date > endDate) {
                    setEndDate(date);
                }
            } else {
                setStartError('You must choose a date that does not already have a request');
            }
        } 
    }

    const handleUpdateEnd = (date) => {
        if(date && !isNaN(date.getTime())){
            const invalid = checkInvalid(date);
            if (!invalid || employee !== user.id) {
                setEndDate(date)
                setEndError('');
            } else {
                setEndError('You must choose a date that does not already have a request');
            }
        }
    }

    const handleWorkHoursChange = (event) => {
        setWorkHours(event.target.value);
    };

    let sortedTypes;
    if (data) {
        sortedTypes = [];
        const ordered = ['PTO', 'UPTO', 'TRAINING', 'Medical', 'Admin', 'OTHER'];
        ordered.forEach(name => {
            let match = data.availabilityTypes.find(type => (
                type.name === name
            ));
            if (match) {
                match = {name: match.name, id: match.id};
                if (match.name === 'TRAINING' || match.name === 'OTHER') {
                    match.name = match.name.slice(0,1) + 
                        match.name.slice(1).toLowerCase();
                    sortedTypes.push(match)
                } else {
                    sortedTypes.push(match)
                }
            }
        })
    };

    const handleIsAllDayChange = (e) => {
        setIsAllDay(e.target.checked);
        !e.target.checked && setEndDate(startDate);
        e.target.checked && setStartTime('');
        e.target.checked && setEndTime('');
    };
    
    return (  
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction='column' spacing={2}>
                <Grid item container justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h2'>
                            Add Calendar Event
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton 
                            aria-label='close' 
                            color='secondary' 
                            size='small' 
                            onClick={closeDialog} 
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isAllDay}
                                onChange={handleIsAllDayChange}
                                name="isAllDay"
                                color={
                                    isAllDay ? "primary" : "secondary"
                                }
                            />
                        }
                        label="All Day"
                    />
                </Grid>
                {managerAccess &&
                    <Grid item>
                        <Typography variant='h5'>Employee:</Typography>
                        <Select 
                            id='employee' 
                            name='employee' 
                            variant='outlined'  
                            value={employee} 
                            className={classes.employeeInput}
                            onChange={e => setEmployee(e.target.value)}
                        >
                            <MenuItem value='All'>All Employees</MenuItem>
                            {employees 
                                ? employees.map(employee => (
                                    <MenuItem 
                                        key={employee.id} 
                                        value={employee.id}
                                    >
                                        {employee.name}
                                    </MenuItem>
                                ))
                                : null
                            }
                        </Select>
                    </Grid>
                }
                <Grid item container justifyContent='flex-start' spacing={2}>
                    <Grid item >
                        <InputLabel htmlFor='start-date'>
                            <Typography variant='h5'>Start Date:</Typography>
                        </InputLabel>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            format='MM/dd/yyyy'
                            id='start-date'
                            shouldDisableDate={checkInvalid}
                            value={startDate}
                            onChange={handleUpdateStart}
                            className={classes.input}
                        />
                        {startError && 
                            <Typography variant='body2' className={classes.dateError}>
                                {startError}
                            </Typography>
                        }
                    </Grid>
                    <Grid item >
                        <InputLabel htmlFor='end-date'>
                            <Typography variant='h5'>End Date:</Typography>
                        </InputLabel>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant='inline'
                            inputVariant='outlined'
                            format='MM/dd/yyyy'
                            id='end-date'
                            shouldDisableDate={checkInvalid}
                            minDate={startDate}
                            minDateMessage='Date should not be before start date'
                            value={endDate}
                            onChange={handleUpdateEnd}
                            className={classes.input}
                            disabled={!isAllDay}
                        />
                        {endError && 
                            <Typography variant='body2' className={classes.dateError}>
                                {endError}
                            </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid item container spacing={2} alignItems='center'>
                    <Grid item>
                        <InputLabel htmlFor='start-time'>
                            <Typography variant='h5'>Start Time:</Typography>
                        </InputLabel>
                        <TextField
                            id={`start-time`}
                            name={`startTime`}
                            variant="outlined"
                            value={startTime}
                            onChange={e => setStartTime(e.target.value)}
                            type="time"
                            disabled={isAllDay}
                            className={classes.timeField}
                        />
                    </Grid>
                    <Grid item>
                        <InputLabel htmlFor='end-time'>
                            <Typography variant='h5'>End Time:</Typography>
                        </InputLabel>
                        <TextField
                            id={`end-time`}
                            name={`endTime`}
                            variant="outlined"
                            value={endTime}
                            onChange={e => setEndTime(e.target.value)}
                            type="time"
                            disabled={isAllDay}
                            className={classes.timeField}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} alignItems='center'>
                    <Grid item>
                        <InputLabel htmlFor='type'>
                            <Typography variant='h5'>Type of Request:</Typography>
                        </InputLabel>
                    </Grid>
                    <Grid item>
                        <Select 
                            id='type' 
                            name='type' 
                            variant='outlined' 
                            type='number' 
                            value={type} 
                            className={classes.input}
                            onChange={e => setType(e.target.value)}
                        >
                            {sortedTypes 
                                ? sortedTypes.map(type => (
                                    <MenuItem 
                                        key={type.name + type.id} 
                                        value={type.id}
                                    >
                                        {type.name}
                                    </MenuItem>
                                ))
                                : null
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} alignItems='center'>
                    <Grid item >
                        <InputLabel htmlFor='work-hours'>
                            <Typography variant='h5'>Hours Used:</Typography>
                        </InputLabel>
                    </Grid>
                    <Grid item>
                        <TextField 
                            id='work-hours' 
                            name='work-hours' 
                            variant='outlined' 
                            type='number' 
                            value={workHours} 
                            className={classes.textField}
                            onChange={handleWorkHoursChange}
                        />
                    </Grid>
                </Grid>
                <Grid item >
                    <InputLabel htmlFor='comment'>
                        <Typography variant='h5'>Comment:</Typography>
                    </InputLabel>
                    <TextField 
                        id='comment' 
                        name='comment' 
                        variant='outlined' 
                        value={comment} 
                        className={classes.wideInput}
                        onChange={e => setComment(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    {error && <Typography className={classes.error}>{error}</Typography>}
                </Grid>
                <Grid 
                    item 
                    container 
                    justifyContent='flex-end' 
                    style={{zIndex: 4, position: 'absolute', bottom: 25, right: 30}}
                >
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={handleSubmit} 
                            disabled={employee === user.id 
                                ? !eligibleToSave : false
                            }
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};
 
export default TimeOffRequestForm;