import React from "react";
import ReactDOM from "react-dom";
import App from "./features/general/App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
// import { appsettingsVar } from './cache';
import { createUploadLink } from "apollo-upload-client";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNiXH5ccHFXTmNYVk0="
);
export const localDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const environment = process.env.NODE_ENV;
const baseURL = window.location.origin;
let backend = "";

//get backend url based on development environment
if (environment === "development") {
  backend = "https://backendtest.balancescheduler.com/graphql";
} else if (baseURL.includes("amplify")) {
  backend = "/api/graphql/";
} else {
  backend = "/api/graphql/";
}

const httpLink = createHttpLink({
  //uri: 'http://test-dev-scheduler.us-east-2.elasticbeanstalk.com/graphql',
  uri: backend,
  //uri: 'https://backenddemo.balancescheduler.com/graphql'
});

const uploadLink = createUploadLink({
  // uri: 'https://backenddemo.balancescheduler.com/graphql'
  uri: backend,
  // uri: 'https://mission-demo-backend.balancescheduler.com/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("jwt");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  // uri: 'http://localhost:8000/graphql',
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
