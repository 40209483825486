import React from 'react';
import { 
    Grid, Typography, FormLabel,
    FormControlLabel, RadioGroup, Radio,
    Paper, makeStyles
} from '@material-ui/core';
import thumbsup from '../../../assets/quizImages/thumbsup.png';
import thumbsdown from '../../../assets/quizImages/thumbsdown.png';

const useStyles = makeStyles(() => ({
    tile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    clicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    }
}));

const ShiftQuestion = ({hiredForShift, values, setFieldValue, handleWorkAdditionalClick}) => {
    const classes = useStyles();

    return ( 
        <Grid container item direction='column' alignItems='center' spacing={5} style={{padding: 25}}>
            {/* <Grid item>
                <Typography variant='h5'>Shift Type</Typography>
            </Grid> */}
            <Grid item>
                <Typography style={{fontSize: 24}}>
                    You were hired for <b>{hiredForShift}</b>
                </Typography>
            </Grid>
            <Grid item align='center'>
                <Typography style={{fontSize: 24}}>
                    Are you willing to work a different shift than what you were hired for?
                </Typography>
            </Grid>
            <Grid item container justifyContent='center' style={{padding: 50, paddingTop: 16, paddingBottom: 0}}>
                <Grid 
                    component={Paper} 
                    className={values.workAdditionalShifts === true ? classes.clicked : classes.tile} 
                    onClick={() => handleWorkAdditionalClick(true, setFieldValue)}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Yes</Typography>
                    <img 
                        alt='yes' 
                        src={thumbsup} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.workAdditionalShifts === false ? classes.clicked : classes.tile} 
                    onClick={() => handleWorkAdditionalClick(false, setFieldValue)}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>No</Typography>
                    <img 
                        alt='no' 
                        src={thumbsdown} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default ShiftQuestion;