import React, { useState } from "react";
import {
  LOCATION_DISTRIBUTION_ANALYTICS,
  GET_EMPLOYEES_OF_CHILD_OFFICES,
  GET_EMPLOYEE_NAMES,
} from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import {
  CircularProgress,
  Grid,
  Box,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
import AnalyticsWidget from "./AnalyticsGraphWidget";
import { userVar } from "../../cache";
import { format, isSameDay } from "date-fns";

const LocationPreferenceDashboard = () => {
  const user = userVar();
  const [selectedChildOffice, SetSelectedChildOffice] = useState("0");
  const [selectedEmployee, SetSelectedEmployee] = useState("0");
  const [childOfficeEmployees, SetChildOfficeEmployees] = useState([]);
  const [userOfficeEmployees, SetUserOfficeEmployees] = useState([]);
  const [allOffices, SetAllOffices] = useState([]);
  const { loading, data } = useQuery(LOCATION_DISTRIBUTION_ANALYTICS, {
    onCompleted(d) {
      console.log(d);
    },
  });

  const getChildOfficeEmployees = useQuery(GET_EMPLOYEES_OF_CHILD_OFFICES, {
    variables: {
      parent: parseInt(user.office.id),
    },
    onCompleted(d) {
      const userofcemps = d.getChildren.map((e) => e.employeeSet).flat();
      SetAllOffices(d.getChildren);
      SetChildOfficeEmployees(userofcemps);
    },
  });

  const getEmployees = useQuery(GET_EMPLOYEE_NAMES, {
    variables: {
      office: parseInt(user.office.id),
    },
    onCompleted(d) {
      SetUserOfficeEmployees(d.offices[0].employeeSet);
    },
  });

  const getEmployeeDetails = (e) => {
    let allEmps = [...childOfficeEmployees, ...userOfficeEmployees];
    let emp =
      allEmps.length > 0 && allEmps.find((x) => parseInt(x.id) === parseInt(e));
    if (emp) {
      return emp;
    } else {
      return null;
    }
  };

  if (loading || getChildOfficeEmployees.loading || getEmployees.loading) {
    return <CircularProgress color="primary" />;
  } else {
    let formattedData = data.locationDistributionAnalytics
      .map((e) => {
        return {
          employee: e.employee,
          employeeName: getEmployeeDetails(e.employee),
          id: e.id,
          month: e.month,
          office: e.office,
          totalHours: e.totalHours,
        };
      })
      .filter((e) => e.employeeName != null);

    let filteredData = formattedData
      .filter((e) =>
        selectedChildOffice != "0"
          ? parseInt(e.office) === parseInt(selectedChildOffice)
          : e.office
      )
      .filter((e) =>
        selectedEmployee != "0"
          ? parseInt(e.employee) === parseInt(selectedEmployee)
          : e.employee
      );

    const dataForGraph = getChildOfficeEmployees.data.getChildren.map(
      (offce) => {
        return {
          x: filteredData.map(
            (e) => e.employeeName.firstName[0] + " " + e.employeeName.lastName
          ),
          y: filteredData.map((e) => e.totalHours).filter(Boolean),
          name: offce.name,
          type: "bar",
        };
      }
    );
    let allEmps = [...childOfficeEmployees, ...userOfficeEmployees];

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="h3">Location Preferences</Typography>
          </Box>
        </Grid>

        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <Typography>View Data For: </Typography>
          </Grid>
          <Grid item>
            {user.isPrimaryParentOffice && (
              <Box m={2}>
                <Select
                  variant="outlined"
                  value={selectedChildOffice}
                  onChange={(e) => {
                    SetSelectedChildOffice(e.target.value);
                  }}
                >
                  <MenuItem key="0" value="0">
                    Select Office
                  </MenuItem>
                  {getChildOfficeEmployees.data.getChildren.length > 0 &&
                    getChildOfficeEmployees.data.getChildren.map((e, index) => (
                      <MenuItem key={index} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}
          </Grid>
          <Grid item>
            {user.isPrimaryParentOffice && (
              <Box m={2}>
                <Select
                  variant="outlined"
                  value={selectedEmployee}
                  onChange={(e) => {
                    SetSelectedEmployee(e.target.value);
                  }}
                >
                  <MenuItem key="0" value="0">
                    Select Employee
                  </MenuItem>
                  {allEmps.length > 0 &&
                    allEmps.map((e, index) => (
                      <MenuItem key={index} value={e.id}>
                        {e.firstName + " " + e.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AnalyticsWidget
            data={dataForGraph}
            layout={{ barmode: "stack" }}
            gridSize={10}
            chartType="4"
            SetChartType={() => null}
            open={true}
            SetOpen={() => null}
            header="Employee Location Preferences"
          />
        </Grid>
      </Grid>
    );
  }
};

export default LocationPreferenceDashboard;
