import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { CSVLink } from "react-csv";
import "./MissionCalendar.css";
import {
  Typography,
  Button,
  Grid,
  Paper,
  makeStyles,
  DialogContent,
  Dialog,
  Popover,
  Snackbar,
  CircularProgress,
  Badge,
  MenuItem,
  Box,
  // IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import TuneIcon from "@material-ui/icons/Tune";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
// import CloseIcon from "@material-ui/icons/Close";
import ShiftSwitchRequestForm from "../shiftSwitch/ShiftSwitchRequestForm";
import EmpCallInForm from "./EmpCallInForm";
// import ShiftEvent from "./ShiftEvent";
import SoftTimeOff from "./SoftTimeOff";
import EditSoftTimeOff from "./EditSoftTimeOff";
import TimeOffRequestForm from "./TimeOffRequestForm";
import EditTimeOffRequestForm from "./EditTimeOffRequestForm";
import ShiftSlackBySkill from "../slacks/ShiftSlackBySkill";
import Roles from "../../Roles/roles";
import MangEditShift from "./MangEditShift";
import {
  formatTimeOff,
  formatSoftRequests,
  orderShifts,
} from "../../helpers/formatMissionEvents";
import {
  format,
  add,
  sub,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
} from "date-fns";
import FilterMenu from "./FilterMenu";
import Scheduler from "./Scheduler";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  filterListVar,
  userVar,
  selectedDateVar,
  locationConfigVar,
} from "../../cache";
import {
  SCHEDULE_FITNESS,
  GET_EMPLOYEE_NAMES,
  AVAILABILITY_TYPES,
  GET_RELEVANT_SKILLS,
  INTERVAL_SLACKS,
  EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
  GET_EMPLOYEES_OF_CHILD_OFFICES,
  GET_MISSIONS,
} from "../../api/gqlQueries";
import AddProcedure from "./AddProcedure";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    marginTop: -7,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
  downloadPopover: {
    textAlign: "left",
  },
}));

function EmpCalendar({
  schedulePeriods,
  refetchSchedulePeriods,
  setOpenSnackBar,
  setSnackBarMessage,
  notifyDevelopers,
  environment,
}) {
  const classes = useStyles();

  const user = userVar();
  const locationConfig = locationConfigVar();
  const selectedDate = useReactiveVar(selectedDateVar);
  const primaryUnit = user.office;
  const userRole = user.role;

  const managerAccess =
    userRole === Roles.MANAGER ||
    userRole === Roles.SCHEDULER ||
    userRole === Roles.LEAD ||
    userRole === Roles.ADMIN;

  useQuery(AVAILABILITY_TYPES, {
    onCompleted(data) {
      let sortedTypes = [];
      const ordered = ["PTO", "UPTO", "TRAINING", "Medical", "Admin", "OTHER"];
      ordered.forEach((name) => {
        let match = data.availabilityTypes.find((type) => type.name === name);
        if (match) {
          match = { name: match.name, id: match.id };
          if (match.name === "TRAINING" || match.name === "OTHER") {
            match.name =
              match.name.slice(0, 1) + match.name.slice(1).toLowerCase();
            sortedTypes.push(match);
          } else {
            sortedTypes.push(match);
          }
        }
      });
      setSortedAvailabilityTypes(sortedTypes);
      const requestFilters = sortedTypes.map((type) => type.name);

      if (!managerAccess) {
        const all = [
          ...filterList.allFilters,
          ...requestFilters,
          // 'Soft Requests'
        ];
        const requests = [
          ...filterList.requestsFilters,
          ...requestFilters,
          // 'Soft Requests'
        ];
        filterListVar({
          ...filterList,
          allFilters: all,
          requestsFilters: requests,
        });
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useQuery(GET_RELEVANT_SKILLS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onCompleted(data) {
      const jobTypes = data.requiredSkills.filter(
        (skill) => skill.variety === "JOB_TYPE"
      );
      setJobTypes(jobTypes);

      const jobTypeNames = jobTypes.map((skill) => skill.name);

      const all = [...filterList.allFilters, ...jobTypeNames, "All Job Type"];

      filterListVar({
        ...filterList,
        allFilters: all,
        jobTypeFilters: ["All Job Type", ...jobTypeNames],
      });
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const notViewable = schedulePeriods.find(
    (schedule) =>
      !schedule.status === "PUBLISHED" || !schedule.status === "MANAGER_DRAFT"
  );
  const releasedSchedules = schedulePeriods.filter(
    (schedule) =>
      schedule.status === "PUBLISHED" ||
      schedule.status === "MANAGER_DRAFT" ||
      schedule.status === "MANAGER DRAFT" ||
      schedule.status === "READY" ||
      schedule.status === "OPEN"
  );

  const allSchedules = [...schedulePeriods];

  const mostRecentReleased =
    releasedSchedules.length > 0
      ? releasedSchedules.sort(
          (a, b) => new Date(b.start) - new Date(a.start)
        )[0]
      : null;

  const mostRecentIncludingDraft =
    allSchedules.length > 0
      ? allSchedules.sort((a, b) => new Date(b.start) - new Date(a.start))[0]
      : null;

  let draftStart;
  let draftEnd;
  let openStart;
  let openEnd;
  if (mostRecentIncludingDraft) {
    if (
      mostRecentIncludingDraft.status === "MANAGER_DRAFT" ||
      mostRecentIncludingDraft.status === "MANAGER DRAFT" ||
      mostRecentIncludingDraft.status === "DRAFT"
    ) {
      draftStart = new Date(`${mostRecentIncludingDraft.start}T12:00:00`);
      draftEnd = sub(new Date(`${mostRecentIncludingDraft.end}T12:00:00`), {
        hours: 12,
      });
    } else if (notViewable && managerAccess) {
      draftStart = new Date(`${notViewable.start}T12:00:00`);
      draftEnd = sub(new Date(`${notViewable.end}T12:00:00`), { hours: 12 });
    }
  }

  //get all the schedules in open state
  const allSchedulesInOpenState =
    allSchedules.length > 0
      ? allSchedules.filter((e) => e.status === "OPEN" || e.status === "READY")
      : null;
  allSchedulesInOpenState &&
    allSchedulesInOpenState.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );

  if (allSchedulesInOpenState && allSchedulesInOpenState.length > 0) {
    let managerManualScheduling = allSchedulesInOpenState.filter(
      (e) => e.status === "READY" || e.status === "OPEN"
    );
    managerManualScheduling.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
    if (managerAccess && managerManualScheduling.length > 0) {
      openStart = new Date(`${managerManualScheduling[0].start}T12:00:00`);
      openEnd = new Date(
        `${
          managerManualScheduling[parseInt(managerManualScheduling.length) - 1]
            .end
        }T12:00:00`
      );
    }
  }

  allSchedules.sort((a, b) => new Date(b.start) - new Date(a.start));
  const scheduleEndDate = allSchedules.length > 0 ? allSchedules[0].end : null;

  function getVariables(startDate, endDate) {
    let variables;
    if (notViewable && managerAccess) {
      variables = {
        office: parseInt(primaryUnit.id),
        officeId: parseInt(primaryUnit.id),
        employeeId: parseInt(user.id),
        rangeStart: startDate && startDate.toISOString(),
        rangeEnd: endDate && endDate.toISOString(),
      };
    } else if (mostRecentReleased) {
      variables = {
        office: parseInt(primaryUnit.id),
        officeId: parseInt(primaryUnit.id),
        employeeId: parseInt(user.id),
        rangeStart: startDate && startDate.toISOString(),
        rangeEnd: endDate && endDate.toISOString(),
      };
    } else if (!mostRecentReleased && notViewable) {
      const rangeEnd = `${notViewable.start}T08:00:00`;

      let end;
      if (new Date(rangeEnd) < endDate) {
        end = rangeEnd;
      } else {
        end = endDate;
      }

      variables = {
        office: parseInt(primaryUnit.id),
        officeId: parseInt(primaryUnit.id),
        employeeId: parseInt(user.id),
        rangeStart: startDate && startDate.toISOString(),
        rangeEnd: rangeEnd,
      };
    } else {
      variables = {
        office: parseInt(primaryUnit.id),
        officeId: parseInt(primaryUnit.id),
        employeeId: parseInt(user.id),
        rangeStart: startDate && startDate.toISOString(),
        rangeEnd: endDate && endDate.toISOString(),
      };
    }
    return variables;
  }

  const today = new Date();
  let firstDayOfCurrentView = startOfMonth(today);
  let lastDayOfCurrentView = endOfMonth(today);

  const variablesForInitialQuery = getVariables(
    firstDayOfCurrentView,
    lastDayOfCurrentView
  );

  const sixmonthsago = sub(new Date(), { months: 6 });
  const sixMonthsFromNow = add(new Date(), { months: 6 });

  const { loading, error, data, refetch } = useQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      variables: variablesForInitialQuery,
      onCompleted(d) {
        let newScheduleData = orderShifts(d.sqlShiftAssignments2);
        newScheduleData.userShifts = [...d.userShifts];
        newScheduleData = { ...scheduleData, ...newScheduleData };
        setScheduleData(newScheduleData);
        const futureVariables = getVariables(
          sub(lastDayOfCurrentView, { days: 1 }),
          sixMonthsFromNow
        );
        const pastVariables = getVariables(
          sixmonthsago,
          add(firstDayOfCurrentView, { days: 1 })
        );
        getFutureShifts({
          variables: futureVariables,
        });
        getPastShifts({
          variables: pastVariables,
        });
        const currentAndFutureSchedules = schedulePeriods.filter(
          (schedule) => new Date(`${schedule.end}T23:00:00`) > new Date()
        );

        currentAndFutureSchedules.sort(
          (a, b) =>
            new Date(`${a.start}T23:00:00`) - new Date(`${b.start}T23:00:00`)
        );

        if (currentAndFutureSchedules.length > 0) {
          const rangeStart = currentAndFutureSchedules[0].start + "T00:00:00";
          const rangeEnd =
            currentAndFutureSchedules[currentAndFutureSchedules.length - 1]
              .end + "T23:00:00";
          if (managerAccess) {
            getSlackIssues({
              variables: {
                rangeStart: rangeStart,
                rangeEnd: rangeEnd,
                office: parseInt(primaryUnit.id),
                issuesOnly: true,
              },
            });
            getSlack({
              variables: {
                rangeStart: rangeStart,
                rangeEnd: rangeEnd,
                office: parseInt(primaryUnit.id),
                issuesOnly: false,
              },
            });
          }
        }
      },
    }
  );

  const [getFutureShifts, { data: futureData }] = useLazyQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_MORE_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (futureData) {
      const newScheduleData = addDataToCurrentScheduleData(futureData);
      setScheduleData(newScheduleData);
    }
  }, [futureData]);

  // const missionVariables = getVariables(oneYearAgo, sixMonthsFromNow);
  //const missionEnd = format(sixMonthsFromNow, "yyyy-MM-dd");

  // const { data: missionData, refetch: refetchMissions } = useQuery(
  //   GET_MISSIONS,
  //   {
  //     variables: {
  //       office: parseInt(primaryUnit.id),
  //       end: missionEnd,
  //     },
  //     onError(error) {
  //       console.log(error);
  //       setOpenSnackBar(true);
  //       setSnackBarMessage(
  //         "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
  //       );
  //       notifyDevelopers({
  //         variables: {
  //           message:
  //             "Error on GET_MISSIONS Query. Environment: " +
  //             environment +
  //             ". Graphql " +
  //             error,
  //         },
  //       });
  //     },
  //   }
  // );

  const { data: employeeData, loading: employeeDataLoading } = useQuery(
    GET_EMPLOYEE_NAMES,
    {
      variables: { office: parseInt(primaryUnit.id) },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_EMPLOYEE_NAMES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const { data: childOfficeEmployeeData } = useQuery(
    GET_EMPLOYEES_OF_CHILD_OFFICES,
    {
      variables: { parent: parseInt(primaryUnit.id) },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_ALL_CHILD_OFFICES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [getPastShifts, { data: pastData }] = useLazyQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_MORE_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (pastData) {
      const newScheduleData = addDataToCurrentScheduleData(pastData);
      setScheduleData(newScheduleData);
    }
  }, [pastData]);

  const ref = React.useRef();
  const params = useParams();

  const filterList = useReactiveVar(filterListVar);
  const allFilters = filterList.allFilters;

  const [scheduleData, setScheduleData] = useState({
    formatted: [],
    userShifts: [],
    shiftNames: [],
  });
  const [sortedAvailabilityTypes, setSortedAvailabilityTypes] = useState([]);
  const [allSlackDates, setAllSlackDates] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [allSlacks, setAllSlacks] = useState([]);
  const [slackIssues, setSlackIssues] = useState([]);
  const [issueDates, setIssueDates] = useState([]);
  const [issueDateswithProcedureName, setIssueDateswithProcedureName] =
    useState([]);
  const [showShiftSlackBySkill, setShowShiftSlackBySkill] = useState(false);
  const [view, setView] = useState("month");
  const [shiftSwitch, setShiftSwitch] = useState(false);
  const [callIn, setCallIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shiftSwitchApproval, setShiftSwitchApproval] = useState(false);
  const [timeOffApproval, setTimeOffApproval] = useState(false);
  const [editShift, setEditShift] = useState(false);
  const [shiftToEdit, setShiftToEdit] = useState();
  const [shiftToChange, setShiftToChange] = useState();
  const [softRequest, setSoftRequest] = useState(false);
  const [softRequestIdToEdit, setSoftRequestIdToEdit] = useState();
  const [editSoftRequest, setEditSoftRequest] = useState(false);
  const [timeOffRequestIdToEdit, setTimeOffRequestIdToEdit] = useState();
  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const [editTimeOffRequest, setEditTimeOffRequest] = useState(false);
  const [procedureReqForm, setprocedureReqForm] = useState(false);
  const [procedureRequirementEvent, setProcedureRequirementEvent] = useState();
  const [openDownload, setopenDownload] = useState(null);
  const [toast, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [errorToast, setErrorToast] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const openD = Boolean(openDownload);
  const anchorId = openD ? "download-popover" : undefined;
  const [employeeResources, setEmployeeResources] = useState([]);

  const [allFitnesses, SetallFitnesses] = useState([]);
  const [empFitness, SetempFitness] = useState({
    score: "",
    maxScore: "",
    ratio: "",
  });

  const [scheduleFitness] = useLazyQuery(SCHEDULE_FITNESS, {
    onCompleted(data) {
      SetallFitnesses(
        data.employeeFitnesses.filter((e) => e.employee.id === user.id)
      );
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_FITNESS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    let a =
      allSchedules.length > 0 &&
      allSchedules.find(
        (x) =>
          new Date(x.start) <= new Date(selectedDate) &&
          new Date(x.end) >= new Date(selectedDate)
      );
    if (a) {
      scheduleFitness({
        schedulePeriod: parseInt(a.id),
      });
    }
  }, []);

  useEffect(() => {
    let a =
      allSchedules.length > 0 &&
      allSchedules.find(
        (x) =>
          new Date(x.start) <= new Date(selectedDate) &&
          new Date(x.end) >= new Date(selectedDate)
      );
    if (a) {
      scheduleFitness({
        schedulePeriod: parseInt(a.id),
      });
    } else {
      SetempFitness({
        score: "",
        maxScore: "",
        ratio: "",
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    let emp = allFitnesses.find(
      (x) =>
        new Date(x.schedulePeriod.start) <= new Date(selectedDate) &&
        new Date(x.schedulePeriod.end) >= new Date(selectedDate)
    );
    if (emp) {
      SetempFitness({
        score: emp.score,
        maxScore: emp.maxScore,
        ratio: emp.ratio,
      });
    } else {
      SetempFitness({
        score: "",
        maxScore: "",
        ratio: "",
      });
    }
  }, [allFitnesses, selectedDate]);

  const [getSlackIssues, { data: slackIssuesData }] = useLazyQuery(
    INTERVAL_SLACKS,
    {
      onCompleted(d) {
        setSlackIssues(d.intervalSlacks);
        let datesWithSlackInfo = [];
        let isseDates = [];
        let issueDatesprocedurename = [];
        d.intervalSlacks.forEach((slack) => {
          const start = format(new Date(slack.interval.start), "MM/dd/yyyy");
          !isseDates.includes(start) && isseDates.push(start);
          !datesWithSlackInfo.includes(start) && datesWithSlackInfo.push(start);
          !issueDatesprocedurename.find(
            (e) =>
              slack.interval.shifts[0].procedure != null &&
              e.procedureName ===
                slack.interval.shifts[0].procedure.procedureRequirement.name
          ) &&
            issueDatesprocedurename.push({
              id: slack.id,
              issueDate: start,
              procedureName:
                slack.interval.shifts[0].procedure &&
                slack.interval.shifts[0].procedure.procedureRequirement.name,
            });
        });
        setIssueDates(isseDates);
        setIssueDateswithProcedureName(issueDatesprocedurename);
        setAllSlackDates(datesWithSlackInfo);
        setAllSlacks([...d.intervalSlacks]);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_SLACK lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [getSlack, { loading: slacksLoading }] = useLazyQuery(INTERVAL_SLACKS, {
    onCompleted(d) {
      let datesWithSlackInfo = [];
      d.intervalSlacks.forEach((slack) => {
        const start = format(new Date(slack.interval.start), "MM/dd/yyyy");
        !datesWithSlackInfo.includes(start) && datesWithSlackInfo.push(start);
      });
      setAllSlackDates(datesWithSlackInfo);
      setAllSlacks(d.intervalSlacks);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SLACK lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (employeeData) {
      let data = employeeData.offices[0].employeeSet;
      addEmployeeData(data);
    }
  }, [employeeData]);

  useEffect(() => {
    if (childOfficeEmployeeData) {
      let data = childOfficeEmployeeData.getChildren
        .map((e) => e.employeeSet)
        .flat();
      addEmployeeData(data);
    }
  }, [childOfficeEmployeeData]);

  const combineGhocShifts = (events) => {
    const nonGhoc = events.filter((event) => event.eventTitle !== "GHOC");
    const ghoc = events.filter((event) => event.eventTitle === "GHOC");

    const noDuplicates = [];
    ghoc.forEach((event) => {
      const exists = noDuplicates.find(
        (newEvent) => event.eventId === newEvent.eventId
      );
      if (exists) {
        const newIds = event.employeeIds.filter(
          (id) => !exists.employeeIds.includes(id)
        );
        exists.employeeIds = [...exists.employeeIds, ...newIds];
      } else {
        noDuplicates.push(event);
      }
    });

    return [...nonGhoc, ...noDuplicates];
  };

  const addDataToCurrentScheduleData = (data) => {
    let currentScheduleData = { ...scheduleData };
    let userShifts = [...currentScheduleData.userShifts];
    let shiftIds = currentScheduleData.formatted.map((event) => event.eventId);
    let userShiftIds = userShifts.map((shift) => shift.shift.id);

    const newEvents = data.sqlShiftAssignments2.filter(
      (shift) => !shiftIds.includes(shift.id)
    );
    const newIds = newEvents.map((event) => event.id);

    shiftIds = [...shiftIds, ...newIds];

    const newData = orderShifts(newEvents);
    currentScheduleData.formatted = [
      ...currentScheduleData.formatted,
      ...newData.formatted,
    ];
    currentScheduleData.formatted.sort((a, b) => a.start - b.start);
    currentScheduleData.formatted = combineGhocShifts(
      currentScheduleData.formatted
    );

    const newShiftNames = newData.shiftNames.filter(
      (shift) => !currentScheduleData.shiftNames.includes(shift)
    );

    currentScheduleData.shiftNames = [
      ...currentScheduleData.shiftNames,
      ...newShiftNames,
    ];
    currentScheduleData.shiftNames.sort();

    const newMissionNames = newData.missionNames.filter(
      (shift) => !currentScheduleData.missionNames.includes(shift)
    );
    currentScheduleData.missionNames = [
      ...currentScheduleData.missionNames,
      ...newMissionNames,
    ];

    const newUserShifts = data.userShifts.filter(
      (shift) => !userShiftIds.includes(shift.shift.id)
    );
    const newUserShiftIds = newUserShifts.map((shift) => shift.shift.id);

    userShifts = [...userShifts, ...newUserShifts];
    userShiftIds = [...userShiftIds, ...newUserShiftIds];

    currentScheduleData.userShifts = userShifts;
    return currentScheduleData;
  };

  const addEmployeeData = (data) => {
    const resources = data.map((employee) => {
      if (parseInt(employee.id) === parseInt(user.id)) {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#F5E1DB",
          skills: employee.skills.map((e) => e.name),
        };
      } else {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#DFE0E2",
          skills: employee.skills.map((e) => e.name),
        };
      }
    });
    const newResources = [...employeeResources, ...resources];
    setEmployeeResources(newResources);
  };

  useEffect(() => {
    const availabilityTypes = sortedAvailabilityTypes.map((type) => type.name);

    if (managerAccess && params.request) {
      // if (params.request === "time-off-calendar") {
      //   setTimeOffApproval(false);
      //   setShiftSwitchApproval(false);
      //   const pendingRequests = data
      //     ? data.timeOff.filter((request) => request.approvedby === null)
      //     : [];
      //   const earliestRequest =
      //     pendingRequests.length > 0
      //       ? pendingRequests.sort(
      //           (a, b) => new Date(a.firstday) - new Date(b.firstday)
      //         )[0]
      //       : null;
      //   const earliestDate = earliestRequest
      //     ? new Date(`${earliestRequest.firstday}T08:00:00`)
      //     : new Date();
      //   selectedDateVar(earliestDate);
      //   setView("Month");
      //   filterListVar({
      //     // allFilters: ["All Shifts", "All Pending"],
      //     allFilters: ["All Shifts", ...availabilityTypes],
      //     shiftsFilters: ["All Shifts"],
      //     missionFilters: [],
      //     requestsFilters: [...availabilityTypes],
      //     // requestsFilters: ["All Pending"],
      //   });
      // } else if (params.request === "time-off") {
      //   showTimeOffApproval();
      // } else if (params.request === "shift-switch") {
      //   showShiftSwitchApproval();
      // }
    } else if (params.date) {
      const showDate = new Date(params.date);
      selectedDateVar(showDate);
      refetchSchedulePeriods();
      if (managerAccess) {
        filterListVar({
          allFilters: ["All Shifts", "All Missions", "All Job Type"],
          shiftsFilters: ["All Shifts"],
          missionFilters: ["All Missions"],
          requestsFilters: [],
          jobTypeFilters: ["All Job Type"],
        });
      } else {
        filterListVar({
          allFilters: [
            "Personal",
            "All Missions",
            "Soft Requests",
            "Pending",
            "Approved",
            ...availabilityTypes,
            "All Requests",
            "All Job Type",
          ],
          shiftsFilters: ["Personal"],
          missionFilters: ["All Missions"],
          requestsFilters: [
            "Soft Requests",
            "Pending",
            "Approved",
            ...availabilityTypes,
            "All Requests",
          ],
          jobTypeFilters: ["All Job Type"],
        });
      }
      getFutureShifts({
        variables: {
          officeId: parseInt(primaryUnit.id),
          employeeId: parseInt(user.id),
          rangeStart: params.date,
        },
      });
    }
  }, []);

  const toggleShiftSwitchForm = () => setShiftSwitch(!shiftSwitch);
  const toggleCallInForm = () => setCallIn(!callIn);
  const toggleEditShift = () => setEditShift(!editShift);
  const toggleSoftRequest = () => setSoftRequest(!softRequest);
  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);
  const toggleEditTimeOffForm = () =>
    setEditTimeOffRequest(!editTimeOffRequest);
  const toggleEditSoftRequestForm = () => setEditSoftRequest(!editSoftRequest);
  const toggleProcedureRequirementForm = () => {
    setprocedureReqForm(!procedureReqForm);
  };

  // const showShiftSwitchApproval = () => {
  //   // setShowDayActions(false);
  //   setShiftSwitchApproval(true);
  //   setTimeOffApproval(false);
  // };

  // const showTimeOffApproval = () => {
  //   // setShowDayActions(false);
  //   setTimeOffApproval(true);
  //   setShiftSwitchApproval(false);
  // };

  const handleEditClick = (shift) => {
    setShiftToEdit(shift);
    toggleEditShift();
  };

  const handleEditProcedureReq = (event) => {
    setProcedureRequirementEvent(event);
    toggleProcedureRequirementForm();
  };

  // const handleCallInClick = (date) => {
  //   selectedDateVar(date);
  //   toggleCallInForm();
  // };

  const toastMessage = () => {
    const toasts = {
      "Employee Shift Switch": "Shift switch request sent!",
      "Manager Shift Switch": "Shift switch successful! Employees notified.",
      "New Time Off Request": "Time off request sent for approval!",
      "Edit Time Off Request":
        "Time off request updated and sent for approval!",
      "Delete Time Off Request": "Time off request deleted!",
      "New Soft Request": "Soft request entered successfully!",
      "Edit Soft Request": "Soft request updated successfully!",
      "Delete Soft Request": "Soft request deleted!",
      "Call In": "Call in successful!",
      "Manager Edit Shift": "Shift updated and employees notified!",
      "Manager Approve Time Off Request": "Time off request approved!",
      "Manager Deny Time Off Request": "Time off request denied!",
      "Manager Approve Shift Switch": "Shift switch request approved!",
      "Manager Deny Shift Switch": "Shift switch request denied!",
      "Delete Procedure": "Procedure successfully deleted!",
    };
    return toasts[toast] ? toasts[toast] : "Success!";
  };

  const errorToastMessage = () => {
    const errorToasts = {
      "Non Eligible Shift Switch": "No eligible shifts to switch.",
      "Error Edit Shift": "Unable to edit shift. Please try again.",
      "Error Call In": "Unable to call in. Please try again.",
      "Error Approve Time Off":
        "Unable to approve time off request. Please try again.",
      "Error Deny Time Off":
        "Unable to deny time off request. Please try again.",
      "Error Approve Shift Switch":
        "Unable to approve shift switch request. Please try again.",
      "Error Deny Shift Switch":
        "Unable to deny shift switch request. Please try again.",
      "Error Delete Soft Request":
        "Unable to delete soft request. Please try again.",
      "Error Delete Time Off":
        "Unable to delete time off request. Please try again.",
    };
    return errorToasts[errorToast]
      ? errorToasts[errorToast]
      : "Error. Please try again.";
  };

  if (loading || !employeeData) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const events = [...scheduleData.formatted];
    const userEvents = events.filter(
      (event) => event.employeeIds && event.employeeIds.includes(user.id)
    );
    const shiftNames = scheduleData.shiftNames
      ? scheduleData.shiftNames.filter(Boolean)
      : [];

    let missionResources = 
      scheduleData &&
      scheduleData.missionNames &&
      scheduleData.missionNames.length > 0 &&
      scheduleData.missionNames.map((name) => {
        return {
          id: name,
          name: name,
        };
      });

    const allTimeOffRequests = data.timeOff.filter(
      (timeOff) =>
        timeOff.deniedText === null && timeOff.userCancelled === false
    );

    const allTimeOffEvents = formatTimeOff(allTimeOffRequests, user.id);

    const userTimeOff = allTimeOffEvents.filter(
      (event) => event.category === "personal"
    );

    const userSoftRequests = formatSoftRequests(data.softRequests, user.id);

    let invalidDates = [];
    userSoftRequests.forEach((request) => {
      invalidDates.push(request.start);
    });

    userTimeOff.forEach((request) => {
      const arrayOfTimeOff = eachDayOfInterval({
        start: request.start,
        end: request.end,
      });
      invalidDates = [...invalidDates, ...arrayOfTimeOff];
    });

    invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

    const maxGreyout = data.officeInfo[0].maxGreyoutRequests;
    const allowCallIns = data.officeInfo[0].allowCallIns;
    const schedulePeriodWeeks = data.officeInfo[0].scheduleDuration
      ? data.officeInfo[0].scheduleDuration
      : 4;

    const toggleEditRequest = (type, id) => {
      if (type === "softRequest") {
        const matchingRequest = userSoftRequests.find(
          (request) => parseInt(request.eventId) === parseInt(id)
        );
        if (matchingRequest) {
          setSoftRequestIdToEdit(matchingRequest.eventId);
          setEditSoftRequest(true);
        }
      } else {
        const matchingRequest = managerAccess
          ? allTimeOffEvents.find(
              (request) => parseInt(request.eventId) === parseInt(id)
            )
          : userTimeOff.find(
              (request) => parseInt(request.eventId) === parseInt(id)
            );
        if (matchingRequest) {
          setTimeOffRequestIdToEdit(matchingRequest.eventId);
          setEditTimeOffRequest(true);
        }
      }
    };

    // const handleDayClick = (e) => {
    //   // if (view === 0) {
    //   //   setShowDayActions(true);
    //   // }
    //   changeView({}, 2);

    //   const eventDate = new Date(e.date);
    //   eventDate.setHours(0, 0, 0, 0);

    //   const startDate = new Date(scheduleStartDate);
    //   startDate.setHours(0, 0, 0, 0);

    //   const endDate = new Date(scheduleEndDate);
    //   endDate.setHours(0, 0, 0, 0);

    //   const newCurrentSchedule = Boolean(
    //     eventDate >= startDate && eventDate <= endDate
    //   );

    //   setSelectedDay({
    //     date: e.date,
    //     events: e.events,
    //     currentSchedule: newCurrentSchedule,
    //   });
    //   selectedDateVar(e.date);
    //   setCurrentSchedule(newCurrentSchedule);

    //   let emp =
    //     allFitnesses.length > 0 &&
    //     allFitnesses.find(
    //       (x) =>
    //         new Date(x.schedulePeriod.start) <= new Date(e.date) &&
    //         new Date(x.schedulePeriod.end) >= new Date(e.date)
    //     );
    //   if (emp) {
    //     SetempFitness({
    //       score: emp.score,
    //       maxScore: emp.maxScore,
    //       ratio: emp.ratio,
    //     });
    //   } else {
    //     SetempFitness({
    //       score: "",
    //       maxScore: "",
    //       ratio: "",
    //     });
    //   }
    // };

    function eventsToView() {
      let eventsToView = [];
      let requests = [];
      if (allFilters) {
        if (allFilters.includes("All Shifts")) {
          eventsToView = [
            ...events.filter(
              (event) =>
                event.participants.length > 0 ||
                !issueDateswithProcedureName.find(
                  (e) =>
                    e.issueDate === format(event.start, "MM/dd/yyyy") ||
                    e.issueDate === format(event.end, "MM/dd/yyyy")
                )
            ),
          ];

          if (allFilters.includes("All Requests")) {
            requests = [...userSoftRequests, ...allTimeOffEvents];
          } else {
            if (allFilters.includes("Soft Requests")) {
              requests.push(userSoftRequests);
            }

            sortedAvailabilityTypes.forEach((type) => {
              if (filterList.requestsFilters.includes(type.name)) {
                let matchingTimeOffs = allTimeOffEvents.filter(
                  (timeOff) => timeOff.typeId === type.id
                );

                if (
                  allFilters.includes("Pending") &&
                  !allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => !timeOff.approvedby
                  );
                } else if (
                  !allFilters.includes("Pending") &&
                  allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => timeOff.approvedby
                  );
                }

                requests.push(matchingTimeOffs);
              }
            });

            requests = requests.flat();
          }
        } else if (allFilters.includes("Personal")) {
          eventsToView = [...userEvents];

          if (allFilters.includes("All Requests")) {
            requests = [...userSoftRequests, ...userTimeOff];
          } else {
            if (allFilters.includes("Soft Requests")) {
              requests.push(userSoftRequests);
            }

            sortedAvailabilityTypes.forEach((type) => {
              if (filterList.requestsFilters.includes(type.name)) {
                let matchingTimeOffs = userTimeOff.filter(
                  (timeOff) => timeOff.typeId === type.id
                );

                if (
                  allFilters.includes("Pending") &&
                  !allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => !timeOff.approvedby
                  );
                } else if (
                  !allFilters.includes("Pending") &&
                  allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => timeOff.approvedby
                  );
                }

                requests.push(matchingTimeOffs);
              }
            });

            requests = requests.flat();
          }
        } else if (allFilters.includes("Not Scheduled")) {
          eventsToView = events.filter(
            (event) =>
              (event.missionId != null &&
                event.procedureName != null &&
                issueDateswithProcedureName.find(
                  (e) =>
                    e.procedureName != null &&
                    e.procedureName === event.missionId &&
                    (e.issueDate === format(event.start, "MM/dd/yyyy") ||
                      e.issueDate === format(event.end, "MM/dd/yyyy"))
                )) ||
              event.participants.length === 0
          );
        }

        if (!allFilters.includes("All Missions")) {
          const filteredList = [];
          const missionNames = missionResources.map((mission) => mission.name);
          missionNames.forEach((name) => {
            if (allFilters.includes(name)) {
              const filtered = eventsToView.filter(
                (event) => event.missionId === name || event.eventTitle === name
              );
              filteredList.push(filtered);
            }
          });
          eventsToView = filteredList.flat();
        }

        if (!allFilters.includes("All Job Type")) {
          const filteredList = [];
          const jobTypeNames = filterList.jobTypeFilters
            ? filterList.jobTypeFilters
            : [];

          eventsToView.forEach((event) => {
            let participantsWithJobType = [];
            if (event.participants) {
              participantsWithJobType = event.participants.filter(
                (participant) =>
                  participant.skillSet?.find((skill) =>
                    jobTypeNames.includes(skill.name)
                  )
              );
            } else if (event.participant) {
              const hasSkill = event.participant.skills?.find((skill) =>
                jobTypeNames.includes(skill.name)
              );
              hasSkill && participantsWithJobType.push(event.participant);
            }

            if (participantsWithJobType.length > 0) {
              filteredList.push({
                ...event,
                participants: participantsWithJobType,
              });
            }
          });

          eventsToView = filteredList;
        }
      }

      const filtered = eventsToView.filter((event) =>
        event.calendars.includes(view)
      );
      return [...filtered, ...requests];
    }

    const PrintIconClick = () => {
      ref.current.print();
    };

    const ExportToICS = () => {
      const scheduleObj =
        document.querySelector(".e-schedule").ej2_instances[0];
      scheduleObj.exportToICalendar();
    };

    // const ExportToExcel = () => {
    //   const scheduleObj =
    //     document.querySelector(".e-schedule").ej2_instances[0];
    //   let exportValues = {
    //     dataSource: events,
    //   };
    //   let a =
    //     allSchedules.length > 0 &&
    //     allSchedules.find(
    //       (x) =>
    //         new Date(x.start) <= new Date() && new Date(x.end) >= new Date()
    //     );
    //   scheduleObj.exportToExcel(events);
    // };

    const hideFilterBadge = Boolean(
      allFilters.includes("All Shifts") &&
        allFilters.includes("All Missions") &&
        allFilters.includes("All Requests")
    );

    const environment = process.env.NODE_ENV;
    let backend = "";
    if (environment === "development") {
      backend = "https://backendtest.balancescheduler.com";
    } else {
      backend = "https://backenddemo.balancescheduler.com";
    }
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headerSpacing}
        >
          <Grid item xs={4}>
            <Typography variant="h3">Schedule</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2} xs={8}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleTimeOffForm}
                style={{ width: 170 }}
              >
                Add Calendar Event
              </Button>
            </Grid>
            {managerAccess && locationConfig.shiftBasedDisplay === false && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={toggleProcedureRequirementForm}
                  style={{ width: 170 }}
                >
                  Add Procedure
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div className="col-lg-3 property-section">
          <Grid container direction="row">
            <Grid item xs={4}>
              {view === "day" && managerAccess && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowShiftSlackBySkill(true)}
                  style={{ width: 178, marginBottom: 8 }}
                  disabled={
                    !allSlackDates.includes(
                      format(selectedDateVar(), "MM/dd/yyyy")
                    )
                  }
                >
                  {slacksLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    "View Staffing"
                  )}
                </Button>
              )}
            </Grid>
            <Grid item xs={4}>
              <Box style={{ textAlign: "center" }}>
                {empFitness.ratio === ""
                  ? ""
                  : "Your schedule quality for this schedule period: " +
                    empFitness.ratio.toFixed(2) * 100 +
                    "%"}
              </Box>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={4}>
              <Grid item>
                <Button
                  color="secondary"
                  aria-describedby={anchorId}
                  onClick={(event) => setopenDownload(event.currentTarget)}
                >
                  <GetAppIcon style={{ marginRight: 5 }} /> Download
                </Button>
                <Popover
                  id={anchorId}
                  anchorEl={openDownload}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  open={openD}
                  onClose={() => setopenDownload(null)}
                  style={{ overflow: "auto" }}
                >
                  {/* <MenuItem
                    color="secondary"
                    target="_blank"
                    filename="schedule.csv"
                    onClick={ExportToExcel}
                    className={classes.downloadPopover}
                    style={{ marginBottom: -8 }}
                  >
                    Export to CSV File
                  </MenuItem> 
                  <br /> */}
                  <MenuItem
                    color="secondary"
                    target="_blank"
                    // href={`${backend}/scheduler/employee_calendar/${parseInt(
                    //   user.id
                    // )}`}
                    onClick={ExportToICS}
                    className={classes.downloadPopover}
                    style={{ marginTop: -8 }}
                  >
                    Export to my Calendar
                  </MenuItem>
                </Popover>
              </Grid>
              <Grid item>
                <Button id="print" color="secondary" onClick={PrintIconClick}>
                  <PrintIcon style={{ marginRight: 5 }} /> Print
                </Button>
              </Grid>
              <Grid item>
                {" "}
                <Button
                  color="secondary"
                  style={{ padding: 4, fontSize: 12 }}
                  onClick={() => window.location.reload()}
                >
                  <RefreshIcon /> Refetch Events
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <Badge
                    variant="dot"
                    color="primary"
                    style={{ marginRight: 5 }}
                    invisible={hideFilterBadge}
                  >
                    <TuneIcon />
                  </Badge>
                  Filter
                </Button>
                <Popover
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  style={{ overflow: "auto" }}
                >
                  <Paper style={{ padding: 10 }}>
                    <FilterMenu
                      missionNames={
                        missionResources &&
                        missionResources.map((mission) => mission.name)
                      }
                      availabilityTypes={sortedAvailabilityTypes}
                      jobTypes={jobTypes}
                    />
                  </Paper>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {!shiftSwitchApproval && !timeOffApproval && (
          <Scheduler
            events={eventsToView()}
            draftStart={draftStart}
            draftEnd={draftEnd}
            openStart={openStart}
            openEnd={openEnd}
            employeeResources={employeeResources}
            missionResources={missionResources}
            view={view}
            setView={setView}
            toggleEditRequest={toggleEditRequest}
            toggleCallInForm={toggleCallInForm}
            toggleShiftSwitchForm={toggleShiftSwitchForm}
            issueDates={issueDates}
            slackIssues={slackIssues}
            handleEditClick={handleEditClick}
            handleEditProcedureReq={handleEditProcedureReq}
            setShiftToChange={setShiftToChange}
            allowCallIns={allowCallIns}
            ref={ref}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        )}
        <Dialog open={shiftSwitch} fullWidth maxWidth="sm">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
              position: "relative",
            }}
          >
            <ShiftSwitchRequestForm
              allEvents={events}
              userEvents={userEvents}
              closeDialog={toggleShiftSwitchForm}
              shiftNames={shiftNames}
              scheduleEndDate={scheduleEndDate}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              shiftToChange={shiftToChange}
              view={view}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={callIn} fullWidth maxWidth="xs">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              height: 460,
              position: "relative",
            }}
          >
            <EmpCallInForm
              closeDialog={toggleCallInForm}
              userEvents={userEvents}
              setToast={setToast}
              setShowToast={setShowToast}
              view={view}
              shiftToChange={shiftToChange}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editShift} fullWidth maxWidth="md">
          <Grid
            container
            component={DialogContent}
            direction="column"
            wrap="nowrap"
            justifyContent="space-between"
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
            }}
          >
            {shiftToEdit ? (
              <MangEditShift
                shiftEvent={shiftToEdit}
                closeDialog={toggleEditShift}
                setToast={setToast}
                setShowToast={setShowToast}
                setErrorToast={setErrorToast}
                setShowErrorToast={setShowErrorToast}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            ) : null}
          </Grid>
        </Dialog>
        <Dialog open={softRequest} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            <SoftTimeOff
              closeDialog={toggleSoftRequest}
              scheduleEndDate={scheduleEndDate}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              maxGreyout={maxGreyout}
              schedulePeriodWeeks={schedulePeriodWeeks}
              softRequests={userSoftRequests}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editSoftRequest} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            {softRequestIdToEdit && (
              <EditSoftTimeOff
                closeDialog={toggleEditSoftRequestForm}
                scheduleEndDate={scheduleEndDate}
                invalidDates={invalidDates}
                setToast={setToast}
                setShowToast={setShowToast}
                maxGreyout={maxGreyout}
                schedulePeriodWeeks={schedulePeriodWeeks}
                softRequests={userSoftRequests}
                softRequestIdToEdit={softRequestIdToEdit}
                setSoftRequestIdToEdit={setSoftRequestIdToEdit}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={timeOffRequestForm} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
          >
            <TimeOffRequestForm
              closeDialog={toggleTimeOffForm}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              employees={employeeResources}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editTimeOffRequest} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
          >
            {timeOffRequestIdToEdit && (
              <EditTimeOffRequestForm
                closeDialog={toggleEditTimeOffForm}
                userSoft={userSoftRequests}
                setToast={setToast}
                setShowToast={setShowToast}
                userTimeOff={userTimeOff}
                timeOffRequestIdToEdit={timeOffRequestIdToEdit}
                setTimeOffRequestIdToEdit={setTimeOffRequestIdToEdit}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={procedureReqForm} fullWidth maxWidth="md">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 750,
              position: "relative",
            }}
          >
            <AddProcedure
              closeDialog={toggleProcedureRequirementForm}
              event={procedureRequirementEvent}
              setProcedureRequirementEvent={setProcedureRequirementEvent}
              refetch={refetch}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              scheduleEndDate={scheduleEndDate}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
              getVariables={getVariables()}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={showShiftSlackBySkill} maxWidth={false}>
          <DialogContent
            style={{ padding: 30, overflowX: "hidden", overflowY: "auto" }}
          >
            <ShiftSlackBySkill
              slacks={allSlacks}
              closeDialog={() => setShowShiftSlackBySkill(false)}
            />
          </DialogContent>
        </Dialog>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>{toastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>{errorToastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default EmpCalendar;
