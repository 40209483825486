import React from 'react';
import { 
    Typography, makeStyles, Grid,
    Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow,
    // IconButton 
} from '@material-ui/core';
// import WarningIcon from '@material-ui/icons/Warning';
// import AlertOutline from 'mdi-material-ui/AlertOutline';
// import CloseIcon from '@material-ui/icons/Close';
import { format, isSameDay } from 'date-fns';

const useStyles = makeStyles(() => ({
    whiteText: {
        color: '#fff'
    },
    blackText: {
        color: '#000'
    },
    boldText: {
        color: '#000',
        fontWeight: 'bold'
    },
}));

const ShiftSlackFloatTable = ({needsByOffice, tooltip}) => {
    const classes = useStyles();
    
    return (  
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell className={tooltip
                            ? classes.whiteText
                            : classes.blackText
                        }>
                            Department
                        </TableCell>
                        <TableCell className={tooltip
                            ? classes.whiteText
                            : classes.blackText
                        }>
                            Time
                        </TableCell>
                        <TableCell className={tooltip
                            ? classes.whiteText
                            : classes.blackText
                        }>
                            Need
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {needsByOffice.map(office => (
                        <TableRow key={office.time + office.office.name}>
                            <TableCell className={tooltip
                                ? classes.whiteText
                                : office.affected
                                    ? classes.boldText
                                    : classes.blackText
                            }>
                                {office.office.name}
                            </TableCell>
                            <TableCell className={tooltip
                                ? classes.whiteText
                                : office.affected
                                    ? classes.boldText
                                    : classes.blackText
                            }>
                                {office.time}
                            </TableCell>
                            <TableCell className={tooltip
                                ? classes.whiteText
                                : office.affected
                                    ? classes.boldText
                                    : classes.blackText
                            }>
                                {office.need}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
 
export default ShiftSlackFloatTable;