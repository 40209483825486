import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Box,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  makeStyles,
  Container,
  Snackbar,
  Dialog,
  DialogContent,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import {
  UPDATE_OFFICE,
  SHIFT_DEFINITIONS,
  BATCH_CREATE_SHIFT_DEFINITIONS,
  BATCH_DELETE_SHIFT_DEFINITIONS,
  SHIFT_DEFINITION_TYPE,
  WEEKDAY,
  GET_OFFICE,
  BATCH_DELETE_CONSTRAINTS,
  GET_USER_NAME,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import { timezones } from "../../helpers/officeHelpers";
import MuiAlert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { getShifts } from "../../helpers/officeHelpers";
import { Formik, FieldArray, useFormikContext, Form } from "formik";
import { userVar, officeConfigVar } from "../../cache";
import { Prompt } from "react-router-dom";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  inputWidth: {
    width: "100%",
  },
  mediumtextbox: {
    width: "50%",
  },
  smalltextbox: {
    width: "25%",
  },
  loading: {
    display: "block",
    margin: "auto",
  },
  textColor: {
    color: "#F15A29",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 80,
  },
  autocompleteInput: {
    width: "100%",
    padding: 0,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const PromptIfDirty = () => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes on this form."
    />
  );
};

function DepartmentSettings(props) {
  const classes = useStyles();
  const user = userVar();
  const [office, SetOffice] = useState(null);
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { data, refetch, loading } = useQuery(GET_OFFICE, {
    variables: {
      id: parseInt(props.office),
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "Something went wrong. Please try again. Graphql " + error
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const getUserDetails = useQuery(GET_USER_NAME, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "Something went wrong. Please try again. Graphql " + error
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_USER_NAME Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    !loading && SetOffice(data.offices[0]);
  }, [!loading]);

  const shiftDefinitions = useQuery(SHIFT_DEFINITIONS, {
    variables: {
      officeId: parseInt(props.office),
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SHIFT_DEFINITIONS query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchcreateShiftDefinition] = useMutation(
    BATCH_CREATE_SHIFT_DEFINITIONS,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        shiftDefinitions.refetch();
        setsnackBarMessage("Changes Succesfully Updated");
        setsnackbaropen(true);
      },
      onError(error) {
        console.log(error);
        setSnackBarOpen(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_SHIFT_DEFINITIONS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchDeleteShiftDefitions] = useMutation(
    BATCH_DELETE_SHIFT_DEFINITIONS,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        shiftDefinitions.refetch();
      },
      onError(error) {
        console.log(error);
        setSnackBarOpen(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_DELETE_SHIFT_DEFINITIONS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchdeleteConstraint] = useMutation(BATCH_DELETE_CONSTRAINTS, {
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_DELETE_CONSTRAINTS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onCompleted(data) {
      console.log(data);
      refetch();
      shiftDefinitions.refetch();
      getUserDetails.refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_OFFICE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const shiftDefinitionTypes = useQuery(SHIFT_DEFINITION_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );

      notifyDevelopers({
        variables: {
          message:
            "Error on SHIFT_DEFINITION_TYPE query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const weekday = useQuery(WEEKDAY, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "Something went wrong. Please try again. Graphql " + error
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on WEEKDAY query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [showDialog, setshowDialog] = useState(false);

  const handleShiftsSave = (values) => {
    const shiftData = [];
    shiftDefinitions.data.shiftDefinitions.map((element) => {
      shiftData.push(parseInt(element.id));
    });
    batchDeleteShiftDefitions({
      variables: {
        ids: shiftData,
      },
    });
    values.forEach((element) => {
      let typeId =
        !shiftDefinitionTypes.loading &&
        shiftDefinitionTypes.data.shiftTypes.find(
          (e) => e.type === element.shiftType
        );

      let inputData =
        !weekday.loading &&
        weekday.data.weekdays.map((e) => {
          return {
            office: parseInt(office.id),
            start: element.startTime,
            end: element.endTime,
            type: parseInt(typeId.id),
            weekday: parseInt(e.id),
            description: element.description,
          };
        });
      batchcreateShiftDefinition({
        variables: {
          input: inputData,
        },
      });
    });
  };

  const [shiftstodelete, setshiftstodelete] = useState({});

  const handleDeleteShifts = () => {
    setshowDialog(false);
    let selectedShifts = shiftDefinitions.data.shiftDefinitions.filter(
      (i) =>
        i.description === shiftstodelete.description &&
        i.type.type === shiftstodelete.shiftType &&
        i.office.id === office.id
    );
    let shiftsData = [];
    let officeConstraintData = [];
    selectedShifts.forEach((e) => {
      shiftsData.push(parseInt(e.id));
      office.constraintSet
        .filter((x) => x.starttime === e.start && x.endtime === e.end)
        .map((i) => {
          //deleteConstraint({ variables: { id: parseInt(i.id) } });
          officeConstraintData.push(i.id);
        });
    });
    batchDeleteShiftDefitions({
      variables: {
        ids: shiftsData,
      },
    });
    batchdeleteConstraint({
      variables: {
        ids: officeConstraintData,
      },
    });
  };

  const handleUpdateOffice = (values) => {
    let elementLength = 0;
    values.shifts.forEach((element) => {
      elementLength++;
      if (elementLength === values.shifts.length) {
        handleShiftsSave(values.shifts);
      }
    });
    updateOffice({
      variables: {
        id: parseInt(office.id),
        input: {
          timezone: values.officeTimezone,
          maxGreyoutRequests: parseInt(values.maxTimeOff),
          allowCallIns: values.allowCallIns,
          shiftSwitchBufferDays: parseInt(values.shiftSwitchBufferDays),
          samlLoginOnly: values.samlLoginOnly,
          numberDaysBeforePreferencesDue: parseInt(
            values.numberDaysBeforePreferencesDue
          ),
          schedulePublishedBufferWeeks: parseInt(values.shiftSwitchBufferDays),
          scheduleDuration: parseInt(values.scheduleDuration),
          shiftTypePrefs: values.shiftTypePrefs,
          weekdaysPrefs: values.weekdaysPrefs,
          daysOffPrefs: values.daysOffPrefs,
          overtimePrefs: values.overtimePrefs,
          softRequests: values.softRequests,
          shiftBasedDisplay: values.shiftBasedDisplay,
          procedureImports: values.procedureImports,
          employeeImports: values.employeeImports,
          availabilityImports: values.availabilityImports,
          skillImports: values.skillImports,
          trainingImports: values.trainingImports,
          multipleEventsPerDay: values.multipleEventsPerDay,
        },
      },
    });
  };
  if (loading || shiftDefinitions.loading || office === null) {
    return <CircularProgress color="primary" />;
  } else {
    return (
      <main variant="body" data-testid="officeshiftsGrid">
        <Container style={{ margin: 0, padding: 0 }}>
          <Dialog open={showDialog} fullWidth maxWidth="xs">
            <DialogContent
              style={{
                padding: 20,
                overflowX: "hidden",
                textAlign: "center",
              }}
            >
              <Typography>
                Are you sure you want to delete this Shift?
              </Typography>
              <br />
              <br />
              <Button
                onClick={handleDeleteShifts}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleDeleteShifts();
                  }
                }}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => setshowDialog(false)}
                color="primary"
                variant="outlined"
              >
                No
              </Button>
            </DialogContent>
          </Dialog>
          <ErrorSnackBar
            open={snackBarOpen}
            message={errorSnackBarMessage}
            close={() => setSnackBarOpen(false)}
          />
          <Snackbar
            open={snackbaropen}
            autoHideDuration={2000}
            onClose={() => {
              setsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => {
                setsnackbaropen(false);
                setsnackBarMessage("");
              }}
              severity="success"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={errorsnackbaropen}
            autoHideDuration={6000}
            onClose={() => {
              seterrorsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => seterrorsnackbaropen(false)}
              severity="error"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" spacing={4}>
            <Grid item className={classes.headerSpacing}>
              <Typography variant="h3">Department Settings</Typography>
            </Grid>
            <Grid item xs={10}>
              <Formik
                enableReinitialize
                initialValues={{
                  name: office.name,
                  officeTimezone: office.timezone,
                  samlLoginOnly: office.samlLoginOnly,
                  name: office.name,
                  shiftSwitchBufferDays: office.shiftSwitchBufferDays
                    ? office.shiftSwitchBufferDays
                    : "",
                  maxTimeOff: office.maxGreyoutRequests
                    ? office.maxGreyoutRequests
                    : "",
                  allowCallIns: office.allowCallIns,
                  shiftTypePrefs: office.shiftTypePrefs,
                  weekdaysPrefs: office.weekdaysPrefs,
                  daysOffPrefs: office.daysOffPrefs,
                  overtimePrefs: office.overtimePrefs,
                  softRequests: office.softRequests,
                  shiftBasedDisplay: office.shiftBasedDisplay,
                  schedulePublishedBufferWeeks:
                    office.schedulePublishedBufferWeeks
                      ? office.schedulePublishedBufferWeeks
                      : "",
                  numberDaysBeforePreferencesDue:
                    office.numberDaysBeforePreferencesDue
                      ? office.numberDaysBeforePreferencesDue
                      : "",
                  reviewWindowDays: office.reviewWindowDays
                    ? office.reviewWindowDays
                    : "",
                  scheduleDuration: office.scheduleDuration,
                  shiftBasedDisplay: office.shiftBasedDisplay,
                  procedureImports: office.procedureImports,
                  employeeImports: office.employeeImports,
                  availabilityImports: office.availabilityImports,
                  skillImports: office.skillImports,
                  trainingImports: office.trainingImports,
                  multipleEventsPerDay: office.multipleEventsPerDay,
                  floatStatus:
                    office.floatStatus != null ? !office.floatStatus : false,
                }}
                onSubmit={(values) => {
                  handleUpdateOffice(values);
                }}
              >
                {({ values, handleChange, dirty, setFieldValue }) => (
                  <Form>
                    <PromptIfDirty />
                    <Grid container direction="row" item xs={12} spacing={4}>
                      <Grid item xs={6}>
                        <Box mb={1}>
                          <Typography variant="h5">Department Name</Typography>
                        </Box>
                        <TextField
                          id="departmentName"
                          name="departmentName"
                          variant="outlined"
                          disabled
                          value={values.name ? values.name : ""}
                          className={classes.inputWidth}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box mb={1}>
                          <Typography variant="h5">Time Zone</Typography>
                        </Box>
                        <Select
                          id="officeTimezone"
                          name="officeTimezone"
                          variant="outlined"
                          value={
                            values.officeTimezone
                              ? values.officeTimezone
                              : "UTC"
                          }
                          onChange={handleChange}
                          className={classes.inputWidth}
                        >
                          {timezones.map((timezone, ind) => (
                            <MenuItem key={ind} id={timezone} value={timezone}>
                              {timezone}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <b>Request and Switch Shifts</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography>Time-Off Requests</Typography>
                        </Box>
                        <Typography variant="body2"># of Requests</Typography>
                        <TextField
                          variant="outlined"
                          className={classes.smalltextbox}
                          id="maxTimeOff"
                          name="maxTimeOff"
                          value={values.maxTimeOff ? values.maxTimeOff : ""}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography>Switch shift at least</Typography>
                        </Box>
                        <Typography variant="body2">days in advance</Typography>
                        <TextField
                          variant="outlined"
                          id="shiftSwitchBufferDays"
                          name="shiftSwitchBufferDays"
                          value={
                            values.shiftSwitchBufferDays
                              ? values.shiftSwitchBufferDays
                              : ""
                          }
                          className={classes.smalltextbox}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography>Allow Call In's for Employees</Typography>
                        </Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.allowCallIns ? true : false}
                              onChange={(e) =>
                                setFieldValue("allowCallIns", e.target.checked)
                              }
                              name="allowCallIns"
                              color={
                                values.allowCallIns ? "primary" : "secondary"
                              }
                            />
                          }
                          label="Allow Call In's"
                        ></FormControlLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <b>Scheduling</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography>
                            Preferences due before schedule is published
                          </Typography>
                        </Box>
                        <Typography variant="body2">in days</Typography>
                        <TextField
                          id="numberDaysBeforePreferencesDue"
                          name="numberDaysBeforePreferencesDue"
                          variant="outlined"
                          value={
                            values.numberDaysBeforePreferencesDue
                              ? values.numberDaysBeforePreferencesDue
                              : ""
                          }
                          onChange={handleChange}
                          className={classes.smalltextbox}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography>Publish Schedule</Typography>
                        </Box>
                        <Typography variant="body2">
                          weeks in advance before schedule start date
                        </Typography>
                        <TextField
                          variant="outlined"
                          id="schedulePublishedBufferWeeks"
                          name="schedulePublishedBufferWeeks"
                          value={
                            values.schedulePublishedBufferWeeks
                              ? values.schedulePublishedBufferWeeks
                              : ""
                          }
                          className={classes.smalltextbox}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography>Schedule Duration</Typography>
                        </Box>
                        <Typography variant="body2">in weeks</Typography>
                        <TextField
                          variant="outlined"
                          id="scheduleDuration"
                          name="scheduleDuration"
                          value={
                            values.scheduleDuration
                              ? values.scheduleDuration
                              : ""
                          }
                          className={classes.smalltextbox}
                          onChange={handleChange}
                        />
                      </Grid>
                      {user.office.name === office.name && (
                        <>
                          {" "}
                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              <b>Allow Preferences</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Shift Type Preferences</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.shiftTypePrefs}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "shiftTypePrefs",
                                      e.target.checked
                                    )
                                  }
                                  name="shiftTypePreferences"
                                  color="primary"
                                />
                              }
                              label="Shift Type Preferences"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Week day Preferences</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.weekdaysPrefs}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "weekdaysPrefs",
                                      e.target.checked
                                    )
                                  }
                                  name="weekDayPreferences"
                                  color="primary"
                                />
                              }
                              label="Week day Preferences"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Day Off Preferences</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.daysOffPrefs}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "daysOffPrefs",
                                      e.target.checked
                                    )
                                  }
                                  name="missionDisplay"
                                  color="primary"
                                />
                              }
                              label="Day Off Preferences"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Overtime Preferences</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.overtimePrefs}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "overtimePrefs",
                                      e.target.checked
                                    )
                                  }
                                  name="overtimePreferences"
                                  color="primary"
                                />
                              }
                              label="Overtime Preferences"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              <b>Allow</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Shift Based Scheduling</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.shiftBasedDisplay}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "shiftBasedDisplay",
                                      e.target.checked
                                    )
                                  }
                                  name="shiftBasedScheduling"
                                  color="primary"
                                />
                              }
                              label="Shift Based Scheduling"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Soft Requests</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.shiftBasedDisplay}
                                  name="softRequests"
                                  color="primary"
                                  disabled
                                />
                              }
                              label="Soft requests"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>SAML Login</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.samlLoginOnly}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "samlLoginOnly",
                                      e.target.checked
                                    )
                                  }
                                  name="samlLoginOnly"
                                  color="primary"
                                />
                              }
                              label="Only SAML Login"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>Multiple events in a day</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    values.multipleEventsPerDay != "undefined"
                                      ? values.multipleEventsPerDay
                                      : false
                                  }
                                  onChange={(e) =>
                                    setFieldValue(
                                      "multipleEventsPerDay",
                                      e.target.checked
                                    )
                                  }
                                  name="multipleEventsPerDay"
                                  color="primary"
                                />
                              }
                              label="Allow Multiple events in a day"
                            />
                          </Grid>
                          {/* <Grid item xs={4}>
                            <Typography>
                              Allow Substitutes for any Skill with Float
                              Employees
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.floatStatus}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "floatStatus",
                                      e.target.checked
                                    )
                                  }
                                  name="floatStatus"
                                  color="primary"
                                />
                              }
                              label="Allow Floats"
                            />
                          </Grid>
                          */}
                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              <b>Allow Imports</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>Employees</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.employeeImports}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "employeeImports",
                                      e.target.checked
                                    )
                                  }
                                  name="employeeImports"
                                  color="primary"
                                />
                              }
                              label="Employees"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>Procedures</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.procedureImports}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "procedureImports",
                                      e.target.checked
                                    )
                                  }
                                  name="procedureImports"
                                  color="primary"
                                  disabled={
                                    values.shiftBasedDisplay ? true : false
                                  }
                                />
                              }
                              label="Procedures"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>Availability</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.availabilityImports}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "availabilityImports",
                                      e.target.checked
                                    )
                                  }
                                  name="availabilityImports"
                                  color="primary"
                                  disabled={
                                    values.shiftBasedDisplay ? true : false
                                  }
                                />
                              }
                              label="Availability"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>Skills</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.skillImports}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "samlLoginOnly",
                                      e.target.checked
                                    )
                                  }
                                  name="skillImports"
                                  color="primary"
                                />
                              }
                              label="Skills"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>Training</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.trainingImports}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "trainingImports",
                                      e.target.checked
                                    )
                                  }
                                  name="trainingImports"
                                  color="primary"
                                />
                              }
                              label="Training"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Box m={4} style={{ textAlign: "right" }}>
                      <Button
                        type="submit"
                        disabled={!dirty}
                        color="primary"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </main>
    );
  }
}

export default DepartmentSettings;
