import React from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import saturday from '../../../assets/quizImages/saturday.png';
import sunday from '../../../assets/quizImages/sunday.png';
import monday from '../../../assets/quizImages/monday.png';
import tuesday from '../../../assets/quizImages/tuesday.png';
import wednesday from '../../../assets/quizImages/wednesday.png';
import thursday from '../../../assets/quizImages/thursday.png';
import friday from '../../../assets/quizImages/friday.png';


const useStyles = makeStyles(() => ({
    dayTile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    dayTileClicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    },
    coloredText: {
        color: '#8CADE1'
    },
}));

const DaysMostPrefer = ({values, setFieldValue}) => {
    const classes = useStyles();

    const handleChange = (fieldName) => {
        if (values[fieldName] <= 0) {
            setFieldValue(fieldName, 1);
        } else {
            setFieldValue(fieldName, 0)
        }
    };
    
    return ( 
        <Grid container item direction='column' spacing={5} style={{paddingTop: 25}}>
            {/* <Grid item>
                <Typography variant='h5'>Days Most Prefer</Typography>
            </Grid> */}
            <Grid item align='center'>
                <Typography style={{fontSize: 24}}>
                    What days of the week do you <b className={classes.coloredText}>like</b> to work?
                </Typography>
            </Grid>
            <Grid 
                item 
                container 
                justifyContent='center' 
                style={{padding: 50, paddingTop: 0, paddingBottom: 0}}
            >
                <Grid 
                    component={Paper} 
                    className={values.monday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('monday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Monday</Typography>
                    <img 
                        alt='monday' 
                        src={monday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.tuesday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('tuesday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Tuesday</Typography>
                    <img 
                        alt='tuesday' 
                        src={tuesday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.wednesday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('wednesday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Wednesday</Typography>
                    <img 
                        alt='wednesday' 
                        src={wednesday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.thursday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('thursday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Thursday</Typography>
                    <img 
                        alt='thursday' 
                        src={thursday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.friday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('friday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Friday</Typography>
                    <img 
                        alt='friday' 
                        src={friday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.saturday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('saturday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Saturday</Typography>
                    <img 
                        alt='saturday' 
                        src={saturday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.sunday > 0 ? classes.dayTileClicked : classes.dayTile} 
                    onClick={() => handleChange('sunday')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Sunday</Typography>
                    <img 
                        alt='sunday' 
                        src={sunday} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default DaysMostPrefer;