import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Typography,
  makeStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  PROCEDURE_REQUIREMENTS,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const AllProcedures = () => {
  const classes = useStyles();
  const user = userVar();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);

  const procedureRequirements = useQuery(PROCEDURE_REQUIREMENTS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onCompleted(data){
      console.log(data)
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again.");
    },
  });

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => <div>Added</div>,
    },
    {
      field: "name",
      headerName: "MSN",
      width: 250,
      renderCell: (params) => (
        <div data-testid="procedureName">{params.row.name}</div>
      ),
    },
    {
      field: "earliestDate",
      headerName: "T/O Time Z",
      width: 300,
      renderCell: (params) => (
        <div data-testid="earliestDate">{params.row.earliestDate}</div>
      ),
    },
    {
      field: "latestDate",
      headerName: "Land Z",
      width: 200,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 200,
    },
    {
      field: "office",
      headerName: "Department",
      width: 200,
      renderCell: (params) => <div>{params.row.office.name}</div>,
    },
  ];

  const rows =
    !procedureRequirements.loading &&
    procedureRequirements.data.procedureRequirements.length > 0 ?
    procedureRequirements.data.procedureRequirements:[];

  if (procedureRequirements.loading) {
    return <CircularProgress color="primary" />;
  } else if (procedureRequirements.error) {
    return (
      <Grid item xs={6}>
        <Typography variant="h3">Mission Details</Typography>
      </Grid>
    );
  } else if (procedureRequirements.data) {
    return (
      <Grid container className={classes.headerSpacing}>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
        <Grid item xs={6}>
          <Typography variant="h3">Mission Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <div style={{ height: 700 }} data-testid="procedureGrid">
              <DataGrid
                className={classes.dt}
                rows={rows}
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default AllProcedures;
