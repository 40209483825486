import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Formik, Form, FieldArray } from "formik";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  Dialog,
  DialogContent,
  Snackbar,
  Divider,
  makeStyles,
  Box,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  MENTEE,
  BATCH_CREATE_MENTORSHIP,
  NOTIFY_DEVELOPERS,
  DELETE_MENTEE,
  FIND_EMPLOYEE_BY_ID,
  EMPLOYEE_NAMES,
  GET_EMPLOYEE_NAMES,
  UPDATE_MENTEE,
} from "../../api/gqlQueries";
import ErrorSnackBar from "../errors/errorSnackBar";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { userVar } from "../../cache";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  divider: {
    border: "0.5px solid thin #333333",
    verticalAlign: "bottom",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20px",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20px",
  },
}))(TableCell);

const Mentor = () => {
  const classes = useStyles();
  const user = userVar();
  console.log(user);
  const environment = process.env.NODE_ENV;
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [snackbarMessage, SetSnackbarMessage] = useState("");
  const [showSnackBar, SetShowSnackBar] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { data, error, loading, refetch } = useQuery(MENTEE, {
    variables: { office: parseInt(user.office.id) },
    onError(error) {
      setOpenSnackBar2(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on MENTEE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [updateMentee] = useMutation(UPDATE_MENTEE, {
    onCompleted() {
      setEditItem(false);
      refetch();
      setOpenSnackBar3(true);
      setSnackBarMessage("Mentorship Sucessfully Updated!");
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_MENTEE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateMentorship] = useMutation(BATCH_CREATE_MENTORSHIP, {
    onCompleted(data) {
      if (data.batchCreateMentee.mentees.length > 0) {
        setEditItem(false);
        refetch();
        setOpenSnackBar3(true);
        setSnackBarMessage("Mentorship Sucessfully Added!");
      }
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_MENTORSHIP Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allEmployees = useQuery(GET_EMPLOYEE_NAMES, {
    variables: { office: parseInt(user.office.id) },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_NAMES QUERY. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleDisable = (e) => {
    setEditItem(e);
  };
  const handleAddMentorship = (values) => {
    let uniqueMentees = [];
    //check if record exists in db and if exists in db skip. Else add the data to batch create mentees
    let CreateMenteeInput = values
      .map((e) => {
        let menteeAssignment =
          allEmployees.data &&
          allEmployees.data.offices[0] &&
          allEmployees.data.offices[0].employeeSet
            .find((emp) => emp.id === e.mentee.employee.id)
            .assignmentSet.find((x) => x.primary === true).id;
        let mentorAssignment =
          allEmployees.data &&
          allEmployees.data.offices[0] &&
          allEmployees.data.offices[0].employeeSet
            .find((emp) => emp.id === e.mentor.employee.id)
            .assignmentSet.find((x) => x.primary === true).id;
        if (menteeAssignment != mentorAssignment) {
          //push all mentees to an array
          uniqueMentees.push({ mentee: e.mentee.employee.id, end: e.end });
          if (!e.id) {
            let menteePresent = uniqueMentees.filter(
              (x) => x.mentee === e.mentee.employee.id && x.end < e.start
            );
            //check if mentor is not mentee
            let mentorisNotMentee = uniqueMentees.filter(
              (x) => x === e.mentor.employee.id
            );
            //check if length of mentee is greater than 1. if >1 the mentee already has a mentor
            if (menteePresent.length === 1 && mentorisNotMentee.length === 0) {
              return {
                mentee: parseInt(menteeAssignment),
                mentor: parseInt(mentorAssignment),
                start: e.start,
                end: e.end,
              };
            } else {
              SetSnackbarMessage(
                "Mentee has a mentor for the same dates or mentee is the same as mentor. Try adding a different mentee to the mentor for different dates."
              );
              SetShowSnackBar(true);
            }
          } else {
            editItem &&
              updateMentee({
                variables: {
                  id: parseInt(e.id),
                  input: {
                    mentee: parseInt(menteeAssignment),
                    mentor: parseInt(mentorAssignment),
                    start: e.start,
                    end: e.end,
                  },
                },
              });
          }
        } else {
          SetSnackbarMessage("Mentee and mentor cannot be the same.");
          SetShowSnackBar(true);
        }
      })
      .filter(Boolean);

    batchCreateMentorship({
      variables: {
        input: CreateMenteeInput,
      },
    });
  };
  if (loading || allEmployees.loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    return (
      <>
        <h1>Mentor/Mentee</h1>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </>
    );
  } else {
    console.log(data);
    let allOfficeEmployees = allEmployees.data.offices[0].employeeSet;

    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classes.headerSpacing}>
            <Grid item xs={6}>
              <Typography variant="h3">Manage Mentees</Typography>
            </Grid>
          </Grid>

          <Grid item xs={11}>
            <Formik
              enableReinitialize
              initialValues={{
                mentorships: data.mentees,
              }}
              onSubmit={(values) => {
                handleAddMentorship(values.mentorships);
              }}
            >
              {({ values, handleChange, dirty, setFieldValue }) => (
                <Form>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={10}>
                      {" "}
                      <FieldArray name="mentorships">
                        {({ push, remove }) => (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell colSpan={3}></StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell>Mentee</StyledTableCell>
                                <StyledTableCell>Mentor</StyledTableCell>
                                <StyledTableCell>Start Date</StyledTableCell>
                                <StyledTableCell>End Date</StyledTableCell>
                                <StyledTableCell>Edit</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.mentorships &&
                                values.mentorships.length > 0 &&
                                values.mentorships.map((element, index) => (
                                  <TableRow key={index}>
                                    <StyledTableCell>
                                      <Select
                                        id={`mentorships.${index}.mentee.employee.id`}
                                        name={`mentorships.${index}.mentee.employee.id`}
                                        variant="outlined"
                                        disabled={
                                          index !== editItem &&
                                          element.id &&
                                          true
                                        }
                                        onChange={handleChange}
                                        value={element.mentee.employee.id}
                                      >
                                        {allOfficeEmployees &&
                                          allOfficeEmployees.map((e, i) => (
                                            <MenuItem key={i} value={e.id}>
                                              {e.firstName} {e.lastName}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Select
                                        id={`mentorships.${index}.mentor.employee.id`}
                                        name={`mentorships.${index}.mentor.employee.id`}
                                        variant="outlined"
                                        disabled={
                                          index !== editItem &&
                                          element.id &&
                                          true
                                        }
                                        value={element.mentor.employee.id}
                                        onChange={handleChange}
                                      >
                                        {allOfficeEmployees &&
                                          allOfficeEmployees.map((e, i) => (
                                            <MenuItem key={i} value={e.id}>
                                              {e.firstName} {e.lastName}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <TextField
                                        id={`mentorships.${index}.start`}
                                        name={`mentorships.${index}.start`}
                                        variant="outlined"
                                        type="date"
                                        disabled={
                                          index !== editItem &&
                                          element.id &&
                                          true
                                        }
                                        value={element.start}
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        variant="outlined"
                                        type="date"
                                        id={`mentorships.${index}.end`}
                                        name={`mentorships.${index}.end`}
                                        disabled={
                                          index !== editItem &&
                                          element.id &&
                                          true
                                        }
                                        value={element.end}
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Button
                                        color="secondary"
                                        onClick={(e) => handleDisable(index)}
                                        startIcon={<EditIcon />}
                                      ></Button>
                                    </StyledTableCell>
                                  </TableRow>
                                ))}

                              <TableRow>
                                <Button
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  onClick={() =>
                                    push({
                                      mentee: {
                                        employee: {
                                          id: "",
                                        },
                                      },
                                      mentor: {
                                        employee: {
                                          id: "",
                                        },
                                      },
                                      start: "",
                                      end: "",
                                    })
                                  }
                                >
                                  Add Another
                                </Button>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                  <Box m={4} style={{ textAlign: "right" }}>
                    <Button
                      type="submit"
                      disabled={!dirty}
                      color="primary"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </MuiPickersUtilsProvider>

        <ErrorSnackBar
          open={(openSnackBar, showSnackBar)}
          message={(snackBarMessage, snackbarMessage)}
          close={() => {
            setOpenSnackBar(false);
            SetShowSnackBar(false);
          }}
        />
        <Snackbar
          open={openSnackBar3}
          autoHideDuration={3000}
          onClose={() => {
            setOpenSnackBar3(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              refetch();
              setOpenSnackBar3(false);
            }}
            severity="success"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
};

export default Mentor;
