import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  Snackbar,
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  FIND_SKILL_TYPE,
  ADD_LOCATION_SKILLS,
  DELETE_SKILL,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  inputWidth: {
    width: "100%",
  },
  smalltextbox: {
    width: "25%",
  },
  divider: {
    border: "0.5px solid thin #333333",
    verticalAlign: "bottom",
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20",
    verticalAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20",
    verticalAlign: "center",
  },
}))(TableCell);

function LocationSettings() {
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });
  const { loading, data, refetch } = useQuery(FIND_SKILL_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage("We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allcerts =
    !loading &&
    data &&
    data.skills &&
    data.skills.filter(
      (i) => i.variety === "CERTIFICATION" && i.office === null
    );
  const alleducation =
    !loading &&
    data &&
    data.skills &&
    data.skills.filter((i) => i.variety === "EDUCATION" && i.office === null);
  const allLicensure =
    !loading &&
    data &&
    data.skills &&
    data.skills.filter((i) => i.variety === "LICENSE" && i.office === null);
  const allJobtypes =
    !loading &&
    data &&
    data.skills &&
    data.skills.filter((i) => i.variety === "JOB_TYPE" && i.office === null);
  const allTrainings =
    !loading &&
    data &&
    data.skills &&
    data.skills.filter((i) => i.variety === "TRAINING" && i.office === null);

  const [certInput, setCertInput] = useState("");
  const [jobInput, setJobInput] = useState("");
  const [trainingInput, setTrainingInput] = useState("");
  const [educationInput, seteducationInput] = useState("");
  const [licenseInput, setlicenseInput] = useState("");
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [showDialog, setshowDialog] = useState(false);
  const [dialogMessage, setshowDialogMessage] = useState(false);
  const [skilltodelete, setskilltodelete] = useState("");

  const [deleteSkill] = useMutation(DELETE_SKILL, {
    update(cache, { data: { deleteSkill } }) {
      cache.evict({
        data: { id: deleteSkill.deletedId },
      });
    },
    onCompleted() {
      setskilltodelete("");
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on DELETE_SKILL Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [addSkills] = useMutation(ADD_LOCATION_SKILLS, {
    update(cache, { data: { createEmployeeSkill } }) {
      cache.modify({
        fields: {
          employeeSkill(existingEmployeeSkill = []) {
            const newEmpSkillRef = cache.writeFragment({
              data: createEmployeeSkill.employeeSkill,
              fragment: gql`
                fragment NewEmployeeSkill on EmployeeSkillNode {
                  skill {
                    id
                    variety
                    name
                  }
                }
              `,
            });
            return [...existingEmployeeSkill, newEmpSkillRef];
          },
        },
      });
    },
    onCompleted() {
      setCertInput("");
      setJobInput("");
      setlicenseInput("");
      seteducationInput("");
      setTrainingInput("");
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_LOCATION_SKILLS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const handleAddCert = () => {
    var certInputLen = certInput.trim().length;
    if (certInputLen > 0) {
      addSkills({
        variables: {
          input: {
            name: certInput,
            variety: "CERTIFICATION",
          },
        },
      });
    }
  };
  const handleAddEducation = () => {
    var edulen = educationInput.trim().length;
    if (edulen > 0) {
      addSkills({
        variables: {
          input: {
            name: educationInput,
            variety: "EDUCATION",
          },
        },
      });
    }
  };
  const handleAddLicensure = () => {
    var licenseLen = licenseInput.trim().length;
    if (licenseLen > 0) {
      addSkills({
        variables: {
          input: {
            name: licenseInput,
            variety: "LICENSE",
          },
        },
      });
    }
  };

  const handleAddJobtype = () => {
    var jobInputLen = jobInput.trim().length;
    if (jobInputLen > 0) {
      addSkills({
        variables: {
          input: {
            name: jobInput,
            variety: "JOB_TYPE",
            office: null,
          },
        },
      });
    }
  };

  const handleAddTraining = () => {
    var trainingLen = trainingInput.trim().length;
    if (trainingLen > 0) {
      addSkills({
        variables: {
          input: {
            name: trainingInput,
            variety: "TRAINING",
          },
        },
      });
    }
  };
  if (loading) {
    return <CircularProgress primary="true" />;
  } else {
    return (
      <>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={4000}
          onClose={() => {
            setsnackbaropen(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              setsnackbaropen(false);
              setsnackBarMessage("");
            }}
            severity="success"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={errorsnackbaropen}
          autoHideDuration={6000}
          onClose={() => {
            seterrorsnackbaropen(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => seterrorsnackbaropen(false)}
            severity="error"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Dialog open={showDialog} fullWidth maxWidth="xs">
          <DialogContent
            style={{ padding: 20, overflowX: "hidden", textAlign: "center" }}
          >
            <Typography>{dialogMessage}</Typography>
            <br />
            <br />
            <Button
              onClick={() => {
                setshowDialog(false);
                setshowDialogMessage("");
                skilltodelete != "" &&
                  deleteSkill({ variables: { id: parseInt(skilltodelete) } });
              }}
              color="primary"
              variant="contained"
            >
              Yes
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setshowDialog(false);
                setshowDialogMessage("");
              }}
              color="primary"
              variant="outlined"
            >
              No
            </Button>
          </DialogContent>
        </Dialog>
        <div style={{ marginTop: "20px" }}>
          <Box mb={4}>
            <Typography variant="h3">Setup Hospital SkillSets</Typography>
          </Box>
          <Grid
            container
            direction="row"
            spacing={4}
            display="flex"
            style={{ border: "0.5px solid lightgray" }}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Certifications</Typography>{" "}
              <Typography>Add Hospital Certifications</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <TextField
                type="text"
                variant="outlined"
                name="newcert"
                value={certInput ? certInput : ""}
                onChange={(e) => setCertInput(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleAddCert();
                  }
                }}
                placeholder="New Certification"
              />
            </Grid>
            <Grid item xs={2} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCert}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6} align="right">
              <Table data-testid="certificationsGrid">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={4}></StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Certification</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { allcerts &&
                    allcerts.length > 0 &&
                    allcerts.map((element, index) => (
                      <TableRow key={index} id={element.id}>
                        <StyledTableCell>{element.name}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              setskilltodelete(element.id);
                              setshowDialog(true);
                              setshowDialogMessage(
                                "Are you sure you want to delete this certification?"
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={12} align="right">
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Education</Typography>{" "}
              <Typography>Add Education</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                name="neweducation"
                value={educationInput ? educationInput : ""}
                onChange={(e) => seteducationInput(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleAddEducation();
                  }
                }}
                placeholder="New Education"
              />
            </Grid>
            <Grid item xs={2} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEducation}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6} align="right">
              <Table data-testid="educationGrid">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      colSpan={4}
                      style={{ textAlign: "left" }}
                    ></StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Education</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alleducation &&
                    alleducation.length > 0 &&
                    alleducation.map((element, index) => (
                      <TableRow key={index} id={element.id}>
                        <StyledTableCell>{element.name}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              setskilltodelete(element.id);
                              setshowDialog(true);
                              setshowDialogMessage(
                                "Are you sure you want to delete this Education?"
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={12} align="right">
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">License</Typography>{" "}
              <Typography>Add Hospital Licensure</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                name="newlicense"
                value={licenseInput ? licenseInput : ""}
                onChange={(e) => setlicenseInput(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleAddLicensure();
                  }
                }}
                placeholder="New License"
              />
            </Grid>
            <Grid item xs={2} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddLicensure}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6} align="right">
              <Table data-testid="licenseGrid">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      colSpan={4}
                      style={{ textAlign: "left" }}
                    ></StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>License</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allLicensure &&
                    allLicensure.length > 0 &&
                    allLicensure.map((element, index) => (
                      <TableRow key={index} id={element.id}>
                        <StyledTableCell>{element.name}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              setskilltodelete(element.id);
                              setshowDialog(true);
                              setshowDialogMessage(
                                "Are you sure you want to delete this Licensure?"
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={12} align="right">
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Job Types</Typography>{" "}
              <Typography>Add Hospital Job Types</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                name="newlicense"
                value={jobInput ? jobInput : ""}
                onChange={(e) => setJobInput(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleAddJobtype();
                  }
                }}
                placeholder="New Job Type"
              />
            </Grid>
            <Grid item xs={2} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddJobtype}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6} align="right">
              <Table data-testid="jobTypeGrid">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      colSpan={4}
                      style={{ textAlign: "left" }}
                    ></StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Job Type</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allJobtypes &&
                    allJobtypes.length > 0 &&
                    allJobtypes.map((element, index) => (
                      <TableRow key={index} id={element.id}>
                        <StyledTableCell>{element.name}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              setskilltodelete(element.id);
                              setshowDialog(true);
                              setshowDialogMessage(
                                "Are you sure you want to delete this Jobtype?"
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={12} align="right">
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Training</Typography>{" "}
              <Typography>Add Hospital Training</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                name="newlicense"
                value={trainingInput ? trainingInput : ""}
                onChange={(e) => setTrainingInput(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleAddTraining();
                  }
                }}
                placeholder="New Training"
              />
            </Grid>
            <Grid item xs={2} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTraining}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6} align="right">
              <Table data-testid="trainingGrid">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      colSpan={4}
                      style={{ textAlign: "left" }}
                    ></StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>Training</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allTrainings &&
                    allTrainings.length > 0 &&
                    allTrainings.map((element, index) => (
                      <TableRow key={index} id={element.id}>
                        <StyledTableCell>{element.name}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              setskilltodelete(element.id);
                              setshowDialog(true);
                              setshowDialogMessage(
                                "Are you sure you want to delete this Jobtype?"
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={5}></Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default LocationSettings;
