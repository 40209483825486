import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Select,
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TextField,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  ALL_OFFICES,
  FIND_SKILL_TYPE,
  GET_SHIFTS_WITH_RANGE,
  GET_SLACK_FOR_STAFF_REQUIREMENT,
  BATCH_UPDATE_SLACKS,
  GET_ALL_USERS,
  UPDATE_SCHEDULE_PERIOD,
  MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT,
  MANAGER_ADD_EMPLOYEE_TO_SHIFT,
  ADD_AND_DROP_OPTIONS_FOR_SHIFT,
} from "../../api/gqlQueries";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Formik, Form, FieldArray } from "formik";
import { format } from "date-fns";
import {
  userVar,
  getOfficeScheduleStatus,
  setShiftIDToEdit,
  schedulePeriodToUpdateAfterUpdateSlacks,
} from "../../cache";
import EventParticipant from "../shiftBasedCalendar/EventParticipant";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    // textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    // textAlign: "center",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
  commentBox: {
    width: "80%",
  },
}));

const StaffRequirementForm = (props) => {
  const classes = useStyles();

  const {
    closeDialog,
    selectedDate,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const [selectedOffice, SetSelectedOffice] = useState("-1");
  const [allShiftsFortheDay, SetAllShiftsFortheDay] = useState([]);
  const [selectedShift, SetSelectedShift] = useState("-1");
  const [currentSlacks, SetCurrentSlacks] = useState([]);
  const [loadingSlacks, SetLoadingSlacks] = useState(false);
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState([]);
  const [employeesAvailable, SetEmployeesAvailable] = useState([]);
  const [slacksForAllOffices, SetSlacksForAllOffices] = useState([]);
  const [addOptions, setAddOptions] = useState([]);
  const [updateSlacksData, SetUpdateSlacksData] = useState([]);

  const user = userVar();

  const allOffices = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on ALL_OFFICES Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const allSkills = useQuery(FIND_SKILL_TYPE, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on FIND_SKILL_TYPE Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const getAllOfficeEmployees = useQuery(GET_ALL_USERS, {
    variables: {
      id: parseInt(user.office.id),
    },
    onCompleted(data) {
      SetEmployeesAvailable(data.offices[0].employeeSet);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_ALL_USERS Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [getShifts] = useLazyQuery(GET_SHIFTS_WITH_RANGE, {
    onCompleted(data) {
      SetAllShiftsFortheDay(data.shifts);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_SHIFTS_WITH_RANGE lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [getSlacks] = useLazyQuery(GET_SLACK_FOR_STAFF_REQUIREMENT, {
    onCompleted(data) {
      console.log(data);
      let slacks =
        data.slacks &&
        data.slacks.length > 0 &&
        data.slacks.map((e) => ({
          id: e.id,
          slack: e.slack,
          required: parseInt(e.count) - parseInt(e.slack),
          count: e.count,
          skill: e.skill,
          schedulePeriod: e.schedulePeriod,
        }));
      slacks.length > 0 &&
        schedulePeriodToUpdateAfterUpdateSlacks({
          schedulePeriodId: slacks[0].schedulePeriod.id,
        });
      SetCurrentSlacks(slacks);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_SLACK_FOR_STAFF_REQUIREMENT lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [getSlacksWithoutOffice] = useLazyQuery(GET_SLACK_FOR_STAFF_REQUIREMENT, {
    onCompleted(data) {
      console.log(data);
      let slacksForAllOffices = data.slacks
        .filter((e) => parseInt(e.slack) > 0)
        .filter((e) => e.schedulePeriod.office.id != selectedOffice)
        .filter((e) => e.schedulePeriod.office.floatStatus === false)
        .sort(
          (a, b) =>
            new Date(a.schedulePeriod.office.id) -
            new Date(b.schedulePeriod.office.id)
        )
        .map((e) => ({
          slack: e.slack,
          skill: e.skill,
          schedulePeriod: e.schedulePeriod,
          selectedEmployee: "",
          shifts: data.shifts.filter(
            (x) => x.office.id === e.schedulePeriod.office.id
          ),
        }));
      console.log(slacksForAllOffices);
      SetSlacksForAllOffices(slacksForAllOffices);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_SLACK_FOR_STAFF_REQUIREMENT lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [batchUpdateSlacks] = useMutation(BATCH_UPDATE_SLACKS, {
    onCompleted(data) {
      getOfficeScheduleStatus({ officeId: parseInt(selectedOffice) });
      refetch();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on BATCH_UPDATE_SLACKS Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [updateSchedulePeriod] = useMutation(UPDATE_SCHEDULE_PERIOD, {
    onCompleted(data) {
      if (
        data.updateSchedulePeriod.schedulePeriod.status ===
        "REQUESTING_ADDITIONAL_FLOATS"
      ) {
        batchUpdateSlacks({
          variables: {
            input: updateSlacksData,
          },
        });
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on UPDATE_SCHEDULE_PERIOD Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [removeEmployee] = useMutation(MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT, {
    onCompleted(data) {
      refetch();
      setToast("Manager Edit Shift");
      setShowToast(true);
    },
   onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("Error Edit Shift Graphql " + error);
      notifyDevelopers({variables: {message: "Error on BATCH_UPDATE_SLACKS Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  //set loader to false after sometime
  useEffect(() => {
    setTimeout(() => {
      SetLoadingSlacks(false);
    }, 1000);
  }, [loadingSlacks]);

  // query for getting add employee and drop employee options for shift
  // const [getRescheduleOptions] = useLazyQuery(ADD_AND_DROP_OPTIONS_FOR_SHIFT, {
  //   onCompleted(data) {
  //     const addOptions =
  //       data.rescheduleAdds.length > 0
  //         ? data.rescheduleAdds
  //             .filter((option) => option.status === "OPTION")
  //             .map((add) => {
  //               const employeeIdsAndBenefits = add.rescheduleindividualSet.map(
  //                 (employee) => {
  //                   return {
  //                     employeeId: employee.employee.id,
  //                     benefit: employee.benefit,
  //                     cost: employee.cost,
  //                     optionId: add.id,
  //                   };
  //                 }
  //               );
  //               return employeeIdsAndBenefits;
  //             })
  //             .flat()
  //         : [];
  //     setAddOptions(addOptions);
  //   },
  //   onError(error) {
  //     console.log(error);
  //   },
  // });

  // get reschedule add and drop option for current shift ID
  // useEffect(() => {
  //   getRescheduleOptions({
  //     variables: {
  //       shifts: [parseInt(selectedShift.id)],
  //     },
  //   });
  // }, [selectedShift != "-1"]);

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      let others = [];
      employees.forEach((employee) => {
        others = [...others, employee];
      });

      others = others.map((employee) => {
        return (
          <MenuItem key={employee.id} value={employee.id}>
            <EventParticipant
              participant={employee}
              showAvatar={false}
              showJobTitle={true}
              singleLine={true}
              addOption={true}
            />
          </MenuItem>
        );
      });
      return [...others];
    } else {
      return (
        <MenuItem value={-1}>
          <Typography>No Eligible Employees</Typography>
        </MenuItem>
      );
    }
  };

  const [addEmployee] = useMutation(MANAGER_ADD_EMPLOYEE_TO_SHIFT, {
    onCompleted(data) {
      refetch();
      setToast("Manager Edit Shift");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setErrorToast("Error Edit Shift");
      setShowErrorToast(true);
    },
  });

  if (
    allOffices.loading ||
    allSkills.loading ||
    getAllOfficeEmployees.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const handleOfficeChange = (value) => {
      SetSelectedShift("-1");
      SetSelectedOffice(value);
      getShifts({
        variables: {
          rangeStart: format(selectedDate, "yyyy-MM-dd") + `T00:00:00`,
          rangeEnd: format(selectedDate, "yyyy-MM-dd") + `T11:59:59`,
          officeId: parseInt(value),
        },
      });
      getSlacksWithoutOffice({
        variables: {
          rangeStart: format(selectedDate, "yyyy-MM-dd") + `T00:00:00`,
          rangeEnd: format(selectedDate, "yyyy-MM-dd") + `T11:59:59`,
        },
      });
    };

    const d = format(selectedDate, "MM-dd-yyyy");
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">Add Staff Requirement for {d} </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between" spacing={4}>
          <Grid item xs={4}>
            <Select
              variant="outlined"
              value={selectedOffice}
              required
              onChange={(e) => {
                handleOfficeChange(e.target.value);
              }}
            >
              <MenuItem value={-1}>Select Office</MenuItem>
              {allOffices.data.offices
                .filter((ofc) => ofc.floatStatus === false)
                .map((e, index) => (
                  <MenuItem key={index} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              variant="outlined"
              value={selectedShift}
              required
              onChange={(e) => {
                let empScheduled =
                  e.target.value.shiftassignmentSet &&
                  e.target.value.shiftassignmentSet.map((e) => {
                    return e.employee;
                  });
                setCurrentShiftEmployees(empScheduled);
                SetSelectedShift(e.target.value);
                let empAvl =
                  employeesAvailable.length > 0 &&
                  employeesAvailable
                    .map((e) => {
                      if (empScheduled.map((x) => x.id != e.id)) {
                        return e;
                      }
                    })
                    .filter(Boolean);
                SetEmployeesAvailable(empAvl);
                if (e.target.value != "-1") {
                  getSlacks({
                    variables: {
                      rangeStart: e.target.value.start,
                      rangeEnd: e.target.value.end,
                      office: parseInt(selectedOffice),
                    },
                  });
                  setShiftIDToEdit({
                    eventId: e.target.value.id,
                    start: new Date(e.target.value.start),
                    end: new Date(e.target.value.end),
                    participants: e.target.value.shiftassignmentSet.map((e) => {
                      return {
                        firstName: e.employee.firstName,
                        lastName: e.employee.lastName,
                        skills: e.employee.skills,
                        id: e.employee.id,
                      };
                    }),
                    eventTitle: `${e.target.value.office.shiftdefinitionSet[0].description} - ${e.target.value.office.name}`,
                    shiftId: `${e.target.value.office.shiftdefinitionSet[0].description}`,
                    officeId: e.target.value,
                  });
                }
              }}
            >
              <MenuItem value="-1">Select Shift</MenuItem>
              {allShiftsFortheDay.length > 0 &&
                allShiftsFortheDay.map((shift, index) => (
                  <MenuItem key={index} value={shift}>
                    {format(new Date(shift.start), "HH:mm")}
                    {" - "}
                    {format(new Date(shift.end), "HH:mm")}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              initialValues={{
                slacks: currentSlacks,
              }}
              onSubmit={(values) => {
                const inputData = values.slacks.map((x) => {
                  return {
                    id: x.id,
                    slack: parseInt(x.count) - parseInt(x.required),
                    start: selectedShift.start,
                    end: selectedShift.end,
                    skill: parseInt(x.skill.id),
                  };
                });
                SetUpdateSlacksData(inputData);
                let updateSchedulePeriodArray = currentSlacks.map((e) => {
                  if (parseInt(e.slack) > 0) {
                    let changedSlack = inputData.find(
                      (x) => x.id === e.id
                    ).slack;
                    if (changedSlack <= 0) {
                      return true;
                    } else {
                      return false;
                    }
                  } else if (parseInt(e.slack) === 0) {
                    let changedSlack = inputData.find(
                      (x) => x.id === e.id
                    ).slack;
                    if (changedSlack < 0) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                });
                if (updateSchedulePeriodArray.includes(true)) {
                  updateSchedulePeriod({
                    variables: {
                      id: parseInt(values.slacks[0].schedulePeriod.id),
                      input: { status: "REQUESTING_ADDITIONAL_FLOATS" },
                    },
                  });
                } else {
                  batchUpdateSlacks({
                    variables: {
                      input: inputData,
                    },
                  });
                  schedulePeriodToUpdateAfterUpdateSlacks(
                    values.slacks[0].schedulePeriod.id
                  );
                }

                SetLoadingSlacks(true);
              }}
            >
              {({ values, handleChange, dirty, setFieldValue }) => (
                <Form>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Enter Staffing Requirement
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={8}>
                      <FieldArray name="slacks">
                        {
                          <>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Skill</StyledTableCell>
                                  <StyledTableCell>Required</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.slacks.map((e, index) => (
                                  <TableRow key={index}>
                                    <StyledTableCell
                                      style={{ display: "none" }}
                                    >
                                      <Typography
                                        name={`slacks.${index}.id`}
                                        id={`slacks.${index}.id`}
                                      >
                                        {e.id}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Select
                                        name={`slacks.${index}.skill.id`}
                                        id={`slacks.${index}.skill.id`}
                                        variant="outlined"
                                        value={e.skill.id}
                                        onChange={handleChange}
                                      >
                                        <MenuItem value="-1">
                                          Select Skill
                                        </MenuItem>
                                        {allSkills.data.skills
                                          .filter(
                                            (x) =>
                                              x.variety === "JOB_TYPE" ||
                                              x.variety === "TRAINING"
                                          )
                                          .filter((x) => x.office === null)
                                          .map((x, index) => (
                                            <MenuItem key={index} value={x.id}>
                                              {x.name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        name={`slacks.${index}.required`}
                                        id={`slacks.${index}.required`}
                                        variant="outlined"
                                        value={e.required}
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        }
                      </FieldArray>
                      <Box style={{ textAlign: "right", marginTop: 20 }}>
                        <Button
                          type="submit"
                          disabled={!dirty || selectedShift === -1}
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between" spacing={4}>
          {loadingSlacks ? (
            <>
              <Typography>Loading Options...</Typography>
              <CircularProgress color="primary" />
            </>
          ) : (
            <>
              <Grid item container justifyContent="space-between" spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Currently Assigned Shift Employees
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container justifyContent="space-between" spacing={4}>
                {currentShiftEmployees.length > 0 &&
                  currentShiftEmployees.map((employee, index) => (
                    <EventParticipant
                      key={index}
                      participant={employee}
                      showAvatar={false}
                      showJobTitle={true}
                      singleLine={false}
                      addOption={false}
                      setOpenSnackBar={setOpenSnackBar}
                      setSnackBarMessage={setSnackBarMessage}
                      notifyDevelopers={notifyDevelopers}
                      environment={environment}

                    />
                  ))}
              </Grid>
              <Grid item container justifyContent="space-between" spacing={4}>
                {selectedShift != -1 && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Department(s) that have extra resources scheduled
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          slacksForAllOffices: slacksForAllOffices,
                        }}
                        onSubmit={(values) => {
                          values.slacksForAllOffices.map((employee) => {
                            if (employee.selectedEmployee != "") {
                              let empToRemove =
                                employee.shifts[0].shiftassignmentSet.find(
                                  (x) =>
                                    x.employee.id === employee.selectedEmployee
                                );
                              removeEmployee({
                                variables: {
                                  shiftAssignmentId: empToRemove.id,
                                  managerId: user.id,
                                },
                              });
                              addEmployee({
                                variables: {
                                  employeeId: parseInt(
                                    employee.selectedEmployee
                                  ),
                                  managerId: parseInt(user.id),
                                  shiftId: parseInt(selectedShift.id),
                                },
                              });
                            }
                          });
                          SetSelectedOffice("-1");
                          SetSelectedShift("-1");
                          refetch();
                          setToast("Manager Edit Shift");
                          setShowToast(true);
                          closeDialog();
                        }}
                      >
                        {({ values, handleChange, dirty, setFieldValue }) => (
                          <Form>
                            <Grid container direction="row" spacing={4}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      Department
                                    </StyledTableCell>
                                    <StyledTableCell>Skill</StyledTableCell>
                                    <StyledTableCell>Slack</StyledTableCell>
                                    <StyledTableCell>
                                      Employees Availble
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.slacksForAllOffices.length > 0 &&
                                    values.slacksForAllOffices.map(
                                      (e, index, arr) => (
                                        <TableRow key={index}>
                                          <StyledTableCell>
                                            <Typography
                                              name={`slacksForAllOffices.${index}.schedulePeriod.office.name`}
                                              id={`slacksForAllOffices.${index}.schedulePeriod.office.name`}
                                              variant="h6"
                                            >
                                              {index != 0 &&
                                              arr[index - 1] &&
                                              arr[index - 1].schedulePeriod
                                                .office.id !=
                                                e.schedulePeriod.office.id
                                                ? e.schedulePeriod.office.name
                                                : index === 0
                                                ? e.schedulePeriod.office.name
                                                : ""}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <Typography
                                              name={`slacksForAllOffices.${index}.skill.name`}
                                              id={`slacksForAllOffices.${index}.skill.name`}
                                            >
                                              {e.skill.name}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <Typography
                                              name={`slacksForAllOffices.${index}.slack`}
                                              id={`slacksForAllOffices.${index}.slack`}
                                            >
                                              {e.slack}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <Grid
                                              item
                                              container
                                              direction="row"
                                              spacing={4}
                                            >
                                              <Grid item>
                                                {" "}
                                                <Select
                                                  variant="outlined"
                                                  name={`slacksForAllOffices.${index}.selectedEmployee`}
                                                  id={`slacksForAllOffices.${index}.selectedEmployee`}
                                                  variant="outlined"
                                                  value={e.selectedEmployee}
                                                  onChange={handleChange}
                                                  className={classes.select}
                                                >
                                                  {renderEmployeeOptions(
                                                    e.shifts.length > 0 &&
                                                      (e.shifts.length === 1
                                                        ? e.shifts[0].shiftassignmentSet
                                                            .filter((emp) =>
                                                              emp.employee.skills.find(
                                                                (x) =>
                                                                  x.id ===
                                                                  e.skill.id
                                                              )
                                                            )
                                                            .filter(
                                                              (emp) =>
                                                                emp.employee
                                                                  .offices[0]
                                                                  .id ===
                                                                user.office.id
                                                            )
                                                            .map(
                                                              (emp) =>
                                                                emp.employee
                                                            )
                                                        : e.shifts.map(
                                                            (shift) =>
                                                              shift.shiftassignmentSet
                                                                .map((x) =>
                                                                  x.employee.map(
                                                                    (emp) =>
                                                                      emp.skills.find(
                                                                        (x) =>
                                                                          x.id ===
                                                                          e
                                                                            .skill
                                                                            .id
                                                                      )
                                                                  )
                                                                )
                                                                .flat()
                                                                .filter(Boolean)
                                                          ))
                                                  )}
                                                </Select>
                                              </Grid>
                                            </Grid>
                                          </StyledTableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                              <Grid item xs={4}>
                                <Box
                                  style={{ textAlign: "right", marginTop: 20 }}
                                >
                                  <Typography>
                                    Selected Office:{" "}
                                    {
                                      allOffices.data.offices.find(
                                        (e) => e.id === selectedOffice
                                      )?.name
                                    }{" "}
                                    -{" "}
                                    {format(
                                      new Date(selectedShift.start),
                                      "HH:mm"
                                    )}
                                    {" - "}
                                    {format(
                                      new Date(selectedShift.end),
                                      "HH:mm"
                                    )}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  style={{ textAlign: "right", marginTop: 20 }}
                                >
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => closeDialog()}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  style={{ textAlign: "right", marginTop: 20 }}
                                >
                                  <Button
                                    type="submit"
                                    disabled={!dirty || selectedShift === -1}
                                    color="primary"
                                    variant="contained"
                                  >
                                    Add Employee(s) to selected Shift
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  }
};

export default StaffRequirementForm;
