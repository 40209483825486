import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Divider,
  makeStyles,
  FormControlLabel,
  Dialog,
  DialogContent,
  Snackbar,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import MangUsers from "./MangTeam";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  FIND_SKILL_TYPE,
  UPDATE_EMPLOYEE_ASSIGNMENT,
  EMPLOYEE_TYPES,
  CREATE_EMPLOYEE_ASSIGNMENT,
  ALL_OFFICES,
  ALL_SHIFT_TYPES,
  ROLES,
  Check_Email_Exists,
  BATCH_CREATE_HIRED_SHIFTS,
  BATCH_UPDATE_HIRED_SHIFTS,
  BATCH_DELETE_HIRED_SHIFTS,
  UPDATE_PASSWORD,
  BATCH_CREATE_EMPLOYEE_SKILLS,
  BATCH_UPDATE_EMPLOYEE_SKILLS,
  BATCH_CREATE_EMPLOYEE_ASSIGNMENT,
  BATCH_UPDATE_EMPLOYEE_ASSIGNMENT,
} from "../../api/gqlQueries";
import MultiSelect from "../general/MultiSelectSkills";
import {
  UpdateAssignment,
  findskillset,
} from "../../helpers/updateUserDetails";
import MuiAlert from "@material-ui/lab/Alert";
import { userVar } from "../../cache";
import { Prompt } from "react-router-dom";
import CustomShiftType from "./CustomShiftType";
import { mapHiredShiftsManagerView } from "../../helpers/hiredShifts";
import { format } from "date-fns";

const useStyles = makeStyles(() => ({
  divider: {
    border: "0.5px solid thin #333333",
    verticalAlign: "bottom",
  },
  inputWidth: {
    width: "100%",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 80,
  },
}));
const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required").email(),
  phone: Yup.string().required("Required"),
  employmentType: Yup.string().required("Required"),
  department: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  shiftType: Yup.string().required("Required"),
  empStartDate: Yup.string().required("Required"),
  hourlyWage: Yup.string().required("Required"),
  role: Yup.string().required("required"),
});

const PromptIfDirty = () => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.dirty && formik.submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes on this form."
    />
  );
};
const UpdateUser = (props) => {
  const refetch = props.refetch;
  const classes = useStyles();
  const user = props.user;
  const getChildOffices = props.getChildOffices;
  const selectedOfc = props.selectedOfc;
  const [open, setOpen] = useState(props.open);
  const [showDialog, setshowDialog] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [shiftTypes, setShiftTypes] = useState();
  const [selectedShiftTypes, setSelectedShiftTypes] = useState();
  const [customShiftTypes, setCustomShiftTypes] = useState(false);
  const [shiftTypeTouched, setShiftTypeTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const notifyDevelopers = props.notifyDevelopers;
  const [openSnackBar, setOpenSnackBar] = useState(props.setOpenSnackBar);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(
    props.setsnackBarMessage
  );
  const environment = props.environment;
  const loggedInUser = userVar();
  const alloffices = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setsnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_OFFICES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const officeid = selectedOfc;
  const office = alloffices.data.offices.find(
    (e) => e.id === (!selectedOfc ? loggedInUser.office : selectedOfc)
  );
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_EMPLOYEE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allEmployeeTypes = useQuery(EMPLOYEE_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allskilltypes = useQuery(FIND_SKILL_TYPE, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  let matchingAssignment;
  let secondaryDepartments;
  if (user) {
    const matching = user.assignmentSet.find(
      (assignment) => assignment.primary === true
    );
    matchingAssignment = matching ? matching : user.assignmentSet[0];
    secondaryDepartments = user.assignmentSet
      .filter(
        (assignment) =>
          assignment.primary === false &&
          (format(new Date(assignment.endDate), "yyyy-MM-dd") >=
            format(new Date(), "yyyy-MM-dd") ||
            assignment.endDate === null ||
            assignment.endDate === "")
      )
      .map((e) => e.office);
  }

  const allShiftTypes = useQuery(ALL_SHIFT_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_SHIFT_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const roles = useQuery(ROLES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ROLES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (allShiftTypes.data) {
      let shiftTypes = [];
      let secondaryDepartmentIds = secondaryDepartments.map((e) => e.id);
      allShiftTypes.data.shiftDefinitions
        .filter((e) => secondaryDepartmentIds.includes(e.office.id))
        .forEach((shift) => {
          const existingShift = shiftTypes.find(
            (type) => type.name === shift.description
          );
          if (existingShift) {
            existingShift.ids.push({
              day: shift.weekday.name,
              id: shift.id,
            });
          } else {
            const shiftObject = {
              name: shift.description,
              ids: [
                {
                  day: shift.weekday.name,
                  id: shift.id,
                },
              ],
            };
            shiftTypes.push(shiftObject);
          }
        });
      setShiftTypes(shiftTypes);
    }
  }, [allShiftTypes]);

  const [updateEmployeeAssignment] = useMutation(UPDATE_EMPLOYEE_ASSIGNMENT, {
    onCompleted(d) {
      console.log(d);
      refetch();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_EMPLOYEE_ASSIGNMENT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateEmployeeAssignment] = useMutation(
    BATCH_CREATE_EMPLOYEE_ASSIGNMENT,
    {
      onCompleted() {
        refetch();
        getChildOffices.refetch();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_EMPLOYEE_ASSIGNMENT Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchUpdateEmployeeAssignment] = useMutation(
    BATCH_UPDATE_EMPLOYEE_ASSIGNMENT,
    {
      onCompleted() {
        refetch();
        getChildOffices.refetch();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_UPDATE_EMPLOYEE_ASSIGNMENT Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_PASSWORD Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateHiredShifts] = useMutation(BATCH_CREATE_HIRED_SHIFTS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_CREATE_HIRED_SHIFTS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [batchUpdateHiredShifts] = useMutation(BATCH_UPDATE_HIRED_SHIFTS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_UPDATE_HIRED_SHIFTS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [batchDeleteHiredShifts] = useMutation(BATCH_DELETE_HIRED_SHIFTS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_DELETE_HIRED_SHIFTS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createEmployeeAssignment] = useMutation(CREATE_EMPLOYEE_ASSIGNMENT, {
    onCompleted(data) {
      if (selectedShiftTypes.length !== 0) {
        const assignmentId = data.createAssignment.assignment.id;
        let inputData = [];
        selectedShiftTypes.forEach((shiftType) => {
          const idsWithAssignment = shiftType.ids.map((id) => {
            return {
              assignment: assignmentId,
              shiftDefinition: id.id,
            };
          });
          inputData = [...inputData, ...idsWithAssignment];
        });
        batchCreateHiredShifts({ variables: { input: inputData } });
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_EMPLOYEE_ASSIGNMENT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [vals, setvals] = useState({});

  const [batchCreateEmployeeSkills] = useMutation(
    BATCH_CREATE_EMPLOYEE_SKILLS,
    {
      onCompleted() {
        refetch();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_EMPLOYEE_SKILLS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchUpdateEmployeeSkills] = useMutation(
    BATCH_UPDATE_EMPLOYEE_SKILLS,
    {
      onCompleted(data) {
        refetch();
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_UPDATE_EMPLOYEE_SKILLS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [addEmployee] = useMutation(ADD_EMPLOYEE, {
    onCompleted(data) {
      const roleid = vals.role;
      const officeid = vals.department;
      const jt = vals.jobType;
      createEmployeeAssignment({
        variables: {
          input: {
            employee: parseInt(data.createEmployee.employee.id),
            office: parseInt(officeid),
            primary: true,
            role: parseInt(roleid),
            startDate: vals.empStartDate,
            schedulable: vals.schedulable,
            rotationLength: vals.rotationLength,
            isManual: vals.isManual,
          },
        },
      });
      //create employee skillset
      let todaysDate = format(new Date(), "yyyy-MM-dd");

      let licenseData = vals.license.map((e) => {
        return {
          employeeId: parseInt(data.createEmployee.employee.id),
          skillId: e.id,
          effective: todaysDate,
        };
      });
      let trainingData = vals.training.map((e) => {
        return {
          employeeId: parseInt(data.createEmployee.employee.id),
          skillId: e.id,
          effective: todaysDate,
        };
      });
      let certificationData = vals.certifications.map((e) => {
        return {
          employeeId: parseInt(data.createEmployee.employee.id),
          skillId: e.id,
          effective: todaysDate,
        };
      });
      let educationData = vals.education.map((e) => {
        return {
          employeeId: parseInt(data.createEmployee.employee.id),
          skillId: e.id,
          effective: todaysDate,
        };
      });
      let jobTypeData = vals.jobType.map((e) => {
        return {
          employeeId: parseInt(data.createEmployee.employee.id),
          skillId: e.id,
          effective: todaysDate,
        };
      });
      let inputData = [
        licenseData,
        trainingData,
        certificationData,
        educationData,
        jobTypeData,
      ];
      let skillInputData = inputData.flat();
      batchCreateEmployeeSkills({
        variables: {
          input: skillInputData,
        },
      });
      refetch();
      setvals({});
      setsnackBarMessage("Employee Successfully Added");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_EMPLOYEE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handlePhoneFormat = (e) => {
    let input = e.replace(/\D/g, "");

    if (input.length >= 10) {
      input =
        "(" +
        input.slice(0, 3) +
        ")-" +
        input.slice(3, 6) +
        "-" +
        input.slice(6, 10);
    } else {
      input = e;
    }

    return input;
  };

  const [emailExist] = useLazyQuery(Check_Email_Exists, {
    onCompleted(data) {
      if (data.emailExists != null) {
        setsnackBarMessage(
          "Email with the same user exists. Please try with another email."
        );
        seterrorsnackbaropen(true);
      } else {
        handleAddEmployee(vals);
        seterrorsnackbaropen(false);
        setsnackBarMessage("");
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setErrorSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on Check_Email_Exists lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleAddEmployee = (values) => {
    const phone = values.phone.replace(/\D/g, "");
    addEmployee({
      variables: {
        input: {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          email: values.email,
          personalEmail: values.personalEmail,
          password: "Test@123",
          primaryPhone: phone,
          hasChangedDefaultPassword: false,
          hourlyWage: parseFloat(values.hourlyWage),
          employeeType: values.employmentType,
        },
      },
    });
    setvals(values);
  };

  if (
    allskilltypes.loading ||
    roles.loading ||
    allShiftTypes.loading ||
    alloffices.loading ||
    allEmployeeTypes.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const certs = findskillset(allskilltypes.data.skills, "CERTIFICATION");
    const userCerts =
      user &&
      user.employeeskillSet &&
      user.employeeskillSet
        .filter(
          (e) =>
            e.skill.variety === "CERTIFICATION" &&
            new Date(e.expiration) > new Date()
        )
        .map((e) => e.skill);

    const trng = allskilltypes.data.skills.filter(
      (skill) =>
        (skill.office === null ||
          (skill.office && skill.office.id === selectedOfc)) &&
        skill.variety === "TRAINING"
    );
    const userTrng =
      user &&
      user.employeeskillSet &&
      user.employeeskillSet
        .filter(
          (e) =>
            e.skill.variety === "TRAINING" &&
            new Date(e.expiration) > new Date()
        )
        .map((e) => e.skill);

    const lics = findskillset(allskilltypes.data.skills, "LICENSE");
    const userLics =
      user &&
      user.employeeskillSet &&
      user.employeeskillSet
        .filter(
          (e) =>
            e.skill.variety === "LICENSE" && new Date(e.expiration) > new Date()
        )
        .map((e) => e.skill);

    const educ = findskillset(allskilltypes.data.skills, "EDUCATION");
    const useredu =
      user &&
      user.employeeskillSet &&
      user.employeeskillSet
        .filter(
          (e) =>
            e.skill.variety === "EDUCATION" &&
            new Date(e.expiration) > new Date()
        )
        .map((e) => e.skill);

    const jobType = allskilltypes.data.skills.filter(
      (skill) =>
        (skill.office === null ||
          (skill.office && skill.office.id === selectedOfc)) &&
        skill.variety === "JOB_TYPE"
    );
    const userjobType =
      user &&
      user.employeeskillSet &&
      user.employeeskillSet
        .filter(
          (e) =>
            e.skill.variety === "JOB_TYPE" &&
            new Date(e.expiration) > new Date()
        )
        .map((e) => e.skill);

    const handleUpdateEmployee = (values) => {
      const roleid = values.role;
      const employeetypeid = values.employmentType;
      const officeid = values.department;
      const phone = values.phone.replace(/\D/g, "");
      updateEmployee({
        variables: {
          id: values.id,
          input: {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            email: values.email,
            personalEmail: values.personalEmail,
            primaryPhone: phone,
            hourlyWage: parseFloat(values.hourlyWage),
            employeeType: employeetypeid,
          },
        },
      });
      if (values.password != "") {
        updatePassword({
          variables: {
            id: parseInt(user.id),
            input: { password: values.password },
          },
        });
      }

      if (shiftTypeTouched) {
        const originalShifts = matchingAssignment.hiredshiftSet;
        const assignmentId = matchingAssignment.id;
        const selectedShiftTypesLength = selectedShiftTypes
          .map((shift) => shift.ids)
          .flat().length;

        if (selectedShiftTypesLength === originalShifts.length) {
          let inputData = [];
          selectedShiftTypes.forEach((shiftType) => {
            const idsWithAssignment = shiftType.ids.map((id, index) => {
              return {
                id: originalShifts[index].id,
                assignment: assignmentId,
                shiftDefinition: id.id,
              };
            });
            inputData = [...inputData, ...idsWithAssignment];
          });
          batchUpdateHiredShifts({ variables: { input: inputData } });
        } else if (selectedShiftTypes.length === 0) {
          const originalIds = originalShifts.map((shift) => shift.id);
          batchDeleteHiredShifts({ variables: { ids: originalIds } });
        } else {
          const originalIds = originalShifts.map((shift) => shift.id);
          batchDeleteHiredShifts({ variables: { ids: originalIds } });
          let inputData = [];
          selectedShiftTypes.forEach((shiftType) => {
            const idsWithAssignment = shiftType.ids.map((id) => {
              return {
                assignment: assignmentId,
                shiftDefinition: id.id,
              };
            });
            inputData = [...inputData, ...idsWithAssignment];
          });
          batchCreateHiredShifts({ variables: { input: inputData } });
        }
      }

      let assignmentsToUpdate = [];
      let assignmentsToCreate = [];

      let userCurrentAssignments = user.assignmentSet.filter(
        (x) => x.primary === false
      );
      if (values.secondaryDepartments.length < userCurrentAssignments.length) {
        if (values.secondaryDepartments.length === 0) {
          userCurrentAssignments.map((assignment) => {
            assignmentsToUpdate.push({
              id: assignment.id,
              role: parseInt(roleid),
              office: parseInt(assignment.office.id),
              primary: false,
              startDate: values.empStartDate,
              endDate: values.empEndDate
                ? values.empEndDate
                : format(new Date(), "yyyy-MM-dd"),
              schedulable: values.schedulable,
              rotationLength: values.rotationLength,
              employee: parseInt(user.id),
            });
          });
        } else {
          let currentSecondaryAssignments = values.secondaryDepartments.map(
            (e) => e.id
          );
          userCurrentAssignments &&
            userCurrentAssignments.length > 0 &&
            userCurrentAssignments
              .filter(
                (assignment) =>
                  !currentSecondaryAssignments.includes(assignment.id)
              )
              .map((assignment) => {
                console.log(assignment);
                assignmentsToUpdate.push({
                  id: assignment.id,
                  role: parseInt(roleid),
                  office: parseInt(assignment.office.id),
                  primary: false,
                  startDate: values.empStartDate,
                  endDate: values.empEndDate
                    ? values.empEndDate
                    : format(new Date(), "yyyy-MM-dd"),
                  schedulable: values.schedulable,
                  rotationLength: values.rotationLength,
                  employee: parseInt(user.id),
                });
              });
        }
      }
      values.secondaryDepartments.map((so) => {
        let existing = userCurrentAssignments.find(
          (x) => so.id === x.office.id
        );
        if (!existing) {
          assignmentsToCreate.push({
            role: parseInt(roleid),
            office: parseInt(so.id),
            primary: false,
            startDate: values.empStartDate,
            endDate: values.empEndDate ? values.empEndDate : null,
            schedulable: values.schedulable,
            rotationLength: values.rotationLength,
            employee: parseInt(user.id),
          });
        } else if (
          existing &&
          format(new Date(existing.endDate), "yyyy-MM-dd") <=
            format(new Date(), "yyyy-MM-dd")
        ) {
          assignmentsToUpdate.push({
            id: existing.id,
            role: parseInt(roleid),
            office: parseInt(so.id),
            primary: false,
            startDate: values.empStartDate,
            endDate: values.empEndDate ? values.empEndDate : null,
            schedulable: values.schedulable,
            rotationLength: values.rotationLength,
            employee: parseInt(user.id),
          });
        } else {
          assignmentsToUpdate.push({
            id: existing.id,
            role: parseInt(roleid),
            office: parseInt(so.id),
            primary: false,
            startDate: values.empStartDate,
            endDate: format(new Date(), "yyyy-MM-dd"),
            schedulable: values.schedulable,
            rotationLength: values.rotationLength,
            employee: parseInt(user.id),
          });
        }
      });

      batchCreateEmployeeAssignment({
        variables: {
          input: assignmentsToCreate,
        },
      });

      batchUpdateEmployeeAssignment({
        variables: {
          input: assignmentsToUpdate,
        },
      });

      //primary assignment
      updateEmployeeAssignment({
        variables: {
          id: parseInt(matchingAssignment.id),
          input: {
            role: parseInt(roleid),
            office: parseInt(officeid),
            primary: true,
            startDate: values.empStartDate,
            endDate: values.empEndDate != "" ? values.empEndDate : null,
            schedulable: values.schedulable,
            rotationLength: values.rotationLength,
            isManual: values.isManual,
          },
        },
      });

      //create employee skillsetD
      let todaysDate = format(new Date(), "yyyy-MM-dd");
      let licenseData = values.license.map((e) => {
        return {
          employeeId: parseInt(values.id),
          skillId: parseInt(e.id),
          effective: todaysDate,
        };
      });
      let trainingData = values.training.map((e) => {
        return {
          employeeId: parseInt(values.id),
          skillId: parseInt(e.id),
          effective: todaysDate,
        };
      });
      let certificationData = values.certifications.map((e) => {
        return {
          employeeid: parseInt(values.id),
          skillid: parseInt(e.id),
          effective: todaysDate,
        };
      });
      let educationData = values.education.map((e) => {
        return {
          employeeId: parseInt(values.id),
          skillId: parseInt(e.id),
          effective: todaysDate,
        };
      });
      let jobTypeData = values.jobType.map((e) => {
        return {
          employeeId: parseInt(values.id),
          skillId: parseInt(e.id),
          effective: todaysDate,
        };
      });
      let inputData = [
        licenseData,
        trainingData,
        certificationData,
        educationData,
        jobTypeData,
      ].flat();
      const skillsToDelete = user.employeeskillSet
        .filter(
          (e) =>
            !inputData.find((s) => parseInt(s.skillId) === parseInt(e.skill.id))
        )
        .map((e) => {
          return {
            id: parseInt(e.id),
            newExpiration: format(new Date(), "yyyy-MM-dd"),
          };
        });
      inputData = inputData
        .filter(
          (e) =>
            !user.employeeskillSet.find(
              (s) => parseInt(s.skill.id) === parseInt(e.skillId)
            )
        )
        .map((e) => e)
        .filter(Boolean);
      //delete existing skills

      batchUpdateEmployeeSkills({
        variables: {
          input: skillsToDelete,
        },
      });
      // let skillInputData = inputData;
      batchCreateEmployeeSkills({
        variables: {
          input: inputData,
        },
      });
      setsnackBarMessage("Employee Successfully updated");
      setsnackbaropen(true);
    };

    return (
      <main variant="body">
        {open ? (
          <>
            <Snackbar
              open={snackbaropen}
              autoHideDuration={3000}
              onClose={() => {
                window.localStorage.setItem("gridGrid", "");
                window.localStorage.setItem("griddata-grid", "");
                setsnackbaropen(false);
                setOpen(false);
              }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => {
                  setsnackbaropen(false);
                  setsnackBarMessage("");
                }}
                severity="success"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Snackbar
              open={errorsnackbaropen}
              autoHideDuration={6000}
              onClose={() => {
                seterrorsnackbaropen(false);
              }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => seterrorsnackbaropen(false)}
                severity="error"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Formik
              initialValues={
                user == null
                  ? {
                      firstName: "",
                      lastName: "",
                      email: "",
                      personalEmail: "",
                      phone: "",
                      certifications: [],
                      license: [],
                      training: [],
                      education: [],
                      employmentType: "",
                      shiftType: "",
                      department: loggedInUser.office.id,
                      secondaryDepartments: [],
                      schedulable: "",
                      jobType: [],
                      empStartDate: "",
                      preferenceUpdatedDate: "",
                      rotationLength: 0,
                      hourlyWage: "",
                      role: "",
                      isManual: "",
                    }
                  : {
                      id: parseInt(user.id),
                      firstName: user.firstName,
                      lastName: user.lastName,
                      email: user.email,
                      personalEmail: user.personalEmail,
                      phone: user.primaryPhone,
                      id: user.id,
                      certifications: userCerts ? userCerts : [],
                      license: userLics ? userLics : [],
                      training: userTrng ? userTrng : [],
                      education: useredu ? useredu : [],
                      employmentType: user.employeeType.id,
                      shiftType: mapHiredShiftsManagerView(
                        matchingAssignment.hiredshiftSet
                      ),
                      department: matchingAssignment.office.id,
                      secondaryDepartments: secondaryDepartments
                        ? secondaryDepartments
                        : [],
                      schedulable: matchingAssignment.schedulable,
                      jobType: userjobType ? userjobType : [],
                      empStartDate: matchingAssignment.startDate,
                      empEndDate: matchingAssignment.endDate
                        ? matchingAssignment.endDate
                        : "",
                      preferenceUpdatedDate:
                        user.preferenceSet &&
                        user.preferenceSet.length > 0 &&
                        user.preferenceSet[0]
                          ? user.preferenceSet[0].updatedAt
                          : "",
                      rotationLength: matchingAssignment.rotationLength,
                      password: "",
                      confirmPassword: "",
                      hourlyWage: user.hourlyWage,
                      role: matchingAssignment.role.id,
                      isManual: matchingAssignment.isManual,
                    }
              }
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (user === null) {
                  emailExist({
                    variables: {
                      email: values.email,
                    },
                  });
                  setvals(values);
                } else {
                  handleUpdateEmployee(values);
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
                setFieldError,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <PromptIfDirty />
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={6} className={classes.headerSpacing}>
                      <Typography variant="h3"> Update Profile</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.headerSpacing}></Grid>
                    <Grid
                      item
                      xs={2}
                      align="right"
                      className={classes.headerSpacing}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.localStorage.setItem("gridGrid", "");
                          window.localStorage.setItem("griddata-grid", "");
                          setOpen(false);
                        }}
                        color="primary"
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      align="left"
                      className={classes.headerSpacing}
                    >
                      {user == null ? (
                        <Button
                          type="submit"
                          disabled={!dirty}
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          disabled={!dirty || !isValid}
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={9} align="right">
                      <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9} align="center">
                      <Typography variant="h4">Contact Details</Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">First Name</Typography>
                      <TextField
                        id="first-name"
                        variant="outlined"
                        name="firstName"
                        className={classes.inputWidth}
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.firstName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Last Name</Typography>
                      <TextField
                        className={classes.inputWidth}
                        id="last-name"
                        variant="outlined"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Work Email</Typography>
                      <TextField
                        className={classes.inputWidth}
                        id="email"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Personal Email</Typography>
                      <TextField
                        className={classes.inputWidth}
                        id="personalEmail"
                        variant="outlined"
                        name="personalEmail"
                        value={values.personalEmail}
                        onChange={handleChange}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.personalEmail}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Phone</Typography>
                      <TextField
                        id="phone"
                        variant="outlined"
                        className={classes.inputWidth}
                        name="phone"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">+1</InputAdornment>
                          ),
                        }}
                        placeholder="xxx-xxx-xxxx"
                        value={values.phone}
                        onChange={(e) =>
                          setFieldValue(
                            "phone",
                            handlePhoneFormat(e.target.value)
                          )
                        }
                      />

                      <Typography color="primary" variant="subtitle2">
                        {errors.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">
                        Allow User to be on Schedule
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values.schedulable}
                            checked={values.schedulable ? true : false}
                            onChange={handleChange}
                            name="schedulable"
                          />
                        }
                        label="Yes"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">
                        Allow User to Manually Schedule Shifts
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isManual}
                            value={values.usManual}
                            onChange={handleChange}
                            name="isManual"
                          />
                        }
                        label="Yes"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Divider className={classes.divider} />
                    </Grid>

                    {user != null && (
                      <>
                        <Grid item xs={9} align="center">
                          <Typography variant="h4">Update Password</Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="password"
                            variant="outlined"
                            name="password"
                            placeholder="password"
                            className={classes.inputWidth}
                            value={values.password}
                            onChange={handleChange}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            id="confirmPassword"
                            variant="outlined"
                            name="confirmPassword"
                            placeholder="confirm password"
                            className={classes.inputWidth}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </>
                    )}
                    <Grid item xs={9} align="center">
                      <Typography variant="h4">Skillset</Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Certifications</Typography>
                      <MultiSelect
                        options={
                          certs.length > 0 ? certs : [{ id: 0, name: "none" }]
                        }
                        name="certifications"
                        id="certifications"
                        placeholder="certifications"
                        onChange={setFieldValue}
                        val={values.certifications}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.certifications}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Training</Typography>
                      <MultiSelect
                        options={
                          trng.length > 0 ? trng : [{ id: 0, name: "none" }]
                        }
                        name="training"
                        id="training"
                        placeholder="training"
                        onChange={setFieldValue}
                        val={values.training}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.training}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Licensure</Typography>
                      <MultiSelect
                        options={
                          lics.length > 0 ? lics : [{ id: 0, name: "none" }]
                        }
                        name="license"
                        id="license"
                        placeholder="license"
                        onChange={setFieldValue}
                        val={values.license}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Job Type</Typography>
                      <MultiSelect
                        options={
                          jobType.length > 0
                            ? jobType
                            : [{ id: 0, name: "none" }]
                        }
                        name="jobType"
                        id="jobType"
                        placeholder="Job Type"
                        onChange={setFieldValue}
                        val={values.jobType}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={9}>
                      <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={9} align="center">
                      <Typography variant="h4">Employment Details</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Primary Department</Typography>
                      <Select
                        id="department"
                        name="department"
                        value={values.department}
                        onChange={handleChange}
                        className={classes.inputWidth}
                        variant="outlined"
                      >
                        {alloffices.data.offices.map((i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography color="primary" variant="subtitle2">
                        {errors.department}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">
                        Secondary Departments
                      </Typography>
                      <MultiSelect
                        options={
                          alloffices.data.offices.length > 0
                            ? alloffices.data.offices
                            : [{ id: 0, name: "none" }]
                        }
                        name="secondaryDepartments"
                        id="secondaryDepartments"
                        placeholder="Secondary Departments"
                        onChange={setFieldValue}
                        val={values.secondaryDepartments}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Employment Type</Typography>
                      <Select
                        id="employmentType"
                        name="employmentType"
                        value={values.employmentType}
                        onChange={handleChange}
                        className={classes.inputWidth}
                        variant="outlined"
                      >
                        {allEmployeeTypes.data.employeeTypes.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography color="primary" variant="subtitle2">
                        {errors.employmentType}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5">Shift Type</Typography>
                      <Select
                        id="shiftType"
                        name="shiftType"
                        value={values.shiftType}
                        onChange={(event) => {
                          handleChange(event);
                          if (event.target.value === "Any Shift") {
                            setSelectedShiftTypes([]);
                          } else if (event.target.value !== "Custom") {
                            const matchingShiftType = shiftTypes.find(
                              (shiftType) =>
                                shiftType.name === event.target.value
                            );
                            setSelectedShiftTypes([matchingShiftType]);
                          }
                          setShiftTypeTouched(true);
                        }}
                        className={classes.inputWidth}
                        variant="outlined"
                      >
                        <MenuItem value={"Any Shift"}>Any Shift</MenuItem>
                        {shiftTypes &&
                          shiftTypes.map((shiftType) => (
                            <MenuItem
                              key={shiftType.name}
                              value={shiftType.name}
                            >
                              {shiftType.name}
                            </MenuItem>
                          ))}
                        <MenuItem
                          value="Custom"
                          onClick={() => setCustomShiftTypes(true)}
                        >
                          <Typography color="primary">
                            Custom Shift Schedule
                          </Typography>
                        </MenuItem>
                      </Select>
                      <Typography color="primary" variant="subtitle2">
                        {errors.shiftType}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">
                        Application Access Role
                      </Typography>
                      <Select
                        id="role"
                        variant="outlined"
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                        className={classes.inputWidth}
                      >
                        {roles.data.roles.length > 0 &&
                          roles.data.roles.map((i, index) => (
                            <MenuItem key={index} value={i.id}>
                              {i.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <Typography color="primary" variant="subtitle2">
                        {errors.role}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Rotate Shifts</Typography>
                      <TextField
                        id="rotationLength"
                        name="rotationLength"
                        variant="outlined"
                        type="number"
                        value={values.rotationLength}
                        onChange={handleChange}
                        className={classes.inputWidth}
                      />
                      <Typography variant="body2">
                        Enter value in weeks. Set to 0 if employee does not
                        rotate shifts.
                      </Typography>
                      <Typography color="primary" variant="subtitle2">
                        {errors.rotationLength}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">
                        Assignment Start Date
                      </Typography>
                      <TextField
                        id="empStartDate"
                        name="empStartDate"
                        variant="outlined"
                        type="date"
                        value={values.empStartDate}
                        onChange={(e) => {
                          setFieldValue(
                            "empStartDate",
                            format(new Date(e.target.valueAsDate), "yyyy-MM-dd")
                          );
                        }}
                        className={classes.inputWidth}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.empStartDate}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Assignment End Date</Typography>
                      <TextField
                        id="empEndDate"
                        name="empEndDate"
                        variant="outlined"
                        type="date"
                        value={values.empEndDate}
                        onChange={(e) => {
                          setFieldValue(
                            "empEndDate",
                            format(new Date(e.target.valueAsDate), "yyyy-MM-dd")
                          );
                        }}
                        className={classes.inputWidth}
                      />
                      <Button
                        color="secondary"
                        onClick={() => setFieldValue("empEndDate", "")}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">
                        Preference Last Updated
                      </Typography>
                      <TextField
                        id="preferenceUpdatedDate"
                        name="preferenceUpdatedDate"
                        variant="outlined"
                        type="date"
                        value={values.preferenceUpdatedDate?.slice(0, 10)}
                        disabled={true}
                        onChange={handleChange}
                        className={classes.inputWidth}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Hourly Wage</Typography>
                      <TextField
                        id="hourlyWage"
                        name="hourlyWage"
                        variant="outlined"
                        value={values.hourlyWage}
                        onChange={handleChange}
                        className={classes.inputWidth}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.hourlyWage}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    <Grid item xs={4}>
                      <Typography variant="h5">Education</Typography>
                      <MultiSelect
                        options={
                          educ.length > 0 ? educ : [{ id: 0, name: "none" }]
                        }
                        name="education"
                        id="education"
                        placeholder="education"
                        onChange={setFieldValue}
                        val={values.education}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.education}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>

                    <Grid
                      item
                      xs={2}
                      align="right"
                      className={classes.headerSpacing}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.localStorage.setItem("gridGrid", "");
                          window.localStorage.setItem("griddata-grid", "");
                          setOpen(false);
                        }}
                        color="primary"
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      align="left"
                      className={classes.headerSpacing}
                    >
                      <Button
                        type="submit"
                        disabled={!dirty || !isValid}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  <Dialog open={customShiftTypes} fullWidth maxWidth="sm">
                    <DialogContent
                      style={{
                        padding: 20,
                        overflowX: "hidden",
                        textAlign: "center",
                      }}
                    >
                      <CustomShiftType
                        allShiftTypes={shiftTypes}
                        userShiftTypes={
                          user ? matchingAssignment.hiredshiftSet : []
                        }
                        closeDialog={() => setCustomShiftTypes(false)}
                        selectedShiftTypes={selectedShiftTypes}
                        setSelectedShiftTypes={setSelectedShiftTypes}
                        setFieldError={setFieldError}
                        rotationValue={values.rotationLength}
                      />
                    </DialogContent>
                  </Dialog>
                </form>
              )}
            </Formik>
            <Dialog open={showDialog} fullWidth maxWidth="xs">
              <DialogContent
                style={{
                  padding: 20,
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                <Typography>
                  You are changing the user's department. The shifts he is
                  currently assigned to for this department will not be affected
                  by this change.
                </Typography>
                <br />
                <br />
                <Button
                  onClick={() => setshowDialog(false)}
                  color="primary"
                  variant="contained"
                >
                  OK
                </Button>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <MangUsers selOfc={office && office.name} />
        )}
      </main>
    );
  }
};

export default UpdateUser;
