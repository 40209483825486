import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Box,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  makeStyles,
  Container,
  Snackbar,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import {
  SHIFT_DEFINITIONS,
  BATCH_UPDATE_SHIFT_DEFINITIONS,
  BATCH_CREATE_SHIFT_DEFINITIONS,
  BATCH_DELETE_SHIFT_DEFINITIONS,
  SHIFT_DEFINITION_TYPE,
  WEEKDAY,
  GET_OFFICE,
  BATCH_DELETE_CONSTRAINTS,
  GET_USER_NAME,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import { timezones } from "../../helpers/officeHelpers";
import MuiAlert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { getShifts } from "../../helpers/officeHelpers";
import { Formik, FieldArray, useFormikContext, Form } from "formik";
import { userVar, officeConfigVar } from "../../cache";
import { Prompt } from "react-router-dom";
import ErrorSnackBar from "../errors/errorSnackBar";
import CustomShiftType from "../mangUsers/CustomShiftType";

const getShiftDefDetails = (values) => {
  let shifts = [];
  let vals = values
    .map((e) => {
      if (
        !shifts.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id
        )
      ) {
        shifts.push(e);
        let mon = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Monday"
        );
        let tue = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Tuesday"
        );
        let wed = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Wednesday"
        );
        let thu = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Thursday"
        );
        let fri = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Friday"
        );
        let sat = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Saturday"
        );
        let sun = values.find(
          (s) =>
            s.description === e.description &&
            s.start === e.start &&
            e.end === e.end &&
            s.type.id === e.type.id &&
            s.weekday.name === "Sunday"
        );
        return {
          description: e.description,
          start: e.start,
          end: e.end,
          type: e.type,
          monday: mon,
          tuesday: tue,
          wednesday: wed,
          thursday: thu,
          friday: fri,
          saturday: sat,
          sunday: sun,
        };
      }
    })
    .filter(Boolean);
  return vals;
};

const useStyles = makeStyles(() => ({
  inputWidth: {
    width: "100%",
  },
  mediumtextbox: {
    width: "50%",
  },
  smalltextbox: {
    width: "25%",
  },
  loading: {
    display: "block",
    margin: "auto",
  },
  textColor: {
    color: "#F15A29",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 80,
  },
  autocompleteInput: {
    width: "100%",
    padding: 0,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const PromptIfDirty = () => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes on this form."
    />
  );
};

function OfficeShifts(props) {
  const user = userVar();
  const classes = useStyles();
  const [office, SetOffice] = useState(null);
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { data, refetch, loading } = useQuery(GET_OFFICE, {
    variables: {
      id: parseInt(props.office),
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "Something went wrong. Please try again. Graphql " + error
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    !loading && SetOffice(data.offices[0]);
  }, [!loading]);

  const shiftDefinitions = useQuery(SHIFT_DEFINITIONS, {
    variables: {
      officeId: parseInt(props.office),
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SHIFT_DEFINITIONS query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchcreateShiftDefinition] = useMutation(
    BATCH_CREATE_SHIFT_DEFINITIONS,
    {
      onCompleted(data) {
        refetch();
        shiftDefinitions.refetch();
        setsnackBarMessage("Changes Succesfully Updated");
        setsnackbaropen(true);
      },
      onError(error) {
        console.log(error);
        setSnackBarOpen(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_SHIFT_DEFINITIONS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchUpdateShiftDefinitions] = useMutation(
    BATCH_UPDATE_SHIFT_DEFINITIONS,
    {
      onCompleted(data) {
        refetch();
        shiftDefinitions.refetch();
        setsnackBarMessage("Changes Succesfully Updated");
        setsnackbaropen(true);
      },
      onError(error) {
        console.log(error);
        setSnackBarOpen(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_SHIFT_DEFINITIONS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchDeleteShiftDefitions] = useMutation(
    BATCH_DELETE_SHIFT_DEFINITIONS,
    {
      onCompleted(data) {
        refetch();
        shiftDefinitions.refetch();
      },
      onError(error) {
        console.log(error);
        setSnackBarOpen(true);
        setErrorSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_DELETE_SHIFT_DEFINITIONS Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchdeleteConstraint] = useMutation(BATCH_DELETE_CONSTRAINTS, {
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_DELETE_CONSTRAINTS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const shiftDefinitionTypes = useQuery(SHIFT_DEFINITION_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );

      notifyDevelopers({
        variables: {
          message:
            "Error on SHIFT_DEFINITION_TYPE query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const weekday = useQuery(WEEKDAY, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "Something went wrong. Please try again. Graphql " + error
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on WEEKDAY query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [showDialog, setshowDialog] = useState(false);

  const handleShiftsSave = (values) => {
    let updateData = [];
    let createData = [];
    let deleteData = [];
    values.forEach((element) => {
      let monday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Monday"
      );
      let tuesday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Tuesday"
      );
      let wednesday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Wednesday"
      );
      let thursday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Thursday"
      );
      let friday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Friday"
      );
      let saturday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Saturday"
      );
      let sunday = shiftDefinitions.data.shiftDefinitions.find(
        (s) =>
          s.description === element.description &&
          s.start === element.start &&
          s.end === element.end &&
          s.type.id === element.type.id &&
          s.weekday.name === "Sunday"
      );
      if (element.monday && element.monday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.monday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.monday.id),
            weekday: parseInt(element.monday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Monday"
          );
          !monday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (monday) {
          deleteData.push(parseInt(monday.id));
        }
      }
      if (element.tuesday && element.tuesday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.tuesday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.tuesday.id),
            weekday: parseInt(element.tuesday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Tuesday"
          );
          !tuesday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (tuesday) {
          deleteData.push(parseInt(tuesday.id));
        }
      }
      if (element.wednesday && element.wednesday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.wednesday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.wednesday.id),
            weekday: parseInt(element.wednesday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Wednesday"
          );
          !wednesday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (wednesday) {
          deleteData.push(parseInt(wednesday.id));
        }
      }
      if (element.thursday && element.thursday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.thursday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.thursday.id),
            weekday: parseInt(element.thursday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Thursday"
          );
          !thursday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (thursday) {
          deleteData.push(parseInt(thursday.id));
        }
      }
      if (element.friday && element.friday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.friday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.friday.id),
            weekday: parseInt(element.friday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Friday"
          );
          !friday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (friday) {
          deleteData.push(parseInt(friday.id));
        }
      }
      if (element.saturday && element.saturday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.saturday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.saturday.id),
            weekday: parseInt(element.saturday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Saturday"
          );
          !saturday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (saturday) {
          deleteData.push(parseInt(saturday.id));
        }
      }
      if (element.sunday && element.sunday != false) {
        let dat = {
          office: parseInt(office.id),
          start: element.start,
          end: element.end,
          type: parseInt(element.type.id),
          description: element.description,
        };
        if (element.sunday.id) {
          updateData.push({
            ...dat,
            id: parseInt(element.sunday.id),
            weekday: parseInt(element.sunday.weekday.id),
          });
        } else {
          let weekdayId = weekday.data.weekdays.find(
            (w) => w.name === "Sunday"
          );
          !sunday &&
            createData.push({ ...dat, weekday: parseInt(weekdayId.id) });
        }
      } else {
        if (sunday) {
          deleteData.push(parseInt(sunday.id));
        }
      }
    });

    if (createData.length > 0) {
      batchcreateShiftDefinition({
        variables: {
          input: createData,
        },
      });
    }

    if (deleteData.length > 0) {
      batchDeleteShiftDefitions({
        variables: { ids: deleteData },
      });
    }

    if (updateData.length > 0) {
      batchUpdateShiftDefinitions({
        variables: { input: updateData },
      });
    }
  };

  const [shiftstodelete, setshiftstodelete] = useState({});

  const handleDeleteShifts = () => {
    setshowDialog(false);
    let selectedShifts = shiftDefinitions.data.shiftDefinitions.filter(
      (i) =>
        i.description === shiftstodelete.description &&
        i.type.type === shiftstodelete.shiftType &&
        i.office.id === office.id
    );
    let shiftsData = [];
    let officeConstraintData = [];
    selectedShifts.forEach((e) => {
      shiftsData.push(parseInt(e.id));
      office.constraintSet
        .filter((x) => x.starttime === e.start && x.endtime === e.end)
        .map((i) => {
          //deleteConstraint({ variables: { id: parseInt(i.id) } });
          officeConstraintData.push(i.id);
        });
    });
    batchDeleteShiftDefitions({
      variables: {
        ids: shiftsData,
      },
    });
    batchdeleteConstraint({
      variables: {
        ids: officeConstraintData,
      },
    });
  };

  if (
    loading ||
    shiftDefinitions.loading ||
    shiftDefinitionTypes.loading ||
    !office
  ) {
    return <CircularProgress color="primary" />;
  } else {
    let shiftTypes = [];
    shiftDefinitionTypes.data.shiftDefinitions &&
      shiftDefinitionTypes.data.shiftDefinitions.length > 0 &&
      shiftDefinitionTypes.data.shiftDefinitions.map((shift) => {
        const existingShift = shiftTypes.find(
          (type) => type.name === shift.description
        );
        if (existingShift) {
          existingShift.ids.push({
            type: shift.type.type,
            day: shift.weekday.name,
            id: shift.id,
          });
        } else {
          const shiftObject = {
            type: shift.type.type,
            name: shift.description,
            ids: [
              {
                day: shift.weekday.name,
                id: shift.id,
              },
            ],
          };
          shiftTypes.push(shiftObject);
        }
      });
    return (
      <main variant="body" data-testid="officeshiftsGrid">
        <Container style={{ margin: 0, padding: 0 }}>
          <Dialog open={showDialog} fullWidth maxWidth="xs">
            <DialogContent
              style={{
                padding: 20,
                overflowX: "hidden",
                textAlign: "center",
              }}
            >
              <Typography>
                Are you sure you want to delete this Shift?
              </Typography>
              <br />
              <br />
              <Button
                onClick={handleDeleteShifts}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleDeleteShifts();
                  }
                }}
                color="primary"
                variant="contained"
              >
                Yes
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => setshowDialog(false)}
                color="primary"
                variant="outlined"
              >
                No
              </Button>
            </DialogContent>
          </Dialog>

          <ErrorSnackBar
            open={snackBarOpen}
            message={errorSnackBarMessage}
            close={() => setSnackBarOpen(false)}
          />
          <Snackbar
            open={snackbaropen}
            autoHideDuration={2000}
            onClose={() => {
              setsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => {
                setsnackbaropen(false);
                setsnackBarMessage("");
              }}
              severity="success"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={errorsnackbaropen}
            autoHideDuration={6000}
            onClose={() => {
              seterrorsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => seterrorsnackbaropen(false)}
              severity="error"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>

          <Formik
            enableReinitialize
            initialValues={{
              shifts:
                !shiftDefinitions.loading &&
                shiftDefinitions.data.shiftDefinitions != null &&
                getShiftDefDetails(shiftDefinitions.data.shiftDefinitions),
            }}
            onSubmit={(values) => {
              handleShiftsSave(values.shifts);
            }}
          >
            {({
              values,
              handleChange,
              dirty,
              setFieldValue,
              setFieldError,
            }) => (
              <Form>
                <PromptIfDirty />
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="flex-start"
                >
                  <Grid item className={classes.headerSpacing}>
                    {" "}
                    <Typography variant="h3">Department Shifts</Typography>
                  </Grid>
                  <Grid item>
                    {(!office.floatStatus || office.floatStatus === null) && (
                      <FieldArray name="shifts">
                        {({ push, remove, setFieldError }) => (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell colSpan={12}>
                                  Shift Settings for department
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell>Shift Name</StyledTableCell>
                                <StyledTableCell>Shift Type</StyledTableCell>
                                <StyledTableCell>Start Time</StyledTableCell>
                                <StyledTableCell>End Time</StyledTableCell>
                                <StyledTableCell>Monday</StyledTableCell>
                                <StyledTableCell>Tuesday</StyledTableCell>
                                <StyledTableCell>Wednesday</StyledTableCell>
                                <StyledTableCell>Thursday</StyledTableCell>
                                <StyledTableCell>Friday</StyledTableCell>
                                <StyledTableCell>Saturday</StyledTableCell>
                                <StyledTableCell>Sunday</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.shifts.length > 0 &&
                                values.shifts.map((e, index) => (
                                  <TableRow key={index}>
                                    <StyledTableCell>
                                      <TextField
                                        id={`shifts.${index}.description`}
                                        name={`shifts.${index}.description`}
                                        variant="outlined"
                                        value={e.description}
                                        onChange={handleChange}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Select
                                        id={`shifts.${index}.type`}
                                        name={`shifts.${index}.type`}
                                        variant="outlined"
                                        value={e.type.id}
                                        onChange={handleChange}
                                      >
                                        {shiftDefinitionTypes.data.shiftTypes.map(
                                          (x, i) => (
                                            <MenuItem key={i} value={x.id}>
                                              {x.type}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`shifts.${index}.start`}
                                        name={`shifts.${index}.start`}
                                        variant="outlined"
                                        value={e.start}
                                        onChange={handleChange}
                                        type="time"
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <TextField
                                        id={`shifts.${index}.end`}
                                        name={`shifts.${index}.end`}
                                        variant="outlined"
                                        value={e.end}
                                        onChange={handleChange}
                                        type="time"
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.monday`}
                                        name={`shifts.${index}.monday`}
                                        value={e.monday}
                                        checked={e.monday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.monday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.tuesday`}
                                        name={`shifts.${index}.tuesday`}
                                        value={e.tuesday}
                                        checked={e.tuesday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.tuesday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.wednesday`}
                                        name={`shifts.${index}.wednesday`}
                                        value={e.wednesday}
                                        checked={e.wednesday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.wednesday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.thursday`}
                                        name={`shifts.${index}.thursday`}
                                        value={e.thursday}
                                        checked={e.thursday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.thursday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.friday`}
                                        name={`shifts.${index}.friday`}
                                        value={e.friday}
                                        checked={e.friday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.friday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.saturday`}
                                        name={`shifts.${index}.saturday`}
                                        value={e.saturday}
                                        checked={e.saturday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.saturday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <input
                                        type="checkbox"
                                        id={`shifts.${index}.sunday`}
                                        name={`shifts.${index}.sunday`}
                                        value={e.sunday}
                                        checked={e.sunday ? true : false}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `shifts.${index}.sunday`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <StyledTableCell>
                                  <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                      push({
                                        description: "",
                                        type: { id: "1" },
                                        start: "",
                                        end: "",
                                        monday: true,
                                        tuesday: true,
                                        wednesday: true,
                                        thursday: true,
                                        friday: true,
                                        saturday: true,
                                        sunday: true,
                                      })
                                    }
                                  >
                                    Add Another Shift
                                  </Button>
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}
                      </FieldArray>
                    )}
                  </Grid>
                </Grid>
                <Box m={4} style={{ textAlign: "right" }}>
                  <Button
                    type="submit"
                    disabled={!dirty}
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
                {/* <Dialog open={customShiftTypes} fullWidth maxWidth="sm">
                  <DialogContent
                    style={{
                      padding: 20,
                      overflowX: "hidden",
                      textAlign: "center",
                    }}
                  >
                    <CustomShiftType
                      allShiftTypes={shiftTypes}
                      closeDialog={() => setCustomShiftTypes(false)}
                      selectedShiftTypes={selectedShiftTypes}
                      setSelectedShiftTypes={setSelectedShiftTypes}
                      setFieldError={setFieldError}
                      userShiftTypes={[]}
                      //rotationValue={values.rotationLength}
                    />
                  </DialogContent>
                </Dialog> */}
              </Form>
            )}
          </Formik>
        </Container>
      </main>
    );
  }
}

export default OfficeShifts;
