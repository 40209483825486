import React, { useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import { format, eachDayOfInterval } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_EMPLOYEE_AVAILABILITY,
  AVAILABILITY_TYPES,
} from "../../api/gqlQueries";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wideInput: {
    width: 575,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: 50,
  },
  timeField: {
    width: 120,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    width: 75,
  },
  error: {
    color: theme.palette.primary.main,
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    marginTop: -4,
    paddingLeft: 14,
    paddingRight: 14,
  },
}));

/* retrieve time off on a given date and lets user edit the form
   start date and end date can span over a few days. 
   user can select a part of the day or user can set the event to an all day event.
   user can delete their time off requests
*/

const EditTimeOffRequestForm = (props) => {
  const classes = useStyles();

  const {
    closeDialog,
    userSoft,
    setToast,
    setShowToast,
    userTimeOff,
    timeOffRequestIdToEdit,
    setTimeOffRequestIdToEdit,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const { data } = useQuery(AVAILABILITY_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const eventId = timeOffRequestIdToEdit;
  const request = userTimeOff.find((request) => request.eventId === eventId);

  const [startDate, setStartDate] = useState(
    request ? request.start : new Date()
  );
  const [endDate, setEndDate] = useState(request ? request.end : new Date());
  const [startTime, setStartTime] = useState(
    request && !request.isAllDay ? format(request.start, "HH:mm") : ""
  );
  const [endTime, setEndTime] = useState(
    request && !request.isAllDay ? format(request.end, "HH:mm") : ""
  );
  const [isAllDay, setIsAllDay] = useState(request && request.isAllDay);
  const [workHours, setWorkHours] = useState(request ? request.workHours : 0);
  const [type, setType] = useState(request ? request.typeId : "1");
  const [comment, setComment] = useState(
    request && request.comment ? request.comment : ""
  );
  const [error, setError] = useState("");
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");

  const timeOffEventsNotCurrentRequest = userTimeOff.filter(
    (event) => event.eventId !== eventId
  );

  let invalidDates = [];
  userSoft.forEach((request) => {
    invalidDates.push(request.start);
  });

  timeOffEventsNotCurrentRequest.forEach((request) => {
    const arrayOfTimeOff = eachDayOfInterval({
      start: request.start,
      end: request.end,
    });
    invalidDates = [...invalidDates, ...arrayOfTimeOff];
  });

  invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

  const [updateRequest] = useMutation(UPDATE_EMPLOYEE_AVAILABILITY, {
    onCompleted() {
      setToast("Edit Time Off Request");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError(
        "Unable to edit time off request. Please check dates and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteRequest] = useMutation(UPDATE_EMPLOYEE_AVAILABILITY, {
    onCompleted() {
      refetch();
      setToast("Delete Time Off Request");
      setShowToast(true);
      setTimeOffRequestIdToEdit(null);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError("Unable to delete time off request. Please try again.");

      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    let newEvent = {
      type: type,
      workHours: workHours,
      firstday: format(startDate, "yyyy-MM-dd"),
      lastday: format(endDate, "yyyy-MM-dd"),
      comment: comment,
    };

    if (startTime && endTime) {
      newEvent.startTime = `${startTime}:00`;
      newEvent.endTime = `${endTime}:00`;
    }

    updateRequest({
      variables: {
        id: eventId,
        input: {
          ...newEvent,
        },
      },
    });
  };

  const handleDeleteRequest = () => {
    deleteRequest({
      variables: {
        id: eventId,
        input: { userCancelled: true },
      },
    });
  };

  const checkInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return invalidDates.includes(formatted);
  };

  const eligibleToSave = Boolean(
    !checkInvalid(startDate) && !checkInvalid(endDate) && !endDate < startDate
  );

  const handleUpdateStart = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid) {
        setStartDate(date);
        setStartError("");
        if (date > endDate) {
          setEndDate(date);
        }
      } else {
        setStartError(
          "You must choose a date that does not already have a request"
        );
      }
    }
  };

  const handleUpdateEnd = (date) => {
    if (date && !isNaN(date.getTime())) {
      const invalid = checkInvalid(date);
      if (!invalid) {
        setEndDate(date);
        setEndError("");
      } else {
        setEndError(
          "You must choose a date that does not already have a request"
        );
      }
    }
  };

  const handleWorkHoursChange = (event) => {
    setWorkHours(event.target.value);
  };

  let sortedTypes;
  if (data) {
    sortedTypes = [];
    const ordered = ["PTO", "UPTO", "TRAINING", "Medical", "Admin", "OTHER"];
    ordered.forEach((name) => {
      let match = data.availabilityTypes.find((type) => type.name === name);
      if (match) {
        match = { name: match.name, id: match.id };
        if (match.name === "TRAINING" || match.name === "OTHER") {
          match.name =
            match.name.slice(0, 1) + match.name.slice(1).toLowerCase();
          sortedTypes.push(match);
        } else {
          sortedTypes.push(match);
        }
      }
    });
  }

  const handleIsAllDayChange = (e) => {
    setIsAllDay(e.target.checked);
    !e.target.checked && setEndDate(startDate);
    e.target.checked && setStartTime("");
    e.target.checked && setEndTime("");
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">Edit Time Off Request</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isAllDay}
                onChange={handleIsAllDayChange}
                name="isAllDay"
                color={isAllDay ? "primary" : "secondary"}
              />
            }
            label="All Day"
          />
        </Grid>
        <Grid item container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <InputLabel htmlFor="start-date">
              <Typography variant="h5">Start Date:</Typography>
            </InputLabel>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="start-date"
              shouldDisableDate={checkInvalid}
              value={startDate}
              onChange={handleUpdateStart}
              className={classes.input}
            />
            {startError && (
              <Typography variant="body2" className={classes.dateError}>
                {startError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <InputLabel htmlFor="end-date">
              <Typography variant="h5">End Date:</Typography>
            </InputLabel>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="end-date"
              shouldDisableDate={checkInvalid}
              minDate={startDate}
              minDateMessage="Date should not be before start date"
              value={endDate}
              onChange={handleUpdateEnd}
              className={classes.input}
              disabled={!isAllDay}
            />
            {endError && (
              <Typography variant="body2" className={classes.dateError}>
                {endError}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="start-time">
              <Typography variant="h5">Start Time:</Typography>
            </InputLabel>
            <TextField
              id={`start-time`}
              name={`startTime`}
              variant="outlined"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              type="time"
              disabled={isAllDay}
              className={classes.timeField}
            />
          </Grid>
          <Grid item>
            <InputLabel htmlFor="end-time">
              <Typography variant="h5">End Time:</Typography>
            </InputLabel>
            <TextField
              id={`end-time`}
              name={`endTime`}
              variant="outlined"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              type="time"
              disabled={isAllDay}
              className={classes.timeField}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="type">
              <Typography variant="h5">Type of Request:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              id="type"
              name="type"
              variant="outlined"
              type="number"
              value={type}
              className={classes.input}
              onChange={(e) => setType(e.target.value)}
            >
              {sortedTypes
                ? sortedTypes.map((type) => (
                    <MenuItem key={type.name + type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <InputLabel htmlFor="work-hours">
              <Typography variant="h5">Hours Used:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <TextField
              id="work-hours"
              name="work-hours"
              variant="outlined"
              type="number"
              value={workHours}
              className={classes.textField}
              onChange={handleWorkHoursChange}
            />
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="comment">
            <Typography variant="h5">Comment:</Typography>
          </InputLabel>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            value={comment}
            className={classes.wideInput}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          spacing={2}
          style={{ zIndex: 4, position: "absolute", bottom: 25, right: 30 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={handleDeleteRequest}
            >
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
              disabled={!eligibleToSave || workHours === 0}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default EditTimeOffRequestForm;
