import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { userVar } from "../../cache";
import {
  NOTIFY_DEVELOPERS,
  GET_ALL_CHILD_OFFICES,
  PREFERENCE_ANALYTICS,
  EMP_DETAILS_BY_OFFICE,
} from "../../api/gqlQueries";
import { useQuery, useMutation } from "@apollo/client";
import AnalyticsWidget from "./AnalyticsGraphWidget";
import { format, isSameDay, sub, isValid } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 225,
  },
}));

/*Graph to indicate how prefence categories and preferences affects schedule quality */
const PreferenceDashboard = () => {
  const classes = useStyles();
  const user = userVar();
  const environment = process.env.NODE_ENV;
  const [preferencesToView, setPreferencesToView] = useState("all");
  const [schedulePeriodToView, setSchedulePeriodToView] = useState("");
  const [timeFrameMonths, setTimeFrameMonths] = useState(6);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [selectedChildOffice, SetSelectedChildOffice] = useState("0");
  const [formattedData, SetFormattedData] = useState([]);
  const [filteredData, SetFilteredData] = useState([]);
  const [uniqueCategories, SetUniqueCategories] = useState([]);
  const [uniquePrefTypes, SetUniquePrefTypes] = useState([]);
  const [uniqueSchedulePeriodIds, SetUniqueSchedulePeriodIds] = useState([]);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(PREFERENCE_ANALYTICS, {
    variables: {
      office:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ANALYTICS_DATA Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      let categories = [];
      let prefTypes = [];
      let schedulePeriodIds = [];
      let formattd = data.preferenceAnalytics.map((e) => {
        !categories.includes(e.category) && categories.push(e.category);
        !isValid(new Date(e.preference))
          ? !prefTypes.includes(e.preference) && prefTypes.push(e.preference)
          : !prefTypes.includes("SOFTREQUEST") && prefTypes.push("SOFTREQUEST");
        let schedulePeriodId =
          empDetails.data.offices[0].scheduleperiodSet.find(
            (x) => x.start === e.scheduleStart && x.end === e.scheduleEnd
          )?.id;
        !schedulePeriodIds.includes(schedulePeriodId) &&
          schedulePeriodIds.push(schedulePeriodId);
        return {
          category: e.category,
          contribution: e.contribution,
          employee: e.employee,
          preference: isValid(new Date(e.preference))
            ? "SOFTREQUEST"
            : e.preference,
          scheduleEnd: e.scheduleEnd,
          scheduleStart: e.scheduleStart,
          schedulePeriodId: empDetails.data.offices[0].scheduleperiodSet.find(
            (x) => x.start === e.scheduleStart && x.end === e.scheduleEnd
          )?.id,
          value: e.value,
        };
      });
      SetUniqueCategories(categories);
      SetUniquePrefTypes(prefTypes);
      SetUniqueSchedulePeriodIds(schedulePeriodIds);
      SetFormattedData(formattd);
    }
  }, [data]);

  const empDetails = useQuery(EMP_DETAILS_BY_OFFICE, {
    variables: {
      office:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMP_DETAILS_BY_OFFICE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allChildOffices = useQuery(GET_ALL_CHILD_OFFICES, {
    variables: {
      parent: user.office.id,
    },
    onCompleted(d) {
      console.log(d);
    },
  });

  useEffect(() => {
    let filtrd = formattedData
      .filter((e) =>
        schedulePeriodToView === ""
          ? e.schedulePeriodId === uniqueSchedulePeriodIds[0]
          : parseInt(e.schedulePeriodId) === parseInt(schedulePeriodToView)
      )
      .filter((e) =>
        preferencesToView === "all"
          ? e.employee
          : parseInt(e.employee) === parseInt(preferencesToView)
      );
    SetFilteredData(filtrd);
  }, [schedulePeriodToView, preferencesToView, selectedChildOffice]);

  if (loading || empDetails.loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    return (
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="h3">Preferences</Typography>
          </Box>
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <Typography>View Data For: </Typography>
          </Grid>
          <Grid item>
            <Select variant="outlined" value={[]}></Select>
          </Grid>
        </Grid>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </Grid>
    );
  } else {
    const mappedToLabel = {
      DAYS: "Day Shift",
      SWINGS: "Swing Shift",
      NIGHTS: "Night Shift",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      ONEDAY: "One Day",
      TWODAYS: "Two Days",
      THREEDAYS: "Three Days",
      FOURDAYS: "Four Days",
      SOFTREQUEST: "Soft Day Off Requests",
    };

    if (formattedData.length > 0) {
      const orderPrefsByCategory = (prefs) => {
        //define the preference category groups
        const shiftCategories = ["DAYS", "SWINGS", "NIGHTS"];
        const dayCategories = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        const daysOffCategories = [
          "ONEDAY",
          "TWODAYS",
          "THREEDAYS",
          "FOURDAYS",
        ];

        const softRequestCategory = ["SOFTREQUEST"];

        //define the three stacked bars we want for each employee
        const orderedByCategory = [
          {
            x: [],
            y: [],
            name: "Shift Type",
            type: "bar",
          },
          {
            x: [],
            y: [],
            name: "Weekday",
            type: "bar",
          },
          {
            x: [],
            y: [],
            name: "Off Stint Length",
            type: "bar",
          },
          {
            x: [],
            y: [],
            name: "Soft Day Off Requests",
            type: "bar",
          },
        ];

        prefs.forEach((pref) => {
          //sort yValues by last name so all values will be in the same order
          //pref.yValues.sort((a, b) => a.lastName.localeCompare(b.lastName));
          //find the object to push the accumulated values on based on
          //preference category
          let dataObject;
          if (shiftCategories.includes(pref.preference)) {
            dataObject = orderedByCategory.find(
              (object) => object.name === "Shift Type"
            );
          } else if (dayCategories.includes(pref.preference)) {
            dataObject = orderedByCategory.find(
              (object) => object.name === "Weekday"
            );
          } else if (daysOffCategories.includes(pref.preference)) {
            dataObject = orderedByCategory.find(
              (object) => object.name === "Off Stint Length"
            );
          } else if (softRequestCategory.includes(pref.preference)) {
            dataObject = orderedByCategory.find(
              (object) => object.name === "Soft Day Off Requests"
            );
          }

          //if x values and y values arrays are not empty, we need to
          //accumulate the values for the category
          if (dataObject) {
            let empName = empDetails.data.offices[0].employeeSet.find(
              (x) => parseInt(x.id) === parseInt(pref.employee)
            );
            const x = empName
              ? empName.firstName[0] + " " + empName.lastName
              : "";
            const y = parseInt(pref.contribution) * 100;
            dataObject.x.push(x);
            dataObject.y.push(y);
          }
        });
        let sumValues = [];
        orderedByCategory.forEach((object) => {
          if (sumValues.length > 0) {
            sumValues = sumValues.map((value, index) => {
              return value + object.y[index];
            });
          } else {
            sumValues = object.y;
          }
        });
        return orderedByCategory;
      };

      const orderPrefs = (prefs) => {
        //define the order we want the categories to be displayed
        const orderCategories = [
          "DAYS",
          "SWINGS",
          "NIGHTS",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
          "ONEDAY",
          "TWODAYS",
          "THREEDAYS",
          "FOURDAYS",
          "SOFTREQUEST",
        ];

        const ordered = [];
        let sumValues = [];
        orderCategories.forEach((category) => {
          //find each preference type object in the preference array
          const match = prefs.find((pref) => pref.type === category);
          let x;
          let y;
          if (match) {
            //sort the employees by last name so all values stay in same order
            match.yValues.sort((a, b) => a.lastName.localeCompare(b.lastName));
            //map the yValues and return the first initial and last name for
            //x axis of the bar graph
            x = match.yValues.map(
              (value) =>
                `${value.firstName[0]?.toUpperCase()}. ${value.lastName}`
            );
            //map the y values of each stacked bar to be the contribution in
            //percentage form
            y = match.yValues.map((value) => value.contribution * 100);

            if (sumValues.length > 0) {
              sumValues = sumValues.map((value, index) => {
                return value + y[index];
              });
            } else {
              sumValues = y;
            }
          } else {
            x = [];
            y = [];
          }

          const dataForGraph = {
            x: x,
            y: y,
            name: match ? mappedToLabel[match.type] : "",
            type: "bar",
          };
          ordered.push(dataForGraph);

          // if (dataForGraph.name === "Soft Request") {
          //   let softReq = ordered
          //     .filter(Boolean)
          //     .find((o) => o.name && o.name === "Soft Request");
          //   if (!softReq) {
          //     ordered.push(dataForGraph);
          //   } else {
          //     softReq.y = [parseInt(softReq.y) + parseInt(dataForGraph.y)];
          //   }
          // } else {
          //   ordered.push(dataForGraph);
          // }
        });
        // if (ordered.length > 0) {
        //   const softRequestYValues = sumValues.map((value) => {
        //     if (value < 99.9) {
        //       return 100 - value;
        //     } else {
        //       return 0;
        //     }
        //   });
        //   const softRequestBar = {
        //     x: ordered[0].x,
        //     y: softRequestYValues,
        //     name: "Soft Request",
        //     type: "bar",
        //   };
        //   ordered.push(softRequestBar);
        // }
        return ordered;
      };

      const dataForAllSchedules = [];
      const preferenceData = [];
      formattedData.forEach((pref) => {
        // const employeeData = [];
        //look for preference type existing in preference data array
        //to make sure only one entry exists for each preference type
        const prefType = preferenceData.find(
          (data) => data.type === pref.preference
        );

        //find the employee the preference belongs to in order to
        //display their name in the bar graph
        const findEmployee = empDetails.data.offices[0].employeeSet.find(
          (employee) => parseInt(employee.id) === parseInt(pref.employee)
        );

        if (findEmployee) {
          //if prefType already exists in data set, push a new yValue object
          if (prefType) {
            prefType.yValues.push({
              contribution: pref.contribution,
              value: pref.value,
              employeeId: pref.employee,
              firstName: findEmployee.firstName,
              lastName: findEmployee.lastName,
            });
            //if prefType does not exist, push a new object for the preference type
          } else {
            preferenceData.push({
              type: pref.preference,
              yValues: [
                {
                  contribution: pref.contribution,
                  value: pref.value,
                  employeeId: pref.employee,
                  firstName: findEmployee.firstName,
                  lastName: findEmployee.lastName,
                },
              ],
            });
          }
        }
        //organize the newly formatted data by schedule period
        let schedulePeriodId =
          empDetails.data.offices[0].scheduleperiodSet.find(
            (e) => e.start === pref.scheduleStart && e.end === pref.scheduleEnd
          )?.id;
        let schedulePeriodPresent = dataForAllSchedules.find(
          (x) => parseInt(x.schedulePeriod) === parseInt(schedulePeriodId)
        );

        if (!schedulePeriodPresent) {
          let dataBySchedule = {
            schedulePeriod: schedulePeriodId,
            data: preferenceData,
          };
          dataForAllSchedules.push(dataBySchedule);
        }
      });

      //find the schedule periods that have analytics data available
      //to put in dropdown selection
      const schedulePeriodsWithData = dataForAllSchedules.map(
        (scheduleData) => {
          const match = empDetails.data.offices[0].scheduleperiodSet.find(
            (sched) =>
              new Date(sched.id) === parseInt(scheduleData.schedulePeriod)
          );
          if (match) {
            return {
              id: scheduleData.schedulePeriod,
              start: new Date(`${match.start}T08:00:00`),
              end: new Date(`${match.end}T23:00:00`),
            };
          } else {
            return null;
          }
        }
      );

      const handleChangeView = (value) => {
        setPreferencesToView(value);
      };

      const handleChangeSchedulePeriod = (value) => {
        setSchedulePeriodToView(value);
      };

      const getDataIndividualPrefs = () => {
        //find data set for selected schedule period
        let dataToShow = dataForAllSchedules.filter(
          (schedData) =>
            parseInt(schedData.schedulePeriod) ===
            parseInt(schedulePeriodToView)
        );

        if (dataToShow.length > 0) {
          dataToShow = dataToShow.map((e) => e.data);
        } else {
          setSchedulePeriodToView(dataForAllSchedules[0].schedulePeriod);
          dataToShow = dataForAllSchedules[0].data;
        }

        //determine if user is viewing all employees or a specific employee
        if (preferencesToView === "all") {
          return orderPrefs(dataToShow.flat());
        } else {
          //define the format that needs to be passed to analytics graph widget
          const newData = {
            x: [],
            y: [],
            name: "",
            type: "bar",
          };

          //define the order of the preference categories we want to show
          const orderCategories = [
            "DAYS",
            "SWINGS",
            "NIGHTS",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
            "ONEDAY",
            "TWODAYS",
            "THREEDAYS",
            "FOURDAYS",
            "SOFTREQUEST",
          ];

          //go through each category in order and add employee's preference
          //types to x value array and contribution values to y value array
          orderCategories.forEach((category) => {
            const match = dataToShow.find((pref) => pref.type === category);
            if (match) {
              const title = mappedToLabel[match.type];
              const employeeMatch = match.yValues.find(
                (object) =>
                  parseInt(object.employeeId) === parseInt(preferencesToView)
              );

              if (employeeMatch) {
                newData.x.push(title);
                newData.y.push(employeeMatch.contribution * 100);
                newData.name = `${employeeMatch.firstName} ${employeeMatch.lastName}`;
              }
            }
          });

          const sumOfValues = newData.y.reduce((sum, value) => value + sum, 0);
          if (sumOfValues === 0) {
            newData.y.push(0);
          } else if (sumOfValues < 99.9) {
            newData.y.push(100 - sumOfValues);
          } else {
            newData.y.push(0);
          }
          newData.x.push("Soft Day Off Requests");

          //return data in an array for graph widget
          return [newData];
        }
      };

      const getDataPrefCategories = () => {
        //find data set for selected schedule period
        let dataToShow = dataForAllSchedules.filter(
          (schedData) =>
            parseInt(schedData.schedulePeriod) ===
            parseInt(schedulePeriodToView)
        );
        if (dataToShow.length > 0) {
          dataToShow = dataToShow.map((e) => e.data).flat();
        } else {
          setSchedulePeriodToView(dataForAllSchedules[0].schedulePeriod);
          dataToShow = dataForAllSchedules[0].data;
        }
        //determine if user is viewing all employees or a specific employee
        if (preferencesToView === "all") {
          return orderPrefsByCategory(dataToShow);
        } else {
          //define the preference category groups
          const shiftCategories = ["DAYS", "SWINGS", "NIGHTS"];
          const dayCategories = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];
          const daysOffCategories = [
            "ONEDAY",
            "TWODAYS",
            "THREEDAYS",
            "FOURDAYS",
          ];
          const softRequestCategory = ["SOFTREQUEST"];

          //define the three bars we want to be shown
          const newData = {
            x: [
              "Shift Type",
              "Days of the Week",
              "Days Off",
              "Soft Day Off Requests",
            ],
            y: [0, 0, 0],
            name: "",
            type: "bar",
          };

          //accumulate date by category into each bar and put it in the
          //data object to send to graph widget
          dataToShow.forEach((pref) => {
            let index;
            if (shiftCategories.includes(pref.type)) {
              index = 0;
            } else if (dayCategories.includes(pref.type)) {
              index = 1;
            } else if (daysOffCategories.includes(pref.type)) {
              index = 2;
            } else if (softRequestCategory.includes(pref.type)) {
              index = 3;
            }

            const matching = pref.yValues.find(
              (object) =>
                parseInt(object.employeeId) === parseInt(preferencesToView)
            );

            if (matching) {
              newData.y[index] = newData.y[index] + matching.contribution * 100;
              newData.name = `${matching.firstName} ${matching.lastName}`;
            }
          });

          const sumOfValues = newData.y.reduce((sum, value) => value + sum, 0);
          if (sumOfValues === 0) {
            newData.y.push(0);
          } else if (sumOfValues < 99.9) {
            newData.y.push(100 - sumOfValues);
          } else {
            newData.y.push(0);
          }

          //return data in an array for graph widget
          return [newData];
        }
      };

      // const organizePrefDataByEmployee = () => {
      //   let parsedInTimeFrame = [...formattedData];
      //   if (timeFrameMonths) {
      //     const startDateToView = sub(new Date(), { months: timeFrameMonths });
      //     const schedulesInTimeFrame = schedulePeriodsWithData
      //       .filter(Boolean)
      //       .filter(
      //         (sched) =>
      //           isSameDay(sched.start, startDateToView) ||
      //           sched.start > startDateToView ||
      //           sched.end > startDateToView
      //       );
      //     const scheduleIds = schedulesInTimeFrame.map((sched) =>
      //       parseInt(sched.id)
      //     );
      //     parsedInTimeFrame = formattedData.filter((x) =>
      //       scheduleIds.includes(parseInt(x.schedulePeriod))
      //     );
      //   }

      //   const prefsByEmployee = [];
      //   parsedInTimeFrame.forEach((dataSet) => {
      //     dataSet.employeePreferences.forEach((preference) => {
      //       const employeeExists = prefsByEmployee.find(
      //         (pref) =>
      //           parseInt(pref.employeeId) === parseInt(preference.employee)
      //       );
      //       if (employeeExists) {
      //         const scheduleExists = employeeExists.prefSets.find(
      //           (prefSet) =>
      //             parseInt(prefSet.schedulePeriod) ===
      //             parseInt(dataSet.schedulePeriod)
      //         );

      //         if (scheduleExists) {
      //           scheduleExists.preferences.push({
      //             type: preference.preference,
      //             value: preference.value,
      //           });
      //         } else {
      //           employeeExists.prefSets.push({
      //             schedulePeriod: dataSet.schedulePeriod,
      //             preferences: [
      //               {
      //                 type: preference.preference,
      //                 value: preference.value,
      //               },
      //             ],
      //           });
      //         }
      //       } else {
      //         prefsByEmployee.push({
      //           employeeId: preference.employee,
      //           prefSets: [
      //             {
      //               schedulePeriod: dataSet.schedulePeriod,
      //               preferences: [
      //                 {
      //                   type: preference.preference,
      //                   value: preference.value,
      //                 },
      //               ],
      //             },
      //           ],
      //         });
      //       }
      //     });
      //   });
      //   return prefsByEmployee;
      // };

      // const getDataForPrefChanges = (preferencesByEmployee) => {
      //   const graphData = {
      //     x: [],
      //     y: [],
      //     name: "Shift Type",
      //     type: "bar",
      //   };

      //   preferencesByEmployee.forEach((preferenceObject) => {
      //     let changeCount = 0;
      //     preferenceObject.prefSets.sort(
      //       (a, b) => parseInt(a.schedulePeriod) - parseInt(b.schedulePeriod)
      //     );
      //     preferenceObject.prefSets.forEach((prefSet, index) => {
      //       if (index !== preferenceObject.prefSets.length - 1) {
      //         const currentPreferences = prefSet.preferences;
      //         const nextPreferences =
      //           preferenceObject.prefSets[index + 1].preferences;

      //         let same = true;
      //         let i = 0;
      //         while (same && i < currentPreferences.length) {
      //           const match = nextPreferences.find(
      //             (pref) => pref.type === currentPreferences[i].type
      //           );
      //           if (match) {
      //             same = match.value === currentPreferences[i].value;
      //           }
      //           i++;
      //         }
      //         if (!same) {
      //           changeCount += 1;
      //         }
      //       }
      //     });
      //     const findEmployee = data.offices[0].employeeSet.find(
      //       (employee) =>
      //         parseInt(employee.id) === parseInt(preferenceObject.employeeId)
      //     );
      //     const employeeLabel = findEmployee
      //       ? `${findEmployee.firstName[0]?.toUpperCase()}. ${
      //           findEmployee.lastName
      //         }`
      //       : "";

      //     graphData.x.push(employeeLabel);
      //     graphData.y.push(changeCount);
      //   });
      //   return [graphData];
      // };

      const mostInfluential = (graphData) => {
        if (preferencesToView === "all") {
          const preferenceWithTotal = graphData.map((datum) => {
            const total =
              datum.y.length > 0 &&
              datum.y.reduce((sum, value) => {
                return sum + value;
              }, 0);
            return {
              name: datum.name,
              total,
            };
          });
          return preferenceWithTotal.sort((a, b) => b.total - a.total)[0].name;
        } else {
          const values = [...graphData[0].y];
          values.sort((a, b) => b - a);
          const indexOfHighest = graphData[0].y.indexOf(values[0]);
          const nameOfHighest = graphData[0].x[indexOfHighest];
          return nameOfHighest;
        }
      };

      schedulePeriodsWithData
        .filter(Boolean)
        .sort((a, b) => parseInt(b.id) - parseInt(a.id));
      //employees of parent and child offices
      let allEmps = [
        ...allChildOffices.data.getChildren.map((e) => e.employeeSet).flat(),
        ...empDetails.data.offices[0].employeeSet,
      ];

      const getEmployeeByName = (emp) => {
        let employee = allEmps.find((e) => parseInt(e.id) === parseInt(emp));
        return employee ? employee.firstName + " " + employee.lastName : "";
      };

      let PrefContributionByCategory = uniqueCategories.map((e) => {
        if (user.isPrimaryParentOffice && preferencesToView === "all") {
          return {
            x: formattedData.map((dat) => getEmployeeByName(dat.employee)),
            y: formattedData.map((dat) => {
              return dat.category === e
                ? (dat.contribution / uniqueSchedulePeriodIds.length) * 100
                : 0;
            }),
            name: e,
            type: "bar",
          };
        } else {
          return {
            x: filteredData.map((dat) => getEmployeeByName(dat.employee)),
            y: filteredData.map((dat) => {
              return dat.category === e
                ? (dat.contribution / uniqueSchedulePeriodIds.length) * 100
                : 0;
            }),
            name: e,
            type: "bar",
          };
        }
      });

      let PrefContributionByType = uniquePrefTypes.map((e) => {
        if (user.isPrimaryParentOffice && preferencesToView === "all") {
          return {
            x: formattedData.map((dat) => getEmployeeByName(dat.employee)),
            y: formattedData.map((dat) => {
              return dat.preference === e
                ? (dat.contribution / uniqueSchedulePeriodIds.length) * 100
                : 0;
            }),
            name: e,
            type: "bar",
          };
        } else {
          return {
            x: filteredData.map((dat) => getEmployeeByName(dat.employee)),
            y: filteredData.map((dat) => {
              return dat.preference === e
                ? (dat.contribution / uniqueSchedulePeriodIds.length) * 100
                : 0;
            }),
            name: e,
            type: "bar",
          };
        }
      });

      return (
        <div>
          <Grid container direction="row" spacing={4} alignItems="center">
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography variant="h3">Preferences</Typography>
              </Box>
            </Grid>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Typography>View Data For: </Typography>
              </Grid>
              <Grid item>
                <Select
                  variant="outlined"
                  value={preferencesToView}
                  onChange={(e) => handleChangeView(e.target.value)}
                  className={classes.select}
                >
                  <MenuItem key={-1} value="all">
                    All Employees
                  </MenuItem>
                  {allEmps.map((employee, index) => (
                    <MenuItem key={index} value={employee.id}>
                      {employee.firstName} {employee.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Select
                  variant="outlined"
                  value={schedulePeriodToView}
                  onChange={(e) => handleChangeSchedulePeriod(e.target.value)}
                  className={classes.select}
                >
                  {empDetails.data.offices[0].scheduleperiodSet.map(
                    (sched, index) => (
                      <MenuItem key={index} value={sched.id}>
                        {format(new Date(sched.start), "yyyy-MM-dd")} -
                        {format(new Date(sched.end), "yyyy-MM-dd")}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Grid>

              <Grid item>
                {user.isPrimaryParentOffice && (
                  <Box m={2}>
                    <Select
                      variant="outlined"
                      value={selectedChildOffice}
                      onChange={(e) => {
                        SetSelectedChildOffice(e.target.value);
                      }}
                      className={classes.select}
                    >
                      <MenuItem key="0" value="0">
                        Select Office
                      </MenuItem>
                      {allChildOffices.data.getChildren.length > 0 &&
                        allChildOffices.data.getChildren.map((e, index) => (
                          <MenuItem key={index} value={e.id}>
                            {e.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography variant="h5">Overview</Typography>
                <br />
                <Typography>
                  Preference with Highest Effect on Schedule Quality:{" "}
                  <b>{mostInfluential(getDataIndividualPrefs())}</b>
                </Typography>
                <Typography>
                  Category with Highest Effect on Schedule Quality:{" "}
                  <b>{mostInfluential(getDataPrefCategories())}</b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <AnalyticsWidget
                data={PrefContributionByCategory}
                layout={
                  preferencesToView === "all" ? { barmode: "stack" } : null
                }
                gridSize={10}
                chartType="4"
                SetChartType={() => null}
                open={true}
                SetOpen={() => null}
                header="Preference Contribution to Satisfaction by Category"
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <AnalyticsWidget
                //data={getDataIndividualPrefs()}
                data={PrefContributionByType}
                layout={
                  preferencesToView === "all" ? { barmode: "stack" } : null
                }
                gridSize={10}
                chartType="4"
                SetChartType={() => null}
                open={true}
                SetOpen={() => null}
                header="Preference Contribution to Satisfaction"
              />
            </Grid>
            {/* <Grid item container xs={12} spacing={2} alignItems="center">
              <Grid item>
                <Typography>
                  Time Frame for Preference Changes in Months:
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  variant="outlined"
                  name="timeFrameMonths"
                  onChange={(e) => {
                    setTimeFrameMonths(e.currentTarget.value);
                  }}
                  value={timeFrameMonths}
                  style={{ width: 50 }}
                />
              </Grid>
            </Grid> */}
            {/* <Grid item xs={12} style={{ marginBottom: 16 }}>
              <AnalyticsWidget
                data={getDataForPrefChanges(organizePrefDataByEmployee())}
                gridSize={10}
                chartType="4"
                SetChartType={() => null}
                open={true}
                SetOpen={() => null}
                header="Preference Changes by Employee"
              />
            </Grid> */}
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <Grid container direction="row" spacing={4} alignItems="center">
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography variant="h3">Preferences</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography>No analytics data to show</Typography>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
};

export default PreferenceDashboard;
