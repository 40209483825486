import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Grid,
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  TextField,
} from "@material-ui/core";
import roles from "../../Roles/roles";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  EMPLOYEE_ACCEPT_SHIFT_SWITCH,
  //MANAGER_APPROVE_SHIFT_SWITCH,
  // EMPLOYEE_DENY_SHIFT_SWITCH,
  //MANAGER_DENY_SHIFT_SWITCH,
  UPDATE_NOTIFICATION,
  MANAGER_APPROVE_EMPLOYEE_AVAILABILITY,
  MANAGER_DENY_EMPLOYEE_AVAILABILITY,
  GET_RESCHEDULE_OPTION,
  APPROVE_OPTION,
  DENY_OPTION,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import AddEmployeesForm from "../rescheduling/AddEmployeesForm";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  topSpacing: {
    marginTop: 20,
  },
  button: {
    width: 90,
  },
  input: {
    width: 384,
  },
  read: {
    background: "rgba(134, 134, 137, 0.15)",
  },
}));

const NotificationItem = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    notification,
    handleCheckboxToggle,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    checked,
    refetch,
  } = props;

  const user = userVar();

  useEffect(() => {
    if (notification.url && notification.url.includes("manager_approvals")) {
      const optionId = notification.url.split("=")[1];
      console.log(optionId);
      getOption({ variables: { id: parseInt(optionId) } });
    }
  }, []);

  const [showDenialReason, setShowDenialReason] = useState(false);
  const [denialReason, setDenialReason] = useState("");
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [rescheduleOption, setRescheduleOption] = useState();

  const [getOption] = useLazyQuery(GET_RESCHEDULE_OPTION, {
    onCompleted(data) {
      console.log(data);
      setRescheduleOption(data.rescheduleOptions[0]);
    },
    onError(error) {
      console.log(error);
    },
  });

  const [approveOption] = useMutation(APPROVE_OPTION, {
    onCompleted(data) {
      refetch();
      console.log(data);
      setToast("Manager Approve Option");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setErrorToast("Error Approve Option");
      setShowErrorToast(true);
    },
  });

  const [denyOption] = useMutation(DENY_OPTION, {
    onCompleted(data) {
      console.log(data);
      refetch();
      setToast("Manager Deny Option");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setErrorToast("Error Deny Option");
      setShowErrorToast(true);
    },
  });

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    onCompleted(data) {
      refetch();
    },
    onError(error) {
      console.log(error);
      setErrorToast("Error Update Notification");
      setShowErrorToast(true);
    },
  });

  const [employeeAcceptShiftSwitch] = useMutation(
    EMPLOYEE_ACCEPT_SHIFT_SWITCH,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        setToast("Employee Accept Shift Switch");
        setShowToast(true);
      },
      onError(error) {
        console.log(error);
        setErrorToast("Error Accept Shift Switch");
        setShowErrorToast(true);
      },
    }
  );

  // const [employeeDenyShiftSwitch] = useMutation(EMPLOYEE_DENY_SHIFT_SWITCH, {
  //   onCompleted(data) {
  //     console.log(data);
  //     refetch();
  //     setToast("Employee Reject Shift Switch");
  //     setShowToast(true);
  //   },
  //   onError(error) {
  //     console.log(error);
  //     setErrorToast("Error Reject Shift Switch");
  //     setShowErrorToast(true);
  //   },
  // });

  // const [managerApproveShiftSwitch] = useMutation(
  //   MANAGER_APPROVE_SHIFT_SWITCH,
  //   {
  //     onCompleted(data) {
  //       console.log(data);
  //       refetch();
  //       setToast("Manager Approve Shift Switch");
  //       setShowToast(true);
  //     },
  //     onError(error) {
  //       console.log(error);
  //       setErrorToast("Error Approve Shift Switch");
  //       setShowErrorToast(true);
  //     },
  //   }
  // );

  // const [managerDenyShiftSwitch] = useMutation(MANAGER_DENY_SHIFT_SWITCH, {
  //   onCompleted(data) {
  //     console.log(data);
  //     refetch();
  //     setShowDenialReason(false);
  //     setDenialReason("");
  //     setToast("Manager Deny Shift Switch");
  //     setShowToast(true);
  //   },
  //   onError(error) {
  //     console.log(error);
  //     setErrorToast("Error Deny Shift Switch");
  //     setShowErrorToast(true);
  //   },
  // });

  const [approveTORequest] = useMutation(
    MANAGER_APPROVE_EMPLOYEE_AVAILABILITY,
    {
      onCompleted(data) {
        console.log(data);
        refetch();
        setToast("Manager Approve Time Off Request");
        setShowToast(true);
      },
      onError(error) {
        console.log(error);
        setErrorToast("Error Approve Time Off");
        setShowErrorToast(true);
      },
    }
  );

  const [denyTORequest] = useMutation(MANAGER_DENY_EMPLOYEE_AVAILABILITY, {
    update(cache, { data: { denyTimeOffRequestWithNotifications } }) {
      cache.evict({
        id: `EmployeeAvailabilityNode:${denyTimeOffRequestWithNotifications.timeOffRequest.id}`,
      });
    },
    onCompleted(data) {
      console.log(data);
      refetch();
      setShowDenialReason(false);
      setDenialReason("");
      setToast("Manager Deny Time Off Request");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setErrorToast("Error Deny Time Off");
      setShowErrorToast(true);
    },
  });

  const handleApproveOption = (e) => {
    e.stopPropagation();
    approveOption({
      variables: {
        option: parseInt(rescheduleOption.id),
        approver: parseInt(user.id),
      },
    });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleDenyOption = (e) => {
    e.stopPropagation();
    denyOption({
      variables: {
        option: parseInt(rescheduleOption.id),
        denier: parseInt(user.id),
      },
    });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleManagerApproveSSRequest = (event, notification) => {
    event.stopPropagation();
    //mutation to add manager approver to shift switch
    //backend will update shifts to reflect switch
    // managerApproveShiftSwitch({
    //   variables: {
    //     id: parseInt(notification.shiftSwitch.id),
    //     approver: user.id,
    //   },
    // });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
    history.push("/Requests");
  };

  const handleEmployeeAcceptSSRequest = (event, notification) => {
    event.stopPropagation();
    let candidateNumber;

    //loop through candidates to find one that matches logged in user
    for (let i = 0; i < 4; i++) {
      if (
        notification.shiftSwitch[`candidate${i + 1}`].employee.id === user.id
      ) {
        candidateNumber = i + 1;
        break;
      }
    }

    //mutation to move user to accepted employee field on shift switch
    //backend will create notification for manager approval
    employeeAcceptShiftSwitch({
      variables: {
        id: parseInt(notification.shiftSwitch.id),
        candidateNumber: candidateNumber,
      },
    });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleManagerDenyRequest = (notification) => {
    if (notification.type === "SSM") {
      // managerDenyShiftSwitch({
      //   variables: {
      //     id: notification.shiftSwitch.id,
      //     deniedText: denialReason,
      //     denier: parseInt(user.id),
      //   },
      // });
    } else if (notification.type === "TOR") {
      denyTORequest({
        variables: {
          id: notification.availability.id,
          denyingManager: parseInt(user.id),
          deniedText: denialReason,
        },
      });
    }
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: { read: true },
      },
    });
  };

  const handleEmployeeDenyRequest = (event, notification) => {
    event.stopPropagation();

    let candidateNumber;
    for (let i = 0; i < 4; i++) {
      if (
        notification.shiftSwitch[`candidate${i + 1}`].employee.id === user.id
      ) {
        candidateNumber = i + 1;
        break;
      }
    }
    // employeeDenyShiftSwitch({
    //   variables: {
    //     id: notification.shiftSwitch.id,
    //     candidateNumber: candidateNumber,
    //     // input: {
    //     //     [`candidate${candidateNumber}Denied`]: true
    //     // }
    //   },
    // });
    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: {
          read: true,
          depreciatedText: "You denied this shift switch request.",
        },
      },
    });
  };

  const showManagerDenialForm = (event) => {
    event.stopPropagation();
    setShowDenialReason(true);
  };

  const handleManagerApproveTORequest = (event, notification) => {
    event.stopPropagation();
    // approveTORequest({
    //   variables: {
    //     id: parseInt(notification.availability.id),
    //     approvingManager: parseInt(user.id),
    //   },
    // });

    updateNotification({
      variables: {
        id: parseInt(notification.id),
        input: {
          read: true,
        },
      },
    });
    history.push("/Requests");
  };

  const deprecatedTitle = (title) => {
    return (
      <Typography>
        <i>No Longer Active - {title}</i>
      </Typography>
    );
  };

  const getDateFromDescription = (description) => {
    const words = description.split(" ");
    let dates = [];
    words.forEach((word) => {
      const possibleDate = word.includes("-");
      const date = possibleDate ? new Date(word) : null;
      const timestamp = Date.parse(date);
      if (isNaN(timestamp) === false) {
        dates.push(date);
      }
    });
    dates.sort((a, b) => a - b);
    return dates[0].toISOString();
  };

  const closeAddOptions = () => {
    setShowAddOptions(false);
  };

  const getDescription = (option) => {
    const individual = option.rescheduleindividualSet[0];
    const employee = individual.employee;
    const employeeName = employee.firstName + " " + employee.lastName;
    const shift = individual.rescheduleactionSet[0].shift;
    const formattedStart = format(new Date(shift.start), "dd MMM yyyy HH:mm");
    const formattedEnd = format(new Date(shift.end), "HH:mm");
    return `${employeeName} would be added to ${formattedStart}-${formattedEnd}`;
  };

  const parseHTMLDescription = (description) => {
    let desc =
      notification.html === true ? ReactHtmlParser(description) : description;
    return desc;
  };

  return (
    <>
      <Grid
        container
        component={ListItem}
        divider
        className={notification.read ? classes.read : null}
      >
        <Grid
          container
          item
          justifyContent="space-between"
          wrap="nowrap"
          alignContent="center"
          xs={12}
          onClick={handleCheckboxToggle(notification.id)}
        >
          <Grid item container wrap="nowrap">
            <Grid item>
              <ListItemIcon>
                <Checkbox checked={checked.indexOf(notification.id) !== -1} />
              </ListItemIcon>
            </Grid>
            <Grid item data-testid="notificationsDescription">
              {notification.depreciatedText ? (
                <ListItemText
                  primary={deprecatedTitle(notification.title)}
                  secondary={
                    <div>
                      notification.depreciatedText Details of Notification:{" "}
                      {parseHTMLDescription(notification.description)}
                    </div>
                  }
                />
              ) : rescheduleOption ? (
                <ListItemText
                  primary={notification.title}
                  secondary={getDescription(rescheduleOption)}
                />
              ) : (
                <ListItemText
                  primary={notification.title}
                  secondary={parseHTMLDescription(notification.description)}
                />
              )}
            </Grid>
          </Grid>
          {notification.url &&
            notification.url.includes("manager_approvals") &&
            user.role === roles.MANAGER &&
            rescheduleOption &&
            rescheduleOption.status === "ACCEPTED" && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDenyOption}
                    className={classes.button}
                  >
                    Deny
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApproveOption}
                    className={classes.button}
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.url &&
            notification.url.includes("FloatNeeds") &&
            user.role === roles.MANAGER && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to={notification.url}
                    disabled={showDenialReason}
                  >
                    Handle Request
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "SSM" &&
            notification.shiftSwitch.acceptor !== null &&
            user.role === roles.MANAGER &&
            notification.depreciatedText === null && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                {/* <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to="/Landing/shift-switch"
                    disabled={showDenialReason}
                  >
                    Shift Switch Requests
                  </Button>
                </Grid> */}
                {/* <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(event) => showManagerDenialForm(event)}
                    className={classes.button}
                    disabled={showDenialReason}
                  >
                    Deny
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleManagerApproveSSRequest(event, notification)
                    }
                    className={classes.button}
                    disabled={showDenialReason}
                  >
                    View in Requests
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "SSR" &&
            notification.shiftSwitch.acceptor === null &&
            notification.depreciatedText === null && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                {/* <Grid item>
                                <Button 
                                    color='primary' 
                                    component={Link} 
                                    to='/Landing'
                                >
                                    Details
                                </Button>
                            </Grid> */}
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(event) =>
                      handleEmployeeDenyRequest(event, notification)
                    }
                    className={classes.button}
                  >
                    Deny
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleEmployeeAcceptSSRequest(event, notification)
                    }
                    className={classes.button}
                  >
                    Accept
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "TOR" &&
            user.role === roles.MANAGER &&
            notification.depreciatedText === null && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to="/Landing/time-off-calendar"
                  >
                    View in Calendar
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(event) => showManagerDenialForm(event)}
                    className={classes.button}
                  >
                    Deny
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) =>
                      handleManagerApproveTORequest(event, notification)
                    }
                    className={classes.button}
                  >
                    View in Requests
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.type === "ECI" && user.role === roles.MANAGER && (
            <Grid item container xs={5} spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    setShowAddOptions(true);
                    event.stopPropagation();
                  }}
                  // className={classes.addOptionsButton}
                >
                  Show Options
                </Button>
              </Grid>
            </Grid>
          )}

          {/* look at next two .includes methods once I see all the descriptions */}

          {notification.description.includes("schedule") &&
            notification.description.includes("create") &&
            notification.read !== true && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" component={Link} to="/CreateSchedule">
                    Create Schedule
                  </Button>
                </Grid>
              </Grid>
            )}
          {(notification.type === "SCG" ||
            notification.type === "SCR" ||
            notification.type === "SCP") &&
            notification.read !== true && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    component={Link}
                    to={`/Landing/${getDateFromDescription(
                      notification.description
                    )}/draft`}
                  >
                    View in Calendar
                  </Button>
                </Grid>
              </Grid>
            )}
          {notification.description.includes("preferences") &&
            notification.read !== true && (
              <Grid item container xs={5} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" component={Link} to="/Preferences">
                    Update Preferences
                  </Button>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Dialog open={showDenialReason} fullWidth maxWidth="xs">
        <DialogContent style={{ padding: 30 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h2">Reason for Denial</Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                value={denialReason}
                onChange={(e) => setDenialReason(e.target.value)}
                className={classes.input}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleManagerDenyRequest(notification);
                }}
              />
            </Grid>
            <Grid item container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleManagerDenyRequest(notification)}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => setShowDenialReason(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={showAddOptions} fullWidth maxWidth="sm">
        <DialogContent style={{ padding: 30 }}>
          <AddEmployeesForm
            selectedNotification={notification}
            closeDialog={closeAddOptions}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotificationItem;
