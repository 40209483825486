import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import EmpCalendar from "./EmpCalendar";
import { userVar } from "../../cache";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SCHEDULE_PERIODS, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import {
  Inject,
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
} from "@syncfusion/ej2-react-schedule";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    marginTop: -7,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
  downloadPopover: {
    textAlign: "left",
  },
}));

function Landing() {
  const user = userVar();
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { loading, error, data, refetch } = useQuery(GET_SCHEDULE_PERIODS, {
    variables: { officeId: parseInt(user.office.id) },
    onError(error) {
      console.log(error);
      setOpenSnackBar2(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SCHEDULE_PERIODS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  if (loading) {
    return <CircularProgress />;
  } else if (error) {
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headerSpacing}
        >
          <Grid item xs={4}>
            <Typography variant="h3">Schedule</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2} xs={8}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ width: 170 }}
              >
                Add Calendar Event
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" style={{ width: 170 }}>
                Add Soft Request
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ScheduleComponent>
          <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
        </ScheduleComponent>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </>
    );
  } else {
    return (
      <>
        <EmpCalendar
          data-testid="LandingGrid"
          schedulePeriods={data.schedulePeriods}
          refetchSchedulePeriods={refetch}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
        />
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </>
    );
  }
}

export default Landing;
