import React from 'react';
import { Grid, Typography, MenuItem, Button, Divider, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_NOTIFICATIONS } from '../../api/gqlQueries';

import PropTypes from 'prop-types';

const NotificationsDropdown = ({closePopover, userId}) => {
    const { loading, error, data } = useQuery(GET_ALL_NOTIFICATIONS, {
        variables: { employeeId: parseInt(userId) },
        fetchPolicy: 'cache-and-network',
        pollInterval: 15000
    });

    if(loading) {
        return <CircularProgress />;
    } else if (error) {
        console.log(error);
        return <Typography>Something went wrong. Please try again.</Typography>
    } else {
        const notifications = data.notifications;
        const renderedNotifications = notifications.filter(notification => notification.read !== true)
            .map(notification => 
                <Grid item key={notification.id} onClick={closePopover}>
                    <MenuItem component={Link} to="/Notifications">
                        {notification.title}
                    </MenuItem>
                    <Divider />
                </Grid>
            );
    
        return (  
            <Grid container direction="column" spacing={2}>
                {notifications 
                    ? renderedNotifications 
                    : <Grid item><Typography>No New Notifications</Typography></Grid>
                }
                <Grid item component={Button} onClick={closePopover}>
                    <Link to="/Notifications">See all notifications</Link>
                </Grid>
            </Grid>
        );
    }
    
};

NotificationsDropdown.propTypes = {
    closePopover: PropTypes.func
};
 
export default NotificationsDropdown;