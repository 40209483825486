import React, { useEffect, useState } from "react";
import ShiftType from "./ShiftType";
import PreferenceQuiz from "./PreferenceQuiz";
import {
  Grid,
  Typography,
  Button,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles,
  Slider,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Collapse,
  TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentNeutralIcon from "../../assets/quizImages/SentimentNeutralIcon";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import InfoIcon from "@material-ui/icons/Info";
import { Formik, FieldArray } from "formik";
import {
  mapPrefsFromBackend,
  mapPrefsToBackend,
} from "../../helpers/mapPreferences";
import { useQuery, useMutation } from "@apollo/client";
import {
  BATCH_UPDATE_PREFERENCES,
  GET_PREFERENCES,
  UPDATE_PREFS_SETTINGS,
  BATCH_CREATE_PREFERENCES,
  NOTIFY_DEVELOPERS,
  OFFICES_WITH_CHILD_PARENT,
  LOCATION_PREFERENCES,
  BATCH_CREATE_LOCATION_PREFERENCES,
  BATCH_UPDATE_LOCATION_PREFERENCES,
  SHIFT_DEFINITION_TYPE,
} from "../../api/gqlQueries";
import { userVar, officeConfigVar } from "../../cache";
import DaysOfTheWeek from "./DaysOfTheWeek";
import DaysOffInARow from "./DaysOffInARow";
import ReschedulingPreferences from "./ReschedulingPreferences";
import LocationPreferences from "./LocationPreferences";
import ErrorSnackBar from "../errors/errorSnackBar";
import { boolean } from "yup";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
  },
  button: {
    width: 110,
  },
  tabs: {
    marginTop: 20,
    marginBottom: 30,
  },
  tab: {
    width: 235,
  },
  sliderBox: {
    maxWidth: 425,
    marginTop: 16,
  },
  coloredText: {
    color: "#8CADE1",
  },
}));

function Preferences() {
  const classes = useStyles();

  const user = userVar();
  const officeConfigVariable = officeConfigVar();
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [status, setStatus] = useState(false);
  const [showQuiz, setShowQuiz] = useState(user.alwaysOpenGuide || false);
  const [showExample, setShowExample] = useState(
    user.showPrefsExample ? user.showPrefsExample : true
  );
  const [example, setExample] = useState(0);

  const toggleQuiz = () => setShowQuiz(!showQuiz);

  const environment = process.env.NODE_ENV;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [parentChild, SetParentChild] = useState();

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const officeParentChild = useQuery(OFFICES_WITH_CHILD_PARENT, {
    onCompleted(d) {
      console.log(d);
      SetParentChild(d.offices);
    },
  });

  const { loading, error, data, refetch } = useQuery(GET_PREFERENCES, {
    variables: {
      id: parseInt(user.id),
      office: parseInt(user.office.id),
    },
    onError(err) {
      setOpenSnackBar2(true);
      setSnackBarMessage("Graphql " + err);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_PREFERENCES Query. Environment: " +
            environment +
            " Graphql " +
            err,
        },
      });
    },
  });

  const locationPreferences = useQuery(LOCATION_PREFERENCES, {
    variables: {
      employee: parseInt(user.id),
    },
    onError(err) {
      setOpenSnackBar2(true);
      setSnackBarMessage("Graphql " + err);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_PREFERENCES Query. Environment: " +
            environment +
            " Graphql " +
            err,
        },
      });
    },
  });

  const allShiftTypes = useQuery(SHIFT_DEFINITION_TYPE);

  const [batchCreateLocationPreferences] = useMutation(
    BATCH_CREATE_LOCATION_PREFERENCES,
    {
      onCompleted(d) {
        console.log(d);
        locationPreferences.refetch();
      },
    }
  );

  const [batchUpdateLocationPreferences] = useMutation(
    BATCH_UPDATE_LOCATION_PREFERENCES,
    {
      onCompleted(d) {
        locationPreferences.refetch();
      },
    }
  );

  const [batchCreatePreferences] = useMutation(BATCH_CREATE_PREFERENCES, {
    onCompleted(data) {
      console.log(data);
      refetch();
      setShowToast(true);
      setStatus(false);
      setShowQuiz(false);
    },
    onError(error) {
      console.log(error);
      setShowErrorToast(true);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_BATCH_PREFERENCES Mutation. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const [batchUpdatePreferences] = useMutation(BATCH_UPDATE_PREFERENCES, {
    onCompleted(data) {
      console.log(data);
      setShowToast(true);
      setStatus(false);
      setShowQuiz(false);
    },
    onError(error) {
      console.log(error);
      setShowErrorToast(true);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_UPDATE_PREFERENCES Mutation. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const [updateUser] = useMutation(UPDATE_PREFS_SETTINGS, {
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_PREFS_SETTINGS Mutation. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const marks = [
    {
      value: -2,
      label: <MoodBadIcon style={{ paddingLeft: 15 }} />,
    },
    {
      value: -1,
      label: <SentimentDissatisfiedIcon />,
    },
    {
      value: 0,
      label: <SentimentNeutralIcon />,
    },
    {
      value: 1,
      label: <SentimentSatisfiedIcon />,
    },
    {
      value: 2,
      label: <MoodIcon style={{ paddingRight: 15 }} />,
    },
  ];

  if (
    loading ||
    locationPreferences.loading ||
    officeParentChild.loading ||
    allShiftTypes.loading
  ) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return (
      <>
        <Grid container direction="row">
          <Grid item container className={classes.headerSpacing} xs={12}>
            <Grid item xs={6}>
              <Typography variant="h3">Preferences</Typography>
            </Grid>
          </Grid>
        </Grid>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </>
    );
  } else {
    let shiftPreferences = mapPrefsFromBackend(data.preferences);

    let daysIndicators = [];
    let dayTypes = [];
    let shiftIndicators = [];
    let shiftTypes = [];

    data.preferenceTypes.forEach((type) => {
      const shifts = ["DAYS", "NIGHTS", "SWINGS"];
      const days = [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ];
      if (shifts.includes(type.type)) {
        shiftTypes.push({
          id: parseInt(type.id),
          name: type.type,
        });
      } else if (days.includes(type.type)) {
        dayTypes.push({
          id: parseInt(type.id),
          name: type.type,
        });
      }
    });
    data.preferenceIndicators.forEach((indicator) => {
      const day = dayTypes.find(
        (type) => parseInt(type.id) === parseInt(indicator.preferenceType)
      );
      const shift = shiftTypes.find(
        (type) => parseInt(type.id) === parseInt(indicator.preferenceType)
      );
      if (day) {
        daysIndicators.push({
          ...indicator,
          prefName: day.name,
        });
      } else if (shift) {
        shiftIndicators.push({
          ...indicator,
          prefName: shift.name,
        });
      }
    });

    const handleSubmit = (values) => {
      const newValues = values;
      newValues.workAdditionalShifts =
        newValues.workAdditionalShifts === true ? 1 : 0;

      const originalPrefsTypeIds = data.preferences.map(
        (preference) => preference.type.id
      );
      const prefTypesToBeCreated = data.preferenceTypes.filter(
        (type) => !originalPrefsTypeIds.includes(type.id)
      );
      const backend = mapPrefsToBackend(
        newValues,
        data.preferences,
        prefTypesToBeCreated,
        parseInt(user.id)
      );

      if (backend.update.length > 0) {
        batchUpdatePreferences({
          variables: {
            input: backend.update,
          },
        });
      }

      if (backend.create.length > 0) {
        batchCreatePreferences({
          variables: {
            input: backend.create,
          },
        });
      }
    };

    let locPreferences =
      parentChild &&
      parentChild.length > 0 &&
      parentChild
        .filter((e) => e.parent != null)
        .map((e) => {
          let prefValue =
            locationPreferences.data &&
            locationPreferences.data.locationPreferences.length > 0 &&
            locationPreferences.data.locationPreferences.find(
              (o) => o.office.id === e.id
            );
          return {
            id: e.id,
            locPrefId: prefValue ? prefValue.id : "",
            office: e.name,
            value: prefValue ? prefValue.value : 0,
          };
        })
        .filter(Boolean);

    const shiftTypeNames = allShiftTypes.data.shiftTypes;
    return (
      <>
        <Formik
          initialValues={{
            workAdditionalShifts:
              shiftPreferences.workAdditionalShifts === 1 ? true : false,

            day: shiftPreferences.day || 0,
            night: shiftPreferences.night || 0,
            swing: shiftPreferences.swing || 0,
            allDayShift: shiftPreferences.allDayShift || 0,
            monday: shiftPreferences.monday || 0,
            tuesday: shiftPreferences.tuesday || 0,
            wednesday: shiftPreferences.wednesday || 0,
            thursday: shiftPreferences.thursday || 0,
            friday: shiftPreferences.friday || 0,
            saturday: shiftPreferences.saturday || 0,
            sunday: shiftPreferences.sunday || 0,

            oneDay: shiftPreferences.oneDay || 0,
            twoDays: shiftPreferences.twoDays || 0,
            threeDays: shiftPreferences.threeDays || 0,
            fourDays: shiftPreferences.fourDays || 0,

            overtime: shiftPreferences.overtime || 0,
            locationPreferences: locPreferences,
          }}
          onSubmit={(values) => {
            let createlocationPrefInput = values.locationPreferences
              .filter((e) => e.locPrefId === "")
              .map((e) => {
                return {
                  employee: parseInt(user.id),
                  office: parseInt(e.id),
                  value: e.value,
                };
              });
            let updatelocationPrefInput = values.locationPreferences
              .filter((e) => e.locPrefId != "")
              .map((e) => {
                return {
                  id: parseInt(e.locPrefId),
                  employee: parseInt(user.id),
                  office: parseInt(e.id),
                  value: e.value,
                };
              });
            createlocationPrefInput.length > 0 &&
              batchCreateLocationPreferences({
                variables: {
                  input: createlocationPrefInput,
                },
              });
            updatelocationPrefInput.length > 0 &&
              batchUpdateLocationPreferences({
                variables: {
                  input: updatelocationPrefInput,
                },
              });
            handleSubmit(values);
          }}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            handleReset,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container direction="row">
                <Grid item container className={classes.headerSpacing} xs={12}>
                  <Grid item xs={6}>
                    <Typography variant="h3">Preferences</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    spacing={2}
                    xs={6}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={toggleQuiz}
                        className={classes.button}
                      >
                        Open Guide
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleReset();
                          setStatus(false);
                        }}
                        className={classes.button}
                        disabled={!dirty}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.button}
                        disabled={
                          !dirty
                          // ((user.isPrimaryParentOffice === true ||
                          //   user.isPrimaryChildOffice === true) &&
                          //   values.locationPreferences &&
                          //   values.locationPreferences.length > 0 &&
                          //   values.locationPreferences
                          //     .map((e) => e.value)
                          //     .reduce((a, b) => a + b, 0) > 1) ||
                          // (values.locationPreferences &&
                          //   values.locationPreferences.length > 0 &&
                          //   values.locationPreferences
                          //     .map((e) => e.value)
                          //     .reduce((a, b) => a + b, 0) < 1)
                        }
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <Typography>
                    Set your preferences for the next schedule
                  </Typography>
                </Grid>
                <Collapse in={user.showPrefsExample} timeout={{ exit: 750 }}>
                  <Grid item sm={12} md={5} style={{ marginBottom: 48 }}>
                    <Grid item container direction="column" xs={10}>
                      <Grid item>
                        <Typography>
                          <InfoIcon
                            style={{ marginRight: 5 }}
                            fontSize="small"
                          />
                          <b>Example: </b>
                          If you really love working day shift, slide the slider
                          all the way to the right to the
                          <MoodIcon
                            style={{
                              height: 18,
                              marginBottom: -4,
                              marginLeft: 2,
                            }}
                          />
                          . If you somewhat dislike working day shift, slide the
                          slider one notch to the left of neutral to the
                          <SentimentDissatisfiedIcon
                            style={{
                              height: 18,
                              marginBottom: -4,
                              marginLeft: 2,
                            }}
                          />
                          .
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.coloredText}
                        >
                          Note: This is for you to play with and will not affect
                          your preferences
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        className={classes.sliderBox}
                      >
                        <Grid item style={{ marginTop: 4 }}>
                          <FormLabel>Day Shift</FormLabel>
                        </Grid>
                        <Grid item xs={9}>
                          <Slider
                            value={example}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="off"
                            step={1}
                            marks={marks}
                            min={-2}
                            max={2}
                            onChange={(e, value) => setExample(value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!showExample}
                              onChange={(e) => {
                                setShowExample(!e.target.checked);
                                updateUser({
                                  variables: {
                                    id: parseInt(user.id),
                                    input: { showPrefsExample: false },
                                  },
                                });
                                const userVariable = userVar();
                                userVar({
                                  ...userVariable,
                                  showPrefsExample: false,
                                });
                              }}
                              name="show-example"
                            />
                          }
                          label="Do not show example again"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}></Grid>
                </Collapse>

                <Grid item container sm={12} md={6} direction="column">
                  {officeConfigVariable.shiftTypePreferences && (
                    <Grid item style={{ marginBottom: 48 }}>
                      <ShiftType
                        values={values}
                        setFieldValue={setFieldValue}
                        setStatus={setStatus}
                        indicators={shiftIndicators}
                        allShiftTypes={shiftTypeNames}
                        // originalPrefs={data.preferences}
                      />
                    </Grid>
                  )}
                  {officeConfigVariable.daysOffPreferences && (
                    <Grid item style={{ marginBottom: 24 }}>
                      <DaysOffInARow
                        values={values}
                        setFieldValue={setFieldValue}
                        setStatus={setStatus}
                        data-testid="daysOffInARow"
                      />
                    </Grid>
                  )}
                  {officeConfigVariable.overTimePreferences && (
                    <Grid item style={{ marginBottom: 24 }}>
                      <ReschedulingPreferences
                        values={values}
                        setFieldValue={setFieldValue}
                        setStatus={setStatus}
                      />
                    </Grid>
                  )}
                </Grid>
                {officeConfigVariable.daysOfTheWeekPreferences && (
                  <Grid item container sm={12} md={6} direction="column">
                    <Grid item style={{ marginBottom: 48 }}>
                      <DaysOfTheWeek
                        values={values}
                        setFieldValue={setFieldValue}
                        setStatus={setStatus}
                        indicators={daysIndicators}
                        // originalPrefs={data.preferences}
                      />
                    </Grid>
                    <Grid item style={{ marginBottom: 48 }}>
                      {(user.isPrimaryParentOffice ||
                        user.isPrimayChildOffice) && (
                        <LocationPreferences
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  spacing={2}
                  xs={12}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleReset();
                        setStatus(false);
                      }}
                      className={classes.button}
                      disabled={!dirty}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      disabled={
                        !dirty 
                        // ((user.isPrimaryParentOffice === true ||
                        //   user.isPrimaryChildOffice === true) &&
                        //   values.locationPreferences &&
                        //   values.locationPreferences.length > 0 &&
                        //   values.locationPreferences
                        //     .map((e) => e.value)
                        //     .reduce((a, b) => a + b, 0) > 1) ||
                        // (values.locationPreferences &&
                        //   values.locationPreferences.length > 0 &&
                        //   values.locationPreferences
                        //     .map((e) => e.value)
                        //     .reduce((a, b) => a + b, 0) < 1)
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>Preferences saved!</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={6000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>
              Unable to save preferences. Please make sure you have completed
              every step.
            </Typography>
          </MuiAlert>
        </Snackbar>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
        <Dialog open={showQuiz} fullWidth maxWidth="md">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              height: 685,
              position: "relative",
            }}
          >
            <PreferenceQuiz
              closeDialog={toggleQuiz}
              shiftPreferences={shiftPreferences}
              batchUpdatePreferences={batchUpdatePreferences}
              batchCreatePreferences={batchCreatePreferences}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              environment={environment}
              notifyDevelopers={notifyDevelopers}
              originalPrefs={data.preferences}
              prefTypes={data.preferenceTypes}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default Preferences;
