import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Checkbox,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const CustomShiftType = (props) => {
  const {
    allShiftTypes,
    userShiftTypes,
    closeDialog,
    selectedShiftTypes,
    setSelectedShiftTypes,
    setFieldError,
    rotationValue,
  } = props;

  let existingShifts = [];
  let existingDays = [];
  if (selectedShiftTypes && selectedShiftTypes.length > 0) {
    selectedShiftTypes.forEach((shiftType) => {
      !existingShifts.includes(shiftType.name) &&
        existingShifts.push(shiftType.name);
      shiftType.ids.forEach((id) => {
        !existingDays.includes(id.day) && existingDays.push(id.day);
      });
    });
  } else if (userShiftTypes.length > 0) {
    userShiftTypes.forEach((shiftType) => {
      const description = shiftType.shiftDefinition.description;
      const weekday = shiftType.shiftDefinition.weekday.name;
      !existingShifts.includes(description) && existingShifts.push(description);
      !existingDays.includes(weekday) && existingDays.push(weekday);
    });
  }

  const [shifts, setShifts] = useState(existingShifts);
  const [days, setDays] = useState(existingDays);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleCheckboxToggle = (value, list) => () => {
    let checked;
    let updater;
    if (list === "shift") {
      checked = [...shifts];
      updater = setShifts;
    } else {
      checked = [...days];
      updater = setDays;
    }

    const currentIndex = checked.indexOf(value);
    if (currentIndex === -1) {
      checked.push(value);
    } else {
      checked.splice(currentIndex, 1);
    }
    updater(checked);
  };

  const handleCheckAll = () => {
    if (days.length === daysOfWeek.length) {
      setDays([]);
    } else {
      setDays(daysOfWeek);
    }
  };
  const handleSave = () => {
    const selectedShifts = allShiftTypes.filter((shiftType) =>
      shifts.includes(shiftType.name)
    );
    let shiftDefinitions = [];
    if (days.length === 7) {
      selectedShifts.forEach((shift) => {
        // const idsOnly = shift.ids.map(id => id.id);
        const newShiftDefinition = { name: shift.name, ids: shift.ids };
        shiftDefinitions = [...shiftDefinitions, newShiftDefinition];
      });
    } else {
      selectedShifts.forEach((shift) => {
        const filteredIds = shift.ids.filter((id) => days.includes(id.day));
        const newShiftDefinition = { name: shift.name, ids: filteredIds };
        shiftDefinitions = [...shiftDefinitions, newShiftDefinition];
      });
    }
    if (shifts.length > 1 && rotationValue === 0) {
      setFieldError(
        "rotationLength",
        "Value cannot be 0 as you have chosen more than one shift type."
      );
    }
    setSelectedShiftTypes(shiftDefinitions);
    closeDialog();
  };

  return (
    <Grid container justifyContent="flex-start">
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item>
          <Typography variant="h2">Custom Shift Type</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Typography style={{ textAlign: "left", fontWeight: "bold" }}>
          Shifts Employee Works:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          color="primary"
          style={{ textAlign: "left" }}
        >
          If employee rotates, choose the shifts they rotate betweeen.
        </Typography>
      </Grid>
      {allShiftTypes.length > 0 &&
        allShiftTypes.map((shiftType) => (
          <Grid
            item
            xs={3}
            container
            alignItems="center"
            key={shiftType.name}
            onClick={handleCheckboxToggle(shiftType.name, "shift")}
          >
            <Grid item>
              <Checkbox checked={shifts.includes(shiftType.name)} />
            </Grid>
            <Grid item>
              <Typography>{shiftType.name}</Typography>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Typography style={{ textAlign: "left", fontWeight: "bold" }}>
          Chosen Shifts Apply to the Following Days:
        </Typography>
      </Grid>
      <Grid item xs={3} container alignItems="center" onClick={handleCheckAll}>
        <Grid item>
          <Checkbox checked={days.length === daysOfWeek.length} />
        </Grid>
        <Grid item>
          <Typography>All Days</Typography>
        </Grid>
      </Grid>
      {daysOfWeek.map((day) => (
        <Grid
          item
          xs={3}
          container
          alignItems="center"
          key={day}
          onClick={handleCheckboxToggle(day, "day")}
        >
          <Grid item>
            <Checkbox checked={days.includes(day)} />
          </Grid>
          <Grid item>
            <Typography>{day}</Typography>
          </Grid>
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        style={{ marginTop: 16 }}
      >
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomShiftType;
