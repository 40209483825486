import React from "react";
import {
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  Typography,
  makeStyles,
  Slider,
} from "@material-ui/core";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentNeutralIcon from "../../assets/quizImages/SentimentNeutralIcon";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import { userVar } from "../../cache";
import PrefDesirability from "./PrefDesirability";

const useStyles = makeStyles(() => ({
  smalltextbox: {
    width: 36,
  },
  disabled: {
    color: "rgb(158,158,158)",
  },
  sliderBox: {
    maxWidth: 425,
    marginTop: 16,
    marginBottom: 16,
  },
}));

const ShiftType = (props) => {
  const classes = useStyles();

  const { values, setFieldValue, setStatus, indicators, allShiftTypes } = props;
  const user = userVar();

  // const mapShiftType = {
  //     'Any': 'Any Shift',
  //     'Days Only': 'Day Shift',
  //     'Nights Only': 'Night Shift',
  //     'Swing Only': 'Swing Shift',
  //     'Weekends Only': 'Weekend Shift',
  //     'Weekdays Only': 'Weekday Shift',
  //     'Rotation': 'Rotating Shifts'
  // };

  const hiredForShift = user.shiftType;
  // const hiredForShift = mapShiftType[user.shiftType]
  //     ? mapShiftType[user.shiftType]
  //     : 'Any Shift';
  const disableShiftType = Boolean(
    hiredForShift !== "Any Shift" && !values.workAdditionalShifts
  );

  const marks = [
    {
      value: -2,
      label: (
        <MoodBadIcon
          className={disableShiftType ? classes.disabled : null}
          style={{ paddingLeft: 15 }}
        />
      ),
    },
    {
      value: -1,
      label: (
        <SentimentDissatisfiedIcon
          className={disableShiftType ? classes.disabled : null}
        />
      ),
    },
    {
      value: 0,
      label: <SentimentNeutralIcon disabled={disableShiftType} />,
    },
    {
      value: 1,
      label: (
        <SentimentSatisfiedIcon
          className={disableShiftType ? classes.disabled : null}
        />
      ),
    },
    {
      value: 2,
      label: (
        <MoodIcon
          className={disableShiftType ? classes.disabled : null}
          style={{ paddingRight: 15 }}
        />
      ),
    },
  ];

  return (
    <Grid container direction="column" data-testid="shiftTypePreferences">
      <Grid item>
        <Typography style={{ marginBottom: 16, lineHeight: 1, fontSize: 18 }}>
          <b>Shift Type</b>
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <Typography variant="h6">
          You were hired for: {hiredForShift}
        </Typography>
      </Grid>
      {hiredForShift !== "Any Shift" && (
        <Grid item style={{ marginLeft: 24, marginTop: 16 }}>
          <FormLabel>
            Are you willing to work a different shift than what you were hired
            for?
          </FormLabel>
          <RadioGroup name="work-additional-shift">
            <FormControlLabel
              label="Yes"
              control={
                <Radio
                  checked={values.workAdditionalShifts === true}
                  onChange={() => {
                    setFieldValue("workAdditionalShifts", true);
                    setStatus(true);
                  }}
                />
              }
            />
            <FormControlLabel
              label="No"
              control={
                <Radio
                  checked={values.workAdditionalShifts === false}
                  onChange={() => {
                    setFieldValue("workAdditionalShifts", false);
                    setStatus(true);
                  }}
                />
              }
            />
          </RadioGroup>
        </Grid>
      )}
      <Grid item style={{ marginTop: 16 }}>
        <Typography
          variant="h6"
          className={disableShiftType ? classes.disabled : null}
        >
          Shift Preferred
        </Typography>
      </Grid>
      {allShiftTypes.find((e) => e.type === "day" || e.type==="Day") != null && (
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel className={disableShiftType ? classes.disabled : null}>
              Day Shift
            </FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.day}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              disabled={disableShiftType}
              onChange={(e, value) => {
                setFieldValue("day", value);
                setStatus(true);
              }}
              className={classes.slider}
            />
          </Grid>
          <Grid item xs={12}>
            <PrefDesirability
              value={values.day}
              indicator={indicators.find(
                (indicator) => indicator.prefName === "DAYS"
              )}
              disabled={disableShiftType}
            />
          </Grid>
        </Grid>
      )}
      {allShiftTypes.find((e) => e.type === "night" || e.type==="Night") != null && (
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel className={disableShiftType ? classes.disabled : null}>
              Night Shift
            </FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.night}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              disabled={disableShiftType}
              onChange={(e, value) => {
                setFieldValue("night", value);
                setStatus(true);
              }}
              className={classes.slider}
            />
          </Grid>
          <Grid item xs={12}>
            <PrefDesirability
              value={values.night}
              indicator={indicators.find(
                (indicator) => indicator.prefName === "NIGHTS"
              )}
              disabled={disableShiftType}
            />
          </Grid>
        </Grid>
      )}
      {allShiftTypes.find((e) => e.type === "swing" || e.type==="Swing") != null && (
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel className={disableShiftType ? classes.disabled : null}>
              Swing Shift
            </FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.swing}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              disabled={disableShiftType}
              onChange={(e, value) => {
                setFieldValue("swing", value);
                setStatus(true);
              }}
              className={classes.slider}
            />
          </Grid>
          <Grid item xs={12}>
            <PrefDesirability
              value={values.swing}
              indicator={indicators.find(
                (indicator) => indicator.prefName === "SWINGS"
              )}
              disabled={disableShiftType}
            />
          </Grid>
        </Grid>
      )}
      {allShiftTypes.find((e) => e.type === "24-hr") != null && (
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel className={disableShiftType ? classes.disabled : null}>
              24-Hours
            </FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.allDayShift}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              disabled={disableShiftType}
              onChange={(e, value) => {
                setFieldValue("allDayShift", value);
                setStatus(true);
              }}
              className={classes.slider}
            />
          </Grid>
          <Grid item xs={12}>
            <PrefDesirability
              value={values.swing}
              indicator={indicators.find(
                (indicator) => indicator.prefName === "24HR"
              )}
              disabled={disableShiftType}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ShiftType;
