import React, { useEffect, useState } from 'react';
import { 
    Typography, Grid, IconButton, 
    FormControlLabel, Switch, Button, 
    InputLabel, makeStyles 
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { add, sub, isWithinInterval, isSameDay, format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { EDIT_SOFT_REQUEST, DELETE_SOFT_REQUEST } from '../../api/gqlQueries';
import { filterListVar } from '../../cache';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 138,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.primary.main
    },
    button: {
        width: 75
    }
}));

const EditSoftTimeOff = (props) => {
    const classes = useStyles();

    const {
        closeDialog, 
        // dayObject, 
        scheduleEndDate, 
        invalidDates, 
        setToast, 
        setShowToast,
        // showDayActions,
        maxGreyout,
        schedulePeriodWeeks,
        softRequests,
        softRequestIdToEdit,
        setSoftRequestIdToEdit,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,
    } = props;

    const filterList = filterListVar();
    
    const softRequestToEdit = softRequests.find(request => (
        request.eventId === softRequestIdToEdit
    ));

    const [priorityChecked, setPriorityChecked] = useState(softRequestToEdit 
        ? softRequestToEdit.highPriority : false
    );
    const [dateForRequest, setDateForRequest] = useState(softRequestToEdit 
        ? new Date(softRequestToEdit.start) : new Date()
    );
    const [disableHighPriority, setDisableHighPriority] = useState(false);
    const [highPriorityMessage, setHighPriorityMessage] = useState('');
    const [error, setError] = useState('');
    const [disableSave, setDisableSave] = useState(true);
    const minimumDate = add(new Date(scheduleEndDate), {days: 1});

    let formattedInvalidDates = [...invalidDates];
    if (softRequestToEdit) {
        formattedInvalidDates = formattedInvalidDates.filter(date => (
            date !== format(new Date(softRequestToEdit.start), 'MM/dd/yyyy')
        ));
    }
        
    const [updatePreference] = useMutation(EDIT_SOFT_REQUEST, {
        onCompleted(data) {
            //check to see if user is currently filtering soft requests or all requests to update 
            //emp day action event
            // if (showDayActions && isSameDay(dateForRequest, new Date(dayObject.date)) &&
            //     (filterList.allFilters.includes('Soft Requests') ||
            //     filterList.allFilters.includes('All Requests'))) {
            //     const eventToChange = dayObject.events.find(event => (
            //         parseInt(event.eventId) === parseInt(data.updateOffPreference.offPreference.id)
            //     ));
            //     const eventsNotChanging = dayObject.events.filter(event => (
            //         parseInt(event.eventId) !== parseInt(data.updateOffPreference.offPreference.id)
            //     ));
            //     const updatedEvent = {
            //         ...eventToChange,
            //         start: new Date(dateForRequest),
            //         highPriority: priorityChecked
            //     };
    
            //     dayObject.events = eventsNotChanging.length > 0 
            //         ? [...eventsNotChanging, updatedEvent] 
            //         : [updatedEvent];
            // } else if (showDayActions && !isSameDay(dateForRequest, new Date(dayObject.date))) {
            //     const eventsNotChanging = dayObject.events.filter(event => (
            //         parseInt(event.eventId) !== parseInt(data.updateOffPreference.offPreference.id)
            //     ));
            //     dayObject.events = eventsNotChanging.length > 0 
            //         ? [...eventsNotChanging] 
            //         : undefined;
            // }
            setToast('Edit Soft Request');
            setShowToast(true);
            setSoftRequestIdToEdit('');
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Unable to edit soft time off request. Please check date and try again.');
            notifyDevelopers({variables: {message: "Error on EDIT_SOFT_REQUEST Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const [deleteSoftRequest] = useMutation(EDIT_SOFT_REQUEST, {
        update(cache, { data: { deleteOffPreference }}) {
            cache.evict({
                id: `OffPreferenceNode:${deleteOffPreference.deletedId}`
            });
        },
        onCompleted(data) {
            console.log(data);
            // if (showDayActions && isSameDay(dateForRequest, new Date(dayObject.date)) &&
            //     (filterList.allFilters.includes('Soft Requests') ||
            //     filterList.allFilters.includes('All Requests'))) {
                
            //     const eventsNotDeleted = dayObject.events.filter(event => (
            //         event.eventId !== data.deleteOffPreference.deletedId
            //     ));
            //     if (eventsNotDeleted.length === 0){
            //         dayObject.events = undefined;
            //     } else {
            //         dayObject.events = [...eventsNotDeleted];
            //     }
            // } else if (showDayActions && !isSameDay(dateForRequest, new Date(dayObject.date))) {
            //     const eventsNotChanging = dayObject.events.filter(event => (
            //         parseInt(event.eventId) !== parseInt(data.updateOffPreference.offPreference.id)
            //     ));
            //     dayObject.events = eventsNotChanging.length > 0 
            //         ? [...eventsNotChanging] 
            //         : undefined;
            // }
            setToast('Delete Soft Request');
            setShowToast(true);
            setSoftRequestIdToEdit('');
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Unable to delete soft time off request. Please try again.');
            notifyDevelopers({variables: {message: "Error on EDIT_SOFT_REQUEST Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const handleSubmit = () => {    
        const updatedRequest = {
            date: dateForRequest.toISOString().slice(0, 10),
            value: priorityChecked ? 10.0 : 5.0
        };
        updatePreference({variables: {
            id: softRequestIdToEdit,
            input: {...updatedRequest}
        }});
    };

    const handleDelete = () => {
        deleteSoftRequest({variables: {id: softRequestIdToEdit}});
    };

    const checkInvalid = (date) => {
        const formatted = format(date, 'MM/dd/yyyy');

        return formattedInvalidDates.includes(formatted);
    };

    const checkRequests = (date) => {
        let firstDate = new Date(scheduleEndDate);
        let secondDate = add(firstDate, {days: 1, weeks: schedulePeriodWeeks});

        let dateToView = date;
        if (date < firstDate) {
            dateToView = add(scheduleEndDate, {days: 1});
        }

        //find schedule start and end dates that selected date is within
        while (!isWithinInterval(dateToView, {start: firstDate, end: secondDate})) {
            firstDate = sub(secondDate, {days: 1});
            secondDate = add(firstDate, {days: 1, weeks: schedulePeriodWeeks});
        }
        firstDate = add(firstDate, {days: 1});
        firstDate.setHours(0, 0, 0, 0);
        secondDate.setHours(23, 59, 59, 59);

        //check for current high and standard priority soft requests in window
        const softRequestsInWindow = softRequests.filter(request => {
            return new Date(request.start) > firstDate && new Date(request.start) < secondDate;
        });
        const highPriorityInWindow = softRequestsInWindow.filter(request => request.highPriority);

        //only allow one high priority request per schedule period
        if (highPriorityInWindow.length > 0 && !priorityChecked) {
            setDisableHighPriority(true);
            setHighPriorityMessage('You can only have one high priority soft request per schedule period.');
        } else {
            setDisableHighPriority(false);
            setHighPriorityMessage('');
        }
        
        //check max soft requests per schedule period
        if (softRequestsInWindow.length <= maxGreyout) {
            // setDateForRequest(date);
            setDisableSave(false);
            setError('');
        } else {
            setError('You have reached you maximum soft requests for this schedule period.');
            // setDateForRequest(date);
            setDisableSave(true);
        }
    };

    const handleUpdateDate = (date) => {
        if(date && !isNaN(date.getTime())){
            const invalid = checkInvalid(date);
            if (!invalid && date >= new Date(scheduleEndDate)) {
                setDateForRequest(date);
                checkRequests(date);
            } else {
                setError('You must choose a date after the schedule ends that does not already have a request');
            }
        } else {
            setError('Invalid date');
        }
    };

    useEffect(() => {
        let date = softRequestToEdit 
            ? new Date(softRequestToEdit.start)
            : new Date();
        checkRequests(date);
    }, []);

    return (  
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction='column' spacing={2}>
                <Grid item container justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h2'>Edit Soft Request</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label='close' color='secondary' size='small' onClick={closeDialog} >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={priorityChecked}
                                onChange={e => setPriorityChecked(e.target.checked)}
                                name="priorityChecked"
                                color={priorityChecked ? 'primary' : 'secondary'}
                                disabled={disableHighPriority}
                            />
                        }
                        label="Priority Request"
                    >
                    </FormControlLabel>
                </Grid>
                <Grid item style={{height: 15, marginTop: -15, marginBottom: 16}}>
                    {highPriorityMessage && 
                        <Typography variant='body2' className={classes.error}>
                            {highPriorityMessage}
                        </Typography>
                    }
                </Grid>
                <Grid item >
                    <InputLabel htmlFor='date-for-request'>
                        <Typography variant='h5'>Date:</Typography>
                    </InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant='inline'
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        id='date-for-request'
                        shouldDisableDate={checkInvalid}
                        minDate={minimumDate}
                        value={dateForRequest}
                        onChange={handleUpdateDate}
                        className={classes.input}
                    />
                </Grid>
                <Grid item style={{height: 40, marginTop: -15, marginBottom: 10}}>
                    {error && <Typography className={classes.error}>{error}</Typography>}
                </Grid>
                <Grid item container justifyContent='flex-end' spacing={2}>
                    <Grid item>
                        <Button 
                            variant='outlined' 
                            color='primary' 
                            onClick={handleDelete}
                            className={classes.button}
                        >
                            Delete
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={handleSubmit}
                            disabled={disableSave}
                            className={classes.button}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};
 
export default EditSoftTimeOff;