export const formatPhoneNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match;
  if (cleaned.length === 10) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})/);
  } else {
    return str;
  }

  if (match) {
    return "+1 " + " (" + match[1] + ") " + match[2] + " " + match[3];
  }
};

export const findskillset = (skills, value, officeid) => {
  let found;
  if (officeid != null) {
    found =
      skills.length > 0 &&
      skills.filter(
        (i) =>
          i.variety === value && i.office != null && i.office.id === officeid
      );
  } else {
    found = skills.length > 0 && skills.filter((i) => i.variety === value);
  }
  return found;
};

export const handleSort = (employees, sortDirection) => {
  const comparator =
    sortDirection === "asc"
      ? (a, b) => a.lastName?.localeCompare(b.lastName)
      : (a, b) => b.lastName?.localeCompare(a.lastName);
  let emp = employees.sort(comparator);
  return emp;
};

function isValid(str) {
  return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
}

export const addEmpDetails = (values, employeeTypes, batchCreateEmployee) => {
  const inputData = values.map((element) => {
    const phone = !isValid(element.Phone)
      ? element.Phone.replace(/\D/g, "")
      : element.Phone;
    let name = element.Name.includes(",")
      ? element.Name.split(",")
      : element.Name.split(" ");
    let firstName = name[0];
    firstName = firstName.trim();
    let lastName = name[1];
    lastName = lastName.trim();
    const employeetypeid = employeeTypes.find(
      (i) => i.name === element.EmploymentType
    ).id;
    return {
      firstName: firstName,
      lastName: lastName,
      email: element.Email,
      password: "Test@123",
      primaryPhone: phone,
      hasChangedDefaultPassword: false,
      employeeType: parseInt(employeetypeid),
    };
  });
  batchCreateEmployee({
    variables: { input: inputData },
  });
};

export const handlefileValdation = (data, alloffices, roles) => {
  let errMsg = "";
  let offices = [];
  let allroles = [];
  !alloffices.loading &&
    alloffices.data.offices.map((e) => offices.push(e.name));
  roles.map((e) => allroles.push(e.name));
  let uniqueEmails = [];
  for (let i = 0; i < data.length; i++) {
    let err = false;
    if (!err) {
      if (!offices.includes(data[i].Department)) {
        err = true;
        return (errMsg =
          "The department name does not match any departments of this location. Make sure you enter the right department name.");
      }
      if (!allroles.includes(data[i].Role)) {
        err = true;
        return (errMsg =
          "The Role entered does not match any Roles of this location. Make sure you enter the right Role name.");
      }
      if (uniqueEmails.includes(data[i].Email)) {
        err = true;
        return (errMsg =
          "The data contains more than one record with the same email ID " +
          data[i].Email +
          ".Please Remove duplicates and try again.");
      } else {
        uniqueEmails.push(data[i].Email);
      }
    }
  }
  return errMsg;
};
