import React, { useEffect, useState } from 'react';
import { 
    Typography, Grid, IconButton, 
    FormControlLabel, Switch, Button, 
    InputLabel, makeStyles 
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { add, sub, isWithinInterval, isSameDay, format } from 'date-fns';
import { useMutation, gql } from '@apollo/client';
import { CREATE_SOFT_REQUEST } from '../../api/gqlQueries';
import { userVar, filterListVar, selectedDateVar } from '../../cache';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 138,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.primary.main
    }
}));

const SoftTimeOff = (props) => {
    const classes = useStyles();

    const {
        closeDialog, 
        scheduleEndDate, 
        invalidDates, 
        setToast, 
        setShowToast,
        maxGreyout,
        schedulePeriodWeeks,
        softRequests,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,
    } = props;

    const user = userVar();
    const date = selectedDateVar();
    // const filterList = filterListVar();

    const [priorityChecked, setPriorityChecked] = useState(false);
    const [dateForRequest, setDateForRequest] = useState(date);
    const [disableHighPriority, setDisableHighPriority] = useState(false);
    const [highPriorityMessage, setHighPriorityMessage] = useState('');
    const [error, setError] = useState('');
    const [disableSave, setDisableSave] = useState(true);
    const minimumDate = add(new Date(scheduleEndDate), {days: 1});
        
    const [updatePreference] = useMutation(CREATE_SOFT_REQUEST, {
        update(cache, { data: { createOffPreference } }) {
            cache.modify({
                fields: {
                    offPreferences(existingOffPreferences = []) {
                        const newOffPreferenceRef = cache.writeFragment({
                            data: createOffPreference.offPreference,
                            fragment: gql`
                                fragment NewOffPreference on OffPreferenceNode {
                                    id
                                    date
                                    value
                                }
                            `
                        });
                        return [...existingOffPreferences, newOffPreferenceRef];
                    }
                }
            });
        },
        onCompleted(data) {
            setError('');
            setToast('New Soft Request');
            setShowToast(true);
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Unable to create soft time off request. Please check date and try again.');
            notifyDevelopers({variables: {message: "Error on CREATE_SOFT_REQUEST Mutation. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const handleSubmit = () => {    
        const newRequest = {
            employee: parseInt(user.id),
            date: dateForRequest.toISOString().slice(0, 10),
            value: priorityChecked ? 10.0 : 5.0
        };
        updatePreference({variables: {input: {...newRequest}}});
    };

    const checkInvalid = (date) => {
        const formatted = format(date, 'MM/dd/yyyy');
        return invalidDates.includes(formatted);
    };

    const checkRequests = (date) => {
        let firstDate = new Date(scheduleEndDate);
        let secondDate = add(firstDate, {days: 1, weeks: schedulePeriodWeeks});

        let dateToView = date;
        if (date < firstDate) {
            dateToView = add(new Date(scheduleEndDate), {days: 1});
        }

        //find schedule start and end dates that selected date is within
        while (!isWithinInterval(dateToView, {start: firstDate, end: secondDate})) {
            firstDate = sub(secondDate, {days: 1});
            secondDate = add(firstDate, {days: 1, weeks: schedulePeriodWeeks});
        }
        firstDate = add(firstDate, {days: 1});
        firstDate.setHours(0, 0, 0, 0);
        secondDate.setHours(23, 59, 59, 59);

        //check for current high and standard priority soft requests in window
        const softRequestsInWindow = softRequests.filter(request => {
            return (
                new Date(request.start) > firstDate && 
                new Date(request.start) < secondDate
            );
        });
        const highPriorityInWindow = softRequestsInWindow.filter(request => (
            request.highPriority
        ));

        //only allow one high priority request per schedule period
        if (highPriorityInWindow.length > 0) {
            setDisableHighPriority(true);
            setHighPriorityMessage('You can only have one high priority soft request per schedule period.');
        } else {
            setDisableHighPriority(false);
            setHighPriorityMessage('');
        }
        
        //check max soft requests per schedule period
        if (softRequestsInWindow.length <= maxGreyout) {
            setDisableSave(false);
            setError('');
        } else {
            setError('You have reached you maximum soft requests for this schedule period.');
            setDisableSave(true);
        }
    };

    const handleUpdateDate = (date) => {
        if(date && !isNaN(date.getTime())){
            const invalid = checkInvalid(date);
            if (!invalid && date >= new Date(scheduleEndDate)) {
                setDateForRequest(date);
                checkRequests(date);
            } else {
                setError('You must choose a date after the schedule ends that does not already have a request');
            }
        } else {
            setError('Invalid date');
        }
    };

    useEffect(() => {
        checkRequests(date);
    }, []);

    return (  
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction='column' spacing={2}>
                <Grid item container justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h2'>Soft Time Off</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label='close' color='secondary' size='small' onClick={closeDialog} >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={priorityChecked}
                                onChange={e => setPriorityChecked(e.target.checked)}
                                name="priorityChecked"
                                color={priorityChecked ? 'primary' : 'secondary'}
                                disabled={disableHighPriority}
                            />
                        }
                        label="Priority Request"
                    >
                    </FormControlLabel>
                </Grid>
                <Grid item style={{height: 15, marginTop: -15, marginBottom: 16}}>
                    {highPriorityMessage && 
                        <Typography variant='body2' className={classes.error}>
                            {highPriorityMessage}
                        </Typography>
                    }
                </Grid>
                <Grid item >
                    <InputLabel htmlFor='date-for-request'>
                        <Typography variant='h5'>Date:</Typography>
                    </InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant='inline'
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        id='date-for-request'
                        shouldDisableDate={checkInvalid}
                        minDate={minimumDate}
                        value={dateForRequest}
                        onChange={handleUpdateDate}
                        className={classes.input}
                    />
                </Grid>
                <Grid item style={{height: 40, marginTop: -15, marginBottom: 10}}>
                    {error && <Typography className={classes.error}>{error}</Typography>}
                </Grid>
                <Grid item container justifyContent='flex-end'>
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={handleSubmit}
                            disabled={disableSave}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};
 
export default SoftTimeOff;