import React, { useEffect, useState } from "react";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Grid,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  TextField,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, add, sub, addDays } from "date-fns";
import axios from "axios";
import {
  userVar,
  creatingScheduleVar,
  initialScheduleStartDateVar,
  newScheduleStartDateVar,
  notificationsVar,
  // initialScheduleStartTimeVar,
} from "../../cache";
import { useReactiveVar, useQuery, useMutation } from "@apollo/client";
import {
  GET_SCHEDULE_PERIODS,
  RELEASE_DRAFT,
  PUBLISH_SCHEDULE,
  GET_FLOAT_DETAILS,
  UPDATE_SCHEDULE_PERIOD,
  NOTIFY_DEVELOPERS,
  CREATE_SKELETON_FOR_MANAGERS,
  CREATE_SKELETON_FOR_ALL,
  RUN_SCHEDULE,
  RUN_FLOAT,
  OFFICES_WITH_CHILD_PARENT,
  RUN_SCHEDULE_DRY_RUN,
  RUN_FLOAT_DRY_RUN,
} from "../../api/gqlQueries";
import Steps from "./Steps";
import isSameDay from "date-fns/isSameDay";
import ErrorSnackBar from "../errors/errorSnackBar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 156,
  },
  smallButton: {
    width: 70,
  },
  otherButton: {
    width: 82,
  },
  input: {
    minWidth: 138,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  timeInput: {
    paddingRight: 8,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 138,
  },
}));

const Actions = ({ scheduleData }) => {
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const {
    schedulePublishedBufferWeeks,
    reviewWindowDays,
    numberDaysBeforePreferencesDue,
    timeOff,
    scheduleDuration,
    isManual,
  } = scheduleData;

  const currentUser = userVar();

  const creatingSchedule = useReactiveVar(creatingScheduleVar);
  const initialScheduleStartDate = initialScheduleStartDateVar();
  // const initialScheduleStartTime = initialScheduleStartTimeVar();
  const [activeStep, setActiveStep] = useState(
    creatingSchedule === true ? (currentUser.officeAllowsManual ? 2 : 1) : 0
  );
  const [publish, setPublish] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [draftStartDate, setDraftStartDate] = useState("");
  const [draftEndDate, setDraftEndDate] = useState("");
  const [draftReleased, setDraftReleased] = useState(false);
  const [draftSchedulePeriodId, setDraftSchedulePeriodId] = useState("");
  const [disableCreateSchedule, setDisableCreateSchedule] = useState(false);
  const [firstSchedule, setFirstSchedule] = useState(false);
  const [initialStartDate, setInitialStartDate] = useState(
    initialScheduleStartDate ? initialScheduleStartDate : new Date()
  );
  const [officeFloatStatus, SetOfficeFloatStatus] = useState();
  const [shiftBasedOffice, SetShiftBasedOffice] = useState();
  const [officeScheduleStatusChanged, SetOfficeScheduleStatusChanged] =
    useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [successSnackBarOpen, SetSuccessSnackbarOpen] = useState(false);
  // const [initialStartTime, setInitialStartTime] = useState(
  //   initialScheduleStartTime ? initialScheduleStartTime : "07:00"
  // );
  const [showPopupForStartDate, setShowPopupForStartDate] = useState(false);
  const [showLoader, SetShowLoader] = useState(false);
  const [scheduleDryRun, SetScheduleDryRun] = useState(false);

  const scheduleDurationWeeks = isNaN(parseInt(scheduleDuration))
    ? 4
    : parseInt(scheduleDuration);

  const { data, startPolling, stopPolling, refetch, loading } = useQuery(
    GET_SCHEDULE_PERIODS,
    {
      variables: { officeId: currentUser.office.id },
      fetchPolicy: "cache-and-network",
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_SCHEDULE_PERIODS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [parentChild, SetParentChild] = useState();

  const officeParentChild = useQuery(OFFICES_WITH_CHILD_PARENT, {
    onCompleted(d) {
      SetParentChild(d.offices);
    },
  });

  const [runSchedule] = useMutation(RUN_SCHEDULE, {
    onCompleted(d) {
      creatingScheduleVar(true);
      setStatus("STARTING");
      startPolling(5000);
    },
    onError(err) {
      console.log(err);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't run the schedule and are working hard to fix the error. Please refresh to try again."
      );
    },
  });

  const [runScheduleDryRun] = useMutation(RUN_SCHEDULE_DRY_RUN, {
    onCompleted(d) {
      creatingScheduleVar(false);
    },
    onError(err) {
      console.log(err);
      SetShowLoader(false);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't run the schedule and are working hard to fix the error. Please refresh to try again."
      );
    },
  });

  const [runFloat] = useMutation(RUN_FLOAT, {
    onCompleted(d) {
      console.log(d);
      creatingScheduleVar(true);
      setStatus("STARTING");
      startPolling(5000);
    },
  });

  const [runFloatDryRun] = useMutation(RUN_FLOAT_DRY_RUN, {
    onCompleted(d) {
      creatingScheduleVar(false);
    },
  });

  const [managerManualSchedulingDate, SetManagerManualSchedulingDate] =
    useState(new Date());
  const [employeeManualSchedulingDate, SetEmployeeManualSchedulingDate] =
    useState(new Date());

  const [manualSchedulingEnabled, SetManualSchedulingEnabled] = useState(false);

  const [
    createSkeletonForShifts,
    { loading: createSkeletonLoading, called: createSkeletonCalled },
  ] = useMutation(CREATE_SKELETON_FOR_MANAGERS, {
    onCompleted(d) {
      setSnackBarMessage("Manual Scheduling enabled");
      SetSuccessSnackbarOpen(true);
      SetManualSchedulingEnabled(true);
      refetch();
    },
    onError(e) {
      console.log(e);
    },
  });

  const [
    createSkeletonForEmployees,
    {
      loading: createSkeletonEmpLoading,
      called: createSkeletonEmpCalled,
      data: createSkeletonEmpData,
    },
  ] = useMutation(CREATE_SKELETON_FOR_ALL, {
    onCompleted(d) {
      setSnackBarMessage("Manual Scheduling enabled for employees");
      SetSuccessSnackbarOpen(true);
      SetManualSchedulingEnabled(true);
      refetch();
    },
    onError(e) {
      console.log(e);
    },
  });

  //when create skeleton is being executed disable create schedule button
  useEffect(() => {
    if (
      (createSkeletonCalled === true && createSkeletonLoading === true) ||
      (createSkeletonEmpCalled === true && createSkeletonEmpLoading === true)
    ) {
      setDisableCreateSchedule(true);
    } else {
      setDisableCreateSchedule(false);
    }
  }, [createSkeletonCalled && createSkeletonLoading]);

  useEffect(() => {
    SetShowLoader(false);
    SetScheduleDryRun(false);
  }, [notificationsVar()]);

  const getFloatDetails = useQuery(GET_FLOAT_DETAILS, {
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_FLOAT_DETAILS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // mutation to set office schedule status to accepting floats
  const [updateSchedulePeriod] = useMutation(UPDATE_SCHEDULE_PERIOD, {
    onCompleted(data) {
      SetOfficeScheduleStatusChanged(true);
      refetch();
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_SCHEDULE_PERIOD Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [publishSchedule] = useMutation(PUBLISH_SCHEDULE, {
    onCompleted(data) {
      handleNext();
      setPublish(false);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on PUBLISH_SCHEDULE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [releaseDraftSchedule] = useMutation(RELEASE_DRAFT, {
    onCompleted(data) {
      console.log(data);
      handleNext();
      setDraftReleased(true);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on RELEASE_DRAFT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  let mostRecent;
  if (data && data.schedulePeriods.length > 0) {
    const schedules = [
      ...data.schedulePeriods.filter(
        (e) =>
          e.status === "MANAGER_DRAFT" ||
          e.status === "DRAFT" ||
          e.status === "READY"
      ),
    ];
    mostRecent = schedules.sort(
      (a, b) => new Date(b.start) - new Date(a.start)
    )[0];
  }

  let scheduleEnd =
    mostRecent && !newScheduleStartDateVar()
      ? new Date(`${mostRecent.end}T12:00:00`)
      : initialStartDate;

  const nextScheduleStart = add(scheduleEnd, { days: 1 });
  const nextScheduleEnd = add(nextScheduleStart, {
    weeks: scheduleDurationWeeks,
  });

  let reviewUntil;
  let scheduleCreationDate;
  if (reviewWindowDays !== 0) {
    if (
      disableCreateSchedule &&
      mostRecent &&
      (mostRecent.status === "DRAFT" ||
        mostRecent.status === "MANAGER_DRAFT" ||
        !mostRecent.status === "PUBLISHED")
    ) {
      reviewUntil = sub(new Date(`${mostRecent.start}T12:00:00`), {
        weeks: schedulePublishedBufferWeeks,
      });
      scheduleCreationDate = sub(reviewUntil, { days: reviewWindowDays });
    } else {
      reviewUntil = sub(nextScheduleStart, {
        weeks: schedulePublishedBufferWeeks,
      });
      scheduleCreationDate = sub(reviewUntil, { days: reviewWindowDays });
    }
  } else {
    scheduleCreationDate = sub(nextScheduleStart, {
      weeks: schedulePublishedBufferWeeks,
    });
  }

  const preferencesDue = sub(scheduleCreationDate, {
    days: numberDaysBeforePreferencesDue,
  });

  const pendingTimeOffRequests = timeOff.filter(
    (request) =>
      request.approvedby === null &&
      request.deniedText === null &&
      request.userCancelled === false &&
      (new Date(request.firstday) > nextScheduleStart ||
        isSameDay(new Date(request.firstday), nextScheduleStart)) &&
      (new Date(request.firstday) < nextScheduleEnd ||
        isSameDay(new Date(request.firstday), nextScheduleEnd))
  );

  useEffect(() => {
    if (data && data.schedulePeriods.length > 0) {
      const newStatus = data.offices[0].schedulingStatus;
      const schedulePeriods = [...data.schedulePeriods];
      const mostRecent = schedulePeriods.sort(
        (a, b) => new Date(b.start) - new Date(a.start)
      )[0];
      const schedulesinreadystatus = data.schedulePeriods.filter(
        (e) => e.status === "READY" || e.status === "OPEN"
      );
      const schedulesinopenstatus = data.schedulePeriods.filter(
        (e) => e.status === "OPEN"
      );
      if (
        mostRecent.status === "MANAGER_DRAFT" ||
        mostRecent.status === "DRAFT" ||
        mostRecent.status === "PUBLISHED"
      ) {
        SetEmployeeManualSchedulingDate(mostRecent.end);
        SetManagerManualSchedulingDate(mostRecent.end);
      } else {
        if (schedulesinreadystatus.length > 1) {
          schedulesinreadystatus.sort(
            (a, b) => new Date(b.start) < new Date(a.start)
          );
          SetManagerManualSchedulingDate(schedulesinreadystatus[0].end);
        } else if (schedulesinreadystatus.length === 1) {
          SetManagerManualSchedulingDate(schedulesinreadystatus[0].end);
        } else if (schedulesinreadystatus.length === 0) {
          SetManagerManualSchedulingDate(new Date());
        }
        if (schedulesinopenstatus.length > 1) {
          schedulesinopenstatus.sort((a, b) => b.start < a.start);
          SetEmployeeManualSchedulingDate(schedulesinopenstatus[0].end);
        } else if (schedulesinopenstatus.length === 1) {
          SetEmployeeManualSchedulingDate(schedulesinopenstatus[0].end);
        } else if (schedulesinopenstatus.length === 0) {
          SetEmployeeManualSchedulingDate(new Date());
        }
      }

      if (newStatus === "ERROR") {
        if (
          (newStatus === status || status === "STARTING") &&
          creatingSchedule === true
        ) {
          setError("");
          setStatus("STARTING");
        } else {
          currentUser.officeAllowsManual ? setActiveStep(2) : setActiveStep(1);
          setStatus(newStatus);
          stopPolling();
          setError(
            "There has been an error creating the schedule. We are working hard to fix it and will notify you when you can create a schedule."
          );
          setDisableCreateSchedule(true);
          creatingScheduleVar(false);
        }
      } else if (
        (!mostRecent.status === "DRAFT" ||
          !mostRecent.status === "MANGER_DRAFT") &&
        !mostRecent.status === "PUBLISHED" &&
        newStatus === "COMPLETE"
      ) {
        currentUser.officeAllowsManual ? setActiveStep(3) : setActiveStep(2);
        creatingScheduleVar(false);
        setDisableCreateSchedule(true);
        stopPolling();
        setDraftReleased(true);
        setDraftStartDate(`${mostRecent.start}T12:00:00`);
        setDraftEndDate(`${mostRecent.end}T23:00:00`);
        setDraftSchedulePeriodId(mostRecent.id);
        setError("");
        setStatus(newStatus);
      } else if (
        (mostRecent.status === "DRAFT" ||
          mostRecent.status === "MANAGER_DRAFT") &&
        newStatus === "COMPLETE"
      ) {
        currentUser.officeAllowsManual ? setActiveStep(5) : setActiveStep(4);
        creatingScheduleVar(false);
        setDisableCreateSchedule(true);
        setDraftReleased(true);
        setDraftStartDate(`${mostRecent.start}T12:00:00`);
        setDraftEndDate(`${mostRecent.end}T23:00:00`);
        setDraftSchedulePeriodId(mostRecent.id);
        stopPolling();
        setError("");
        setStatus(newStatus);
      } else if (
        mostRecent.status === "PUBLISHED" &&
        newStatus === "COMPLETE"
      ) {
        setActiveStep(0);
        creatingScheduleVar(false);
        setDisableCreateSchedule(false);
        stopPolling();
        setError("");
        setStatus(newStatus);
      } else if (newStatus === "COMPLETE") {
        if (
          (newStatus === status || status === "STARTING") &&
          creatingSchedule
        ) {
          setError("");
          setStatus("STARTING");
        }
      } else if (newStatus === "RESCHEDULING") {
        setError("");
        setStatus(newStatus);
        currentUser.officeAllowsManual ? setActiveStep(3) : setActiveStep(2);
        creatingScheduleVar(false);
        setDisableCreateSchedule(true);
        setDraftReleased(true);
        setDraftStartDate(`${mostRecent.start}T12:00:00`);
        setDraftEndDate(`${mostRecent.end}T23:00:00`);
        setDraftSchedulePeriodId(mostRecent.id);
      } else if (
        newStatus !== "COMPLETE" &&
        newStatus !== "ERROR" &&
        scheduleDryRun === false
      ) {
        creatingScheduleVar(true);
        currentUser.officeAllowsManual ? setActiveStep(2) : setActiveStep(1);
        setDisableCreateSchedule(true);
        startPolling(5000);
        setError("");
        setStatus(newStatus);
      }
      setShowPopupForStartDate(false);
      setFirstSchedule(false);
    } else if (
      data &&
      data.schedulePeriods.length === 0 &&
      creatingSchedule === true
    ) {
      const newStatus = data.offices[0].schedulingStatus;
      if (newStatus === "COMPLETE") {
        if (
          (newStatus === status || status === "STARTING") &&
          creatingSchedule === true
        ) {
          setError("");
          setStatus("STARTING");
        } else {
          stopPolling();
          currentUser.officeAllowsManual ? setActiveStep(3) : setActiveStep(2);
          setStatus(newStatus);
          creatingScheduleVar(false);
        }
      } else if (newStatus === "ERROR") {
        currentUser.officeAllowsManual ? setActiveStep(2) : setActiveStep(1);
        setStatus(newStatus);
        stopPolling();
        setError(
          "There has been an error creating the schedule. We are working hard to fix it and will notify you when you can create a schedule."
        );
        setDisableCreateSchedule(true);
        creatingScheduleVar(false);
      } else if(scheduleDryRun!=true){
        creatingScheduleVar(true);
        currentUser.officeAllowsManual ? setActiveStep(2) : setActiveStep(1);
        setDisableCreateSchedule(true);
        startPolling(5000);
        setError("");
        setStatus(newStatus);
      }
    } else if (data && data.schedulePeriods.length === 0) {
      if (creatingSchedule === false && !initialScheduleStartDate) {
        setShowPopupForStartDate(true);
        setFirstSchedule(true);
        stopPolling();
      }
    }
  }, [data]);

  useEffect(() => {
    startPolling(5000);
  }, []);

  useEffect(() => {
    let officeDetails =
      !getFloatDetails.loading &&
      getFloatDetails.data.offices.find((e) => e.id === currentUser.office.id);
    if (officeDetails) {
      SetOfficeFloatStatus(officeDetails.floatStatus);
      SetShiftBasedOffice(officeDetails.shiftBasedDisplay);
    }
  }, [!getFloatDetails.loading]);

  let steps = [];
  if (officeFloatStatus) {
    steps = ["Notify Employees to Update Preferences", "Create", "Complete"];
  } else if (reviewUntil) {
    if (currentUser.officeAllowsManual) {
      steps = [
        "Notify Employees to Update Preferences",
        "Enable Manual Scheduling",
        "Create",
        "Management Review",
        "Release for Staff Review",
        "Review Window",
        "Publish",
      ];
    } else {
      steps = [
        "Notify Employees to Update Preferences",
        "Create",
        "Management Review",
        "Release for Staff Review",
        "Review Window",
        "Publish",
      ];
    }
  } else {
    if (currentUser.officeAllowsManual) {
      steps = [
        "Notify Employees to Update Preferences",
        "Enable Manual Scheduling",
        "Create",
        "Management Review",
        "Publish",
      ];
    } else {
      steps = [
        "Notify Employees to Update Preferences",
        "Create",
        "Management Review",
        "Publish",
      ];
    }
  }

  const openDialog = () => {
    setPublish(true);
  };

  const handleCancel = () => {
    setPublish(false);
  };

  const handleReleaseDraft = () => {
    releaseDraftSchedule({
      variables: {
        schedulePeriodId: draftSchedulePeriodId,
      },
    });
  };

  const handlePublish = () => {
    setDraftStartDate("");
    setDraftEndDate("");
    publishSchedule({
      variables: {
        schedulePeriodId: draftSchedulePeriodId,
        approver: parseInt(currentUser.id),
      },
    });
  };

  const handleCreateSkeleton = () => {
    SetManualSchedulingEnabled(true);
    if (data.schedulePeriods.length > 0) {
      createSkeletonForShifts({
        variables: {
          office: parseInt(currentUser.office.id),
          thru: format(new Date(managerManualSchedulingDate), "yyyy-MM-dd"),
        },
      });
    } else {
      createSkeletonForShifts({
        variables: {
          office: parseInt(currentUser.office.id),
          begin: format(new Date(initialStartDate), "yyyy-MM-dd"),
          thru: format(new Date(managerManualSchedulingDate), "yyyy-MM-dd"),
        },
      });
    }
  };
  const generateSchedule = () => {
    const dateString = format(initialStartDate, "yyyy-MM-dd");
    //  + initialStartTime;
    // const newScheduleDate = new Date(dateString).toISOString();
    const newStartDate = Boolean(newScheduleStartDateVar() || firstSchedule);
    const environment = process.env.NODE_ENV;
    const baseURL = window.location.origin;
    let backend = "";
    if (environment === "development" || baseURL.includes("amplify")) {
      backend = "development";
    } else {
      backend = "production";
    }
    currentUser.office.floatStatus
      ? runFloat({
          variables: {
            office: parseInt(currentUser.office.id),
            startDate: newStartDate
              ? dateString
              : format(new Date(), "yyyy-MM-dd"),
          },
        })
      : runSchedule({
          variables: {
            office: parseInt(currentUser.office.id),
            startDate:
              data.schedulePeriods.length === 0
                ? newStartDate
                  ? dateString
                  : format(new Date(), "yyyy-MM-dd")
                : null,
          },
        });
  };

  const ChangeOfficeToAcceptingFloats = () => {
    updateSchedulePeriod({
      variables: {
        id: parseInt(data.schedulePeriods[0].id),
        input: { status: "REQUESTING_FLOATS" },
      },
    });
  };

  const getContent = (index) => {
    return (
      <Steps
        index={index}
        reviewUntil={reviewUntil}
        preferencesDue={preferencesDue}
        draftStartDate={draftStartDate}
        draftEndDate={draftEndDate}
        pendingTimeOffRequests={pendingTimeOffRequests}
        draftReleased={draftReleased}
        generateSchedule={generateSchedule}
        handleReleaseDraft={handleReleaseDraft}
        officeFloatStatus={officeFloatStatus}
        shiftBasedOffice={shiftBasedOffice}
        ChangeOfficeToAcceptingFloats={ChangeOfficeToAcceptingFloats}
        officeScheduleStatusChanged={officeScheduleStatusChanged}
        disableCreateSchedule={disableCreateSchedule}
        error={error}
        status={status}
        handleCreateSkeleton={handleCreateSkeleton}
        SetManagerManualSchedulingDate={SetManagerManualSchedulingDate}
        managerManualSchedulingDate={managerManualSchedulingDate}
        SetEmployeeManualSchedulingDate={SetEmployeeManualSchedulingDate}
        employeeManualSchedulingDate={employeeManualSchedulingDate}
        manualSchedulingEnabled={manualSchedulingEnabled}
        SetManualSchedulingEnabled={SetManualSchedulingEnabled}
        schedulePeriods={data.schedulePeriods}
        updateSchedulePeriod={updateSchedulePeriod}
        parentChild={parentChild}
        createSkeletonLoading={createSkeletonLoading}
        createSkeletonCalled={createSkeletonCalled}
        createSkeletonForAll={createSkeletonForEmployees}
        scheduleGenerationLoading={loading}
        scheduleDryRun={scheduleDryRun}
      />
    );
  };

  const handleNext = () => {
    const step = activeStep;
    setActiveStep(step + 1);
  };

  const handleBack = () => {
    const step = activeStep;
    setActiveStep(step - 1);
  };

  const checkNextDisabled = () => {
    if (reviewUntil) {
      if (currentUser.officeAllowsManual) {
        if (activeStep === 2 && !draftStartDate && !draftEndDate) {
          return true;
        } else if (creatingSchedule === true) {
          return true;
        } else if (activeStep === 4 && !draftReleased) {
          return true;
        } else {
          return false;
        }
      } else {
        if (activeStep === 1 && !draftStartDate && !draftEndDate) {
          return true;
        } else if (creatingSchedule === true) {
          return true;
        } else if (activeStep === 3 && !draftReleased) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (currentUser.officeAllowsManual) {
        if (activeStep === 2 && !draftStartDate && !draftEndDate) {
          return true;
        } else if (creatingSchedule === true) {
          return true;
        } else {
          return false;
        }
      } else {
        if (activeStep === 1 && !draftStartDate && !draftEndDate) {
          return true;
        } else if (creatingSchedule === true) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const handleUpdateStartDate = (date) => {
    if (date && !isNaN(date.getTime())) {
      setInitialStartDate(date);
    }
  };

  let history = useHistory();

  if (getFloatDetails.loading || loading || officeParentChild.loading) {
    return <CircularProgress color="primary" />;
  } else if (getFloatDetails.error) {
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    return (
      <>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
        <Snackbar
          open={successSnackBarOpen}
          autoHideDuration={2000}
          onClose={() => {
            SetSuccessSnackbarOpen(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              SetSuccessSnackbarOpen(false);
              setSnackBarMessage("");
            }}
            severity="success"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Typography variant="h5">{currentUser.office.name}</Typography>
        {nextScheduleStart &&
          nextScheduleEnd &&
          // !disableCreateSchedule &&
          creatingSchedule === true && (
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: 20 }}
            >
              <Grid item>
                <Typography variant="h6">
                  Next Schedule to Create:
                  {" " + format(nextScheduleStart, "dd MMM yyyy") + " - "}
                  {format(nextScheduleEnd, "dd MMM yyyy")}
                </Typography>
              </Grid>
              {data.schedulePeriods.length === 0 && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setShowPopupForStartDate(true)}
                    disabled={
                      creatingSchedule === true ||
                      draftReleased ||
                      disableCreateSchedule
                    }
                  >
                    Edit Dates
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item>
            <Typography variant="h6">
              Check if Next Schedule parameters are set and Schedule is ready to
              be generated
            </Typography>
            <Button
              color="primary"
              onClick={() => {
                creatingScheduleVar(false);
                SetScheduleDryRun(true);
                const dateString = format(initialStartDate, "yyyy-MM-dd");
                const newStartDate = Boolean(
                  newScheduleStartDateVar() || firstSchedule
                );
                currentUser.office.floatStatus
                  ? runFloatDryRun({
                      variables: {
                        office: parseInt(currentUser.office.id),
                        dryRun: true,
                        startDate: newStartDate
                          ? dateString
                          : format(new Date(), "yyyy-MM-dd"),
                      },
                    })
                  : runScheduleDryRun({
                      variables: {
                        office: parseInt(currentUser.office.id),
                        dryRun: true,
                        startDate:
                          data.schedulePeriods.length === 0
                            ? newStartDate
                              ? dateString
                              : format(new Date(), "yyyy-MM-dd")
                            : null,
                      },
                    });
                SetShowLoader(true);
              }}
              disabled={showLoader}
            >
              Check Parameters for Next Schedule
            </Button>
            <br />
            {showLoader ? (
              <CircularProgress color="primary" />
            ) : (
              <Button
                color="secondary"
                onClick={() => history.push("/Notifications")}
              >
                View more information about the schedule readiness here
              </Button>
            )}
          </Grid>
        </Grid>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.length > 0 &&
            steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <Typography variant="h5">{label}</Typography>
                </StepLabel>
                <StepContent>
                  {getContent(index)}
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: 8, marginBottom: 8 }}
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        disabled={activeStep === 0 || creatingSchedule === true}
                        onClick={handleBack}
                        className={classes.smallButton}
                      >
                        Previous
                      </Button>
                    </Grid>
                    <Grid item>
                      {activeStep === steps.length - 1 ? (
                        officeFloatStatus ? (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setActiveStep(0);
                              setDisableCreateSchedule(false);
                            }}
                            className={classes.smallButton}
                          >
                            Finish
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={openDialog}
                            className={classes.smallButton}
                            disabled={
                              creatingSchedule === true ||
                              (!draftStartDate && !draftEndDate)
                            }
                          >
                            Publish
                          </Button>
                        )
                      ) : (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={handleNext}
                          className={classes.smallButton}
                          disabled={checkNextDisabled()}
                        >
                          Next
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            ))}
        </Stepper>
        {((currentUser.officeAllowsManual && activeStep === 7 && reviewUntil) ||
          (!currentUser.officeAllowsManual &&
            activeStep === 6 &&
            reviewUntil) ||
          (currentUser.officeAllowsManual &&
            activeStep === 5 &&
            !reviewUntil) ||
          (!currentUser.officeAllowsManual &&
            activeStep === 4 &&
            !reviewUntil)) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setActiveStep(0);
              setDisableCreateSchedule(false);
            }}
          >
            Create Next Schedule
          </Button>
        )}
        <Dialog open={publish} onClose={handleCancel} style={{ padding: 30 }}>
          <DialogContent>
            <Typography variant="h5">
              Are you sure you want to publish schedule?
            </Typography>
            <DialogContentText style={{ marginTop: 16 }}>
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: 30 }}>
            <Button
              onClick={handleCancel}
              color="primary"
              variant="outlined"
              className={classes.otherButton}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePublish}
              color="primary"
              variant="contained"
              className={classes.otherButton}
              style={{ marginLeft: 16 }}
            >
              Publish
            </Button>
          </DialogActions>
        </Dialog>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Dialog
            open={showPopupForStartDate}
            onClose={() => setShowPopupForStartDate(false)}
            fullWidth
            maxWidth="xs"
          >
            <DialogContent style={{ padding: 30 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h3">Schedule Start Date</Typography>
                </Grid>
                <Grid item>
                  {/* {firstSchedule ? (
                    <Typography variant="h5">
                      To get started creating your first schedule, enter the
                      start date.
                    </Typography>
                  ) : (
                    <Typography variant="h5">
                      Edit the next schedule start date.
                    </Typography>
                  )} */}
                </Grid>
                <Grid item>
                  <InputLabel htmlFor="date">
                    <Typography variant="h5">Date:</Typography>
                  </InputLabel>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date"
                    minDate={new Date()}
                    minDateMessage="Date should not be before today"
                    value={initialStartDate}
                    onChange={handleUpdateStartDate}
                    className={classes.input}
                  />
                </Grid>
                {/* <Grid item>
                  <InputLabel htmlFor="time">
                    <Typography variant="h5">Time:</Typography>
                  </InputLabel>
                  <TextField
                    type="time"
                    id="time"
                    variant="outlined"
                    value={initialStartTime}
                    className={classes.timeInput}
                    onChange={(e) => setInitialStartTime(e.target.value)}
                  />
                </Grid> */}
                <Grid container justifyContent="flex-end" spacing={2}>
                  {!firstSchedule && (
                    <Grid item>
                      <Button
                        onClick={() => {
                          setShowPopupForStartDate(false);
                          newScheduleStartDateVar(false);
                        }}
                        color="primary"
                        variant="outlined"
                        className={classes.otherButton}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      onClick={() => {
                        setShowPopupForStartDate(false);
                        initialScheduleStartDateVar(initialStartDate);
                        // initialScheduleStartTimeVar(initialStartTime);
                        newScheduleStartDateVar(true);
                      }}
                      color="primary"
                      variant="contained"
                      className={classes.otherButton}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </MuiPickersUtilsProvider>
      </>
    );
  }
};

export default Actions;
