import React from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import moon from '../../../assets/quizImages/moon.png';
import sun from '../../../assets/quizImages/sun.png';
import swing from '../../../assets/quizImages/swing.png';

const useStyles = makeStyles(() => ({
    tile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    clicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    },
    disabled: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        // cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    },
    coloredText: {
        color: '#8CADE1',
        textAlign: 'center'
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)'
    }
}));

const ShiftsDislike = ({values, setFieldValue}) => {
    const classes = useStyles();

    const handleChange = (fieldName) => {
        console.log(values[fieldName])
        if (values[fieldName] === 0) {
            setFieldValue(fieldName, -1);
        } else if (values[fieldName] > 0) {
            return;
        } else {
            setFieldValue(fieldName, 0)
        }
    };

    return (  
        <Grid container item direction='column' alignItems='center' spacing={5} style={{padding: 25}}>
            <Grid item>
                <Typography style={{fontSize: 24}}>Are there any shifts you really don't like working?</Typography>
                <Typography style={{fontSize: 18}} className={classes.coloredText}>
                    The shifts you like to work are disabled.
                </Typography>
            </Grid>
            <Grid 
                item 
                container 
                justifyContent='center' 
                style={{padding: 50, paddingTop: 16, paddingBottom: 0}}
            >
                <Grid 
                    component={Paper} 
                    // className={values.day < 0 ? classes.clicked : classes.tile} 
                    className={clsx(
                        values.day < 0 && classes.clicked, 
                        values.day === 0 && classes.tile, 
                        values.day > 0 && classes.disabled
                    )}
                    onClick={() => handleChange('day')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography className={clsx(values.day > 0 && classes.disabledText)}>
                        Day Shift
                    </Typography>
                    <img 
                        alt='day shift' 
                        src={sun} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    // className={values.night < 0 ? classes.clicked : classes.tile} 
                    className={clsx(
                        values.night < 0 && classes.clicked, 
                        values.night === 0 && classes.tile, 
                        values.night > 0 && classes.disabled
                    )}
                    onClick={() => handleChange('night')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography className={clsx(values.night > 0 && classes.disabledText)}>
                        Night Shift
                    </Typography>
                    <img 
                        alt='night shift' 
                        src={moon} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    // className={values.swing < 0 ? classes.clicked : classes.tile} 
                    className={clsx(
                        values.swing < 0 && classes.clicked, 
                        values.swing === 0 && classes.tile, 
                        values.swing > 0 && classes.disabled
                    )}
                    onClick={() => handleChange('swing')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography className={clsx(values.swing > 0 && classes.disabledText)}>
                        Swing Shift
                    </Typography>
                    <img 
                        alt='swing shift' 
                        src={swing} 
                        style={{position: 'absolute', bottom: 10, left: 32, height: 125}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default ShiftsDislike;