import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    disabled: {
        color: 'rgb(158,158,158)'
    }
}))

const SentimentNeutralIcon = ({disabled=false}) => {
    const classes = useStyles();

    return (
        <svg className={clsx('MuiSvgIcon-root', disabled && classes.disabled)} focusable='false' aria-hidden='true' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M9 15.5h6v1H9v-1z"/>
            <circle cx="15.5" cy="9.5" r="1.5"/>
            <circle cx="8.5" cy="9.5" r="1.5"/>
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
        </svg>
    )
}
 
export default SentimentNeutralIcon;
