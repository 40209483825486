import React from "react";
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  LinearProgress,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { add, format } from "date-fns";
import {
  creatingScheduleVar,
  schedulePeriodToUpdateAfterUpdateSlacks,
} from "../../cache";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { userVar } from "../../cache";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 250,
  },
  error: {
    color: theme.palette.primary.main,
  },
}));

const Steps = (props) => {
  const {
    index,
    reviewUntil,
    preferencesDue,
    draftStartDate,
    draftEndDate,
    pendingTimeOffRequests,
    draftReleased,
    generateSchedule,
    handleReleaseDraft,
    disableCreateSchedule,
    error,
    status,
    officeFloatStatus,
    shiftBasedOffice,
    ChangeOfficeToAcceptingFloats,
    officeScheduleStatusChanged,
    handleCreateSkeleton,
    SetManagerManualSchedulingDate,
    managerManualSchedulingDate,
    employeeManualSchedulingDate,
    SetEmployeeManualSchedulingDate,
    schedulePeriods,
    updateSchedulePeriod,
    parentChild,
    createSkeletonLoading,
    createSkeletonCalled,
    createSkeletonForAll,
    scheduleGenerationLoading,
    scheduleDryRun
  } = props;

  let history = useHistory();

  const user = userVar();

  const classes = useStyles();

  const creatingSchedule = creatingScheduleVar();

  const mapStatusToValue = {
    STARTING: 10,
    READING: 25,
    GENERATING: 50,
    OPTIMIZING: 75,
    COMPLETED: 95,
  };

  const mappedToExplanations = {
    STARTING: "starting the algorithm",
    READING: "reading employee preferences and unit constraints",
    GENERATING: "generating the schedule",
    OPTIMIZING: "optimizing the schedule",
    COMPLETED: "wrapping up",
  };

  const allChildOffices =
    parentChild &&
    parentChild.length > 0 &&
    parentChild.filter(
      (e) => e.parent != null && e.parent.id === user.office.id
    );

  const getContentReviewWindowTrue = () => {
    if (user.officeAllowsManual) {
      switch (index) {
        case 0:
          return (
            <Grid container direction="column" spacing={2}>
              {preferencesDue && (
                <>
                  <Grid item>
                    <Typography>
                      Preferences due: {format(preferencesDue, "dd MMM yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {preferencesDue > new Date() ? (
                      <Typography>
                        All employees will be notified to update their
                        preferences.
                      </Typography>
                    ) : (
                      <Typography>
                        All employees have been notified to update their
                        preferences.
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography>
                  Pending time off requests for next schedule period:{" "}
                  <b>{pendingTimeOffRequests.length}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Requests"
                  className={classes.button}
                >
                  Time Off Requests
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid container spacing={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Typography>Manual Scheduling for Managers</Typography>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <DatePicker
                        placeholder="MM/DD/YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        minDate={managerManualSchedulingDate}
                        value={managerManualSchedulingDate}
                        autoOk={true}
                        onChange={(e) => SetManagerManualSchedulingDate(e)}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleCreateSkeleton}
                    disabled={createSkeletonCalled && createSkeletonLoading}
                  >
                    {createSkeletonCalled && createSkeletonLoading && (
                      <CircularProgress color="primary" />
                    )}
                    Enable
                  </Button>
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: 20 }}
                container
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <Typography>Manual Scheduling for Employees</Typography>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <DatePicker
                        placeholder="MM/DD/YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        minDate={employeeManualSchedulingDate}
                        maxDate={managerManualSchedulingDate}
                        value={employeeManualSchedulingDate}
                        autoOk={true}
                        onChange={(e) => SetEmployeeManualSchedulingDate(e)}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={
                      !schedulePeriods.find((e) => e.status === "READY")
                    }
                    onClick={() =>
                      createSkeletonForAll({
                        variables: {
                          office: parseInt(user.office.id),
                          thru: format(
                            employeeManualSchedulingDate,
                            "yyyy-MM-dd"
                          ),
                        },
                      })
                    }
                  >
                    Enable
                  </Button>
                </Grid>
                <Grid item>
                  {allChildOffices.length > 0 && (
                    <Typography variant="body2">
                      Note: All Child offices of <b>{user.office.name}</b> will
                      be open to manual scheduling for the above dates
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  Schedules for the following offices will be created:{" "}
                </Typography>
                {allChildOffices && allChildOffices.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Office Name</TableCell>
                        <TableCell>Schedule Start Date</TableCell>
                        <TableCell>Schedule End Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allChildOffices.map((e, index) => {
                        let officeSchedulePeriods =
                          e.scheduleperiodSet &&
                          e.scheduleperiodSet.filter(
                            (e) =>
                              e.status === "PUBLISHED" ||
                              e.status === "MANAGER_DRAFT" ||
                              e.status === "DRAFT"
                          );
                        officeSchedulePeriods &&
                          officeSchedulePeriods.length > 0 &&
                          officeSchedulePeriods.sort(
                            (a, b) => new Date(b.start) - new Date(a.start)
                          );
                        return (
                          <TableRow key={index}>
                            <TableCell>{e.name}</TableCell>
                            <TableCell>
                              {officeSchedulePeriods &&
                              officeSchedulePeriods.length > 0
                                ? format(
                                    add(
                                      new Date(officeSchedulePeriods[0].end),
                                      {
                                        days: 1,
                                      }
                                    ),
                                    "MM-dd-yyyy"
                                  )
                                : format(new Date(), "MM-dd-yyyy")}
                            </TableCell>
                            <TableCell>
                              {officeSchedulePeriods &&
                              officeSchedulePeriods.length > 0
                                ? format(
                                    add(
                                      new Date(officeSchedulePeriods[0].end),
                                      {
                                        weeks: parseInt(e.scheduleDuration),
                                      }
                                    ),
                                    "MM-dd-yyyy"
                                  )
                                : format(
                                    add(new Date(), {
                                      weeks: parseInt(e.scheduleDuration),
                                    }),
                                    "MM-dd-yyyy"
                                  )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}

                <Button
                  variant="contained"
                  onClick={generateSchedule}
                  color="primary"
                  disabled={
                    creatingSchedule === true ||
                    disableCreateSchedule ||
                    scheduleGenerationLoading
                  }
                  style={{ marginTop: 8 }}
                  className={classes.button}
                >
                  Create Schedule
                </Button>
              </Grid>
              {creatingSchedule === true && scheduleDryRun === false &&
              status !== "ERROR"? (
                <>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography>Creating Schedule</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={
                        mapStatusToValue[status] ? mapStatusToValue[status] : 0
                      }
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Right now we are{" "}
                      {mappedToExplanations[status]
                        ? mappedToExplanations[status]
                        : "gathering data"}
                      .
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 450 }}>
                    <Typography>
                      You can continue working while the schedule is being
                      generated and you will be notified when it finishes.
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {error && (
                <Grid item>
                  <Typography className={classes.error}>{error}</Typography>
                  <Button
                    color="primary"
                    onClick={() => history.push("/Notifications")}
                  >
                    View Error Details
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        case 3:
          if (draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    A schedule has been created for
                    <b>
                      {format(new Date(draftStartDate), " dd MMM yyyy")} -
                      {format(new Date(draftEndDate), " dd MMM yyyy")}
                    </b>
                    .
                  </Typography>
                </Grid>
                <Grid item>
                  {!officeFloatStatus && shiftBasedOffice && (
                    <>
                      <Typography>
                        Update schedule status to accept floats in draft
                        schedule state (optional)
                      </Typography>
                      <br />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={ChangeOfficeToAcceptingFloats}
                        disabled={officeScheduleStatusChanged && true}
                      >
                        {officeScheduleStatusChanged
                          ? "Schedule Status Changed to Accepting Floats in Draft"
                          : "Accept Floats in Draft"}
                      </Button>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Typography>
                    View the draft schedule and make updates.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/Landing/${draftStartDate}/draft`}
                    className={classes.button}
                  >
                    View Schedule
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>No draft schedule to review at this time.</Typography>
            );
          }
        case 4:
          if (!draftReleased && draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography style={{ width: 400 }}>
                    To release the draft schedule for your staff to review,
                    click the Release Draft button below.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReleaseDraft}
                    className={classes.button}
                  >
                    Release Draft
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>
                {draftReleased
                  ? "You have already released the draft schedule."
                  : "No draft schedule to release at this time."}
              </Typography>
            );
          }
        case 5:
          if (draftReleased && draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    The schedule for
                    <b>
                      {format(new Date(draftStartDate), " dd MMM yyyy")} -
                      {format(new Date(draftEndDate), " dd MMM yyyy")}
                    </b>{" "}
                    will be open for review until
                    <b>{format(reviewUntil, " dd MMM yyyy")}</b>.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/Landing/${draftStartDate}/draft`}
                    className={classes.button}
                  >
                    View Schedule
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>No draft schedule to review at this time.</Typography>
            );
          }
        case 6:
          if (draftStartDate && draftEndDate) {
            return (
              <Typography>
                Schedule to publish:
                <b>
                  {format(new Date(draftStartDate), " dd MMM yyyy")} -
                  {format(new Date(draftEndDate), " dd MMM yyyy")}
                </b>
              </Typography>
            );
          } else {
            return (
              <Typography>No schedule to publish at this time.</Typography>
            );
          }
      }
    } else {
      switch (index) {
        case 0:
          return (
            <Grid container direction="column" spacing={2}>
              {preferencesDue && (
                <>
                  <Grid item>
                    <Typography>
                      Preferences due: {format(preferencesDue, "dd MMM yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {preferencesDue > new Date() ? (
                      <Typography>
                        All employees will be notified to update their
                        preferences.
                      </Typography>
                    ) : (
                      <Typography>
                        All employees have been notified to update their
                        preferences.
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography>
                  Pending time off requests for next schedule period:{" "}
                  <b>{pendingTimeOffRequests.length}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Requests"
                  className={classes.button}
                >
                  Time Off Requests
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  Schedules for the following offices will be created:{" "}
                </Typography>
                {allChildOffices.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Office Name</TableCell>
                        <TableCell>Schedule Start Date</TableCell>
                        <TableCell>Schedule End Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allChildOffices.map((e) => {
                        console.log(e.scheduleperiodSet);
                        let officeSchedulePeriods =
                          e.scheduleperiodSet &&
                          e.scheduleperiodSet.filter(
                            (e) => e.status === "PUBLISHED"
                          );
                        officeSchedulePeriods &&
                          officeSchedulePeriods.length > 0 &&
                          officeSchedulePeriods.sort(
                            (a, b) => new Date(b.start) - new Date(a.start)
                          );
                        return (
                          <TableRow>
                            <TableCell>{e.name}</TableCell>
                            <TableCell>
                              {officeSchedulePeriods &&
                              officeSchedulePeriods.length > 0
                                ? format(
                                    add(
                                      new Date(officeSchedulePeriods[0].end),
                                      {
                                        days: 1,
                                      }
                                    ),
                                    "MM-dd-yyyy"
                                  )
                                : format(new Date(), "MM-dd-yyyy")}
                            </TableCell>
                            <TableCell>
                              {officeSchedulePeriods &&
                              officeSchedulePeriods.length > 0
                                ? format(
                                    add(
                                      new Date(officeSchedulePeriods[0].end),
                                      {
                                        weeks: parseInt(e.scheduleDuration),
                                      }
                                    ),
                                    "MM-dd-yyyy"
                                  )
                                : format(
                                    add(new Date(), {
                                      weeks: parseInt(e.scheduleDuration),
                                    }),
                                    "MM-dd-yyyy"
                                  )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}

                <Button
                  variant="contained"
                  onClick={generateSchedule}
                  color="primary"
                  disabled={creatingSchedule === true || disableCreateSchedule}
                  style={{ marginTop: 8 }}
                  className={classes.button}
                >
                  Create Schedule
                </Button>
              </Grid>
              {creatingSchedule === true && scheduleDryRun === false && status !== "ERROR"? (
                <>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography>Creating Schedule</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={
                        mapStatusToValue[status] ? mapStatusToValue[status] : 0
                      }
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Right now we are{" "}
                      {mappedToExplanations[status]
                        ? mappedToExplanations[status]
                        : "gathering data"}
                      .
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 450 }}>
                    <Typography>
                      You can continue working while the schedule is being
                      generated and you will be notified when it finishes.
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {error && (
                <Grid item>
                  <Typography className={classes.error}>{error}</Typography>
                  <Button
                    color="primary"
                    onClick={() => history.push("/Notifications")}
                  >
                    View Error Details
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        case 2:
          if (draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    A schedule has been created for
                    <b>
                      {format(new Date(draftStartDate), " dd MMM yyyy")} -
                      {format(new Date(draftEndDate), " dd MMM yyyy")}
                    </b>
                    .
                  </Typography>
                </Grid>
                <Grid item>
                  {!officeFloatStatus && shiftBasedOffice && (
                    <>
                      <Typography>
                        Update schedule status to accept floats in draft
                        schedule state (optional)
                      </Typography>
                      <br />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={ChangeOfficeToAcceptingFloats}
                        disabled={officeScheduleStatusChanged && true}
                      >
                        {officeScheduleStatusChanged
                          ? "Schedule Status Changed to Accepting Floats in Draft"
                          : "Accept Floats in Draft"}
                      </Button>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Typography>
                    View the draft schedule and make updates.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/Landing/${draftStartDate}/draft`}
                    className={classes.button}
                  >
                    View Schedule
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>No draft schedule to review at this time.</Typography>
            );
          }
        case 3:
          if (!draftReleased && draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography style={{ width: 400 }}>
                    To release the draft schedule for your staff to review,
                    click the Release Draft button below.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReleaseDraft}
                    className={classes.button}
                  >
                    Release Draft
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>
                {draftReleased
                  ? "You have already released the draft schedule."
                  : "No draft schedule to release at this time."}
              </Typography>
            );
          }
        case 4:
          if (draftReleased && draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    The schedule for
                    <b>
                      {format(new Date(draftStartDate), " dd MMM yyyy")} -
                      {format(new Date(draftEndDate), " dd MMM yyyy")}
                    </b>{" "}
                    will be open for review until
                    <b>{format(reviewUntil, " dd MMM yyyy")}</b>.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/Landing/${draftStartDate}/draft`}
                    className={classes.button}
                  >
                    View Schedule
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>No draft schedule to review at this time.</Typography>
            );
          }
        case 5:
          if (draftStartDate && draftEndDate) {
            return (
              <Typography>
                Schedule to publish:
                <b>
                  {format(new Date(draftStartDate), " dd MMM yyyy")} -
                  {format(new Date(draftEndDate), " dd MMM yyyy")}
                </b>
              </Typography>
            );
          } else {
            return (
              <Typography>No schedule to publish at this time.</Typography>
            );
          }
      }
    }
  };

  const getContentReviewWindowFalse = () => {
    if (user.officeAllowsManual) {
      switch (index) {
        case 0:
          return (
            <Grid container direction="column" spacing={2}>
              {preferencesDue && (
                <>
                  <Grid item>
                    <Typography>
                      Preferences due: {format(preferencesDue, "dd MMM yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {preferencesDue > new Date() ? (
                      <Typography>
                        All employees will be notified to update their
                        preferences.
                      </Typography>
                    ) : (
                      <Typography>
                        All employees have been notified to update their
                        preferences.
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography>
                  Pending time off requests for next schedule period:{" "}
                  <b>{pendingTimeOffRequests.length}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Requests"
                  className={classes.button}
                >
                  Time Off Requests
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid container spacing={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Typography>Manual Scheduling for Managers</Typography>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <DatePicker
                        placeholder="MM/DD/YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        minDate={managerManualSchedulingDate}
                        value={managerManualSchedulingDate}
                        autoOk={true}
                        onChange={(e) => SetManagerManualSchedulingDate(e)}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleCreateSkeleton}
                    disabled={createSkeletonCalled && createSkeletonLoading}
                  >
                    {createSkeletonCalled && createSkeletonLoading && (
                      <CircularProgress color="primary" />
                    )}
                    Enable
                  </Button>
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: 20 }}
                container
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <Typography>Manual Scheduling for Employees</Typography>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <DatePicker
                        placeholder="MM/DD/YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        minDate={employeeManualSchedulingDate}
                        maxDate={managerManualSchedulingDate}
                        value={employeeManualSchedulingDate}
                        autoOk={true}
                        onChange={(e) => SetEmployeeManualSchedulingDate(e)}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={
                      !schedulePeriods.find((e) => e.status === "READY")
                    }
                    onClick={() =>
                      createSkeletonForAll({
                        variables: {
                          office: parseInt(user.office.id),
                          thru: format(
                            employeeManualSchedulingDate,
                            "yyyy-MM-dd"
                          ),
                        },
                      })
                    }
                  >
                    Enable
                  </Button>
                </Grid>
                <Grid item>
                  {allChildOffices.length > 0 && (
                    <Typography variant="body2">
                      Note: All Child offices of <b>{user.office.name}</b> will
                      be open to manual scheduling for the above dates
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={generateSchedule}
                  color="primary"
                  disabled={creatingSchedule === true || disableCreateSchedule}
                  style={{ marginTop: 8 }}
                  className={classes.button}
                >
                  Create Schedule
                </Button>
              </Grid>
              {creatingSchedule===true && scheduleDryRun === false && status !== "ERROR" ? (
                <>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography>Creating Schedule</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={mapStatusToValue[status]}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Right now we are {mappedToExplanations[status]}.
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 450 }}>
                    <Typography>
                      You can continue working while the schedule is being
                      generated and you will be notified when it finishes.
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {error && (
                <Grid item>
                  <Typography className={classes.error}>{error}</Typography>
                  <Button
                    color="primary"
                    onClick={() => history.push("/Notifications")}
                  >
                    View Error Details
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        case 3:
          if (draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    A schedule has been created for
                    <b>
                      {format(new Date(draftStartDate), " dd MMM yyyy")} -
                      {format(new Date(draftEndDate), " dd MMM yyyy")}
                    </b>
                    .
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    View the draft schedule and make updates.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/Landing/${draftStartDate}/draft`}
                    className={classes.button}
                  >
                    View Schedule
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>No draft schedule to review at this time.</Typography>
            );
          }
        case 4:
          if (draftStartDate && draftEndDate) {
            return (
              <Typography>
                Schedule to publish:
                <b>
                  {format(new Date(draftStartDate), " dd MMM yyyy")} -
                  {format(new Date(draftEndDate), " dd MMM yyyy")}
                </b>
              </Typography>
            );
          } else {
            return (
              <Typography>No schedule to publish at this time.</Typography>
            );
          }
      }
    } else {
      switch (index) {
        case 0:
          return (
            <Grid container direction="column" spacing={2}>
              {preferencesDue && (
                <>
                  <Grid item>
                    <Typography>
                      Preferences due: {format(preferencesDue, "dd MMM yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {preferencesDue > new Date() ? (
                      <Typography>
                        All employees will be notified to update their
                        preferences.
                      </Typography>
                    ) : (
                      <Typography>
                        All employees have been notified to update their
                        preferences.
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography>
                  Pending time off requests for next schedule period:{" "}
                  <b>{pendingTimeOffRequests.length}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Requests"
                  className={classes.button}
                >
                  Time Off Requests
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={generateSchedule}
                  color="primary"
                  disabled={creatingSchedule === true || disableCreateSchedule}
                  style={{ marginTop: 8 }}
                  className={classes.button}
                >
                  Create Schedule
                </Button>
              </Grid>
              {creatingSchedule===true && scheduleDryRun === false && status !== "ERROR" ? (
                <>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography>Creating Schedule</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={mapStatusToValue[status]}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Right now we are {mappedToExplanations[status]}.
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 450 }}>
                    <Typography>
                      You can continue working while the schedule is being
                      generated and you will be notified when it finishes.
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {error && (
                <Grid item>
                  <Typography className={classes.error}>{error}</Typography>
                  <Button
                    color="primary"
                    onClick={() => history.push("/Notifications")}
                  >
                    View Error Details
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        case 2:
          if (draftStartDate && draftEndDate) {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>
                    A schedule has been created for
                    <b>
                      {format(new Date(draftStartDate), " dd MMM yyyy")} -
                      {format(new Date(draftEndDate), " dd MMM yyyy")}
                    </b>
                    .
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/Landing/${draftStartDate}/draft`}
                    className={classes.button}
                  >
                    View Schedule
                  </Button>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Typography>No draft schedule to review at this time.</Typography>
            );
          }
        case 3:
          if (draftStartDate && draftEndDate) {
            return (
              <Typography>
                Schedule to publish:
                <b>
                  {format(new Date(draftStartDate), " dd MMM yyyy")} -
                  {format(new Date(draftEndDate), " dd MMM yyyy")}
                </b>
              </Typography>
            );
          } else {
            return (
              <Typography>No schedule to publish at this time.</Typography>
            );
          }
      }
    }
  };

  const getContentFloat = () => {
    if (user.officeAllowsManual) {
      switch (index) {
        case 0:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>
                  Pending time off requests for next schedule period:{" "}
                  <b>{pendingTimeOffRequests.length}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Requests"
                  className={classes.button}
                >
                  Time Off Requests
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid container spacing={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Typography>Manual Scheduling for Managers</Typography>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <DatePicker
                        placeholder="MM/DD/YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        minDate={managerManualSchedulingDate}
                        value={managerManualSchedulingDate}
                        autoOk={true}
                        onChange={(e) => SetManagerManualSchedulingDate(e)}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleCreateSkeleton}
                    disabled={createSkeletonCalled && createSkeletonLoading}
                  >
                    {createSkeletonCalled && createSkeletonLoading && (
                      <CircularProgress color="primary" />
                    )}
                    Enable
                  </Button>
                </Grid>
              </Grid>
              <Grid
                style={{ marginTop: 20 }}
                container
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <Typography>Manual Scheduling for Employees</Typography>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <DatePicker
                        placeholder="MM/DD/YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        minDate={employeeManualSchedulingDate}
                        maxDate={managerManualSchedulingDate}
                        value={employeeManualSchedulingDate}
                        autoOk={true}
                        onChange={(e) => SetEmployeeManualSchedulingDate(e)}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={
                      !schedulePeriods.find((e) => e.status === "READY")
                    }
                    onClick={() =>
                      createSkeletonForAll({
                        variables: {
                          office: parseInt(user.office.id),
                          thru: format(
                            employeeManualSchedulingDate,
                            "yyyy-MM-dd"
                          ),
                        },
                      })
                    }
                  >
                    Enable
                  </Button>
                </Grid>
                <Grid item>
                  {allChildOffices.length > 0 && (
                    <Typography variant="body2">
                      Note: All Child offices of <b>{user.office.name}</b> will
                      be open to manual scheduling for the above dates
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={generateSchedule}
                  color="primary"
                  disabled={creatingSchedule === true || disableCreateSchedule}
                  style={{ marginTop: 8 }}
                  className={classes.button}
                >
                  Create Schedule
                </Button>
              </Grid>
              {creatingSchedule===true && scheduleDryRun === false && status !== "ERROR" ? (
                <>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography>Creating Schedule</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={mapStatusToValue[status]}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Right now we are {mappedToExplanations[status]}.
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 450 }}>
                    <Typography>
                      You can continue working while the schedule is being
                      generated and you will be notified when it finishes.
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {error && (
                <Grid item>
                  <Typography className={classes.error}>{error}</Typography>
                  <Button
                    color="primary"
                    onClick={() => history.push("/Notifications")}
                  >
                    View Error Details
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        case 3:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>A schedule has been created!</Typography>
              </Grid>
            </Grid>
          );
      }
    } else {
      switch (index) {
        case 0:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>
                  Pending time off requests for next schedule period:{" "}
                  <b>{pendingTimeOffRequests.length}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Requests"
                  className={classes.button}
                >
                  Time Off Requests
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={generateSchedule}
                  color="primary"
                  disabled={creatingSchedule === true || disableCreateSchedule}
                  style={{ marginTop: 8 }}
                  className={classes.button}
                >
                  Create Schedule
                </Button>
              </Grid>
              {creatingSchedule===true && scheduleDryRun === false && status !== "ERROR" ? (
                <>
                  <Grid item style={{ marginTop: "10px" }}>
                    <Typography>Creating Schedule</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      variant="determinate"
                      value={mapStatusToValue[status]}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      Right now we are {mappedToExplanations[status]}.
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 450 }}>
                    <Typography>
                      You can continue working while the schedule is being
                      generated and you will be notified when it finishes.
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {error && (
                <Grid item>
                  <Typography className={classes.error}>{error}</Typography>
                  <Button
                    color="primary"
                    onClick={() => history.push("/Notifications")}
                  >
                    View Error Details
                  </Button>
                </Grid>
              )}
            </Grid>
          );
        case 2:
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>A schedule has been created!</Typography>
              </Grid>
            </Grid>
          );
      }
    }
  };

  if (officeFloatStatus) {
    return getContentFloat();
  } else if (reviewUntil) {
    return getContentReviewWindowTrue();
  } else {
    return getContentReviewWindowFalse();
  }
};

export default Steps;
