import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import ShiftSwitchRequestForm from "../shiftSwitch/ShiftSwitchRequestForm";
import ShiftSwitchItem from "./ShiftSwitchItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { userVar } from "../../cache";
import { orderShifts } from "../../helpers/formatShiftEvents";
import { GET_SHIFTS_FOR_SS_FORM } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import Roles from "../../Roles/roles";
import MangShiftSwitchApprovalItem from "./MangShiftSwitchApprovalItem";

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    background: theme.palette.secondary.superLight,
    padding: 16,
    marginTop: 24,
  },
  sectionHeadingWOButton: {
    background: theme.palette.secondary.superLight,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 23,
    paddingBottom: 23,
    marginTop: 24,
  },
  sectionHeaderButton: {
    width: 117,
  },
  sectionHeaderText: {
    paddingTop: 7,
  },
  smallButton: {
    width: 50,
  },
}));

const ShiftSwitch = (props) => {
  const {
    shiftSwitchData,
    scheduleEnd,
    refetch,
    setToast,
    setShowToast,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    setErrorToast,
    setShowErrorToast,
  } = props;

  const classes = useStyles();

  const user = userVar();
  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  const [shiftSwitch, setShiftSwitch] = useState(false);
  const [dateToView, setDateToView] = useState(new Date());

  const toggleShiftSwitchForm = () => setShiftSwitch(!shiftSwitch);

  const closeShiftSwitchForm = () => {
    setShiftSwitch(false);
    refetch();
  };

  const shiftSwitchRequestsToView = (type) => {
    let shiftSwitchRequests = [];

    if (type === "outgoing") {
      const shiftSwitches = managerAccess
        ? shiftSwitchData.requestedShiftSwitches
        : shiftSwitchData.shiftSwitches;

      shiftSwitchRequests = shiftSwitches.filter((request) => {
        const currentUser = request.rescheduleindividualSet.find(
          (individual) => parseInt(individual.employee.id) === parseInt(user.id)
        );
        const shiftsInPast = currentUser.rescheduleactionSet.find(
          (action) => new Date(action.shift.start) < dateToView
        );
        return currentUser.employeeResponse !== "NONE" && !shiftsInPast;
      });
    } else {
      if (managerAccess && type === "incomingPersonal") {
        shiftSwitchRequests = shiftSwitchData.requestedShiftSwitches.filter(
          (request) => {
            const currentUser = request.rescheduleindividualSet.find(
              (individual) =>
                parseInt(individual.employee.id) === parseInt(user.id)
            );
            const shiftsInPast = currentUser.rescheduleactionSet.find(
              (action) => new Date(action.shift.start) < dateToView
            );
            return currentUser.employeeResponse === "NONE" && !shiftsInPast;
          }
        );
      } else if (managerAccess && type === "incomingUnit") {
        shiftSwitchRequests = shiftSwitchData.acceptedShiftSwitches.filter(
          (request) => {
            const shiftsInPast =
              request.rescheduleindividualSet[0].rescheduleactionSet.find(
                (action) => new Date(action.shift.start) < dateToView
              );
            return !shiftsInPast;
          }
        );
      } else {
        shiftSwitchRequests = shiftSwitchData.shiftSwitches.filter(
          (request) => {
            const currentUser = request.rescheduleindividualSet.find(
              (individual) =>
                parseInt(individual.employee.id) === parseInt(user.id)
            );
            const shiftsInPast = currentUser.rescheduleactionSet.find(
              (action) => new Date(action.shift.start) < dateToView
            );
            return currentUser.employeeResponse === "NONE" && !shiftsInPast;
          }
        );
      }
    }
    return shiftSwitchRequests;
  };

  const { loading, error, data } = useQuery(GET_SHIFTS_FOR_SS_FORM, {
    variables: {
      officeId: parseInt(user.office.id),
      employeeId: parseInt(user.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_SHIFTS_FOR_SS_FORM Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  let events;
  let userEvents;
  let shiftNames;

  if (!loading && !error) {
    let scheduleData = orderShifts(data.allShifts);
    console.log(scheduleData.formatted);

    events = scheduleData.formatted;
    userEvents = events.filter(
      (event) => event.employeeIds && event.employeeIds.includes(user.id)
    );
    shiftNames = scheduleData.shiftNames;
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            spacing={1}
            style={{ marginTop: 8, marginBottom: 8 }}
          >
            <Grid item>
              <Typography variant="h5">
                See requests from this date on:
              </Typography>
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="date-to-view"
                value={dateToView}
                onChange={setDateToView}
              />
            </Grid>
          </Grid>
          {managerAccess && (
            <>
              <Grid
                item
                container
                justifyContent="space-between"
                className={classes.sectionHeadingWOButton}
                xs={11}
              >
                <Grid item>
                  <Typography variant="h4">Incoming Unit</Typography>
                </Grid>
              </Grid>
              {shiftSwitchRequestsToView("incomingUnit").length > 0 ? (
                shiftSwitchRequestsToView("incomingUnit").map((request) => (
                  <MangShiftSwitchApprovalItem
                    key={request.id}
                    request={request}
                    setToast={setToast}
                    setShowToast={setShowToast}
                    setErrorToast={setErrorToast}
                    setShowErrorToast={setShowErrorToast}
                    refetch={refetch}
                    setOpenSnackBar={setOpenSnackBar}
                    setSnackBarMessage={setSnackBarMessage}
                    notifyDevelopers={notifyDevelopers}
                    environment={environment}
                  />
                ))
              ) : (
                <Typography style={{ marginTop: 16, marginLeft: 16 }}>
                  No incoming unit shift switch requests after minimum date
                </Typography>
              )}
            </>
          )}
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.sectionHeadingWOButton}
            xs={11}
          >
            <Grid item>
              <Typography variant="h4">
                {managerAccess ? "Incoming Personal" : "Incoming"}
              </Typography>
            </Grid>
          </Grid>
          {managerAccess ? (
            shiftSwitchRequestsToView("incomingPersonal").length > 0 ? (
              shiftSwitchRequestsToView("incomingPersonal").map((request) => (
                <ShiftSwitchItem
                  key={request.id}
                  request={request}
                  incoming={true}
                  refetch={refetch}
                  setOpenSnackBar={setOpenSnackBar}
                  setSnackBarMessage={setSnackBarMessage}
                  notifyDevelopers={notifyDevelopers}
                  environment={environment}
                />
              ))
            ) : (
              <Typography style={{ marginTop: 16, marginLeft: 16 }}>
                No incoming personal shift switch requests after minimum date
              </Typography>
            )
          ) : shiftSwitchRequestsToView("incoming").length > 0 ? (
            shiftSwitchRequestsToView("incoming").map((request) => (
              <ShiftSwitchItem
                key={request.id}
                request={request}
                incoming={true}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            ))
          ) : (
            <Typography style={{ marginTop: 16, marginLeft: 16 }}>
              No incoming shift switch requests after minimum date
            </Typography>
          )}
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.sectionHeading}
            xs={11}
          >
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeaderText}>
                {managerAccess ? "Outgoing Personal" : "Outgoing"}
              </Typography>
            </Grid>
            <Grid item>
              {user.isSchedulable && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleShiftSwitchForm}
                  className={classes.sectionHeaderButton}
                >
                  {loading ? (
                    <CircularProgress size={22} style={{ color: "#fff" }} />
                  ) : (
                    "Add Request"
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          {shiftSwitchRequestsToView("outgoing").length > 0 ? (
            shiftSwitchRequestsToView("outgoing").map((request) => (
              <ShiftSwitchItem
                key={request.id}
                request={request}
                incoming={false}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            ))
          ) : (
            <Typography style={{ marginTop: 16, marginLeft: 16 }}>
              {managerAccess
                ? "No outgoing personal shift switch requests after minimum date"
                : "No outgoing shift switch requests after minimum date"}
            </Typography>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      <Dialog open={shiftSwitch} fullWidth maxWidth="sm">
        <DialogContent
          style={{
            padding: 30,
            overflowX: "hidden",
            overflowY: "auto",
            height: 675,
            position: "relative",
          }}
        >
          <ShiftSwitchRequestForm
            allEvents={events}
            userEvents={userEvents}
            closeDialog={closeShiftSwitchForm}
            shiftNames={shiftNames}
            date={new Date()}
            scheduleEndDate={scheduleEnd}
            setToast={setToast}
            setShowToast={setShowToast}
            setErrorToast={setErrorToast}
            setShowErrorToast={setShowErrorToast}
            refetch={() => null}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShiftSwitch;
