import React from "react";
import { Grid, Typography, Button, Paper, makeStyles } from "@material-ui/core";
import { format, isSameDay } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_EMPLOYEE_AVAILABILITY,
  AVAILABILITY_TYPES,
} from "../../api/gqlQueries";

const useStyles = makeStyles((theme) => ({
  requestCard: {
    padding: 16,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: theme.palette.background.default,
  },
  button: {
    width: 75,
  },
}));

const OffRequestItem = ({
  request,
  setIdToEdit,
  toggleEditForm,
  type,
  setOpenSnackBar,
  setSnackBarMessage,
  notifyDevelopers,
  environment,
  refetch,
  setToast,
  setShowToast,
}) => {
  const classes = useStyles();

  const { data } = useQuery(AVAILABILITY_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteTimeOff] = useMutation(UPDATE_EMPLOYEE_AVAILABILITY, {
    onCompleted(d) {
      refetch();
      setToast("Request Deleted Successfully");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      setOpenSnackBar(true);
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_EMPLOYEE_AVAILABILITY Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleEditClick = () => {
    setIdToEdit(request.eventId);
    toggleEditForm();
  };

  const handleDeleteClick = () => {
    deleteTimeOff({
      variables: {
        id: parseInt(request.eventId),
        input: { userCancelled: true },
      },
    });
  };

  const typeName = data?.availabilityTypes.find(
    (type) => type.id === request.typeId
  );

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      component={Paper}
      xs={11}
      md={7}
      className={classes.requestCard}
    >
      <Grid item>
        {type === "timeOff" ? (
          <Typography variant="h5">
            Time Off Request {typeName ? `- ${typeName.name}` : null}
          </Typography>
        ) : (
          <Typography variant="h5">
            {format(request.start, "dd MMM yyyy")}
          </Typography>
        )}
      </Grid>
      {request.approvedby || request.deniedText ? (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDeleteClick}
            className={classes.button}
          >
            Delete
          </Button>
        </Grid>
      ) : (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleEditClick}
            className={classes.button}
          >
            Edit
          </Button>
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: 8 }}>
        {type === "timeOff" ? (
          <>
            <Typography variant="subtitle1">
              Status:{" "}
              {request.approvedby && !request.deniedText
                ? request.approvedby.firstName && request.approvedby.lastName
                  ? `Approved by ${request.approvedby.firstName} ${request.approvedby.lastName}`
                  : "Approved"
                : null}
              {!request.approvedby && !request.deniedText && "Pending approval"}
              {request.deniedText && `Denied - Reason: ${request.deniedText}`}
            </Typography>
            <Typography>
              <i>
                Request was made on{" "}
                {format(new Date(request.createdAt), "dd MMM yyyy")}
              </i>
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle1">
            Priority: {request.highPriority ? "High" : "Standard"}
          </Typography>
        )}
      </Grid>
      {type === "timeOff" ? (
        <Grid item xs={12} style={{ marginTop: 8, marginBottom: 8 }}>
          {!isSameDay(request.start, request.end) ? (
            <>
              <Typography>
                Request dates:
                {format(request.start, " dd MMM yyyy")} -
                {format(request.end, " dd MMM yyyy")}
              </Typography>
              <Typography>All Day</Typography>
              <Typography>Hours used: {request.workHours}</Typography>
            </>
          ) : (
            <>
              <Typography>
                Request date: {format(request.start, " dd MMM yyyy")}
              </Typography>
              {type === "timeOff" ? (
                request.isAllDay ? (
                  <Typography>All Day</Typography>
                ) : (
                  <Typography>
                    {format(request.start, "HH:mm")}
                    {" - "}
                    {format(request.end, "HH:mm")}
                  </Typography>
                )
              ) : null}
              {type === "timeOff" && (
                <Typography>Hours used: {request.workHours}</Typography>
              )}
            </>
          )}
        </Grid>
      ) : null}
      {type === "timeOff" && request.comment && (
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <Typography>Comment: {request.comment}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default OffRequestItem;
