import React, { useState } from "react";
//import Settings from './Settings';
import Actions from "./Actions";
import {
  Typography,
  CircularProgress,
  makeStyles,
  Box,
  Tabs,
  Tab,
  Grid,
  FormControlLabel,
  Switch,
  FormControl,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SCHEDULE_INFO, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { userVar } from "../../cache";
import ErrorSnackBar from "../errors/errorSnackBar";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateSchedule = () => {
  const classes = useStyles();
  const [value, SetValue] = useState(0);
  const user = userVar();
  // const [view, setView] = useState(0);
  const environment = process.env.NODE_ENV;
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [allowManualScheduling, SetAllowManualScheduling] = useState(true);
  const [noOfSchedulePeriods, SetNoOfSchedulePeriods] = useState(1);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(GET_SCHEDULE_INFO, {
    variables: { id: user.office.id },
    fetchPolicy: "cache-and-network",
    onError(error) {
      console.log(error);
      setOpenSnackBar2(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SCHEDULE_INFO Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  if (loading) {
    return <CircularProgress />;
  } else if (error) {
    return (
      <>
        <Typography variant="h3" className={classes.headerSpacing}>
          Create Schedule
        </Typography>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </>
    );
  } else {
    const scheduleData = data.offices[0];
    console.log(data);
    const schedulePeriods = data.schedulePeriods;
    const handleChange = (event, newValue) => {
      SetValue(newValue);
    };

    return (
      <>
        <Box mt={6} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Create Schedule" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Typography variant="h3" className={classes.headerSpacing}>
            Create Schedule
          </Typography>
          <Actions
            scheduleData={scheduleData}
          />
        </TabPanel>
      </>
    );
  }
};

export default CreateSchedule;
