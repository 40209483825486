import React from 'react';
import { Checkbox, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    coloredText: {
        color: '#8CADE1'
    }
}));

const Final = ({values, setFieldValue}) => {
    const classes = useStyles();
    
    return (  
        <Grid container item direction='column' alignItems='center' spacing={5} style={{padding: 25}}>
            <Grid item align='center'>
                <Typography style={{fontSize: 24}}>Okay, enough questions for now!</Typography>
            </Grid>
            {/* <Grid item align='center'>
                <Typography style={{fontSize: 24}}>
                    Check the box below if you would like to always use this guide to set your preferences.
                </Typography>
            </Grid> */}
            <Grid 
                item 
                container 
                alignItems='center' 
                justifyContent='center' 
            >
                <Grid item>
                    <Checkbox 
                        checked={values.alwaysOpenGuide} 
                        onChange={(event) => setFieldValue('alwaysOpenGuide', event.target.checked)}
                        color='primary'
                    />
                </Grid>
                <Grid item>
                    <Typography style={{fontSize: 24}}>Always use this guide to set your preferences</Typography>
                </Grid>
            </Grid>
            <Grid item align='center' style={{marginTop: 100}}>
                <Typography style={{fontSize: 18}} className={classes.coloredText}>
                    Don't worry, you can always get to the guide through the Open Guide button at the top of the preferences screen.
                </Typography>
            </Grid>
        </Grid>
    );
}
 
export default Final;