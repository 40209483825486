import React from 'react';
import EventParticipant from '../shiftBasedCalendar/EventParticipant';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
    addSpacing: {
        marginTop: 10,
        marginBottom: 10,
    }
}));

const SingleShiftList = ({shiftEvent, employeeId}) => {
    const classes = useStyles();
    
    const participants = shiftEvent.employeeSet;
    let users;

    if(participants){
        let remainingEmployees = participants.filter(participant => (
            participant.id !== employeeId
        ));
        const selectedEmployeeObject = participants.find(participant => (
            participant.id === employeeId
        ));

        remainingEmployees = remainingEmployees.map(employee => (
            <EventParticipant 
                key={employee.id + 'shiftswitch'} 
                participant={employee} 
                showAvatar={true} 
                showJobTitle={true}
            />
        ));
        const selectedEmployee = <EventParticipant 
            participant={selectedEmployeeObject} 
            key={employeeId + 'shiftswitch'} 
            showAvatar={true} 
            selected={true} 
            showJobTitle={true}
        />;
        remainingEmployees.unshift(selectedEmployee);
        users = remainingEmployees;
    }
    
    return (
        <Grid item container direction='column' spacing={2} xs={6}>
            <Grid item >
                <Typography variant='h5'>
                    {new Date(shiftEvent.start).toDateString() + ' - '}
                    <i>
                        {`${
                            format(new Date(shiftEvent.start), 'HH:mm')
                        } to ${
                            format(new Date(shiftEvent.end), 'HH:mm')
                        }`}
                    </i>
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h5' className={classes.addSpacing}>
                    Employees Scheduled:
                </Typography>
            </Grid>
            <Grid item container direction='column' spacing={1}>
                {users}
            </Grid>
        </Grid>
    );
};
 
export default SingleShiftList;