import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import { add, sub, isWithinInterval, isSameDay, format } from "date-fns";
import { useMutation, gql } from "@apollo/client";
import { CREATE_SOFT_REQUEST } from "../../api/gqlQueries";
import { userVar, selectedDateVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
}));

/* Soft time off requests are added prior to generating a schedule to indicate that a particular date is not preferred to work.
This component is a soft time off form.
A user can add priority to a soft request
*/

const SoftTimeOff = (props) => {
  const classes = useStyles();

  const {
    closeDialog,
    scheduleEndDate,
    setToast,
    setShowToast,
    notifyDevelopers,
    environment,
  } = props;

  const user = userVar();
  const date = selectedDateVar();

  const minimumDate = add(new Date(scheduleEndDate), { days: 1 });
  const [priorityChecked, setPriorityChecked] = useState(false);
  const [dateForRequest, setDateForRequest] = useState(
    date < minimumDate ? minimumDate : date
  );
  const [error, setError] = useState("");

  const [updatePreference] = useMutation(CREATE_SOFT_REQUEST, {
    update(cache, { data: { createOffPreference } }) {
      cache.modify({
        fields: {
          offPreferences(existingOffPreferences = []) {
            const newOffPreferenceRef = cache.writeFragment({
              data: createOffPreference.offPreference,
              fragment: gql`
                fragment NewOffPreference on OffPreferenceNode {
                  id
                  date
                  value
                }
              `,
            });
            return [...existingOffPreferences, newOffPreferenceRef];
          },
        },
      });
    },
    onCompleted() {
      setError("");
      setToast("New Soft Request");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      setError("Unable to create Soft Request. " + error);
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_SOFT_REQUEST Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleSubmit = () => {
    // the soft request is a preference. Like all other preferences we give a value to the soft request. If priority flag is on the value is 10.
    const newRequest = {
      employee: parseInt(user.id),
      date: dateForRequest.toISOString().slice(0, 10),
      value: priorityChecked ? 10.0 : 5.0,
    };
    updatePreference({ variables: { input: { ...newRequest } } });
  };

  const handleUpdateDate = (date) => {
    setDateForRequest(date);
  };
 
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">Soft Time Off</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeDialog}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={priorityChecked}
                onChange={(e) => setPriorityChecked(e.target.checked)}
                name="priorityChecked"
                color={priorityChecked ? "primary" : "secondary"}
              />
            }
            label="Priority Request"
          ></FormControlLabel>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="date-for-request">
            <Typography variant="h5">Date:</Typography>
          </InputLabel>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="date-for-request"
            value={dateForRequest}
            onChange={handleUpdateDate}
            className={classes.input}
          />
        </Grid>
        <Grid item style={{ height: 40, marginTop: -15, marginBottom: 10 }}>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default SoftTimeOff;
