import React from "react";
import {
  FormLabel,
  Grid,
  Typography,
  makeStyles,
  Slider,
} from "@material-ui/core";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentNeutralIcon from "../../assets/quizImages/SentimentNeutralIcon";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";

const useStyles = makeStyles(() => ({
  sliderBox: {
    maxWidth: 425,
    marginTop: 16,
  },
}));

const DaysOffInARow = (props) => {
  const classes = useStyles();

  const { values, setFieldValue, setStatus } = props;

  const marks = [
    {
      value: -2,
      label: <MoodBadIcon style={{ paddingLeft: 15 }} />,
    },
    {
      value: -1,
      label: <SentimentDissatisfiedIcon />,
    },
    {
      value: 0,
      label: <SentimentNeutralIcon />,
    },
    {
      value: 1,
      label: <SentimentSatisfiedIcon />,
    },
    {
      value: 2,
      label: <MoodIcon style={{ paddingRight: 15 }} />,
    },
  ];

  return (
    <Grid container data-testid="daysOffInARow">
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        xs={9}
        spacing={2}
      >
        <Grid item>
          <Typography style={{ marginBottom: 16, lineHeight: 1, fontSize: 18 }}>
            <b>Days Off</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            How many days <b>off in a row</b> do you like to have?
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel>One Day</FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.oneDay}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              onChange={(e, value) => {
                setFieldValue("oneDay", value);
                setStatus(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel>Two Days</FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.twoDays}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              onChange={(e, value) => {
                setFieldValue("twoDays", value);
                setStatus(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel>Three Days</FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.threeDays}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              onChange={(e, value) => {
                setFieldValue("threeDays", value);
                setStatus(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.sliderBox}
        >
          <Grid item style={{ marginTop: 4 }}>
            <FormLabel>Four Days</FormLabel>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={values.fourDays}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="off"
              step={1}
              marks={marks}
              min={-2}
              max={2}
              onChange={(e, value) => {
                setFieldValue("fourDays", value);
                setStatus(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DaysOffInARow;
