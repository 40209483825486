import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import EditTimeOffRequestForm from "../shiftBasedCalendar/EditTimeOffRequestForm";
import TimeOffRequestForm from "../shiftBasedCalendar/TimeOffRequestForm";
import OffRequestItem from "./OffRequestItem";
import IncomingOffRequestItem from "./IncomingOffRequestItem";
import { locationConfigVar, userVar } from "../../cache";
import {
  GET_EMPLOYEE_NAMES,
  GET_EMPLOYEES_OF_CHILD_OFFICES,
  MANAGER_APPROVE_ALL_TO_REQUESTS,
} from "../../api/gqlQueries";
import { useMutation, useQuery } from "@apollo/client";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isSameDay, format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    background: theme.palette.secondary.superLight,
    padding: 16,
    marginTop: 24,
  },
  sectionHeaderButton: {
    width: 117,
  },
  sectionHeaderText: {
    paddingTop: 7,
  },
  smallButton: {
    width: 50,
  },
}));

const ManagerTimeOff = (props) => {
  const {
    outgoingTimeOff,
    incomingTimeOff,
    scheduleEnd,
    invalidDates,
    softRequests,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    timeOffRequestsRefetch,
  } = props;

  const classes = useStyles();

  const user = userVar();
  const locationConfig = locationConfigVar();
  const shiftDisplay = locationConfig.shiftBasedDisplay;

  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const [editTimeOffRequest, setEditTimeOffRequest] = useState(false);
  const [approveAllConfirmation, setApproveAllConfirmation] = useState(false);
  const [timeOffRequestIdToEdit, setTimeOffRequestIdToEdit] = useState("");
  const [dateToView, setDateToView] = useState(new Date());
  const [employeeResources, setEmployeeResources] = useState([]);

  const { data } = useQuery(GET_EMPLOYEE_NAMES, {
    variables: { office: parseInt(user.office.id) },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_EMPLOYEE_NAMES Query. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const { data: childOfficeEmployeeData } = useQuery(
    GET_EMPLOYEES_OF_CHILD_OFFICES,
    {
      variables: { parent: parseInt(user.office.id) },
      onCompleted(d) {
        console.log(d);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_ALL_CHILD_OFFICES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [approveAllRequests] = useMutation(MANAGER_APPROVE_ALL_TO_REQUESTS, {
    onCompleted(data) {
      console.log(data);
      toggleApproveAllConfirmation();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on MANAGER_APPROVE_ALL_TO_REQUESTS Mutation. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      addEmployeeData(data.offices[0].employeeSet);
    }
  }, [data]);

  useEffect(() => {
    if (childOfficeEmployeeData) {
      let data = childOfficeEmployeeData.getChildren
        .map((e) => e.employeeSet)
        .flat();
      addEmployeeData(data);
    }
  }, [childOfficeEmployeeData]);

  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);
  const toggleEditTimeOffForm = () =>
    setEditTimeOffRequest(!editTimeOffRequest);
  const toggleApproveAllConfirmation = () => {
    setApproveAllConfirmation(!approveAllConfirmation);
  };

  const closeTimeOffForm = () => {
    setTimeOffRequestForm(false);
    refetch();
  };

  const closeEditTimeOffForm = () => {
    setEditTimeOffRequest(false);
    refetch();
  };

  const timeOffRequestsToView = (type) => {
    let requests = [];
    if (type === "outgoing") {
      requests = outgoingTimeOff;
    } else if (type === "incoming") {
      requests = incomingTimeOff.filter(
        (timeOff) =>
          !timeOff.approvedby && !timeOff.deniedText && !timeOff.userCancelled
      );
    }

    const filtered = requests.filter(
      (request) =>
        request.start > dateToView || isSameDay(request.start, dateToView)
    );
    filtered.length > 0 &&
      filtered.sort((a, b) => new Date(a.firstday) - new Date(b.firstday));
    return filtered;
  };

  const addEmployeeData = (data) => {
    const resources = data.map((employee) => {
      if (parseInt(employee.id) === parseInt(user.id)) {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#F5E1DB",
          skills: employee.skills.map((e) => e.name),
        };
      } else {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#DFE0E2",
          skills: employee.skills.map((e) => e.name),
        };
      }
    });
    setEmployeeResources(resources);
  };

  const handleApproveAll = () => {
    const allRequests = timeOffRequestsToView("incoming");
    const requestIds = allRequests.map((request) => parseInt(request.eventId));
    approveAllRequests({
      variables: {
        approvingManager: parseInt(user.id),
        ids: requestIds,
      },
    });
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            spacing={1}
            style={{ marginTop: 8, marginBottom: 8 }}
          >
            <Grid item>
              <Typography variant="h5">
                See requests from this date on:
              </Typography>
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="date-to-view"
                value={dateToView}
                onChange={setDateToView}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.sectionHeading}
            xs={11}
          >
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeaderText}>
                Incoming Unit
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleApproveAllConfirmation}
                className={classes.sectionHeaderButton}
                disabled={timeOffRequestsToView("incoming").length === 0}
              >
                Approve All
              </Button>
            </Grid>
          </Grid>
          {timeOffRequestsToView("incoming").length > 0 ? (
            timeOffRequestsToView("incoming").map((request) => (
              <IncomingOffRequestItem
                key={request.eventId}
                request={request}
                setToast={setToast}
                setShowToast={setShowToast}
                setErrorToast={setErrorToast}
                setShowErrorToast={setShowErrorToast}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            ))
          ) : (
            <Typography style={{ marginTop: 16, marginLeft: 16 }}>
              No incoming unit time off requests after minimum date
            </Typography>
          )}
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.sectionHeading}
            xs={11}
          >
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeaderText}>
                Outgoing Personal
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleTimeOffForm}
                className={classes.sectionHeaderButton}
              >
                Add Request
              </Button>
            </Grid>
          </Grid>
          {timeOffRequestsToView("outgoing").length > 0 ? (
            timeOffRequestsToView("outgoing").map((request) => (
              <OffRequestItem
                key={request.eventId}
                request={request}
                setIdToEdit={setTimeOffRequestIdToEdit}
                toggleEditForm={toggleEditTimeOffForm}
                setToast={setToast}
                setShowToast={setShowToast}
                refetch={timeOffRequestsRefetch}
                type="timeOff"
              />
            ))
          ) : (
            <Typography style={{ marginTop: 16, marginLeft: 16 }}>
              No outgoing personal time off requests after minimum date
            </Typography>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      <Dialog open={timeOffRequestForm} fullWidth maxWidth="sm">
        <DialogContent
          style={{ padding: 30, height: 650, position: "relative" }}
        >
          <TimeOffRequestForm
            closeDialog={closeTimeOffForm}
            scheduleEndDate={shiftDisplay ? scheduleEnd : null}
            invalidDates={invalidDates}
            setToast={setToast}
            setShowToast={setShowToast}
            employees={employeeResources}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={editTimeOffRequest} fullWidth maxWidth="sm">
        <DialogContent
          style={{ padding: 30, height: 650, position: "relative" }}
        >
          {timeOffRequestIdToEdit && (
            <EditTimeOffRequestForm
              closeDialog={closeEditTimeOffForm}
              userSoft={softRequests}
              setToast={setToast}
              setShowToast={setShowToast}
              userTimeOff={outgoingTimeOff}
              timeOffRequestIdToEdit={timeOffRequestIdToEdit}
              setTimeOffRequestIdToEdit={setTimeOffRequestIdToEdit}
              refetch={refetch}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={approveAllConfirmation} fullWidth maxWidth="xs">
        <DialogContent style={{ padding: 30 }}>
          <Typography variant="h5">
            Are you sure you want to approve all time off requests from{" "}
            {format(dateToView, "dd MMM yyyy")} on?
          </Typography>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            style={{ marginTop: 16 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.smallButton}
                onClick={toggleApproveAllConfirmation}
              >
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.smallButton}
                onClick={handleApproveAll}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManagerTimeOff;
