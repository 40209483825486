import React from 'react';
import { 
    Button, 
    Grid,
    Typography, 
    makeStyles, 
    Paper, 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery, useMutation } from '@apollo/client';
import { 
    APPROVE_OPTION,
    DENY_OPTION,
    GET_TWO_SHIFTS_BY_IDS
} from '../../api/gqlQueries';
import { userVar } from '../../cache';
import { format } from 'date-fns';
import SingleShiftList from './SingleShiftList';

const useStyles = makeStyles((theme) => ({
    buttonSpacing: {
        marginLeft: 10, 
        marginTop: 10,
        width: 75
    },
    addSpacing: {
        marginTop: 10,
        marginBottom: 10,
    },
    paper: {
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 10, 
        marginBottom: 10,
        backgroundColor: theme.palette.background.default
    },
    input: {
        width: 384
    }
}));

const MangShiftSwitchApprovalItem = (props) => {
    const classes = useStyles();

    const {
        request,
        setToast, 
        setShowToast,
        setErrorToast,
        setShowErrorToast,
        refetch
    } = props;

    const user = userVar();

    const shiftIdOne = request.rescheduleindividualSet[0]
        .rescheduleactionSet.find(action => (
            action.shiftAssignment
        )).shift.id;
    const shiftIdTwo = request.rescheduleindividualSet[1]
        .rescheduleactionSet.find(action => (
            action.shiftAssignment
        )).shift.id;

    const employeeOne = request.rescheduleindividualSet[0].employee;
    const employeeTwo = request.rescheduleindividualSet[1].employee;

    const { loading, error, data } = useQuery(GET_TWO_SHIFTS_BY_IDS, {variables: {
        shiftOneId: shiftIdOne,
        shiftTwoId: shiftIdTwo
    }});

    const [approveRequest] = useMutation(APPROVE_OPTION, {
        // update(cache, { data: { managerApproveShiftSwitchWithNotifications }}) {
        //     cache.evict({
        //         id: `ShiftSwitchNode:${managerApproveShiftSwitchWithNotifications.shiftSwitchRequest.id}`
        //     });
        // },
        onCompleted(data) {
            refetch();
            console.log(data);
            setToast('Manager Approve Shift Switch');
            setShowToast(true);
        },
        onError(error) {
            console.log(error);
            setErrorToast('Error Approve Shift Switch');
            setShowErrorToast(true);
        }
    });

    const [denyShiftSwitch] = useMutation(DENY_OPTION, {
        // update(cache, { data: { updateShiftSwitch }}) {
        //     cache.evict({
        //         id: `ShiftSwitchNode:${updateShiftSwitch.shiftSwitch.id}`
        //     });
        // },
        onCompleted(data) {
            console.log(data);
            refetch();
            setToast('Manager Deny Shift Switch');
            setShowToast(true);
        },
        onError(error) {
            console.log(error);
            setErrorToast('Error Deny Shift Switch');
            setShowErrorToast(true);
        }
    });

    if(loading) {
        return <CircularProgress />;
    } else if (error) {
        console.log(error);
        return <Typography>Something went wrong. Please try again.</Typography>;
    } else {
        const shiftOne = data.firstShift[0];
        const shiftTwo = data.secondShift[0];

        const employeeOneName = employeeOne.firstName + ' ' +
            employeeOne.lastName;
        const employeeTwoName = employeeTwo.firstName + ' ' +
            employeeTwo.lastName;

        const requestorCurrentDate = format(new Date(shiftOne.start), 'MM/dd/yyyy');
        const acceptorCurrentDate = format(new Date(shiftTwo.start), 'MM/dd/yyyy');

        const title = 
            `Shift Switch Request between ${
                employeeOneName
            } and ${employeeTwoName}`;

        const details = 
            `${employeeOneName} would move to ${
                acceptorCurrentDate
            } and ${employeeTwoName} would move to ${
                requestorCurrentDate
            }.`;

        const handleApproveRequest = (e) => {
            e.stopPropagation();
            approveRequest({variables: {
                option: parseInt(request.id),
                approver: parseInt(user.id)
            }});
        };
                
        const handleDenyRequest = (e) => {
            e.stopPropagation();
            denyShiftSwitch({variables: {
                option: parseInt(request.id),
                denier: parseInt(user.id)
            }});
        };
        
        return (  
            <Paper component={Accordion} className={classes.paper}>
                <Grid 
                    container 
                    component={AccordionSummary} 
                    justifyContent='space-between' 
                    alignContent='center' 
                    className={classes.addSpacing} 
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Grid item container direction='column' xs={8}>
                        <Grid item>
                            <Typography variant='h5' className={classes.addSpacing}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{details}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} alignContent='center' justifyContent='flex-end'>
                        <Grid item>
                            <Button 
                                variant='outlined' 
                                color='primary' 
                                onClick={handleDenyRequest} 
                                className={classes.buttonSpacing}
                            >
                                Deny
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant='contained' 
                                color='primary' 
                                onClick={handleApproveRequest} 
                                className={classes.buttonSpacing}
                            >
                                Approve
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid component={AccordionDetails} container justifyContent='space-between' className={classes.addSpacing}>
                    <SingleShiftList 
                        shiftEvent={shiftOne} 
                        employeeId={employeeOne.id} 
                    />
                    <SingleShiftList 
                        shiftEvent={shiftTwo} 
                        employeeId={employeeTwo.id} 
                    />
                </Grid>
            </Paper>
        );
    }
};
 
export default MangShiftSwitchApprovalItem;