import React from 'react';
import { 
    Grid, Typography, makeStyles, Slider
} from '@material-ui/core';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentNeutralIcon from '../../assets/quizImages/SentimentNeutralIcon';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import PrefDesirability from './PrefDesirability';

const useStyles = makeStyles(() => ({
    coloredText: {
        color: '#8CADE1'
    },
    sliderBox: {
        marginTop: 16,
        maxWidth: 425,
        marginBottom: 16
    },
    button: {
        width: 125
    },
    disabled: {
        color: 'rgb(158,158,158)'
    }
}));

const DaysOfTheWeek = ({values, setFieldValue, setStatus, indicators}) => {
    const classes = useStyles();
    
    const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
    ];

    const marks = [
        {
            value: -2, 
            label: <MoodBadIcon style={{paddingLeft: 15}}/>
        },{
            value: -1, 
            label: <SentimentDissatisfiedIcon/>
        },{ 
            value: 0, 
            label: <SentimentNeutralIcon />
        },{
            value: 1, 
            label: <SentimentSatisfiedIcon />
        },{
            value: 2, 
            label: <MoodIcon style={{paddingRight: 15}}/>
        },
    ];

    const daysMap = {
        'Monday': values.monday,
        'Tuesday': values.tuesday,
        'Wednesday': values.wednesday,
        'Thursday': values.thursday,
        'Friday': values.friday,
        'Saturday': values.saturday,
        'Sunday': values.sunday
    };

    const daysToBackendMap = {
        'Monday': 'MONDAY',
        'Tuesday': 'TUESDAY',
        'Wednesday': 'WEDNESDAY',
        'Thursday': 'THURSDAY',
        'Friday': 'FRIDAY',
        'Saturday': 'SATURDAY',
        'Sunday': 'SUNDAY'
    };

    const handleChange = (event, value, day) => {
        const fields = {
            'Monday': 'monday',
            'Tuesday': 'tuesday',
            'Wednesday': 'wednesday',
            'Thursday': 'thursday',
            'Friday': 'friday',
            'Saturday': 'saturday',
            'Sunday': 'sunday'
        };
        setFieldValue(fields[day], value);
        setStatus(true);
    };
    
    return ( 
        <Grid container data-testid="daysoftheweekpreferences">
            <Grid item xs={12} >
                <Typography style={{marginBottom: 16, lineHeight: 1, fontSize: 18}}>
                    <b>Days of the Week</b>
                </Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: 8}}>
                <Typography>
                    How much do you like or dislike working each day?
                </Typography>
            </Grid>
            <Grid 
                item 
                container 
                direction='column' 
                alignItems='stretch'
                spacing={2}
                style={{marginTop: 8}}
            >
                {days.map(day => (
                    <Grid 
                        item 
                        container
                        justifyContent='space-between'
                        key={day}   
                        className={classes.sliderBox}
                    >
                        <Grid item >
                            <Typography variant='body1'>
                                {day}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Slider 
                                value={daysMap[day]}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="off"
                                step={1}
                                marks={marks}
                                min={-2}
                                max={2}
                                onChange={(e, value) => handleChange(e, value, day)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PrefDesirability 
                                value={daysMap[day]} 
                                indicator={indicators.find(indicator => (
                                    indicator.prefName === daysToBackendMap[day])
                                )}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};
 
export default DaysOfTheWeek;