import React from "react";
import { FieldArray } from "formik";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  withStyles,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { handleDeleteSkillSetConstraints } from "../../helpers/officeHelpers";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    borderBottom: "1px solid #f0f0f0",
    textAlign: "center",
    verticalAlign: "top",
  },
}))(TableCell);

const SkillCoverReq = (props) => {
  const alljobtypes = props.alljobtypes;
  const values = props.values;
  const setFieldValue = props.setFieldValue;
  const ErrorMessage = props.ErrorMessage;
  const handleChange = props.handleChange;
  const classes = props.classes;
  const name = props.name;
  const getDailyShifts = props.getDailyShifts.shiftTypes;
  const setshowdialog = props.setshowDialog;
  const setdialogmessage = props.setDialogMessage;
  const SetDuplicateRow = props.SetDuplicateRow;
  const setValuestoDelete = props.setValuestoDelete;

  return (
    <FieldArray name={props.name}>
      {({ push, remove }) => (
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={12} style={{ textAlign: "left" }}>
                <b>{`${
                  name === "staffRequirements"
                    ? `Staff Requirements for each shift by Job Type`
                    : `Unit Specific Requirements`
                }`}</b>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>Shift Name</StyledTableCell>
              <StyledTableCell>Mon</StyledTableCell>
              <StyledTableCell>Tue</StyledTableCell>
              <StyledTableCell>Wed</StyledTableCell>
              <StyledTableCell>Thu</StyledTableCell>
              <StyledTableCell>Fri</StyledTableCell>
              <StyledTableCell>Sat</StyledTableCell>
              <StyledTableCell>Sun</StyledTableCell>
              <StyledTableCell>{`${
                name === "staffRequirements" ? `Job Type` : `SkillSet`
              }`}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values &&
              values.length > 0 &&
              values.map((e, index) => (
                <TableRow key={index}>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.effectStart`}
                      name={`${name}.${index}.effectStart`}
                      variant="outlined"
                      type="date"
                      value={e.effectStart ? e.effectStart : ""}
                      onChange={(event) => {
                        setFieldValue(
                          `${name}.${index}.effectStart`,
                          event.target.value
                        );
                      }}
                    />
                    <ErrorMessage name={`${name}.${index}.effectstart`} />
                  </StyledTableCell>

                  <StyledTableCell>
                    <Select
                      id={`${name}.${index}.shiftType`}
                      name={`${name}.${index}.shiftType`}
                      value={e.shiftType}
                      variant="outlined"
                      className={classes.mediumText}
                      onChange={(event) => {
                        if (event.target.value === "All Day") {
                          setdialogmessage(
                            "Setting this value to All Day will set the skillset constraint for the entire day. Are you sure you want to change the value?"
                          );
                          setValuestoDelete(e);
                          setshowdialog(true);
                          setFieldValue(
                            `${name}.${index}.shiftType`,
                            event.target.value
                          );
                        }
                        else if (
                          values.find(
                            (x) =>
                              x.skill === event.target.value &&
                              x.shiftType === e.shiftType
                          )
                        ) {
                          setdialogmessage(
                            "A row with the same skillSet and ShiftType exists. Please update that row"
                          );
                          setshowdialog(true);
                          SetDuplicateRow(true);
                          setFieldValue(
                            `${name}.${index}.shiftType`,
                            event.target.value
                          );
                        } else {
                          if(e.shiftType==="All Day"){
                            setValuestoDelete(e);
                            setdialogmessage(
                              "The All Day constraint will be deleted with this change. Are you sure you want to change the value?"
                            );
                            setshowdialog(true);
                          }
                          setFieldValue(
                            `${name}.${index}.shiftType`,
                            event.target.value
                          );
                        }
                      }}
                    >
                      {getDailyShifts.map((i, index) => (
                        <MenuItem key={index} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name={`${name}.${index}.shiftType`} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.monday`}
                      name={`${name}.${index}.monday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.monday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.tuesday`}
                      name={`${name}.${index}.tuesday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.tuesday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.wednesday`}
                      name={`${name}.${index}.wednesday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.wednesday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.thursday`}
                      name={`${name}.${index}.thursday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.thursday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.friday`}
                      name={`${name}.${index}.friday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.friday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.saturday`}
                      name={`${name}.${index}.saturday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.saturday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      id={`${name}.${index}.sunday`}
                      name={`${name}.${index}.sunday`}
                      variant="outlined"
                      type="text"
                      className={classes.mediumText}
                      value={e.sunday}
                      onChange={handleChange}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Select
                      id={`${name}.${index}.skill`}
                      name={`${name}.${index}.skill`}
                      variant="outlined"
                      value={e.skill}
                      className={classes.inputWidth}
                      disabled={e.effectStart!="" && e.shiftType!="" && e.skill!=""?true:false}
                      onChange={(event) => {
                        if (
                          values.find(
                            (x) =>
                              x.skill === event.target.value &&
                              x.shiftType === e.shiftType
                          )
                        ) {
                          setdialogmessage(
                            "A row with the same skillSet and ShiftType exists. Please update that row"
                          );
                          setshowdialog(true);
                          SetDuplicateRow(true);
                          setFieldValue(
                            `${name}.${index}.skill`,
                            event.target.value
                          );
                        } else {
                          setFieldValue(
                            `${name}.${index}.skill`,
                            event.target.value
                          );
                        }
                      }}
                    >
                      {alljobtypes.length > 0 &&
                        alljobtypes.map((t, ind) => (
                          <MenuItem key={ind} value={t.name}>
                            {t.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <ErrorMessage name={`${name}.${index}.skill.name`} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        setdialogmessage(
                          "Are you sure you want to delete this skillset requirement?"
                        );
                        setshowdialog(true);
                        setValuestoDelete(e);
                        remove(index);
                      }}
                      style={{ padding: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}

            <TableRow>
              <StyledTableCell
                colSpan={4}
                style={{ textAlign: "left", borderBottom: "none" }}
              >
                <Box ml={3}>
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      push({
                        effectStart: "",
                        shiftType: "",
                        monday: 0,
                        tuesday: 0,
                        wednesday: 0,
                        thursday: 0,
                        friday: 0,
                        saturday: 0,
                        sunday: 0,
                        skill: "",
                      })
                    }
                  >{`${
                    name === "staffRequirements"
                      ? `Add Staff Requirements`
                      : `Add Training Requirements`
                  }`}</Button>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </FieldArray>
  );
};

export default SkillCoverReq;
