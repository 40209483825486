import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import CheckIcon from "@material-ui/icons/Check";
import { userVar, filterListVar } from "../../cache";
import PersonIcon from "@material-ui/icons/Person";
import { format } from "date-fns";

const useStyles = makeStyles(() => ({
  tooltip: {
    minWidth: 450,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
  label: {
    height: 19.5,
    borderRadius: 4,
    padding: 1,
    paddingLeft: 4,
  },
}));

const Label = ({ data, issueDates }) => {
  const classes = useStyles();
  const user = userVar();
  const filterList = filterListVar();
  const formatted = format(data.start, "MM/dd/yyyy");
  const slackIssueDate = issueDates.includes(formatted);

  if (data.type === "softRequest") {
    return (
      <>
        {data.highPriority ? (
          <Grid
            container
            wrap="nowrap"
            className={classes.label}
            // style={{backgroundColor: data.color}}
          >
            <Grid item>
              <PriorityHighIcon
                fontSize="small"
                style={{ marginBottom: -1, marginRight: 5 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">{data.eventTitle}</Typography>
            </Grid>
            <Grid item>
              <PersonIcon
                style={{
                  width: 14,
                  marginTop: -4,
                  marginLeft: 5,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            wrap="nowrap"
            className={classes.label}
            // style={{backgroundColor: data.color}}
          >
            <Grid item>
              <Typography variant="body2">{data.eventTitle}</Typography>
            </Grid>
            <Grid item>
              <PersonIcon
                style={{
                  width: 14,
                  marginTop: -4,
                  marginLeft: 5,
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  } else if (data.type === "timeOff") {
    const personal = data.employeeIds.includes(user.id);
    return (
      <Grid
        container
        wrap="nowrap"
        className={classes.label}
        // style={{backgroundColor: data.color}}
      >
        {data.status === "approved" && (
          <Grid item>
            <CheckIcon
              fontSize="small"
              style={{ marginBottom: -1, marginRight: 5 }}
            />
          </Grid>
        )}
        <Grid item>
          <Typography variant="body2">{data.eventTitle}</Typography>
        </Grid>
        {personal && (
          <Grid item>
            <PersonIcon
              style={{
                width: 14,
                marginTop: -4,
                marginLeft: 5,
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  } else if (data.employeeIds) {
    // const title = data.eventTitle.substr(0, data.eventTitle.indexOf('-'));
    // const participantsCount = data.participants.length;
    // const participantIds = data.participants.map(participant => participant.id);
    const personal = data.employeeIds.includes(user.id);
    return (
      <Grid
        container
        wrap="nowrap"
        className={classes.label}
        // style={{backgroundColor: data.color}}
      >
        <Grid item>
          <Typography variant="body2">
            {slackIssueDate &&
            data.missionId != null &&
            filterList.allFilters.includes("Not Scheduled")
              ? data.procedureName
              : data.eventTitle}
          </Typography>
        </Grid>
        {personal && (
          <Grid item>
            <PersonIcon
              style={{
                width: 14,
                marginTop: -4,
                marginLeft: 5,
              }}
            />
          </Grid>
        )}
      </Grid>
    );
    // } else if (data.type === 'staffing') {
    //     const under = issuesForDate.find(issue => (
    //         issue.lower > issue.assigned ||
    //         issue.slack < 0
    //     ));
    //     const over = issuesForDate.find(issue => (
    //         issue.upper < issue.assigned ||
    //         issue.slack > 0
    //     ));

    //     return (
    //         <Tooltip
    //             title={
    //                 <ShiftSlack
    //                     shift={data.original}
    //                     slacks={issuesForDate}
    //                 />
    //             }
    //             placement='left'
    //             arrow
    //             enterDelay={1000}
    //             enterNextDelay={500}
    //             classes={{tooltip: classes.tooltip}}
    //         >
    //             {under &&
    //                 <WarningIcon style={{
    //                     fontSize: 18,
    //                     marginTop: -4,
    //                     marginLeft: 4,
    //                     opacity: 0.95
    //                 }}/>
    //             }
    //             {over && !under &&
    //                 <AlertOutline style={{
    //                     fontSize: 18,
    //                     marginTop: -4,
    //                     marginLeft: 4,
    //                     opacity: 0.95
    //                 }}/>
    //             }
    //         </Tooltip>
    //     );
  } else {
    return (
      <Grid
        container
        wrap="nowrap"
        className={classes.label}
        // style={{backgroundColor: data.color}}
      >
        <Grid item>
          <Typography variant="body2">{data.eventTitle}</Typography>
        </Grid>
      </Grid>
    );
  }
};

export default Label;
