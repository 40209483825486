import React, { useState } from "react";
import {
  OFFICE_SCHDULING_INFO_MANAGER,
  GET_SCHEDULE_PERIODS,
} from "../../api/gqlQueries";
import {
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useQuery, useLazyQuery } from "@apollo/client";
import { userVar } from "../../cache";
import { differenceInCalendarDays } from "date-fns";

const DashboardFooter = (props) => {
  const user = userVar();
  const [officeSchedulingInfoManagers] = useLazyQuery(
    OFFICE_SCHDULING_INFO_MANAGER,
    {
      onCompleted(d) {
        SetOfficeScheduleInfo(d.officeSummary);
      },
    }
  );
  const [officeScheduleInfo, SetOfficeScheduleInfo] = useState();
  const SchedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onCompleted(d) {
      let currentSchedulePeriod = d.schedulePeriods.find(
        (e) => new Date(e.start) <= new Date() && new Date(e.end) >= new Date()
      );

      currentSchedulePeriod &&
        officeSchedulingInfoManagers({
          variables: {
            schedulePeriod: parseInt(currentSchedulePeriod.id),
          },
        });
    },
  });

  if (SchedulePeriods.loading) {
    return <CircularProgress color="primary" />;
  } else {
    let mostRecentSchedule =
      SchedulePeriods.data.schedulePeriods &&
      SchedulePeriods.data.schedulePeriods.length > 0 &&
      SchedulePeriods.data.schedulePeriods.filter(
        (e) =>
          e.status === "PUBLISHED" ||
          e.status === "MANAGER_DRAFT" ||
          e.status === "MANAGER DRAFT" ||
          e.status === "DRAFT" ||
          e.status === "REQUESTING_FLOATS" ||
          e.status === "REQUESTING_ADDITIONAL_FLOATS"
      );
    mostRecentSchedule &&
      mostRecentSchedule.length > 0 &&
      mostRecentSchedule.sort((a, b) => new Date(b.start) - new Date(a.start));
    let daysScheduled =
      mostRecentSchedule &&
      mostRecentSchedule.length > 0 &&
      differenceInCalendarDays(new Date(mostRecentSchedule[0].end), new Date());
    return (
      <div
        style={{
          display: "inline-block",
          fontWeight: 400,
          fontSize: 14,
          margin: 0,
          padding: 0,
        }}
      >
        <Table style={{ margin: 0, padding: 0 }}>
          <TableBody>
            <TableRow>
              <TableCell style={{ border: "none" }}>
                Days Scheduled{" "}
                {daysScheduled && daysScheduled > 0
                  ? daysScheduled + " days"
                  : ""}{" "}
                {" | "}
              </TableCell>
              <TableCell style={{ border: "none" }}>
                Scheduling Time{" "}
                {officeScheduleInfo &&
                  officeScheduleInfo.length > 0 &&
                  officeScheduleInfo[0].schedulingTime &&
                  officeScheduleInfo[0].schedulingTime + "min"}
                {" | "}
              </TableCell>
              <TableCell style={{ border: "none" }}>
                Schedules Considered{" "}
                {officeScheduleInfo &&
                  officeScheduleInfo.length > 0 &&
                  officeScheduleInfo[0].rostersConsidered &&
                  officeScheduleInfo[0].rostersConsidered}{" "}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
};

export default DashboardFooter;
