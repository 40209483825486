import React from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import thumbsup from '../../../assets/quizImages/thumbsup.png';
import thumbsdown from '../../../assets/quizImages/thumbsdown.png';

const useStyles = makeStyles(() => ({
    tile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    clicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    }
}));

const Overtime = ({values, setFieldValue}) => {
    const classes = useStyles();

    const handleClick = (value) => {
        if (value) {
            setFieldValue('overtime', 1);
        } else {
            setFieldValue('overtime', -1)
        }
    };

    return ( 
        <Grid container item direction='column' alignItems='center' spacing={5} style={{padding: 25}}>
            <Grid item align='center'>
                <Typography style={{fontSize: 24}}>Willing to work overtime if asked?</Typography>
            </Grid>
            <Grid item align='center'>
                <Typography style={{fontSize: 18}}>
                    This question will not affect your initial schedule but will be taken into consideration when looking for employees to fill gaps in the schedule. 
                </Typography>
            </Grid>
            <Grid item container justifyContent='center' style={{padding: 50, paddingTop: 16, paddingBottom: 16}}>
                <Grid 
                    component={Paper} 
                    className={values.overtime > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleClick(true)}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Yes</Typography>
                    <img 
                        alt='yes' 
                        src={thumbsup} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.overtime < 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleClick(false)}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>No</Typography>
                    <img 
                        alt='no' 
                        src={thumbsdown} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default Overtime;