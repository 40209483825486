import React, { useState } from 'react';
import { 
    Typography, makeStyles, Paper, Grid,
    Dialog, DialogContent, Snackbar
} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { format, isSameDay } from 'date-fns';
import {
    ScheduleComponent, Month, Inject,
    ViewDirective, ViewsDirective
} from "@syncfusion/ej2-react-schedule";
import MangAddEmployees from '../floatCalendar/MangAddEmployees';
import ShiftSlackFloatTable from './ShiftSlackFloatTable';

const useStyles = makeStyles((theme) => ({
    whiteText: {
        color: '#fff'
    }, 
    tooltip: {
        minWidth: 600
    },
    today: {
        background: '#F15A25', 
        borderRadius: 30,
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 1,
        paddingBottom: 1
    },
}));

const ShiftSlackCalendar = ({events}) => {
    const classes = useStyles();

    const [showAddEmployees, setShowAddEmployees] = useState(false);
    const [eventObject, setEventObject] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);

    const eventTemplate = (props) => {
        return (
            <div className="templatewrap" style={{paddingLeft: 4}}>
                {props.title}
            </div>
        );
    };

    const tooltipTemplate = (props) => {
        return (
            <div className="templatewrap" >
                <ShiftSlackFloatTable
                    needsByOffice={props.officeData}
                    tooltip={true}
                />
            </div>
        );
    };

    const cellHeaderTemplate = (props) => {
        const today = isSameDay(new Date(), props.date);

        return (
            <Grid container justifyContent='flex-start'>
                <Grid item style={{marginLeft: 2}}>
                    <Typography
                        variant='body2' 
                        className={today ? classes.today : null}
                    >
                        {format(props.date, 'd')}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const handleEventClick = (args) => {
        const { event } = args;
        setEventObject(event);
        setShowAddEmployees(true);
    };

    const onPopupOpen = (args) => {
        args.cancel = true;
    };

    return (
        <>
            <div id="schedule">
                <Paper>
                    <ScheduleComponent
                        // selectedDate={selectedDate}
                        currentView="Month"
                        height="750px"
                        delayUpdate={true}
                        eventSettings={{
                            dataSource: events,
                            fields: {
                                id: "id",
                                subject: { name: "title" },
                                startTime: { name: "start" },
                                endTime: { name: "end" },
                                // isAllDay: { name: "isAllDay" },
                                // description: { name: "description" },
                            },
                            template: eventTemplate,
                            tooltipTemplate: tooltipTemplate,
                            enableTooltip: true,
                            allowEditing: false
                        }}
                        // cssClass="print excel-export calendar-component"
                        // ref={ref}
                        allowDragAndDrop={false}
                        workDays={[0, 1, 2, 3, 4, 5, 6]}
                        workHours={{ start: "00:00", end: "23:59" }}
                        rowAutoHeight={true}
                        // quickInfoTemplates={{
                        //     header: quickInfoHeader
                        // }}
                        // showTimeIndicator={false}
                        // navigating={changeView}
                        // resourceHeaderTemplate={resourceHeaderTemplate}
                        // enableTooltip={true}
                        // cellClick={handleCellClick}
                        eventClick={handleEventClick}
                        popupOpen={onPopupOpen}
                        // readonly={user.isAdmin || user.isManager ? false : true}
                    >
                        <ViewsDirective>
                            <ViewDirective
                                option="Month"
                                isSelected={true}
                                cellHeaderTemplate={cellHeaderTemplate}
                            />
                        </ViewsDirective>
                        <Inject services={[Month]} />
                    </ScheduleComponent>
                </Paper>
            </div>
            <Dialog open={showAddEmployees} fullWidth maxWidth="md">
                <DialogContent style={{ 
                    padding: 30, 
                    height: 700, 
                    position: "relative" 
                }}>
                    <MangAddEmployees 
                        event={eventObject}
                        closeDialog={() => setShowAddEmployees(false)}
                        setShowToast={setShowToast}
                        setShowErrorToast={setShowErrorToast}
                        singleShift={false}
                    />
                </DialogContent>
            </Dialog>
            <Snackbar
                open={showToast}
                autoHideDuration={3000}
                onClose={() => setShowToast(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert
                    elevation={6}
                    onClose={() => setShowToast(false)}
                    severity="success"
                >
                    <Typography>
                        Employees successfully added!
                    </Typography>
                </MuiAlert>
            </Snackbar>
            <Snackbar
                open={showErrorToast}
                autoHideDuration={3000}
                onClose={() => setShowErrorToast(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MuiAlert
                    elevation={6}
                    onClose={() => setShowErrorToast(false)}
                    severity="error"
                >
                    <Typography>
                        Could not add employees. Please try again.
                    </Typography>
                </MuiAlert>
            </Snackbar>
        </>
    )
}
 
export default ShiftSlackCalendar;