import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Asynchronous(props) {
  const opt = props.options;
  const val = props.val;
  const setFieldValue = props.onChange;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open;
  const getOptionSelected = props.getOptionSelected;
  const getOptionLabel = props.getOptionLabel;

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    setOptions(opt);
    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      multiple
      id={props.id}
      name={props.name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disableCloseOnSelect
      onChange={(e, newValue) => {
        if (props.name === "certifications") {
          setFieldValue("certifications", newValue);
        } else if (props.name === "education") {
          setFieldValue("education", newValue);
        } else if (props.name === "training") {
          setFieldValue("training", newValue);
        } else if (props.name === "license") {
          setFieldValue("license", newValue);
        } else if (props.name === "secondaryDepartments") {
          setFieldValue("secondaryDepartments", newValue);
        } else if (
          props.name === "skills" ||
          props.name === "employeesToSelect"
        ) {
          props.onChange(newValue);
        } else {
          setFieldValue(props.name, newValue);
        }
        props.autoClose && setOpen(false);
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      value={val}
      getOptionDisabled={(option) => option.name === "none"}
      placeholder={props.placeholder}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={props.placeholder}
        />
      )}
    />
  );
}
