import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import happycalendar from '../../../assets/quizImages/happycalendar.png';
import sadcalendar from '../../../assets/quizImages/sadcalendar.png';
import dayscalendar from '../../../assets/quizImages/dayscalendar.png';
import moon from '../../../assets/quizImages/moon.png';
import sun from '../../../assets/quizImages/sun.png';

const useStyles = makeStyles(() => ({
    tile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    clicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    }
}));

const MostImportantCategories = ({values, setFieldValue}) => {
    const classes = useStyles();

    // const {
    //     day, night, swing, 
    //     monday, tuesday, wednesday, 
    //     thursday, friday, saturday, 
    //     sunday, oneDay, twoDays, 
    //     threeDays, fourDays
    // } = values;

    // const [mostImportant, setMostImportant] = useState([]);

    // const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    // const categoriesToValueNames = {
    //     'Shift Type': ['day', 'night', 'swing'],
    //     'Days Most Prefer': days,
    //     'Days Least Prefer': days,
    //     'Days Off': ['oneDay', 'twoDays', 'threeDays', 'fourDays']
    // }

    // useEffect(() => {
    //     return function setValues() {
    //         console.log('running')
    //     }
    // }, []);

    const handleImportanceClick = (category) => {
        const newImportant = [...values.mostImportant];
        const index =  newImportant.indexOf(category);
        if(index === -1){
            newImportant.push(category);
            
        } else {
            newImportant.splice(index, 1);
        }
        setFieldValue('mostImportant', newImportant);
    }
    
    return (  
        <Grid container item direction='column' spacing={5} style={{paddingTop: 25}}>
            <Grid item>
                <Typography align='center' style={{fontSize: 24}}>Which of the categories we just walked through are the most important to you when thinking about your schedule? You can choose more than one!</Typography>
            </Grid>
            <Grid item container justifyContent='center' style={{padding: 50, paddingTop: 0, paddingBottom: 0}}>
                <Grid 
                    component={Paper} 
                    className={values.mostImportant.includes('Shift Type') ? classes.clicked : classes.tile} 
                    onClick={() => handleImportanceClick('Shift Type')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Shift Type</Typography>
                    <img alt='night shift' src={moon} style={{position: 'absolute', bottom: 10, left:10, height: 85, width: 85}}/>
                    <img alt='day shift' src={sun} style={{position: 'absolute', top: 40, right:10, height: 100, width: 100}}/>
                </Grid>
                <Grid
                    component={Paper} 
                    className={values.mostImportant.includes('Days Off') ? classes.clicked : classes.tile} 
                    onClick={() => handleImportanceClick('Days Off')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Days Off in a Row</Typography>
                    <img alt='days off in a row' src={dayscalendar} style={{position: 'absolute', bottom: 10, left:32, height: 110, width: 110}}/>
                </Grid>
            </Grid>
            <Grid item container justifyContent='center' style={{padding: 50, paddingTop: 0, paddingBottom: 0}}>
                <Grid
                    component={Paper} 
                    className={values.mostImportant.includes('Days Most Prefer') ? classes.clicked : classes.tile} 
                    onClick={() => handleImportanceClick('Days Most Prefer')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Days of the Week Most Prefer to Work</Typography>
                    <img alt='days prefer' src={happycalendar} style={{position: 'absolute', bottom: 10, left:32, height: 110, width: 110}}/>
                </Grid>
                <Grid
                    component={Paper} 
                    className={values.mostImportant.includes('Days Least Prefer') ? classes.clicked : classes.tile} 
                    onClick={() => handleImportanceClick('Days Least Prefer')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Days of the Week Least Prefer to Work</Typography>
                    <img alt='days prefer' src={sadcalendar} style={{position: 'absolute', bottom: 10, left:32, height: 110, width: 110}}/>
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default MostImportantCategories;