import React, { useEffect } from "react";
import "./MissionCalendar.css";
import {
  Typography,
  IconButton,
  Button,
  Paper,
  Grid,
  makeStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { format, isAfter, isSameDay } from "date-fns";
import WeekLabel from "./WeekLabel";
import Label from "./Label";
import { useQuery } from "@apollo/client";
import { userVar, selectedDateVar } from "../../cache";
import Roles from "../../Roles/roles";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  Inject,
  ViewDirective,
  ViewsDirective,
  TimelineViews,
  ResourcesDirective,
  ResourceDirective,
  Print,
  ICalendarExport,
  ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import {
  GET_EMPLOYEE_SKILL,
  FIND_OFFICE_CONSTRAINT,
} from "../../api/gqlQueries";
import WarningIcon from "@material-ui/icons/Warning";
import AlertOutline from "mdi-material-ui/AlertOutline";
import CloseIcon from "@material-ui/icons/Close";
import ShiftSlack from "../slacks/ShiftSlack";
import EditIcon from "@material-ui/icons/Edit";
import { compareAsc } from "date-fns";
import { useField } from "formik";

const useStyles = makeStyles(() => ({
  today: {
    background: "#F15A25",
    borderRadius: 30,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tooltip: {
    minWidth: 450,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
}));

const Scheduler = React.forwardRef((props, ref) => {
  const {
    events,
    draftStart,
    draftEnd,
    openStart,
    openEnd,
    employeeResources,
    missionResources,
    view,
    setView,
    toggleEditRequest,
    toggleCallInForm,
    toggleShiftSwitchForm,
    issueDates,
    slackIssues,
    handleEditClick,
    handleEditProcedureReq,
    setShiftToChange,
    allowCallIns,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;
  const classes = useStyles();

  const user = userVar();
  const selectedDate = selectedDateVar();

  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  useEffect(() => {
    if (view === "mission") {
      clickMissionTimeline();
    } else if (view === "employee") {
      clickEmployeeTimeline();
    } else if (view === "Day") {
      clickDayView();
    } else if (view === "Month") {
      clickMonthView();
    }
  }, [events]);

  function clickMonthView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_4");
      button && button.click();
    }, 200);
  }

  function clickMissionTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_5");
      button && button.click();
    }, 500);
  }

  function clickEmployeeTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_6");
      button && button.click();
    }, 500);
  }

  function clickDayView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_7");
      button && button.click();
    }, 500);
  }

  function changeView(props) {
    if (props.action === "view") {
      if (props.currentView === "TimelineDay") {
        setView("mission");
        clickMissionTimeline();
      } else if (props.currentView === "TimelineWeek") {
        setView("employee");
        clickEmployeeTimeline();
      } else if (props.currentView === "Day") {
        setView("day");
        clickDayView();
      } else if (props.currentView === "Month") {
        setView("month");
        clickMonthView();
      }
    }
  }

  const monthEventTemplate = (props) => {
    return (
      <div className="templatewrap">
        <Label data={props} issueDates={issueDates} />
      </div>
    );
  };

  const weekMissionTemplate = (props) => {
    let skills =
      props.skills && props.skills.length > 0 && props.skills.map((e) => e);
    return (
      <div className="templatewrap">
        <WeekLabel data={props} skills={skills} />
      </div>
    );
  };

  const weekEmployeeTemplate = (props) => {
    const formattedStart = format(props.start, "HH:mm");
    const formattedEnd = format(props.end, "HH:mm");
    const title = `${formattedStart} - ${formattedEnd}`;
    return (
      <div className="template-wrap">
        {props.type === "softRequest" || props.type === "timeOff" ? (
          props.eventTitle
        ) : (
          <>{title}</>
        )}
      </div>
    );
  };

  const dayViewTemplate = (props) => {
    if (props.missionId === "GHOC") {
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.missionId}
          </Typography>
          <Typography>{props.eventTitle}</Typography>
          <br />
          {props.participants.map((participant) => (
            <React.Fragment key={participant.id}>
              <Typography>
                {participant.firstName} {participant.lastName}
              </Typography>
              <Typography variant="body2">
                {participant.skillSet &&
                  participant.skillSet.map((e) => e.name).join(", ")}
              </Typography>
              <br />
            </React.Fragment>
          ))}
        </div>
      );
    } else if (props.participants && props.participants.length > 0) {
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.missionId}
          </Typography>
          <Typography>{props.eventTitle}</Typography>
          <br />
          {props.participants.map((participant) => (
            <React.Fragment key={participant.id}>
              <Typography>
                {participant.firstName} {participant.lastName}
              </Typography>
              <Typography variant="body2">
                {participant.skillSet &&
                  participant.skillSet.length > 0 &&
                  participant.skillSet.map((e) => e.name).join(", ")}
              </Typography>
              <br />
            </React.Fragment>
          ))}
        </div>
      );
    } else if (props.type === "timeOff" || props.type === "softRequest") {
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.eventTitle}
          </Typography>
          {props.comment ? <Typography>{props.comment}</Typography> : null}
          <br />
        </div>
      );
    } else {
      const startTime = format(props.start, "HH:mm");
      const endTime = format(props.end, "HH:mm");
      const title = `${startTime} - ${endTime}`;
      return (
        <div className="templatewrap">
          <Typography style={{ fontWeight: "bold" }}>
            {props.missionId}
          </Typography>
          <Typography>{title}</Typography>
          <br />
          <Typography>{props.eventTitle}</Typography>
        </div>
      );
    }
  };

  const resourceHeaderTemplate = (props) => {
    return (
      <div className="template-wrap">
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>
          {props.resourceData.name}
        </Typography>
      </div>
    );
  };

  const cellHeaderTemplate = (props) => {
    const today = isSameDay(new Date(), props.date);
    const formatted = format(props.date, "MM/dd/yyyy");
    const slackIssueDate = issueDates.includes(formatted);
    let issuesForDate;
    let under;
    let over;
    if (slackIssueDate) {
      issuesForDate = slackIssues.filter((slack) => {
        const startDate = new Date(slack.interval.start);
        return isSameDay(startDate, props.date);
      });
      under = issuesForDate.find(
        (issue) => issue.lower > issue.assigned || issue.slack < 0
      );
      over = issuesForDate.find(
        (issue) => issue.upper < issue.assigned || issue.slack > 0
      );
    }

    const draft =
      (new Date(props.date) > new Date(draftStart) ||
        isSameDay(new Date(props.date), new Date(draftStart))) &&
      (new Date(props.date) < new Date(draftEnd) ||
        isSameDay(new Date(props.date), new Date(draftEnd)));

    const open =
      (new Date(props.date) > new Date(openStart) ||
        isSameDay(new Date(props.date), new Date(openStart))) &&
      (new Date(props.date) < new Date(openEnd) ||
        isSameDay(new Date(props.date), new Date(openEnd)));

    return (
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" className={today ? classes.today : null}>
            {format(props.date, "d")}
          </Typography>
        </Grid>
        <Grid item>
          {draft && (
            <Typography variant="body2" style={{ color: "#8CADE1" }}>
              Draft
            </Typography>
          )}
          {open && (
            <Typography variant="body2" style={{ color: "#8CADE1" }}>
              Open
            </Typography>
          )}
        </Grid>
        <Grid item>
          {slackIssueDate && (
            <Tooltip
              title={
                <ShiftSlack startDate={props.date} slacks={issuesForDate} />
              }
              placement="left"
              arrow
              enterDelay={500}
              enterNextDelay={500}
              classes={{ tooltip: classes.tooltip }}
            >
              <span>
                {under && (
                  <WarningIcon
                    style={{
                      fontSize: 16,
                      marginBottom: -3,
                      color: "black",
                    }}
                  />
                )}
                {over && !under && (
                  <AlertOutline
                    style={{
                      fontSize: 16,
                      marginBottom: -3,
                      color: "black",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  };

  const closeQuickInfo = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.closeQuickInfoPopup();
  };

  const quickInfoHeader = (props) => {
    const personal = props.employeeIds?.includes(user.id);
    return (
      <div>
        <Grid container justifyContent="flex-end">
          <Grid item style={{ marginTop: 8, marginRight: 8 }}>
            {managerAccess &&
            props.type !== "softRequest" &&
            props.type !== "timeOff" ? (
              props.participants && props.participants.length === 0 ? (
                props.start > new Date() ? (
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => handleEditProcedureReq(props)}
                  >
                    <EditIcon style={{ color: "#000", fontSize: 18 }} />
                  </IconButton>
                ) : null
              ) : (
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => handleEditClick(props)}
                >
                  <EditIcon style={{ color: "#000", fontSize: 18 }} />
                </IconButton>
              )
            ) : null}
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeQuickInfo}
            >
              <CloseIcon style={{ color: "#000", fontSize: 18 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          {props.type === "timeOff" ? (
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ marginLeft: 8, marginBottom: 8 }}
              >
                {props.eventTitle}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography variant="h4" style={{ marginLeft: 8 }}>
                {props.eventTitle}
              </Typography>
            </Grid>
          )}
          {props.type !== "timeOff" ? (
            props.tasks && props.tasks.length === 0 ? null : (
              <Grid item container xs={6} justifyContent="flex-end">
                {allowCallIns && (
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={!personal}
                      onClick={() => {
                        setShiftToChange(props.eventId);
                        toggleCallInForm();
                      }}
                    >
                      Call In
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    color="primary"
                    disabled={managerAccess ? false : !personal}
                    onClick={() => {
                      setShiftToChange(props.eventId);
                      toggleShiftSwitchForm();
                    }}
                    style={{ marginLeft: 4, marginRight: 8 }}
                  >
                    Switch Shifts
                  </Button>
                </Grid>
              </Grid>
            )
          ) : null}
        </Grid>
      </div>
    );
  };

  const handleCellClick = (args) => {
    const { startTime } = args;
    selectedDateVar(startTime);
    // SetDateSelected(startTime);
    // toggleTimeOffForm();
    // if (isAfter(startTime, new Date(scheduleEndDate)) &&
    //   !offRequestDates.includes(format(startTime, 'MM/dd/yyyy'))
    // ) {
    //   setAnchorEl(element);
    // }
  };

  const handleEventClick = (args) => {
    const { event } = args;
    event && selectedDateVar(event.start);
    if (event.type === "softRequest" || event.category === "personal") {
      toggleEditRequest(event.type, event.eventId);
    }
  };

  const onPopupOpen = (args) => {
    const { data } = args;

    if (args.element.className.includes("e-more-popup-wrapper")) {
      args.cancel = false;
    } else if (
      !data.eventId ||
      data.type === "softRequest" ||
      data.category === "personal"
    ) {
      args.cancel = true;
    }
  };

  const onActionBegin = (args) => {
    const { event } = args;
  };
  const onDragStop = (args) => {
    const { event } = args;
  };

  return (
    <div id="schedule">
      <Paper>
        <ScheduleComponent
          selectedDate={selectedDate}
          currentView="Month"
          height="auto"
          delayUpdate={true}
          rowAutoHeight={true}
          eventSettings={{
            dataSource: events,
            fields: {
              id: "id",
              subject: { name: "eventTitle" },
              startTime: { name: "start" },
              endTime: { name: "end" },
              isAllDay: { name: "isAllDay" },
              description: { name: "comment" },
            },
          }}
          cssClass="missionCalendar print excel-export calendar-component"
          ref={ref}
          allowDragAndDrop={false}
          workDays={[0, 1, 2, 3, 4, 5, 6]}
          workHours={{ start: "00:00", end: "23:59" }}
          showTimeIndicator={false}
          navigating={changeView}
          resourceHeaderTemplate={resourceHeaderTemplate}
          enableTooltip={true}
          cellClick={handleCellClick}
          eventClick={handleEventClick}
          popupOpen={onPopupOpen}
          actionBegin={onActionBegin}
          dragStop={onDragStop}
          quickInfoTemplates={{
            header: quickInfoHeader,
          }}
        >
          <ViewsDirective>
            <ViewDirective
              option="Month"
              isSelected={true}
              cellHeaderTemplate={cellHeaderTemplate}
              eventTemplate={monthEventTemplate}
              group={{ byDate: true, allowGroupEdit: true }}
            />
            <ViewDirective
              option="TimelineDay"
              displayName="Mission Timeline"
              timeScale={{ interval: 720, slotCount: 4 }}
              group={{ resources: ["Missions"] }}
              eventTemplate={weekMissionTemplate}
              interval={7}
            />
            <ViewDirective
              option="TimelineWeek"
              displayName="Employees Timeline"
              timeScale={{ enable: false }}
              group={{ resources: ["Employees"] }}
              eventTemplate={weekEmployeeTemplate}
            />
            <ViewDirective option="Day" eventTemplate={dayViewTemplate} />
            <ViewDirective option="Print" />
          </ViewsDirective>
          <ResourcesDirective>
            <ResourceDirective
              field="missionId"
              title="Missions"
              name="Missions"
              textField="name"
              idField="id"
              colorField="color"
              allowMultiple={true}
              dataSource={missionResources}
            ></ResourceDirective>
            <ResourceDirective
              field="employeeIds"
              title="Employee Name"
              name="Employees"
              textField="name"
              idField="id"
              colorField="color"
              allowMultiple={true}
              dataSource={employeeResources}
            ></ResourceDirective>
          </ResourcesDirective>
          <Inject
            services={[
              Day,
              Week,
              TimelineViews,
              Month,
              Agenda,
              ICalendarExport,
              ExcelExport,
              Print,
            ]}
          />
        </ScheduleComponent>
      </Paper>
    </div>
  );
});

function areEqual(prevProps, nextProps) {
  return (
    prevProps.events.length === nextProps.events.length &&
    prevProps.employeeResources.length === nextProps.employeeResources.length &&
    prevProps.missionResources === nextProps.missionResources &&
    nextProps.missionResources.length === nextProps.missionResources.length
  );
}

export default React.memo(Scheduler, areEqual);
