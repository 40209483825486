import { format } from "date-fns";
import OfficeDisplayOrder from "./OfficeDisplayOrder.json";
/* The scheduler we are using is Syncfusion. Syncfusion accepts props in the format
     {
      Id: unique ID for the event,
      StartTime: event start time,
      EndTime: event end time,\
      IsAllDay: indicates of event is all day
    };
    This helper method formats the shifts retrieved from database from GET_SHIFTS graphql query and returns data in the format accepted by Syncfusion.
    Time-Off Soft-Time off and logged in user's events are formatted in this helper. 
*/

export const orderShifts = (shifts) => {
  const shiftNames = [];
  if (shifts.length > 0) {
    let formatted = [];
    shifts.forEach((shift) => {
      const start = new Date(shift.start);
      const end = new Date(shift.end);
      const formattedStart = format(start, "HH:mm");
      const formattedEnd = format(end, "HH:mm");
      const monthViewEnd =
        shift.shiftTypeName === "Night" ||
        (shift.shiftTypeName === "Swing" && parseInt(format(end, "HH")) < 12)
          ? format(start, "yyyy-MM-dd") + "T" + "23:59:00"
          : end;
      const title = `${formattedStart} - ${formattedEnd}`;
      let nameOfTheShift = shiftNames.find(
        (e) =>
          e.title === title &&
          e.office === shift.officeName &&
          e.description === shift.description
      );
      if (nameOfTheShift === "undefined" || !nameOfTheShift) {
        shiftNames.push({
          title: title,
          office: shift.officeName,
          description: shift.description,
          startHour: formattedStart,
          endHour: formattedEnd,
          displayOrder: OfficeDisplayOrder.offices.find(
            (e) => e.name === shift.officeName
          )
            ? OfficeDisplayOrder.offices.find(
                (e) => e.name === shift.officeName
              ).displayPriority
            : "",
        });
      }
      const shiftAssignments = shift.shiftassignmentSet;

      const participants = shiftAssignments.map((shiftAssignment) => {
        const float =
          shiftAssignment.employee.offices &&
          shiftAssignment.employee.offices.find((e) => e.floatStatus);
        return {
          ...shiftAssignment,
          isFromFloatOffice: float ? true : false,
        };
      });

      const participantIds = participants
        .map((participant) => participant.employeeId)
        .filter(Boolean);

      formatted.push({
        eventId: shift.id,
        idOfShift: shift.id,
        start: start,
        end: end,
        monthViewEnd: monthViewEnd,
        participants: [...participants],
        eventTitle: title,
        employeeIds: [...participantIds],
        shiftId: title + " " + shift.officeName,
        office: shift.officeName,
        description: shift.description,
        shiftType: shift.shiftTypeName,
      });
      if (participants.length > 0) {
        participants.forEach((participant) => {
          let uniqueSkills = [];
          let skillSet =
            participant.skillSet &&
            participant.skillSet.map((e) => {
              return {
                id: e.id,
                skillId: e.skillId,
                name: e.name,
                isActive: e.isActive,
              };
            });
          formatted.push({
            eventId: shift.id,
            idOfShift: shift.id,
            shiftName: title,
            start: start,
            end: end,
            monthViewEnd: monthViewEnd,
            participant: participant,
            eventTitle: `${participant.employee}`,
            shiftId: title + " " + shift.officeName,
            calendars: ["shift"],
            originalParticipants: [...participants],
            skills: skillSet,
            skillNames:
              participant.skillSet &&
              participant.skillSet.length > 0 &&
              participant.skillSet
                .map(
                  (e) =>
                    !uniqueSkills.find((s) => s === e.name) &&
                    uniqueSkills.push(e.name) &&
                    e.name != "APP" &&
                    e.name
                )
                .filter(Boolean),
            office: shift.officeName,
            description: shift.description,
            shiftType: shift.shiftTypeName,
          });
        });
      }
      else{
        formatted.push({
          idOfShift: shift.id,
          start: start,
          end: end,
          calendars: ["shift"],
          office: shift.officeName
        });
      }
    });
    formatted = formatted.filter((event) => event !== null);
    formatted.sort((a, b) => a.start - b.start);
    return { formatted, shiftNames };
  } else {
    return {
      formatted: [],
      shiftNames: [],
    };
  }
};

export const formatSoftRequests = (requests, userId) => {
  return requests.map((request) => {
    const highPriority = request.value > 5;
    return {
      eventId: request.id,
      eventTitle: "Soft Time Off",
      start: new Date(`${request.date}T08:00:00`),
      end: new Date(`${request.date}T09:00:00`),
      type: "softRequest",
      highPriority,
      employeeIds: [userId],
      isAllDay: true,
      shiftId: "Requests",
    };
  });
};

export const formatTimeOff = (requests, userId) => {
  return (
    requests &&
    requests.map((request) => {
      const name = request.employee.firstName + " " + request.employee.lastName;
      const personal = parseInt(request.employee.id) === parseInt(userId);

      const newRequest = {
        eventId: request.id,
        eventTitle: `${name} - ${request.type.name}`,
        type: "timeOff",
        status: request.approvedby ? "approved" : "pending",
        category: personal ? "personal" : "employee",
        employeeIds: [request.employee.id],
        approvedby: request.approvedby,
        deniedText: request.deniedText,
        userCancelled: request.userCancelled,
        workHours: request.workHours,
        typeId: request.type.id,
        comment: request.comment,
        shiftId: "Requests",
        createdAt: request.createdAt,
        office: request.office.name,
      };

      if (request.startTime && request.endTime) {
        newRequest.start = new Date(`${request.firstday} ${request.startTime}`);
        newRequest.end = new Date(`${request.lastday} ${request.endTime}`);
        newRequest.isAllDay = false;
      } else {
        newRequest.start = new Date(`${request.firstday}T08:00:00`);
        newRequest.end = new Date(`${request.lastday}T08:00:00`);
        newRequest.isAllDay = true;
      }
      return newRequest;
    })
  );
};
