import { isSameDay } from "date-fns";

export const selectEmployees = (events, shift, date, setError) => {
  const eventsByDate = events.filter((event) =>
    isSameDay(new Date(event.start), new Date(date))
  );

  let employees;

  if (eventsByDate.length === 0) {
    setError("Invalid date or shift selection");
  } else if (shift && shift === "All Shifts") {
    const repetitiveEmployees = eventsByDate
      .map((event) => event.participants || event.originalParticipants)
      .flat();

    const uniqueEmployees = new Set(repetitiveEmployees);
    employees = Array.from(uniqueEmployees);
    employees = employees.filter((employee) => employee);
    setError("");
  } else if (shift) {
    const matchingShift = eventsByDate.find((event) => {
      let office = shift.split(",")[1];
      let shiftName = shift.split(",")[0];
      let shiftThatMatches =
        (event.eventTitle === shiftName && event.office === office) ||
        (event.shiftName === shiftName && event.office === office);
      return shiftThatMatches;
    });
    if (matchingShift) {
      employees =
        matchingShift.participants || matchingShift.originalParticipants;
      employees = employees.filter((employee) => employee);
      setError("");
    } else {
      setError("Invalid date or shift selection");
    }
  } else {
    const repetitiveEmployees = eventsByDate
      .map((event) => event.participants || event.orgininalParticipants)
      .flat();
    const uniqueEmployees = new Set(repetitiveEmployees);
    employees = Array.from(uniqueEmployees);
    employees = employees.filter((employee) => employee);
    setError("");
  }
  return employees;
};

export const findEmployeesToSwitch = (
  currentDateParticipants,
  desiredDateParticipants,
  jobTypes
) => {
  const currentDateIds = currentDateParticipants.map((user) => user.id);
  const desiredDateIds = desiredDateParticipants.map((user) => user.id);
  const eligibleIds = desiredDateIds.filter(
    (id) => !currentDateIds.includes(id)
  );
  const eligibleEmployees = desiredDateParticipants.filter((user) =>
    eligibleIds.includes(user.id)
  );

  return eligibleEmployees;
};
