import React, { useState } from "react";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import ImportEmployees from "./ImportEmployees";
import ImportProcedures from "./ImportProcedures";
import ImportTrainings from "./ImportTrainings";
import ImportAvailability from "./ImportAvailability";
import ImportEmployeeTrainings from "./ImportEmployeeTrainings";
import { officeConfigVar } from "../../cache";
import { NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import ErrorSnackBar from "../errors/errorSnackBar";
import { useMutation } from "@apollo/client";
const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  select: {
    width: 200,
  },
}));

const ImportData = () => {
  const classes = useStyles();
  const [selectedImport, setSelectedImport] = useState(0);
  const officeConfigVariable = officeConfigVar();
  const environment = process.env.NODE_ENV;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  return (
    <Grid container direction="row" className={classes.headerSpacing}>
      <ErrorSnackBar
        open={openSnackBar}
        message={snackBarMessage}
        close={() => setOpenSnackBar(false)}
      />
      <Grid item xs={4}>
        <Typography variant="h3">Import Data</Typography>
      </Grid>
      <Grid item xs={4}>
        <Select
          variant="outlined"
          value={selectedImport}
          onChange={(e) => setSelectedImport(parseInt(e.target.value))}
          className={classes.select}
        >
          {officeConfigVariable.employeeImports && (
            <MenuItem key={0} value={0}>
              Import Employees
            </MenuItem>
          )}
          {officeConfigVariable.skillImports && (
            <MenuItem key={1} value={1}>
              Import Skills
            </MenuItem>
          )}
          {officeConfigVariable.trainingImports && (
            <MenuItem key={2} value={2}>
              Import Employee Trainings
            </MenuItem>
          )}
          {officeConfigVariable.procedureImports && (
            <MenuItem key={3} value={3}>
              Import Procedures
            </MenuItem>
          )}
          {officeConfigVariable.availabilityImports && (
            <MenuItem key={4} value={4}>
              Import Availability
            </MenuItem>
          )}
        </Select>
      </Grid>
      {selectedImport === 0 && (
        <ImportEmployees
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
        />
      )}
      {selectedImport === 1 && <ImportTrainings />}
      {selectedImport === 2 && (
        <ImportEmployeeTrainings
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
        />
      )}
      {selectedImport === 3 && (
        <ImportProcedures
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
        />
      )}
      {selectedImport === 4 && (
        <ImportAvailability
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
        />
      )}
    </Grid>
  );
};

export default ImportData;
