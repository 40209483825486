import React, { useState } from "react";
import {
  makeStyles,
  Stepper,
  StepButton,
  Button,
  Typography,
  Step,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import DepartmentTrainings from "./DepartmentTrainings";
import DepartmentJobTitles from "./DepartmentJobTypes";
import OfficeShifts from "./OfficeShifts";
import ErrorIcon from "@material-ui/icons/Error";
import UpdateOfficeForm from "./updateOfficeForm";
import EmploymentTypeSettings from "./EmploymentTypeSettings";
import DepartmentSettings from "./DepartmentSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Add Job Types",
    "Add Unit Specific Skillset Requirements",
    "Add Department Settings",
    "Add Shifts",
    "Add Employment Type Settings",
    "Add Shift Requirements",
  ];
}

function getStepContent(step, office) {
  switch (step) {
    case 0:
      return (
        <DepartmentJobTitles office={office.id} officename={office.name} />
      );
    case 1:
      return (
        <DepartmentTrainings office={office.id} officename={office.name} />
      );
    case 2:
      return <DepartmentSettings office={office.id} />;
    case 3:
      return <OfficeShifts office={office.id} />;
    case 4:
      return <EmploymentTypeSettings office={office.id} />;
    case 5:
      return <UpdateOfficeForm open={true} office={office.id} />;
    default:
      return "Unknown step";
  }
}

function OfficeSetupGuide(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();
  const office = props.office;
  const [showloader, SetShowLoader] = useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps();
  };

  const allStepsCompleted = () => {
    //return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep = isLastStep()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;

    if (activeStep === 0) {
      setActiveStep(newActiveStep);
    }

    if (activeStep === 1) {
      setActiveStep(newActiveStep);
    }

    if (activeStep === 2) {
      setActiveStep(newActiveStep);
    }

    if (activeStep === 3) {
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  if (showloader) {
    return <CircularProgress color="primary" />;
  } else {
    return (
      <div className={classes.root} data-testid="officeSetUpGuideGrid">
        <Box mt={4}>
          <Typography variant="h3">Setup Guide</Typography>
        </Box>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={completed[index]}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Box mb={4} style={{ textAlign: "right" }}>
                    {activeStep != 4 && (
                      <>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          Next
                        </Button>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {getStepContent(activeStep, office)}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OfficeSetupGuide;
