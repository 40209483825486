import React from 'react';
import {
    FormLabel, Grid, Typography,
    makeStyles, Slider
} from '@material-ui/core';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentNeutralIcon from '../../assets/quizImages/SentimentNeutralIcon';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

const useStyles = makeStyles(() => ({
    disabled: {
        color: 'rgb(158,158,158)'
    },
    slider: {
        maxWidth: 400,
        marginTop: 16
    }
}));

const ReschedulingPreferences = (props) => {
    const classes = useStyles();

    const {
        values,
        setFieldValue,
        setStatus
    } = props;

    const marks = [
        {value: -2, label: <MoodBadIcon style={{paddingLeft: 15}}/>},
        {value: -1, label: <SentimentDissatisfiedIcon/>},
        {value: 0, label: <SentimentNeutralIcon />},
        {value: 1, label: <SentimentSatisfiedIcon/>},
        {value: 2, label: <MoodIcon style={{paddingRight: 15}}/>},
    ];

    return (
        <Grid item container direction='column' alignItems='stretch' xs={9} spacing={2}>
            <Grid item >
                <Typography style={{marginBottom: 16, lineHeight: 1, fontSize: 18}}>
                    <b>Overtime</b>
                </Typography>
            </Grid>
            <Grid item>
                <Typography >
                    This question will not affect your initial schedule but will be taken into consideration when looking for employees to fill gaps in the schedule. 
                </Typography>
            </Grid>
            <Grid item className={classes.slider}>
                <FormLabel>Are you willing to work overtime if asked?</FormLabel>
                <Slider 
                    value={values.overtime}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="off"
                    step={1}
                    marks={marks}
                    min={-2}
                    max={2}
                    onChange={(e, value) => {setFieldValue('overtime', value); setStatus(true);}}
                />
            </Grid>
        </Grid>
    );
};

export default ReschedulingPreferences;