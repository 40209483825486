import React, { useEffect } from "react";
import {
  Typography,
  IconButton,
  Button,
  Paper,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { format, isSameDay } from "date-fns";
import Label from "../shiftBasedCalendar/Label";
import { useQuery } from "@apollo/client";
import { userVar, selectedDateVar } from "../../cache";
import Roles from "../../Roles/roles";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  Inject,
  ViewDirective,
  ViewsDirective,
  TimelineViews,
  ResourcesDirective,
  ResourceDirective,
  Print,
  ICalendarExport,
  ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import {
  GET_EMPLOYEE_SKILL,
  FIND_OFFICE_CONSTRAINT,
} from "../../api/gqlQueries";
import CloseIcon from "@material-ui/icons/Close";
import EventParticipant from "../shiftBasedCalendar/EventParticipant";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(() => ({
  today: {
    background: "#F15A25",
    borderRadius: 30,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tooltip: {
    minWidth: 450,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
}));

const Scheduler = React.forwardRef((props, ref) => {
  const {
    events,
    employeeResources,
    shiftResources,
    view,
    setView,
    toggleEditRequest,
    toggleCallInForm,
    toggleShiftSwitchForm,
    handleEditClick,
    allowCallIns,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const classes = useStyles();

  const user = userVar();
  const selectedDate = selectedDateVar();

  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  useEffect(() => {
    if (view === "ShiftTimeline") {
      clickShiftTimeline();
    } else if (view === "EmployeeTimeline") {
      clickEmployeeTimeline();
    } else if (view === "Day") {
      clickDayView();
    }
  }, [events]);

  function clickShiftTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_4");
      console.log(button);
      button && button.click();
    }, 100);
  }

  function clickEmployeeTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_5");
      console.log(button);
      button && button.click();
    }, 100);
  }

  function clickDayView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_6");
      console.log(button);
      button && button.click();
    }, 100);
  }

  function changeView(props) {
    if (props.action === "view") {
      if (props.currentView === "TimelineWeek") {
        if (props.viewIndex === 0) {
          setView("ShiftTimeline");
          clickShiftTimeline();
        } else {
          setView("EmployeeTimeline");
          clickEmployeeTimeline();
        }
      } else if (props.currentView === "Day") {
        setView(props.currentView);
        selectedDateVar(props.currentDate);
        clickDayView();
      }
    } else if (props.action === "date") {
      selectedDateVar(props.currentDate);
      if (view === "ShiftTimeline") {
        clickShiftTimeline();
      } else if (view === "EmployeeTimeline") {
        clickEmployeeTimeline();
      } else if (view === "Day") {
        clickDayView();
      }
    }
  }

  const eventTemplate = (props) => {
    return (
      <div className="templatewrap">
        <Label data={props} />
      </div>
    );
  };

  const weekShiftTemplate = (props) => {
    if (props.participant) {
      return (
        <div className="templatewrap">
          <EventParticipant
            participant={props.participant}
            showJobTitle={true}
            showAvatar={false}
            widthMatters={true}
          />
        </div>
      );
    } else {
      return (
        <div className="templatewrap">
          <Typography>{props.eventTitle}</Typography>
        </div>
      );
    }
  };

  const listEmployeeTemplate = (props) => {
    return (
      <div className="templatewrap">
        <Typography style={{ fontWeight: "bold" }}>
          {props.eventTitle}
        </Typography>
        {props.comment ? <Typography>{props.comment}</Typography> : null}
        <br />
        {props.type !== "softRequest" && props.type !== "timeOff" && (
          <>
            {props.participants && props.participants.length > 0 ? (
              props.participants.map((participant) => (
                <EventParticipant
                  key={participant.id}
                  participant={participant}
                  showJobTitle={true}
                  showAvatar={false}
                  widthMatters={true}
                />
              ))
            ) : (
              <Typography>No Employees Scheduled</Typography>
            )}
          </>
        )}
      </div>
    );
  };

  const timelineweekViewTemplate = (props) => {
    return (
      <div className="template-wrap">
        <Typography>{props.eventTitle}</Typography>
      </div>
    );
  };

  const resourceHeaderTemplate = (props) => {
    let officeName = props.resourceData.name.split("Time")[0];
    let shiftTime = props.resourceData.name.split("Time")[1];
    return (
      <div className="template-wrap">
        <Typography style={{ fontSize: 13, fontWeight: "bold" }}>
          {officeName}
        </Typography>
        <Typography style={{ fontSize: 10, fontWeight: "bold" }}>
          {shiftTime}
        </Typography>
      </div>
    );
  };

  const cellHeaderTemplate = (props) => {
    const today = isSameDay(new Date(), props.date);

    return (
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Typography variant="body2" className={today ? classes.today : null}>
            {format(props.date, "d")}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const closeQuickInfo = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.closeQuickInfoPopup();
  };

  const quickInfoHeader = (props) => {
    const personal = props.employeeIds?.includes(user.id);
    return (
      <div>
        <Grid container justifyContent="flex-end">
          <Grid item style={{ marginTop: 8, marginRight: 8 }}>
            {managerAccess &&
              props.type !== "softRequest" &&
              props.type !== "timeOff" && (
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => handleEditClick(props)}
                >
                  <EditIcon style={{ color: "#000", fontSize: 18 }} />
                </IconButton>
              )}
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeQuickInfo}
            >
              <CloseIcon style={{ color: "#000", fontSize: 18 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          {props.type === "timeOff" ? (
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ marginLeft: 8, marginBottom: 8 }}
              >
                {props.eventTitle}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography variant="h4" style={{ marginLeft: 8 }}>
                {props.eventTitle}
              </Typography>
            </Grid>
          )}
          {props.type !== "timeOff" && (
            <Grid item container xs={6} justifyContent="flex-end">
              {allowCallIns && (
                <Grid item>
                  <Button
                    color="primary"
                    disabled={!personal}
                    onClick={toggleCallInForm}
                  >
                    Call In
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  disabled={managerAccess ? false : !personal}
                  onClick={toggleShiftSwitchForm}
                  style={{ marginLeft: 4, marginRight: 8 }}
                >
                  Switch Shifts
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  const handleCellClick = (args) => {
    const { startTime } = args;
    selectedDateVar(startTime);
  };

  const handleEventClick = (args) => {
    const { event } = args;
    event && selectedDateVar(event.start);
    if (event.type === "softRequest" || event.category === "personal") {
      toggleEditRequest(event.type, event.eventId);
    }
  };

  const onPopupOpen = (args) => {
    const { data } = args;

    if (args.element.className.includes("e-more-popup-wrapper")) {
      args.cancel = false;
    } else if (
      !data.eventId ||
      data.type === "softRequest" ||
      data.category === "personal"
    ) {
      args.cancel = true;
    }
  };

  return (
    <div id="schedule">
      <Paper>
        <ScheduleComponent
          selectedDate={selectedDate}
          currentView="TimelineWeek"
          height="auto"
          delayUpdate={true}
          eventSettings={{
            dataSource: events,
            fields: {
              id: "id",
              subject: { name: "eventTitle" },
              startTime: { name: "start" },
              endTime: { name: "end" },
              isAllDay: { name: "isAllDay" },
              description: { name: "comment" },
            },
            template: eventTemplate,
          }}
          cssClass="print excel-export calendar-component"
          ref={ref}
          allowDragAndDrop={false}
          workDays={[0, 1, 2, 3, 4, 5, 6]}
          workHours={{ start: "00:00", end: "23:59" }}
          rowAutoHeight={true}
          showTimeIndicator={false}
          navigating={changeView}
          resourceHeaderTemplate={resourceHeaderTemplate}
          enableTooltip={true}
          cellClick={handleCellClick}
          eventClick={handleEventClick}
          popupOpen={onPopupOpen}
          quickInfoTemplates={{
            header: quickInfoHeader,
          }}
        >
          <ViewsDirective>
            <ViewDirective
              option="TimelineWeek"
              displayName="Shift Timeline"
              timeScale={{ enable: false }}
              group={{ resources: ["Shifts"] }}
              eventTemplate={weekShiftTemplate}
            />
            <ViewDirective
              option="TimelineWeek"
              displayName="Employees Timeline"
              timeScale={{ enable: false }}
              group={{ resources: ["Employees"] }}
              eventTemplate={timelineweekViewTemplate}
            />
            <ViewDirective option="Day" eventTemplate={listEmployeeTemplate} />
            <ViewDirective option="Print" />
          </ViewsDirective>
          <ResourcesDirective>
            <ResourceDirective
              field="shiftId"
              title="Shifts"
              name="Shifts"
              textField="name"
              idField="id"
              allowMultiple={true}
              dataSource={shiftResources}
            ></ResourceDirective>
            <ResourceDirective
              field="employeeIds"
              title="Employee Name"
              name="Employees"
              textField="name"
              idField="id"
              colorField="color"
              allowMultiple={true}
              dataSource={employeeResources}
            ></ResourceDirective>
          </ResourcesDirective>
          <Inject
            services={[
              Day,
              Week,
              TimelineViews,
              Month,
              Agenda,
              ICalendarExport,
              ExcelExport,
              Print,
            ]}
          />
        </ScheduleComponent>
      </Paper>
    </div>
  );
});

function areEqual(prevProps, nextProps) {
  return (
    prevProps.events === nextProps.events &&
    prevProps.events.length === nextProps.events.length &&
    prevProps.employeeResources.length === nextProps.employeeResources.length &&
    prevProps.shiftResources.length === nextProps.shiftResources.length
  );
}

export default React.memo(Scheduler, areEqual);
