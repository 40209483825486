import React, { useEffect, useState } from "react";
import LoginForm from "../userAuthentication/LoginForm";
import ForgotPassword from "../userAuthentication/ForgotPassword";
import SAMLLogin from "../userAuthentication/SAMLLogin";
import {
  Container,
  makeStyles,
  AppBar,
  Toolbar,
  CssBaseline,
  Box,
  Link,
} from "@material-ui/core";
import clsx from "clsx";
import { Switch, Route, useHistory } from "react-router-dom";
import { PUBLICLOCATION } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import { appsettingsVar } from "../../cache";
import App from "./App";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

function UnauthenticatedApp() {
  const history = useHistory();
  const classes = useStyles();
  const locationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.log(error);
    },
  });
  const [logourl, setlogourl] = useState("");
  const [logoErr, setLogoErr] = useState(false);
  const appSettings = appsettingsVar();

  const currentLogo =
    !locationSettings.loading &&
    locationSettings.data &&
    locationSettings.data.locationPublicSettings[0].logo;
  const baseURL = window.location.origin;
  const environment = process.env.NODE_ENV;
  appSettings.logo = currentLogo;

  console.log(appSettings);

  let url = document.URL;
  url = url.split("//")[1];
  let urlLink = url.includes("/") && url.split("/")[1];
  if (urlLink && urlLink.length > 0) {
    history.push("/");
  }

  useEffect(() => {
    const currentLogo =
      !locationSettings.loading &&
      locationSettings.data &&
      locationSettings.data.locationPublicSettings[0].logo;
    const baseURL = window.location.origin;
    const environment = process.env.NODE_ENV;
    appSettings.logo = currentLogo;
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [!locationSettings.loading]);

  useEffect(() => {
    const currentLogo =
      !locationSettings.loading &&
      locationSettings.data &&
      locationSettings.data.locationPublicSettings[0].logo;
    const baseURL = window.location.origin;
    const environment = process.env.NODE_ENV;
    appSettings.logo = currentLogo;
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [appSettings.logo]);

  console.log(logourl);

  // axios({
  //   method: "get",
  //   url: logourl,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then(function (response) {
  //     setLogoErr(false);
  //   })
  //   .catch(function (error) {
  //     setLogoErr(true);
  //   });

  if (locationSettings.loading) {
    return <div>loading...</div>;
  } else {
    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer && classes.root}>
          <Container>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar)}>
              <Toolbar className={classes.toolbar}>
                <Box component={Link} to="/">
                  {/* <img
                    // src={
                    //   appSettings.logo && !logoErr
                    //     ? logourl
                    //     : "Duality-Systems_white.png"
                    // }
                    //src="Fillmore.png"
                    src="Duality-Systems_white.png"
                    height={50}
                    alt="Fillmore Logo"
                  /> */}
                  <img src="IntermountainLogo.png" height="50" />
                </Box>
              </Toolbar>
            </AppBar>
            <Box>
              <Switch>
                <Route path="/" exact component={LoginForm} />
                <Route path="/ForgotPassword" component={ForgotPassword} />
                <Route path="/saml2/login" component={SAMLLogin} />
                <Route path="/saml2/unauthorized">
                  {(props) => <LoginForm {...props} error={true} />}
                </Route>
              </Switch>
            </Box>
          </Container>
        </div>
      </main>
    );
  }
}

export default UnauthenticatedApp;
