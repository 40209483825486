import React, { useState, useEffect, useRef } from "react";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import "./shift.css";
import {
  Typography,
  IconButton,
  Button,
  Paper,
  Grid,
  makeStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  format,
  isSameDay,
  sub,
  add,
  getDay,
  differenceInWeeks,
  differenceInDays,
  getDate,
} from "date-fns";
import Label from "./Label";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import {
  userVar,
  selectedDateVar,
  ManualEventsToSave,
  selectedEventVar,
  lastSeenViewVar,
  slackAssignmentVar,
} from "../../cache";
import Roles from "../../Roles/roles";
import {
  Day,
  Week,
  Month,
  Agenda,
  TimelineViews,
  MonthAgenda,
  Print,
  ICalendarExport,
  ExcelExport,
  DragAndDrop,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  TimelineMonth,
} from "@syncfusion/ej2-react-schedule";
import { UPDATE_SLACK_ASSIGNMENT } from "../../api/gqlQueries";
import WarningIcon from "@material-ui/icons/Warning";
import AlertOutline from "mdi-material-ui/AlertOutline";
import CloseIcon from "@material-ui/icons/Close";
import ShiftSlack from "../slacks/ShiftSlack";
import EditIcon from "@material-ui/icons/Edit";
import { closest, addClass } from "@syncfusion/ej2-base";
import MultiSelect from "../general/MultiSelectSkills";
import Person from "@material-ui/icons/Person";
import SkillColors from "../../helpers/SkillColors.json";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 5,
    paddingTop: 0,
  },
  today: {
    background: "#D0D0D0",
    color: "red",
    borderRadius: 30,
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tooltip: {
    minWidth: 600,
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
}));

const mapDaysOfTheWeek = {
  SU: 0,
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
};

const Scheduler = React.forwardRef((props, ref) => {
  const {
    events,
    employeeResources,
    employeeResourcesLoading,
    shiftResources,
    officeResources,
    toggleEditRequest,
    toggleCallInForm,
    toggleShiftSwitchForm,
    issueDates,
    slackIssues,
    handleEditClick,
    draftStart,
    draftEnd,
    allowCallIns,
    schedulePeriods,
    setErrorToast,
    setShowErrorToast,
    skills,
    openStart,
    openEnd,
    createManualShiftAssignments,
    getFutureShifts,
    OfficeConstraints,
    shiftdata,
    view,
    setView,
    refetch,
    setScheduleData,
    scheduleData,
    delayUpdate,
  } = props;
  //order offices by display priority requested by client
  officeResources.length > 0 &&
    officeResources.sort(
      (a, b) =>
        a.displayOrder &&
        b.displayOrder &&
        parseInt(a.displayOrder) - parseInt(b.displayOrder)
    );

  const classes = useStyles();
  const user = userVar();
  const selectedDate = selectedDateVar();

  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  const lastSeenView = lastSeenViewVar();
  useEffect(() => {
    if (view === "ShiftTimeline") {
      clickShiftTimeline();
    } else if (view === "LocationTimeline") {
      clickLocationTimeline();
    } else if (view === "Month") {
      clickmonthtimeline();
    } else if (view === "MonthAgenda") {
      clickAgendaView();
    } else if (view === "Day") {
      clickDayView();
    }
  }, [events, view]);

  const [updateSlackAssignment] = useMutation(UPDATE_SLACK_ASSIGNMENT, {
    onCompleted(d) {
      setScheduleData({
        formatted:
          events &&
          events.length > 0 &&
          events.filter(
            (e) =>
              e.calendars &&
              e.eventId !==
                d.updateSlackAssignment.slackAssignment.assignment.shift.id
          ),
        userShifts: scheduleData.userShifts,
        shiftNames: scheduleData.shiftNames,
      });

      const twoDaysFromNow = add(new Date(selectedDate), { days: 1 });
      const currentDate = sub(new Date(selectedDate), { days: 1 });
      let variables = {
        rangeStart: currentDate.toISOString(),
        rangeEnd: twoDaysFromNow.toISOString(),
        office: parseInt(user.office.id),
        employeeId: parseInt(user.id),
        officeId: parseInt(user.office.id),
        startDate: format(currentDate, "yyyy-MM-dd"),
        endDate: format(twoDaysFromNow, "yyyy-MM-dd"),
      };
      getFutureShifts({ variables: variables });
    },
    onError(err) {
      console.log(err);
    },
  });

  function clickShiftTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_4");
      button && button.click();
    }, 200);
  }

  function clickLocationTimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_5");
      button && button.click();
    }, 200);
  }

  function clickmonthtimeline() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_6");
      button && button.click();
    }, 200);
  }

  function clickAgendaView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_7");
      button && button.click();
    }, 200);
  }

  function clickDayView() {
    setTimeout(() => {
      const button = document.getElementById("e-tbr-btn_8");
      button && button.click();
    }, 200);
  }

  function changeView(props) {
    if (props.action === "view") {
      if (props.currentView === "TimelineWeek") {
        if (props.viewIndex === 0) {
          lastSeenViewVar("ShiftTimeline");
          setView("ShiftTimeline");
          clickShiftTimeline();
        } else if (props.viewIndex === 1) {
          lastSeenViewVar("LocationTimeline");
          setView("LocationTimeline");
          clickLocationTimeline();
        }
      } else if (props.currentView === "Day") {
        selectedDateVar(props.currentDate);
        lastSeenViewVar(props.currentView);
        setView(props.currentView);
        clickDayView();
      } else if (props.currentView === "Month" || props.viewIndex === 2) {
        lastSeenViewVar("Month");
        setView("Month");
        clickmonthtimeline();
      } else if (props.currentView === "MonthAgenda" || props.viewIndex === 3) {
        lastSeenViewVar("MonthAgenda");
        setView("MonthAgenda");
        clickAgendaView();
      }
    } else if (props.action === "date") {
      selectedDateVar(props.currentDate);
      const onemonthfromnow = add(new Date(props.currentDate), { months: 1 });
      const currentDate = new Date(props.currentDate);

      let eventforViewEnd =
        events &&
        events.length > 0 &&
        events.find(
          (e) =>
            format(new Date(e.start), "yyyy-MM-dd") ===
            format(new Date(onemonthfromnow), "yyyy-MM-dd")
        );
      let eventforViewStart =
        events &&
        events.length > 0 &&
        events.find(
          (e) =>
            format(new Date(e.start), "yyyy-MM-dd") ===
            format(new Date(props.currentDate), "yyyy-MM-dd")
        );
      if (!eventforViewEnd || !eventforViewStart) {
        let variables = {
          rangeStart: currentDate.toISOString(),
          rangeEnd: onemonthfromnow.toISOString(),
          office: parseInt(user.office.id),
          employeeId: parseInt(user.id),
          officeId: parseInt(user.office.id),
          startDate: format(currentDate, "yyyy-MM-dd"),
          endDate: format(onemonthfromnow, "yyyy-MM-dd"),
        };
        getFutureShifts({ variables: variables });
      }
    }
  }

  // from Label component get the event to display on calendar
  const eventTemplate = (props) => {
    if (props.employeeIds) {
      return (
        <div
          className="templatewrap"
          style={{
            backgroundColor: props.color && props.color,
            overflowWrap: "break-word",
          }}
        >
          <Label data={props} skills={skills} />
        </div>
      );
    } else {
      return (
        <div
          className="templatewrap employeeNameEvents"
          style={{
            backgroundColor: props.color && props.color,
            overflowWrap: "break-word",
          }}
        >
          <Label data={props} skills={skills} />
        </div>
      );
    }
  };

  // week view event template for shift timeline
  const weekShiftTemplate = (props) => {
    if (props.participant) {
      let uniqueSkills = [];
      props.skills &&
        props.skills.length > 0 &&
        props.skills.map((skill) => {
          if (!uniqueSkills.find((e) => e.name === skill.name)) {
            uniqueSkills.push({
              id: skill.skillId,
              name: skill.name,
              office: skill.office,
              skillColor: SkillColors.skills.find((e) => e.name === skill.name)
                ? SkillColors.skills.find((e) => e.name === skill.name).color
                : "none",
              isActive: skill.isActive,
              displayPriority: SkillColors.skills.find(
                (e) => e.name === skill.name
              )
                ? SkillColors.skills.find((e) => e.name === skill.name)
                    .displayPriority
                : "none",
            });
          }
        });
      uniqueSkills.sort(
        (a, b) => parseInt(a.displayPriority) - parseInt(b.displayPriority)
      );
      return (
        <div
          className="template-wrap"
          style={{ backgroundColor: props.color && props.color }}
        >
          {props.participant.firstName[0] + " " + props.participant.lastName}
          {uniqueSkills.map((e, index) => (
            <span
              style={{
                background: e.skillColor,
              }}
              key={index}
            >
              {" "}
              {" : " + e.isActive &&
                e.isActive === true &&
                e.name != "APP" &&
                e.name}
            </span>
          ))}
        </div>
      );
    } else {
      return (
        <div className="template-wrap">
          {props.eventTitle}
          {props.Skills}
        </div>
      );
    }
  };

  const getOfficeNameForHEader = (value) => {
    return value.resourceData.office;
  };
  const getShiftTitleForHeader = (value) => {
    return value.resourceData.name;
  };

  // get resource data and have access to the shift resources and employee resources on week view
  const resourceHeaderTemplate = (props) => {
    return (
      <div className="template-wrap">
        <div className="office-name" style={{ fontSize: 12, fontWeight: 600 }}>
          {getOfficeNameForHEader(props)}
        </div>
        <div className="Shift-title" style={{ fontSize: 12, fontWeight: 600 }}>
          {getShiftTitleForHeader(props)}
        </div>
      </div>
    );
  };

  const empresourceHeaderTemplate = (props) => {
    return (
      <div className="template-wrap">
        <div style={{ fontSize: 12, fontWeight: 600 }}>
          {props.resourceData.name}
        </div>
      </div>
    );
  };

  const cellHeaderTemplate = (props) => {
    const today = isSameDay(new Date(), props.date);
    const formatted = format(props.date, "MM/dd/yyyy");
    const slackIssueDate = issueDates.includes(formatted); // check if selected date has a slack issue
    let issuesForDate;
    let under;
    let over;
    if (slackIssueDate) {
      // get slack issues for the date selected
      issuesForDate = slackIssues.filter((slack) => {
        const startDate = new Date(slack.interval.start);
        return isSameDay(startDate, props.date);
      });

      // check if the staffing is low or high for the date selected
      under = issuesForDate.find(
        (issue) =>
          parseInt(issue.required) > parseInt(issue.numAssigned) ||
          parseInt(issue.slack) < 0
      );
      over = issuesForDate.find(
        (issue) =>
          parseInt(issue.required) < parseInt(issue.numAssigned) ||
          parseInt(issue.slack) > 0
      );
    }
    // check if the selected date has a draft schedule or released schedule
    const draft =
      (new Date(props.date) > new Date(draftStart) ||
        isSameDay(new Date(props.date), new Date(draftStart))) &&
      (new Date(props.date) < new Date(draftEnd) ||
        isSameDay(new Date(props.date), new Date(draftEnd)));

    const open =
      (new Date(props.date) > new Date(openStart) ||
        isSameDay(new Date(props.date), new Date(openStart))) &&
      (new Date(props.date) < new Date(openEnd) ||
        isSameDay(new Date(props.date), new Date(openEnd)));
    return (
      <Grid container>
        <Grid item xs={12}>
          {slackIssueDate && (
            <Tooltip
              title={
                <ShiftSlack startDate={props.date} slacks={issuesForDate} />
              }
              placement="left"
              arrow
              enterDelay={500}
              enterNextDelay={500}
              classes={{ tooltip: classes.tooltip }}
            >
              <span>
                {under && (
                  <WarningIcon
                    style={{
                      fontSize: 16,
                      marginBottom: -3,
                      color: "black",
                    }}
                  />
                )}
                {over && !under && (
                  <AlertOutline
                    style={{
                      fontSize: 16,
                      marginBottom: -3,
                      color: "black",
                    }}
                  />
                )}
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={today ? classes.today : null}
            style={{
              textAlign: lastSeenView === "Month" && "right",
              background: lastSeenView === "Month" && "none",
            }}
          >
            {props.type === "monthCells"
              ? format(props.date, "dd")
              : format(props.date, "MMM dd, EEE")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {draft ? (
            <Typography variant="body2" style={{ color: "#8CADE1" }}>
              Draft
            </Typography>
          ) : open && lastSeenView === "ShiftTimeline" ? (
            <Typography variant="body2">
              <Person color="secondary" fontSize="small" /> open
            </Typography>
          ) : (
            <>
              <Typography
                variant="body2"
                style={{ color: "#8CADE1" }}
              ></Typography>
              <br />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const closeQuickInfo = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.closeQuickInfoPopup();
  };

  // style quick info header
  const quickInfoHeader = (props) => {
    const personal =
      (props.employeeIds && props.employeeIds.includes(user.id)) ||
      (props.participant &&
        parseInt(props.participant.employeeId) === parseInt(user.id));
    let selectedDateSchedulePeriod = schedulePeriods.find(
      (e) =>
        new Date(e.start) < new Date(props.start) &&
        new Date(e.end) >= new Date(props.start)
    );
    return (
      <div>
        <Grid container justifyContent="flex-end">
          <Grid item style={{ marginTop: 8, marginRight: 8 }}>
            {managerAccess &&
              props.eventId &&
              props.type !== "softRequest" &&
              props.type !== "timeOff" && (
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => lastSeenView === handleEditClick(props)}
                >
                  <EditIcon style={{ color: "#000", fontSize: 18 }} />
                </IconButton>
              )}
            <IconButton
              aria-label="close"
              color="secondary"
              size="small"
              onClick={closeQuickInfo}
            >
              <CloseIcon style={{ color: "#000", fontSize: 18 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {props.type === "timeOff" ? (
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{ marginLeft: 8, marginBottom: 8, padding: 8 }}
              >
                {props.eventTitle}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography variant="h4" style={{ marginLeft: 8 }}>
                {props.eventTitle}
              </Typography>
            </Grid>
          )}

          {props.type !== "timeOff" && (
            <Grid item container xs={6} justifyContent="flex-end">
              {allowCallIns && (
                <Grid item>
                  <Button
                    color="primary"
                    disabled={!personal}
                    onClick={toggleCallInForm}
                  >
                    Call In
                  </Button>
                </Grid>
              )}
              {props.eventId &&
                props.eventId != null &&
                selectedDateSchedulePeriod &&
                selectedDateSchedulePeriod.status != "OPEN" &&
                selectedDateSchedulePeriod.status != "READY" && (
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={managerAccess ? false : !personal}
                      onClick={toggleShiftSwitchForm}
                      style={{ marginLeft: 4, marginRight: 8 }}
                    >
                      Switch Shifts
                    </Button>
                  </Grid>
                )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  // on event click bring up edit event popup
  const handleEventClick = (args) => {
    const { event } = args;
    selectedDateVar(event.start);
    if (
      event.type === "softRequest" ||
      event.category === "personal" ||
      (event.type === "timeOff" && user.isManager)
    ) {
      toggleEditRequest(event.type, event.eventId);
    } else if (event && !event.eventId) {
      args.cancel = true;
    }
  };

  const onPopupOpen = (args) => {
    var buttonElement =
      args.type === "QuickInfo"
        ? ".e-event-popup .e-delete"
        : ".e-schedule-dialog .e-event-delete";
    var deleteButton = document.querySelector(buttonElement);
    if (args.type === "Editor" && args.data.eventId) {
      selectedEventVar(args.data);
      deleteButton.ej2_instances[0].disabled = true;
    }
    if (
      args.type === "QuickInfo" &&
      args.event &&
      args.event.eventId &&
      lastSeenView != "ShiftTimeline"
    ) {
      args.cancel = true;
    }
  };

  //on Cell Click
  const handleCellClick = (args) => {
    selectedDateVar(new Date(args.startTime));
    if (lastSeenView != "MonthAgenda") {
      args.cancel = true;
    }
  };

  const treeTemplate = (props) => {
    return (
      <div id="waiting">
        <div id="waitdetails">
          <div id="waitlist">
            <b>{props.name}</b>
            <div style={{ fontSize: 10, color: "#DODODO" }}>
              {props.skills &&
                props.skills.length > 0 &&
                props.skills.map((e) => e).join(", ")}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onActionBegin = () => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.showSpinner();
  };

  const manualEvents = ManualEventsToSave();
  let newEvents = [];

  const onTreeDragStop = (event) => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    newEvents = [];
    let treeElement = closest(event.target, ".e-treeview");
    let classElement = document.querySelector(".e-device-hover");
    if (classElement) {
      classElement.classList.remove(".e-device-hover");
    }
    if (!treeElement) {
      event.cancel = true;
      let scheduleElement = closest(event.target, ".e-content-wrap");
      if (scheduleElement) {
        let treeviewData = fields.dataSource;
        if (event.target.classList.contains("e-work-cells")) {
          const filteredData = treeviewData.filter(
            (item) =>
              parseInt(item.id) === parseInt(event.draggedNodeData.id, 10)
          );
          let cellData = scheduleObj.getCellDetails(event.target);
          let resourceDetails = scheduleObj.getResourcesByIndex(
            cellData.groupIndex
          );
          selectedDateVar(new Date(cellData.startTime));
          let starttime = resourceDetails.resourceData.startHour;
          starttime = starttime + ":00";
          starttime =
            format(cellData.startTime, "yyyy-MM-dd") + "T" + starttime;
          starttime = new Date(starttime).toISOString();
          let endtime = resourceDetails.resourceData.endHour;
          let endtimehour = endtime.split(":")[0];
          endtimehour =
            parseInt(endtimehour) > 12
              ? parseInt(endtimehour) - 12
              : parseInt(endtimehour);
          endtime = endtime.split(" ")[0];
          endtime = endtime + ":00";
          let endtimeWithStartDate =
            format(cellData.startTime, "yyyy-MM-dd") + "T" + endtime;
          let endtimeWithEndDate =
            format(cellData.endTime, "yyyy-MM-dd") + "T" + endtime;
          endtimeWithStartDate = new Date(endtimeWithStartDate).toISOString();
          endtimeWithEndDate = new Date(endtimeWithEndDate).toISOString();
          let schedulePresent = schedulePeriods.find(
            (e) =>
              e.start <= format(new Date(cellData.startTime), "yyyy-MM-dd") &&
              e.end >= format(new Date(cellData.endTime), "yyyy-MM-dd")
          );
          if (!schedulePresent) {
            setErrorToast("No Manual Scheduling");
            setShowErrorToast(true);
          } else if (
            schedulePresent != null ||
            schedulePresent != "undefined"
          ) {
            if (
              schedulePresent.status === "PUBLISHED" ||
              schedulePresent.status === "DRAFT" ||
              format(new Date(), "yyyy-MM-dd") >
                format(new Date(cellData.startTime), "yyyy-MM-dd")
            ) {
              setErrorToast("No Manual Scheduling");
              setShowErrorToast(true);
            } else if (
              schedulePresent.status === "OPEN" ||
              schedulePresent.status === "READY" ||
              schedulePresent.status === "MANAGER_DRAFT"
            ) {
              let newdateShiftId =
                events &&
                events.length > 0 &&
                events.find(
                  (e) =>
                    new Date(e.start).toISOString() === starttime &&
                    (new Date(e.end).toISOString() === endtimeWithStartDate ||
                      new Date(e.end).toISOString() === endtimeWithEndDate) &&
                    e.office === resourceDetails.resourceData.office
                );
              console.log(newdateShiftId);
              let shiftStart = resourceDetails.resourceData.id.split("-")[0];
              shiftStart = shiftStart && shiftStart.trim();
              shiftStart = shiftStart + ":00";
              let shiftEnd = resourceDetails.resourceData.id.split("-")[1];
              shiftEnd = shiftEnd.split(" ")[1];
              shiftEnd = shiftEnd + ":00";
              shiftEnd = shiftEnd.trim();
              let uniqueSkills = [];
              OfficeConstraints &&
                OfficeConstraints.length > 0 &&
                OfficeConstraints.filter(
                  (e) =>
                    e.type.name === "SKILLCOVER" &&
                    e.starttime === shiftStart &&
                    e.endtime === shiftEnd &&
                    e.office.name === resourceDetails.resourceData.office
                ).map(
                  (e) =>
                    !uniqueSkills.includes(parseInt(e.skill.id)) &&
                    uniqueSkills.push(parseInt(e.skill.id))
                );

              let eventData = {
                name: filteredData[0].name,
                isAllDay: cellData.isAllDay,
                start: new Date(cellData.startTime).toISOString(),
                end: new Date(cellData.endTime).toISOString(),
                shiftId: resourceDetails.resourceData.id,
                eventTitle: filteredData[0].name,
                calendars: ["shift"],
                newdateShiftId: newdateShiftId ? newdateShiftId.idOfShift : "",
                employeeId: filteredData[0].id,
                requiredSkills: uniqueSkills,
                skillNames: filteredData[0].skills,
                startTime: resourceDetails.resourceData.startHour,
                endTime: resourceDetails.resourceData.endHour,
                startTimeHour:
                  resourceDetails.resourceData.startHour.split(":")[0],
                endTimeHour: resourceDetails.resourceData.endHour.split(":")[0],
                office: resourceDetails.resourceData.office,
                description: resourceDetails.resourceData.description,
                idOfShift: newdateShiftId ? newdateShiftId.idOfShift : "",
              };
              console.log(eventData);
              scheduleObj.openEditor(eventData, "Add", true);
              scheduleObj.eventWindow.recurrenceEditor.frequencies = [
                "none",
                "daily",
                "weekly",
              ];
              let end =
                document.querySelector(".e-end-on-element").ej2_instances[0];
              end.dataSource = [{ text: "Never", value: "never" }];
              end.dataBind();
              newEvents.push(
                manualEvents && manualEvents.length > 0
                  ? [...manualEvents, eventData]
                  : eventData
              );
              ManualEventsToSave(newEvents.flat());
            }
          }
        }
      }
    }
  };

  const onPopupClose = (args) => {
    if (
      args.type === "Editor" &&
      args.data != null &&
      selectedEventVar() === null
    ) {
      //show a snackbar with loader to say events are

      newEvents.map((ev) => {
        if (args.data.RecurrenceRule != null) {
          createManualShiftAssignments({
            variables: {
              employee: parseInt(ev.employeeId),
              shift: parseInt(ev.newdateShiftId),
              skills: ev.requiredSkills,
              recurrence: args.data.RecurrenceRule,
            },
          });
        } else {
          createManualShiftAssignments({
            variables: {
              employee: parseInt(ev.employeeId),
              shift: parseInt(ev.newdateShiftId),
              skills: ev.requiredSkills,
            },
          });
        }

        //   // if (args.data.RecurrenceRule != null) {
        //   //   let rules = args.data.RecurrenceRule.split(";");
        //   //   let freq = rules.find((e) => e.includes("FREQ"));
        //   //   freq = freq.split("=")[1]; //get frequency "DAILY" or "WEEKLY"
        //   //   let count = rules.find((e) => e.includes("COUNT"));
        //   //   let until = rules.find((e) => e.includes("UNTIL"));
        //   //   let dayOfWeekOnTarget = getDay(new Date(args.data.start));
        //   //   let interval = rules.find((e) => e.includes("INTERVAL"));
        //   //   interval = interval ? interval.split("=")[1] : 1;
        //   //   let byday = rules.find((e) => e.includes("BYDAY"));
        //   //   byday = byday ? byday.split("=")[1] : [dayOfWeekOnTarget]; //get which day of the week it has to repeat
        //   //   byday = byday.includes(",")
        //   //     ? byday.split(",")
        //   //     : [parseInt(dayOfWeekOnTarget)];
        //   //   until = until && until.split("=")[1];
        //   //   let untilYear = until && until.slice(0, 4);
        //   //   let untilMonth = until && until.slice(4, 6);
        //   //   let untilDay = until && until.slice(6, 8);
        //   //   let untilDate =
        //   //     until &&
        //   //     new Date(
        //   //       untilYear + "-" + untilMonth + "-" + untilDay + "T00:00:00"
        //   //     );
        //   //   untilDate = until && add(untilDate, { days: 1 });
        //   //   until =
        //   //     until && freq === "DAILY"
        //   //       ? differenceInDays(
        //   //           untilDate,
        //   //           sub(new Date(args.data.start), { days: 1 })
        //   //         )
        //   //       : differenceInWeeks(
        //   //           untilDate,
        //   //           sub(new Date(args.data.start), { days: 1 })
        //   //         );
        //   //   count = count && count.split("=")[1];
        //   //   let len = count ? parseInt(count) : until ? parseInt(until) : null;
        //   //   const dayOfWeekOnRecurrence = byday.map((e) =>
        //   //     mapDaysOfTheWeek[e] ? mapDaysOfTheWeek[e] : parseInt(e)
        //   //   );
        //   //   if (
        //   //     (freq &&
        //   //       (!count || count === "undefined") &&
        //   //       (!until || until === "undefined")) ||
        //   //     len === null
        //   //   ) {
        //   //     setErrorToast("End date for recurring event");
        //   //     setShowErrorToast(true);
        //   //     args.cancel = true;
        //   //   } else {
        //   //     for (
        //   //       let index = 0;
        //   //       index < parseInt(len);
        //   //       index += parseInt(interval)
        //   //     ) {
        //   //       dayOfWeekOnRecurrence.map((e) => {
        //   //         let numberofDaysToadd =
        //   //           parseInt(dayOfWeekOnTarget) != parseInt(e)
        //   //             ? parseInt(e) - parseInt(dayOfWeekOnTarget)
        //   //             : 0;
        //   //         let indexStart =
        //   //           freq === "DAILY"
        //   //             ? add(new Date(args.data.start), {
        //   //                 days: index,
        //   //               })
        //   //             : add(new Date(args.data.start), {
        //   //                 days:
        //   //                   numberofDaysToadd > 0
        //   //                     ? parseInt(numberofDaysToadd)
        //   //                     : 0,
        //   //                 weeks: index,
        //   //               });
        //   //         indexStart =
        //   //           format(new Date(indexStart), "yyyy-MM-dd") +
        //   //           "T" +
        //   //           ev.startTime;
        //   //         indexStart = new Date(indexStart).toISOString();
        //   //         let indexEnd =
        //   //           ev.description.includes("Night") ||
        //   //           ev.description.includes("Swing")
        //   //             ? freq === "DAILY"
        //   //               ? add(new Date(ev.end), {
        //   //                   days: index,
        //   //                 })
        //   //               : add(new Date(ev.end), {
        //   //                   days:
        //   //                     parseInt(numberofDaysToadd) > 0 &&
        //   //                     parseInt(numberofDaysToadd),
        //   //                   weeks: index,
        //   //                 })
        //   //             : freq === "DAILY"
        //   //             ? add(new Date(ev.start), { days: index })
        //   //             : add(new Date(ev.start), {
        //   //                 days:
        //   //                   parseInt(numberofDaysToadd) > 0 &&
        //   //                   parseInt(numberofDaysToadd),
        //   //                 weeks: index,
        //   //               });
        //   //         indexEnd =
        //   //           format(new Date(indexEnd), "yyyy-MM-dd") + "T" + ev.endTime;
        //   //         indexEnd = new Date(indexEnd).toISOString();
        //   //         let getIdOfShift = shiftdata.find(
        //   //           (e) =>
        //   //             new Date(e.start).toISOString() === indexStart &&
        //   //             new Date(e.end).toISOString() === indexEnd &&
        //   //             e.officeName === ev.office
        //   //         );
        //   //         if (getIdOfShift != null) {
        //   //           newDataToSave.push({
        //   //             employeeId: parseInt(ev.employeeId),
        //   //             shiftId: getIdOfShift && parseInt(getIdOfShift.id),
        //   //             isArchived: false,
        //   //             skills: ev.requiredSkills,
        //   //           });
        //   //         } else {
        //   //           noShiftIdData.push(indexStart);
        //   //         }
        //   //       });
        //   //     }
        //   //   }
        //   // } else {
        //   //   newDataToSave.push({
        //   //     employeeId: parseInt(ev.employeeId),
        //   //     shiftId: parseInt(ev.newdateShiftId),
        //   //     isArchived: false,
        //   //     skills: ev.requiredSkills,
        //   //   });
        //   // }
        // });
        // let manev = manEvents.length > 0 && manEvents[0].newDataToSave.flat();
        // console.log(manev);
        // if (
        //   manEvents.length > 0 &&
        //   manEvents[0].noShiftIdData &&
        //   manEvents[0].noShiftIdData.length > 0
        // ) {
        //   setErrorToast("Some Shifts Could not be saved.");
        //   setShowErrorToast(true);
        // }
      });
    } else if (
      args.type === "Editor" &&
      args.data != null &&
      selectedEventVar() != null
    ) {
      let skillNames =
        args.data.skillNames != "undefined" || !args.data.skillNames
          ? args.data.skillNames && args.data.skillNames.includes(",")
            ? args.data.skillNames.split(",")
            : [args.data.skillNames]
          : [];
      let slackAssignmentIds =
        skillNames.length > 0 &&
        skillNames.map((sn) => {
          let matchingSkill = selectedEventVar().skills.find(
            (s) => s.name === sn
          );
          if (matchingSkill) {
            return matchingSkill.id;
          } else {
            return null;
          }
        });
      const currentDedicatedSkills = selectedEventVar().skills.filter(
        (sa) => sa.isActive === true
      );

      const dataToDelete =
        currentDedicatedSkills.length > 0 &&
        currentDedicatedSkills.map((sa) => {
          if (
            (skillNames && !skillNames.includes(sa.name)) ||
            skillNames.length <= 0
          ) {
            return {
              id: parseInt(sa.id),
              input: { isActive: false },
            };
          }
        });
      dataToDelete &&
        dataToDelete.length > 0 &&
        dataToDelete.map((e) =>
          updateSlackAssignment({
            variables: e,
          })
        );

      slackAssignmentIds.map((e) => {
        let matchingSkill = selectedEventVar().skills.find((s) => s.id === e);
        if (!matchingSkill && dataToDelete.length <= 0) {
          setErrorToast("Employee not qualified to fill the selected skill");
          setShowErrorToast(true);
        }
      });
      const dataToSave = slackAssignmentIds.filter(Boolean).map((sa) => {
        return {
          id: parseInt(sa),
          input: { isActive: true },
        };
      });
      dataToSave &&
        dataToSave.length > 0 &&
        dataToSave.map((e) =>
          updateSlackAssignment({
            variables: e,
          })
        );
      selectedEventVar(null);
    } else if (args.data === null) {
      selectedEventVar(null);
    }
  };

  const dragStop = (args) => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    scheduleObj.hideSpinner();
  };

  const onItemDrag = (event) => {
    const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
    let treeElement = document.querySelector(".e-treeview").ej2_instances[0];
    treeElement.dragArea = ".e-schedule";

    //treeElement.dragObj.dragArea = "body";
    if (scheduleObj.isAdaptive) {
      let classElement = scheduleObj.element.querySelector(".e-device-hover");
      if (classElement) {
        classElement.classList.remove("e-device-hover");
      }
      if (event.target.classList.contains("e-work-cells")) {
        addClass([event.target], "e-device-hover");
      }
    }
    if (document.body.style.cursor === "not-allowed") {
      document.body.style.cursor = "";
    }
    if (event.name === "nodeDragging") {
      let dragElementIcon = document.querySelectorAll(
        ".e-drag-item.treeview-external-drag .e-icon-expandable"
      );
      for (let i = 0; i < dragElementIcon.length; i++) {
        dragElementIcon[i].style.display = "none";
      }
    }
  };

  const [selectedSkills, SetSelectedSkills] = useState([]);

  const [fields, SetFields] = useState({
    dataSource: employeeResources,
    id: "id",
    subject: { name: "eventTitle" },
  });

  useEffect(() => {
    SetFields({
      dataSource: employeeResources,
      id: "id",
      subject: { name: "eventTitle" },
    });
  }, [employeeResources.length || selectedSkills.length === 0]);

  const filterBySkillsandEmployees = (e) => {
    SetSelectedSkills(e);
    if (e.length <= 0 && events) {
      SetFields({
        dataSource: employeeResources,
        id: "id",
        subject: { name: "eventTitle" },
      });
    } else {
      let skillNames = e.map((s) => s.name);
      SetFields({
        dataSource: employeeResources
          .filter((employee) => {
            let skillpresent =
              employee.skills &&
              employee.skills.length > 0 &&
              employee.skills
                .map((skill) => {
                  if (skillNames.includes(skill)) {
                    return true;
                  }
                })
                .filter(Boolean);
            let emppresent = e.find((emp) => emp.id === employee.id);
            if (emppresent) {
              return employee;
            }
            if (skillpresent && skillpresent.includes(true)) {
              return employee;
            }
          })
          .filter(Boolean),
        id: "id",
        subject: { name: "eventTitle" },
      });
    }
  };

  return (
    <div id="schedule" className="control-wrpper drag-sample-wrapper">
      <Paper>
        <Grid container direction="row" spacing={2}>
          <Grid
            item
            xs={
              user.manuallySchedulable && lastSeenView === "ShiftTimeline"
                ? 10
                : 12
            }
          >
            <ScheduleComponent
              className="scheduleComponent"
              selectedDate={selectedDate}
              rowAutoHeight={true}
              currentView={view}
              height={
                user.manuallySchedulable && lastSeenView === "ShiftTimeline"
                  ? 800
                  : "auto"
              }
              delayUpdate={true}
              popupClose={onPopupClose}
              popupOpen={onPopupOpen}
              eventSettings={{
                dataSource: events,
                fields: {
                  id: "id",
                  location: { name: "idOfShift", title: "Shift ID" },
                  subject: { name: "eventTitle", title: "Name" },
                  startTime: { name: "start" },
                  endTime:
                    lastSeenView === "Month" || lastSeenView === "month"
                      ? { name: "monthViewEnd" }
                      : { name: "end" },
                  isAllDay: { name: "isAllDay" },
                  description: {
                    name: "skillNames",
                    title: "Employee Skills",
                  },
                },
                template: eventTemplate,
              }}
              cssClass="shiftCalendar print excel-export calendar-component schedule-drag-drop schedule-cell-dimension"
              ref={ref}
              workDays={[0, 1, 2, 3, 4, 5, 6]}
              workHours={{ start: "00:00", end: "23:59" }}
              showTimeIndicator={false}
              navigating={changeView}
              dateHeaderTemplate={cellHeaderTemplate}
              eventClick={handleEventClick}
              cellClick={handleCellClick}
              quickInfoTemplates={{
                header: quickInfoHeader,
              }}
              actionBegin={onActionBegin}
              drag={onItemDrag}
              dragStop={dragStop}
              timeScale={{
                enable: true,
              }}
            >
              <ViewsDirective>
                {!user.isEmployee && (
                  <ViewDirective
                    option="TimelineWeek"
                    displayName="Shift Timeline"
                    timeScale={{ enable: false }}
                    //timeScale={{ enable: , interval: 1440, slotCount: 1 }}
                    group={{
                      enableCompactView: false,
                      resources: ["Shifts"],
                    }}
                    //startHour="07:00"
                    //endHour="06:59:59"
                    eventTemplate={weekShiftTemplate}
                    interval={4}
                    resourceHeaderTemplate={resourceHeaderTemplate}
                  />
                )}

                <ViewDirective
                  option="TimelineWeek"
                  group={{ enableCompactView: false, resources: ["Offices"] }}
                  timeScale={{ enable: true, interval: 1440, slotCount: 1 }}
                  displayName="Location Timeline"
                  startHour="07:00"
                  endHour="06:59:59"
                  resourceHeaderTemplate={empresourceHeaderTemplate}
                  interval={4}
                />
                <ViewDirective
                  option="Month"
                  cellHeaderTemplate={cellHeaderTemplate}
                />
                <ViewDirective
                  option="MonthAgenda"
                  startHour="07:00"
                  endHour="06:59:59"
                  group={{ enableCompactView: true, resources: ["Offices"] }}
                />
                {/* <ViewDirective
                    option="Day"
                    startHour="07:00"
                    endHour="06:59:59"
                    eventTemplate={listEmployeeTemplate}
                  /> */}
                <ViewDirective option="Print" />
              </ViewsDirective>
              <ResourcesDirective>
                <ResourceDirective
                  field="shiftId"
                  title="Shifts"
                  name="Shifts"
                  dataSource={shiftResources}
                  textField="id"
                  idField="id"
                ></ResourceDirective>
                <ResourceDirective
                  field="office"
                  title="Offices"
                  name="Offices"
                  allowMultiple={false}
                  dataSource={officeResources}
                  textField="name"
                  idField="name"
                  groupIDField="name"
                ></ResourceDirective>
              </ResourcesDirective>
              <Inject
                services={[
                  Day,
                  Week,
                  TimelineViews,
                  TimelineMonth,
                  Month,
                  Agenda,
                  MonthAgenda,
                  ICalendarExport,
                  ExcelExport,
                  Print,
                  DragAndDrop,
                ]}
              />
            </ScheduleComponent>
          </Grid>
          {lastSeenView === "ShiftTimeline" && user.manuallySchedulable && (
            <Grid item xs={2} style={{ height: 800, overflow: "scroll" }}>
              <div className="treeview-container">
                <div className="title-container">
                  {/* <h1 className="title-text" style={{ textAlign: "center" }}>
                      Employees
                    </h1> */}
                  <div className={classes.root}>
                    <MultiSelect
                      options={[...skills, ...employeeResources]}
                      name="skills"
                      id="skills"
                      placeholder="Enter Skills or Employee"
                      onChange={filterBySkillsandEmployees}
                      val={selectedSkills}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={(option) => option.name}
                      autoClose={true}
                    />
                  </div>
                  <TreeViewComponent
                    ref={ref}
                    id="e-treeview"
                    cssClass="treeview-external-drag"
                    dragArea=".drag-sample-wrapper"
                    nodeTemplate={treeTemplate}
                    fields={fields}
                    nodeDragStop={onTreeDragStop}
                    nodeDragging={onItemDrag}
                    allowDragAndDrop={true}
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
});

function areEqual(prevProps, nextProps) {
  return (
    prevProps.events.filter((e) => e.eventId).length ===
      nextProps.events.length &&
    prevProps.employeeResources.length === nextProps.employeeResources.length &&
    prevProps.shiftResources.length === nextProps.shiftResources.length
  );
}

export default React.memo(Scheduler, areEqual);
