import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  Button,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useQuery, useMutation } from "@apollo/client";
import { GET_FLOAT_DETAILS, UPDATE_OFFICE } from "../../api/gqlQueries";
import MultiSelect from "../general/MultiSelectSkills";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 150,
  },
}));

const FloatSettings = () => {
  const classes = useStyles();

  const { data, loading, error, refetch } = useQuery(GET_FLOAT_DETAILS);

  const [officeId, setOfficeId] = useState();

  const timingOptions = ["Schedule in Draft", "Schedule Published"];

  const [officeFloatStatus, SetOfficeFloatStatus] = useState();

  const [snackbaropen, setsnackbaropen] = useState(false);

  const [snackBarMessage, setsnackBarMessage] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onCompleted(data) {
      console.log(data);
      setOfficeId();
      // SetOfficeFloatStatus();
      setsnackBarMessage("Office Float settings succesfully updated");
      setsnackbaropen(true);
    },
    onError() {
      setsnackBarMessage("Something went wrong please try again");
      seterrorsnackbaropen(true);
    },
  });

  const floatStatus = [
    {
      value: true,
      name: "Sends Floats",
    },
    {
      value: false,
      name: "Accepts Floats",
    },
    {
      value: null,
      name: "No Floats",
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Department",
      width: 300,
    },
    {
      field: "Float Status",
      width: 250,
      headerName: "Float Status",
      editable: true,
      // valueGetter: (params) => {
      //     if (params.row.floatStatus === false) {
      //         return 'Accepts Floats'
      //     } else if (params.row.floatStatus) {
      //         return 'Sends Floats'
      //     } else {
      //         return 'Neither Accepts or Sends'
      //     }
      // },
      renderCell: (params) => {
        const status = floatStatus.find(
          (status) => status.value === params.row.floatStatus
        );

        return officeId === params.row.id ? (
          <Select
            variant="outlined"
            value={officeFloatStatus ? officeFloatStatus : status.name}
            onChange={(e) => {
              SetOfficeFloatStatus(e.target.value);
              setOfficeId(params.row.id);
            }}
            style={{ width: 240 }}
          >
            {floatStatus.map((status) => (
              <MenuItem key={status.name} value={status.name}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          status.name
        );
      },
    },
    // {
    //     field: "Timing for Requesting Floats",
    //     width: 250,
    //     headerName: "Timing for Requesting Floats",
    //     filterable: false,
    //     sortable: false,
    //     // valueGetter: (params) => 'Schedule in Draft'
    //     renderCell: (params) => {
    //         return officeId === params.row.id
    //             ? <Select
    //                 variant='outlined'
    //                 value={timingOptions[0]}
    //                 onChange={e => console.log(e.target.value)}
    //                 style={{width: 240}}
    //             >
    //                 {timingOptions.map(option => (
    //                     <MenuItem key={option} value={option}>
    //                         {option}
    //                     </MenuItem>
    //                 ))}
    //             </Select>
    //             : 'Schedule in Draft'
    //     },
    // },
    // {
    //   field: "Skills Allowed",
    //   width: 400,
    //   headerName: "Skills Allowed",
    //   filterable: false,
    //   sortable: false,
    //   // valueGetter: (params) => 'Nurses'
    //   renderCell: (params) =>
    //       <Typography name="skills" id="skills">
    //         {params.row.skillSet
    //           .filter(
    //             (skill) =>
    //               skill.variety === "JOB_TYPE" || skill.variety === "TRAINING"
    //           )
    //           .map((skill) => skill.name)
    //           .join(", ")}
    //       </Typography>
    // },
    {
      field: "Edit",
      width: 200,
      headerName: "Edit",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return officeId === params.row.id ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              updateOffice({
                variables: {
                  id: parseInt(params.row.id),
                  input: {
                    floatStatus:
                      floatStatus.find((e) => e.name === officeFloatStatus)
                        .name === "No Floats"
                        ? null
                        : floatStatus.find((e) => e.name === officeFloatStatus)
                            .value,
                  },
                },
              })
            }
          >
            Save
          </Button>
        ) : (
          <IconButton
            onClick={() => {
              setOfficeId(params.row.id);
            }}
            color="secondary"
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  if (loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const rows = data.offices;
    // const officesAcceptingFloats = data.offices.filter(office => (
    //     office.floatStatus === false
    // ));

    return (
      <Grid container>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={() => {
            setsnackbaropen(false);
            setShowLoader(false);
            refetch();
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              setsnackbaropen(false);
              setsnackBarMessage("");
            }}
            severity="success"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={errorsnackbaropen}
          autoHideDuration={3000}
          onClose={() => {
            seterrorsnackbaropen(false);
            setShowLoader(false);
            refetch();
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              seterrorsnackbaropen(false);
              setsnackBarMessage("");
            }}
            severity="error"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Grid item xs={6} className={classes.headerSpacing}>
          <Typography variant="h3">Settings</Typography>
        </Grid>
        <Grid item xs={8}>
          <DataGrid
            className={classes.dt}
            rows={rows}
            columns={columns}
            autoHeight
            autoPageSize
            // getRowCl
            // rowHeight={100}
            // getRowClassName={params => 'taller-row'}
            // className={classes.row}
            // pageSize={10}
            // components={{
            // Toolbar: CustomToolbar,
            // }}
          />
        </Grid>
      </Grid>
    );
  }
};

export default FloatSettings;
