import React, { useEffect, useState } from "react";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashBoardSideNav from "../navigation/DashBoardSideNav";
import Dashboard from "../Dashboard/Dashboard";
import Preferences from "../preferences/Preferences";
import ShiftBasedLanding from "../shiftBasedCalendar/Landing";
import MissionBasedLanding from "../missionBasedCalendar/Landing";
import FloatLanding from "../floatCalendar/Landing";
import ManageUsers from "../mangUsers/MangTeam";
import ImportData from "../importData/ImportData";
import ManageOffice from "../admin/ManageOffice";
import Profile from "../profile/Profile";
import Notifications from "../notifications/Notifications";
import FirstTimeUser from "../userAuthentication/firstTimeUser";
import OfficeSetupGuide from "../admin/OfficeSetupGuide";
import LocationSettings from "../admin/LocationSettings";
import RolesandPermissions from "../admin/RolesandPermissions";
import AnalyticsOverview from "../Analytics/AnalyticsOveview";
import PreferenceDashboard from "../Analytics/PreferenceDashboard";
import SkillSetDashboard from "../Analytics/SkillSetDashboard";
import TimeOffDashboard from "../Analytics/TimeOffAnalytics";
// import UpcomingShift from '../upcomingShift/UpcomingShift';
import FloatSettings from "../floating/FloatSettings";
import FloatNeeds from "../floating/FloatNeeds";
import DepartmentStatus from "../floating/DepartmentStatus";
import CreateSchedule from "../createSchedule/CreateSchedule";
import AllProcedures from "../admin/AllProcedures";
import Requests from "../requests/Requests";
import FloatAnalytics from "../Analytics/FloatAnalytics";
import LocationAnalytics from "../Analytics/LocationPreferenceDashboard";
import ErrorSnackBar from "../errors/errorSnackBar";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import MangMentees from "../MangMentees/mentee";
import {
  Container,
  Box,
  List,
  CssBaseline,
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Button,
  IconButton,
  Badge,
  Popover,
  ListItem,
  Dialog,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Roles from "../../Roles/roles";
import NotificationsDropdown from "../notifications/NotificationsDropdown";
import AppSettings from "../admin/AppSettings";
import Copyright from "./Copyright";
import {
  filterListVar,
  isLoggedInVar,
  userVar,
  initialScheduleStartDateVar,
  // initialScheduleStartTimeVar,
  newScheduleStartDateVar,
  selectedDateVar,
  appsettingsVar,
  locationConfigVar,
  officeConfigVar,
  notificationsVar,
} from "../../cache";
import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import {
  GET_NOTIFICATION_COUNT,
  GET_USER_NAME,
  ABILITIES,
  PUBLICLOCATION,
  REFRESH_TOKEN,
  LOCATION,
  NOTIFY_DEVELOPERS,
  VERIFY_TOKEN,
} from "../../api/gqlQueries";
import Ability from "../../Roles/defineAbility";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import { mapHiredShifts } from "../../helpers/hiredShifts";
import { fromUnixTime, differenceInSeconds } from "date-fns";
import LogRocket from "logrocket";
import Integrations from "../admin/Integrations";
import EmployeeAllocation from "../floating/EmployeeAllocation";
import { WindowSharp } from "@mui/icons-material";
import { format, getTime } from "date-fns";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflowX: "hidden",
  },
  mainContainer: {
    marginBottom: "50px",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  dividerColor: {
    border: "0.5px solid#ffffff",
    height: "50px",
    marginRight: "20px",
    marginLeft: "20px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 10,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    overflow: "none",
  },
  drawerPaper: {
    position: "sticky",
    paddingTop: 50,
    flexGrow: 1,
    bottom: 20,
    //background: "#EDE9E0",
    background: "none",
    whiteSpace: "nowrap",
    overflow: "none",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    position: "sticky",
    paddingTop: 50,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function AuthenticatedApp() {
  const client = useApolloClient();
  const classes = useStyles();
  const history = useHistory();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const locationSettings = useQuery(PUBLICLOCATION, {
    onCompleted(d) {
      appsettingsVar({ color: d.locationPublicSettings[0].hexcolor });
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);

      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PUBLICLOCATION Query. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const locationConfig = useQuery(LOCATION, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on LOCATION Query. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const [logourl, setlogourl] = useState("");
  const [windowHeight, SetWindowHeight] = useState(
    parseInt(window.innerHeight)
  );
  const [openRefreshTokenDialog, SetOpenRefreshTokenDialog] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () =>
      SetWindowHeight(window.innerHeight)
    );
  }, []);

  const currentLogo =
    !locationSettings.loading &&
    locationSettings.data &&
    locationSettings.data.locationPublicSettings[0].logo;

  const appSettings = appsettingsVar();
  appSettings.logo = currentLogo;
  const baseURL = window.location.origin;
  const environment = process.env.NODE_ENV;

  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    onCompleted(data) {
      console.log(data);
      const expires = fromUnixTime(data.refreshToken.payload.exp);
      const now = new Date();
      const distanceInSeconds = differenceInSeconds(expires, now) - 10;
      sessionStorage.setItem("jwt", data.refreshToken.token);
      setTimeout(() => {
        if (sessionStorage.getItem("jwt")) {
          refreshToken({
            variables: {
              token: sessionStorage.getItem("jwt"),
            },
          });
        }
      }, [1000 * distanceInSeconds]);
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Logging Out."
      );
      handleLogout();
      notifyDevelopers({
        variables: {
          message:
            "Error on REFRESH_TOKEN Mutation. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
      console.log(error);
      isLoggedInVar(false);
      sessionStorage.removeItem("jwt");
      sessionStorage.clear("jwt");
      client.cache.reset();
      userVar({});
      filterListVar({});
    },
  });

  const handleLogout = () => {
    SetOpenRefreshTokenDialog(false);
    isLoggedInVar(false);
    userVar({});
    filterListVar({});
    selectedDateVar(new Date());
    initialScheduleStartDateVar(null);
    newScheduleStartDateVar(false);
    sessionStorage.removeItem("jwt");
    sessionStorage.clear("jwt");
    client.cache.reset();
  };

  const handleIdle = () => {
    SetOpenRefreshTokenDialog(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 29,
    onIdle: handleIdle,
    stopOnIdle: false,
  });

  useEffect(() => {
    if (sessionStorage.getItem("jwt")) {
      refreshToken({
        variables: {
          token: sessionStorage.getItem("jwt"),
        },
      });
    }
  }, []);

  useEffect(() => {
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [!locationSettings.loading]);

  useEffect(() => {
    if (environment === "development") {
      setlogourl("https://backendtest.balancescheduler.com" + appSettings.logo);
    } else if (baseURL.includes("amplify")) {
      setlogourl(appSettings.logo);
    } else {
      setlogourl(appSettings.logo);
    }
  }, [appSettings.logo]);

  const { loading, error, data } = useQuery(GET_USER_NAME, {
    onCompleted() {
      const user = data.me;
      LogRocket.identify(user.id, {
        email: user.email,
      });

      // const primaryAssignment = user.assignmentSet.find(
      //   (assignment) => assignment.primary === true
      // );
      // const userRole = primaryAssignment
      //   ? primaryAssignment.role.name.toUpperCase()
      //   : user.assignmentSet[0].role.name.toUpperCase();

      const userVariable = userVar();
      userVar({
        ...userVariable,
        alwaysOpenGuide: user.alwaysOpenGuide,
        showPrefsExample: user.showPrefsExample,
      });
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_USER_NAME. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const userpermissions = useQuery(ABILITIES, {
    onCompleted(data) {
      SetuserAbilities(data.abilities);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ABILITIES Query. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const [userAbilities, SetuserAbilities] = useState({});

  const { data: notificationsData, loading: notificationsLoading } = useQuery(
    GET_NOTIFICATION_COUNT,
    {
      pollInterval: 15000,
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_NOTIFICATION_COUNT Query. Environment: " +
              environment +
              " Graphql " +
              error,
          },
        });
      },
    }
  );

  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    if (window.location.pathname === "/") {
      setOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    onCompleted(data) {
      console.log(data.payload);
    },
    onError(error) {
      console.log(error);
      isLoggedInVar(false);
      sessionStorage.removeItem("jwt");
      sessionStorage.clear("jwt");
      client.cache.reset();
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem("jwt")) {
      verifyToken({
        variables: {
          token: sessionStorage.getItem("jwt"),
        },
      });
    } else {
      handleLogout();
    }
  }, []);

  if (
    loading ||
    locationSettings.loading ||
    notificationsLoading ||
    userpermissions.loading ||
    locationConfig.loading
  ) {
    return <Typography>Loading...</Typography>;
  } else if (error) {
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const notificationCount = notificationsData
      ? notificationsData.me.notificationSet.filter(
          (notification) => notification.read !== true
        ).length
      : 0;
    notificationsVar(notificationCount);
    const user = data.me;
    const primaryAssignment = user.assignmentSet.find(
      (assignment) => assignment.primary === true && assignment.endDate === null
    );

    const secondaryOffices = user.assignmentSet.filter(
      (assignment) => assignment.primary === false
    );

    const schedulable = primaryAssignment
      ? primaryAssignment.schedulable
      : user.assignmentSet[0].schedulable;
    const primaryUnit = primaryAssignment
      ? primaryAssignment.office
      : user.assignmentSet[0].office;
    const userRole = primaryAssignment
      ? primaryAssignment.role.name.toUpperCase()
      : user.assignmentSet[0].role.name.toUpperCase();
    const shiftType = primaryAssignment
      ? mapHiredShifts(
          primaryAssignment.hiredshiftSet,
          primaryAssignment.rotationLength
        )
      : mapHiredShifts(
          user.assignmentSet[0].hiredshiftSet,
          user.assignmentSet[0].rotationLength
        );
    // const shiftType = primaryAssignment
    //   ? primaryAssignment.shiftType.name
    //   : user.assignmentSet[0].shiftType.name;
    const userName = user.firstName + " " + user.lastName;
    const locationConfigVariable = locationConfigVar();
    locationConfigVar({
      analyticsTab: locationConfig.data.locations[0].analyticsTab,
      integrationsTab: locationConfig.data.locations[0].integrationsTab,
      shiftBasedScheduling:
        primaryAssignment.office.shiftBasedDisplay === null ||
        primaryAssignment.office.shiftBasedDisplay === "undefined"
          ? locationConfig.data.locations[0].shiftBasedDisplay
          : primaryAssignment.office.shiftBasedDisplay,
      shiftBasedDisplay:
        primaryAssignment.office.shiftBasedDisplay === null ||
        primaryAssignment.office.shiftBasedDisplay === "undefined"
          ? locationConfig.data.locations[0].shiftBasedDisplay
          : primaryAssignment.office.shiftBasedDisplay,
    });

    officeConfigVar({
      softRequests: primaryAssignment.office.softRequests,
      shiftBasedDisplay:
        primaryAssignment.office.shiftBasedDisplay != null
          ? primaryAssignment.office.shiftBasedDisplay
          : locationConfigVariable.shiftBasedDisplay,
      shiftTypePreferences: primaryAssignment.office.shiftTypePrefs,
      daysOfTheWeekPreferences: primaryAssignment.office.weekdaysPrefs,
      daysOffPreferences: primaryAssignment.office.daysOffPrefs,
      overTimePreferences: primaryAssignment.office.overtimePrefs,
      procedureImports: primaryAssignment.office.procedureImports,
      employeeImports: primaryAssignment.office.employeeImports,
      availabilityImports: primaryAssignment.office.availabilityImports,
      skillImports: primaryAssignment.office.skillImports,
      trainingImports: primaryAssignment.office.trainingImports,
    });

    if (
      userRole === Roles.MANAGER ||
      userRole === Roles.SCHEDULER ||
      userRole === Roles.ADMIN
    ) {
      const shiftfilterList = {
        allFilters: ["All Shifts", "All Shift Type", "All Job Type"],
        shiftsFilters: ["All Shifts"],
        shiftTypeFilters: ["All Shift Type"],
        requestsFilters: [],
        jobTypeFilters: ["All Job Type"],
      };
      const missionfilterList = {
        allFilters: ["All Shifts", "All Missions", "All Job Type"],
        shiftsFilters: ["All Shifts"],
        missionFilters: ["All Missions"],
        requestsFilters: [],
        jobTypeFilters: ["All Job Type"],
      };
      locationConfigVariable.shiftBasedDisplay
        ? filterListVar(shiftfilterList)
        : filterListVar(missionfilterList);
    } else {
      const shiftfilterList = {
        allFilters: [
          "Personal",
          "All Shift Type",
          "All Requests",
          "Soft Requests",
          "Pending",
          "Approved",
          "All Job Type",
        ],
        shiftsFilters: ["Personal"],
        shiftTypeFilters: ["All Shift Type"],
        requestsFilters: [
          "Soft Requests",
          "Pending",
          "Approved",
          "All Requests",
        ],
        jobTypeFilters: ["All Job Type"],
      };
      const missionfilterList = {
        allFilters: [
          "Personal",
          "All Missions",
          "All Requests",
          "Soft Requests",
          "Pending",
          "Approved",
          "All Job Type",
        ],
        shiftsFilters: ["Personal"],
        missionFilters: ["All Missions"],
        requestsFilters: [
          "Soft Requests",
          "Pending",
          "Approved",
          "All Requests",
        ],
        jobTypeFilters: ["All Job Type"],
      };
      locationConfigVariable.shiftBasedDisplay
        ? filterListVar(shiftfilterList)
        : filterListVar(missionfilterList);
    }
    let primaryAllowsManual = locationConfig.data.locations[0].officeSet.find(
      (e) => e.id === primaryUnit.id
    )?.isManual;

    let isPrimaryParentOffice =
      primaryUnit.parent === null &&
      locationConfig.data.locations[0].officeSet.filter(
        (e) => e.parent && e.parent.id === primaryUnit.id
      ).length > 0;
    let isPrimayChildOffice = primaryUnit.parent != null;

    const userObject = {
      office: primaryUnit,
      role: userRole,
      name: userName,
      id: user.id,
      shiftType: shiftType,
      isEmployee: userRole === Roles.EMPLOYEE,
      isAdmin: userRole === Roles.ADMIN,
      isManager: userRole === Roles.MANAGER,
      isScheduler: userRole === Roles.SCHEDULER,
      isSchedulable: schedulable,
      userabilities: userAbilities,
      floatOffice: primaryUnit.floatStatus,
      skills: user.skills,
      maxgreyOutRequests: primaryUnit.maxgreyOutRequests,
      allowCallIns: primaryUnit.allowCallIns,
      scheduleDuration: primaryUnit.scheduleDuration,
      manuallySchedulable: primaryAssignment.isManual,
      secondaryOffices: secondaryOffices,
      officeAllowsManual: primaryAllowsManual,
      isPrimaryParentOffice: isPrimaryParentOffice,
      isPrimayChildOffice: isPrimayChildOffice,
    };

    const userVariable = userVar();
    userVar({ ...userVariable, ...userObject });

    const ability = Ability(userObject);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
        <AppBar position="absolute" className={clsx(classes.appBar)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <Box m={1} component={Link} to="/">
              {/* <img
                // src={
                //   appSettings.logo && !logoErr
                //     ? logourl
                //     : "Duality-Systems_white.png"
                // }
                //src="Fillmore.png"
                src="Duality-Systems_white.png"
                height={40}
                alt="Fillmore Logo"
              /> */}
              <img src="IntermountainLogo.png" height="50" />
            </Box>
            {/* <Divider className={classes.dividerColor} /> */}
            <Typography
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {/* <i
                style={{
                  color: "#d3d3d3",
                  fontFamily: "Tahoma",
                  fontWeight: "650",
                }}
              >
                BALANCE{" "}
              </i>
              <i
                style={{
                  color: "#ffffff",
                  fontFamily: "Tahoma",
                  fontWeight: "650",
                }}
              >
                SCHEDULER
              </i> */}
            </Typography>

            <IconButton
              color="inherit"
              onClick={(e) => setNotificationsAnchorEl(e.currentTarget)}
            >
              <Badge badgeContent={notificationCount} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Popover
              anchorEl={notificationsAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(notificationsAnchorEl)}
              onClose={() => setNotificationsAnchorEl(null)}
            >
              <Container style={{ padding: 10, overflow: "hidden" }}>
                <NotificationsDropdown
                  closePopover={() => setNotificationsAnchorEl(null)}
                  userId={data.me.id}
                />
              </Container>
            </Popover>

            <Button onClick={(e) => setProfileAnchorEl(e.currentTarget)}>
              {data.me.firstName && data.me.lastName ? (
                <Avatar>{data.me.firstName[0] + data.me.lastName[0]}</Avatar>
              ) : (
                <Avatar>
                  <AccountCircleIcon style={{ fontSize: 40 }} />
                </Avatar>
              )}
            </Button>
            <Popover
              anchorEl={profileAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(profileAnchorEl)}
              onClose={() => setProfileAnchorEl(null)}
            >
              <List>
                <ListItem
                  component={Link}
                  to="/Profile"
                  onClick={() => setProfileAnchorEl(null)}
                >
                  My Profile
                </ListItem>
                <ListItem component={Link} to="/UpdatePassword">
                  Update Password
                </ListItem>
                <ListItem component={Link} to="/" onClick={handleLogout}>
                  Logout
                </ListItem>
              </List>
            </Popover>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          style={{ height: windowHeight + 150 }}
        >
          <Divider />
          <List>
            <DashBoardSideNav setDrawerOpen={setOpen} />
          </List>
          <Divider />
          <Box
            style={{
              position: "fixed",
              bottom: 20,
              display: !open && "none",
              left: 30,
            }}
          >
            <img
              src="Balance_Logo_in_White.png"
              height="50"
              width="130"
              alt="Duality Logo"
            />
            <Copyright />
          </Box>
        </Drawer>
        <div className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.mainContainer} maxWidth={false}>
            <Dialog open={openRefreshTokenDialog} fullWidth maxWidth="sm">
              <DialogContent
                style={{
                  padding: 40,
                  overflowX: "hidden",
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: "center", marginBottom: 40 }}
                >
                  There has been no activity siince 30 minutes.
                </Typography>
                <Grid style={{ textAlign: "center" }}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleLogout}
                    style={{ marginRight: 30 }}
                  >
                    Log Out{" "}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (sessionStorage.getItem("jwt")) {
                        refreshToken({
                          variables: {
                            token: sessionStorage.getItem("jwt"),
                          },
                        });
                      }
                      SetOpenRefreshTokenDialog(false);
                    }}
                  >
                    {" "}
                    Stay Logged In
                  </Button>
                </Grid>
              </DialogContent>
            </Dialog>
            <Box>
              <Switch>
                <>
                  {!data.me.hasChangedDefaultPassword ? (
                    <Route
                      path="/"
                      exact
                      component={() => <FirstTimeUser user={data.me} />}
                    />
                  ) : (
                    <>
                      {ability.can("manage", "Profile") && (
                        <Route path="/Profile" component={Profile} />
                      )}
                      {
                        <Route
                          path="/UpdatePassword"
                          exact
                          component={() => <FirstTimeUser user={data.me} />}
                        />
                      }
                      {ability.can("manage", "Notifications") && (
                        <Route
                          path="/Notifications"
                          component={Notifications}
                        />
                      )}
                      {ability.can("manage", "Calendar") &&
                      locationConfigVariable.shiftBasedDisplay === true ? (
                        <>
                          <Route path="/" exact component={Dashboard} />
                          <Route
                            path="/Schedule"
                            exact
                            component={ShiftBasedLanding}
                          />
                          <Route
                            path="/Schedule/:date/draft"
                            exact
                            component={ShiftBasedLanding}
                          />
                        </>
                      ) : (
                        <>
                          <Route path="/" exact component={Dashboard} />
                          <Route
                            path="/Schedule"
                            exact
                            component={MissionBasedLanding}
                          />
                          <Route
                            path="/Schedule/:date/draft"
                            exact
                            component={MissionBasedLanding}
                          />
                        </>
                      )}
                      {ability.can("manage", "Preferences") && schedulable && (
                        <Route path="/Preferences" component={Preferences} />
                      )}
                      {ability.can("manage", "Schedule") && (
                        <Route
                          path="/CreateSchedule"
                          component={CreateSchedule}
                        />
                      )}
                      {ability.can("manage", "Requests") && (
                        <Route
                          path="/Schedule/:request"
                          exact
                          component={ShiftBasedLanding}
                        />
                        /* <Route
                          path="/UpcomingShift"
                          component={UpcomingShift}
                        />{" "}
                        */
                      )}

                      {ability.can("manage", "Users") && (
                        <>
                          <Route
                            path="/ManageTeam"
                            component={() => (
                              <ManageUsers open={true} user={user} />
                            )}
                          />
                          <Route
                            path="/ManageMentees"
                            component={MangMentees}
                          />
                        </>
                      )}
                      {ability.can("manage", "Users") && (
                        <>
                          <Route path="/ImportData" component={ImportData} />
                        </>
                      )}
                      {ability.can("manage", "Users") &&
                        userVariable.floatOffice && (
                          <>
                            <Route
                              path="/EmployeeAllocation"
                              component={EmployeeAllocation}
                            />
                          </>
                        )}
                      {ability.can("manage", "Users") &&
                        userVariable.floatOffice &&
                        locationConfigVariable.analyticsTab && (
                          <>
                            <Route
                              path="/FloatAnalytics"
                              component={FloatAnalytics}
                            />
                          </>
                        )}
                      {ability.can("manage", "Users") &&
                        locationConfigVariable.analyticsTab && (
                          <>
                            <Route
                              path="/ScheduleQualityAnalytics"
                              component={AnalyticsOverview}
                            />
                            <Route
                              path="/PreferenceAnalytics"
                              component={PreferenceDashboard}
                            />
                            <Route
                              path="/SkillSetAnalytics"
                              component={SkillSetDashboard}
                            />
                            <Route
                              path="/TimeOffAnalytics"
                              component={TimeOffDashboard}
                            />
                            <Route
                              path="/LocationPreferenceAnalytics"
                              component={LocationAnalytics}
                            />
                          </>
                        )}
                      {ability.can("manage", "AllOffices") && (
                        <>
                          <Route
                            path="/Admin"
                            component={() => (
                              <ManageOffice open={false} office={null} />
                            )}
                          />
                          <Route
                            path="/SetupGuide"
                            component={OfficeSetupGuide}
                          />
                          {!locationConfigVariable.shiftBasedDisplay && (
                            <Route
                              path="/MissionDetails"
                              component={AllProcedures}
                            />
                          )}
                          {userObject.floatOffice && (
                            <>
                              <Route
                                path="/FloatSettings"
                                component={FloatSettings}
                              />
                              <Route
                                path="/FloatNeeds"
                                component={FloatNeeds}
                              />
                              <Route
                                path="/DepartmentStatus"
                                component={DepartmentStatus}
                              />
                            </>
                          )}
                        </>
                      )}
                      {ability.can("manage", "LocationSettings") && (
                        <Route
                          path="/LocationSettings"
                          component={LocationSettings}
                        />
                      )}
                      {ability.can("manage", "AppSettings") && (
                        <Route path="/AppSettings" component={AppSettings} />
                      )}
                      {ability.can("manage", "Requests") && (
                        <Route path="/Requests" component={Requests} />
                      )}
                      {ability.can("manage", "RolesandPermissions") && (
                        <Route
                          path="/RolesandPermissions"
                          component={RolesandPermissions}
                        />
                      )}
                    </>
                  )}
                </>
              </Switch>
            </Box>
          </Container>
        </div>
      </div>
    );
  }
}

export default AuthenticatedApp;
