import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  Select,
  makeStyles,
  CircularProgress,
  Button,
  Snackbar,
  Dialog,
  DialogContent
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ShiftSlackCalendar from "../slacks/ShiftSlackCalendar";
import MangAddEmployees from "../floatCalendar/MangAddEmployees";
import { useLazyQuery, useQuery } from "@apollo/client";
import { 
  GET_FLOAT_DETAILS, GET_SLACK_FLOAT, GET_SINGLE_SHIFT
} from "../../api/gqlQueries";
import {
  isAfter,
  isBefore,
  isSameDay,
  isEqual,
  differenceInHours,
  format,
  add,
} from "date-fns";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const FloatNeeds = () => {
  const classes = useStyles();
  
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const shiftId = queryParams.get("shift");
  const comment = queryParams.get("comments");

  let aggregatedSlacks = [];

  const { loading, error } = useQuery(GET_FLOAT_DETAILS, {
    onCompleted(data) {
      console.log(data);
      const officesAcceptingFloats = data.offices.filter(
        (office) => office.floatStatus === false
      );

      if (officesAcceptingFloats.length > 0) {
        setOffices(officesAcceptingFloats);
      }
    },
  });

  const [getSlackIssues] = useLazyQuery(GET_SLACK_FLOAT, {
    onCompleted(data) {
      console.log(data);
      const allSlacks = [...data.slacks];
      const mostRecentSchedulePeriodIds = [];
      offices.forEach((office) => {
        const mostRecent = findMostRecentSchedulePeriod(office);
        if (mostRecent) {
          if (
            mostRecent.status === "REQUESTING_FLOATS" ||
            mostRecent.status === "REQUESTING_ADDITIONAL_FLOATS"
          ) {
            mostRecentSchedulePeriodIds.push(mostRecent.id);
          }
        }
      });

      //filter to get slacks from most recent schedule period with
      //a skill of RN
      const slacksFromMostRecent = allSlacks.filter(
        (slack) =>
          mostRecentSchedulePeriodIds.includes(slack.schedulePeriod.id) &&
          (slack.slack < 0 || slack.assigned < slack.lower)
      );
      
      // setAggregatedSlacks([]);
      aggregatedSlacks = [];
      setAllSlackIssues(slacksFromMostRecent);
      aggregateTimes(slacksFromMostRecent);
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getShiftData] = useLazyQuery(GET_SINGLE_SHIFT, {
    onCompleted(data) {
      if (data.shifts.length > 0) {
        const newEvent = {
          id: data.shifts[0].id,
          start: new Date(data.shifts[0].start),
          end: new Date(data.shifts[0].end),
          office: data.shifts[0].office
        }
        setShiftEvent(newEvent);
        setShowAddEmployees(true);
      }
    }
  })

  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState("All");
  const [allSlackIssues, setAllSlackIssues] = useState([]);
  const [slackEventsToView, setSlackEventsToView] = useState([]);
  const [shiftLength, setShiftLength] = useState('8');
  const [shiftEvent, setShiftEvent] = useState();
  const [showAddEmployees, setShowAddEmployees] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    if (shiftId) {
      getShiftData({variables: {
        id: parseInt(shiftId)
      }});
    }
  }, []);

  useEffect(() => {
    let rangeStart;
    let rangeEnd;
    if (offices.length > 0) {
      offices.forEach((office) => {
        const mostRecent = findMostRecentSchedulePeriod(office);
        //add logic to make sure most recent is the correct stage for
        //accepting floats
        if (mostRecent) {
          if (rangeStart && rangeEnd) {
            if (isBefore(new Date(mostRecent.start), new Date(rangeStart))) {
              rangeStart = `${mostRecent.start}T00:00:00`;
            }
            if (isAfter(new Date(mostRecent.end), new Date(rangeEnd))) {
              rangeEnd = `${mostRecent.end}T23:59:00`;
            }
          } else {
            rangeStart = `${mostRecent.start}T00:00:00`;
            rangeEnd = `${mostRecent.end}T23:59:00`;
          }
        }
      });

      if (rangeStart && rangeEnd) {
        getSlackIssues({
          variables: {
            rangeStart: rangeStart,
            rangeEnd: rangeEnd,
            issuesOnly: true,
          },
        });
      }
    }
  }, [offices]);

  useEffect(() => {
    const office = offices.find((office) => office.id === selectedOffice);

    if (selectedOffice === "All") {
      // setAggregatedSlacks([]);
      aggregatedSlacks = [];
      aggregateTimes(allSlackIssues);
    } else if (selectedOffice && office) {
      const mostRecent = findMostRecentSchedulePeriod(office);
      if (mostRecent) {
        if (
          mostRecent.status === "REQUESTING_FLOATS" ||
          mostRecent.status === "REQUESTING_ADDITIONAL_FLOATS"
        ) {
          const officeSlacks = allSlackIssues.filter(
            (slack) => slack.schedulePeriod.id === mostRecent.id
          );
          // setAggregatedSlacks([]);
          aggregatedSlacks = [];
          aggregateTimes(officeSlacks);
        }
      } else {
        setSlackEventsToView([]);
      }
    }
  }, [selectedOffice, shiftLength]);

  function findMostRecentSchedulePeriod(office) {
    const sortedSchedules = [...office.scheduleperiodSet];
    sortedSchedules.sort((a, b) => new Date(b.start) - new Date(a.start));
    return sortedSchedules[0];
  }

  function aggregateTimes(slacks) {
    let extras = [];
    let newSlacks = [];
    if (slacks.length > 0) {
      const slacksCopy = [...slacks];
      slacksCopy.sort((a,b) => new Date(a.start) - new Date(b.start));

      slacksCopy.forEach((slack) => {
        let skillName;
        let schedulePeriodId;
        if (slack.schedulePeriod.id) {
          skillName = slack.skill.name;
          schedulePeriodId = slack.schedulePeriod.id;
        } else {
          skillName = slack.skill;
          schedulePeriodId = slack.schedulePeriod;
        }

        let existsAndConsecutive = newSlacks.filter(
          (existingSlack) =>
            existingSlack.skill === skillName &&
            // (existingSlack.slack === slack.slack ||
            //   existingSlack.slack < slack.slack) &&
            isEqual(existingSlack.end, new Date(slack.start)) &&
            isSameDay(existingSlack.start, new Date(slack.start)) &&
            existingSlack.schedulePeriod === schedulePeriodId
        );

        if (existsAndConsecutive.length > 0) {
          existsAndConsecutive.forEach((existingSlack) => {
            if (existingSlack.slack === slack.slack) {
              //slacks are the same so we just aggregate the times together
              existingSlack.end = new Date(slack.end);
            } else if (existingSlack.slack > slack.slack) {
              existingSlack.end = new Date(slack.end);
              //need of our existing event is less than the need of the next
              //event so we add to the existing event and create a new event
              //with the difference between needs
              const difference = slack.slack - existingSlack.slack;
              extras.push({
                id: slack.id,
                slack: difference,
                skill: skillName,
                start: new Date(slack.start),
                end: new Date(slack.end),
                schedulePeriod: schedulePeriodId,
                office: slack.office
                  ? slack.office
                  : {
                      name: slack.schedulePeriod.office.name,
                      id: slack.schedulePeriod.office.id,
                    },
              });
            } else {
              //need of our existing event is greater than the need of the next
              //event so we pull out the extra need from the existing event
              //and create a new event with the lower need amount to fill the time
              //of both events
              const difference = existingSlack.slack - slack.slack;
              const index = newSlacks.indexOf(existingSlack);
              //pull existing out of aggregated slacks and change the slack
              //to the difference. Add this to extras.
              newSlacks.splice(index, 1);
              existingSlack.slack = difference;
              extras.push(existingSlack);

              //add new slack with lower need amount and times aggregated to
              //aggregated slacks array
              newSlacks.push({
                id: slack.id,
                slack: slack.slack,
                skill: skillName,
                start: existingSlack.start,
                end: new Date(slack.end),
                schedulePeriod: schedulePeriodId,
                office: slack.office
                  ? slack.office
                  : {
                      name: slack.schedulePeriod.office.name,
                      id: slack.schedulePeriod.office.id,
                    },
              });
            }
          });
        } else {
          newSlacks.push({
            id: slack.id,
            slack: slack.slack,
            skill: skillName,
            start: new Date(slack.start),
            end: new Date(slack.end),
            schedulePeriod: schedulePeriodId,
            office: slack.office
              ? slack.office
              : {
                  name: slack.schedulePeriod.office.name,
                  id: slack.schedulePeriod.office.id,
                },
          });
        }
      });
      aggregatedSlacks = [...aggregatedSlacks, ...newSlacks];
      // setAggregatedSlacks(newAggregatedSlacks);
      //aggregate the extras by themselves until there are no more extras
      aggregateTimes(extras);
    } else {
      const events = createSlackEvents(aggregatedSlacks);
      setSlackEventsToView(events);
      return;
    }
  }

  function getOfficeData(slack, slackAbsoluteValue) {
    let officeData = [];

    const formattedStart = format(slack.start, 'HH:mm');
    const formattedEnd = format(slack.end, 'HH:mm');

    const selectedShiftLength = parseInt(shiftLength);
    const slackLength = differenceInHours(
        slack.end, 
        slack.start
    );

    if (slackLength > selectedShiftLength) {
        const finalEnd = slack.end;
        let currentStart = slack.start;
        let currentEnd = add(
            slack.start, 
            {hours: selectedShiftLength}
        );
        const formattedFinalEnd = format(finalEnd, 'HH:mm');

        while (finalEnd > currentEnd) {
            const formattedStart = format(currentStart, 'HH:mm');
            const formattedEnd = format(currentEnd, 'HH:mm');
            officeData.push({
                time: `${formattedStart} - ${formattedEnd}`,
                office: slack.office,
                need: slackAbsoluteValue,
                start: currentStart,
                end: currentEnd
            });
            currentStart = currentEnd;
            currentEnd = add(currentEnd, {hours: selectedShiftLength});
        }

        officeData.push({
            time: `${
                format(currentStart, 'HH:mm')
            } - ${formattedFinalEnd}`,
            office: slack.office,
            need: slackAbsoluteValue,
            start: currentStart,
            end: finalEnd
        });
    } else {
        officeData = [{
            time: `${formattedStart} - ${formattedEnd}`,
            office: slack.office,
            need: slackAbsoluteValue,
            start: slack.start,
            end: slack.end
        }];
    }

    const multiplierOfStaff = Math.ceil(
        slackLength / selectedShiftLength
    );

    return {
        officeData, 
        slackAbsoluteValue: slackAbsoluteValue * multiplierOfStaff
    };
  }
 
  function createSlackEvents(slacks) {
    const sortedByTime = [...slacks];
    sortedByTime.sort((a, b) => a.start - b.start);

    let events = [];
    sortedByTime.forEach((slack) => {
      const match = events.find(
        (event) =>
          isSameDay(event.start, slack.start) && event.skill === slack.skill
      );

      let slackAbsoluteValue = Math.abs(slack.slack);
      const data = getOfficeData(slack, slackAbsoluteValue);
      slackAbsoluteValue = data.slackAbsoluteValue;
      const officeData = data.officeData;
      //if slack time slot is longer than a 12 hour shift, double
      //staff are needed to fill this slot
      

      if (match) {
        match.need += slackAbsoluteValue;
        match.title = `${match.need} ${match.skill}`;
        match.officeData = [...match.officeData, ...officeData];
      } else {
        events.push({
          id: slack.id,
          need: slackAbsoluteValue,
          skill: slack.skill,
          title: `${slackAbsoluteValue} ${slack.skill}`,
          start: new Date(slack.start),
          end: new Date(slack.end),
          officeData: officeData,
        });
      }
    });
    return events;
  }

  if (loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    return (
      <Grid>
        <Grid container>
          <Grid item className={classes.headerSpacing}>
            <Typography variant="h3">Department Needs</Typography>
            <Typography variant="subtitle2">Upcoming Schedules</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid container item xs={10} direction="row" alignItems='center'>
            <Grid item>
              <Typography variant="h5">Department:</Typography>
            </Grid>
            <Grid item >
              <Select
                variant="outlined"
                style={{ minWidth: 273, marginLeft: 8 }}
                value={selectedOffice}
                onChange={(e) => setSelectedOffice(e.target.value)}
              >
                <MenuItem value="All">All Departments</MenuItem>
                {offices.length > 0 ? (
                  offices.map((office) => (
                    <MenuItem key={office.id} value={office.id}>
                      {office.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={""}>No Departments Accept Floats</MenuItem>
                )}
              </Select>
            </Grid>
            <Grid item style={{marginLeft: 24}}>
                <Typography variant='h5'>Shift Length:</Typography>
            </Grid>
            <Grid item>
                <Select
                    variant='outlined'
                    style={{marginLeft: 8}}
                    value={shiftLength}
                    onChange={e => setShiftLength(e.target.value)}
                >
                    <MenuItem value='4'>
                        4 Hours
                    </MenuItem>
                    <MenuItem value='8'>
                        8 Hours
                    </MenuItem>
                    <MenuItem value='12'>
                        12 Hours
                    </MenuItem>
                </Select>
            </Grid>
          </Grid>
          <Grid container item xs={2} direction="row" justifyContent="flex-end">
            <Grid item >
              <Button
                onClick={() => history.push("/CreateSchedule")}
                color="primary"
                variant="contained"
              >
                Create Schedule
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 24 }}>
            {slackEventsToView.length > 0 ? (
              <ShiftSlackCalendar events={slackEventsToView} />
            ) : (
              <Typography>No float needs found</Typography>
            )}
          </Grid>
        </Grid>
        {shiftEvent &&
          <Dialog open={showAddEmployees} fullWidth maxWidth="md">
            <DialogContent style={{ 
              padding: 30, 
              height: 700, 
              position: "relative" 
            }}>
              <MangAddEmployees 
                event={shiftEvent}
                closeDialog={() => setShowAddEmployees(false)}
                setShowToast={setShowToast}
                setShowErrorToast={setShowErrorToast}
                comment={comment}
                singleShift={true}
              />
            </DialogContent>
          </Dialog>
        }
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>
              Employees successfully added!
            </Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>
              Could not add employees. Please try again.
            </Typography>
          </MuiAlert>
        </Snackbar>
      </Grid>
    );
  }
};

export default FloatNeeds;
