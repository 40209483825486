import React, { useState } from "react";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-react-layouts";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
  DataLabel,
} from "@syncfusion/ej2-react-charts";
import { useTheme } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Button,
  Dialog,
  DialogContent,
  Card,
  CardContent,
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  NOTIFY_DEVELOPERS,
  UTILIZATION_ANALYTICS,
  SCHEDULE_QUALITY_ANALYTICS,
  INTERVAL_SLACKS,
  MOST_RECENT_CENSUS,
  FIND_SKILL_TYPE,
  SCHEDULE_FITNESS,
  GET_SCHEDULE_PERIODS,
} from "../../api/gqlQueries";
import { userVar, appsettingsVar } from "../../cache";
import { add, format } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";
import "./DashboardLayout.css";
import Plot from "react-plotly.js";
import NextShiftDetails from "./NextShiftDetails";
import MonthOverview from "./MonthOverview";
import UpcomingTasks from "./UpcomingTasks";
import ScheduleInfo from "./ScheduleInfo";
import PreferenceFeedback from "./PreferenceFeedback";
import CensusDataForm from "../shiftBasedCalendar/CensusDataForm";
import DashboardFooter from "./DashboardFooter";
import { scheduleQualityGrade } from "../../helpers/DashboardHelpers";

const useStyles = makeStyles(() => ({
  panelStyle: {
    boxSizing: "border-box",
  },
  mainDiv: {
    width: "100%",
    marginBottom: 20,
  },
  box: {
    width: "25%",
    textAlign: "center",
    boxSizing: "border-box",
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

const ManagerDashboard = (props) => {
  const classes = useStyles();
  const cellSpacing = [8, 8];
  const theme = useTheme();

  const user = userVar();
  const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";
  const onemonthfromnow =
    format(add(new Date(), { months: 1 }), "yyyy-MM-dd") + "T00:00:00";
  const environment = process.env.NODE_ENV;

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [showcensus, SetShowCensus] = useState(false);

  const toggleCensusform = () => SetShowCensus(!showcensus);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const utilizationAnalytics = useQuery(UTILIZATION_ANALYTICS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(err) {
      console.log(err);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UTILIZATION_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  const scheduleQualityAnalytics = useQuery(SCHEDULE_QUALITY_ANALYTICS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(err) {
      console.log(err);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_QUALITY_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  const intervalSlacks = useQuery(INTERVAL_SLACKS, {
    variables: {
      office: parseInt(user.office.id),
      issuesOnly: true,
      rangeStart: today,
      rangeEnd: onemonthfromnow,
    },
    onError(err) {
      console.log(err);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_QUALITY_ANALYTICS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  const censusData = useQuery(MOST_RECENT_CENSUS, {
    variables: {
      office: parseInt(user.office.id),
      range: 5,
    },
    onError(err) {
      console.log(err);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on MOST_RECENT_CENSUS Query. Environment: " +
            environment +
            ". Graphql " +
            err,
        },
      });
    },
  });

  const skillSet = useQuery(FIND_SKILL_TYPE, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onError(err) {
      setOpenSnackBar(true);
      setSnackBarMessage("Graphql " + err);
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            " Graphql " +
            err,
        },
      });
    },
  });

  const SchedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onCompleted(d) {
      let currentSchedulePeriod = d.schedulePeriods.find(
        (e) => new Date(e.start) <= new Date() && new Date(e.end) >= new Date()
      );
      currentSchedulePeriod &&
        scheduleFitnesses({
          variables: {
            schedulePeriod: parseInt(currentSchedulePeriod.id),
            office: parseInt(user.office.id),
          },
        });
    },
  });
  const [scheduleFitnesses, { data: scheduleFitnessData }] = useLazyQuery(
    SCHEDULE_FITNESS,
    {
      onError(err) {
        setOpenSnackBar(true);
        setSnackBarMessage("Graphql " + err);
        notifyDevelopers({
          variables: {
            message:
              "Error on SCHEDULE_FITNESS Query. Environment: " +
              environment +
              " Graphql " +
              err,
          },
        });
      },
    }
  );

  if (
    utilizationAnalytics.loading ||
    scheduleQualityAnalytics.loading ||
    intervalSlacks.loading ||
    censusData.loading ||
    skillSet.loading ||
    SchedulePeriods.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const lighterPrimaryColor = convertHexToRGBA(appsettingsVar().color, 40);

    //all employees utilization values
    let allUtilization =
      utilizationAnalytics.data.utilizationAnalytics &&
      utilizationAnalytics.data.utilizationAnalytics.map((e) =>
        parseFloat(e.utilization)
      );

    const patientLevelDataGraph =
      censusData.data.censusTrend &&
      censusData.data.censusTrend.length > 0 &&
      censusData.data.censusTrend.map((e) => {
        return {
          x: format(new Date(e.createdAt), "MM-dd-yyyy"),
          y: parseInt(e.count),
        };
      });

    const leastCapacity = utilizationAnalytics.data.utilizationAnalytics
      .filter((e) => e.skill != null)
      .reduce((a, b) => (a = a.utilization > b.utilization ? a : b), 0);

    const leastCapacitySkillSet = skillSet.data.skills.find(
      (e) => parseInt(e.id) === parseInt(leastCapacity?.skill)
    )?.name;

    const mostCapacity = utilizationAnalytics.data.utilizationAnalytics
      .filter((e) => e.skill != null)
      .reduce((a, b) => (a = a.utilization < b.utilization ? a : b), 0);

    const mostCapacitySkillSet = skillSet.data.skills.find(
      (e) => parseInt(e.id) === parseInt(mostCapacity?.skill)
    )?.name;

    const one = () => {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <span id="close" className="e-close-icon e-clear-icon" />
          <NextShiftDetails />
        </div>
      );
    };

    const two = () => {
      let empScheduleFitnesses =
        scheduleFitnessData &&
        scheduleFitnessData.employeeFitnesses.length > 0 &&
        scheduleFitnessData.employeeFitnesses.reduce(
          (a, b) => (a = a.score < b.score ? a : b)
        );

      let averagescheduleQualityForUnit =
        scheduleQualityAnalytics &&
        scheduleQualityAnalytics.data &&
        scheduleQualityAnalytics.data.scheduleQualityAnalytics &&
        (
          (scheduleQualityAnalytics.data.scheduleQualityAnalytics
            .map((e) => parseFloat(e.score))
            .reduce((a, b) => a + b, 0) /
            scheduleQualityAnalytics.data.scheduleQualityAnalytics.length) *
          100
        ).toFixed(2);

      let colorandgrade = scheduleQualityGrade(averagescheduleQualityForUnit);

      return (
        <>
          <Grid container spacing={2}>
            <span id="close" className="e-close-icon e-clear-icon" />
            <Grid item container>
              <Grid item xs={12}>
                {" "}
                <Box m={2}>
                  {" "}
                  <Typography variant="h4">UNIT STAFFING HEALTH</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-evenly"
              style={{
                marginBottom: 10,
              }}
            >
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                {" "}
                <Typography variant="body1">Imminent Shortages</Typography>
                <Typography variant="body1">
                  {intervalSlacks.data.intervalSlacks &&
                    intervalSlacks.data.intervalSlacks.length > 0 &&
                    intervalSlacks.data.intervalSlacks.filter(
                      (e) =>
                        new Date(e.interval.start) > new Date() &&
                        new Date(e.interval.start) <
                          add(new Date(), { days: 7 })
                    ).length}
                </Typography>
                <Typography variant="body2">
                  (shortages in the next week)
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body1">Next Shortage</Typography>
                <Typography variant="body1">
                  {" "}
                  {intervalSlacks.data.intervalSlacks &&
                    intervalSlacks.data.intervalSlacks.length > 0 &&
                    format(
                      new Date(
                        intervalSlacks.data.intervalSlacks[0].interval.start
                      ),
                      "MM-dd-yyyy"
                    )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body1"> Total Shortages</Typography>
                <Typography variant="body1">
                  {intervalSlacks.data.intervalSlacks &&
                    intervalSlacks.data.intervalSlacks.length}
                </Typography>
                <Typography variant="body2">
                  (shortages in the next month)
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body1">Utilization</Typography>
                <Typography variant="body1">
                  {allUtilization.length > 0 &&
                    (
                      (allUtilization.reduce((a, b) => a + b, 0) /
                        allUtilization.length) *
                      100
                    ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid item container justifyContent="space-evenly">
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  borderRadius: 4,
                  background: colorandgrade
                    ? colorandgrade.color
                    : lighterPrimaryColor,
                }}
              >
                <Typography variant="body1"> Schedule Quality</Typography>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {colorandgrade && colorandgrade.grade}
                </Typography>
                <Typography variant="body2">
                  (Average schedule quality for all employees of the unit)
                </Typography>
              </Grid>{" "}
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body1">Least Capacity</Typography>
                <Typography variant="body1">{leastCapacitySkillSet}</Typography>
              </Grid>{" "}
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body1">Most Capacity</Typography>
                <Typography variant="body1">{mostCapacitySkillSet}</Typography>
              </Grid>{" "}
              <Grid
                item
                xs={2}
                style={{
                  border: "1px solid" + appsettingsVar().color,
                  background: empScheduleFitnesses
                    ? scheduleQualityGrade(
                        empScheduleFitnesses.score?.toFixed(2)
                      ).color
                    : lighterPrimaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body1">Worst Schedule : </Typography>
                <Typography variant="subtitle2">
                  {" "}
                  Schedule Quality :{" "}
                  {empScheduleFitnesses
                    ? scheduleQualityGrade(
                        empScheduleFitnesses.score?.toFixed(2)
                      ).grade
                    : ""}
                </Typography>
                <Typography variant="subtitle2">
                  {" "}
                  Skills :{" "}
                  {empScheduleFitnesses
                    ? empScheduleFitnesses.employee &&
                      empScheduleFitnesses.employee.skills &&
                      empScheduleFitnesses.employee.skills.length > 0 &&
                      empScheduleFitnesses.employee.skills
                        .map((e) => e.name)
                        .join(",")
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </>
      );
    };

    const three = () => {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <span id="close" className="e-close-icon e-clear-icon" />
          <UpcomingTasks
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
          />
        </div>
      );
    };

    const four = () => {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <span id="close" className="e-close-icon e-clear-icon" />
          <MonthOverview />
        </div>
      );
    };

    const five = () => {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <span id="close" className="e-close-icon e-clear-icon" />
          <div>
            {" "}
            <Box m={2}>
              <Typography variant="h4">UNIT CENSUS </Typography>{" "}
              <Button
                color="primary"
                onClick={() => SetShowCensus(!showcensus)}
              >
                Add Unit Census
              </Button>
            </Box>
            <div className="control-pane">
              <div className="control-section">
                <ChartComponent
                  id="charts"
                  style={{ textAlign: "center", margin: "auto" }}
                  height="50%"
                  primaryXAxis={{
                    valueType: "Category",
                    interval: 1,
                    majordivLines: { width: 0 },
                  }}
                  primaryYAxis={{
                    lineStyle: { width: 0 },
                    labelStyle: {
                      color: "#000",
                      fontWeight: 400,
                      fontSize: 15,
                    },
                    minimum: 0,
                    interval: 5,
                    majorGridLines: { width: 0 },
                  }}
                  chartArea={{ border: { width: 0 } }}
                  tooltip={{ enable: true }}
                  palettes={[appsettingsVar().color]}
                >
                  <Inject
                    services={[
                      ColumnSeries,
                      Legend,
                      Tooltip,
                      Category,
                      DataLabel,
                    ]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={patientLevelDataGraph}
                      xName="x"
                      yName="y"
                      type="Column"
                      name="No of Patients recorded for Date"
                      marker={{
                        dataLabel: {
                          visible: true,
                          font: { fontWeight: "600", color: "#ffffff" },
                        },
                      }}
                    ></SeriesDirective>
                  </SeriesCollectionDirective>
                </ChartComponent>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const six = () => {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <span id="close" className="e-close-icon e-clear-icon" />
          <ScheduleInfo
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
          />
        </div>
      );
    };
    const seven = () => {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <span id="close" className="e-close-icon e-clear-icon" />
          <PreferenceFeedback
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
          />
        </div>
      );
    };

    const mediaQuery = "max-width: 1000px";

    return (
      <div
        id="defaultTarget"
        className="dashboardParent"
        style={{ marginTop: 20, textAlign: "center" }}
      >
        <DashboardLayoutComponent
          id="defaultLayout"
          columns={5}
          cellSpacing={cellSpacing}
          allowResizing={false}
          cellAspectRatio={100 / 115}
          mediaQuery={mediaQuery}
        >
          <PanelsDirective>
            <PanelDirective
              row={0}
              col={0}
              sizeX={1}
              sizeY={1}
              content={one}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={0}
              col={1}
              sizeX={3}
              sizeY={1}
              content={two}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={0}
              col={4}
              sizeX={1}
              sizeY={1}
              content={three}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={0}
              sizeX={1}
              sizeY={1}
              content={four}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={1}
              sizeX={2}
              sizeY={1}
              content={five}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={3}
              sizeX={1}
              sizeY={1}
              content={six}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={4}
              sizeX={1}
              sizeY={1}
              content={seven}
              cssClass={classes.panelStyle}
            ></PanelDirective>
          </PanelsDirective>
        </DashboardLayoutComponent>
        <div>
          <DashboardFooter />
        </div>
        <Dialog open={showcensus} fullWidth maxWidth="sm">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
              position: "relative",
            }}
          >
            <CensusDataForm
              closeDialog={toggleCensusform}
              selectedDate={new Date()}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </div>
    );
  }
};

export default ManagerDashboard;
