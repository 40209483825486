import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import SoftTimeOffForm from "../shiftBasedCalendar/SoftTimeOff";
import EditSoftTimeOff from "../shiftBasedCalendar/EditSoftTimeOff";
import OffRequestItem from "./OffRequestItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isSameDay } from "date-fns";
import { locationConfigVar, userVar } from "../../cache";


const SoftTimeOff = (props) => {
  const {
    softRequests,
    scheduleEnd,
    invalidDates,
    setToast,
    setShowToast,
    schedulePeriodWeeks,
    maxGreyout,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const [softRequest, setSoftRequest] = useState(false);
  const [editSoftRequest, setEditSoftRequest] = useState(false);
  const [softRequestIdToEdit, setSoftRequestIdToEdit] = useState("");
  const [dateToView, setDateToView] = useState(new Date());

  const user = userVar();

  const toggleSoftRequest = () => setSoftRequest(!softRequest);
  const toggleEditSoftRequestForm = () => setEditSoftRequest(!editSoftRequest);

  const softRequestsToView = () => {
    const filtered = softRequests.filter(
      (request) =>
        request.start > dateToView || isSameDay(request.start, dateToView)
    );
    filtered.length > 0 &&
      filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
    return filtered;
  };

  const closeSoftForm = () => {
    setSoftRequest(false);
    refetch();
  };

  const closeEditSoftForm = () => {
    setEditSoftRequest(false);
    refetch();
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid
            item
            container
            justifyContent="space-between"
            xs={12}
            style={{ marginTop: 8, marginBottom: 8 }}
          >
            <Grid item container xs={7} alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h5">
                  See requests from this date on:
                </Typography>
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-to-view"
                  value={dateToView}
                  onChange={setDateToView}
                />
              </Grid>
            </Grid>
            <Grid item>
              {user.isSchedulable && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleSoftRequest}
                >
                  Add Request
                </Button>
              )}
            </Grid>
          </Grid>
          {softRequestsToView().length > 0 ? (
            softRequestsToView().map((request) => (
              <OffRequestItem
                request={request}
                setIdToEdit={setSoftRequestIdToEdit}
                toggleEditForm={toggleEditSoftRequestForm}
                type="soft"
                key={request.eventId}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            ))
          ) : (
            <Typography variant="h5" style={{ marginTop: 16 }}>
              No soft requests after minimum date
            </Typography>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      <Dialog open={softRequest} fullWidth maxWidth="xs">
        <DialogContent style={{ padding: 30 }}>
          <SoftTimeOffForm
            closeDialog={closeSoftForm}
            scheduleEndDate={scheduleEnd}
            invalidDates={invalidDates}
            setToast={setToast}
            setShowToast={setShowToast}
            maxGreyout={maxGreyout}
            schedulePeriodWeeks={schedulePeriodWeeks}
            softRequests={softRequests}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={editSoftRequest} fullWidth maxWidth="xs">
        <DialogContent style={{ padding: 30 }}>
          {softRequestIdToEdit && (
            <EditSoftTimeOff
              closeDialog={closeEditSoftForm}
              scheduleEndDate={scheduleEnd}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              maxGreyout={maxGreyout}
              schedulePeriodWeeks={schedulePeriodWeeks}
              softRequests={softRequests}
              softRequestIdToEdit={softRequestIdToEdit}
              setSoftRequestIdToEdit={setSoftRequestIdToEdit}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SoftTimeOff;
