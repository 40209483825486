import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Box,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  makeStyles,
  Container,
  Snackbar,
  Divider,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Formik, FieldArray, getIn, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";
import {
  FIND_SKILL_TYPE,
  GET_CONSTRAINT_TYPE,
  DELETE_CONSTRAINT,
  EMPLOYEE_TYPES,
  SHIFT_DEFINITIONS,
  GET_OFFICE,
  DELETE_EMPLOYEE_TYPES,
  CREATE_EMPLOYEE_TYPE,
  BATCH_CREATE_CONSTRAINT,
  BATCH_UPDATE_CONSTRAINT,
  BATCH_DELETE_CONSTRAINT,
  PATIENT_LEVELS,
  ADD_PATIENT_LEVEL_RATIO,
  UPDATE_PATIENT_LEVEL_RATIO,
  DELETE_PATIENT_LEVEL,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import {
  UpdateHospitalConstraint,
  CreateHospitalConstraint,
  findConstraint,
  findofficeConstraint,
  findemployeeTypeOfficeConstraint,
  CreateHourlyRequirementsConstraints,
  getShifts,
  getskillsetforDaysofweek,
  saveskillsetforDaysofweek,
  getEmployeeTypeValues,
  handleDeleteSkillSetConstraints,
} from "../../helpers/officeHelpers";
import MuiAlert from "@material-ui/lab/Alert";
import SkillCoverReq from "./SkillCoverReq";
import { Prompt } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  inputWidth: {
    width: "100%",
  },
  mediumtextbox: {
    width: "50%",
  },
  smalltextbox: {
    width: "25%",
  },
  loading: {
    display: "block",
    margin: "auto",
  },
  textColor: {
    color: "#F15A29",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 80,
  },
  autocompleteInput: {
    width: "100%",
    padding: 0,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const ErrorMessage = ({ name }) => (
  <Field>
    {({ form }) => {
      const error = getIn(form.errors, name);
      return (
        <Typography variant="subtitle2" color="primary">
          {error ? error : null}
        </Typography>
      );
    }}
  </Field>
);

const PromptIfDirty = () => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes on this form."
    />
  );
};

const d = new Date();

function UpdateOfficeForm(props) {
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const allskilltypes = useQuery(FIND_SKILL_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const history = useHistory();
  const [showDialog, setshowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteempType, setDeleteEmpType] = useState(false);
  const [idtoDelete, setIdtoDelete] = useState("");
  const [duplicateRow, SetDuplicateRow] = useState(false);
  const [valuestodelete, setValuestoDelete] = useState({});
  const [otherform, setotherform] = useState(false);

  const { data, refetch, loading } = useQuery(GET_OFFICE, {
    variables: {
      id: parseInt(props.office),
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateConstraints] = useMutation(BATCH_CREATE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_CREATE_CONSTRAINT Mutation Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchUpdateConstraints] = useMutation(BATCH_UPDATE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_UPDATE_CONSTRAINT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchDeleteConstraints] = useMutation(BATCH_DELETE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_DELETE_CONSTRAINT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const shiftDefinitions = useQuery(SHIFT_DEFINITIONS, {
    variables: {
      officeId: parseInt(props.office),
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SHIFT_DEFINITIONS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allConstraintTypes = useQuery(GET_CONSTRAINT_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_CONSTRAINT_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteConstraint] = useMutation(DELETE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on DELETE_CONSTRAINT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteEmployeeType] = useMutation(DELETE_EMPLOYEE_TYPES, {
    onCompleted() {
      setshowDialog(false);
      setDialogMessage("");
      setDeleteEmpType(false);
      setIdtoDelete("");
      refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on DELETE_EMPLOYEE_TYPES Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);

  if (
    loading ||
    allskilltypes.loading ||
    allConstraintTypes.loading ||
    shiftDefinitions.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const getshifts = getShifts(
      !shiftDefinitions.loading && shiftDefinitions.data.shiftDefinitions
    );
    const alljobtypes =
      allskilltypes.data.skills.length > 0 &&
      allskilltypes.data.skills.filter(
        (skill) =>
          skill.variety === "JOB_TYPE" &&
          (skill.office === null || skill.office.id === props.office)
      );

    const alltrainings =
      allskilltypes.data.skills.length > 0 &&
      allskilltypes.data.skills.filter(
        (skill) =>
          skill.variety === "TRAINING" &&
          (skill.office === null || skill.office.id === props.office)
      );

    const staffTable = data.offices[0].constraintSet.filter(
      (i) => i.skill != null && i.skill.variety === "JOB_TYPE"
    );
    const trainingTable = data.offices[0].constraintSet.filter(
      (i) => i.skill != null && i.skill.variety === "TRAINING"
    );

    const handleUpdateOffice = (values) => {
      saveskillsetforDaysofweek(
        values.staffRequirements,
        staffTable,
        data.offices[0],
        allskilltypes.data.skills.filter(
          (skill) =>
            skill.variety === "JOB_TYPE" &&
            (skill.office === null || skill.office.id === props.office)
        ),
        batchUpdateConstraints,
        batchCreateConstraints,
        allConstraintTypes.data,
        getshifts.shifts,
        batchDeleteConstraints
      );
      saveskillsetforDaysofweek(
        values.trainingRequirements,
        trainingTable,
        data.offices[0],
        allskilltypes.data.skills.filter(
          (skill) =>
            skill.variety === "TRAINING" &&
            (skill.office === null || skill.office.id === props.office)
        ),
        batchUpdateConstraints,
        batchCreateConstraints,
        allConstraintTypes.data,
        getshifts.shifts,
        batchDeleteConstraints
      );
      refetch();
      setsnackBarMessage("Department successfully updated");
      setsnackbaropen(true);
    };

    return (
      <main variant="body">
        <Container style={{ margin: 0, padding: 0 }}>
          <ErrorSnackBar
            open={snackBarOpen}
            message={errorSnackBarMessage}
            close={() => setSnackBarOpen(false)}
          />
          <Snackbar
            open={snackbaropen}
            autoHideDuration={2000}
            onClose={() => {
              setsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => {
                setsnackbaropen(false);
                setsnackBarMessage("");
              }}
              severity="success"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={errorsnackbaropen}
            autoHideDuration={6000}
            onClose={() => {
              seterrorsnackbaropen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={() => seterrorsnackbaropen(false)}
              severity="error"
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <Formik
                enableReinitialize
                initialValues={{
                  id: data.offices[0].id,
                  departmentName: data.offices[0].name,
                  staffRequirements: getskillsetforDaysofweek(
                    staffTable,
                    getshifts.shifts,
                    alljobtypes
                  ),
                  trainingRequirements: getskillsetforDaysofweek(
                    trainingTable,
                    getshifts.shifts,
                    alltrainings
                  ),
                }}
                onSubmit={(values) => {
                  handleUpdateOffice(values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                  dirty,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {dirty && <PromptIfDirty />}
                    <Grid container direction="row" spacing={4}>
                      <Grid
                        item
                        container
                        xs={12}
                        className={classes.headerSpacing}
                      >
                        {data.offices[0] ? (
                          <Grid item xs={6} style={{ padding: 0 }}>
                            <Typography variant="h3">
                              Edit Department
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={6} style={{ padding: 0 }}>
                            <Typography variant="h3">Add Department</Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Box mb={1}>
                          <Typography variant="h5">Department Name</Typography>
                        </Box>
                        <TextField
                          id="departmentName"
                          name="departmentName"
                          variant="outlined"
                          value={
                            values.departmentName ? values.departmentName : ""
                          }
                          className={classes.inputWidth}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <Grid item xs={12}>
                        <SkillCoverReq
                          name="staffRequirements"
                          getDailyShifts={getshifts}
                          values={values.staffRequirements}
                          setFieldValue={setFieldValue}
                          ErrorMessage={ErrorMessage}
                          handleChange={handleChange}
                          classes={classes}
                          alljobtypes={alljobtypes}
                          deleteConstraint={deleteConstraint}
                          constraintSet={data.offices[0].constraintSet}
                          setshowDialog={setshowDialog}
                          setDialogMessage={setDialogMessage}
                          SetDuplicateRow={SetDuplicateRow}
                          setValuestoDelete={setValuestoDelete}
                          setotherform={setotherform}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SkillCoverReq
                          name="trainingRequirements"
                          getDailyShifts={getshifts}
                          values={values.trainingRequirements}
                          setFieldValue={setFieldValue}
                          ErrorMessage={ErrorMessage}
                          handleChange={handleChange}
                          classes={classes}
                          alljobtypes={alltrainings}
                          deleteConstraint={deleteConstraint}
                          constraintSet={data.offices[0].constraintSet}
                          setshowDialog={setshowDialog}
                          setDialogMessage={setDialogMessage}
                          SetDuplicateRow={SetDuplicateRow}
                          setValuestoDelete={setValuestoDelete}
                          setotherform={setotherform}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      xs={12}
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => history.push("/Admin")}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          className={classes.button}
                          disabled={!dirty}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
          <Dialog open={showDialog} fullWidth maxWidth="xs">
            <DialogContent
              style={{
                padding: 20,
                overflowX: "hidden",
                textAlign: "center",
              }}
            >
              <Typography>{dialogMessage}</Typography>
              <br />
              <br />
              {duplicateRow ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setshowDialog(false);
                      SetDuplicateRow(false);
                    }}
                  >
                    OK
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      deleteempType
                        ? deleteEmployeeType({
                            variables: { id: parseInt(idtoDelete) },
                          })
                        : handleDeleteSkillSetConstraints(
                            valuestodelete,
                            data.offices[0].constraintSet,
                            batchDeleteConstraints,
                            getshifts,
                            setshowDialog
                          )
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ marginLeft: "20px" }}
                    onClick={() => setshowDialog(false)}
                    color="primary"
                    variant="outlined"
                  >
                    No
                  </Button>
                </>
              )}
            </DialogContent>
          </Dialog>
        </Container>
      </main>
    );
  }
}

export default UpdateOfficeForm;
