import React, { useEffect } from "react";
import { Typography, Grid, Avatar, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { userVar } from "../../cache";
import { GET_ASSIGNMENT } from "../../api/gqlQueries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  highlightedEmployee: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  coloredAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  greyAvatar: {
    backgroundColor: "white",
    height: 27,
    width: 27,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  subtitleBlack: {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
}));

const EventParticipant = ({
  participant,
  showAvatar,
  selected,
  showJobTitle,
  singleLine,
  widthMatters,
  color,
  addOption,
  ref,
  setOpenSnackBar,
  setSnackBarMessage,
  notifyDevelopers,
  environment,
}) => {
  const classes = useStyles();

  const user = userVar();

  const [getAssignment, { data }] = useLazyQuery(GET_ASSIGNMENT, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ASSIGNMENT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (showJobTitle) {
      getAssignment({
        variables: {
          employeeId: parseInt(participant.id),
        },
      });
    }
  }, []);

  const firstInitial = participant.firstName ? participant.firstName[0] : "";
  const lastInitial = participant.lastName ? participant.lastName[0] : "";
  const initials = firstInitial + lastInitial;
  const fullName = participant.firstName + " " + participant.lastName;

  let matchingAssignment;
  let jobTitle;
  if (data) {
    matchingAssignment = data.assignments.find(
      (assignment) => assignment.office.id === user.office.id
    );
    jobTitle = data.employees[0].employeeskillSet.find(
      (skill) => skill.skill.variety === "JOB_TYPE"
    );
    jobTitle = jobTitle ? jobTitle.skill.name : "";
  }
  // let jobTitle = matchingAssignment ? matchingAssignment.role.name : '';
  // jobTitle = jobTitle.slice(0, 1).toUpperCase() + jobTitle.slice(1).toLowerCase();

  const schedulable = matchingAssignment
    ? matchingAssignment.schedulable
    : true;

  if (!addOption || (addOption && schedulable)) {
    if (showAvatar && showJobTitle && widthMatters && color) {
      return (
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="center"
          ref={ref}
        >
          <Grid item>
            <Avatar
              className={color ? classes.coloredAvatar : classes.greyAvatar}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid item style={{ width: 175 }}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitle}>{jobTitle}</Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && showJobTitle && !widthMatters) {
      return (
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="center"
          ref={ref}
        >
          <Grid item>
            <Avatar
              className={color ? classes.coloredAvatar : classes.greyAvatar}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitle}>{jobTitle}</Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && !showJobTitle && color) {
      return (
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="center"
          ref={ref}
        >
          <Grid item>
            <Avatar className={classes.coloredAvatar}>{initials}</Avatar>
          </Grid>
          <Grid item style={{ width: 175 }}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && showJobTitle && !color) {
      return (
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="center"
          ref={ref}
        >
          <Grid item xs={3}>
            <Avatar className={classes.greyAvatar}>{initials}</Avatar>
          </Grid>
          <Grid item style={{ width: 100 }} xs={9}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (!showAvatar && showJobTitle && singleLine) {
      return (
        <Grid item style={{ width: 255 }} ref={ref}>
          <Typography noWrap>
            {fullName} {jobTitle ? " - " + jobTitle : null}
          </Typography>
        </Grid>
      );
    } else if (!showAvatar && showJobTitle && !singleLine) {
      return (
        <Grid item style={{ width: 255 }} ref={ref}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
          <Typography className={classes.subtitle}>{jobTitle}</Typography>
        </Grid>
      );
    } else if (!widthMatters) {
      return (
        <Grid item style={{ width: 230 }} ref={ref}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
        </Grid>
      );
    } else if (widthMatters) {
      return (
        <Grid item style={{ width: 130 }} ref={ref}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
        </Grid>
      );
    } else {
      return <Typography>Loading...</Typography>;
    }
  } else {
    return null;
  }
};

EventParticipant.propTypes = {
  participant: PropTypes.object,
  showAvatar: PropTypes.bool,
  selected: PropTypes.bool,
  showJobTitle: PropTypes.bool,
  singleLine: PropTypes.bool,
  widthMatters: PropTypes.bool,
  color: PropTypes.bool,
  addOption: PropTypes.bool,
};

EventParticipant.defaultProps = {
  showAvatar: false,
  selected: false,
  showJobTitle: false,
  singleLine: false,
  widthMatters: true,
  color: true,
  addOption: false,
};

export default EventParticipant;
