import React, { useState } from "react";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { GET_ONLY_USER_SHIFTS } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import { userVar, appsettingsVar } from "../../cache";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { format, add } from "date-fns";

const useStyles = makeStyles(() => ({
  calendarStyles: {
    textAlign: "center",
    minWidth: 50,
    "& td.e-selected span.e-day": {
      backgroundColor: appsettingsVar().color + "!important",
    },
  },
}));

const MonthOverview = () => {
  const user = userVar();
  const classes = useStyles();
  const history = useHistory();
  const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";
  const twoweeksfromtoday =
    format(add(new Date(), { weeks: 2 }), "yyyy-MM-dd") + "T00:00:00";

  const userShifts = useQuery(GET_ONLY_USER_SHIFTS, {
    variables: {
      employeeId: parseInt(user.id),
      rangeStart: today,
      rangeEnd: twoweeksfromtoday,
    },
  });

  if (userShifts.loading) {
    return <CircularProgress color="primary" />;
  } else {
    const userShiftDates = userShifts.data.userShifts.map(
      (e) => new Date(e.shift.start)
    );

    return (
      <>
        <div>
          <Box m={2}>
            {" "}
            <Typography
              variant="h4"
              onClick={() => history.push("/Schedule")}
              style={{ cursor: "pointer" }}
            >
              MONTH
            </Typography>
          </Box>
        </div>
        <div className="control-pane">
          <div className="control-section">
            <div
              className="calendar-control-section"
              style={{ overflow: "auto", cursor: "pointer" }}
              onClick={() => history.push("/Schedule")}
            >
              <CalendarComponent
                start="Month"
                showTodayButton={false}
                isMultiSelection={true}
                values={userShiftDates}
                value={today}
                cssClass={classes.calendarStyles}
              />{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default MonthOverview;
