import { gql } from "@apollo/client";

/************************************************************************************/
/*Authentication*/
/************************************************************************************/
export const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String) {
    refreshToken(token: $token) {
      payload
      token
      refreshExpiresIn
    }
  }
`;

export const GET_USER_NAME = gql`
  query GetUserName {
    me {
      id
      firstName
      lastName
      email
      hasChangedDefaultPassword
      alwaysOpenGuide
      showPrefsExample
      skills {
        id
        name
        variety
      }
      assignmentSet {
        id
        startDate
        endDate
        role {
          id
          name
        }
        primary
        isManual
        office {
          id
          name
          timezone
          shiftTypePrefs
          weekdaysPrefs
          daysOffPrefs
          overtimePrefs
          softRequests
          shiftBasedDisplay
          procedureImports
          employeeImports
          availabilityImports
          skillImports
          trainingImports
          floatStatus
          allowCallIns
          maxGreyoutRequests
          scheduleDuration
          parent {
            id
          }
        }
        schedulable
        hiredshiftSet {
          id
          shiftDefinition {
            id
            description
            start
            end
            type {
              id
              type
            }
            weekday {
              id
              name
            }
          }
        }
        rotationLength
      }
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query GetNotificationCount {
    me {
      notificationSet {
        id
        title
        description
        read
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdateEmployee($id: ID!, $input: PatchEmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      employee {
        id
        hasChangedDefaultPassword
      }
    }
  }
`;

/************************************************************************************/
/*Profile*/
/************************************************************************************/
export const GET_PROFILE = gql`
  query GetProfile {
    me {
      id
      firstName
      lastName
      email
      personalEmail
      primaryPhone
      secondaryPhone
      notificationByText
      notificationByEmail
      notifyScheduleFinal
      notifyPreferencesDue
      notifyShiftSwitchRequest
      notifyScheduleAvailable
      employeeType {
        id
      }
      assignmentSet {
        startDate
        endDate
        primary
        office {
          name
          constraintSet {
            type {
              name
              definition
            }
            value
          }
          maxGreyoutRequests
        }
        role {
          name
        }
      }
      skills {
        name
        variety
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateEmployee($id: ID!, $input: PatchEmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      employee {
        firstName
        lastName
        personalEmail
        primaryPhone
        secondaryPhone
        hourlyWage
      }
    }
  }
`;

export const GET_NOTIFICATION_PREFS = gql`
  query GetProfile {
    me {
      id
      notificationByText
      notificationByEmail
      notifyScheduleFinal
      notifyPreferencesDue
      notifyShiftSwitchRequest
      notifyScheduleAvailable
    }
  }
`;

export const UPDATE_NOTIFICATION_PREFS = gql`
  mutation UpdateEmployee($id: ID!, $input: PatchEmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      employee {
        notificationByText
        notificationByEmail
        notifyScheduleFinal
        notifyPreferencesDue
        notifyShiftSwitchRequest
        notifyScheduleAvailable
      }
    }
  }
`;

/************************************************************************************/
/*Preferences*/
/************************************************************************************/

export const UPDATE_PREFS_SETTINGS = gql`
  mutation UpdateEmployee($id: ID!, $input: PatchEmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      employee {
        alwaysOpenGuide
        showPrefsExample
      }
    }
  }
`;

export const GET_PREFERENCES = gql`
  query GetPreferences($id: Int!, $office: Int!) {
    preferences: preferences(employeeId: $id) {
      id
      type {
        id
        type
      }
      value
    }
    preferenceIndicators(office: $office) {
      preferenceType
      desirability
    }
    preferenceTypes {
      id
      type
    }
  }
`;

export const UPDATE_PREFERENCE = gql`
  mutation UpdatePreference($id: ID!, $input: PatchPreferenceInput!) {
    updatePreference(id: $id, input: $input) {
      preference {
        id
        type {
          type
        }
        value
      }
    }
  }
`;

export const BATCH_CREATE_PREFERENCES = gql`
  mutation BatchCreatePreferences($input: [BatchCreatePreferenceInput]!) {
    batchCreatePreference(input: $input) {
      preferences {
        id
        value
        type {
          type
        }
      }
    }
  }
`;

export const BATCH_UPDATE_PREFERENCES = gql`
  mutation BatchUpdatePreferences($input: [BatchPatchPreferenceInput]!) {
    batchUpdatePreference(input: $input) {
      preferences {
        id
        value
        type {
          type
        }
      }
    }
  }
`;

export const CREATE_TEMP_PREFERENCES = gql`
  mutation CreateTempPreferences($input: CreateTempPreferenceInput!) {
    createTempPreference(input: $input) {
      tempPreference {
        id
        employee {
          id
          firstName
          lastName
        }
        preferences
      }
    }
  }
`;

export const UPDATE_TEMP_PREFERENCES = gql`
  mutation UpdateTempPreferences($id: ID!, $input: PatchTempPreferenceInput!) {
    updateTempPreference(id: $id, input: $input) {
      tempPreference {
        id
        employee {
          id
          firstName
          lastName
        }
        preferences
      }
    }
  }
`;

export const LOCATION_PREFERENCES = gql`
  query LocationPreferences($employee: ID) {
    locationPreferences(employee: $employee) {
      id
      value
      employee {
        id
        firstName
        lastName
      }
      office {
        id
        name
      }
    }
  }
`;

export const BATCH_CREATE_LOCATION_PREFERENCES = gql`
  mutation BatchCreateLocationPreferenceInput(
    $input: [BatchCreateLocationPreferenceInput]!
  ) {
    createLocationPreferences(input: $input) {
      locationPreferences {
        id
        value
        office {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_UPDATE_LOCATION_PREFERENCES = gql`
  mutation BatchUpdateLocationPreferenceInput(
    $input: [BatchPatchLocationPreferenceInput]!
  ) {
    updateLocationPreferences(input: $input) {
      locationPreferences {
        id
        value
        office {
          id
          name
        }
      }
    }
  }
`;

/************************************************************************************/
/*Calendar*/
/************************************************************************************/

export const GET_ONLY_USER_SHIFTS = gql`
  query GetShifts(
    $employeeId: Int
    $rangeStart: DateTime
    $rangeEnd: DateTime
  ) {
    userShifts: shiftAssignments(
      employeeId: $employeeId
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      id
      isArchived
      shift {
        id
        start
        end
        office {
          id
        }
        employeeSet {
          id
          firstName
          lastName
          skills {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_SHIFT_DEFINITIONS = gql`
  query ShiftDefinitions($officeId: Int) {
    shiftDefinitions(officeId: $officeId) {
      start
      end
      description
      type {
        type
      }
    }
  }
`;

export const GET_SHIFTS = gql`
  query GetShifts(
    $officeId: Int!
    $employeeId: Int!
    $rangeStart: DateTime
    $rangeEnd: DateTime
  ) {
    allShifts: shifts(
      officeId: $officeId
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      id
      start
      end
      office {
        id
        name
      }
      shiftassignmentSet {
        id
        employee {
          id
          firstName
          lastName
          offices {
            id
            name
            floatStatus
          }
          skills {
            id
            name
            variety
          }
        }
        isArchived
        taskSet {
          id
          procedure {
            id
            notes
          }
        }
      }
    }
    userShifts: shiftAssignments(employeeId: $employeeId) {
      id
      isArchived
      shift {
        id
        start
        office {
          id
        }
      }
    }
    timeOff: availability(officeId: $officeId) {
      id
      employee {
        id
        firstName
        lastName
      }
      approvedby {
        id
        firstName
        lastName
      }
      firstday
      lastday
      startTime
      endTime
      type {
        name
        id
      }
      comment
      workHours
      office {
        id
      }
      deniedText
      userCancelled
    }
    softRequests: offPreferences(employeeId: $employeeId) {
      id
      date
      value
    }
    officeInfo: offices(id: $officeId) {
      maxGreyoutRequests
      allowCallIns
      scheduleDuration
    }
  }
`;

export const GET_SHIFT_BY_ID = gql`
  query Shifts($id: Int) {
    shifts(id: $id) {
      id
      start
      end
      shiftassignmentSet {
        id
        employee {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_MORE_SHIFTS = gql`
  query GetShifts(
    $officeId: Int!
    $employeeId: Int!
    $rangeStart: DateTime
    $rangeEnd: DateTime
  ) {
    allShifts: shifts(
      officeId: $officeId
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      id
      start
      end
      shiftassignmentSet {
        id
        employee {
          id
          firstName
          lastName
          offices {
            name
            floatStatus
          }
          skills {
            id
            name
            variety
          }
        }
        isArchived
        taskSet {
          id
        }
      }
    }
    userShifts: shiftAssignments(employeeId: $employeeId) {
      isArchived
      shift {
        id
        start
        office {
          id
        }
      }
    }
  }
`;

export const GET_SHIFTS_WITH_RANGE_LENGTH = gql`
  query GetShifts($rangeStart: DateTime, $rangeEnd: DateTime) {
    shifts(rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
      id
      start
      end
      office {
        id
      }
      shiftassignmentSet {
        id
        employee {
          id
        }
      }
    }
  }
`;

export const GET_SHIFTS_WITH_RANGE = gql`
  query GetShifts($officeId: Int!, $rangeStart: DateTime, $rangeEnd: DateTime) {
    shifts(officeId: $officeId, rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
      id
      start
      end
      office {
        id
        name
        shiftdefinitionSet {
          id
          description
        }
      }
      shiftassignmentSet {
        employee {
          firstName
          lastName
          id
        }
      }
    }
  }
`;

export const FLOAT_MANAGER_GET_SHIFTS = gql`
  query FloatManagerGetShifts($officeId: Int, $employeeId: Int) {
    offices(id: $officeId) {
      id
      name
      maxGreyoutRequests
      allowCallIns
      employeeSet {
        id
        firstName
        lastName
        skills {
          id
          name
          variety
        }
        shiftassignmentSet {
          id
          isArchived
          shift {
            id
            office {
              id
              name
              floatStatus
              shiftdefinitionSet {
                id
                description
              }
            }
            shiftType {
              type
            }
            start
            end
          }
        }
      }
    }
    availability(officeId: $officeId) {
      id
      employee {
        id
        firstName
        lastName
      }
      approvedby {
        id
        firstName
        lastName
      }
      firstday
      lastday
      startTime
      endTime
      type {
        name
        id
      }
      comment
      workHours
      office {
        id
      }
      deniedText
      userCancelled
    }
    softRequests: offPreferences(employeeId: $employeeId) {
      id
      date
      value
    }
  }
`;

export const EFFICIENT_SQL_SHIFT_ASSIGNMENTS = gql`
  query SqlShiftAssignments(
    $office: ID!
    $rangeStart: DateTime
    $rangeEnd: DateTime
    $employeeId: Int
    $officeId: Int
    $startDate: Date
    $endDate: Date
  ) {
    sqlShiftAssignments2(
      office: $office
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      id
      start
      end
      officeName
      shiftTypeName
      description
      procedureId
      procedureName
      shiftassignmentSet {
        id
        employeeId
        firstName
        lastName
        employee
        skillSet {
          id
          name
          isActive
          skillId
        }
      }
    }
    userShifts: shiftAssignments(employeeId: $employeeId) {
      id
      isArchived
      shift {
        id
        start
        office {
          id
        }
      }
    }
    timeOff: availability(
      officeId: $officeId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      employee {
        id
        firstName
        lastName
      }
      approvedby {
        id
        firstName
        lastName
      }
      firstday
      lastday
      startTime
      endTime
      type {
        name
        id
      }
      comment
      workHours
      office {
        id
        name
      }
      deniedText
      userCancelled
    }
    softRequests: offPreferences(employeeId: $employeeId) {
      id
      date
      value
    }
    officeInfo: offices(id: $officeId) {
      maxGreyoutRequests
      allowCallIns
      scheduleDuration
    }
  }
`;

export const UPDATE_SLACK_ASSIGNMENT = gql`
  mutation updateSlackAssignment($id: ID!, $input: PatchSlackAssignmentInput!) {
    updateSlackAssignment(id: $id, input: $input) {
      slackAssignment {
        id
        isActive
        requirement {
          id
          skill {
            id
            name
          }
        }
        assignment {
          id
          shift {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_SKELETON_FOR_MANAGERS = gql`
  mutation CreateSkeletonForManagers($begin: Date, $office: ID!, $thru: Date) {
    createSkeletonForManagers(begin: $begin, office: $office, thru: $thru) {
      successful
    }
  }
`;

export const CREATE_SKELETON_FOR_ALL = gql`
  mutation CreateSkeletonForAll($begin: Date, $office: ID!, $thru: Date) {
    createSkeletonForAll(begin: $begin, office: $office, thru: $thru) {
      successful
    }
  }
`;

export const CREATE_MANUAL_SHIFT_ASSIGNMENTS = gql`
  mutation CreateManualShiftAssignments(
    $employee: ID!
    $recurrence: String
    $shift: ID!
    $skills: [ID]
  ) {
    createManualShiftAssignments(
      employee: $employee
      recurrence: $recurrence
      shift: $shift
      skills: $skills
    ) {
      assignments {
        id
        start
        end
        officeName
        description
        shiftTypeName
        shiftassignmentSet {
          id
          employeeId
          firstName
          lastName
          employee
          skillSet {
            id
            name
            isActive
            skillId
          }
        }
      }
    }
  }
`;

export const GET_ASSIGNMENT = gql`
  query getAssignment($employeeId: Int) {
    assignments(employeeId: $employeeId) {
      office {
        id
      }
      role {
        name
      }
      schedulable
    }
    employees(id: $employeeId) {
      employeeskillSet {
        id
        skill {
          id
          name
          variety
        }
      }
    }
  }
`;

export const GET_MISSIONS = gql`
  query getMissions($office: Int, $start: Date, $end: Date) {
    procedureRequirements(office: $office, startRange: $start, endRange: $end) {
      id
      name
      procedureType {
        name
        id
      }
      earliestDate
      latestDate
      duration
      additiveConstraint
      procedureemployeeconstraintSet {
        id
        value
        start
        end
        employeeCapability {
          id
          name
        }
      }
      procedure {
        id
        name
        start
        end
        notes
        taskSet {
          id
          shiftAssignment {
            id
            employee {
              id
              firstName
              lastName
              skills {
                id
                name
                variety
              }
            }
            isArchived
            shift {
              id
              start
              end
            }
          }
          start
          end
        }
        assetassignmentSet {
          id
          asset {
            name
          }
        }
      }
    }
  }
`;

export const GET_PROCEDURE_REQUIREMENT = gql`
  query getProcedureRequirement($id: Int) {
    procedureRequirements(id: $id) {
      id
      name
      earliestDate
      latestDate
      duration
      additiveConstraint
      procedureemployeeconstraintSet {
        employeeCapability {
          id
          name
        }
        id
        value
        start
        end
      }
      procedure {
        id
        name
        start
        end
        notes
      }
    }
  }
`;

export const DELETE_PROCEDURE_REQUIREMENT = gql`
  mutation DeleteProcedureRequirement($id: ID!) {
    deleteProcedureRequirement(id: $id) {
      deletedId
      found
    }
  }
`;

export const GET_OFFICE_TIMEZONE = gql`
  query OfficeTimezone($office: Int) {
    offices(id: $office) {
      id
      timezone
    }
  }
`;

export const GET_SLACK = gql`
  query GetSlack(
    $rangeStart: DateTime
    $rangeEnd: DateTime
    $office: Int
    $issuesOnly: Boolean
  ) {
    slacks: datetimeSlacks(
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      office: $office
      issuesOnly: $issuesOnly
    ) {
      id
      count
      slack
      start
      end
      schedulePeriod {
        id
        status
        office {
          id
          name
          floatStatus
        }
      }
      skill {
        id
        name
      }
    }
    acuitySlacks: datetimeAcuitySlacks(
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      office: $office
      issuesOnly: $issuesOnly
    ) {
      id
      assigned
      lower
      upper
      start
      end
    }
  }
`;

export const INTERVAL_SLACKS = gql`
  query Slacks(
    $rangeStart: DateTime
    $rangeEnd: DateTime
    $office: ID
    $issuesOnly: Boolean
  ) {
    intervalSlacks(
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      office: $office
      issuesOnly: $issuesOnly
    ) {
      id
      required
      slack
      numAssigned
      interval {
        id
        start
        end
        shifts {
          start
          end
          procedure {
            id
            procedureRequirement {
              id
              name
            }
          }
          office {
            name
          }
        }
      }
      skill {
        id
        name
      }
    }
  }
`;

export const GET_SLACK_FOR_STAFF_REQUIREMENT = gql`
  query GetSlack(
    $rangeStart: DateTime
    $rangeEnd: DateTime
    $office: Int
    $issuesOnly: Boolean
  ) {
    slacks: datetimeSlacks(
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      office: $office
      issuesOnly: $issuesOnly
    ) {
      id
      count
      slack
      start
      end
      schedulePeriod {
        id
        status
        office {
          id
          name
          floatStatus
        }
      }
      skill {
        id
        name
      }
    }
    acuitySlacks: datetimeAcuitySlacks(
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      office: $office
      issuesOnly: $issuesOnly
    ) {
      id
      assigned
      lower
      upper
      start
      end
    }
    shifts: shifts(rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
      id
      start
      end
      shiftassignmentSet {
        id
        employee {
          id
          firstName
          lastName
          offices {
            id
          }
          skills {
            id
            name
          }
        }
      }
      office {
        id
        name
      }
    }
  }
`;

export const BATCH_UPDATE_SLACKS = gql`
  mutation BatchUpdateSlacks($input: [BatchPatchDatetimeSlackInput]!) {
    batchUpdateDatetimeSlack(input: $input) {
      datetimeSlacks {
        id
        count
        slack
        skill {
          name
        }
      }
    }
  }
`;

export const GET_TWO_SHIFTS_BY_IDS = gql`
  query GetShifts($shiftOneId: Int, $shiftTwoId: Int) {
    firstShift: shifts(id: $shiftOneId) {
      id
      start
      end
      employeeSet {
        id
        firstName
        lastName
      }
    }
    secondShift: shifts(id: $shiftTwoId) {
      id
      start
      end
      employeeSet {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers($id: Int!) {
    offices(id: $id) {
      employeeSet {
        id
        firstName
        lastName
        skills {
          id
          name
        }
        shiftassignmentSet {
          id
        }
        assignmentSet {
          primary
        }
      }
    }
  }
`;

export const GET_ALL_USERS_AND_SHIFTS = gql`
  query GetAllUsersAndShifts($id: Int!) {
    employees: offices(id: $id) {
      employeeSet {
        id
        firstName
        lastName
        shifts {
          id
          start
          end
          office {
            id
          }
        }
        employeeskillSet {
          id
          skill {
            id
            name
            variety
          }
        }
      }
    }
  }
`;

export const CREATE_SOFT_REQUEST = gql`
  mutation CreateOffPreference($input: CreateOffPreferenceInput!) {
    createOffPreference(input: $input) {
      offPreference {
        id
        date
        value
      }
    }
  }
`;

export const EDIT_SOFT_REQUEST = gql`
  mutation UpdateOffPreference($id: ID!, $input: PatchOffPreferenceInput!) {
    updateOffPreference(id: $id, input: $input) {
      offPreference {
        id
        date
        value
      }
    }
  }
`;

export const DELETE_SOFT_REQUEST = gql`
  mutation DeleteOffPreference($id: ID!) {
    deleteOffPreference(id: $id) {
      found
      deletedId
    }
  }
`;

export const AVAILABILITY_TYPES = gql`
  query availabilityTypes {
    availabilityTypes {
      id
      name
    }
  }
`;

export const CREATE_EMPLOYEE_AVAILABILITY = gql`
  mutation CreateEmployeeAvailability(
    $employee: ID!
    $firstday: Date!
    $lastday: Date!
    $type: ID!
    $workHours: Int!
    $office: ID!
    $comment: String
    $startTime: Time
    $endTime: Time
  ) {
    createTimeOffRequestWithNotifications(
      employee: $employee
      firstday: $firstday
      lastday: $lastday
      type: $type
      workHours: $workHours
      office: $office
      comment: $comment
      startTime: $startTime
      endTime: $endTime
    ) {
      timeOffRequest {
        id
        firstday
        lastday
        startTime
        endTime
        employee {
          id
          firstName
          lastName
        }
        approvedby {
          id
          firstName
          lastName
        }
        type {
          name
          id
        }
        office {
          id
        }
        workHours
        comment
        deniedText
        userCancelled
      }
    }
  }
`;

export const MANAGER_CREATE_EMPLOYEE_AVAILABILITY = gql`
  mutation ManagerCreateEmployeeAvailability(
    $employee: ID!
    $firstday: Date!
    $lastday: Date!
    $type: ID!
    $workHours: Int!
    $office: ID!
    $comment: String
    $startTime: Time
    $endTime: Time
  ) {
    managerInitiateTimeOff(
      employee: $employee
      firstday: $firstday
      lastday: $lastday
      type: $type
      workHours: $workHours
      office: $office
      comment: $comment
      startTime: $startTime
      endTime: $endTime
    ) {
      timeOffRequest {
        id
        firstday
        lastday
        startTime
        endTime
        employee {
          id
          firstName
          lastName
        }
        approvedby {
          id
          firstName
          lastName
        }
        type {
          name
          id
        }
        office {
          id
        }
        workHours
        comment
        deniedText
        userCancelled
      }
    }
  }
`;

export const BATCH_MANAGER_CREATE_EMPLOYEE_AVAILABILITY = gql`
  mutation BatchManagerInitiateTimeOff($input: [MITOInput]) {
    batchManagerInitiateTimeOff(input: $input) {
      timeOffRequests {
        id
        firstday
        lastday
        startTime
        endTime
        employee {
          id
          firstName
          lastName
        }
        approvedby {
          id
          firstName
          lastName
        }
        type {
          name
          id
        }
        office {
          id
        }
        workHours
        comment
        deniedText
        userCancelled
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_AVAILABILITY = gql`
  mutation UpdateEmployeeAvailability(
    $id: ID!
    $input: PatchEmployeeAvailabilityInput!
  ) {
    updateEmployeeAvailability(id: $id, input: $input) {
      employeeAvailability {
        id
        approvedby {
          id
          firstName
          lastName
        }
        type {
          id
          name
        }
        firstday
        lastday
        startTime
        endTime
        workHours
        deniedText
        userCancelled
        comment
      }
    }
  }
`;

export const BATCH_CREATE_EMPLOYEE_AVAILABILITY = gql`
  mutation BatchCreateEmployeeAvailability(
    $input: [BatchCreateEmployeeAvailabilityInput]!
  ) {
    batchCreateEmployeeAvailability(input: $input) {
      employeeAvailabilitys {
        id
        firstday
        lastday
        startTime
        endTime
        employee {
          id
          firstName
          lastName
        }
        approvedby {
          id
        }
        type {
          name
          id
        }
        office {
          id
        }
        workHours
        comment
        userCancelled
      }
    }
  }
`;

export const MANAGER_APPROVE_EMPLOYEE_AVAILABILITY = gql`
  mutation ApproveTimeOffRequestWithNotifications(
    $id: ID!
    $approvingManager: ID!
  ) {
    approveTimeOffRequestWithNotifications(
      id: $id
      approvingManager: $approvingManager
    ) {
      timeOffRequest {
        id
        approvedby {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const MANAGER_DENY_EMPLOYEE_AVAILABILITY = gql`
  mutation DenyTimeOffRequestWithNotifications(
    $id: ID!
    $denyingManager: ID!
    $deniedText: String!
  ) {
    denyTimeOffRequestWithNotifications(
      id: $id
      denyingManager: $denyingManager
      deniedText: $deniedText
    ) {
      timeOffRequest {
        id
        deniedText
      }
    }
  }
`;

export const MANAGER_ADD_EMPLOYEE_TO_SHIFT = gql`
  mutation AddEmployee(
    $employeeId: ID!
    $managerId: ID!
    $shiftId: ID!
    $note: String
  ) {
    managerCreateShiftAssignment(
      employeeId: $employeeId
      managerId: $managerId
      shiftId: $shiftId
      note: $note
    ) {
      shiftAssignment {
        id
        isArchived
        employee {
          id
          firstName
          lastName
        }
        shift {
          id
          start
          end
        }
      }
    }
  }
`;

export const MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT = gql`
  mutation RemoveEmployee(
    $managerId: ID!
    $shiftAssignmentId: ID!
    $note: String
  ) {
    managerCallOff(
      managerId: $managerId
      shiftAssignmentId: $shiftAssignmentId
      note: $note
    ) {
      shiftAssignment {
        id
        isArchived
      }
    }
  }
`;

export const ADD_AND_DROP_OPTIONS_FOR_SHIFT = gql`
  query AddAndDropOptions($shifts: [Int]) {
    rescheduleAdds(shifts: $shifts) {
      id
      optionType
      userGenerated
      status
      rescheduleindividualSet {
        id
        cost
        benefit
        employeeResponse
        employee {
          id
          firstName
          lastName
        }
      }
    }
    rescheduleDrops(shifts: $shifts) {
      id
      optionType
      userGenerated
      status
      rescheduleindividualSet {
        id
        cost
        benefit
        employeeResponse
        employee {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADD_OPTIONS_FOR_SHIFT = gql`
  query AddAndDropOptions($shifts: [Int]) {
    rescheduleAdds(shifts: $shifts) {
      id
      optionType
      userGenerated
      status
      rescheduleindividualSet {
        id
        cost
        benefit
        employeeResponse
        employee {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const EMPLOYEE_CALL_IN = gql`
  mutation EmployeeCallIN($id: ID!, $note: String) {
    employeeCallIn(shiftAssignmentId: $id, note: $note) {
      shiftAssignment {
        id
        isArchived
      }
    }
  }
`;

export const SHIFT_ASSIGNMENT = gql`
  query shiftAssignment($id: Int) {
    shiftAssignments(id: $id) {
      id
      isArchived
      employee {
        id
      }
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        id
        start
        end
        procedure {
          id
          start
          end
        }
        shiftAssignment {
          employee {
            id
            firstName
            lastName
          }
          shift {
            id
            start
            end
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_AVAILABILITY = gql`
  query Availability($employeeId: Int, $startDate: Date) {
    availability(employeeId: $employeeId, startDate: $startDate) {
      id
      firstday
      lastday
      type {
        id
        name
      }
      workHours
    }
  }
`;

/************************************************************************************/
/*Shift Switch*/
/************************************************************************************/

// export const GET_SHIFT_SWITCH_REQUESTS = gql`
//   query GetShiftSwitchRequests($officeId: Int) {
//     unapprovedShiftSwitches(office: $officeId) {
//       id
//       requestorShift {
//         id
//         employee {
//           id
//           firstName
//           lastName
//         }
//         shift {
//           start
//           id
//           office {
//             id
//           }
//         }
//       }
//       candidate1 {
//         id
//         employee {
//           id
//           firstName
//           lastName
//         }
//         shift {
//           start
//           id
//         }
//       }
//       candidate2 {
//         id
//         employee {
//           id
//           firstName
//           lastName
//         }
//         shift {
//           start
//           id
//         }
//       }
//       candidate3 {
//         id
//         employee {
//           id
//           firstName
//           lastName
//         }
//         shift {
//           start
//           id
//         }
//       }
//       candidate4 {
//         id
//         employee {
//           id
//           firstName
//           lastName
//         }
//         shift {
//           start
//           id
//         }
//       }
//       candidate1Denied
//       candidate2Denied
//       candidate3Denied
//       candidate4Denied
//       acceptor {
//         id
//         employee {
//           id
//           firstName
//           lastName
//         }
//         shift {
//           start
//           id
//         }
//       }
//       approver {
//         id
//         firstName
//         lastName
//       }
//       deniedText
//       userCancelled
//     }
//   }
// `;

export const GET_SHIFT_SWITCH_BUFFER_DAYS = gql`
  query GetShiftSwitchBufferDays($officeId: Int!) {
    offices(id: $officeId) {
      shiftSwitchBufferDays
    }
  }
`;

export const GET_SHIFT_ASSIGNMENT = gql`
  query ShiftAssignments($employeeId: Int, $shiftId: Int) {
    shiftAssignments(employeeId: $employeeId, shiftId: $shiftId) {
      id
      isArchived
      employee {
        id
      }
      shift {
        id
      }
    }
  }
`;

export const GET_SHIFT_ASSIGNMENTS_BY_SHIFT = gql`
  query ShiftAssignments($shiftId: Int) {
    shiftAssignments(shiftId: $shiftId) {
      id
      isArchived
      employee {
        id
        firstName
        lastName
      }
      shift {
        start
      }
    }
  }
`;

export const ADD_AND_REMOVE_EMPLOYEES = gql`
  mutation AddRemoveEmployee(
    $employeeOneAdd: ID!
    $employeeTwoAdd: ID!
    $shiftOneId: ID!
    $shiftTwoId: ID!
    $employeeOneShiftAssignmentRemove: ID!
    $employeeTwoShiftAssignmentRemove: ID!
    $managerId: ID!
    $note: String
  ) {
    createShiftOne: managerCreateShiftAssignment(
      employeeId: $employeeOneAdd
      managerId: $managerId
      shiftId: $shiftOneId
      note: $note
    ) {
      shiftAssignment {
        id
        isArchived
        employee {
          id
          firstName
          lastName
        }
        shift {
          start
          id
        }
      }
    }
    createShiftTwo: managerCreateShiftAssignment(
      employeeId: $employeeTwoAdd
      managerId: $managerId
      shiftId: $shiftTwoId
      note: $note
    ) {
      shiftAssignment {
        id
        isArchived
        employee {
          id
          firstName
          lastName
        }
        shift {
          start
          id
        }
      }
    }
    removeShiftOne: managerCallOff(
      managerId: $managerId
      shiftAssignmentId: $employeeOneShiftAssignmentRemove
      note: $note
    ) {
      shiftAssignment {
        id
        isArchived
        employee {
          id
        }
      }
    }
    removeShiftTwo: managerCallOff(
      managerId: $managerId
      shiftAssignmentId: $employeeTwoShiftAssignmentRemove
      note: $note
    ) {
      shiftAssignment {
        id
        isArchived
        employee {
          id
        }
      }
    }
  }
`;

export const EMPLOYEE_ACCEPT_SHIFT_SWITCH = gql`
  mutation AcceptShiftSwitch($id: ID!, $candidateNumber: Int!) {
    candidateAcceptShiftSwitchWithNotifications(
      shiftSwitchId: $id
      acceptor: $candidateNumber
    ) {
      shiftSwitchRequest {
        id
        acceptor {
          id
        }
      }
    }
  }
`;

// export const MANAGER_APPROVE_SHIFT_SWITCH = gql`
//   mutation ApproveShiftSwitch($id: ID!, $approver: Int!) {
//     managerApproveShiftSwitchWithNotifications(
//       shiftSwitchId: $id
//       approver: $approver
//     ) {
//       shiftSwitchRequest {
//         id
//         approver {
//           id
//         }
//       }
//     }
//   }
// `;

// export const EMPLOYEE_DENY_SHIFT_SWITCH = gql`
//   mutation CandidateDenyShiftSwitch($id: ID!, $candidateNumber: Int!) {
//     candidateRejectShiftSwitchWithNotifications(
//       shiftSwitchId: $id
//       candidateNumber: $candidateNumber
//     ) {
//       shiftSwitchRequest {
//         id
//         candidate1Denied
//         candidate2Denied
//         candidate3Denied
//         candidate4Denied
//       }
//     }
//   }
// `;

// export const MANAGER_DENY_SHIFT_SWITCH = gql`
//   mutation AcceptShiftSwitch($id: ID!, $denier: ID!, $deniedText: String!) {
//     managerDenyShiftSwitchWithNotifications(
//       shiftSwitchId: $id
//       denier: $denier
//       deniedText: $deniedText
//     ) {
//       shiftSwitchRequest {
//         id
//         deniedText
//       }
//     }
//   }
// `;

export const RESCHEDULE_SWAP_OPTIONS = gql`
  query RescheduleSwapOption($shiftAssignments: [Int]) {
    rescheduleSwaps(shiftAssignments: $shiftAssignments) {
      id
      optionType
      userGenerated
      status
      rescheduleindividualSet {
        id
        cost
        benefit
        employeeResponse
        employee {
          id
          firstName
          lastName
          skills {
            id
            name
            variety
          }
        }
        rescheduleactionSet {
          id
          actionType
          shiftAssignment
          shift {
            id
            start
            end
          }
        }
      }
    }
  }
`;

export const FORCE_OPTIONS_FOR_EMPLOYEE = gql`
  mutation ForceOptions($employee: ID!, $office: ID!, $shifts: [ID]!) {
    forceOption(employee: $employee, office: $office, shifts: $shifts) {
      triggered
    }
  }
`;

export const CREATE_RESCHEDULE_OPTION = gql`
  mutation createRescheduleOption(
    $individuals: [RescheduleIndividualInput]!
    $optionType: OptionTypeEnum!
    $status: String
  ) {
    createRescheduleOption(
      individuals: $individuals
      optionType: $optionType
      status: $status
    ) {
      option {
        id
        userGenerated
        status
        rescheduleindividualSet {
          employee {
            firstName
            lastName
          }
          employeeResponse
          rescheduleactionSet {
            shift {
              start
              end
            }
            shiftAssignment
          }
        }
      }
    }
  }
`;

export const BATCH_CREATE_RESCHEDULE_OPTION = gql`
  mutation BatchCreateRescheduleOption(
    $options: [BatchCreateRescheduleInput]!
  ) {
    batchCreateRescheduleOption(options: $options) {
      options {
        id
        userGenerated
        status
        rescheduleindividualSet {
          employee {
            firstName
            lastName
          }
          employeeResponse
          rescheduleactionSet {
            shift {
              start
              end
            }
            shiftAssignment
          }
        }
      }
    }
  }
`;

export const EXECUTE_OPTION = gql`
  mutation ExecuteOption($option: Int!) {
    executeOption(option: $option) {
      option {
        id
        status
      }
    }
  }
`;

/************************************************************************************/
/*Notifications*/
/************************************************************************************/

export const GET_ALL_NOTIFICATIONS = gql`
  query GetNotifications($employeeId: Int) {
    notifications(employeeId: $employeeId) {
      id
      title
      html
      description
      url
      read
      timestamp
      depreciatedText
      type
      employee {
        id
      }
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: ID!, $input: PatchNotificationInput!) {
    updateNotification(id: $id, input: $input) {
      notification {
        id
        read
        userDeleted
      }
    }
  }
`;

export const BATCH_UPDATE_NOTIFICATION = gql`
  mutation BatchUpdateNotification($input: [BatchPatchNotificationInput]!) {
    batchUpdateNotification(input: $input) {
      notifications {
        id
        read
        depreciatedText
        userDeleted
      }
    }
  }
`;

export const GET_RESCHEDULE_OPTION = gql`
  query GetRescheduleOption($id: Int) {
    rescheduleOptions(id: $id) {
      id
      optionType
      userGenerated
      status
      rescheduleindividualSet {
        createdAt
        id
        cost
        benefit
        employee {
          id
          firstName
          lastName
        }
        rescheduleactionSet {
          id
          actionType
          shift {
            id
            start
            end
          }
        }
      }
    }
  }
`;

/************************************************************************************/
/*Office Settings*/
/************************************************************************************/

export const FILE_UPLOAD = gql`
  mutation ($id: ID!, $file: Upload!) {
    updateLocation(id: $id, input: { logo: $file }) {
      location {
        id
        logo
      }
    }
  }
`;

export const LOCATION = gql`
  query Location {
    locations {
      id
      name
      address1
      address2
      address3
      hexcolor
      logo
      analyticsTab
      integrationsTab
      missionDetails
      shiftBasedDisplay
      shiftBasedScheduling
      officeSet {
        id
        name
        isManual
        parent {
          id
        }
      }
    }
  }
`;

export const PUBLICLOCATION = gql`
  query LocationPublicSettings {
    locationPublicSettings {
      id
      hexcolor
      logo
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: ID!, $input: PatchLocationInput!) {
    updateLocation(id: $id, input: $input) {
      location {
        id
        hexcolor
        name
        address1
        address2
        address3
        hexcolor
        logo
      }
    }
  }
`;

export const ALL_OFFICES = gql`
  query Offices($receivesFloats: Boolean) {
    offices(receivesFloats: $receivesFloats) {
      id
      name
      floatStatus
      parent {
        id
      }
      assignmentSet {
        id
      }
    }
  }
`;

export const ALL_OFFICES_NAMES = gql`
  query Offices($receivesFloats: Boolean) {
    offices(receivesFloats: $receivesFloats) {
      id
      name
      floatStatus
      parent {
        id
      }
    }
  }
`;

export const GET_ALL_CHILD_OFFICES_FOR_MANAGE_TEAM = gql`
  query getChildren($parent: ID!) {
    getChildren(parent: $parent) {
      id
      name
      employeeSet {
        id
        firstName
        lastName
        email
        primaryPhone
        personalEmail
        hourlyWage
        employeeType {
          id
          name
        }
        employeeskillSet {
          id
          skill {
            id
            name
            variety
          }
          expiration
        }
        offices {
          id
          name
        }
        assignmentSet {
          id
          primary
          startDate
          schedulable
          rotationLength
          endDate
          isManual
          role {
            id
            name
          }
          office {
            id
            name
          }
          hiredshiftSet {
            id
            shiftDefinition {
              id
              description
              start
              end
              type {
                type
              }
              weekday {
                name
              }
            }
          }
        }
        skills {
          id
          variety
          name
        }
      }
    }
  }
`;

export const GET_ALL_CHILD_OFFICES = gql`
  query getChildren($parent: ID!) {
    getChildren(parent: $parent) {
      id
      name
      assignmentSet {
        id
      }
      employeeSet {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_ALL_CHILD_OFFICES_NAMES = gql`
  query getChildren($parent: ID!) {
    getChildren(parent: $parent) {
      id
      name
    }
  }
`;

export const DELETE_OFFICE = gql`
  mutation DeleteOffice($id: ID!) {
    deleteOffice(id: $id) {
      deletedId
    }
  }
`;

export const GET_OFFICE = gql`
  query Offices($id: Int) {
    offices(id: $id) {
      id
      name
      maxGreyoutRequests
      numberDaysBeforePreferencesDue
      reviewWindowDays
      shiftSwitchBufferDays
      schedulePublishedBufferWeeks
      scheduleDuration
      timezone
      allowCallIns
      samlLoginOnly
      shiftTypePrefs
      weekdaysPrefs
      daysOffPrefs
      overtimePrefs
      softRequests
      procedureImports
      employeeImports
      availabilityImports
      skillImports
      trainingImports
      shiftBasedDisplay
      multipleEventsPerDay
      floatStatus
      payPeriodWeeks
      skillSet {
        id
        name
        variety
      }
      shiftdefinitionSet {
        id
        type {
          type
        }
        description
        start
        end
      }
      constraintSet {
        id
        starttime
        endtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        effectstart
        effectend
        employeetype {
          id
          name
        }
        type {
          id
          name
        }
        value
        skill {
          id
          name
          variety
        }
      }
    }
  }
`;

export const UPDATE_OFFICE = gql`
  mutation UpdateOffice($id: ID!, $input: PatchOfficeInput!) {
    updateOffice(id: $id, input: $input) {
      office {
        id
        name
        maxGreyoutRequests
        numberDaysBeforePreferencesDue
        reviewWindowDays
        shiftSwitchBufferDays
        schedulePublishedBufferWeeks
        allowCallIns
        timezone
        samlLoginOnly
        shiftTypePrefs
        weekdaysPrefs
        daysOffPrefs
        overtimePrefs
        softRequests
        procedureImports
        employeeImports
        availabilityImports
        skillImports
        trainingImports
        shiftBasedDisplay
        multipleEventsPerDay
        floatStatus
        payPeriodWeeks
      }
    }
  }
`;

export const ADD_OFFICE = gql`
  mutation CreateOffice($input: CreateOfficeInput!) {
    createOffice(input: $input) {
      office {
        id
        name
      }
    }
  }
`;

export const ADD_LOCATION_SKILLS = gql`
  mutation CreateSkill($input: CreateSkillInput!) {
    createSkill(input: $input) {
      skill {
        id
        name
        variety
      }
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation DeleteSkill($id: ID!) {
    deleteSkill(id: $id) {
      deletedId
    }
  }
`;

export const CREATE_CONSTRAINT = gql`
  mutation CreateConstraint($input: CreateConstraintInput!) {
    createConstraint(input: $input) {
      constraint {
        type {
          id
          name
        }
        effectstart
        starttime
        endtime
        value
        office {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_CREATE_CONSTRAINT = gql`
  mutation BatchCreateConstraint($input: [BatchCreateConstraintInput]!) {
    batchCreateConstraint(input: $input) {
      constraints {
        id
        type {
          id
          name
        }
        effectstart
        starttime
        endtime
        value
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        office {
          id
          name
        }
        skill {
          id
          name
          variety
        }
      }
    }
  }
`;

export const BATCH_UPDATE_CONSTRAINT = gql`
  mutation BatchUpdateConstraint($input: [BatchPatchConstraintInput]!) {
    batchUpdateConstraint(input: $input) {
      constraints {
        id
        type {
          id
          name
        }
        effectstart
        starttime
        endtime
        value
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        office {
          id
          name
        }
        skill {
          id
          name
          variety
        }
      }
    }
  }
`;

export const CREATE_SKILLSET_CONSTRAINT = gql`
  mutation CreateConstraint($input: CreateConstraintInput!) {
    createConstraint(input: $input) {
      constraint {
        id
        type {
          id
          name
        }
        effectstart
        starttime
        endtime
        value
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        office {
          id
          name
        }
        skill {
          id
          name
          variety
        }
      }
    }
  }
`;

export const UPDATE_CONSTRAINT = gql`
  mutation UpdateConstraint($id: ID!, $input: PatchConstraintInput!) {
    updateConstraint(id: $id, input: $input) {
      constraint {
        type {
          id
          name
        }
        effectstart
        starttime
        endtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        value
        office {
          id
          name
        }
      }
    }
  }
`;

export const GET_CONSTRAINT_TYPE = gql`
  query ConstraintDefinitions {
    constraintDefinitions {
      id
      name
      definition
    }
  }
`;

export const FIND_OFFICE_CONSTRAINT = gql`
  query FindOfficeConstraint($officeId: Int) {
    constraints(officeId: $officeId) {
      id
      starttime
      endtime
      office {
        name
      }
      type {
        id
        name
      }
      skill {
        id
        name
        variety
      }
    }
  }
`;

export const DELETE_CONSTRAINT = gql`
  mutation DeleteConstraint($id: ID!) {
    deleteConstraint(id: $id) {
      deletedId
    }
  }
`;

export const BATCH_DELETE_CONSTRAINT = gql`
  mutation BatchDeleteConstraint($ids: [ID]!) {
    batchDeleteConstraint(ids: $ids) {
      deletedIds
    }
  }
`;

export const BATCH_DELETE_CONSTRAINTS = gql`
  mutation BatchDeleteConstraints($ids: [ID]!) {
    batchDeleteConstraint(ids: $ids) {
      deletionCount
      deletedIds
    }
  }
`;

export const MODEL_TYPE = gql`
  query ModelType {
    preferenceModelTypes {
      id
      modelname
      modeltag
    }
  }
`;

export const SHIFT_DEFINITIONS = gql`
  query ShiftDefinitions($officeId: Int) {
    shiftDefinitions(officeId: $officeId) {
      id
      start
      end
      description
      office {
        id
      }
      type {
        id
        type
      }
      weekday {
        id
        name
      }
    }
  }
`;

export const PATIENT_LEVELS = gql`
  query PatientLevel($office: ID) {
    patientLevels(office: $office) {
      id
      level
      ratio
      office {
        id
      }
    }
  }
`;

export const MOST_RECENT_CENSUS = gql`
  query CensusTrend($office: ID!, $range: Int) {
    censusTrend(office: $office, range: $range) {
      id
      count
      createdAt
      patientLevel {
        id
        level
        ratio
      }
    }
  }
`;

export const RECORD_CENSUS_DATA = gql`
  mutation RecordCensus($input: [RecordCensusInput]) {
    recordCensus(input: $input) {
      patientCensuses {
        id
        patientLevel {
          id
        }
      }
    }
  }
`;

export const ADD_PATIENT_LEVEL_RATIO = gql`
  mutation CreatePatientLevels($input: [BatchCreatePatientLevelInput]!) {
    createPatientLevel(input: $input) {
      patientLevels {
        id
        level
        ratio
        office {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_LEVEL_RATIO = gql`
  mutation UpdatePatientLevels($input: [BatchPatchPatientLevelInput]!) {
    updatePatientLevel(input: $input) {
      patientLevels {
        id
        level
        ratio
        office {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PATIENT_LEVEL = gql`
  mutation DeletePatientLevels($ids: [ID]!) {
    deletePatientLevel(ids: $ids) {
      deletedIds
    }
  }
`;

//was shiftType2
export const SHIFT_DEFINITION_TYPE = gql`
  query ShiftType {
    shiftTypes {
      id
      type
    }
  }
`;

export const WEEKDAY = gql`
  query Weekday {
    weekdays {
      id
      name
    }
  }
`;

export const DELETE_SHIFT_DEFINITIONS = gql`
  mutation DeleteShiftDefinitionMutation($id: ID!) {
    deleteShiftDefinition(id: $id) {
      deletedId
    }
  }
`;

export const CREATE_SHIFT_DEFINITIONS = gql`
  mutation CreateShiftDefinition($input: CreateShiftDefinitionInput!) {
    createShiftDefinition(input: $input) {
      shiftDefinition {
        id
        start
        end
        office {
          id
        }
        type {
          id
        }
        weekday {
          id
        }
      }
    }
  }
`;

export const BATCH_CREATE_SHIFT_DEFINITIONS = gql`
  mutation BatchCreateShiftDefinition(
    $input: [BatchCreateShiftDefinitionInput]!
  ) {
    batchCreateShiftDefinition(input: $input) {
      shiftDefinitions {
        id
        start
        end
        office {
          id
        }
        type {
          id
        }
        weekday {
          id
        }
      }
    }
  }
`;

export const BATCH_UPDATE_SHIFT_DEFINITIONS = gql`
  mutation BatchUpdateShiftDefinition(
    $input: [BatchPatchShiftDefinitionInput]!
  ) {
    batchUpdateShiftDefinition(input: $input) {
      shiftDefinitions {
        id
        start
        end
        office {
          id
        }
        type {
          id
        }
        weekday {
          id
        }
      }
    }
  }
`;

export const BATCH_DELETE_SHIFT_DEFINITIONS = gql`
  mutation BatchDeleteShiftDefinitionMutation($ids: [ID]!) {
    batchDeleteShiftDefinition(ids: $ids) {
      deletedIds
    }
  }
`;

export const UPDATE_SHIFT_DEFINITIONS = gql`
  mutation UpdateShiftDefinition($id: ID!, $input: PatchShiftDefinitionInput!) {
    updateShiftDefinition(id: $id, input: $input) {
      shiftDefinition {
        id
        start
        end
        office {
          id
        }
        type {
          type
        }
        weekday {
          name
        }
      }
    }
  }
`;

export const PERMISSIONS = gql`
  query Permissions {
    permissions {
      id
      name
      description
    }
  }
`;

export const CREATE_ABILITIES = gql`
  mutation CreateAbilities($input: CreateAbilityInput!) {
    createAbility(input: $input) {
      ability {
        id
        role {
          id
          name
        }
        permission {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ABILITIES = gql`
  mutation DeleteAbilities($id: ID!) {
    deleteAbility(id: $id) {
      deletedId
    }
  }
`;

export const ABILITIES = gql`
  query Abilities {
    abilities {
      id
      role {
        id
        name
      }
      permission {
        id
        name
      }
    }
  }
`;

/************************************************************************************/
/*Manage Users*/
/************************************************************************************/

export const Get_Employees = gql`
  query Offices($id: Int) {
    offices(id: $id) {
      employeeSet {
        id
        firstName
        lastName
        email
        primaryPhone
        personalEmail
        hourlyWage
        employeeType {
          id
          name
        }
        employeeskillSet {
          id
          skill {
            id
            name
            variety
          }
          expiration
        }
        preferenceSet {
          id
          updatedAt
        }
        offices {
          id
          name
        }
        assignmentSet {
          id
          primary
          startDate
          schedulable
          rotationLength
          startDate
          endDate
          isManual
          role {
            id
            name
          }
          office {
            id
            name
          }
          hiredshiftSet {
            id
            shiftDefinition {
              id
              description
              start
              end
              type {
                type
              }
              weekday {
                name
              }
            }
          }
        }
        skills {
          id
          variety
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_NAMES = gql`
  query GetEmployeeNames($office: Int) {
    offices(id: $office) {
      employeeSet {
        id
        firstName
        lastName
        skills {
          id
          name
          variety
        }
        assignmentSet {
          id
          endDate
          office {
            id
            floatStatus
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_OF_CHILD_OFFICES = gql`
  query getChildren($parent: ID!) {
    getChildren(parent: $parent) {
      id
      name
      employeeSet {
        id
        firstName
        lastName
        skills {
          id
          name
          variety
        }
        assignmentSet {
          id
          endDate
          office {
            id
            floatStatus
          }
        }
      }
    }
  }
`;

export const GET_RELEVANT_SKILLS = gql`
  query RequiredSkills($office: ID!) {
    requiredSkills(office: $office) {
      skillId
      name
      variety
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($id: ID!, $input: PatchEmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      employee {
        id
        firstName
        lastName
        email
        personalEmail
        primaryPhone
        employeeType {
          id
        }
      }
    }
  }
`;
export const ADD_EMPLOYEE_SKILLS = gql`
  mutation CreateEmployeeSkill($input: CreateEmployeeSkillInput!) {
    createEmployeeSkill(input: $input) {
      employeeSkill {
        employee {
          id
        }
        skill {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_CREATE_SKILLS = gql`
  mutation BatchCreateSkils($input: [BatchCreateSkillInput]!) {
    batchCreateSkill(input: $input) {
      skills {
        id
        name
        variety
      }
    }
  }
`;

export const DELETE_EMPLOYEE_SKILLS = gql`
  mutation DeleteEmployeeSkills($id: ID!) {
    deleteEmployeeSkill(id: $id) {
      found
      deletedId
    }
  }
`;
export const UPDATE_EMPLOYEE_SKILLS = gql`
  mutation UpdateEmployeeSkills($id: ID!, $input: PatchEmployeeSkillInput!) {
    updateEmployeeSkill(id: $id, input: $input) {
      employeeSkill {
        id
        skill {
          name
          variety
        }
      }
    }
  }
`;

export const BATCH_CREATE_EMPLOYEE_SKILLS = gql`
  mutation batchCreateEmployeeSkills($input: [CreateEmployeeSkillInput]!) {
    batchCreateEmployeeSkill(input: $input) {
      employeeSkills {
        id
        employee {
          id
          firstName
          lastName
        }
        skill {
          id
          name
        }
        expiration
      }
    }
  }
`;

export const BATCH_DELETE_EMPLOYEE_SKILLS = gql`
  mutation BatchDeleteEmployeeSkills($ids: [ID]!) {
    batchDeleteEmployeeSkill(ids: $ids) {
      deletedIds
      deletionCount
    }
  }
`;

export const BATCH_UPDATE_EMPLOYEE_SKILLS = gql`
  mutation BatchUpdateEmployeeSkills($input: [UpdateEmployeeSkillInput]!) {
    batchUpdateEmployeeSkill(input: $input) {
      employeeSkills {
        id
        employee {
          id
          firstName
          lastName
        }
        skill {
          id
          name
        }
        expiration
      }
    }
  }
`;

export const PREFERENCE_TYPE = gql`
  query PreferenceType {
    preferenceTypes {
      id
      definition
      type
    }
  }
`;

export const UPDATE_EMPLOYEE_ASSIGNMENT = gql`
  mutation UpdateEmployeeAssignment($id: ID!, $input: PatchAssignmentInput!) {
    updateAssignment(id: $id, input: $input) {
      assignment {
        id
        role {
          id
          name
        }
        schedulable
        isManual
        startDate
        endDate
        office {
          id
          name
        }
        primary
        rotationLength
      }
    }
  }
`;
export const CREATE_EMPLOYEE_ASSIGNMENT = gql`
  mutation CreateEmployeeAssignment($input: CreateAssignmentInput!) {
    createAssignment(input: $input) {
      assignment {
        id
        employee {
          id
          email
        }
        schedulable
        isManual
        rotationLength
        office {
          id
        }
        primary
        role {
          id
        }
      }
    }
  }
`;

export const CREATE_HIRED_SHIFT_ASSIGNMENT = gql`
  mutation CreateHiredShiftAssignment($input: CreateHiredShiftInput!) {
    createHiredShift(input: $input) {
      hiredShift {
        id
        shiftDefinition {
          id
          type {
            type
          }
        }
      }
    }
  }
`;
export const BATCH_ADD_EMPLOYEE = gql`
  mutation BatchCreateEmployee($input: [BatchCreateEmployeeInput]!) {
    batchCreateEmployee(input: $input) {
      employees {
        id
        firstName
        lastName
        email
        primaryPhone
        hasChangedDefaultPassword
      }
    }
  }
`;

export const BATCH_CREATE_EMPLOYEE_ASSIGNMENT = gql`
  mutation BatchCreateEmployeeAssignment(
    $input: [BatchCreateAssignmentInput]!
  ) {
    batchCreateAssignment(input: $input) {
      assignments {
        id
        employee {
          id
          email
        }
        schedulable
        rotationLength
        office {
          id
        }
        primary
        role {
          id
        }
      }
    }
  }
`;

export const BATCH_UPDATE_EMPLOYEE_ASSIGNMENT = gql`
  mutation BatchCreateEmployeeAssignment($input: [BatchPatchAssignmentInput]!) {
    batchUpdateAssignment(input: $input) {
      assignments {
        id
        employee {
          id
          email
        }
        schedulable
        rotationLength
        office {
          id
        }
        primary
        role {
          id
        }
      }
    }
  }
`;

export const BATCH_CREATE_HIRED_SHIFTS = gql`
  mutation createHiredShifts($input: [BatchCreateHiredShiftInput]!) {
    batchCreateHiredShift(input: $input) {
      hiredShifts {
        id
        assignment {
          id
          employee {
            firstName
            lastName
            email
          }
          office {
            name
          }
        }
        shiftDefinition {
          id
          description
          start
          end
          type {
            type
          }
          weekday {
            name
          }
        }
      }
    }
  }
`;

export const BATCH_UPDATE_HIRED_SHIFTS = gql`
  mutation updateHiredShifts($input: [BatchPatchHiredShiftInput]!) {
    batchUpdateHiredShift(input: $input) {
      hiredShifts {
        id
        assignment {
          id
          employee {
            firstName
            lastName
            email
          }
          office {
            name
          }
        }
        shiftDefinition {
          id
          description
          start
          end
          type {
            type
          }
          weekday {
            name
          }
        }
      }
    }
  }
`;

export const BATCH_DELETE_HIRED_SHIFTS = gql`
  mutation deleteHiredShifts($ids: [ID]!) {
    batchDeleteHiredShift(ids: $ids) {
      deletionCount
      deletedIds
      missedIds
    }
  }
`;

export const FIND_SKILL_TYPE = gql`
  query Skills($office: Int) {
    skills(officeId: $office) {
      id
      name
      variety
      office {
        id
        name
      }
    }
  }
`;

export const FIND_EMPLOYEE_BY_ID = gql`
  query Employee($id: Int) {
    employees(id: $id) {
      id
      firstName
      employeeskillSet {
        id
        skill {
          id
          variety
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_SKILL = gql`
  query getEmployeeSkills {
    employeeSkills {
      id
      employee {
        id
        firstName
        lastName
      }
      skill {
        id
        name
        variety
      }
    }
  }
`;

export const ROLES = gql`
  query {
    roles {
      id
      name
    }
  }
`;

export const EMPLOYEE_TYPES = gql`
  query {
    employeeTypes {
      id
      name
    }
  }
`;

export const CREATE_EMPLOYEE_TYPE = gql`
  mutation CreateEmployeeType($input: CreateEmployeeTypeInput!) {
    createEmployeeType(input: $input) {
      employeeType {
        id
        name
      }
    }
  }
`;

export const DELETE_EMPLOYEE_TYPES = gql`
  mutation ($id: ID!) {
    deleteEmployeeType(id: $id) {
      deletedId
    }
  }
`;

export const ALL_SHIFT_TYPES = gql`
  query AllShiftTypes($officeId: Int) {
    shiftDefinitions(officeId: $officeId) {
      id
      type {
        type
      }
      office {
        id
      }
      description
      start
      end
      weekday {
        name
      }
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      employee {
        id
        firstName
        lastName
        email
        primaryPhone
        hasChangedDefaultPassword
        hourlyWage
        employeeType {
          id
        }
      }
    }
  }
`;

export const Delete_Employee = gql`
  mutation DeleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      deletedId
      found
    }
  }
`;

export const Check_Email_Exists = gql`
  query emailExists($email: String) {
    emailExists(email: $email)
  }
`;

export const Reset_Password = gql`
  mutation resetPassword($email: String!, $newPassword: String!) {
    resetPassword(email: $email, newPassword: $newPassword) {
      success
    }
  }
`;

export const SCHEDULE_FITNESS = gql`
  query EmployeeFitness($schedulePeriod: Int, $office: Int) {
    employeeFitnesses(schedulePeriod: $schedulePeriod, office: $office) {
      id
      employee {
        id
        firstName
        lastName
        skills {
          id
          name
        }
      }
      score
      maxScore
      ratio
      schedulePeriod {
        id
        start
        end
      }
    }
  }
`;

/************************************************************************************/
/*Schedule Actions*/
/************************************************************************************/

export const GET_SCHEDULE_INFO = gql`
  query GetScheduleInfo($id: Int) {
    offices(id: $id) {
      id
      name
      numberDaysBeforePreferencesDue
      reviewWindowDays
      schedulePublishedBufferWeeks
      scheduleDuration
      schedulingStatus
      isManual
      parent {
        id
        name
      }
      timeOff {
        approvedby {
          id
        }
        deniedText
        userCancelled
        firstday
      }
    }
  }
`;

export const GET_SCHEDULE_PERIODS = gql`
  query GetSchedulePeriods($officeId: Int, $id: Int) {
    schedulePeriods(office: $officeId, id: $id) {
      id
      start
      end
      isUnderReview
      isPublished
      status
      updatedAt
      approver {
        id
      }
    }
    offices(id: $officeId) {
      schedulingStatus
      name
    }
  }
`;

export const RELEASE_DRAFT = gql`
  mutation OpenReviewWindow($schedulePeriodId: ID!) {
    openReviewWindowWithNotifications(schedulePeriod: $schedulePeriodId) {
      schedulePeriod {
        id
        isUnderReview
        isPublished
        approver {
          id
        }
      }
    }
  }
`;

export const PUBLISH_SCHEDULE = gql`
  mutation PublishSchedule($schedulePeriodId: ID!, $approver: ID!) {
    publishScheduleWithNotifications(
      schedulePeriod: $schedulePeriodId
      approver: $approver
    ) {
      schedulePeriod {
        id
        isUnderReview
        isPublished
        approver {
          id
        }
      }
    }
  }
`;

export const UPDATE_SCHEDULE_PERIOD = gql`
  mutation updateSchedulePeriod($id: ID!, $input: PatchSchedulePeriodInput!) {
    updateSchedulePeriod(id: $id, input: $input) {
      schedulePeriod {
        id
        status
      }
    }
  }
`;

export const OFFICES_WITH_CHILD_PARENT = gql`
  query Offices {
    offices {
      id
      name
      scheduleDuration
      scheduleperiodSet {
        id
        start
        end
        status
      }
      parent {
        id
        name
      }
    }
  }
`;

/************************************************************************************/
/*Algorithm Trigger Mutations*/
/***********************************************************************************/
export const RUN_SCHEDULE = gql`
  mutation RunSchedule($office: ID!, $startDate: Date, $dryRun: Boolean) {
    runSchedule(office: $office, startDate: $startDate, dryRun: $dryRun) {
      successful
    }
  }
`;

export const RUN_SCHEDULE_DRY_RUN = gql`
  mutation RunSchedule($office: ID!, $startDate: Date, $dryRun: Boolean) {
    runSchedule(office: $office, startDate: $startDate, dryRun: $dryRun) {
      successful
    }
  }
`;

export const RUN_FLOAT = gql`
  mutation RunFloat($office: ID!, $startDate: Date, $dryRun: Boolean) {
    runFloat(office: $office, startDate: $startDate, dryRun: $dryRun) {
      successful
    }
  }
`;

export const RUN_FLOAT_DRY_RUN = gql`
  mutation RunFloat($office: ID!, $startDate: Date, $dryRun: Boolean) {
    runFloat(office: $office, startDate: $startDate, dryRun: $dryRun) {
      successful
    }
  }
`;

/************************************************************************************/
/*Requests*/
/************************************************************************************/

export const GET_REQUESTS = gql`
  query GetRequests($officeId: Int) {
    me {
      softRequests: offpreferenceSet {
        id
        date
        value
      }
      timeOff: availabilityEmployee {
        id
        userCancelled
        comment
        approvedby {
          id
          firstName
          lastName
        }
        firstday
        lastday
        startTime
        endTime
        type {
          name
          id
        }
        office {
          id
        }
        workHours
        deniedText
        employee {
          firstName
          lastName
        }
        createdAt
      }
    }
    schedulePeriods(office: $officeId) {
      start
      end
      isUnderReview
      isPublished
    }
    offices(id: $officeId) {
      maxGreyoutRequests
      scheduleDuration
    }
  }
`;

export const GET_SHIFTS_FOR_SS_FORM = gql`
  query GetShifts($officeId: Int!, $employeeId: Int!, $rangeStart: DateTime) {
    allShifts: shifts(officeId: $officeId, rangeStart: $rangeStart) {
      id
      start
      end
      shiftassignmentSet {
        employee {
          id
          firstName
          lastName
          skills {
            id
            name
            variety
          }
        }
        isArchived
      }
    }
    userShifts: shiftAssignments(employeeId: $employeeId) {
      isArchived
      shift {
        id
        start
        office {
          id
        }
      }
    }
  }
`;

export const GET_INCOMING_TO_REQUESTS = gql`
  query GetIncomingTORequests($officeId: Int) {
    timeOff: availability(officeId: $officeId, approved: false) {
      id
      employee {
        id
        firstName
        lastName
      }
      approvedby {
        id
        firstName
        lastName
      }
      firstday
      lastday
      startTime
      endTime
      type {
        name
      }
      comment
      workHours
      office {
        id
      }
      deniedText
      userCancelled
      createdAt
    }
  }
`;

export const EMPLOYEE_GET_INCOMING_SS_REQUESTS = gql`
  query GetIncomingSSRequests($employee: Int) {
    shiftSwitches: rescheduleOptions(
      optionType: "SWAP"
      initiated: true
      employee: $employee
    ) {
      id
      status
      optionType
      userGenerated
      createdAt
      rescheduleindividualSet {
        id
        employeeResponse
        employee {
          id
          firstName
          lastName
        }
        cost
        benefit
        createdAt
        updatedAt
        rescheduleactionSet {
          shift {
            id
            start
            end
          }
          shiftAssignment
        }
      }
    }
  }
`;

export const MANAGER_GET_INCOMING_SS_REQUESTS = gql`
  query GetIncomingSSRequests($officeId: Int, $employee: Int) {
    requestedShiftSwitches: rescheduleOptions(
      optionType: "SWAP"
      initiated: true
      employee: $employee
    ) {
      id
      status
      optionType
      userGenerated
      rescheduleindividualSet {
        id
        employeeResponse
        employee {
          id
          firstName
          lastName
        }
        cost
        benefit
        createdAt
        updatedAt
        rescheduleactionSet {
          shift {
            id
            start
            end
          }
          shiftAssignment
        }
      }
    }
    acceptedShiftSwitches: rescheduleOptions(
      optionType: "SWAP"
      status: "ACCEPTED"
      office: $officeId
    ) {
      id
      status
      optionType
      userGenerated
      rescheduleindividualSet {
        id
        employeeResponse
        employee {
          id
          firstName
          lastName
        }
        cost
        benefit
        createdAt
        updatedAt
        rescheduleactionSet {
          shift {
            id
            start
            end
          }
          shiftAssignment
        }
      }
    }
  }
`;

export const ACCEPT_OPTION = gql`
  mutation AcceptOption($option: Int!, $acceptor: Int!) {
    acceptOption(option: $option, acceptor: $acceptor) {
      option {
        id
        status
      }
    }
  }
`;

export const REJECT_OPTION = gql`
  mutation RejectOption($option: Int!, $rejector: Int!) {
    rejectOption(option: $option, rejector: $rejector) {
      option {
        id
        status
      }
    }
  }
`;

export const APPROVE_OPTION = gql`
  mutation ApproveOption($option: Int!, $approver: Int!) {
    approveOption(option: $option, approver: $approver) {
      option {
        id
        status
      }
    }
  }
`;

export const DENY_OPTION = gql`
  mutation DenyOption($option: Int!, $denier: Int!) {
    denyOption(option: $option, denier: $denier) {
      option {
        id
        status
      }
    }
  }
`;

export const MANAGER_APPROVE_ALL_TO_REQUESTS = gql`
  mutation approveAll($approvingManager: ID!, $ids: [Int]!) {
    batchApproveTimeOffRequestWithNotifications(
      approvingManager: $approvingManager
      ids: $ids
    ) {
      timeOffRequests {
        id
        approvedby {
          id
        }
      }
    }
  }
`;

/************************************************************************************/
/*Analytics*/
/************************************************************************************/

export const ANALYTICS_DATA = gql`
  query analytics(
    $id: Int
    $officeId: Int
    $source: String
    $schedulePeriod: Int
  ) {
    analyticsDatums(
      id: $id
      office: $officeId
      source: $source
      schedulePeriod: $schedulePeriod
    ) {
      id
      data
      source
    }
    offices(id: $officeId) {
      employeeSet {
        id
        firstName
        lastName
      }
      scheduleperiodSet {
        id
        start
        end
      }
    }
  }
`;

export const EMPLOYEE_NAMES = gql`
  query EmployeeNames($officeId: Int) {
    offices(id: $officeId) {
      employeeSet {
        id
        firstName
        lastName
      }
    }
  }
`;

export const EMPLOYEE_AVAILABILITY = gql`
  query EmployeeAvailability($officeId: Int) {
    availability(officeId: $officeId) {
      id
      employee {
        id
        firstName
        lastName
      }
      approvedby {
        id
        firstName
        lastName
      }
      firstday
      lastday
      startTime
      endTime
      type {
        name
        id
      }
      comment
      workHours
      office {
        id
      }
    }
  }
`;

export const LOCATION_DISTRIBUTION_ANALYTICS = gql`
  query locationDistributionAnalytics {
    locationDistributionAnalytics {
      id
      employee
      office
      month
      totalHours
    }
  }
`;

export const SCHEDULE_QUALITY_ANALYTICS = gql`
  query ScheduleQualityAnalytics($office: ID, $employee: ID) {
    scheduleQualityAnalytics(office: $office, employee: $employee) {
      employee
      office
      scheduleStart
      scheduleEnd
      score
    }
  }
`;

export const PREFERENCE_ANALYTICS = gql`
  query PreferenceAnalytics($office: ID, $employee: ID) {
    preferenceAnalytics(office: $office, employee: $employee) {
      employee
      scheduleStart
      scheduleEnd
      category
      preference
      value
      contribution
    }
  }
`;

export const EMP_DETAILS_BY_OFFICE = gql`
  query Offices($office: Int) {
    offices(id: $office) {
      employeeSet {
        id
        firstName
        lastName
      }
      scheduleperiodSet {
        id
        start
        end
      }
    }
  }
`;

export const UTILIZATION_ANALYTICS = gql`
  query UtilizationAnalytics($office: ID, $employee: ID) {
    utilizationAnalytics(office: $office, employee: $employee) {
      employee
      scheduleStart
      scheduleEnd
      office
      skill
      utilization
    }
  }
`;
/************************************************************************************/
/*Procedures*/
/************************************************************************************/
export const BATCH_CREATE_PROCEDURE = gql`
  mutation BatchCreateProcedures($input: [BatchCreateProcedureInput]!) {
    batchCreateProcedure(input: $input) {
      procedures {
        id
        start
        end
        notes
        procedureRequirement {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_UPDATE_PROCEDURE = gql`
  mutation BatchUpdateProcedures($input: [BatchPatchProcedureInput]!) {
    batchUpdateProcedure(input: $input) {
      procedures {
        id
        start
        end
        notes
        procedureRequirement {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_CREATE_PROCEDURE_REQUIREMENTS = gql`
  mutation BatchCreateProcedureRequirements(
    $input: [BatchCreateProcedureRequirementInput]!
  ) {
    batchCreateProcedureRequirement(input: $input) {
      procedureRequirements {
        id
        name
        procedureType {
          id
          name
        }
        procedure {
          id
          notes
        }
        office {
          id
          name
        }
        earliestDate
        latestDate
        duration
        additiveConstraint
      }
    }
  }
`;

export const BATCH_UPDATE_PROCEDURE_REQUIREMENTS = gql`
  mutation BatchUpdateProcedureRequirements(
    $input: [BatchPatchProcedureRequirementInput]!
  ) {
    batchUpdateProcedureRequirement(input: $input) {
      procedureRequirements {
        id
        name
        procedureType {
          id
          name
        }
        procedure {
          id
          notes
        }
        office {
          id
          name
        }
        earliestDate
        latestDate
        duration
        additiveConstraint
      }
    }
  }
`;

export const BATCH_CREATE_PROCEDURE_EMPLOYEE_CONSTRAINTS = gql`
  mutation BatchCreateProcedureEmployeeConstraint(
    $input: [BatchCreateProcedureEmployeeConstraintInput]!
  ) {
    batchCreateProcedureEmployeeConstraint(input: $input) {
      procedureEmployeeConstraints {
        id
        value
        start
        end
        employeeCapability {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_UPDATE_PROCEDURE_EMPLOYEE_CONSTRAINTS = gql`
  mutation BatchUpdateProcedureEmployeeConstraint(
    $input: [BatchPatchProcedureEmployeeConstraintInput]!
  ) {
    batchUpdateProcedureEmployeeConstraint(input: $input) {
      procedureEmployeeConstraints {
        id
        value
        start
        end
        employeeCapability {
          id
          name
        }
      }
    }
  }
`;

export const BATCH_DELETE_PROCEDURE_EMPLOYEE_CONSTRAINTS = gql`
  mutation BatchDeleteProcedureEmployeeConstraint($ids: [ID]!) {
    batchDeleteProcedureEmployeeConstraint(ids: $ids) {
      deletedIds
    }
  }
`;

export const PROCEDURE_REQUIREMENTS = gql`
  query ProcedureRequirements($office: Int) {
    procedureRequirements(office: $office) {
      id
      name
      earliestDate
      latestDate
      duration
      procedureType {
        id
        name
      }
      office {
        id
        name
      }
    }
  }
`;

export const PROCEDURE_EMPLOYEE_CONSTRAINTS = gql`
  query ProcedureEmployeeConstraints {
    procedureEmployeeConstraints {
      id
      start
      end
      employeeCapability {
        id
        name
      }
      procedureRequirement {
        id
        procedure {
          id
          notes
        }
        office {
          id
        }
      }
    }
  }
`;

export const CREATE_BOOKED_PROCEDURES = gql`
  mutation CreateBookedProcedureMutation(
    $procedures: [CreateScheduledProcedureInput]!
  ) {
    createBookedProcedures(procedures: $procedures) {
      procedures {
        id
        name
        procedureRequirement {
          id
          name
          procedureType {
            id
            name
          }
        }
      }
    }
  }
`;

export const PROCEDURE_TYPES = gql`
  query ProcedureTypes {
    procedureTypes {
      id
      name
    }
  }
`;

/************************************************************************************/
/*Float*/
/************************************************************************************/

export const GET_OFFICE_SCHEDULE_STATUS = gql`
  query Offices($id: Int) {
    offices(id: $id) {
      schedulingStatus
    }
  }
`;

export const GET_FLOAT_DETAILS = gql`
  query Offices {
    offices {
      id
      name
      floatStatus
      shiftBasedDisplay
      skillSet {
        id
        name
        variety
      }
      scheduleperiodSet {
        id
        isPublished
        isUnderReview
        start
        end
        status
      }
    }
  }
`;

export const GET_SLACK_FLOAT = gql`
  query GetSlack(
    $rangeStart: DateTime
    $rangeEnd: DateTime
    $issuesOnly: Boolean
  ) {
    slacks: datetimeSlacks(
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      issuesOnly: $issuesOnly
    ) {
      id
      count
      slack
      start
      end
      skill {
        name
        id
      }
      schedulePeriod {
        id
        office {
          name
          id
        }
      }
    }
    # acuitySlacks: datetimeAcuitySlacks(
    #   rangeStart: $rangeStart
    #   rangeEnd: $rangeEnd
    #   office: $office
    #   issuesOnly: $issuesOnly
    # ) {
    #   id
    #   assigned
    #   lower
    #   upper
    #   start
    #   end
    #   schedulePeriod {
    #     id
    #     office {
    #       name
    #     }
    #   }
    # }
  }
`;

export const GET_SHIFTS_FOR_FLOAT_MANAGER = gql`
  query GetShifts($officeId: Int!, $rangeStart: DateTime, $rangeEnd: DateTime) {
    shifts(officeId: $officeId, rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
      id
      start
      end
    }
    offices(id: $officeId) {
      timezone
      constraintSet {
        type {
          id
          name
          definition
        }
        skill {
          id
          name
          variety
        }
        value
        employeetype {
          id
          name
        }
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        starttime
        endtime
      }
    }
  }
`;

export const REQUEST_SINGLE_FLOAT = gql`
  mutation RequestSingleFloat($shift: ID!, $comments: String) {
    requestSingleFloat(shift: $shift, comments: $comments) {
      notifications {
        id
      }
    }
  }
`;

export const GET_SINGLE_SHIFT = gql`
  query GetSingleShift($id: Int) {
    shifts(id: $id) {
      id
      start
      end
      office {
        id
        name
      }
    }
  }
`;

export const GET_FLOAT_SHIFT_ASSIGNMENTS = gql`
  query FloatShiftAssignments($year: Int!, $month: Int!) {
    floatShiftAssignments(year: $year, month: $month) {
      id
      shift {
        id
        office {
          id
          name
        }
        start
        end
      }
      employee {
        id
        firstName
        lastName
        employeeskillSet {
          id
          skill {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_FLOAT_DATETIME_SLACKS = gql`
  query FloatDatetimeSlacks($year: Int!, $month: Int!) {
    floatDatetimeSlacks(year: $year, month: $month) {
      skill {
        id
      }
      schedulePeriod {
        office {
          id
        }
      }
    }
  }
`;

export const NOTIFY_DEVELOPERS = gql`
  mutation notifyDevelopers($message: String!) {
    notifyDevelopers(message: $message) {
      success
    }
  }
`;

export const MENTEE = gql`
  query Mentee($id: Int, $office: Int) {
    mentees(id: $id, office: $office) {
      id
      start
      end
      mentor {
        id
        employee {
          id
          firstName
          lastName
          assignmentSet {
            id
            primary
          }
        }
      }
      mentee {
        id
        employee {
          id
          firstName
          lastName
          assignmentSet {
            id
            primary
          }
        }
      }
    }
  }
`;

export const BATCH_CREATE_MENTORSHIP = gql`
  mutation BatchCreateMentee($input: [BatchCreateMenteeInput]!) {
    batchCreateMentee(input: $input) {
      mentees {
        id
        start
        end
        mentor {
          employee {
            id
            firstName
            lastName
          }
        }
        mentee {
          employee {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const DELETE_MENTEE = gql`
  mutation deleteMentee($id: ID!) {
    deleteMentee(id: $id) {
      found
    }
  }
`;

export const UPDATE_MENTEE = gql`
  mutation updateMentee($id: ID!, $input: PatchMenteeInput!) {
    updateMentee(id: $id, input: $input) {
      mentee {
        id
      }
    }
  }
`;

/******************************************************************
 * Dashboards
 ******************************************************************/

export const FEEDBACK_EMAIL = gql`
  mutation FeedbackEmail(
    $body: String!
    $recipients: [String]
    $subject: String!
  ) {
    emailNotify(body: $body, recipients: $recipients, subject: $subject) {
      success
    }
  }
`;

export const TOTAL_HOURS_THIS_SCHEDULE_FOR_EMPLOYEE = gql`
  query ShiftAnalytics($period: ID!, $employee: ID) {
    shiftAnalytics(period: $period, employee: $employee) {
      employee
      officeHours {
        office
        totalHours
      }
    }
  }
`;

export const OFFICE_SCHDULING_INFO_MANAGER = gql`
  query OfficeSchedulingSummary($schedulePeriod: ID!) {
    officeSummary(schedulePeriod: $schedulePeriod) {
      office
      schedulingTime
      rostersConsidered
    }
  }
`;

export const EMPLOYEE_PREFERENCE_SUMMARY = gql`
  query EmployeePreferenceSummary($schedulePeriod: ID!, $employee: ID!) {
    employeeSummary(schedulePeriod: $schedulePeriod, employee: $employee) {
      employee
      bestPerforming
      mostUnderperforming
    }
  }
`;
