import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Roles from "../../Roles/roles";
import { useReactiveVar } from "@apollo/client";
import { filterListVar, userVar } from "../../cache";

const MuiAccordion = withStyles({
  root: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(Accordion);

/*
1. Get the list of all the differnt filters from filterListVar
2. Set inititial filters
3. Handle event when user selects an option on the filter and set
4. Handle Reset and set the filter back to initial state
*/

const FilterMenu = ({
  jobTypes,
  shiftNames,
  availabilityTypes,
  officeFloatStatus,
}) => {
  const filterList = useReactiveVar(filterListVar);
  const { shiftsFilters, shiftTypeFilters, jobTypeFilters, requestsFilters } =
    filterList;
    
  const user = userVar();
  const userRole = user.role;

  const jobTypeNames = jobTypes.map((skill) => skill.name);

  const availabilityTypeNames = availabilityTypes.map((type) => type.name);
  const nonLeaveTypes = ["Admin", "Training", "Medical"];

  const initialShiftsFilters = ["Personal"];
    //userRole === Roles.MANAGER ? ["All Shifts"] : ["Personal"]; //on load show all shifts if it is a manager.show personal shifts if it is an employee
  const initialShiftTypeFilters = [...shiftNames, "All Shift Type"]; // on load select all shifts
  const initialJobTypeFilters =
    officeFloatStatus === false
      ? [...jobTypeNames, "Float", "All Job Type"]
      : [...jobTypeNames, "All Job Type"]; // if office accepts floats select floats and all jobtypes
  const initialRequestsFilters =
    userRole === Roles.MANAGER
      ? []
      : [
          "Soft Requests",
          ...availabilityTypeNames,
          "Pending",
          "Approved",
          "All Requests",
        ]; // if user is an employee select all requests and get different types of availability types for this office

  useEffect(() => {
    if (shiftTypeFilters.includes("All Shift Type")) {
      filterListVar({
        ...filterList,
        allFilters: [...filterList.allFilters, ...shiftNames],
        shiftTypeFilters: [...shiftNames, "All Shift Type"],
      });
    }
    if (jobTypeFilters.includes("All Job Type")) {
      if (officeFloatStatus === false) {
        filterListVar({
          ...filterList,
          allFilters: [...filterList.allFilters, ...jobTypeNames],
          jobTypeFilters: [...jobTypeNames, "Float", "All Job Type"],
        });
      } else {
        filterListVar({
          ...filterList,
          allFilters: [...filterList.allFilters, ...jobTypeNames],
          jobTypeFilters: [...jobTypeNames, "All Job Type"],
        });
      }
    }
  }, []);

  //set the selected filter values based on user's selection
  const handleCheckboxToggle = (value, list) => () => {
    let checked;
    let allNotChanging;
    let allLabel;
    let allList;
    switch (list) {
      case "shiftsFilters":
        checked = [value];
        allNotChanging = [
          ...shiftTypeFilters,
          ...requestsFilters,
          ...jobTypeFilters,
        ];
        break;
      case "shiftTypeFilters":
        checked = [...shiftTypeFilters];
        allNotChanging = [
          ...shiftsFilters,
          ...requestsFilters,
          ...jobTypeFilters,
        ];
        allLabel = "All Shift Type";
        allList = [...shiftNames, "All Shift Type"];
        break;
      case "jobTypeFilters":
        checked = [...jobTypeFilters];
        allNotChanging = [
          ...shiftTypeFilters,
          ...shiftsFilters,
          ...requestsFilters,
        ];
        allLabel = "All Job Type";
        allList =
          officeFloatStatus === false
            ? [...jobTypeNames, "Float", "All Job Type"]
            : [...jobTypeNames, "All Job Type"];
        break;
      case "requestsFilters":
        checked = [...requestsFilters];
        allNotChanging = [
          ...shiftTypeFilters,
          ...shiftsFilters,
          ...jobTypeFilters,
        ];
        allLabel = "All Requests";
        allList = [
          "Soft Requests",
          ...availabilityTypeNames,
          "Pending",
          "Approved",
          "All Requests",
        ];
        break;
      default:
        handleResetAll();
        break;
    }

    const currentIndex = checked.indexOf(value); // get the selected ckeckbox index
    let indexOfAll = checked.indexOf(allLabel); //get the alllabel index

    if (value === "Non-Leave") {
      if (
        checked.includes("Admin") &&
        checked.includes("Medical") &&
        checked.includes("Training")
      ) {
        nonLeaveTypes.forEach((type) => {
          const indexOfType = checked.indexOf(type);
          checked.splice(indexOfType, 1);
        });
      } else {
        nonLeaveTypes.forEach((type) => {
          const indexOfType = checked.indexOf(type);
          if (indexOfType === -1) {
            checked.push(type);
          }
        });
      }
    } else if (list !== "shiftsFilters") {
      if (currentIndex === -1 && value === allLabel) {
        checked = [...allList];
      } else if (currentIndex === -1) {
        checked.push(value);
      } else if (currentIndex !== -1 && value === allLabel) {
        checked = [];
      } else if (currentIndex !== -1 && indexOfAll !== -1) {
        checked.splice(currentIndex, 1);
        indexOfAll = checked.indexOf(allLabel);
        checked.splice(indexOfAll, 1);
      } else {
        checked.splice(currentIndex, 1);
      }
    }
    filterListVar({
      ...filterListVar(),
      [list]: checked,
      allFilters: [...allNotChanging, ...checked],
    });
  };

  //gets all filters to initial state
  const handleResetAll = () => {
    const allFilters = [
      ...initialShiftsFilters,
      ...initialShiftTypeFilters,
      ...initialJobTypeFilters,
      ...initialRequestsFilters,
    ];
    filterListVar({
      allFilters,
      requestsFilters: initialRequestsFilters,
      shiftTypeFilters: initialShiftTypeFilters,
      jobTypeFilters: initialJobTypeFilters,
      shiftsFilters: initialShiftsFilters,
    });
  };

  return (
    <Grid container direction="column" style={{ width: 300 }}>
      <Grid item align="right">
        <Button
          color="primary"
          onClick={handleResetAll}
          style={{ marginBottom: 8 }}
        >
          Reset All Filters
        </Button>
      </Grid>
      <Grid item container component={MuiAccordion}>
        <Grid
          item
          container
          component={AccordionSummary}
          alignItems="center"
          justifyContent="space-between"
          expandIcon={<ExpandMoreIcon />}
          style={{ height: 60, marginTop: -15 }}
        >
          <Grid item xs={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
            <Typography variant="subtitle1">Events to View</Typography>
          </Grid>
        </Grid>
        <Grid item container component={AccordionDetails} direction="column">
          <Grid
            item
            component={RadioGroup}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <FormControlLabel
              label="Personal"
              control={
                <Radio
                  checked={shiftsFilters.indexOf("Personal") !== -1}
                  onChange={handleCheckboxToggle("Personal", "shiftsFilters")}
                  style={{ marginTop: -5, marginBottom: -5, marginLeft: 10 }}
                />
              }
            />
            <FormControlLabel
              label="All"
              control={
                <Radio
                  checked={shiftsFilters.indexOf("All Shifts") !== -1}
                  onChange={handleCheckboxToggle("All Shifts", "shiftsFilters")}
                  style={{ marginTop: -5, marginBottom: -5, marginLeft: 10 }}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container component={MuiAccordion}>
        <Grid
          item
          container
          component={AccordionSummary}
          alignItems="center"
          justifyContent="space-between"
          expandIcon={<ExpandMoreIcon />}
          style={{ height: 60, marginTop: -15 }}
        >
          <Grid item xs={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
            <Typography variant="subtitle1">Shift Type</Typography>
          </Grid>
        </Grid>
        <Grid item container component={AccordionDetails} direction="column">
          {shiftNames &&
            shiftNames.map((shiftName) => (
              <Grid
                item
                container
                alignItems="center"
                onClick={handleCheckboxToggle(shiftName, "shiftTypeFilters")}
                key={shiftName}
                style={{ marginTop: -5, marginBottom: -5 }}
              >
                <Grid item>
                  <Checkbox
                    checked={shiftTypeFilters.indexOf(shiftName) !== -1}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">{shiftName}</Typography>
                </Grid>
              </Grid>
            ))}
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("All Shift Type", "shiftTypeFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox
                checked={shiftTypeFilters.indexOf("All Shift Type") !== -1}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">All</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container component={MuiAccordion}>
        <Grid
          item
          container
          component={AccordionSummary}
          alignItems="center"
          justifyContent="space-between"
          expandIcon={<ExpandMoreIcon />}
          style={{ height: 60, marginTop: -15 }}
        >
          <Grid item xs={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
            <Typography variant="subtitle1">Skills</Typography>
          </Grid>
        </Grid>
        <Grid item container component={AccordionDetails} direction="column">
          {jobTypeNames &&
            jobTypeNames.map((jobType,index) => (
              <Grid
                item
                container
                alignItems="center"
                onClick={handleCheckboxToggle(jobType, "jobTypeFilters")}
                key={index}
                style={{ marginTop: -5, marginBottom: -5 }}
              >
                <Grid item>
                  <Checkbox checked={jobTypeFilters.indexOf(jobType) !== -1} />
                </Grid>
                <Grid item>
                  <Typography variant="body1">{jobType}</Typography>
                </Grid>
              </Grid>
            ))}
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("Float", "jobTypeFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox checked={jobTypeFilters.indexOf("Float") !== -1} />
            </Grid>
            <Grid item>
              <Typography variant="body1">Float Employees</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("All Job Type", "jobTypeFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox
                checked={jobTypeFilters.indexOf("All Job Type") !== -1}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">All</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item container component={MuiAccordion}>
        <Grid
          item
          container
          component={AccordionSummary}
          alignItems="center"
          justify="space-between"
          expandIcon={<ExpandMoreIcon />}
          style={{ height: 60, marginTop: -15 }}
        >
          <Grid item xs={6} style={{ paddingTop: 12, paddingBottom: 12 }}>
            <Typography variant="subtitle1">Employee Type</Typography>
          </Grid>
        </Grid>
        <Grid item container component={AccordionDetails} direction="column">
          <Grid
            item
            container
            alignItems="center"
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox checked={true} />
            </Grid>
            <Grid item>
              <Typography variant="body1">Floats</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            //onClick={handleCheckboxToggle("All Job Type", "jobTypeFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox
                checked={true}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">All</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid item container component={MuiAccordion}>
        <Grid
          item
          container
          component={AccordionSummary}
          alignItems="center"
          justifyContent="space-between"
          expandIcon={<ExpandMoreIcon />}
          style={{ height: 60, marginTop: -15 }}
        >
          <Grid item xs={10} style={{ paddingTop: 12, paddingBottom: 12 }}>
            <Typography variant="subtitle1">Non-Schedule Events</Typography>
          </Grid>
        </Grid>
        <Grid item container component={AccordionDetails} direction="column">
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("All Requests", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox
                checked={requestsFilters.indexOf("All Requests") !== -1}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">All</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("Soft Requests", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox
                checked={requestsFilters.indexOf("Soft Requests") !== -1}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">Personal Soft Requests</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("Pending", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox checked={requestsFilters.indexOf("Pending") !== -1} />
            </Grid>
            <Grid item>
              <Typography variant="body1">Pending</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("Approved", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5 }}
          >
            <Grid item>
              <Checkbox checked={requestsFilters.indexOf("Approved") !== -1} />
            </Grid>
            <Grid item>
              <Typography variant="body1">Approved</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("UPTO", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5, paddingLeft: 16 }}
          >
            <Grid item>
              <Checkbox checked={requestsFilters.indexOf("UPTO") !== -1} />
            </Grid>
            <Grid item>
              <Typography variant="body1">Unavailable (UPTO)</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("PTO", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5, paddingLeft: 16 }}
          >
            <Grid item>
              <Checkbox checked={requestsFilters.indexOf("PTO") !== -1} />
            </Grid>
            <Grid item>
              <Typography variant="body1">PTO</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            onClick={handleCheckboxToggle("Non-Leave", "requestsFilters")}
            style={{ marginTop: -5, marginBottom: -5, paddingLeft: 16 }}
          >
            <Grid item>
              <Checkbox
                checked={
                  requestsFilters.includes("Admin") &&
                  requestsFilters.includes("Medical") &&
                  requestsFilters.includes("Training")
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">Non-Leave</Typography>
            </Grid>
          </Grid>
          {nonLeaveTypes.map((name) => (
            <Grid
              item
              key={name}
              container
              alignItems="center"
              onClick={handleCheckboxToggle(name, "requestsFilters")}
              style={{ marginTop: -5, marginBottom: -5, paddingLeft: 32 }}
            >
              <Grid item>
                <Checkbox checked={requestsFilters.indexOf(name) !== -1} />
              </Grid>
              <Grid item>
                <Typography variant="body1">{name}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterMenu;
