import React, { useEffect, useState } from "react";
import {
  Paper,
  CircularProgress,
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
  Box,
  Slider,
} from "@material-ui/core";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbar
} from "@mui/x-data-grid";
import { SCHEDULE_FITNESS, GET_PREFERENCES, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import SentimentNeutralIcon from "../../assets/quizImages/SentimentNeutralIcon";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import ErrorSnackBar from '../errors/errorSnackBar';
const useStyles = makeStyles(() => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 35,
    marginBottom: 20,
  },
}));

const CustomSlider = withStyles({
  root: {
    margin: 25,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#000",
    marginTop: -7,
    marginLeft: -7,
  },
  active: {},
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
    borderTop: "1px solid lightgrey",
  },
}))(TableCell);

const marks = [
  {
    value: -2,
    label: <MoodBadIcon style={{ paddingLeft: 15 }} />,
  },
  {
    value: -1,
    label: <SentimentDissatisfiedIcon />,
  },
  {
    value: 0,
    label: <SentimentNeutralIcon />,
  },
  {
    value: 1,
    label: <SentimentSatisfiedIcon />,
  },
  {
    value: 2,
    label: <MoodIcon style={{ paddingRight: 15 }} />,
  },
];

function ScheduleQualityList(props) {
  const classes = useStyles();
  const environment = process.env.NODE_ENV
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserPreferences, SetselectedUserPreferences] = useState([]);
  const [selectedUserName, SetselectedUserName] = useState("");
  const [showDialog, setshowDialog] = useState(false);
  const [rows, setrows] = useState([]);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS,
    {
      onError(error) {
        console.log(error);
      }
    }
  );

  const [scheduleFitness, { loading, error }] = useLazyQuery(SCHEDULE_FITNESS, {
    onCompleted(data) {
      setrows(data.employeeFitnesses);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({variables: {message: "Error on SCHEDULE_FITNESS Query. Environment: " + environment + ". Graphql " + error}});
    },
  });

  useEffect(() => {
    if (props.schedulePeriod != 0) {
      scheduleFitness({
        variables: {
          schedulePeriod: parseInt(props.schedulePeriod),
        },
      });
    } else {
      scheduleFitness({
        variables: {
          office: parseInt(props.officeId),
        },
      });
    }
  }, [props.schedulePeriod]);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      width: 300,
      valueGetter: (params) =>
        `${
          params.row.employee.firstName + " " + params.row.employee.lastName ||
          ""
        }`,
    },
    {
      field: "score",
      width: 300,
      headerName: "Schedule Quality Score",
      valueGetter: (params) => `${params.row.score.toFixed(2)}`,
    },
    {
      field: "maxScore",
      width: 400,
      hide:true,
      headerName: "Max Score you can get based on your preferences",
      valueGetter: (params) => `${params.row.maxScore.toFixed(2)}`,
    },
    {
      field: "ratio",
      width: 300,
      headerName: "Overall Schedule Quality",
      valueGetter: (params) => `${params.row.ratio.toFixed(2) * 100 + "%"}`,
    },
    {
      field: "User Preferences",
      width: 300,
      headerName: "View User Preferences",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setshowDialog(true);
            setSelectedUser(params.row.employee.id);
            SetselectedUserName(
              params.row.employee.firstName + " " + params.row.employee.lastName
            );
          }}
          color="secondary"
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const [getPreferences] = useLazyQuery(GET_PREFERENCES, {
    onCompleted(data) {
      SetselectedUserPreferences(data.preferences);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({variables: {message: "Error on GET_PREFERENCES lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  useEffect(() => {
    selectedUser != "" &&
      getPreferences({
        variables: {
          id: parseInt(selectedUser),
          office: parseInt(props.officeId),
        },
      });
  }, [selectedUser]);

  if (error) {
    return (
      <Paper>
        Something went wrong.{error.message}. Please try again later.
      </Paper>
    );
  } else if (loading || rows.length<=0) {
    return <CircularProgress color="primary" />;
  } else {
    return (
      <Container>
        <Dialog open={showDialog} fullWidth maxWidth="md">
          <DialogContent
            style={{ padding: 20, overflowX: "hidden", textAlign: "center" }}
          >
            <Box style={{ textAlign: "right" }}>
              <IconButton onClick={() => setshowDialog(false)} color="primary">
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography>
              <b>Preferences - {selectedUserName}</b>
            </Typography>
            <Grid container direction="row" spacing={4}>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Preference Type</StyledTableCell>
                      <StyledTableCell>User Input</StyledTableCell>
                      <StyledTableCell style={{textAlign:'right'}}>Value</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedUserPreferences.length &&
                      selectedUserPreferences.map((e, index) => (
                        <TableRow key={index}>
                          <StyledTableCell>{e.type.type}</StyledTableCell>
                          <StyledTableCell>
                            <CustomSlider
                              value={e.value}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="off"
                              marks={marks}
                              min={-2}
                              max={2}
                              disabled={true}
                              className={classes.slider}
                            />
                          </StyledTableCell>
                          <StyledTableCell style={{textAlign:'right'}}>{e.value}</StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Grid container direction="row" spacing={2} component={Paper}>
          <Grid item xs={12} style={{ marginTop: 30 }}>
            <Typography variant="h5">Schedule Quality Report</Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 700 }}>
              <DataGrid
                data-testid="c"
                className={classes.dt}
                rows={rows}
                columns={columns}
                pageSize={10}
                // components={{
                //   Toolbar: CustomToolbar,
                // }}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default ScheduleQualityList;
