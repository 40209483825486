import React from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import AlertOutline from "mdi-material-ui/AlertOutline";
import CloseIcon from "@material-ui/icons/Close";
import { format, isSameDay } from "date-fns";
import { selectedDateVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "white",
  },
  darkText: {
    color: "black",
  },
  underText: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
  },
  icon: {
    padding: 0,
    marginRight: 5,
    marginBottom: -2,
  },
}));

const ShiftSlackBySkill = ({ slacks, closeDialog }) => {
  const classes = useStyles();
  const date = selectedDateVar();
  let slacksForDate;
  if (slacks.length > 0) {
    slacksForDate = slacks.filter((slack) => {
      const startDate = new Date(slack.interval.start);
      return isSameDay(startDate, date);
    });
  }

  let skills = [];
  if (slacksForDate) {
    slacksForDate.forEach((slack) => {
      !skills.includes(slack.skill.name) && skills.push(slack.skill.name);
    });
  }
  const timeRows = [];
  if (slacksForDate) {
    slacksForDate.forEach((slack) => {
      const start = new Date(slack.interval.start);
      const end = new Date(slack.interval.end);
      const formattedStart = format(start, "HH:mm");
      const formattedEnd = format(end, "HH:mm");
      const title = `${formattedStart} - ${formattedEnd}`;
      const existing = timeRows.find(
        (row) =>
          row.time === title &&
          row.office === slack.interval.shifts[0].office.name
      );
      let index;
      let count;
      let slackNumber;
      let empty;
      let over = false;
      let under = false;

      index = skills.indexOf(slack.skill.name);

      if (slack.slack > 0) {
        slackNumber = ` (+${slack.slack})`;
        over = true;
      } else if (slack.slack < 0) {
        slackNumber = ` (${slack.slack})`;
        under = true;
      } else {
        slackNumber = "";
      }
      count = slack.numAssigned;
      empty = slack.numAssigned === 0 && slack.numAssigned === 0;

      if (existing) {
        if (empty) {
          return;
        } else {
          over = existing.over ? existing.over : over;
          under = existing.under ? existing.under : under;
          const values = [...existing.values];
          values.splice(index, 1, `${count}${slackNumber}`);
          existing.values = values;
          existing.over = over;
          existing.under = under;
        }
      } else {
        const values = skills.map((skill) => null);
        if (empty) {
          timeRows.push({
            time: title,
            start: slack.interval.start,
            under: under,
            over: over,
            values: values,
            office: slack.interval.shifts[0].office.name,
          });
        } else {
          values.splice(index, 1, `${count} ${slackNumber}`);
          timeRows.push({
            time: title,
            start: slack.interval.start,
            under: under,
            over: over,
            values: values,
            office: slack.interval.shifts[0].office.name,
          });
        }
      }
    });
  }
  const slacksRows = () => {
    const sortedByTime = [...timeRows];
    sortedByTime.sort((a, b) => new Date(a.start) - new Date(b.start));

    return sortedByTime.map((slack, rowIndex) => {
      return (
        <TableRow key={slack.time + slack.office} className={classes.row}>
          <TableCell className={classes.darkText} align="center">
            {slack.office}
          </TableCell>
          <TableCell className={classes.darkText} align="center">
            {slack.time}
          </TableCell>
          <TableCell className={classes.darkText}>
            {slack.under && <WarningIcon style={{ fontSize: 18 }} />}
            {slack.over && !slack.under && (
              <AlertOutline style={{ fontSize: 18 }} />
            )}
          </TableCell>
          {slack.values.map((value, index) => {
            return (
              <TableCell
                key={`${value}-${rowIndex}-${index}`}
                className={
                  value && value.includes("-")
                    ? classes.underText
                    : classes.darkText
                }
                align="left"
              >
                {value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <TableContainer>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" className={classes.darkText}>
            {format(date, "dd MMM yyyy")}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="close"
            color="secondary"
            size="small"
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.darkText} align="center">
              Office
            </TableCell>
            <TableCell className={classes.darkText} align="center">
              Time
            </TableCell>
            <TableCell></TableCell>
            {skills.map((skill) => (
              <TableCell className={classes.darkText} key={skill} align="left">
                {skill}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{slacksRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShiftSlackBySkill;
