import React, { useState } from "react";
import { userVar, selectedDateVar, locationConfigVar } from "../../cache";
import { GET_SHIFT_SWITCH_BUFFER_DAYS } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import roles from "../../Roles/roles";
import MangShiftSwitchRequestForm from "./MangShiftSwitchRequestForm";
import EmpShiftSwitchRequestForm from "./EmpShiftSwitchRequestForm";
import MissionMangShiftSwitchRequestForm from "./MissionMangShiftSwitchRequestForm";
import MissionEmpShiftSwitchRequestForm from "./MissionEmpShiftSwitchRequestForm";

import {
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";

const ShiftSwitchRequestForm = (props) => {
  const {
    allEvents,
    userEvents,
    closeDialog,
    shiftNames,
    scheduleEndDate,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    view,
    shiftToChange,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    getVariables
  } = props;

  const user = userVar();
  const date = selectedDateVar();
  const locationConfig = locationConfigVar();

  const shiftBased = locationConfig.shiftBasedDisplay;

  const managerAccess =
    user.role === roles.MANAGER ||
    user.role === roles.SCHEDULER ||
    user.role === roles.ADMIN;

  const [personalShiftSwitch, setPersonalShiftSwitch] = useState(false);

  //The number of days required between shift and a shift switch request for that shift.
  const { data, loading, error } = useQuery(GET_SHIFT_SWITCH_BUFFER_DAYS, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SHIFT_SWITCH_BUFFER_DAYS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // check if loggedin user is employee or manager and display the shift switch form based on the employee role
  if (loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    // console.log(error)
    return (
      <>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">Switch Shifts</Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item container justifyContent="flex-start">
            <RescheduleOptionsKey type="switch" />
          </Grid>
        </Grid>
      </>
    );
    //  <Typography>Something went wrong. Please try again.</Typography>
  } else {
    const shiftSwitchBuffer =
      data.offices.length > 0 ? data.offices[0].shiftSwitchBufferDays : 1;

    return (
      <Grid
        container
        component="form"
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">Switch Shifts</Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item container justifyContent="flex-start">
            <RescheduleOptionsKey type="switch" />
          </Grid>
        </Grid>
        {managerAccess && (
          <Grid item style={{ marginTop: 15 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={personalShiftSwitch}
                  onChange={(e) => setPersonalShiftSwitch(e.target.checked)}
                  name="personalShiftSwitch"
                  color={personalShiftSwitch ? "primary" : "secondary"}
                />
              }
              label="Shift Switch Request is for Me"
            ></FormControlLabel>
          </Grid>
        )}
        {user.role === roles.EMPLOYEE || personalShiftSwitch ? (
          shiftBased ? (
            <EmpShiftSwitchRequestForm
              allEvents={allEvents}
              userEvents={userEvents}
              closeDialog={closeDialog}
              date={date}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              shiftSwitchBuffer={shiftSwitchBuffer}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          ) : (
            <MissionEmpShiftSwitchRequestForm
              allEvents={allEvents}
              userEvents={userEvents}
              closeDialog={closeDialog}
              date={date}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              shiftToChange={shiftToChange}
              view={view}
              shiftSwitchBuffer={shiftSwitchBuffer}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )
        ) : shiftBased ? (
          <MangShiftSwitchRequestForm
            allEvents={allEvents}
            shiftNames={shiftNames}
            closeDialog={closeDialog}
            date={date}
            scheduleEndDate={scheduleEndDate}
            setToast={setToast}
            setShowToast={setShowToast}
            setErrorToast={setErrorToast}
            setShowErrorToast={setShowErrorToast}
            refetch={refetch}
            shiftSwitchBuffer={shiftSwitchBuffer}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
            getVariables={getVariables}
          />
        ) : (
          <MissionMangShiftSwitchRequestForm
            allEvents={allEvents}
            shiftNames={shiftNames}
            closeDialog={closeDialog}
            date={date}
            scheduleEndDate={scheduleEndDate}
            setToast={setToast}
            setShowToast={setShowToast}
            setErrorToast={setErrorToast}
            setShowErrorToast={setShowErrorToast}
            refetch={refetch}
            shiftSwitchBuffer={shiftSwitchBuffer}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        )}
      </Grid>
    );
  }
};

export default ShiftSwitchRequestForm;
