import React, { useEffect, useState } from 'react';
import { 
    Button, Grid, Typography, 
    makeStyles, InputLabel, IconButton, TextField 
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { format, sub } from 'date-fns';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SHIFT_ASSIGNMENT, EMPLOYEE_CALL_IN } from '../../api/gqlQueries';
import { userVar, selectedDateVar } from '../../cache';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 138,
        maxWidth: 225,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    spaceBelow: {
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.primary.main
    },
    textInput: {
        width: 375,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    dateError: {
        color: theme.palette.primary.main,
        width: 225, 
        marginTop: -4,
        paddingLeft: 14,
        paddingRight: 14
    }
}));

// users use this form to indicate that they wont be available on a particular date after the schedule period is generated

const EmpCallInForm = (props) => {
    const classes = useStyles();

    const {
        closeDialog, 
        userEvents, 
        setToast, 
        setShowToast, 
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,

        // refetch
    } = props;

    const user = userVar();
    const date = selectedDateVar();

    const userScheduleDates = userEvents.map(event => (
        format(new Date(event.start), 'MM/dd/yyyy')
    ));
    const [currentDate, setCurrentDate] = useState(new Date(date));
    const [note, setNote] = useState('');
    const [shiftAssignmentId, setShiftAssignmentId] = useState('');
    const [shiftId, setShiftId] = useState('');
    const [error, setError] = useState('');
    const [dateError, setDateError] = useState('');

    const [getShiftAssignmentId] = useLazyQuery(GET_SHIFT_ASSIGNMENT, {
        onCompleted(data) {
            console.log(data);
            setShiftAssignmentId(data.shiftAssignments[0].id);
            setError('');
        },
        onError(error){
            console.log(error);
            setError('Unable to complete request. Please check date and try again.');
            notifyDevelopers({variables: {message: "Error on GET_SHIFT_ASSIGNMENT lazyQuery. Environment: " + environment + ". Graphql " + error}});
        }
    });

    const [callIn] = useMutation(EMPLOYEE_CALL_IN, {
        onCompleted() {
            // refetch();
            
            setToast('Call In');
            setShowToast(true);
            setError('');
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Error with request. Please ensure you chose the correct date and try again.');
            notifyDevelopers({variables: {message: "Error on EMPLOYEE_CALL_IN lazyQuery. Environment: " + environment + ". Graphql " + error}});
        }
    });

    useEffect(() => {
        const newDate = new Date(date);
        if (newDate < sub(new Date(), {days: 1})){
            setDateError('Date should not be before today');
            return;
        }
        setDateError('');
        const shift = userEvents.find(event => (
            new Date(event.start).toDateString() === newDate.toDateString()
        ));
        const shiftId = shift.eventId;
        setShiftId(shiftId);
        getShiftAssignmentId({variables: {
            employeeId: parseInt(user.id), 
            shiftId: parseInt(shiftId)
        }});
    }, []);

    const handleSubmit = () => {
        //mutation to change isArchived value on shift to true to indicate employee called off
        callIn({variables: {
            id: parseInt(shiftAssignmentId),
            note: note
        }});
    };

    const handleDateChange = (date) => {
        if(date && !isNaN(date.getTime())){
            if (date < sub(new Date(), {days: 1})){
                setCurrentDate(date);
                setDateError('Date should not be before today');
                return;
            } else {
                setCurrentDate(date);
                const shift = userEvents.find(event => (
                    new Date(event.start).toDateString() === date.toDateString()
                ));
                if (shift) {
                    setDateError('');
                    const shiftId = shift.eventId;
                    setShiftId(shiftId);
                    //query to get shift assignment ID every time date changes so value is stored for submit
                    getShiftAssignmentId({variables: {
                        employeeId: parseInt(user.id), 
                        shiftId: parseInt(shiftId)
                    }});
                } else {
                    setDateError('You must choose a date that you are working');
                }
            }
        } else {
            setDateError('Invalid date');
        }
    };

    const checkInvalid = (date) => {
        const formatted = format(date, 'MM/dd/yyyy');
        return !userScheduleDates.includes(formatted);
    };

    return (  
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid 
                container 
                component='form' 
                direction='column' 
                alignItems='flex-start' 
                justifyContent='center' 
                spacing={3}
            >
                <Grid container item justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h2' >Call In</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color='secondary' onClick={closeDialog}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <InputLabel htmlFor="current-date" shrink={false}>
                        <Typography variant='h5'>Date To Call In</Typography>
                    </InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant='inline'
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        id='current-date'
                        shouldDisableDate={checkInvalid}
                        value={currentDate}
                        onChange={handleDateChange}
                        className={classes.input}
                    />
                    {dateError && 
                        <Typography variant='body2' className={classes.dateError}>
                            {dateError}
                        </Typography>
                    }
                </Grid>
                <Grid item>
                    <InputLabel htmlFor="current-date" shrink={false}>
                        <Typography variant='h5'>Note to Manager</Typography>
                    </InputLabel>
                    <TextField 
                        variant='outlined'
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        className={classes.textInput}
                    />
                </Grid>
                <Grid item>
                    {error && <Typography className={classes.error}>{error}</Typography>}
                </Grid>
                <Grid 
                    item 
                    container 
                    justifyContent="flex-end" 
                    style={{zIndex: 4, position: 'absolute', bottom: 25, right: 30}}
                >
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={handleSubmit} 
                            disabled={Boolean(error || dateError)}
                        >
                            Call in
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};
 
export default EmpCallInForm;