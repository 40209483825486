import React, { useState } from 'react';
import { Typography, Grid, Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { GET_ASSIGNMENT } from '../../api/gqlQueries';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
    coloredAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    subtitle: {
        fontSize: 12,
        color: theme.palette.secondary.main
    },
    newSubtitle: {
        fontSize: 12,
        color: theme.palette.primary.main
    },
    grayedOut: {
        color: theme.palette.secondary.light,
        fontStyle: 'italic'
    },
    grayedOutSubtitle: {
        color: theme.palette.secondary.light,
        fontStyle: 'italic',
        fontSize: 12
    },
    added: {
        color: theme.palette.primary.main
    },
    button: {
        width: 110
    }
}));

const ParticipantDelete = (props) => {
    const classes = useStyles();

    const {
        participant, 
        employeesToDelete, 
        setEmployeesToDelete, 
        employeesToAdd, 
        setEmployeesToAdd, 
        currentShiftEmployees, 
        setCurrentShiftEmployees, 
        newAddition,
        officeId,
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,
    } = props;

    const [deleted, setDeleted] = useState(false);

    const { data } = useQuery(GET_ASSIGNMENT, {
        variables: {
            employeeId: parseInt(participant.id)
        },
        onError(error) {
            console.log(error);
            setOpenSnackBar(true);
            setSnackBarMessage(
                "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
              );
            notifyDevelopers({variables: {message: "Error on GET_ASSIGNMENT Query. Environment: " + environment + ". Graphql " + error}});
        },

    });

    const fullName = participant.firstName + ' ' + participant.lastName;

    let matchingAssignment;
    let jobTitle;
    if (data) {
        matchingAssignment = data.assignments.find(assignment => (
            assignment.office.id === officeId
        ));
        // jobTitle = data.employees[0].employeeskillSet && data.employees[0].employeeskillSet.find(skill => (
        //     skill.skill.variety === 'JOB_TYPE'
        // ));
        // jobTitle = jobTitle ? jobTitle.skill.name : '';
    }
    // let jobTitle = matchingAssignment ? matchingAssignment.role.name : '';
    // jobTitle = jobTitle.slice(0, 1).toUpperCase() + jobTitle.slice(1).toLowerCase();

    const handleDeleteClick = (participant) => {
        const currentIndex = employeesToDelete.indexOf(participant);
        const newEmployeesToDelete = [...employeesToDelete];

        if (currentIndex === -1) {
            newEmployeesToDelete.push(participant);
        } else {
            newEmployeesToDelete.splice(currentIndex, 1);
        }

        setDeleted(!deleted);
        setEmployeesToDelete(newEmployeesToDelete);
    };

    const handleUndoAddClick = (participant) => {
        const currentIndexInAdd = employeesToAdd.indexOf(participant);
        const currentIndexInShiftEmployees = currentShiftEmployees.indexOf(participant);
        const newEmployeesToAdd = [...employeesToAdd];
        const newCurrentShiftEmployees = [...currentShiftEmployees];

        newEmployeesToAdd.splice(currentIndexInAdd, 1);
        newCurrentShiftEmployees.splice(currentIndexInShiftEmployees, 1);
        setEmployeesToAdd(newEmployeesToAdd);
        setCurrentShiftEmployees(newCurrentShiftEmployees);
    };
 
    return (  
        <Grid item container justifyContent='space-between' alignItems='center' xs={10}>
            {newAddition 
                ? <>
                    <Grid item style={{width: 185}}>
                        <Typography variant='subtitle1' className={classes.added} noWrap>
                            {fullName} 
                        </Typography>
                        <Typography className={classes.newSubtitle}>{jobTitle}</Typography>
                    </Grid>
                    <Grid item>
                        <Button 
                            color='primary' 
                            className={classes.button} 
                            onClick={() => handleUndoAddClick(participant)}
                        >
                            Undo Add
                        </Button>
                    </Grid> 
                </>
                : <>
                    <Grid item style={{width: 185}}>
                        <Typography variant='subtitle1' className={deleted ? classes.grayedOut : null} noWrap>
                            {fullName} 
                        </Typography>
                        <Typography className={deleted ? classes.grayedOutSubtitle : classes.subtitle}>
                            {jobTitle}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {deleted && 
                            <Button 
                                color='primary' 
                                className={classes.button} 
                                onClick={() => handleDeleteClick(participant)}
                            >
                                Undo Remove
                            </Button>
                        }
                        {!deleted && 
                            <Button 
                                color='primary' 
                                variant='outlined' 
                                className={classes.button} 
                                onClick={() => handleDeleteClick(participant)}
                            >
                                Remove
                            </Button>
                        }
                    </Grid>
                </>  
            }
        </Grid>
    );
  
};

ParticipantDelete.propTypes = {
    participant: PropTypes.object,
    employeesToDelete: PropTypes.array,
    setEmployeesToDelete: PropTypes.func,
    employeesToAdd: PropTypes.array,
    setEmployeesToAdd: PropTypes.func,
    currentShiftEmployees: PropTypes.array,
    setCurrentShiftEmployees: PropTypes.func,
    newAddition: PropTypes.bool
};

ParticipantDelete.defaultProps = {
    newAddition: false
};
 
export default ParticipantDelete;