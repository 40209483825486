export const mapHiredShifts = (shifts, rotationLength) => {
    if (shifts.length === 0) {
        if (rotationLength === 0) {
            return 'Any Shift';
        } else {
            return `Rotating Shifts Every ${rotationLength} Weeks`;
        }
    } else if (shifts.length === 7) {
        let shiftTypes = [];
        shifts.forEach(shift => {
            // const times = shift.shiftDefinition.start.slice(0, -3) + ' - ' +
            //     shift.shiftDefinition.end.slice(0, -3) 
            const description = shift.shiftDefinition.description;
            !shiftTypes.includes(description) &&
            shiftTypes.push(description)
        });

        if (shiftTypes.length === 1) {
            return `${shiftTypes[0]} Shift`;
        } else {
            const dayAndShift = shifts.map(shift => {
                return `${shift.shiftDefinition.weekday.name}: ${shift.shiftDefinition.description} Shift`;
            });
            return dayAndShift.join(', ');
        }
    } else if (shifts.length < 7) {
        const days = shifts.map(shift => shift.shiftDefinition.weekday.name);
        let shiftTypes = [];
        shifts.forEach(shift => {
            !shiftTypes.includes(shift.shiftDefinition.description) &&
            shiftTypes.push(shift.shiftDefinition.description)
        });

        if (
            days.includes('Monday') && days.includes('Tuesday') 
            && days.includes('Wednesday') && days.includes('Thursday') 
            && !days.includes('Saturday') && !days.includes('Sunday')
        ) {
            if (shiftTypes.length === 1) {
                return `Weekdays Only - ${shiftTypes[0]} Shift`;
            } else {
                return `Weekdays Only - ${shiftTypes.join(', ')} Shifts`;
            }
        } else if (
            !days.includes('Monday') && !days.includes('Tuesday') 
            && !days.includes('Wednesday') && !days.includes('Thursday') 
            && days.includes('Saturday') && days.includes('Sunday')
        ) {
            if (shiftTypes.length === 1) {
                return `Weekends Only - ${shiftTypes[0]} Shift`;
            } else {
                return `Weekends Only - ${shiftTypes.join(', ')} Shifts`;
            }
        } else {
            const dayAndShift = shifts.map(shift => {
                return `${shift.shiftDefinition.weekday.name}: ${shift.shiftDefinition.description}`;
            });
            return dayAndShift.join(', ');
        }
    } else if (shifts.length > 7 && rotationLength > 0) {
        let shiftTypes = [];
        shifts.forEach(shift => {
            !shiftTypes.includes(shift.shiftDefinition.description) &&
            shiftTypes.push(shift.shiftDefinition.description)
        });

        if (shiftTypes.length === 2) {
            return `Rotating ${shiftTypes[0]} and ${shiftTypes[1]} Shifts Every ${rotationLength} Weeks`;
        } else if (shiftTypes.length === 3) {
            const firstTwo = [shiftTypes[0], shiftTypes[1]].join(', ');
            return `Rotating ${firstTwo} and ${shiftTypes[2]} Shifts Every ${rotationLength} Weeks`;
        } else if (shiftTypes.length === 4) {
            const firstThree = [shiftTypes[0], shiftTypes[1], shiftTypes[2]].join(', ');
            return `Rotating ${firstThree} and ${shiftTypes[3]} Shifts Every ${rotationLength} Weeks`;
        } else {
            const joined = shiftTypes.join(', ');
            return `Rotating ${joined} Shifts Every ${rotationLength} Weeks`;
        }
    } else {
        const dayAndShift = shifts.map(shift => {
            return `${shift.shiftDefinition.weekday.name}: ${shift.shiftDefinition.description}`;
        });
        return dayAndShift.join(', ');
    }
};

export const mapHiredShiftsManagerView = (shifts) => {
    let shiftTypes = [];
    shifts.forEach(shift => {
        !shiftTypes.includes(shift.shiftDefinition.description) &&
        shiftTypes.push(shift.shiftDefinition.description)
    });
    if (shifts.length === 7 && shiftTypes.length === 1) {
        return shiftTypes[0];
    } else if (shifts.length === 0) {
        return 'Any Shift';
    } else {
        return 'Custom';
    }
};