import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  Paper,
  makeStyles,
  DialogContent,
  Dialog,
  Popover,
  Snackbar,
  CircularProgress,
  Badge,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import TuneIcon from "@material-ui/icons/Tune";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import ShiftSwitchRequestForm from "../shiftSwitch/ShiftSwitchRequestForm";
import EmpCallInForm from "./EmpCallInForm";
import Scheduler from "./Scheduler";
import SoftTimeOff from "./SoftTimeOff";
import EditSoftTimeOff from "./EditSoftTimeOff";
import TimeOffRequestForm from "./TimeOffRequestForm";
import EditTimeOffRequestForm from "./EditTimeOffRequestForm";
import CensusDataForm from "./CensusDataForm";
import Roles from "../../Roles/roles";
import MangEditShift from "./MangEditShift";
import {
  orderShifts,
  formatTimeOff,
  formatSoftRequests,
} from "../../helpers/formatShiftEvents";
import {
  format,
  add,
  sub,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  eachDayOfInterval,
  endOfWeek,
  isValid,
} from "date-fns";
import FilterMenu from "./FilterMenu";
import ShiftSlackBySkill from "../slacks/ShiftSlackBySkill";
import {
  useLazyQuery,
  useQuery,
  useMutation,
  useReactiveVar,
} from "@apollo/client";
import {
  filterListVar,
  userVar,
  selectedDateVar,
  forceOptionsExecution,
  ManualEventsToSave,
  lastSeenViewVar,
  slackAssignmentVar,
} from "../../cache";
import {
  INTERVAL_SLACKS,
  GET_EMPLOYEE_NAMES,
  GET_EMPLOYEES_OF_CHILD_OFFICES,
  AVAILABILITY_TYPES,
  GET_RELEVANT_SKILLS,
  GET_FLOAT_DETAILS,
  GET_OFFICE_SCHEDULE_STATUS,
  RESCHEDULE_SWAP_OPTIONS,
  EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
  CREATE_MANUAL_SHIFT_ASSIGNMENTS,
  ALL_OFFICES_NAMES,
  SCHEDULE_QUALITY_ANALYTICS,
  FIND_OFFICE_CONSTRAINT,
} from "../../api/gqlQueries";
import MultiSelect from "../general/MultiSelectSkills";
import StaffRequirementForm from "../floatCalendar/StaffRequirementForm";
import officeDisplayOrder from "../../helpers/OfficeDisplayOrder.json";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    marginTop: -7,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
  downloadPopover: {
    textAlign: "left",
  },
}));

function EmpCalendar({
  schedulePeriods,
  refetchSchedulePeriods,
  notifyDevelopers,
  environment,
  setOpenSnackBar,
  setSnackBarMessage,
}) {
  const classes = useStyles();

  const user = userVar();
  const selectedDate = selectedDateVar();
  const lastSeenView = lastSeenViewVar();
  const newSlackAssignment = slackAssignmentVar();

  useEffect(() => {
    lastSeenViewVar(view);
  }, []);
  const [view, setView] = useState(
    lastSeenView != null
      ? lastSeenView
      : !schedulePeriods || schedulePeriods.length <= 0
      ? "Month"
      : "ShiftTimeline"
  );
  const forceOptionsAlgorithm = forceOptionsExecution();

  const primaryUnit = user.office;
  const userRole = user.role;

  const managerAccess =
    userRole === Roles.MANAGER ||
    userRole === Roles.SCHEDULER ||
    userRole === Roles.LEAD ||
    userRole === Roles.ADMIN;

  //different types of Time Off's for this department

  useQuery(AVAILABILITY_TYPES, {
    onCompleted(data) {
      let sortedTypes = [];
      const ordered = ["PTO", "UPTO", "TRAINING", "Medical", "Admin", "OTHER"];
      ordered.forEach((name) => {
        let match = data.availabilityTypes.find((type) => type.name === name);
        if (match) {
          match = { name: match.name, id: match.id };
          if (match.name === "TRAINING" || match.name === "OTHER") {
            match.name =
              match.name.slice(0, 1) + match.name.slice(1).toLowerCase();
            sortedTypes.push(match);
          } else {
            sortedTypes.push(match);
          }
        }
      });
      setSortedAvailabilityTypes(sortedTypes);
      const requestFilters = sortedTypes.map((type) => type.name);

      if (!managerAccess) {
        const all = [...filterList.allFilters, ...requestFilters];
        const requests = [...filterList.requestsFilters, ...requestFilters];
        filterListVar({
          ...filterList,
          allFilters: all,
          requestsFilters: requests,
        });
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  // get Office Constraints
  const [OfficeConstraints, SetOfficeConstraints] = useState();

  useQuery(FIND_OFFICE_CONSTRAINT, {
    onCompleted(d) {
      SetOfficeConstraints(d.constraints);
    },
    onError(error) {
      console.log(error);
    },
  });

  //All skills of this particular office
  const { data: allSkillData } = useQuery(GET_RELEVANT_SKILLS, {
    variables: {
      office: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    let colors = [
      "#ee204d",
      "#ff7538",
      "#ffaa1d",
      "#fce883",
      "#c5e384",
      "#1cac78",
      "#1f75fe",
      "#7366bd",
      "#c154c1",
      "#f653a6",
    ];
    if (allSkillData) {
      const jobTypes = allSkillData.requiredSkills
        .filter(
          (skill) =>
            skill.variety === "JOB_TYPE" || skill.variety === "TRAINING"
        )
        .map((skill) => {
          return {
            id: skill.skillId,
            name: skill.name,
            skillColor: colors[Math.floor(Math.random() * colors.length)],
          };
        });
      setJobTypes(jobTypes);

      const jobTypeNames = jobTypes.map((skill) => skill.name);

      const all = [...filterList.allFilters, ...jobTypeNames, "All Job Type"];
      //on query complete set filterListVar with jobTypeFilters with all jobTypes selected
      filterListVar({
        ...filterList,
        allFilters: all,
        jobTypeFilters: ["All Job Type", ...jobTypeNames],
      });
    }
  }, [allSkillData]);

  const [allOffices, SetAllOffices] = useState();

  //All Office Names
  useQuery(ALL_OFFICES_NAMES, {
    variables: {
      receivesFloats: !user.isPrimaryParentOffice ? true : false,
    },
    onCompleted(d) {
      SetAllOffices(d.offices);
    },
    onError(error) {
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  //check if the schedule period isUnderReview or not published
  const notViewable =
    schedulePeriods.length > 0 &&
    schedulePeriods.find((schedule) => schedule.status === "MANAGER_DRAFT");

  const allSchedules = [...schedulePeriods];

  //get the most recent schedule even if it is in draft state
  const mostRecentIncludingDraft =
    allSchedules.length > 0
      ? allSchedules.sort((a, b) => new Date(b.start) - new Date(a.start))[0]
      : null;

  //get the start date and end date of draft schedule
  let draftStart;
  let draftEnd;
  let openStart;
  let openEnd;
  if (mostRecentIncludingDraft) {
    if (mostRecentIncludingDraft.status === "DRAFT") {
      draftStart = new Date(`${mostRecentIncludingDraft.start}T12:00:00`);
      draftEnd = sub(new Date(`${mostRecentIncludingDraft.end}T12:00:00`), {
        hours: 12,
      });
    } else if (
      mostRecentIncludingDraft.status === "MANAGER_DRAFT" &&
      managerAccess
    ) {
      //if not released for employees yet and logged in user is manager
      draftStart = new Date(`${notViewable.start}T12:00:00`);
      draftEnd = sub(new Date(`${notViewable.end}T12:00:00`), { hours: 12 });
    }
  }

  //get all the schedules in open state
  const allSchedulesInOpenState =
    allSchedules.length > 0
      ? allSchedules.filter(
          (e) =>
            e.status === "OPEN" ||
            e.status === "READY" ||
            e.status === "MANAGER_DRAFT"
        )
      : null;
  allSchedulesInOpenState &&
    allSchedulesInOpenState.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );

  if (allSchedulesInOpenState && allSchedulesInOpenState.length > 0) {
    let managerManualScheduling = allSchedulesInOpenState.filter(
      (e) => e.status === "READY" || e.status === "MANAGER_DRAFT"
    );
    managerManualScheduling.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
    if (managerAccess && managerManualScheduling.length > 0) {
      openStart = new Date(`${managerManualScheduling[0].start}T12:00:00`);
      openEnd = new Date(
        `${
          managerManualScheduling[parseInt(managerManualScheduling.length) - 1]
            .end
        }T12:00:00`
      );
    } else {
      let employeeOpen = allSchedulesInOpenState.filter(
        (e) => e.status === "OPEN"
      );
      openStart =
        employeeOpen.length > 0 &&
        new Date(`${employeeOpen[0].start}T12:00:00`);
      openEnd =
        employeeOpen.length > 0 &&
        new Date(
          `${employeeOpen[parseInt(employeeOpen.length) - 1].end}T12:00:00`
        );
    }
  }

  allSchedules.sort((a, b) => new Date(b.start) - new Date(a.start));

  const scheduleEndDate =
    allSchedules.length > 0
      ? allSchedules.find(
          (e) => e.status === "PUBLISHED" || e.status === "MANAGER_DRAFT"
        )?.end
      : null;

  const ref = React.useRef();
  const params = useParams();

  // function to get the events between startDate and endDate to increase performance of calendar
  function getVariables(startDate, endDate) {
    let variables;
    variables = {
      office: parseInt(primaryUnit.id),
      rangeStart: startDate.toISOString(),
      rangeEnd: endDate.toISOString(),
      employeeId: parseInt(user.id),
      officeId: parseInt(primaryUnit.id),
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    };
    return variables;
  }

  const today = new Date();
  let firstDayOfCurrentView = startOfMonth(today);
  firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
  let lastDayOfCurrentView = endOfMonth(today);
  lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
  lastDayOfCurrentView = add(lastDayOfCurrentView, { days: 1 });

  // on load get start of the month of selected date which is today up to end of the week of end date
  const variablesForInitialQuery = getVariables(
    firstDayOfCurrentView,
    lastDayOfCurrentView
  );

  const [shiftdata, SetShiftData] = useState([]);

  //get shifts query takes in the variables returned in getVariables method
  const { loading, error, data, refetch } = useQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      variables: variablesForInitialQuery,
      fetchPolicy: "no-cache",
      onCompleted(d) {
        console.log(d);
        let newShiftData = [...shiftdata, ...d.sqlShiftAssignments2];
        SetShiftData(newShiftData);
        let newScheduleData = orderShifts(d.sqlShiftAssignments2); //get shifts in the format required by syncfusion calendar
        newScheduleData.userShifts = [...d.userShifts]; //get only logged in users shifts
        newScheduleData = { ...scheduleData, ...newScheduleData };
        setScheduleData(newScheduleData);
        let timeOffs = d.timeOff.filter(
          (timeOff) =>
            timeOff.deniedText === null && timeOff.userCancelled === false
        );
        const newTimeOffs = formatTimeOff(timeOffs, user.id);
        const allTimeOffEvents = [...allDateTimeOffs, ...newTimeOffs];
        SetAllDateTimeOffs(allTimeOffEvents);
        //logged in users soft requests
        const newSoftRequests = formatSoftRequests(d.softRequests, user.id);
        const userSoftRequests = [...allDateSoftRequests, ...newSoftRequests];
        SetAllDateSoftRequests(userSoftRequests);
        const twomonthsago = sub(new Date(), { months: 2 });
        const twomonthsfromnow = add(new Date(), { months: 4 });
        allSchedulesInOpenState &&
          allSchedulesInOpenState.length > 0 &&
          allSchedulesInOpenState.sort(
            (a, b) => new Date(b.start) - new Date(a.start)
          );
        let futureEndDate =
          allSchedulesInOpenState &&
          allSchedulesInOpenState.length > 0 &&
          allSchedulesInOpenState[0].end;
        const futureVariables = getVariables(
          sub(lastDayOfCurrentView, { days: 1 }),
          futureEndDate && new Date(futureEndDate) > new Date(twomonthsfromnow)
            ? new Date(futureEndDate)
            : twomonthsfromnow
        );
        const pastVariables = getVariables(
          twomonthsago,
          add(firstDayOfCurrentView, { days: 1 })
        );
        getFutureShifts({
          variables: futureVariables,
        });
        getPastShifts({
          variables: pastVariables,
        });
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on shifts Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [allDateTimeOffs, SetAllDateTimeOffs] = useState([]);
  const [allDateSoftRequests, SetAllDateSoftRequests] = useState([]);

  //get shifts before first day of current view
  const [getFutureShifts, { data: futureData, loading: futureDataLoading }] =
    useLazyQuery(EFFICIENT_SQL_SHIFT_ASSIGNMENTS, {
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_FUTURE_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  //get shifts after last day of current view
  const [getPastShifts, { data: pastData, loading: pastDataLoading }] =
    useLazyQuery(EFFICIENT_SQL_SHIFT_ASSIGNMENTS, {
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_PAST_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  //get all employees of this office
  const { loading: employeesLoading, data: employeeData } = useQuery(
    GET_EMPLOYEE_NAMES,
    {
      variables: {
        office: parseInt(primaryUnit.id),
      },
      onCompleted(d) {
        addEmployeeData(d.offices[0].employeeSet);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_EMPLOYEE_NAMES Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  //get all employees of child office
  const {
    loading: childOfficeEmployeesLoading,
    data: childOfficeEmployeeData,
  } = useQuery(GET_EMPLOYEES_OF_CHILD_OFFICES, {
    variables: {
      parent: parseInt(primaryUnit.id),
    },
    onCompleted(d) {
      d.getChildren &&
        d.getChildren.length > 0 &&
        addEmployeeData(d.getChildren.map((e) => e.employeeSet).flat());
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_EMPLOYEE_NAMES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const { data: floatData } = useQuery(GET_FLOAT_DETAILS);

  //get all employees of float office
  const [getFloatEmployees, { data: floatEmployeeData }] = useLazyQuery(
    GET_EMPLOYEE_NAMES,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_FLOAT_DETAILS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );
  //filterlist to filter the events
  const filterList = useReactiveVar(filterListVar);
  const allFilters = filterList.allFilters;
  const [scheduleData, setScheduleData] = useState({
    formatted: [],
    userShifts: [],
    shiftNames: [],
  });
  const [sortedAvailabilityTypes, setSortedAvailabilityTypes] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [allSlackDates, setAllSlackDates] = useState([]);
  const [allSlacks, setAllSlacks] = useState([]);
  const [slackIssues, setSlackIssues] = useState([]);
  const [issueDates, setIssueDates] = useState([]);
  const [showShiftSlackBySkill, setShowShiftSlackBySkill] = useState(false);
  const [shiftSwitch, setShiftSwitch] = useState(false);
  const [callIn, setCallIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editShift, setEditShift] = useState(false);
  const [shiftToEdit, setShiftToEdit] = useState();
  const [softRequest, setSoftRequest] = useState(false);
  const [softRequestIdToEdit, setSoftRequestIdToEdit] = useState();
  const [editSoftRequest, setEditSoftRequest] = useState(false);
  const [timeOffRequestIdToEdit, setTimeOffRequestIdToEdit] = useState();
  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const [editTimeOffRequest, setEditTimeOffRequest] = useState(false);
  const [censusDataForm, setCensusDataForm] = useState(false);
  const [openDownload, setopenDownload] = useState(null);
  const [toast, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showForceOptionToast, SetShowForceOptionToast] = useState(false);
  const [errorToast, setErrorToast] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const openD = Boolean(openDownload);
  const anchorId = openD ? "download-popover" : undefined;
  const [employeeResources, setEmployeeResources] = useState([]);
  const [addRequestPopoverAnchor, setAddRequestPopoverAnchor] = useState(null);
  const [officeFloatStatus, setOfficeFloatStatus] = useState();
  const [empFitness, SetempFitness] = useState("");
  const [allFitnesses, SetAllFitnesses] = useState([]);
  const [showSpinnerOnCalendar, SetShowSpinnerOnCalendar] = useState(false);
  const [selectedEmployeesToFilter, SetSelectedEmployeesToFilter] = useState(
    []
  );
  const [staffRequirementForm, setStaffRequirementForm] = useState(false);
  const [manualEventsToSave, SetManualEventsToSave] = useState([]);

  useEffect(() => {
    const getSlackRangeStart = format(selectedDate, "yyyy-MM-dd") + "T00:00:00";
    const getSlackRangeEnd = format(selectedDate, "yyyy-MM-dd") + "T11:59:59";
    getSlacks({
      variables: {
        rangeStart: getSlackRangeStart,
        rangeEnd: getSlackRangeEnd,
        office: parseInt(primaryUnit.id),
        issuesOnly: false,
      },
    });
  }, [selectedDate]);

  //scheduler algorithm calculates slacks and stores slack in Slacks table.

  //gets only dates where there are issues for the month calendar
  const [getSlackIssuesOnly] = useLazyQuery(INTERVAL_SLACKS, {
    onCompleted(d) {
      setSlackIssues(d.intervalSlacks);
      let issueDates = [];
      let datesWithSlackInfo = [];
      d.intervalSlacks.forEach((slack) => {
        const start = format(new Date(slack.interval.start), "MM/dd/yyyy");
        !issueDates.includes(start) && issueDates.push(start);
        !datesWithSlackInfo.includes(start) && datesWithSlackInfo.push(start);
      });
      setAllSlackDates(datesWithSlackInfo);
      setAllSlacks([...d.intervalSlacks]);
      setIssueDates(issueDates);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SLACK lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [getSlacks, { loading: slacksForDayLoading }] = useLazyQuery(
    INTERVAL_SLACKS,
    {
      onCompleted(data) {
        setSlackIssues(data.intervalSlacks);
        let datesWithSlackInfo = [];
        data.intervalSlacks.forEach((slack) => {
          const start = format(new Date(slack.interval.start), "MM/dd/yyyy");
          !datesWithSlackInfo.includes(start) && datesWithSlackInfo.push(start);
        });
        setAllSlackDates(datesWithSlackInfo);
        setAllSlacks(data.intervalSlacks);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_SLACK lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  useEffect(() => {
    const currentAndFutureSchedules = schedulePeriods.filter(
      (schedule) => new Date(schedule.end) > new Date()
    );

    currentAndFutureSchedules.sort(
      (a, b) =>
        new Date(`${a.start}T23:00:00`) - new Date(`${b.start}T23:00:00`)
    );
    if (currentAndFutureSchedules.length > 0) {
      const schedulesNotinManual = currentAndFutureSchedules;
      const rangeStart = format(selectedDate, "yyyy-MM-dd") + "T00:00:00";
      const rangeEnd =
        schedulesNotinManual.length > 0 &&
        schedulesNotinManual[schedulesNotinManual.length - 1].end + "T23:00:00";
      if (managerAccess && rangeEnd) {
        getSlackIssuesOnly({
          variables: {
            rangeStart: rangeStart,
            rangeEnd: rangeEnd,
            office: parseInt(primaryUnit.id),
            issuesOnly: true,
          },
        });
      }
    }
  }, []);

  //get schedule fitness for the logged in user
  useQuery(SCHEDULE_QUALITY_ANALYTICS, {
    variables: {
      employee: parseInt(user.id),
    },
    onCompleted(data) {
      let fitnessFotCurrentDate = data.scheduleQualityAnalytics.find(
        (e) =>
          e.scheduleStart <= format(new Date(selectedDate), "yyyy-MM-dd") &&
          e.scheduleEnd >= format(new Date(selectedDate), "yyyy-MM-dd")
      );
      fitnessFotCurrentDate && SetempFitness(fitnessFotCurrentDate.score);
      SetAllFitnesses(data.scheduleQualityAnalytics);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SCHEDULE_QUALITY_ANALYTICS query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    let fitnessFotCurrentDate = allFitnesses.find(
      (e) =>
        new Date(e.scheduleStart) <= new Date(selectedDate) &&
        new Date(e.scheduleEnd) >= new Date(selectedDate)
    );
    fitnessFotCurrentDate && SetempFitness(fitnessFotCurrentDate.score);
  }, [selectedDate]);

  const getFloatDetails = useQuery(GET_FLOAT_DETAILS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_FLOAT_DETAILS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  //set field to determine of office accepts floats
  useEffect(() => {
    if (getFloatDetails.data) {
      let officeDetails = getFloatDetails.data.offices.find(
        (office) => office.id === user.office.id
      );
      if (officeDetails) {
        setOfficeFloatStatus(officeDetails.floatStatus);
      }
    }
  }, [getFloatDetails.data]);

  // if new data is added on calendar or if user goes to the next month where we have not yet retrieved the events
  const addDataToCurrentScheduleData = (data) => {
    let currentScheduleData = { ...scheduleData };
    let userShifts = data.userShifts &&
      data.userShifts.length > 0 && [...data.userShifts];
    let shiftIds = currentScheduleData.formatted.map(
      (event) => event.idOfShift
    );
    let userShiftIds = userShifts && userShifts.map((shift) => shift.shift.id);
    let newEvents;

    newEvents = data.sqlShiftAssignments2
      ? data.sqlShiftAssignments2.filter(
          (shift) => !shiftIds.includes(shift.id)
        )
      : data.filter((shift) => !shiftIds.includes(shift.id));

    const newIds = newEvents.map((event) => event.id);

    shiftIds = [...shiftIds, ...newIds];

    const newData = orderShifts(newEvents);

    currentScheduleData.formatted = [
      ...currentScheduleData.formatted,
      ...newData.formatted,
    ];
    currentScheduleData.formatted.sort((a, b) => a.start - b.start);

    const newShiftNames = newData.shiftNames.filter(
      (shift) =>
        !currentScheduleData.shiftNames.find(
          (e) => e.title === shift.title && e.office === shift.office
        )
    );

    currentScheduleData.shiftNames = [
      ...currentScheduleData.shiftNames,
      ...newShiftNames,
    ];
    currentScheduleData.shiftNames.sort(function (a, b) {
      if (a.description < b.description) {
        return -1;
      }
      if (a.description > b.description) {
        return 1;
      }
      return 0;
    });

    const newUserShifts =
      data.userShifts &&
      data.userShifts.filter((shift) => !userShiftIds.includes(shift.shift.id));
    const newUserShiftIds =
      newUserShifts && newUserShifts.map((shift) => shift.shift.id);

    userShifts = userShifts && [...userShifts, ...newUserShifts];
    userShiftIds = userShiftIds && [...userShiftIds, ...newUserShiftIds];

    currentScheduleData.userShifts = userShifts;
    return currentScheduleData;
  };

  //resource data for employee timeline
  const addEmployeeData = (data) => {
    let uniqueEmployees = [];
    const resources = data
      .map((employee) => {
        if (!uniqueEmployees.includes(employee.id)) {
          uniqueEmployees.push(employee.id);
          if (
            (employee.assignmentSet &&
              employee.assignmentSet.length > 0 &&
              !employee.assignmentSet.find(
                (x) =>
                  x.endDate &&
                  x.endDate != null &&
                  new Date(x.endDate) >= new Date() &&
                  x.office.id === user.office.id
              )) ||
            !employee.assignmentSet.find((x) => x.office.id === user.office.id)
          ) {
            if (parseInt(employee.id) === parseInt(user.id)) {
              return {
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
                color: "#F5E1DB",
                selectedEmployeeColor: "#F5E1DB",
                skills:
                  employee.skills &&
                  employee.skills.length > 0 &&
                  employee.skills
                    .filter(
                      (x) =>
                        x.variety === "JOB_TYPE" || x.variety === "TRAINING"
                    )
                    .map((e) => e.name),
              };
            } else {
              return {
                id: employee.id,
                name: `${employee.firstName} ${employee.lastName}`,
                color: "#DFE0E2",
                selectedEmployeeColor: "#FFC0CB",
                skills:
                  employee.skills &&
                  employee.skills.length > 0 &&
                  employee.skills
                    .filter(
                      (x) =>
                        x.variety === "JOB_TYPE" || x.variety === "TRAINING"
                    )
                    .map((e) => e.name),
              };
            }
          }
        }
      })
      .filter(Boolean);
    const newResources = [...employeeResources, ...resources];
    setEmployeeResources(newResources);
  };
  //query to get office schedule status
  const {
    data: officeScheduleStatus,
    startPolling,
    stopPolling,
  } = useQuery(GET_OFFICE_SCHEDULE_STATUS, {
    variables: {
      id: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE_SCHEDULE_STATUS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (forceOptionsAlgorithm.startPolling === true) {
      setTimeout(() => {
        startPolling(500);
      }, 1000);
    }
  }, [forceOptionsAlgorithm.startPolling]);

  const [rescheduleSwapOptions] = useLazyQuery(RESCHEDULE_SWAP_OPTIONS, {
    onCompleted() {
      SetShowForceOptionToast(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  //Start polling and check if office schedule status is complete
  useEffect(() => {
    if (
      officeScheduleStatus &&
      (officeScheduleStatus.offices[0].schedulingStatus != "READING" ||
        officeScheduleStatus.offices[0].schedulingStatus != "ERROR")
    ) {
      if (forceOptionsAlgorithm.selectedShiftAssignment != "") {
        rescheduleSwapOptions({
          variables: {
            shiftAssignments: parseInt(
              forceOptionsAlgorithm.selectedShiftAssignment
            ),
          },
        });
      }
      forceOptionsExecution({ startPolling: false });
      stopPolling();
    }
  }, [
    forceOptionsAlgorithm.startPolling === true &&
      forceOptionsAlgorithm.selectedShiftAssignment != "",
  ]);

  useEffect(() => {
    stopPolling();
    forceOptionsExecution({
      complete: false,
      selectedShiftAssignment: "",
      startPolling: false,
    });
    return () => {
      stopPolling();
    };
  }, [stopPolling]);

  useEffect(() => {
    if (futureData) {
      let newShiftData = [...shiftdata, ...futureData.sqlShiftAssignments2];
      SetShiftData(newShiftData);
      const newScheduleData = addDataToCurrentScheduleData(futureData);
      setScheduleData(newScheduleData);
      let timeOffIds = allDateTimeOffs.map((event) => event.eventId);
      let timeOffs = futureData.timeOff.filter(
        (timeOff) =>
          timeOff.deniedText === null &&
          timeOff.userCancelled === false &&
          !timeOffIds.includes(timeOff.id)
      );
      const newTimeOffs = formatTimeOff(timeOffs, user.id);
      const allTimeOffEvents = [...allDateTimeOffs, ...newTimeOffs];
      SetAllDateTimeOffs(allTimeOffEvents);
      //logged in users soft requests
      let softRequestIds = allDateSoftRequests.map((event) => event.eventId);
      let softReq = futureData.softRequests.filter(
        (e) => !softRequestIds.includes(e.id)
      );
      const newSoftRequests = formatSoftRequests(softReq, user.id);
      const userSoftRequests = [...allDateSoftRequests, ...newSoftRequests];
      SetAllDateSoftRequests(userSoftRequests);
    }
  }, [futureData]);

  useEffect(() => {
    if (pastData) {
      let newShiftData = [...shiftdata, ...pastData.sqlShiftAssignments2];
      SetShiftData(newShiftData);
      const newScheduleData = addDataToCurrentScheduleData(pastData);
      setScheduleData(newScheduleData);
      let timeOffIds = allDateTimeOffs.map((event) => event.eventId);
      let timeOffs = pastData.timeOff.filter(
        (timeOff) =>
          timeOff.deniedText === null &&
          timeOff.userCancelled === false &&
          !timeOffIds.includes(timeOff.id)
      );
      const newTimeOffs = formatTimeOff(timeOffs, user.id);
      const allTimeOffEvents = [...allDateTimeOffs, ...newTimeOffs];
      SetAllDateTimeOffs(allTimeOffEvents);
      //logged in users soft requests
      let softRequestIds = allDateSoftRequests.map((event) => event.eventId);
      let softReq = pastData.softRequests.filter(
        (e) => !softRequestIds.includes(e.id)
      );
      const newSoftRequests = formatSoftRequests(softReq, user.id);
      const userSoftRequests = [...allDateSoftRequests, ...newSoftRequests];
      SetAllDateSoftRequests(userSoftRequests);
    }
  }, [pastData]);

  useEffect(() => {
    if (floatData) {
      const floatOffice = floatData.offices.find(
        (office) => office.floatStatus === true
      );
      if (floatOffice) {
        getFloatEmployees({ variables: { office: parseInt(floatOffice.id) } });
      }
    }
  }, [floatData]);

  useEffect(() => {
    if (floatEmployeeData) {
      addEmployeeData(floatEmployeeData.offices[0].employeeSet);
    }
  }, [floatEmployeeData]);

  useEffect(() => {
    const availabilityTypes = sortedAvailabilityTypes.map((type) => type.name);
    //if coming from create schedule screen will have a date for new schedule
    if (params.date) {
      const showDate = new Date(params.date);
      selectedDateVar(showDate);
      refetchSchedulePeriods();
      if (managerAccess) {
        //filter options for managers
        filterListVar({
          allFilters: ["Personal", "All Shift Type", "All Job Type"],
          shiftsFilters: ["Personal"],
          shiftTypeFilters: ["All Shift Type"],
          requestsFilters: [],
          jobTypeFilters: ["All Job Type"],
        });
      } else {
        //filter options for employees
        filterListVar({
          allFilters: [
            "Personal",
            "All Shift Type",
            "Soft Requests",
            "Pending",
            "Approved",
            ...availabilityTypes,
            "All Requests",
            "All Job Type",
          ],
          shiftsFilters: ["Personal"],
          shiftTypeFilters: ["All Shift Type"],
          requestsFilters: [
            "Soft Requests",
            "Pending",
            "Approved",
            ...availabilityTypes,
            "All Requests",
          ],
          jobTypeFilters: ["All Job Type"],
        });
      }
      let rangeStart = params.date;
      let rangeEnd = add(params.date, { months: 2 });
      const futureVariables = getVariables(rangeStart, rangeEnd);
      getFutureShifts({
        variables: futureVariables,
      });
    }
  }, []);

  const [createManualShiftAssignments] = useMutation(
    CREATE_MANUAL_SHIFT_ASSIGNMENTS,
    {
      onCompleted(d) {
        let newScheduleData = addDataToCurrentScheduleData(
          d.createManualShiftAssignments.assignments
        ); //get shifts in the format required by syncfusion calendar
        newScheduleData = { ...scheduleData, ...newScheduleData };
        setScheduleData(newScheduleData);
        ManualEventsToSave([]);
        setToast("Saved Manual Events with date");
        setShowToast(true);
      },
      onError(e) {
        console.log(e);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "Manual shifts could not be saved. Please refresh and try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on CREATE_MANUAL_SHIFT_ASSIGNMENTS mutation. Environment: " +
              environment +
              ". Graphql " +
              e,
          },
        });
      },
    }
  );

  //different popup windows that show and hide for shift switch, call in, time off, soft request...
  const toggleShiftSwitchForm = () => setShiftSwitch(!shiftSwitch);
  const toggleCallInForm = () => setCallIn(!callIn);
  const toggleEditShift = () => setEditShift(!editShift);
  const toggleSoftRequest = () => setSoftRequest(!softRequest);
  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);
  const toggleEditTimeOffForm = () =>
    setEditTimeOffRequest(!editTimeOffRequest);
  const toggleEditSoftRequestForm = () => setEditSoftRequest(!editSoftRequest);
  const toggleCensusDataForm = () => setCensusDataForm(!censusDataForm);
  const toggleStaffRequirementForm = () =>
    setStaffRequirementForm(!staffRequirementForm);

  const handleEditClick = (shift) => {
    setShiftToEdit(shift);
    toggleEditShift();
  };

  //toast messages after successful mutation complete
  const toastMessage = () => {
    const toasts = {
      "Employee Shift Switch": "Shift switch request sent!",
      "Manager Shift Switch": "Shift switch successful! Employees notified.",
      "New Time Off Request": "Time off request sent for approval!",
      "Edit Time Off Request":
        "Time off request updated and sent for approval!",
      "Delete Time Off Request": "Time off request deleted!",
      "New Soft Request": "Soft request entered successfully!",
      "Edit Soft Request": "Soft request updated successfully!",
      "Delete Soft Request": "Soft request deleted!",
      "Call In": "Call in successful!",
      "Manager Edit Shift": "Shift updated and employees notified!",
      "Manager Approve Time Off Request": "Time off request approved!",
      "Manager Deny Time Off Request": "Time off request denied!",
      "Manager Approve Shift Switch": "Shift switch request approved!",
      "Manager Deny Shift Switch": "Shift switch request denied!",
      "Float Request": "Float request sent to float manager!",
      "Created Skeleton":
        "Successfully created shifts until the requested date",
      "Saved Manual Events": "Successfully saved shifts that were created",
      "Saved Manual Events with date": openEnd
        ? "Successfully saved manual shifts for selected dates until: " +
          format(new Date(openEnd), "MMM-dd-yyyy")
        : "Successfully saved manual shifts",
    };
    return toasts[toast] ? toasts[toast] : "Success!";
  };
  //error toasts
  const errorToastMessage = () => {
    const errorToasts = {
      "Non Eligible Shift Switch": "No eligible shifts to switch.",
      "Error Edit Shift": "Unable to edit shift. Please try again.",
      "Error Call In": "Unable to call in. Please try again.",
      "Error Approve Time Off":
        "Unable to approve time off request. Please try again.",
      "Error Deny Time Off":
        "Unable to deny time off request. Please try again.",
      "Error Approve Shift Switch":
        "Unable to approve shift switch request. Please try again.",
      "Error Deny Shift Switch":
        "Unable to deny shift switch request. Please try again.",
      "Error Delete Soft Request":
        "Unable to delete soft request. Please try again.",
      "Error Delete Time Off":
        "Unable to delete time off request. Please try again.",
      "No Manual Scheduling":
        "Manual scheduling disabled for the requested date.",
      "Some Shifts Could not be saved.":
        "Some Shifts Could not be saved. Please check dates and try again.",
      "Employee not qualified to fill the selected skill":
        "Employee not qualified to fill the selected skill",
      "End date for recurring event":
        "Please select an end date for the recurring event",
    };
    return errorToasts[errorToast]
      ? errorToasts[errorToast]
      : "Error. Please try again.";
  };

  if (loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return (
      <Grid container direction="row" spacing={4} style={{ marginTop: 20 }}>
        <Grid item>
          <Scheduler
            events={[]}
            employeeResources={[]}
            shiftResources={[]}
            issueDates={[]}
            slackIssues={[]}
            handleEditClick={handleEditClick}
            ref={ref}
          />
        </Grid>
      </Grid>
    );
  } else {
    const events = scheduleData.formatted;
    // formatted logged in user events;
    const userEvents = events.filter(
      (event) =>
        (event.employeeIds && event.employeeIds.includes(user.id)) ||
        (event.participant && event.participant.employeeId === user.id)
    );

    const userTimeOff = allDateTimeOffs.filter(
      (event) => event.category === "personal"
    );

    const shiftNames = scheduleData.shiftNames
      ? scheduleData.shiftNames.map((e) => e.title + "," + e.office)
      : [];
    //resource data for syncfusion shift timeline
    let shiftResources = scheduleData.shiftNames.map((name) => {
      return {
        id: name.title + " " + name.office,
        name: name.title,
        office: name.office,
        startHour: name.startHour,
        endHour: name.endHour,
        description: name.description,
        displayOrder: name.displayOrder,
      };
    });
    shiftResources.sort(
      (a, b) => parseInt(a.displayOrder) - parseInt(b.displayOrder)
    );
    shiftResources = [
      ...shiftResources,
      {
        id: "Requests",
        name: "Requests",
      },
    ];
    let officeResources = user.floatOffice
      ? allOffices &&
        allOffices.map((office) => {
          return {
            id: office.name,
            name: office.name,
          };
        })
      : allOffices &&
        allOffices
          .filter(
            (e) =>
              (user.isPrimaryParentOffice === true &&
                e.parent != null &&
                e.parent.id === user.office.id) ||
              (user.isPrimaryParentOffice === false && e.id === user.office.id)
          )
          .map((office) => {
            return {
              id: office.name,
              name: office.name,
              displayOrder:
                officeDisplayOrder.offices.find(
                  (e) => e.name === office.name
                ) &&
                officeDisplayOrder.offices.find((e) => e.name === office.name)
                  .displayPriority,
            };
          });

    let invalidDates = [];
    allDateSoftRequests.forEach((request) => {
      invalidDates.push(request.start);
    });

    userTimeOff.forEach((request) => {
      const arrayOfTimeOff = eachDayOfInterval({
        start: request.start,
        end: request.end,
      });
      invalidDates = [...invalidDates, ...arrayOfTimeOff];
    });

    invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

    //max number of soft requests allowed in this office to provide a message if user exceeds this number
    const maxGreyout = data.officeInfo[0].maxGreyoutRequests;
    const allowCallIns = data.officeInfo[0] && data.officeInfo[0].allowCallIns;
    const schedulePeriodWeeks = data.officeInfo[0].scheduleDuration
      ? data.officeInfo[0].scheduleDuration
      : 4;

    //filtered events based on filter selection
    const eventsToView = () => {
      let eventsToView = [];
      let requests = [];
      if (allFilters) {
        if (allFilters.includes("All Shifts")) {
          //start with all events and filter down
          eventsToView = [...events];

          //filter requests based on selected types
          if (allFilters.includes("All Requests")) {
            requests = [...allDateSoftRequests, ...allDateTimeOffs];
          } else {
            if (allFilters.includes("Soft Requests")) {
              requests.push(allDateSoftRequests);
            }

            sortedAvailabilityTypes.forEach((type) => {
              if (filterList.requestsFilters.includes(type.name)) {
                let matchingTimeOffs = allDateTimeOffs.filter(
                  (timeOff) => timeOff.typeId === type.id
                );

                if (
                  allFilters.includes("Pending") &&
                  !allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => !timeOff.approvedby
                  );
                } else if (
                  !allFilters.includes("Pending") &&
                  allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => timeOff.approvedby
                  );
                }

                requests.push(matchingTimeOffs);
              }
            });

            requests = requests.flat();
          }
        } else if (allFilters.includes("Personal")) {
          //start with only personal events and filter down
          eventsToView = [...userEvents];

          //filter requests based on selected types
          if (allFilters.includes("All Requests")) {
            requests = [...allDateSoftRequests, ...userTimeOff];
          } else {
            if (allFilters.includes("Soft Requests")) {
              requests.push(allDateSoftRequests);
            }

            sortedAvailabilityTypes.forEach((type) => {
              if (filterList.requestsFilters.includes(type.name)) {
                let matchingTimeOffs = userTimeOff.filter(
                  (timeOff) => timeOff.typeId === type.id
                );

                if (
                  allFilters.includes("Pending") &&
                  !allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => !timeOff.approvedby
                  );
                } else if (
                  !allFilters.includes("Pending") &&
                  allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => timeOff.approvedby
                  );
                }

                requests.push(matchingTimeOffs);
              }
            });

            requests = requests.flat();
          }
        }

        //if filter list is NOT all shift types, we need to filter down to only selected shift types
        if (!allFilters.includes("All Shift Type")) {
          const filteredList = [];
          shiftNames &&
            shiftNames.forEach((shiftName) => {
              let ofc = shiftName.split(",")[1];
              let shiftTitle = shiftName.split(",")[0];
              if (allFilters.includes(shiftName)) {
                const filtered = eventsToView.filter(
                  (event) =>
                    (event.eventTitle === shiftTitle && event.office === ofc) ||
                    (event.shiftName === shiftTitle && event.office === ofc)
                );
                filteredList.push(filtered);
              }
            });
          eventsToView = filteredList.flat();
        }

        //if filter list is NOT all job types, we need to filter down to only selected job types
        if (!allFilters.includes("All Job Type")) {
          const filteredList = [];
          //get list of jobtypes that are selected in filter list
          const jobTypeNames = filterList.jobTypeFilters
            ? filterList.jobTypeFilters
            : [];

          eventsToView.forEach((event) => {
            let participantsWithJobType = [];
            if (event.participants) {
              //filter each event participants down to only those with selected job type
              participantsWithJobType = event.participants.filter(
                (participant) =>
                  participant.skillSet &&
                  participant.skillSet.find((skill) =>
                    jobTypeNames.includes(skill.name)
                  )
              );
              console.log(participantsWithJobType);

              if (jobTypeNames.includes("Float")) {
                const floats = event.participants.filter(
                  (participant) => participant.isFromFloatOffice
                );
                participantsWithJobType = [
                  ...participantsWithJobType,
                  ...floats,
                ];
              } else {
                const nonFloats = event.participants.filter(
                  (participant) => participant.isFromFloatOffice
                );
                participantsWithJobType = [
                  ...participantsWithJobType,
                  ...nonFloats,
                ];
              }
            } else if (event.participant) {
              const hasSkill = jobTypeNames.find(
                (skill) => event.skillNames && event.skillNames.includes(skill)
              );
              hasSkill && participantsWithJobType.push(event);

              if (jobTypeNames.includes("Float")) {
                const floats = event.participant.isFromFloatOffice;
                floats && participantsWithJobType.push(event);
              } else {
                const nonFloats = !event.participant.isFromFloatOffice;
                nonFloats && participantsWithJobType.push(event);
              }
            }
            //if event has participants with selected job type, push that event onto the events to view
            if (participantsWithJobType.length > 0) {
              if (event.participants) {
                filteredList.push({
                  ...event,
                  participants: participantsWithJobType,
                });
              } else if (event.participant) {
                if (
                  event.skillNames &&
                  event.skillNames.find((skill) => jobTypeNames.includes(skill))
                ) {
                  filteredList.push({
                    ...event,
                  });
                }
              }
            }
          });

          //reassign events to view to the list filtered by job type
          eventsToView = filteredList;
        }
      }

      if (selectedEmployeesToFilter.length > 0) {
        let selectedEmpEvents = selectedEmployeesToFilter
          .map((e) => {
            let eventsWithParticipants = eventsToView
              .filter(
                (event) =>
                  (event.participants &&
                    event.participants.find((x) => x.employeeId === e.id)) ||
                  (event.participant && event.participant.employeeId === e.id)
              )
              .flat();
            let eventsWithColor = eventsWithParticipants.map((x) => {
              return { ...x, color: e.selectedEmployeeColor };
            });
            return eventsWithColor;
          })
          .flat();
        let idsToSlice = selectedEmpEvents.map((e) =>
          e.participants ? e.eventId : e.participant.employeeId
        );
        eventsToView = eventsToView.filter((e) =>
          e.participants
            ? !idsToSlice.includes(e.eventId)
            : !idsToSlice.includes(e.participant.employeeId)
        );
        eventsToView = [...eventsToView, ...selectedEmpEvents];
        let selectedEmpRequests = selectedEmployeesToFilter
          .map((e) => {
            let selectedEmployeeTimeOffs = allDateTimeOffs.filter(
              (x) =>
                x.employeeIds &&
                x.employeeIds.length > 0 &&
                x.employeeIds[0] === e.id
            );
            selectedEmployeeTimeOffs = selectedEmployeeTimeOffs.map((x) => {
              return { ...x, color: e.selectedEmployeeColor };
            });

            return selectedEmployeeTimeOffs;
          })
          .flat();
        let requestsToSlice = selectedEmpRequests.map((e) => e.eventId);
        requests = requests.filter((e) => !requestsToSlice.includes(e.eventId));
        requests = [...requests, ...selectedEmpRequests];
      }

      if (view === "ShiftTimeline") {
        eventsToView = eventsToView.filter(
          (event) => event.calendars && event.calendars.includes("shift")
        );
      } else if (view === "Day" || view === "EmployeeTimeline") {
        eventsToView = eventsToView.filter((event) => !event.calendars);
      }

      return [...eventsToView, ...requests];
    };

    const PrintIconClick = () => {
      const scheduleObj =
        document.querySelector(".e-schedule").ej2_instances[0];
      scheduleObj.print();
    };

    const ExportToICS = () => {
      const scheduleObj =
        document.querySelector(".e-schedule").ej2_instances[0];
      scheduleObj.exportToICalendar();
    };

    //hide the filter badge if all events are currently being viewed
    const hideFilterBadge = Boolean(
      allFilters.includes("Personal") &&
        allFilters.includes("All Shift Type") &&
        allFilters.includes("All Requests") &&
        allFilters.includes("All Job Type")
    );

    const toggleEditRequest = (type, id) => {
      if (type === "softRequest") {
        const matchingRequest = allDateSoftRequests.find(
          (request) => parseInt(request.eventId) === parseInt(id)
        );
        if (matchingRequest) {
          setSoftRequestIdToEdit(matchingRequest.eventId);
          setEditSoftRequest(true);
        }
      } else {
        const matchingRequest = managerAccess
          ? allDateTimeOffs.find(
              (request) => parseInt(request.eventId) === parseInt(id)
            )
          : userTimeOff.find(
              (request) => parseInt(request.eventId) === parseInt(id)
            );
        if (matchingRequest) {
          setTimeOffRequestIdToEdit(matchingRequest.eventId);
          setEditTimeOffRequest(true);
        }
      }
    };

    const filterByEmployees = (e) => {
      SetSelectedEmployeesToFilter(e);
    };

    const environment = process.env.NODE_ENV;
    let backend = "";
    if (environment === "development") {
      backend = "https://backendtest.balancescheduler.com";
    } else {
      backend = "https://backenddemo.balancescheduler.com";
    }
    return (
      <>
        {/* <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h3" style={{ marginTop: 15 }}>
              Schedule
            </Typography>
          </Grid>
        </Grid> */}

        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.headerSpacing}
        >
          <Grid item xs={2}>
            {view === "MonthAgenda" ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowShiftSlackBySkill(true);
                }}
                style={{ width: 178, marginBottom: 8 }}
                disabled={
                  !allSlackDates.includes(format(selectedDate, "MM/dd/yyyy"))
                }
              >
                {slacksForDayLoading ? (
                  <CircularProgress size={20} color="primary" />
                ) : (
                  "View Staffing"
                )}
              </Button>
            ) : employeesLoading || childOfficeEmployeesLoading ? (
              <CircularProgress color="primary" />
            ) : (
              <MultiSelect
                options={employeeResources}
                name="employeesToSelect"
                id="employeesToSelect"
                placeholder="Filter by Employees"
                onChange={filterByEmployees}
                val={selectedEmployeesToFilter}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
              />
            )}
          </Grid>
          {user.floatOffice && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleStaffRequirementForm}
              >
                Add Staff Requirement
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="outlined"
              color={"primary"}
              onClick={toggleTimeOffForm}
            >
              Add Calendar Event
            </Button>
          </Grid>
          {user.isSchedulable === true && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleSoftRequest}
              >
                Add Soft Request
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              color="secondary"
              aria-describedby={anchorId}
              onClick={(event) => setopenDownload(event.currentTarget)}
            >
              <GetAppIcon style={{ marginRight: 5 }} /> Download
            </Button>
          </Grid>
          <Grid>
            <Popover
              id={anchorId}
              anchorEl={openDownload}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={openD}
              onClose={() => setopenDownload(null)}
              style={{ overflow: "auto" }}
            >
              {/* <MenuItem
                    color="secondary"
                    target="_blank"
                    filename="schedule.csv"
                    onClick={ExportToExcel}
                    className={classes.downloadPopover}
                    style={{ marginBottom: -8 }}
                  >
                    Export to CSV File
                  </MenuItem> 
                  <br /> */}
              <MenuItem
                color="secondary"
                target="_blank"
                // href={`${backend}/scheduler/employee_calendar/${parseInt(
                //   user.id
                // )}`}
                onClick={ExportToICS}
                className={classes.downloadPopover}
                style={{ marginTop: -8 }}
              >
                Export to my Calendar
              </MenuItem>
            </Popover>
          </Grid>
          <Grid item>
            <Button id="print" color="secondary" onClick={PrintIconClick}>
              <PrintIcon style={{ marginRight: 5 }} /> Print
            </Button>
          </Grid>
          <Grid item>
            {" "}
            <Button
              color="secondary"
              style={{ padding: 4, fontSize: 12 }}
              onClick={() => {
                window.location.reload();
                // let firstDayOfCurrentView = startOfMonth(
                //   new Date(selectedDate)
                // );
                // firstDayOfCurrentView = startOfWeek(firstDayOfCurrentView);
                // let lastDayOfCurrentView = endOfMonth(new Date(selectedDate));
                // lastDayOfCurrentView = endOfWeek(lastDayOfCurrentView);
                // lastDayOfCurrentView = add(lastDayOfCurrentView, { days: 1 });
                // let variables = {
                //   rangeStart: firstDayOfCurrentView.toISOString(),
                //   rangeEnd: lastDayOfCurrentView.toISOString(),
                //   office: parseInt(user.office.id),
                //   employeeId: parseInt(user.id),
                //   officeId: parseInt(user.office.id),
                //   startDate: format(firstDayOfCurrentView, "yyyy-MM-dd"),
                //   endDate: format(lastDayOfCurrentView, "yyyy-MM-dd"),
                // };
                // refetch(variables);
              }}
            >
              <RefreshIcon /> Refetch Events
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <Badge
                variant="dot"
                color="primary"
                style={{ marginRight: 5 }}
                invisible={hideFilterBadge}
              >
                <TuneIcon />
              </Badge>
              Filter
            </Button>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              style={{ overflow: "auto" }}
            >
              <Paper style={{ padding: 10 }}>
                <FilterMenu
                  jobTypes={jobTypes}
                  shiftNames={shiftNames}
                  availabilityTypes={sortedAvailabilityTypes}
                  officeFloatStatus={officeFloatStatus}
                />
              </Paper>
            </Popover>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <Typography variant="body2">
              {" "}
              {empFitness != ""
                ? "Your schedule quality for this schedule period: " +
                  parseFloat(empFitness).toFixed(2) * 100 +
                  "%"
                : empFitness}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {user.isManager || user.isAdmin ? (
              loading || futureDataLoading || pastDataLoading ? (
                <>
                  <Typography variant="body2">
                    <CircularProgress color="primary" />
                    Loading Shifts for Manual Scheule
                  </Typography>{" "}
                </>
              ) : (
                <Typography variant="body2">
                  Ready For Manual Scheduling
                </Typography>
              )
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>

        {isValid(openEnd) && (
          <Scheduler
            events={eventsToView()}
            view={view}
            setView={setView}
            employeeResources={employeeResources}
            employeeResourcesLoading={
              employeesLoading || childOfficeEmployeesLoading
            }
            shiftResources={shiftResources}
            officeResources={officeResources}
            handleEditClick={handleEditClick}
            toggleEditRequest={toggleEditRequest}
            toggleCallInForm={toggleCallInForm}
            toggleShiftSwitchForm={toggleShiftSwitchForm}
            issueDates={issueDates}
            slackIssues={slackIssues}
            draftStart={draftStart}
            draftEnd={draftEnd}
            allowCallIns={allowCallIns}
            ref={ref}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
            schedulePeriods={schedulePeriods}
            setToast={setToast}
            setShowToast={setShowToast}
            setErrorToast={setErrorToast}
            setShowErrorToast={setShowErrorToast}
            refetch={refetch}
            skills={jobTypes}
            openStart={openStart}
            openEnd={openEnd}
            showSpinnerOnCalendar={showSpinnerOnCalendar}
            SetShowSpinnerOnCalendar={SetShowSpinnerOnCalendar}
            manualEventsToSave={manualEventsToSave}
            SetManualEventsToSave={SetManualEventsToSave}
            createManualShiftAssignments={createManualShiftAssignments}
            getVariables={getVariables}
            getFutureShifts={getFutureShifts}
            getPastShifts={getPastShifts}
            OfficeConstraints={OfficeConstraints}
            shiftdata={shiftdata}
            setScheduleData={setScheduleData}
            scheduleData={scheduleData}
          />
        )}

        <Dialog open={shiftSwitch} fullWidth maxWidth="sm">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
              position: "relative",
            }}
          >
            <ShiftSwitchRequestForm
              allEvents={eventsToView()}
              userEvents={userEvents}
              closeDialog={toggleShiftSwitchForm}
              shiftNames={shiftNames}
              scheduleEndDate={scheduleEndDate}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
              selectedDate={selectedDate}
              getVariables={getVariables}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={callIn} fullWidth maxWidth="xs">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              height: 400,
              position: "relative",
            }}
          >
            <EmpCallInForm
              closeDialog={toggleCallInForm}
              userEvents={userEvents}
              setToast={setToast}
              setShowToast={setShowToast}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editShift} fullWidth maxWidth="md">
          <Grid
            container
            component={DialogContent}
            direction="column"
            wrap="nowrap"
            justifyContent="space-between"
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
            }}
          >
            {shiftToEdit ? (
              <MangEditShift
                shiftEvent={shiftToEdit}
                closeDialog={toggleEditShift}
                setToast={setToast}
                setShowToast={setShowToast}
                setErrorToast={setErrorToast}
                setShowErrorToast={setShowErrorToast}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
                getVariables={getVariables}
                selectedDate={selectedDate}
              />
            ) : null}
          </Grid>
        </Dialog>
        <Dialog open={softRequest} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            <SoftTimeOff
              closeDialog={toggleSoftRequest}
              scheduleEndDate={scheduleEndDate}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              maxGreyout={maxGreyout}
              schedulePeriodWeeks={schedulePeriodWeeks}
              softRequests={allDateSoftRequests}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editSoftRequest} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            {softRequestIdToEdit && (
              <EditSoftTimeOff
                closeDialog={toggleEditSoftRequestForm}
                scheduleEndDate={scheduleEndDate}
                invalidDates={invalidDates}
                setToast={setToast}
                setShowToast={setShowToast}
                maxGreyout={maxGreyout}
                schedulePeriodWeeks={schedulePeriodWeeks}
                softRequests={allDateSoftRequests}
                softRequestIdToEdit={softRequestIdToEdit}
                setSoftRequestIdToEdit={setSoftRequestIdToEdit}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={timeOffRequestForm} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
          >
            <TimeOffRequestForm
              closeDialog={toggleTimeOffForm}
              scheduleEndDate={scheduleEndDate}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              employees={employeeResources}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editTimeOffRequest} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
          >
            {timeOffRequestIdToEdit && (
              <EditTimeOffRequestForm
                closeDialog={toggleEditTimeOffForm}
                userSoft={allDateSoftRequests}
                setToast={setToast}
                setShowToast={setShowToast}
                userTimeOff={userTimeOff}
                timeOffRequestIdToEdit={timeOffRequestIdToEdit}
                setTimeOffRequestIdToEdit={setTimeOffRequestIdToEdit}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={censusDataForm} fullWidth maxWidth="sm">
          <DialogContent style={{ padding: 30, position: "relative" }}>
            <CensusDataForm
              closeDialog={toggleCensusDataForm}
              selectedDate={selectedDate}
            />
          </DialogContent>
        </Dialog>

        <Popover
          id="add-request"
          anchorEl={addRequestPopoverAnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(addRequestPopoverAnchor)}
          onClose={() => setAddRequestPopoverAnchor(null)}
        >
          <Paper style={{ padding: 16 }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <Grid item>
                <Typography variant="h5">Add Request</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close"
                  color="secondary"
                  size="small"
                  onClick={() => setAddRequestPopoverAnchor(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    toggleSoftRequest();
                    setAddRequestPopoverAnchor(null);
                  }}
                  style={{ width: 150 }}
                >
                  Soft Request
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    toggleTimeOffForm();
                    setAddRequestPopoverAnchor(null);
                  }}
                  style={{ width: 150 }}
                >
                  Time Off Request
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
        <Dialog open={staffRequirementForm} fullWidth maxWidth="md">
          <DialogContent
            style={{ padding: 30, height: 600, position: "relative" }}
          >
            <StaffRequirementForm
              closeDialog={toggleStaffRequirementForm}
              selectedDate={selectedDate}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={showShiftSlackBySkill} maxWidth={false}>
          <DialogContent
            style={{ padding: 30, overflowX: "hidden", overflowY: "auto" }}
          >
            <ShiftSlackBySkill
              slacks={allSlacks}
              closeDialog={() => setShowShiftSlackBySkill(false)}
            />
          </DialogContent>
        </Dialog>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => {
            setShowToast(false);
            SetShowSpinnerOnCalendar(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => {
              setShowToast(false);
              SetShowSpinnerOnCalendar(false);
            }}
            severity="success"
          >
            <Typography>{toastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>{errorToastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showForceOptionToast}
          onClose={() => {
            forceOptionsExecution({
              complete: false,
              selectedShiftAssignment: "",
              startPolling: false,
            });
            SetShowForceOptionToast(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => {
              stopPolling();
              forceOptionsExecution({
                complete: false,
                selectedShiftAssignment: "",
                startPolling: false,
              });
              ManualEventsToSave([]);
              SetShowForceOptionToast(false);
            }}
            severity="success"
          >
            <Typography>
              Our algorithm has new options for you to edit shift based on your
              requirement.
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  forceOptionsExecution({
                    complete: false,
                    selectedShiftAssignment: "",
                    startPolling: false,
                  });
                  toggleShiftSwitchForm();
                  stopPolling();
                  SetShowForceOptionToast(false);
                }}
                size="small"
              >
                View Options
              </Button>
            </Typography>
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default EmpCalendar;
