import React, { useState, useEffect } from "react";
import {
  DashboardLayoutComponent,
  PanelsDirective,
  PanelDirective,
} from "@syncfusion/ej2-react-layouts";
import { useTheme } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  Box,
  TextField,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import {
  PUBLICLOCATION,
  NOTIFY_DEVELOPERS,
  FEEDBACK_EMAIL,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import { add, format } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";
import { useHistory } from "react-router-dom";
import "./DashboardLayout.css";
import { Rating } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import NextShiftDetails from "./NextShiftDetails";
import MonthOverview from "./MonthOverview";
import UpcomingTasks from "./UpcomingTasks";
import ScheduleInfo from "./ScheduleInfo";
import PreferenceFeedback from "./PreferenceFeedback";
import DashboardFooter from "./DashboardFooter";
import { Formik, Form } from "formik";

const useStyles = makeStyles(() => ({
  root: {},
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: "Very Satisfied",
  },
};

const IconContainer = (props) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

const EmployeeDashboard = (props) => {
  const classes = useStyles();
  const cellSpacing = [8, 8];
  const theme = useTheme();

  const user = userVar();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);

  const [emailSentMessage, SetEmailSentMEssage] = useState(false);

  const [feedbackEmail] = useMutation(FEEDBACK_EMAIL, {
    onCompleted() {
      SetEmailSentMEssage(true);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      SetEmailSentMEssage(false);
    }, 3000);
  }, [emailSentMessage === true]);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const locationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.log(error);
    },
  });

  if (locationSettings.loading) {
    return <CircularProgress color="primary" />;
  } else {
    const currentPrimaryColor = convertHexToRGBA(
      locationSettings.data.locationPublicSettings[0].hexcolor,
      20
    );
    const lighterPrimaryColor = convertHexToRGBA(
      locationSettings.data.locationPublicSettings[0].hexcolor,
      30
    );

    const handleEmailSend = (values) => {
      feedbackEmail({
        variables: {
          recipients: ["info@dualitysys.com"],
          subject: values.feedbackEmailSubject,
          body:
            "Issue Type" +
            ": " +
            values.feedbackEmailIssueType +
            ". " +
            "Message:" +
            values.feedbackEmailBody +
            ". Stress Level: " +
            +values.stressLevel +
            ". Schedule Satisfaction: " +
            values.scheduleSatisfaction,
        },
      });
    };

    const one = () => {
      return (
        <div>
          <span id="close" className="e-close-icon e-clear-icon" />
          <NextShiftDetails />
        </div>
      );
    };

    const two = () => {
      return (
        <div>
          <span id="close" className="e-close-icon e-clear-icon" />
          <UpcomingTasks
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
          />
        </div>
      );
    };

    const three = () => {
      return (
        <div>
          <span id="close" className="e-close-icon e-clear-icon" />
          <ScheduleInfo
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
          />
        </div>
      );
    };

    const four = () => {
      return (
        <div>
          <span id="close" className="e-close-icon e-clear-icon" />
          <MonthOverview />
        </div>
      );
    };

    const five = () => {
      return (
        <div>
          <span id="close" className="e-close-icon e-clear-icon" />
          <PreferenceFeedback
            lightBackground={lighterPrimaryColor}
            borderColor={theme.palette.primary.main}
          />
        </div>
      );
    };

    const six = () => {
      return (
        <div>
          <span id="close" className="e-close-icon e-clear-icon" />
          <div className="e-panel-container">
            <div>
              <div>
                <Box m={2}>
                  <Typography variant="h4">FEEDBACK</Typography>
                </Box>
              </div>
            </div>
            <Grid container>
              <Formik
                enableReinitialize
                initialValues={{
                  feedbackEmailSubject: "",
                  feedbackEmailIssueType: "",
                  feedbackEmailBody: "",
                  stressLevel: 0,
                  scheduleSatisfaction: 0,
                }}
                onSubmit={(values) => {
                  handleEmailSend(values);
                }}
              >
                {({ values, handleChange, dirty }) => (
                  <Form>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>
                        {emailSentMessage && "Feedback Successfully Sent!"}
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {" "}
                        <Typography variant="body1">
                          Feedback Category :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <TextField
                          variant="outlined"
                          name="feedbackEmailSubject"
                          placeholder="Enter Feedback Category"
                          style={{ background: "white" }}
                          value={values.feedbackEmailSubject}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {" "}
                        <Typography variant="body1">Issue Type :</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <TextField
                          variant="outlined"
                          name="feedbackEmailIssueType"
                          placeholder="Enter Issue Type"
                          value={values.feedbackEmailIssueType}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {" "}
                        <Typography variant="body1">Comments :</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <TextareaAutosize
                          variant="outlined"
                          name="feedbackEmailBody"
                          minRows={3}
                          value={values.feedbackEmailBody}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {" "}
                        <Typography variant="body1">Stress Level :</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Rating
                          name="stressLevel"
                          value={values.stressLevel}
                          onChange={handleChange}
                          IconContainerComponent={IconContainer}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        {" "}
                        <Typography variant="body1">
                          Schedule Satisfaction :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Rating
                          name="scheduleSatisfaction"
                          value={values.scheduleSatisfaction}
                          onChange={handleChange}
                          IconContainerComponent={IconContainer}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          disabled={!dirty}
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </div>
        </div>
      );
    };

    const mediaQuery = "max-width: 1000px";

    return (
      <div
        id="defaultTarget"
        className="control-section"
        style={{ marginTop: 20, textAlign: "center" }}
      >
        <DashboardLayoutComponent
          id="defaultLayout"
          columns={3}
          cellSpacing={cellSpacing}
          allowResizing={true}
          cellAspectRatio={100 / 80}
          mediaQuery={mediaQuery}
        >
          <PanelsDirective>
            <PanelDirective
              row={0}
              col={0}
              sizeX={1}
              sizeY={1}
              content={one}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={0}
              col={1}
              sizeX={1}
              sizeY={1}
              content={two}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={0}
              col={4}
              sizeX={1}
              sizeY={1}
              content={three}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={0}
              sizeX={1}
              sizeY={1}
              content={four}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={1}
              sizeX={1}
              sizeY={1}
              content={five}
              cssClass={classes.panelStyle}
            ></PanelDirective>
            <PanelDirective
              row={1}
              col={3}
              sizeX={1}
              sizeY={1}
              content={six}
              cssClass={classes.panelStyle}
            ></PanelDirective>
          </PanelsDirective>
        </DashboardLayoutComponent>
        <div>
          <DashboardFooter />
        </div>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </div>
    );
  }
};

export default EmployeeDashboard;
