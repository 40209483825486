import { format } from "date-fns";

const d = new Date();

export const CreateHospitalConstraint = (
  createContraints,
  values,
  typeid,
  office
) => {
  createContraints({
    variables: {
      input: {
        type: parseInt(typeid),
        value: parseInt(values),
        office: office ? parseInt(office) : null,
        effectstart: format(d, "yyyy-MM-dd"),
      },
    },
  });
};

export const CreateHourlyRequirementsConstraints = (
  createContraints,
  values,
  typeid,
  office,
  employeetype
) => {
  createContraints({
    variables: {
      input: {
        type: parseInt(typeid),
        value: parseInt(values),
        office: office ? parseInt(office) : null,
        employeetype: parseInt(employeetype),
        effectstart: format(d, "yyyy-MM-dd"),
      },
    },
  });
};

export const UpdateHospitalConstraint = (
  updateContraints,
  values,
  constraintid,
  type
) => {
  updateContraints({
    variables: {
      id: parseInt(constraintid),
      input: {
        type: parseInt(type),
        value: parseInt(values),
        effectstart: format(d, "yyyy-MM-dd"),
      },
    },
  });
};

export const findConstraint = (constraints, value) => {
  let found;
  if (constraints != null && constraints.length > 0) {
    constraints.forEach((e) => {
      if (e.name === value) {
        found = e;
      }
    });
  }

  return found;
};

export const findofficeConstraint = (constraints, value, office) => {
  let found;
  if (constraints != null && constraints.length > 0) {
    constraints.find((e) => {
      if (office != null) {
        if (e.type != null && e.type.name === value) {
          found = e;
        }
      } else {
        if (e.type != null && e.type.name === value && e.office === office) {
          found = e;
        }
      }
    });
  }
  return found;
};

export const findemployeeTypeOfficeConstraint = (constraints, value, type) => {
  let found;
  if (constraints != null && constraints.length > 0) {
    constraints.find((e) => {
      if (
        e.type.name === value &&
        e.employeetype != null &&
        e.employeetype.name === type
      ) {
        found = e;
      }
    });
  }
  return found;
};

export const getEmployeeTypeValues = (values, constraintSet) => {
  let employeeTypeVals = [
    {
      employeeType: "",
      requiredWeeklyHrs: "",
      maxWeeklyHrs: "",
      maxDaysOff: "",
      maxConsecutiveDays: "",
      maxHoursPerDay: "",
    },
  ];
  values &&
    values.length > 0 &&
    values.forEach((element) => {
      employeeTypeVals.push({
        id: element.id,
        employeeType: element.name,
        requiredWeeklyHrs: findemployeeTypeOfficeConstraint(
          constraintSet,
          "FTEMINHRWK",
          element.name
        )
          ? findemployeeTypeOfficeConstraint(
              constraintSet,
              "FTEMINHRWK",
              element.name
            ).value
          : 40,
        maxWeeklyHrs: findemployeeTypeOfficeConstraint(
          constraintSet,
          "FTEMAXHRWK",
          element.name
        )
          ? findemployeeTypeOfficeConstraint(
              constraintSet,
              "FTEMAXHRWK",
              element.name
            ).value
          : 40,
        maxDaysOff: findemployeeTypeOfficeConstraint(
          constraintSet,
          "MAXDAYSOFF",
          element.name
        )
          ? findemployeeTypeOfficeConstraint(
              constraintSet,
              "MAXDAYSOFF",
              element.name
            ).value
          : 2,
        maxConsecutiveDays: findemployeeTypeOfficeConstraint(
          constraintSet,
          "MAXDAYS",
          element.name
        )
          ? findemployeeTypeOfficeConstraint(
              constraintSet,
              "MAXDAYS",
              element.name
            ).value
          : 5,
        maxHoursPerDay: findemployeeTypeOfficeConstraint(
          constraintSet,
          "MAXDAILYHR",
          element.name
        )
          ? findemployeeTypeOfficeConstraint(
              constraintSet,
              "MAXDAILYHR",
              element.name
            ).value
          : 12,
      });
    });
  return employeeTypeVals.slice(1);
};

export const timezones = [
  "US_ALASKA",
  "US_ALEUTIAN",
  "US_ARIZONA",
  "US_CENTRAL",
  "US_EAST_INDIANA",
  "US_EASTERN",
  "US_HAWAII",
  "US_INDIANA_STARKE",
  "US_MICHIGAN",
  "US_MOUNTAIN",
  "US_PACIFIC",
  "US_SAMOA",
  "UTC",
];

export const getShifts = (values) => {
  let shifts = [{ description: "", shiftType: "", startTime: "", endTime: "" }];
  let shiftType = ["All Day"];
  values &&
    values.length > 0 &&
    values.forEach((element) => {
      if (!shiftType.includes(element.description)) {
        shifts.push({
          description: element.description,
          shiftType: element.type.type,
          startTime: element.start,
          endTime: element.end,
        });
        shiftType.push(element.description);
      }
    });
  return { shifts: shifts.slice(1), shiftTypes: shiftType };
};

export const skillsetforDaysofweek = [
  {
    effectStart: "",
    shiftType: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
    skill: "",
  },
];

export const getskillsetforDaysofweek = (values, getShifts, alljobtitles) => {
  let skillsetforDaysofweek = [
    {
      effectStart: "",
      shiftType: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
      skill: "",
    },
  ];
  alljobtitles &&
    alljobtitles.length > 0 &&
    alljobtitles.map((element) => {
      getShifts &&
        getShifts.length > 0 &&
        getShifts.map((e) => {
          let val = values && values.length>0 && values.find(
            (x) =>
              x.skill &&
              x.skill.name === element.name &&
              x.starttime === e.startTime &&
              x.endtime === e.endTime &&
              x.type.name === "SKILLCOVER" &&
              x.effectend === null
          );
          val &&
            skillsetforDaysofweek.push({
              effectStart: val.effectstart,
              shiftType: e.description,
              monday: val.monday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).monday,
              tuesday: val.tuesday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).tuesday,
              wednesday: val.wednesday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).wednesday,
              thursday: val.thursday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).thursday,
              friday: val.friday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).friday,
              saturday: val.saturday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).saturday,
              sunday: val.sunday
                ? val.value
                : getSkillsetConstraintVal(
                    values,
                    element.name,
                    e.startTime,
                    e.endTime
                  ).sunday,
              skill: element.name,
            });
        });
      let val = values && values.length>0 && values.find(
        (x) =>
          x.skill &&
          x.skill.name === element.name &&
          x.type.name === "SKILLCOVER" &&
          x.effectend === null &&
          x.endtime === null &&
          x.starttime === null
      );
      val &&
        skillsetforDaysofweek.push({
          effectStart: val.effectstart,
          shiftType: "All Day",
          monday: val.monday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null).monday,
          tuesday: val.tuesday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null)
                .tuesday,
          wednesday: val.wednesday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null)
                .wednesday,
          thursday: val.thursday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null)
                .thursday,
          friday: val.friday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null).friday,
          saturday: val.saturday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null)
                .saturday,
          sunday: val.sunday
            ? val.value
            : getSkillsetConstraintVal(values, element.name, null, null).sunday,
          skill: element.name,
        });
    });

  return skillsetforDaysofweek.slice(1);
};

export const saveskillsetforDaysofweek = (
  values,
  staffTable,
  office,
  allskills,
  batchUpdateConstraints,
  batchCreateConstraints,
  allconstrinttypes,
  getShifts,
  batchDeleteConstraints
) => {
  values &&
    values.length > 0 &&
    values.forEach((element) => {
      const reqskillid =
        allskills.length > 0 && allskills.find((i) => i.name === element.skill);
      const typeid = findConstraint(
        allconstrinttypes.constraintDefinitions,
        "SKILLCOVER"
      );

      // push all user entered unique weekday values to an array
      let allVals = [];
      !allVals.includes(parseInt(element.monday)) &&
        allVals.push(parseInt(element.monday));
      !allVals.includes(parseInt(element.tuesday)) &&
        allVals.push(parseInt(element.tuesday));
      !allVals.includes(parseInt(element.wednesday)) &&
        allVals.push(parseInt(element.wednesday));
      !allVals.includes(parseInt(element.thursday)) &&
        allVals.push(parseInt(element.thursday));
      !allVals.includes(parseInt(element.friday)) &&
        allVals.push(parseInt(element.friday));
      !allVals.includes(parseInt(element.saturday)) &&
        allVals.push(parseInt(element.saturday));
      !allVals.includes(parseInt(element.sunday)) &&
        allVals.push(parseInt(element.sunday));

      //push all existing constraint values to an array
      let existingVals = [];
      staffTable &&
        staffTable.length > 0 &&
        staffTable.forEach((x) => {
          if (!existingVals.includes(x.value) && !allVals.includes(x.value)) {
            existingVals.push(x.value);
          }
        });
      //delete old values that are present in the database and not present in user entered values
      var valuesToDelete = [];
      existingVals.forEach((a) => {
        let existing =
          staffTable.length > 0 &&
          staffTable.find(
            (x) =>
              x.value === a &&
              x.skill &&
              x.skill.name === element.skill &&
              (element.shiftType === "All Day"
                ? x.starttime === null
                : x.starttime ===
                  getShifts.find((e) => e.description === element.shiftType)
                    .startTime) &&
              (element.shiftType === "All Day"
                ? x.endtime === null
                : x.endtime ===
                  getShifts.find((e) => e.description === element.shiftType)
                    .endTime)
          );
        if (existing != null) {
          valuesToDelete.push(parseInt(existing.id));
        }
      });
      var valuesToUpdate = [];
      var valuesToCreate = [];
      //check if use entered values are present in the database. If yes update. If not create new constraints
      allVals.forEach((a) => {
        let existing =
          staffTable.length > 0 &&
          staffTable.find(
            (x) =>
              x.value === a &&
              x.skill &&
              x.skill.name === element.skill &&
              (element.shiftType === "All Day"
                ? x.starttime === null
                : x.starttime ===
                  getShifts.find((e) => e.description === element.shiftType)
                    .startTime) &&
              (element.shiftType === "All Day"
                ? x.endtime === null
                : x.endtime ===
                  getShifts.find((e) => e.description === element.shiftType)
                    .endTime)
          );

        if (existing) {
          valuesToUpdate.push({
            id: parseInt(existing.id),
            value: parseInt(existing.value),
            monday:
              parseInt(element.monday) === parseInt(existing.value)
                ? true
                : false,
            tuesday:
              parseInt(element.tuesday) === parseInt(existing.value)
                ? true
                : false,
            wednesday:
              parseInt(element.wednesday) === parseInt(existing.value)
                ? true
                : false,
            thursday:
              parseInt(element.thursday) === parseInt(existing.value)
                ? true
                : false,
            friday:
              parseInt(element.friday) === parseInt(existing.value)
                ? true
                : false,
            saturday:
              parseInt(element.saturday) === parseInt(existing.value)
                ? true
                : false,
            sunday:
              parseInt(element.sunday) === parseInt(existing.value)
                ? true
                : false,
            effectstart: element.effectStart,
            starttime:
              element.shiftType === "All Day"
                ? null
                : getShifts.find((e) => e.description === element.shiftType)
                    .startTime,
            endtime:
              element.shiftType === "All Day"
                ? null
                : getShifts.find((e) => e.description === element.shiftType)
                    .endTime,
          });
          allVals.slice(parseInt(existing.id));
        } else {
          valuesToCreate.push({
            value: parseInt(a),
            office: parseInt(office.id),
            monday: parseInt(element.monday) === parseInt(a) ? true : false,
            tuesday: parseInt(element.tuesday) === parseInt(a) ? true : false,
            wednesday:
              parseInt(element.wednesday) === parseInt(a) ? true : false,
            thursday: parseInt(element.thursday) === parseInt(a) ? true : false,
            friday: parseInt(element.friday) === parseInt(a) ? true : false,
            saturday: parseInt(element.saturday) === parseInt(a) ? true : false,
            sunday: parseInt(element.sunday) === parseInt(a) ? true : false,
            effectstart: format(new Date(), "yyyy-MM-dd"),
            skill: parseInt(reqskillid.id),
            type: parseInt(typeid.id),
            starttime:
              element.shiftType === "All Day"
                ? null
                : getShifts.find((e) => e.description === element.shiftType)
                    .startTime,
            endtime:
              element.shiftType === "All Day"
                ? null
                : getShifts.find((e) => e.description === element.shiftType)
                    .endTime,
          });
          allVals.slice(parseInt(a));
        }
      });
      batchCreateConstraints({
        variables: {
          input: valuesToCreate,
        },
      });
      batchUpdateConstraints({
        variables: {
          input: valuesToUpdate,
        },
      });
      console.log(valuesToDelete);
      batchDeleteConstraints({
        variables: {
          ids: valuesToDelete,
        },
      });
    });
};

export const getSkillsetConstraintVal = (values, skill, starttime, endtime) => {
  let mondayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.monday === true
    );
  let tuesdayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.tuesday === true
    );
  let wednesdayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.wednesday === true
    );
  let thursdayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.thursday === true
    );
  let fridayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.friday === true
    );
  let saturdayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.saturday === true
    );
  let sundayVal =
    values &&
    values.length > 0 &&
    values.find(
      (e) =>
        e.type.name === "SKILLCOVER" &&
        e.skill &&
        e.skill.name === skill &&
        e.starttime === starttime &&
        e.endtime === endtime &&
        e.sunday === true
    );
  return {
    monday: mondayVal ? mondayVal.value : 0,
    tuesday: tuesdayVal ? tuesdayVal.value : 0,
    wednesday: wednesdayVal ? wednesdayVal.value : 0,
    thursday: thursdayVal ? thursdayVal.value : 0,
    friday: fridayVal ? fridayVal.value : 0,
    saturday: saturdayVal ? saturdayVal.value : 0,
    sunday: sundayVal ? sundayVal.value : 0,
  };
};

export const handleDeleteSkillSetConstraints = (
  values,
  constraintSet,
  batchDeleteConstraints,
  getDailyShifts,
  setshowDialog
) => {
  let constrantExist =
    constraintSet.length > 0 &&
    constraintSet.filter(
      (i) =>
        i.skill &&
        i.skill.name === values.skill &&
        (values.shiftType === "All Day"
          ? i.starttime === null
          : i.starttime ===
            getDailyShifts.shifts.find(
              (e) => e.description === values.shiftType
            ).startTime) &&
        (values.shiftType === "All Day"
          ? i.starttime === null
          : i.endtime ===
            getDailyShifts.shifts.find(
              (e) => e.description === values.shiftType
            ).endTime)
    );
  var valuesToDelete = [];
  if (constrantExist) {
    constrantExist.map((e) => {
      valuesToDelete.push(parseInt(e.id));
    });
  }
  console.log(valuesToDelete);
  batchDeleteConstraints({
    variables: {
      ids: valuesToDelete,
    },
  });
  setshowDialog(false);
};
