import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  makeStyles,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EventParticipant from "../shiftBasedCalendar/EventParticipant";
import {
  selectEmployees,
  findEmployeesToSwitch,
} from "../../helpers/shiftSwitch";
import { add, sub, format, isSameDay } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_RESCHEDULE_OPTION,
  EXECUTE_OPTION,
  GET_SHIFT_ASSIGNMENT,
  RESCHEDULE_SWAP_OPTIONS,
  FORCE_OPTIONS_FOR_EMPLOYEE,
} from "../../api/gqlQueries";
import { userVar, forceOptionsExecution } from "../../cache";
import StarRateIcon from "@material-ui/icons/StarRate";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
  },
  shiftSelect: {
    width: 273,
  },
  select: {
    width: 273,
    height: 56,
  },
  error: {
    color: theme.palette.primary.main,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  icon: {
    padding: 0,
    marginRight: -9,
    marginBottom: -6,
    marginLeft: 2,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
}));

const MangShiftSwitchRequestForm = (props) => {
  const classes = useStyles();

  const {
    allEvents,
    shiftNames,
    closeDialog,
    date,
    scheduleEndDate,
    setToast,
    setShowToast,
    shiftSwitchBuffer,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
    selectedDate,
    getVariables,
  } = props;

  const currentUser = userVar();

  const allMonthEvents = allEvents;
  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  const [getFirstShiftAssignment] = useLazyQuery(GET_SHIFT_ASSIGNMENT, {
    onCompleted(data) {
      setRescheduleSwapAddOptions([]);
      if (data.shiftAssignments.length > 0) {
        forceOptionsExecution({
          selectedShiftAssignment: data.shiftAssignments[0].id,
        });

        rescheduleSwapOptions({
          variables: {
            shiftAssignments: parseInt(data.shiftAssignments[0].id),
          },
        });
        setError("");
      } else {
        setError("Please try selecting first date and employee again.");
      }
    },
    onError(error) {
      console.log(error);
      setError("Please try selecting first date and employee again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SHIFT_ASSIGNMENT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [algorithmOnly, setAlgorithmOnly] = useState(true);
  const [error, setError] = useState("");
  const [currentDateError, setCurrentDateError] = useState("");
  const [desiredDateError, setDesiredDateError] = useState("");
  const [shift, setShift] = useState("All Shifts");
  const [currentDate, setCurrentDate] = useState();
  const [desiredDate, setDesiredDate] = useState();
  const [currentDateShiftId, setCurrentDateShiftId] = useState("");
  const [desiredDateShiftId, setDesiredDateShiftId] = useState("");
  const [currentDateSelectedEmployee, setCurrentDateSelectedEmployee] =
    useState("");
  const [desiredDateSelectedEmployee, setDesiredDateSelectedEmployee] =
    useState("");

  const [currentDateEmployees, setCurrentDateEmployees] = useState([]);
  const [filteredCurrentDateEmployees, setFilteredCurrentDateEmployees] =
    useState([]);
  const [desiredDateEmployees, setDesiredDateEmployees] = useState([]);
  const [eligibleEmployees, setEligibleEmployees] = useState([]);

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState({});

  const [rescheduleSwapOptions, { refetch }] = useLazyQuery(
    RESCHEDULE_SWAP_OPTIONS,
    {
      onCompleted(data) {
        refetch();
        if (data.rescheduleSwaps.length > 0) {
          const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
            const past =
              swap.rescheduleindividualSet[0].rescheduleactionSet.find(
                (action) => new Date(action.shift.start) < minimumDate
              );

            return swap.status === "OPTION" && !past;
          });

          const options = swapsWithOptionStatus
            .map((option) => {
              const employeeNotCurrentSelected =
                option.rescheduleindividualSet.find(
                  (individual) =>
                    parseInt(individual.employee.id) !==
                    parseInt(currentDateSelectedEmployee)
                );
              return {
                ...employeeNotCurrentSelected,
                optionId: option.id,
              };
            })
            .filter((option) => option.rescheduleactionSet);
          setRescheduleSwapAddOptions(options);
        } else {
          setRescheduleSwapAddOptions([]);
        }
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [executeOption] = useMutation(EXECUTE_OPTION, {
    onCompleted(data) {
      console.log(data);
      refetch();
      setError("");
      setToast("Manager Shift Switch");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError(
        "Unable to create shift switch request. Please check details and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EXECUTE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      refetch();
      console.log(data);
      setError("");
      setToast("Manager Shift Switch");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError(
        "Unable to create shift switch request. Please check details and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [forceOptions] = useMutation(FORCE_OPTIONS_FOR_EMPLOYEE, {
    onCompleted(data) {
      if (data.forceOption.triggered) {
        getFirstShiftAssignment({
          variables: {
            employeeId: parseInt(currentDateSelectedEmployee),
            shiftId: parseInt(currentDateShiftId),
          },
        });
        forceOptionsExecution({ startPolling: true });
        closeDialog();
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FORCE_OPTIONS_FOR_EMPLOYEE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    let dateToView =
      date && new Date(date) > minimumDate ? new Date(date) : minimumDate;

    let firstDate;
    let secondDate;
    if (isSameDay(dateToView, new Date(scheduleEndDate))) {
      //prevent error if user wants a switch on the last day of
      //the schedule by setting the second date to a previous date
      firstDate = sub(dateToView, { days: 1 });
    } else {
      secondDate = add(dateToView, { days: 1 });
    }

    let currentEmployees;
    let desiredEmployees;
    if (firstDate) {
      setCurrentDate(firstDate);
      setDesiredDate(dateToView);
      currentEmployees = selectEmployees(
        allMonthEvents,
        shift,
        firstDate,
        setError
      );
      console.log(currentEmployees);
      desiredEmployees = selectEmployees(
        allMonthEvents,
        shift,
        dateToView,
        setError
      );
    } else {
      setCurrentDate(dateToView);
      setDesiredDate(secondDate);
      desiredEmployees = selectEmployees(
        allMonthEvents,
        shift,
        secondDate,
        setError
      );
      currentEmployees = selectEmployees(
        allMonthEvents,
        shift,
        dateToView,
        setError
      );
    }

    if (currentEmployees && desiredEmployees) {
      const newEligible = findEmployeesToSwitch(
        currentEmployees,
        desiredEmployees
      );
      const filteredCurrentEmployees = findEmployeesToSwitch(
        desiredEmployees,
        currentEmployees
      );

      setCurrentDateEmployees(currentEmployees);
      setFilteredCurrentDateEmployees(filteredCurrentEmployees);
      setDesiredDateEmployees(desiredEmployees);
      setEligibleEmployees(newEligible);
    }
  }, []);

  const handleCurrentDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      setCurrentDate(date);
      setCurrentDateError("");
      setCurrentDateSelectedEmployee("");
      setDesiredDateSelectedEmployee("");
      const newCurrentEmployees = selectEmployees(
        allMonthEvents,
        shift,
        date,
        setError
      );

      if (newCurrentEmployees) {
        const newEligible = findEmployeesToSwitch(
          newCurrentEmployees,
          desiredDateEmployees
        );
        const filteredCurrentEmployees = findEmployeesToSwitch(
          desiredDateEmployees,
          newCurrentEmployees
        );

        setCurrentDateEmployees(newCurrentEmployees);
        setFilteredCurrentDateEmployees(filteredCurrentEmployees);
        setEligibleEmployees(newEligible);
      }
    } else {
      setCurrentDateError("Invalid date format");
    }
  };

  const handleDesiredDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      setDesiredDate(date);
      setDesiredDateError("");
      setCurrentDateSelectedEmployee("");
      setDesiredDateSelectedEmployee("");
      const newDesiredEmployees = selectEmployees(
        allMonthEvents,
        shift,
        date,
        setError
      );
      const newCurrentEmployees = selectEmployees(
        allMonthEvents,
        shift,
        currentDate,
        setError
      );
      if (newDesiredEmployees && newCurrentEmployees) {
        setDesiredDateEmployees(newDesiredEmployees);
        const filteredCurrentEmployees = findEmployeesToSwitch(
          newDesiredEmployees,
          newCurrentEmployees
        );
        const newEligible = findEmployeesToSwitch(
          newCurrentEmployees,
          newDesiredEmployees
        );

        setCurrentDateEmployees(newCurrentEmployees);
        setFilteredCurrentDateEmployees(filteredCurrentEmployees);
        setEligibleEmployees(newEligible);
      }
    } else {
      setDesiredDateError("Invalid date format");
    }
  };

  const handleShiftChange = (event) => {
    const newShift = event.target.value;
    setShift(newShift);
    setCurrentDateSelectedEmployee("");
    setDesiredDateSelectedEmployee("");
    const newCurrentEmployees = selectEmployees(
      allMonthEvents,
      newShift,
      currentDate,
      setError
    );
    const newDesiredEmployees = selectEmployees(
      allMonthEvents,
      newShift,
      desiredDate,
      setError
    );

    if (newCurrentEmployees && newDesiredEmployees) {
      setDesiredDateEmployees(newDesiredEmployees);

      const newEligibles = findEmployeesToSwitch(
        newCurrentEmployees,
        newDesiredEmployees
      );
      const filteredCurrentEmployees = findEmployeesToSwitch(
        newDesiredEmployees,
        newCurrentEmployees
      );
      setCurrentDateEmployees(newCurrentEmployees);
      setFilteredCurrentDateEmployees(filteredCurrentEmployees);
      setEligibleEmployees(newEligibles);
    } else {
      setError("Invalid date or shift selection");
    }
  };

  const handleCurrentSelectedEmployeeChange = (e) => {
    if (e.target.value === "None") {
      setCurrentDateSelectedEmployee("None");
      return;
    } else {
      const employeeId = e.target.value;
      setDesiredDateSelectedEmployee("");
      const jobTypes =
        currentDateEmployees.find(
          (employee) => employee.employeeId === employeeId
        ) &&
        currentDateEmployees
          .find((employee) => employee.employeeId === employeeId)
          .skillSet.map((skill) => skill.name);

      const desiredWithMatchingJob = findEmployeesToSwitch(
        currentDateEmployees,
        desiredDateEmployees,
        jobTypes
      );
      setEligibleEmployees(desiredWithMatchingJob);

      const selectedShift = allMonthEvents.find((event) => {
        let participantIds;
        if (event.participants) {
          participantIds = event.participants.map(
            (participant) => participant.employeeId
          );
        } else if (event.participant) {
          participantIds = [event.participant.employeeId];
        }

        return (
          isSameDay(new Date(event.start), new Date(currentDate)) &&
          participantIds?.includes(employeeId)
        );
      });
      setCurrentDateSelectedEmployee(employeeId);
      setCurrentDateShiftId(selectedShift.eventId);
      getFirstShiftAssignment({
        variables: {
          employeeId: parseInt(employeeId),
          shiftId: parseInt(selectedShift.eventId),
        },
      });
    }
  };

  const handleDesiredSelectedEmployeeChange = (e) => {
    if (e.target.value === "None") {
      setDesiredDateSelectedEmployee("None");
      return;
    } else {
      if (algorithmOnly) {
        //path for desired date selected option change when
        //algorithm only is checked
        const optionId = e.target.value;
        const matchingOption = rescheduleSwapAddOptions.find(
          (option) => option.optionId === optionId
        );

        const dropAction = matchingOption
          ? matchingOption.rescheduleactionSet.find(
              (action) => action.actionType === "DROP"
            )
          : null;
        setDesiredDateSelectedEmployee(optionId);

        if (dropAction) {
          setDesiredDateShiftId(dropAction.shift.id);
          setDesiredDate(new Date(dropAction.shift.start));
          setError("");
        } else {
          setError(
            "We could not find that employee's assignment. " +
              "Please try your selections again."
          );
        }
      } else {
        //path for desired date selected employee change when
        //algorithm only is NOT checked
        const employeeId = e.target.value;

        const selectedShift = allMonthEvents.find((event) => {
          let participantIds;
          if (event.participants) {
            participantIds = event.participants.map(
              (participant) => participant.employeeId
            );
          } else if (event.participant) {
            participantIds = [event.participant.employeeId];
          }
          return (
            isSameDay(new Date(event.start), new Date(desiredDate)) &&
            participantIds?.includes(employeeId)
          );
        });

        if (selectedShift) {
          setDesiredDateSelectedEmployee(employeeId);
          setDesiredDateShiftId(selectedShift.eventId);
          setError("");
        } else {
          setError(
            "We could not find that employee's assignment. " +
              "Please try your selections again."
          );
        }
      }
    }
  };

  //method that renders manual employee options
  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      return employees.map((employee, index) => (
        <MenuItem
          key={`${employee.lastName}-${index}`}
          value={employee.employeeId}
        >
          <EventParticipant
            participant={employee}
            showAvatar={false}
            widthMatters={false}
            showJobTitle={true}
          />
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem value={"None"}>
          <Typography>No Recommended Employees</Typography>
        </MenuItem>
      );
    }
  };

  //method that renders reschedule options with date
  const renderEmployeeOptionswithDate = (options) => {
    if (options.length > 0) {
      const sortedOptions = [...options];
      sortedOptions.sort((a, b) => b.benefit - a.benefit);

      return sortedOptions.map((option) => {
        let shiftTitle;
        if (option.rescheduleactionSet.length > 0) {
          const shift = option.rescheduleactionSet.find(
            (e) => e.actionType === "DROP"
          );
          shiftTitle = shift
            ? format(new Date(shift.shift.start), " dd MMM ") +
              format(new Date(shift.shift.start), "HH:mm") +
              "-" +
              format(new Date(shift.shift.end), "HH:mm")
            : "";
        }

        return (
          <MenuItem key={option.optionId + "select"} value={option.optionId}>
            {option.employee.firstName + " " + option.employee.lastName}
            <span style={{ fontSize: 15, fontWeight: 500 }}>{shiftTitle}</span>
            {option.benefit <= -1 && <StarRateIcon className={classes.icon} />}
            {option.benefit < 1 && option.benefit > -1 && (
              <>
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
              </>
            )}
            {option.benefit >= 1 && (
              <>
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
              </>
            )}
          </MenuItem>
        );
      });
    } else {
      return <MenuItem value={"None"}>No Eligible Employees</MenuItem>;
    }
  };

  const validRequest = Boolean(
    algorithmOnly
      ? desiredDateSelectedEmployee && currentDate > minimumDate
      : currentDateShiftId &&
          desiredDateShiftId &&
          currentDateSelectedEmployee &&
          desiredDateSelectedEmployee &&
          currentDate > minimumDate &&
          desiredDate > minimumDate
  );

  const handleSubmit = () => {
    if (algorithmOnly) {
      console.log(desiredDateSelectedEmployee);
      console.log(currentUser.id);
      executeOption({
        variables: {
          option: parseInt(desiredDateSelectedEmployee),
          approver: parseInt(currentUser.id),
        },
      });
    } else {
      const individuals = [
        {
          employee: parseInt(currentDateSelectedEmployee),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "ADD",
              shift: desiredDateShiftId,
            },
            {
              actionType: "DROP",
              shift: currentDateShiftId,
            },
          ],
        },
        {
          employee: parseInt(desiredDateSelectedEmployee),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "DROP",
              shift: desiredDateShiftId,
            },
            {
              actionType: "ADD",
              shift: currentDateShiftId,
            },
          ],
        },
      ];

      createSwapOption({
        variables: {
          optionType: "SWAP",
          status: "APPROVED",
          individuals: individuals,
          userGenerated: true,
        },
      });
    }
  };

  const handleAlgorithmOnlyChange = (event) => {
    setError("");
    setDesiredDateSelectedEmployee("");
    setAlgorithmOnly(event.target.checked);
    if (!event.target.checked) {
      setCurrentDateSelectedEmployee("");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={algorithmOnly}
              onChange={handleAlgorithmOnlyChange}
              name="algorithmOnly"
              color={algorithmOnly ? "primary" : "secondary"}
            />
          }
          label="System-Generated Options"
        ></FormControlLabel>
      </Grid>
      <Grid item style={{ marginTop: 16 }}>
        <InputLabel id="shift-select-label" shrink={false}>
          <Typography variant="h5">Shift</Typography>
        </InputLabel>
        <Select
          labelId="shift-select-label"
          id="shift-select"
          variant="outlined"
          value={shift}
          onChange={handleShiftChange}
          className={classes.shiftSelect}
        >
          {shiftNames.map((shiftName) => (
            <MenuItem key={shiftName} value={shiftName}>
              {shiftName}
            </MenuItem>
          ))}
          <MenuItem value={"All Shifts"}>All Shifts</MenuItem>
        </Select>
      </Grid>
      <Grid item style={{ marginTop: 16 }}>
        <Typography variant="h5">Dates to Switch</Typography>
      </Grid>
      <Grid item container justifyContent="flex-start" spacing={2}>
        <Grid item>
          <InputLabel htmlFor="current-date">
            <Typography variant="h5">From</Typography>
          </InputLabel>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="current-date"
            value={currentDate}
            onChange={handleCurrentDateChange}
            minDate={minimumDate}
            className={classes.input}
          />
          {currentDateError && (
            <Typography variant="body2" className={classes.dateError}>
              {currentDateError}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <InputLabel htmlFor="desired-date">
            <Typography variant="h5">To</Typography>
          </InputLabel>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="desired-date"
            value={desiredDate}
            onChange={handleDesiredDateChange}
            minDate={minimumDate}
            className={classes.input}
            disabled={algorithmOnly}
          />
          {desiredDateError && (
            <Typography variant="body2" className={classes.dateError}>
              {desiredDateError}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 16 }}>
        <Typography variant="h5">Employees</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" spacing={2}>
        <Grid item>
          <InputLabel id="select-employee-1-label">
            <Typography variant="h5">From</Typography>
          </InputLabel>
          <Select
            labelId="select-employee-1-label"
            id="select-employee-1"
            variant="outlined"
            value={currentDateSelectedEmployee}
            onChange={handleCurrentSelectedEmployeeChange}
            className={classes.select}
            disabled={!!error}
          >
            {
              // algorithmOnly
              //   ? renderEmployeeOptions(currentDateEmployees)
              //   :
              renderEmployeeOptions(filteredCurrentDateEmployees)
            }
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-employee-2-label">
            <Typography variant="h5">To</Typography>
          </InputLabel>
          <Select
            labelId="select-employee-2-label"
            id="select-employee-2"
            variant="outlined"
            value={desiredDateSelectedEmployee}
            onChange={handleDesiredSelectedEmployeeChange}
            className={classes.select}
            //disabled={!!error}
          >
            {algorithmOnly
              ? renderEmployeeOptionswithDate(rescheduleSwapAddOptions)
              : renderEmployeeOptions(eligibleEmployees)}
          </Select>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" style={{ marginTop: 16 }}>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{
          marginTop: 20,
          zIndex: 4,
        }}
        spacing={2}
      >
        {currentDateShiftId != "" && rescheduleSwapAddOptions.length <= 0 && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                forceOptions({
                  variables: {
                    employee: parseInt(currentDateSelectedEmployee),
                    office: parseInt(currentUser.office.id),
                    shifts: [currentDateShiftId],
                  },
                });
              }}
            >
              Force Options
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!validRequest}
            style={{ width: 169, height: 39 }}
          >
            Switch Shifts
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default MangShiftSwitchRequestForm;
