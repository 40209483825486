import React, { useEffect, useState } from "react";
import ParticipantDelete from "./ParticipantDelete";
import EventParticipant from "./EventParticipant";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  makeStyles,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarRateIcon from "@material-ui/icons/StarRate";
import {
  MANAGER_ADD_EMPLOYEE_TO_SHIFT,
  GET_ALL_USERS,
  GET_SHIFT_ASSIGNMENTS_BY_SHIFT,
  MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT,
  ADD_AND_DROP_OPTIONS_FOR_SHIFT,
  CREATE_TASK,
  EXECUTE_OPTION,
} from "../../api/gqlQueries";
import { format } from "date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { userVar } from "../../cache";
import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
}));

const MangEditShift = (props) => {
  const classes = useStyles();

  const {
    shiftEvent,
    closeDialog,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const user = userVar();
  const officeId = user.office.id;

  let currentShiftId;
  if (!shiftEvent.tasks) {
    currentShiftId = shiftEvent.eventId;
  }

  const [includedShifts, setIncludedShifts] = useState();
  const [selectedShift, setSelectedShift] = useState();
  const [shiftAssignments, setShiftAssignments] = useState([]);
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState(
    shiftEvent.participants
  );
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [addOptions, setAddOptions] = useState([]);
  const [dropOptions, setDropOptions] = useState([]);

  const { loading, error, data } = useQuery(GET_ALL_USERS, {
    variables: {
      id: parseInt(officeId),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_USERS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [getShiftAssignmentData, { data: shiftAssignmentData }] = useLazyQuery(
    GET_SHIFT_ASSIGNMENTS_BY_SHIFT,
    {
      fetchPolicy: "cache-and-network",
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
        notifyDevelopers({
          variables: {
            message:
              "Error on  GET_SHIFT_ASSIGNMENTS_BY_SHIFT lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [getRescheduleOptions] = useLazyQuery(ADD_AND_DROP_OPTIONS_FOR_SHIFT, {
    onCompleted(data) {
      console.log(data);
      const addOptions =
        data.rescheduleAdds.length > 0
          ? data.rescheduleAdds
              .filter((option) => option.status === "OPTION")
              .map((add) => {
                const employeeIdsAndBenefits = add.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: add.id,
                    };
                  }
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];

      const dropOptions =
        data.rescheduleDrops.length > 0
          ? data.rescheduleDrops
              .filter((option) => option.status === "OPTION")
              .map((drop) => {
                const employeeIdsAndBenefits = drop.rescheduleindividualSet.map(
                  (employee) => {
                    return {
                      employeeId: employee.employee.id,
                      benefit: employee.benefit,
                      cost: employee.cost,
                      optionId: drop.id,
                    };
                  }
                );
                return employeeIdsAndBenefits;
              })
              .flat()
          : [];
      setAddOptions(addOptions);
      setDropOptions(dropOptions);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_AND_DROP_OPTIONS_FOR_SHIFT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    if (shiftAssignmentData) {
      const assignments = shiftAssignmentData.shiftAssignments.filter(
        (shiftAssignment) => shiftAssignment.isArchived === false
      );
      setShiftAssignments(assignments);
    }
  }, [shiftAssignmentData]);

  useEffect(() => {
    if (shiftEvent.tasks) {
      let smallerShifts = [];
      shiftEvent.tasks.forEach((task) => {
        const exists = smallerShifts.find(
          (small) => task.start === small.start && task.end === small.end
        );
        const title =
          format(new Date(task.start), "HH:mm") +
          " - " +
          format(new Date(task.end), "HH:mm");

        const shiftId =
          shiftEvent.eventTitle === "GHOC"
            ? task.id
            : task.shiftAssignment.shift.id;

        const procedureId =
          shiftEvent.eventTitle !== "GHOC" ? shiftEvent.eventId : null;

        const shiftAssignment = task.shiftAssignment
          ? [task.shiftAssignment]
          : task.shiftassignmentSet.filter(
              (assignment) =>
                assignment.taskSet && assignment.taskSet.length === 0
            );

        if (!exists) {
          smallerShifts = [
            ...smallerShifts,
            {
              title: title,
              procedureId: procedureId,
              shiftId: shiftId,
              start: task.start,
              end: task.end,
              participants: [...shiftAssignment],
            },
          ];
        } else {
          exists.participants = [...exists.participants, ...shiftAssignment];
        }
      });
      setIncludedShifts(smallerShifts);
      setSelectedShift(smallerShifts[0]);
      getRescheduleOptions({
        variables: {
          shifts: [parseInt(smallerShifts[0].shiftId)],
        },
      });
    } else if (currentShiftId) {
      getShiftAssignmentData({
        variables: {
          shiftId: parseInt(currentShiftId),
        },
      });
      getRescheduleOptions({
        variables: {
          shifts: [parseInt(currentShiftId)],
        },
      });
    }
  }, []);

  const [createTask] = useMutation(CREATE_TASK, {
    onCompleted(data) {
      console.log(data);
      // refetch();
      setToast("Manager Edit Shift");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error Edit Shift. Error on CREATE_TASK Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [addEmployee] = useMutation(MANAGER_ADD_EMPLOYEE_TO_SHIFT, {
    onCompleted(data) {
      console.log(data);
      // refetch();
      setToast("Manager Edit Shift");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error Edit Shift. Error on MANAGER_ADD_EMPLOYEE_TO_SHIFT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [addEmployeeAndTask] = useMutation(MANAGER_ADD_EMPLOYEE_TO_SHIFT, {
    onCompleted(data) {
      console.log(data);
      createTask({
        variables: {
          input: {
            shiftAssignment:
              data.managerCreateShiftAssignment.shiftAssignment.id,
            procedure: selectedShift.procedureId,
            start: selectedShift.start,
            end: selectedShift.end,
          },
        },
      });
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error Edit Shift. Error on MANAGER_ADD_EMPLOYEE_TO_SHIFT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [removeEmployee] = useMutation(MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT, {
    onCompleted(data) {
      // refetch();
      setToast("Manager Edit Shift");
      setShowToast(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error Edit Shift. Error on MANAGER_REMOVE_EMPLOYEE_FROM_SHIFT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [executeOption] = useMutation(EXECUTE_OPTION, {
    onCompleted(data) {
      console.log(data);
      refetch();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on EXECUTE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  let eligibleEmployees;
  let allUsers;
  if (!loading && !error) {
    allUsers = data.offices[0].employeeSet;
    const allUsersIds = allUsers.map((user) => user.id);
    const currentShiftEmployeeIds = currentShiftEmployees.map(
      (user) => user.id
    );
    const eligibleEmployeeIds = allUsersIds.filter(
      (id) => !currentShiftEmployeeIds.includes(id)
    );
    eligibleEmployees = allUsers.filter((user) =>
      eligibleEmployeeIds.includes(user.id)
    );
  }

  const renderParticipants = () => {
    const uniqueIds = [];
    let uniqueEmployees = [];

    if (selectedShift) {
      uniqueEmployees = selectedShift.participants.map(
        (participant) => participant.employee
      );
    } else {
      shiftEvent.participants.forEach((participant) => {
        !uniqueIds.includes(participant.id) &&
          uniqueIds.push(participant.id) &&
          uniqueEmployees.push(participant);
      });
    }
    return uniqueEmployees.map((participant) => {
      const drop = dropOptions.find(
        (option) => option.employeeId === participant.id
      );
      return (
        <Grid
          item
          container
          key={participant.id}
          xs={5}
          alignItems="center"
          spacing={1}
          style={{ margin: 5, marginRight: 25 }}
        >
          <Grid item xs={2}>
            {drop && (
              <>
                {drop.benefit <= -1 && (
                  <StarRateIcon className={classes.icon} />
                )}
                {drop.benefit < 1 && drop.benefit > -1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
                {drop.benefit >= 1 && (
                  <>
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                    <StarRateIcon className={classes.icon} />
                  </>
                )}
              </>
            )}
          </Grid>
          <ParticipantDelete
            participant={participant}
            employeesToDelete={employeesToDelete}
            setEmployeesToDelete={setEmployeesToDelete}
            officeId={officeId}
            shiftEvent={shiftEvent}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </Grid>
      );
    });
  };

  const renderNewAdditions = () => {
    if (employeesToAdd) {
      return employeesToAdd.map((employee) => {
        const add = addOptions.find(
          (option) => option.employeeId === employee.id
        );

        return (
          <Grid
            item
            container
            key={employee.id}
            xs={5}
            alignItems="center"
            spacing={1}
            style={{ margin: 5, marginRight: 25 }}
          >
            <Grid item xs={2}>
              {add && (
                <>
                  {add.benefit <= -1 && (
                    <StarRateIcon className={classes.icon} />
                  )}
                  {add.benefit < 1 && add.benefit > -1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                  {add.benefit >= 1 && (
                    <>
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                      <StarRateIcon className={classes.icon} />
                    </>
                  )}
                </>
              )}
            </Grid>
            <ParticipantDelete
              key={employee.id}
              participant={employee}
              newAddition={true}
              employeesToAdd={employeesToAdd}
              setEmployeesToAdd={setEmployeesToAdd}
              currentShiftEmployees={currentShiftEmployees}
              setCurrentShiftEmployees={setCurrentShiftEmployees}
              officeId={officeId}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </Grid>
        );
      });
    }
  };

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      let adds = [];
      let others = [];
      employees.forEach((employee) => {
        const add = addOptions.find(
          (option) => option.employeeId === employee.id
        );
        if (add) {
          adds = [...adds, { ...add, ...employee }];
        } else {
          others = [...others, employee];
        }
      });

      adds.sort((a, b) => b.benefit - a.benefit);

      adds = adds.map((employee) => {
        return (
          <MenuItem key={employee.id} value={employee.id}>
            <EventParticipant
              participant={employee}
              showAvatar={false}
              showJobTitle={true}
              singleLine={true}
              addOption={true}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
            {employee.benefit <= -1 && (
              <StarRateIcon className={classes.icon} />
            )}
            {employee.benefit < 1 && employee.benefit > -1 && (
              <>
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
              </>
            )}
            {employee.benefit >= 1 && (
              <>
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
                <StarRateIcon className={classes.icon} />
              </>
            )}
          </MenuItem>
        );
      });

      others = others.map((employee) => {
        return (
          <MenuItem key={employee.id} value={employee.id}>
            <EventParticipant
              participant={employee}
              showAvatar={false}
              showJobTitle={true}
              singleLine={true}
              addOption={true}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </MenuItem>
        );
      });
      return [...adds, ...others];
    } else {
      return (
        <MenuItem value={-1}>
          <Typography>No Eligible Employees</Typography>
        </MenuItem>
      );
    }
  };

  const handleAddEmployee = (e) => {
    setSelectedEmployeeToAdd(e.target.value);
  };

  const temporaryAdd = () => {
    if (selectedEmployeeToAdd) {
      const employeeToAdd = allUsers.find(
        (user) => user.id === selectedEmployeeToAdd
      );
      setEmployeesToAdd([...employeesToAdd, employeeToAdd]);
      setCurrentShiftEmployees([...currentShiftEmployees, employeeToAdd]);
      setSelectedEmployeeToAdd("");
      setErrorMessage("");
    } else {
      setErrorMessage("Must select employee to add");
    }
  };

  //ability to add note to the shift changes to go to notification
  const handleSubmit = () => {
    if (selectedEmployeeToAdd) {
      setErrorMessage("Click Add button to save selected employee to shift");
      return;
    } else {
      if (selectedShift) {
        let optionsToExecute = [];

        if (selectedShift.procedureId) {
          employeesToAdd.forEach((employee) => {
            const existingOption = addOptions.find(
              (option) => option.employeeId === employee.id
            );
            if (existingOption) {
              optionsToExecute.push(existingOption.optionId);
            } else {
              addEmployeeAndTask({
                variables: {
                  employeeId: employee.id,
                  managerId: user.id,
                  shiftId: selectedShift.shiftId,
                },
              });
            }
          });
        } else {
          employeesToAdd.forEach((employee) => {
            const existingOption = addOptions.find(
              (option) => option.employeeId === employee.id
            );
            if (existingOption) {
              optionsToExecute.push(existingOption.optionId);
            } else {
              addEmployee({
                variables: {
                  employeeId: employee.id,
                  managerId: user.id,
                  shiftId: selectedShift.shiftId,
                },
              });
            }
          });
        }

        employeesToDelete.forEach((employee) => {
          const existingOption = addOptions.find(
            (option) => option.employeeId === employee.id
          );
          if (existingOption) {
            optionsToExecute.push(existingOption.optionId);
          } else {
            const matchingShiftAssignment = selectedShift.participants.find(
              (participant) =>
                parseInt(participant.employee.id) === parseInt(employee.id)
            );

            removeEmployee({
              variables: {
                shiftAssignmentId: matchingShiftAssignment.id,
                managerId: user.id,
              },
            });
          }
        });

        if (optionsToExecute.length > 0) {
          optionsToExecute.forEach((optionId) => {
            executeOption({
              variables: {
                option: parseInt(optionId),
              },
            });
          });
        }

        closeDialog();
      } else {
        let optionsToExecute = [];

        employeesToAdd.forEach((employee) => {
          const existingOption = addOptions.find(
            (option) => option.employeeId === employee.id
          );
          if (existingOption) {
            optionsToExecute.push(existingOption.optionId);
          } else {
            addEmployee({
              variables: {
                employeeId: employee.id,
                managerId: user.id,
                shiftId: currentShiftId,
              },
            });
          }
        });

        employeesToDelete.forEach((employee) => {
          console.log(employee)
          const existingOption = dropOptions.find(
            (option) => option.employeeId === employee.employeeId
          );
          if (existingOption) {
            optionsToExecute.push(existingOption.optionId);
          } else {
            const matchingShiftAssignment = shiftAssignments.find(
              (shiftAssignment) => shiftAssignment.employee.id === employee.employeeId
            );
            removeEmployee({
              variables: {
                shiftAssignmentId: matchingShiftAssignment.id,
                managerId: user.id,
              },
            });
          }
        });

        if (optionsToExecute.length > 0) {
          optionsToExecute.forEach((optionId) => {
            executeOption({
              variables: {
                option: parseInt(optionId),
              },
            });
          });
        }
        closeDialog();
      }
    }
  };

  const handleShiftChange = (e) => {
    const title = e.target.value;
    const selected = includedShifts.find((shift) => shift.title === title);
    setSelectedShift(selected);
    getRescheduleOptions({
      variables: {
        shifts: [parseInt(selected.shiftId)],
      },
    });
  };

  const eligibleToSave = Boolean(
    employeesToAdd.length > 0 || employeesToDelete.length > 0
  );

  return (
    <>
      <Grid item container direction="column" spacing={2}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Grid item container direction="column" spacing={1} xs={4}>
            <Grid item>
              <Typography variant="h2">Edit {shiftEvent.eventTitle}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {format(new Date(shiftEvent.start), "dd MMM yyyy")}
              </Typography>
              {includedShifts && (
                <Box mt={1}>
                  <Typography variant="h5" style={{ marginBottom: 8 }}>
                    Shift Time:
                  </Typography>
                  <Select
                    variant="outlined"
                    name="selectedShift"
                    value={selectedShift.title}
                    onChange={handleShiftChange}
                    className={classes.select}
                  >
                    {includedShifts.map((shift, index) => (
                      <MenuItem key={index} value={shift.title}>
                        {shift.title}
                      </MenuItem>
                    ))}
                    {/* {shiftEvent.tasks.map((task, index) => (
                      <MenuItem key={index} value={task.id}>
                        {format(new Date(task.start), "HH:mm") +
                          "-" +
                          format(new Date(task.end), "HH:mm")}
                      </MenuItem>
                    ))} */}
                  </Select>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            <Grid item>
              <IconButton color="secondary" onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="flex-end"
          style={
            includedShifts
              ? { marginBottom: -10, marginTop: -85 }
              : { marginBottom: -10, marginTop: -30 }
          }
        >
          <RescheduleOptionsKey type="addDrop" />
        </Grid>
        <Grid item>
          <Typography variant="h5">Employees Scheduled:</Typography>
        </Grid>
        <Grid item container justifyContent="space-between" wrap="wrap">
          {renderParticipants()}
          {renderNewAdditions()}
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <InputLabel id="select-employee-1-label">
              <Typography variant="h5">Add Employee:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              labelId="select-employee-1-label"
              id="select-employee-1"
              variant="outlined"
              value={selectedEmployeeToAdd}
              onChange={handleAddEmployee}
              className={classes.select}
            >
              {renderEmployeeOptions(eligibleEmployees)}
            </Select>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={temporaryAdd}
              disabled={!selectedEmployeeToAdd}
            >
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setSelectedEmployeeToAdd("")}
              disabled={!selectedEmployeeToAdd}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h5">Notes: {shiftEvent.notes}</Typography>
        </Grid>
        {errorMessage && (
          <Grid item>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
        style={{ marginTop: 15 }}
      >
        <Grid item>
          <Typography variant="body2" className={classes.helpfulTip}>
            Save to activate shift changes
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={!eligibleToSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MangEditShift;
