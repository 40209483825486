import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  makeStyles,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EventParticipant from "../missionBasedCalendar/EventParticipant";
import {
  selectEmployees,
  findEmployeesToSwitch,
} from "../../helpers/shiftSwitch";
import { add, format } from "date-fns";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SHIFT_ASSIGNMENT,
  RESCHEDULE_SWAP_OPTIONS,
  CREATE_RESCHEDULE_OPTION,
  EXECUTE_OPTION,
} from "../../api/gqlQueries";
import { userVar } from "../../cache";
import StarRateIcon from "@material-ui/icons/StarRate";
import isSameDay from "date-fns/isSameDay";

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 138,
    maxWidth: 225,
  },
  select: {
    width: 273,
    height: 56,
  },
  error: {
    color: theme.palette.primary.main,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  icon: {
    padding: 0,
    marginRight: -9,
    marginBottom: -6,
    marginLeft: 2,
  },
}));

const MissionMangShiftSwitchRequestForm = (props) => {
  const classes = useStyles();

  const {
    allEvents,
    // shiftNames,
    closeDialog,
    date,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    shiftSwitchBuffer,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,

  } = props;

  const currentUser = userVar();
  const allMonthEvents =
    allEvents.length > 0 &&
    allEvents.filter(
      (event) => event.calendars && event.calendars.includes("month")
    );
  allMonthEvents.sort((a, b) => a.start - b.start);

  const formattedScheduleDates = [];
  allMonthEvents.forEach((event) => {
    const formatted = format(event.start, "MM/dd/yyyy");
    if (!formattedScheduleDates.includes(formatted)) {
      formattedScheduleDates.push(formatted);
    }
  });

  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  const [getFirstShiftAssignment] = useLazyQuery(GET_SHIFT_ASSIGNMENT, {
    onCompleted(data) {
      setRescheduleSwapAddOptions([]);
      if (data.shiftAssignments.length > 0) {
        rescheduleSwapOptions({
          variables: {
            shiftAssignments: parseInt(data.shiftAssignments[0].id),
          },
        });
        setError("");
      } else {
        setError("Please try selecting first date and employee again.");
      }
    },
    onError(error) {
      console.log(error);
      setError("Please try selecting first date and employee again.");
      notifyDevelopers({variables: {message: "Error on GET_SHIFT_ASSIGNMENT lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [algorithmOnly, setAlgorithmOnly] = useState(true);
  const [error, setError] = useState("");
  const [currentDateError, setCurrentDateError] = useState("");
  const [desiredDateError, setDesiredDateError] = useState("");
  // const [shift, setShift] = useState("All Shifts");
  const [currentDate, setCurrentDate] = useState();
  const [desiredDate, setDesiredDate] = useState();
  const [currentDateShiftId, setCurrentDateShiftId] = useState("");
  const [desiredDateShiftId, setDesiredDateShiftId] = useState("");
  const [currentDateSelectedEmployee, setCurrentDateSelectedEmployee] =
    useState("");
  const [desiredDateSelectedEmployee, setDesiredDateSelectedEmployee] =
    useState("");

  const [currentDateEmployees, setCurrentDateEmployees] = useState([]);
  const [filteredCurrentDateEmployees, setFilteredCurrentDateEmployees] =
    useState([]);
  const [desiredDateEmployees, setDesiredDateEmployees] = useState([]);
  const [eligibleEmployees, setEligibleEmployees] = useState([]);

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState([]);

  const [rescheduleSwapOptions] = useLazyQuery(RESCHEDULE_SWAP_OPTIONS, {
    onCompleted(data) {
      if (data.rescheduleSwaps.length > 0) {
        const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
          const past = swap.rescheduleindividualSet[0].rescheduleactionSet.find(
            (action) => new Date(action.shift.start) < minimumDate
          );

          return swap.status === "OPTION" && !past;
        });

        const options = swapsWithOptionStatus
          .map((option) => {
            const employeeNotCurrentSelected =
              option.rescheduleindividualSet.find(
                (individual) =>
                  parseInt(individual.employee.id) !==
                  parseInt(currentDateSelectedEmployee)
              );
            return {
              ...employeeNotCurrentSelected,
              optionId: option.id,
            };
          })
          .filter((option) => option.rescheduleactionSet);
        setRescheduleSwapAddOptions(options);
      } else {
        setRescheduleSwapAddOptions([]);
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [executeOption] = useMutation(EXECUTE_OPTION, {
    onCompleted(data) {
      console.log(data);
      setError("");
      setToast("Manager Shift Switch");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError(
        "Unable to create shift switch request. Please check details and try again."
      );
      notifyDevelopers({variables: {message: "Error on EXECUTE_OPTION Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      console.log(data);
      setError("");
      setToast("Manager Shift Switch");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError(
        "Unable to create shift switch request. Please check details and try again."
      );
      notifyDevelopers({variables: {message: "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const findNextEvent = (date) => {
    const nextEvent = allMonthEvents.find(
      (event) => isSameDay(event.start, date) || event.start > date
    );
    return nextEvent;
  };

  const findPreviousEvent = (date) => {
    const sortByRecent = [...allMonthEvents];
    sortByRecent.sort((a, b) => b.start - a.start);

    const previousEvent = sortByRecent.find(
      (event) =>
        event.start < date &&
        (event.start > minimumDate || isSameDay(event.start, minimumDate))
    );
    return previousEvent;
  };

  const findDateToView = () => {
    const firstUpcomingAfterMinDate = findNextEvent(minimumDate);
    let secondUpcomingAfterMinDate;
    if (firstUpcomingAfterMinDate) {
      secondUpcomingAfterMinDate = findNextEvent(
        add(firstUpcomingAfterMinDate.start, { days: 1 })
      );
    }

    let firstDate;
    let secondDate;
    if (date && new Date(date) > minimumDate) {
      const firstUpcomingEvent = findNextEvent(new Date(date));
      const secondUpcomingEvent = findNextEvent(
        add(new Date(date), { days: 1 })
      );
      const previousEvent = findPreviousEvent(new Date(date));

      if (firstUpcomingEvent) {
        firstDate = firstUpcomingEvent.start;
        secondDate = secondUpcomingEvent
          ? secondUpcomingEvent.start
          : previousEvent
          ? previousEvent.start
          : firstDate;
      } else {
        firstDate = firstUpcomingAfterMinDate
          ? firstUpcomingAfterMinDate.start
          : null;

        secondDate = secondUpcomingAfterMinDate
          ? secondUpcomingAfterMinDate.start
          : firstDate;
      }
    } else {
      firstDate = firstUpcomingAfterMinDate
        ? firstUpcomingAfterMinDate.start
        : null;

      secondDate = secondUpcomingAfterMinDate
        ? secondUpcomingAfterMinDate.start
        : firstDate;
    }
    return { firstDate, secondDate };
  };

  useEffect(() => {
    //utilize helper to find surrounding events since there
    //may not be mission events every day
    const datesToView = findDateToView();
    const firstDate = datesToView.firstDate;
    const secondDate = datesToView.secondDate;

    if (!firstDate || !secondDate) {
      setErrorToast("Non Eligible Shift Switch");
      setShowErrorToast(true);
      closeDialog();
      return;
    }

    setCurrentDate(firstDate);
    setDesiredDate(secondDate);
    const currentEmployees = selectEmployees(
      allMonthEvents,
      "All Shifts",
      firstDate,
      setError
    );
    const desiredEmployees = selectEmployees(
      allMonthEvents,
      "All Shifts",
      secondDate,
      setError
    );

    if (currentEmployees && desiredEmployees) {
      const newEligible = findEmployeesToSwitch(
        currentEmployees,
        desiredEmployees
      );
      const filteredCurrentEmployees = findEmployeesToSwitch(
        desiredEmployees,
        currentEmployees
      );

      setCurrentDateEmployees(currentEmployees);
      setFilteredCurrentDateEmployees(filteredCurrentEmployees);
      setDesiredDateEmployees(desiredEmployees);
      setEligibleEmployees(newEligible);
    }
  }, []);

  const handleCurrentDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      setCurrentDate(date);
      setCurrentDateError("");
      setCurrentDateSelectedEmployee("");
      setDesiredDateSelectedEmployee("");
      const newCurrentEmployees = selectEmployees(
        allMonthEvents,
        "All Shifts",
        date,
        setError
      );

      if (newCurrentEmployees) {
        const newEligible = findEmployeesToSwitch(
          newCurrentEmployees,
          desiredDateEmployees
        );
        const filteredCurrentEmployees = findEmployeesToSwitch(
          desiredDateEmployees,
          newCurrentEmployees
        );

        setCurrentDateEmployees(newCurrentEmployees);
        setFilteredCurrentDateEmployees(filteredCurrentEmployees);
        setEligibleEmployees(newEligible);
      }
    } else {
      setCurrentDateError("Invalid date format");
    }
  };

  const handleDesiredDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      setDesiredDate(date);
      setDesiredDateError("");
      setCurrentDateSelectedEmployee("");
      setDesiredDateSelectedEmployee("");

      const newDesiredEmployees = selectEmployees(
        allMonthEvents,
        "All Shifts",
        date,
        setError
      );
      const newCurrentEmployees = selectEmployees(
        allMonthEvents,
        "All Shifts",
        currentDate,
        setError
      );

      if (newDesiredEmployees && newCurrentEmployees) {
        setDesiredDateEmployees(newDesiredEmployees);
        const filteredCurrentEmployees = findEmployeesToSwitch(
          newDesiredEmployees,
          newCurrentEmployees
        );
        const newEligible = findEmployeesToSwitch(
          newCurrentEmployees,
          newDesiredEmployees
        );

        setCurrentDateEmployees(newCurrentEmployees);
        setFilteredCurrentDateEmployees(filteredCurrentEmployees);
        setEligibleEmployees(newEligible);
      }
    } else {
      setDesiredDateError("Invalid date format");
    }
  };

  const getMatchingShiftId = (employeeId, dateToMatch) => {
    const selectedEvent = allMonthEvents.find((event) => {
      const participantIds = event.participants.map(
        (participant) => participant.id
      );
      return (
        isSameDay(event.start, new Date(dateToMatch)) &&
        participantIds.includes(employeeId)
      );
    });

    let shiftId;
    let shiftAssignmentId;

    if (selectedEvent) {
      if (selectedEvent.eventTitle === "GHOC") {
        const selectedShift = selectedEvent.tasks.find((task) => {
          const matchingShiftAssignment = task.shiftassignmentSet.find(
            (shiftAssign) =>
              parseInt(shiftAssign.employee.id) === parseInt(employeeId)
          );
          shiftAssignmentId = matchingShiftAssignment
            ? matchingShiftAssignment.id
            : null;
          return matchingShiftAssignment;
        });
        shiftId = selectedShift ? selectedShift.id : null;
      } else {
        const selectedShift = selectedEvent.tasks.find(
          (task) =>
            parseInt(task.shiftAssignment.employee.id) === parseInt(employeeId)
        );
        shiftId = selectedShift ? selectedShift.shiftAssignment.shift.id : null;
        shiftAssignmentId = selectedShift
          ? selectedShift.shiftAssignment.id
          : null;
      }
    }
    return { shiftId, shiftAssignmentId };
  };

  const handleCurrentSelectedEmployeeChange = (e) => {
    if (e.target.value === "None") {
      setCurrentDateSelectedEmployee("None");
      return;
    } else {
      const employeeId = e.target.value;
      setDesiredDateSelectedEmployee("");

      const jobTypes = currentDateEmployees
        .find((employee) => employee.id === employeeId)
        ?.skills.filter((skill) => skill.variety === "JOB_TYPE")
        .map((skill) => skill.name);

      const desiredWithMatchingJob = findEmployeesToSwitch(
        currentDateEmployees,
        desiredDateEmployees,
        jobTypes
      );
      setEligibleEmployees(desiredWithMatchingJob);

      const shiftData = getMatchingShiftId(employeeId, currentDate);

      if (shiftData.shiftId) {
        setCurrentDateSelectedEmployee(employeeId);
        setCurrentDateShiftId(shiftData.shiftId);
        if (shiftData.shiftAssignmentId) {
          rescheduleSwapOptions({
            variables: {
              shiftAssignments: parseInt(shiftData.shiftAssignmentId),
            },
          });
        } else {
          getFirstShiftAssignment({
            variables: {
              employeeId: parseInt(employeeId),
              shiftId: parseInt(shiftData.shiftId),
            },
          });
        }
        setError("");
      } else {
        setError(
          "We could not find that employee's assignment. " +
            "Please try your selections again."
        );
      }
    }
  };

  const handleDesiredSelectedEmployeeChange = (e) => {
    if (e.target.value === "None") {
      setDesiredDateSelectedEmployee("None");
      return;
    } else {
      if (algorithmOnly) {
        //path for desired date selected option change when
        //algorithm only is checked
        const optionId = e.target.value;
        setDesiredDateSelectedEmployee(optionId);

        const matchingOption = rescheduleSwapAddOptions.find(
          (option) => option.optionId === optionId
        );
        const dropAction = matchingOption
          ? matchingOption.rescheduleactionSet.find(
              (action) => action.actionType === "DROP"
            )
          : null;

        if (dropAction) {
          setDesiredDate(new Date(dropAction.shift.start));
          setDesiredDateShiftId(dropAction.shift.id);
          setError("");
        } else {
          setError(
            "We could not find that employee's assignment. " +
              "Please try your selections again."
          );
        }
      } else {
        //path for desired date selected employee change when
        //algorithm only is NOT checked
        const employeeId = e.target.value;
        const shiftData = getMatchingShiftId(employeeId, desiredDate);

        setDesiredDateSelectedEmployee(employeeId);
        if (shiftData.shiftId) {
          setDesiredDateShiftId(shiftData.shiftId);
          setError("");
        } else {
          setError(
            "We could not find that employee's assignment. " +
              "Please try your selections again."
          );
        }
      }
    }
  };

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      return employees.map((employee, index) => (
        <MenuItem key={`${employee.lastName}-${index}`} value={employee.id}>
          <EventParticipant
            participant={employee}
            showAvatar={false}
            widthMatters={false}
            showJobTitle={true}
          />
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem value={"None"}>
          <Typography>No Eligible Employees</Typography>
        </MenuItem>
      );
    }
  };

  const renderEmployeeOptionswithDate = (options) => {
    if (options.length > 0) {
      const sortedOptions = [...options];
      sortedOptions.sort((a, b) => b.benefit - a.benefit);

      return sortedOptions.map((option) => {
        let shiftTitle;
        if (option.rescheduleactionSet.length > 0) {
          const shift = option.rescheduleactionSet.find(
            (e) => e.actionType === "DROP"
          );

          shiftTitle = shift
            ? format(new Date(shift.shift.start), " dd MMM ") +
              format(new Date(shift.shift.start), "HH:mm") +
              "-" +
              format(new Date(shift.shift.end), "HH:mm")
            : "";
        }

        return (
          <MenuItem key={option.employee.id + "select"} value={option.optionId}>
            <Typography>
              {option.employee.firstName + " " + option.employee.lastName}
              <span style={{ fontSize: 15, fontWeight: 500 }}>
                {shiftTitle}
              </span>
              {option.benefit <= -1 && (
                <StarRateIcon className={classes.icon} />
              )}
              {option.benefit < 1 && option.benefit > -1 && (
                <>
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                </>
              )}
              {option.benefit >= 1 && (
                <>
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                  <StarRateIcon className={classes.icon} />
                </>
              )}
            </Typography>
          </MenuItem>
        );
      });
    } else {
      return (
        <MenuItem value={"None"}>
          <Typography>No eligible employee options</Typography>
        </MenuItem>
      );
    }
  };

  const validRequest = Boolean(
    algorithmOnly
      ? desiredDateSelectedEmployee && currentDate > minimumDate
      : currentDateShiftId &&
          desiredDateShiftId &&
          currentDateSelectedEmployee &&
          desiredDateSelectedEmployee &&
          currentDate > minimumDate &&
          desiredDate > minimumDate
  );

  const handleSubmit = () => {
    if (algorithmOnly) {
      executeOption({
        variables: {
          option: parseInt(desiredDateSelectedEmployee),
          // approver: parseInt(currentUser.id)
        },
      });
    } else {
      const individuals = [
        {
          employee: parseInt(currentDateSelectedEmployee),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "ADD",
              shift: desiredDateShiftId,
            },
            {
              actionType: "DROP",
              shift: currentDateShiftId,
            },
          ],
        },
        {
          employee: parseInt(desiredDateSelectedEmployee),
          cost: 0,
          benefit: 0,
          actions: [
            {
              actionType: "DROP",
              shift: desiredDateShiftId,
            },
            {
              actionType: "ADD",
              shift: currentDateShiftId,
            },
          ],
        },
      ];

      createSwapOption({
        variables: {
          optionType: "SWAP",
          status: "APPROVED",
          individuals: individuals,
          userGenerated: true,
        },
      });
    }
  };

  const checkInvalidDate = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return !formattedScheduleDates.includes(formatted);
  };

  const handleAlgorithmOnlyChange = (event) => {
    setError("");
    setDesiredDateSelectedEmployee("");
    setAlgorithmOnly(event.target.checked);
    if (!event.target.checked) {
      setCurrentDateSelectedEmployee("");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={algorithmOnly}
              onChange={handleAlgorithmOnlyChange}
              name="algorithmOnly"
              color={algorithmOnly ? "primary" : "secondary"}
            />
          }
          label="System-Generated Options"
        ></FormControlLabel>
      </Grid>
      {/* <Grid item style={{ marginTop: 15 }}>
        <InputLabel id="shift-select-label" shrink={false}>
          <Typography variant="h5">Shift</Typography>
        </InputLabel>
        <Select
          labelId="shift-select-label"
          id="shift-select"
          variant="outlined"
          value={shift}
          onChange={handleShiftChange}
          className={classes.select}
        >
          {shiftNames.map((shiftName) => (
            <MenuItem key={shiftName} value={shiftName}>
              {shiftName}
            </MenuItem>
          ))}
          <MenuItem value={"All Shifts"}>All Shifts</MenuItem>
        </Select>
      </Grid> */}
      <Grid item style={{ marginTop: 15 }}>
        <Typography variant="h5">Dates to Switch</Typography>
      </Grid>
      <Grid item container justifyContent="flex-start" spacing={2}>
        <Grid item>
          <InputLabel htmlFor="current-date">
            <Typography variant="h5">From</Typography>
          </InputLabel>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="current-date"
            value={currentDate}
            onChange={handleCurrentDateChange}
            shouldDisableDate={checkInvalidDate}
            minDate={minimumDate}
            className={classes.input}
          />
          {currentDateError && (
            <Typography variant="body2" className={classes.dateError}>
              {currentDateError}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <InputLabel htmlFor="desired-date">
            <Typography variant="h5">To</Typography>
          </InputLabel>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="desired-date"
            value={desiredDate}
            onChange={handleDesiredDateChange}
            shouldDisableDate={checkInvalidDate}
            minDate={minimumDate}
            className={classes.input}
            disabled={algorithmOnly}
          />
          {desiredDateError && (
            <Typography variant="body2" className={classes.dateError}>
              {desiredDateError}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 15 }}>
        <Typography variant="h5">Employees</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" spacing={2}>
        <Grid item>
          <InputLabel id="select-employee-1-label">
            <Typography variant="h5">From</Typography>
          </InputLabel>
          <Select
            labelId="select-employee-1-label"
            id="select-employee-1"
            variant="outlined"
            value={currentDateSelectedEmployee}
            onChange={handleCurrentSelectedEmployeeChange}
            className={classes.select}
            disabled={!!error}
          >
            {algorithmOnly
              ? renderEmployeeOptions(currentDateEmployees)
              : renderEmployeeOptions(filteredCurrentDateEmployees)}
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="select-employee-2-label">
            <Typography variant="h5">To</Typography>
          </InputLabel>
          <Select
            labelId="select-employee-2-label"
            id="select-employee-2"
            variant="outlined"
            value={desiredDateSelectedEmployee}
            onChange={handleDesiredSelectedEmployeeChange}
            className={classes.select}
            disabled={!!error}
          >
            {algorithmOnly
              ? renderEmployeeOptionswithDate(rescheduleSwapAddOptions)
              : renderEmployeeOptions(eligibleEmployees)}
          </Select>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" style={{ marginTop: 15 }}>
        <Grid item>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="flex-end"
        style={{
          marginTop: 30,
          zIndex: 4,
          position: "absolute",
          bottom: 30,
          right: 30,
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!validRequest}
            style={{ width: 169, height: 39 }}
          >
            Switch Shifts
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default MissionMangShiftSwitchRequestForm;
