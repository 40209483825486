import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import { userVar } from "../../cache";
import {
  GET_RELEVANT_SKILLS,
  SCHEDULE_FITNESS,
  NOTIFY_DEVELOPERS,
  GET_ALL_CHILD_OFFICES,
  UTILIZATION_ANALYTICS,
  EMP_DETAILS_BY_OFFICE,
} from "../../api/gqlQueries";
import { useQuery, useMutation } from "@apollo/client";
import AnalyticsWidget from "./AnalyticsGraphWidget";
import { format } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  DateTime,
  Legend,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 225,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
  },
}))(TableCell);

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const SkillSetDashboard = () => {
  const classes = useStyles();

  const user = userVar();

  const [allSchedulePeriods, SetAllSchedulePeriods] = useState([]);
  const [selectSchedulePeriod, SetSelectSchedulePeriod] = useState("0");
  const [officeAnalyticsData, SetOfficeAnalyticsData] = useState([]);
  const [skillsetGraph, SetskillsetGraph] = useState(true);
  const [skillsetsq, Setskillsetsq] = useState(true);
  const [allemps, SetAllemps] = useState([]);
  const [showLoader, SetShowLoader] = useState(false);
  const [empFitnesses, SetEmpFitnesses] = useState([]);
  const environment = process.env.NODE_ENV;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [selectedChildOffice, SetSelectedChildOffice] = useState("0");

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { loading, error, data } = useQuery(UTILIZATION_ANALYTICS, {
    variables: {
      office:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onCompleted(d) {
      let officeData = data.utilizationAnalytics.map((m) => {
        return {
          employee: m.employee,
          scheduleStart: m.scheduleStart,
          scheduleEnd: m.scheduleEnd,
          schedulePeriodId:
            empDetails.data &&
            empDetails.data.offices[0].scheduleperiodSet.find(
              (x) => x.start === m.scheduleStart && x.end === m.scheduleEnd
            )?.id,
          office: m.office,
          skill: m.skill,
          utilization: m.utilization,
        };
      });
      const allSchedules = empDetails.data
        ? [...empDetails.data.offices[0].scheduleperiodSet]
        : [];
      allSchedules.sort((a, b) => new Date(a.start) - new Date(b.start));
      SetAllSchedulePeriods(allSchedules);
      SetAllemps(empDetails.data && empDetails.data.offices[0].employeeSet);
      if (selectSchedulePeriod === "0") {
        SetOfficeAnalyticsData(officeData);
      } else {
        SetOfficeAnalyticsData(
          officeData.filter(
            (e) => parseInt(e.schedulePeriod) === parseInt(selectSchedulePeriod)
          )
        );
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ANALYTICS_DATA Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const empDetails = useQuery(EMP_DETAILS_BY_OFFICE, {
    variables: {
      office:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMP_DETAILS_BY_OFFICE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const scheduleFitness = useQuery(
    SCHEDULE_FITNESS,
    {
      variables: {
        office:
          user.isPrimaryParentOffice && selectedChildOffice != "0"
            ? parseInt(selectedChildOffice)
            : parseInt(user.office.id),
      },
    },
    {
      onCompleted(d) {
        SetEmpFitnesses(d.employeeFitnesses);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar2(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on SCHEDULE_FITNESS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const SkillType = useQuery(GET_RELEVANT_SKILLS, {
    variables: {
      office:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar2(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const getSkillName = (value) => {
    const findSkill =
      SkillType.data.requiredSkills.length > 0 &&
      SkillType.data.requiredSkills.find(
        (x) => parseInt(x.skillId) === parseInt(value)
      );
    if (findSkill) {
      return findSkill.name;
    } else {
      return null;
    }
  };

  const getSchedulePeriod = (value) => {
    const sch = allSchedulePeriods.find(
      (x) => parseInt(x.id) === parseInt(value)
    );
    if (sch) {
      return (
        format(new Date(`${sch.start}T12:00:00`), "dd MMM yyyy") +
        " to " +
        format(new Date(`${sch.end}T12:00:00`), "dd MMM yyyy")
      );
    } else {
      return null;
    }
  };

  const allChildOffices = useQuery(GET_ALL_CHILD_OFFICES, {
    variables: {
      parent: parseInt(user.office.id),
    },
    onCompleted(d) {
      console.log(d);
    },
  });

  if (
    loading ||
    showLoader ||
    SkillType.loading ||
    allSchedulePeriods.loading ||
    scheduleFitness.loading ||
    empDetails.loading
  ) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    return (
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="h3">Skill Set</Typography>
          </Box>
        </Grid>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </Grid>
    );
  } else {
    const data = [...officeAnalyticsData];
    const uniqueSchedulePeriods = [];
    data.map(
      (e) =>
        !uniqueSchedulePeriods.includes(e.schedulePeriodId) &&
        uniqueSchedulePeriods.push(e.schedulePeriodId)
    );
    const constrainedSkillSetD1 = SkillType.data.requiredSkills.map((s) => {
      let uniqueSchedulePeriods = [];
      data.map(
        (e) =>
          !uniqueSchedulePeriods.includes(
            format(new Date(e.scheduleStart), "MMM-dd-yyyy") +
              " to " +
              format(new Date(e.scheduleEnd), "MMM-dd-yyyy")
          ) &&
          uniqueSchedulePeriods.push(
            format(new Date(e.scheduleStart), "MMM-dd-yyyy") +
              " to " +
              format(new Date(e.scheduleEnd), "MMM-dd-yyyy")
          )
      );

      let utilizationForSchedulePeriods = data
        .map(
          (e) =>
            uniqueSchedulePeriods.includes(
              format(new Date(e.scheduleStart), "MMM-dd-yyyy") +
                " to " +
                format(new Date(e.scheduleEnd), "MMM-dd-yyyy")
            ) &&
            parseInt(e.skill) === parseInt(s.skillId) &&
            parseFloat(e.utilization * 100)
        )
        .filter(Boolean);

      return {
        x: uniqueSchedulePeriods,
        y: utilizationForSchedulePeriods,
        name: s.name,
      };
    });

    // const boxdata = SkillType.data.requiredSkills.map((s) => {
    //   return {
    //     type: "line",
    //     boxpoints: "all",
    //     x: empDetails.data.offices[0].scheduleperiodSet
    //       .map((e) => getSchedulePeriod(e.id))
    //       .filter(Boolean),
    //     y:
    //       data.length > 0
    //         ? data
    //             .map((e) => {
    //               let fitnesseses = scheduleFitness.data.employeeFitnesses.find(
    //                 (f) => parseInt(f.employee.id) === parseInt(e.employee)
    //               )?.score;
    //               return fitnesseses;
    //             })
    //             .filter(Boolean)
    //         : [],
    //     name: s.name,
    //   };
    // });

    let schedulePeriods = [];
    if (
      empDetails.data &&
      empDetails.data.offices.length > 0 &&
      empDetails.data.offices[0].scheduleperiodSet
    ) {
      schedulePeriods = [...empDetails.data.offices[0].scheduleperiodSet];
    }
    return (
      <div>
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h3">Skill Set</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box m={2}>
              <Select
                variant="outlined"
                value={selectSchedulePeriod}
                onChange={(e) => {
                  SetSelectSchedulePeriod(e.target.value);
                  let officeData = [...officeAnalyticsData];
                  data.utilizationAnalytics &&
                    data.utilizationAnalytics.length > 0 &&
                    data.utilizationAnalytics.map((m) => {
                      officeData.push(m);
                    });
                  if (selectSchedulePeriod === "0") {
                    SetOfficeAnalyticsData(officeData);
                  } else {
                    SetOfficeAnalyticsData(
                      officeData.filter(
                        (e) =>
                          parseInt(e.schedulePeriod) ===
                          parseInt(selectSchedulePeriod)
                      )
                    );
                  }
                }}
                className={classes.select}
              >
                <MenuItem key="0" value="0">
                  All Schedule Periods
                </MenuItem>
                {schedulePeriods
                  .filter((e) => uniqueSchedulePeriods.includes(e.id))
                  .map((e, index) => (
                    <MenuItem key={index} value={e.id}>
                      {format(new Date(`${e.start}T08:00:00`), "dd MMM yyyy") +
                        " to " +
                        format(new Date(`${e.end}T08:00:00`), "dd MMM yyyy")}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            {user.isPrimaryParentOffice && (
              <Box m={2}>
                <Select
                  variant="outlined"
                  value={selectedChildOffice}
                  onChange={(e) => {
                    SetSelectSchedulePeriod("0");
                    SetSelectedChildOffice(e.target.value);
                  }}
                  className={classes.select}
                >
                  <MenuItem key="0" value="0">
                    Select Child Office
                  </MenuItem>
                  {allChildOffices.data &&
                    allChildOffices.data.getChildren.length > 0 &&
                    allChildOffices.data.getChildren.map(
                      (childOffice, index) => (
                        <MenuItem key={index} value={childOffice.id}>
                          {childOffice.name}
                        </MenuItem>
                      )
                    )}
                </Select>
              </Box>
            )}
          </Grid>
          <Grid xs={8} item></Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              {selectSchedulePeriod === "0" ||
                (selectedChildOffice === "0" && (
                  <>
                    <Typography variant="h5">Overview</Typography>
                    <br />
                    <br />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Skill</StyledTableCell>
                          <StyledTableCell>Skill Utilization</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {officeAnalyticsData &&
                          officeAnalyticsData.length > 0 &&
                          officeAnalyticsData
                            .sort(
                              (a, b) =>
                                parseInt(b.schedulePeriodId) -
                                parseInt(a.schedulePeriodId)
                            )
                            .map((e, index, arr) => (
                              <>
                                <TableRow>
                                  <StyledTableCell>
                                    <b>
                                      {index != 0 &&
                                      arr[index - 1] &&
                                      arr[index - 1].schedulePeriod !=
                                        e.schedulePeriod
                                        ? ""
                                        : getSchedulePeriod(e.schedulePeriodId)}
                                    </b>
                                  </StyledTableCell>
                                </TableRow>
                                <TableRow key={index} style={{ marginTop: 5 }}>
                                  <StyledTableCell>
                                    {getSkillName(e.skill)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {e.utilization.toFixed(2)}
                                  </StyledTableCell>
                                </TableRow>
                              </>
                            ))
                            .slice(0, 3)}
                      </TableBody>
                    </Table>
                  </>
                ))}
            </Box>
          </Grid>

          <AnalyticsWidget
            gridSize={12}
            open={skillsetGraph}
            SetOpen={SetskillsetGraph}
            data={constrainedSkillSetD1}
            chartType={4}
            SetChartType={null}
            header="Utilization Rate by Skill Set"
            layout={{
              yaxis: {
                title: {
                  text: "Utilization Rate in %",
                },
                rangemode: "tozero",
              },
            }}
          />

          {/*<AnalyticsWidget
            gridSize={10}
            open={skillsetsq}
            SetOpen={Setskillsetsq}
            data={boxdata}
            chartType={4}
            SetChartType={null}
            header="Schedule Quality by Skill Set"
            layout={{
              yaxis: {
                title: {
                  text: "Schedule Quality in %",
                },
                rangemode: "tozero",
              },
            }}
          /> */}
        </Grid>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </div>
    );
  }
};

export default SkillSetDashboard;
