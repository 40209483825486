import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Grid,
  Avatar,
  CssBaseline,
  Box,
  InputAdornment,
  IconButton,
  Paper,
  Link,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOpen from "@material-ui/icons/LockOpen";
import Copyright from "../general/Copyright";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@material-ui/icons/Error";
import { isLoggedInVar } from "../../cache";
import { useMutation, gql } from "@apollo/client";
import { userVar } from "../../cache";
import ErrorSnackBar from "../errors/errorSnackBar";
import { NOTIFY_DEVELOPERS } from "../../api/gqlQueries";

const GET_TOKEN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "160px",
    display: "flex",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "450px",
    margin: "auto",
    border: theme.shape.border,
    boxSizing: theme.shape.boxSizing,
    boxShadow: theme.shape.boxShadow,
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "40px",
    height: "40px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  inputWidth: {
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string().required("Required").email(),
  password: Yup.string().required("Required"),
});

const LoginForm = ({ error = false }) => {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const [getToken, loginStatus] = useMutation(GET_TOKEN, {
    onCompleted(data) {
      if (data) {
        sessionStorage.setItem("jwt", data.tokenAuth.token);
        isLoggedInVar(true);
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("Unable to login. Please check username or password");
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_TOKEN Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const environment = process.env.NODE_ENV;
  const baseURL = window.location.origin;
  let ssoURL = "";
  if (environment === "development" || baseURL.includes("amplify")) {
    ssoURL = "https://backendtest.balancescheduler.com/saml2/login/";
  } else {
    ssoURL = "https://backenddemo.balancescheduler.com/saml2/login/";
  }

  console.log(userVar());

  return (
    <main variant="body">
      <Grid className={classes.root}>
        <CssBaseline />
        <Grid className={classes.paper} component={Paper}>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // dispatch(attemptLogin(values));
              const { email, password } = values;
              getToken({
                variables: {
                  email,
                  password,
                },
              });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={3}>
                  <Grid item align="center">
                    {/* {authError ?
                                            <Typography color="primary" variant="subtitle2"><b>{errMsg}</b></Typography>
                                            : null
                                        } */}
                    {loginStatus.error && (
                      <Typography color="primary" variant="subtitle2">
                        Error: {loginStatus.error.message}
                      </Typography>
                    )}
                    {error && (
                      <Typography color="primary" variant="subtitle2">
                        You are not authorized to access this application.
                      </Typography>
                    )}
                    <Avatar className={classes.avatar}>
                      <LockOpen />
                    </Avatar>
                    <Typography variant="h3">
                      Welcome to Balance Scheduler
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid>
                      <Typography variant="subtitle2">
                        Email Address*
                      </Typography>
                    </Grid>
                    <TextField
                      variant="outlined"
                      type="text"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Email"
                      className={classes.inputWidth}
                      error={errors.email != null}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              {errors.email != null ? (
                                <ErrorIcon color="primary" />
                              ) : null}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography color="primary" variant="subtitle2">
                      {errors.email}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid container direction="row">
                      <Grid item xs={6} align="left">
                        <Typography variant="subtitle2">Password*</Typography>
                      </Grid>
                      <Grid item xs={6} align="right">
                        <Typography
                          variant="subtitle1"
                          href="/ForgotPassword"
                          component={Link}
                        >
                          Forgot your password?
                        </Typography>
                      </Grid>
                    </Grid>
                    <TextField
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Password"
                      className={classes.inputWidth}
                      error={!errors.password === null}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {errors.password != null ? (
                                <ErrorIcon color="primary" />
                              ) : showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography color="primary" variant="subtitle2">
                      {errors.password}
                    </Typography>
                  </Grid>

                  {/* <Grid item container justify='center' spacing={2}> */}
                  <Grid item align="center" mt={8}>
                    <Button type="submit" variant="contained" color="primary">
                      Sign In
                    </Button>
                  </Grid>
                  <Grid item align="center" mt={8}>
                    <Button
                      component={Link}
                      href={ssoURL}
                      // target="blank"
                      color="primary"
                    >
                      Sign In with SSO
                    </Button>
                  </Grid>
                  {/* </Grid> */}

                  <Grid item>
                    <Box mb={3}>
                      <Copyright />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <ErrorSnackBar
        open={openSnackBar}
        message={snackBarMessage}
        close={() => setOpenSnackBar(false)}
      />
    </main>
  );
};

export default LoginForm;
