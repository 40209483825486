import React from 'react';
import {
    Grid,
    Typography
} from '@material-ui/core';

function Integrations() {
    return (
        <>
            <Grid container direction="row" spacing={4} display="flex">
                <Grid item xs={12}><Typography><b>Integrations</b></Typography>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>Reserved for future use.</Grid>
                <Grid item xs={1}></Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Integrations;