import React, { useEffect, useState } from "react";
import ParticipantDelete from "../shiftBasedCalendar/ParticipantDelete";
import EventParticipant from "../shiftBasedCalendar/EventParticipant";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  MenuItem,
  InputLabel,
  Select,
  makeStyles,
  Box
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarRateIcon from "@material-ui/icons/StarRate";
import {
  // MANAGER_ADD_EMPLOYEE_TO_SHIFT,
  GET_ALL_USERS_AND_SHIFTS,
  // ADD_AND_DROP_OPTIONS_FOR_SHIFT,
  GET_SHIFTS_FOR_FLOAT_MANAGER,
  BATCH_CREATE_RESCHEDULE_OPTION
} from "../../api/gqlQueries";
import { format, sub, add } from "date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { userVar } from "../../cache";
// import RescheduleOptionsKey from "../rescheduling/RescheduleOptionsKey";
import ShiftSlackFloatTable from "../slacks/ShiftSlackFloatTable";
import isSameDay from "date-fns/isSameDay";
import moment from "moment-timezone";
import isEqual from "date-fns/isEqual";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 273,
    textOverflow: "ellipsis",
  },
  helpfulTip: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.primary.main,
    marginTop: -15,
  },
  button: {
    width: 75,
  },
  icon: {
    padding: 0,
    marginLeft: -7,
    marginTop: 2,
  },
}));

const MangAddEmployees = (props) => {
  const classes = useStyles();

  const {
    event,
    closeDialog,
    setShowToast,
    setShowErrorToast,
    singleShift,
    comment,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,

  } = props;

  const user = userVar();
  const officeId = user.office.id;

  useEffect(() => {
    if (singleShift) {
      setAvailableShifts([event]);
      setSelectedShift(event.id);
      setSelectedOffice(event.office.id);
      setRequestingOffices([event.office]);
    } else {
      let newOffices = [];
      event.officeData.forEach(office => {
        const match = newOffices.find(requestingOffice => (
          requestingOffice.id === office.office.id
        ));
        if (!match) {
          newOffices.push(office.office);
        }
      });
      setRequestingOffices(newOffices);
      setSelectedOffice(newOffices.length > 0
        ? newOffices[0].id
        : ''
      );
    }
  }, []);

  const [
    getShiftData,{ refetch }
  ] = useLazyQuery(GET_SHIFTS_FOR_FLOAT_MANAGER, {
    onCompleted(data) {
      console.log(data);
      const constraintsForNeededSkill = 
        data.offices[0].constraintSet.filter(constraint => (
          constraint.skill?.name === event.skill
        ));

      let availableShifts = data.shifts.filter(shift => {
        const start = moment(shift.start);
        const startInTimezone = start.clone().tz(data.offices[0].timezone);
        const startTime = startInTimezone.format('HH:mm');
        const end = moment(shift.end);
        const endInTimezone = end.clone().tz(data.offices[0].timezone);
        const endTime = endInTimezone.format('HH:mm');

        const matchesConstraint = constraintsForNeededSkill.find(
          (constraint) => (           
            constraint.starttime === `${startTime}:00` &&
            constraint.endtime === `${endTime}:00`
        ));

        return (
          isSameDay(new Date(shift.start), event.start) &&
          matchesConstraint
        );
      });
      
      availableShifts = availableShifts.map(shift => {
        return {
          id: shift.id,
          start: new Date(shift.start),
          end: new Date(shift.end)
        }
      });
      availableShifts.sort((a,b) => a.start - b.start);

      setAvailableShifts(availableShifts);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_SHIFTS_FOR_FLOAT_MANAGER lazyQuery. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [batchCreateAddOptions] = useMutation(BATCH_CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      console.log(data);
      refetch();
      usersandshiftsRefetch();
      setShowToast(true);
      closeDialog();
    }, 
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on BATCH_CREATE_RESCHEDULE_OPTION Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  const [requestingOffices, setRequestingOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [availableShifts, setAvailableShifts] = useState([]);
  const [selectedOfficeData, setSelectedOfficeData] = useState([]);
  const [currentShiftEmployees, setCurrentShiftEmployees] = useState([]);
  const [selectedEmployeeToAdd, setSelectedEmployeeToAdd] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { loading, error, data ,refetch:usersandshiftsRefetch} = useQuery(GET_ALL_USERS_AND_SHIFTS, {
    variables: {
      id: parseInt(officeId),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({variables: {message: "Error on GET_ALL_USERS_AND_SHIFTS Mutation. Environment: " + environment + ". Graphql " + error}});
    },
  });

  
  useEffect(() => {
    if (selectedOffice && !singleShift) {
      let rangeStart = sub(event.start, {days: 1});
      rangeStart = rangeStart.toISOString();
      let rangeEnd = add(event.end, {days: 1});
      rangeEnd = rangeEnd.toISOString();

      getShiftData({variables: {
        officeId: parseInt(selectedOffice),
        rangeStart: rangeStart,
        rangeEnd: rangeEnd
      }});

      const newSelectedOfficeData = event.officeData.filter(office => (
        office.office.id === selectedOffice
      ));
      setSelectedOfficeData(newSelectedOfficeData);
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (selectedShift && !singleShift) {
      const matching = availableShifts.find(shift => (
        shift.id === selectedShift
      ));

      const newSelectedOfficeData = selectedOfficeData.map(data => {
        const coveredByShift = (
          isEqual(data.start, matching.start) ||
          (data.start > matching.start &&
            data.start < matching.end
          )
        );

        if (coveredByShift) {
          return {
            ...data,
            affected: true
          }
        } else {
          return {
            ...data,
            affected: false
          }
        }
      });
      setSelectedOfficeData(newSelectedOfficeData)
    }
  }, [selectedShift]);

  let allEmployees;
  if (!loading && !error) {
    allEmployees = data.employees[0].employeeSet;
  }

  const renderNewAdditions = () => {
    if (employeesToAdd) {
      return employeesToAdd.map((employee) => (
        <Grid
          item
          container
          key={employee.id}
          xs={12}
          alignItems="center"
          spacing={1}
          style={{ margin: 5 }}
        >
          <ParticipantDelete
            key={employee.id}
            participant={employee}
            newAddition={true}
            employeesToAdd={employeesToAdd}
            setEmployeesToAdd={setEmployeesToAdd}
            currentShiftEmployees={currentShiftEmployees}
            setCurrentShiftEmployees={setCurrentShiftEmployees}
            officeId={officeId}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </Grid>
      ));
    }
  };

  const renderEmployeeOptions = (employees) => {
    if (employees && employees.length > 0) {
      const employeesAddedIds = employeesToAdd.map(employee => employee.id);

      let filteredEmployees = [];
      if (!singleShift && event.skill) {
        filteredEmployees = employees.filter(employee => (
          !employeesAddedIds.includes(employee.id) &&
          !employee.shifts.find(shift => (
            isSameDay(new Date(shift.start), event.start)
          )) &&
          employee.employeeskillSet.find(skill => (
            skill.skill.name === event.skill
          ))
        ));
      } else {
        filteredEmployees = employees.filter(employee => (
          !employeesAddedIds.includes(employee.id) &&
          !employee.shifts.find(shift => (
            isSameDay(new Date(shift.start), event.start)
          ))
        ));
      }

      return filteredEmployees.length > 0
        ? filteredEmployees.map((employee) => {
          return (
            <MenuItem key={employee.id} value={employee.id}>
              <EventParticipant
                participant={employee}
                showAvatar={false}
                showJobTitle={true}
                singleLine={true}
                addOption={true}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            </MenuItem>
          );
        })
        : <MenuItem value={-1}>
          <Typography>No Eligible Employees</Typography>
        </MenuItem>
    } else {
      return (
        <MenuItem value={-1}>
          <Typography>No Eligible Employees</Typography>
        </MenuItem>
      );
    }
  };

  const handleAddEmployee = (e) => {
    setSelectedEmployeeToAdd(e.target.value);
  };

  const temporaryAdd = () => {
    if (selectedEmployeeToAdd) {
      const employeeToAdd = allEmployees.find(
        (user) => user.id === selectedEmployeeToAdd
      );
      setEmployeesToAdd([...employeesToAdd, employeeToAdd]);
      setCurrentShiftEmployees([...currentShiftEmployees, employeeToAdd]);
      setSelectedEmployeeToAdd("");
      setErrorMessage("");
    } else {
      setErrorMessage("Must select employee to add");
    }
  };

  //ability to add note to the shift changes to go to notification
  const handleSubmit = () => {
    if (selectedEmployeeToAdd) {
      setErrorMessage("Click Add button to save selected employee to shift");
      return;
    } else {
      const input = employeesToAdd.map(employee => {
        return {
          optionType: 'ADD',
          status: 'ACCEPTED',
          individuals: [{
            employee: parseInt(employee.id),
            cost: 0,
            benefit: 0,
            actions: [{
              actionType: 'ADD',
              shift: parseInt(selectedShift)
            }]
          }],
          userGenerated: true
        }
      });
      batchCreateAddOptions({
        variables: {options: input}
      });
      refetch();
      usersandshiftsRefetch();
    }
  };

  const handleShiftChange = (e) => {
    const shiftId = e.target.value;
    setSelectedShift(shiftId);
    // const shift = constraints[index];
    // const exact = findExact(shift);
    // if (exact) {
    //   getRescheduleOptions({
    //     variables: {
    //       shifts: [parseInt(exact.id)],
    //     },
    //   });
    // }
  };

  const eligibleToSave = Boolean(employeesToAdd.length > 0);

  return (
    <>
      <Grid item container >
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item>
            <Typography variant="h2">
              Add Employees to {format(event.start, "dd MMM yyyy")}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            <Grid item>
              <IconButton color="secondary" onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container alignItems="flex-start">
          <Grid item xs={6} container direction='column' spacing={2}>
            <Grid item>
              <Typography variant="h5" style={{marginBottom: 8}}>
                Department:
              </Typography>
              <Select 
                variant="outlined" 
                name='selectedShift'
                value={selectedOffice}
                onChange={setSelectedOffice}
                className={classes.select}
                disabled={singleShift}
              >
                {requestingOffices.map(office => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{marginBottom: 8}}>
                Shift to Fill:
              </Typography>
              <Select 
                variant="outlined" 
                name='selectedShift'
                value={selectedShift}
                onChange={handleShiftChange}
                className={classes.select}
                disabled={singleShift}
              >
                {availableShifts.map(shift => (
                  <MenuItem key={shift.id} value={shift.id}>
                    {format(shift.start, 'HH:mm')} - {format(shift.end, 'HH:mm')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              {singleShift
                ? comment
                  ? <Typography variant='h5'>
                    Comment from requesting manager: {comment}
                  </Typography>
                  : null
                : <Typography variant='h5'>
                  Skill Needed: {event.skill}
                </Typography>
              }
            </Grid>
            <Grid 
              item 
              container 
              alignContent='flex-start'
              wrap="wrap" 
              style={{height: 275, overflowY: 'auto'}}
            >
              {renderNewAdditions()}
            </Grid>
          </Grid>
          <Grid item xs={6} >
            {selectedOfficeData.length > 0 &&
              <ShiftSlackFloatTable
                needsByOffice={selectedOfficeData}
                tooltip={false}
              />
            }
          </Grid>
        </Grid>

        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <InputLabel id="select-employee-1-label">
              <Typography variant="h5">Add Employee:</Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Select
              labelId="select-employee-1-label"
              id="select-employee-1"
              variant="outlined"
              value={selectedEmployeeToAdd}
              onChange={handleAddEmployee}
              className={classes.select}
            >
              {renderEmployeeOptions(allEmployees)}
            </Select>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={temporaryAdd}
              disabled={!selectedEmployeeToAdd}
            >
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setSelectedEmployeeToAdd("")}
              disabled={!selectedEmployeeToAdd}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid item style={{marginTop: 16}}>
            <Typography className={classes.error}>{errorMessage}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
        style={{ marginTop: 15 }}
      >
        <Grid item>
          <Typography variant="body2" className={classes.helpfulTip}>
            Save to activate shift changes
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={!eligibleToSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MangAddEmployees;
