import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import CheckIcon from "@material-ui/icons/Check";

const WeekLabel = ({ data, skills }) => {
  if (data.participants) {
    return (
      <Grid
        container
        direction="column"
      >
        <Grid item>
          <Typography variant="body1">{data.eventTitle}</Typography>
          <Typography variant="body2">{skills && skills.length>0 && skills.map(e=>e)}</Typography>
        </Grid>
      </Grid>
    );
  } else if (data.type === "softRequest") {
    return (
      <>
        {data.highPriority ? (
          <Grid
            container
            wrap="nowrap"
          >
            <Grid item>
              <PriorityHighIcon style={{ marginBottom: -2, marginRight: 5 }} />
            </Grid>
            <Grid item>
              <Typography variant="body1">{data.eventTitle}</Typography>
              <Typography variant="body2">{skills}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            wrap="nowrap"
          >
            <Grid item>
              <Typography variant="body1">{data.eventTitle}</Typography>
              <Typography variant="body2">{skills}</Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  } else if (data.status === "approved") {
    return (
      <Grid
        container
        wrap="nowrap"
      >
        <Grid item>
          <CheckIcon style={{ marginBottom: -2, marginRight: 5 }} />
        </Grid>
        <Grid item>
          <Typography variant="body1">{data.eventTitle}</Typography>
          <Typography variant="body2">{skills}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="body1">{data.eventTitle}</Typography>
          <Typography variant="body2">{skills}</Typography>
        </Grid>
      </Grid>
    );
  }
};

export default WeekLabel;
