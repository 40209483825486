import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  makeStyles,
  Container,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  Snackbar,
  Select,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import UpdateUser from "./UpdateUser";
import { useQuery, useMutation } from "@apollo/client";
import {
  Get_Employees,
  Delete_Employee,
  ALL_OFFICES,
  NOTIFY_DEVELOPERS,
  GET_ALL_CHILD_OFFICES,
  GET_ALL_CHILD_OFFICES_FOR_MANAGE_TEAM,
} from "../../api/gqlQueries";
import MuiAlert from "@material-ui/lab/Alert";
import { userVar } from "../../cache";
import { mapHiredShiftsManagerView } from "../../helpers/hiredShifts";
import ErrorSnackBar from "../errors/errorSnackBar";
import { format } from "date-fns";
import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  GridComponent,
  Filter,
  Inject,
  Page,
  Sort,
  CommandColumn,
  Toolbar,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import { getComponent } from "@syncfusion/ej2-base";
import "./Grid.css";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ClearIcon from "@mui/icons-material/Clear";
import { L10n } from "@syncfusion/ej2-base";

const useStyles = makeStyles(() => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

//test commit

function MangUsers(props) {
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [openForm, setOpenForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [enablePersistence, SetEnablePersistence] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const user = userVar();
  const allOffices = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setsnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_OFFICES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [selectedOffice, SetselectedOffice] = useState(
    user != null && user.office.name
  );

  const [officeid, setofficeid] = useState(user.office.id);
  const { loading, data, error, refetch } = useQuery(Get_Employees, {
    variables: {
      id:
        user.isAdmin || user.isPrimaryParentOffice
          ? officeid
            ? parseInt(officeid)
            : parseInt(user.office.id)
          : parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on Get_Employees Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const getChildOffices = useQuery(GET_ALL_CHILD_OFFICES_FOR_MANAGE_TEAM, {
    variables: {
      parent: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ALL_CHILD_OFFICES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    setofficeid(user.office.id);
    SetselectedOffice(
      props.selOfc ? props.selOfc : user != null && user.office.name
    );
  }, []);

  useEffect(() => {
    let o =
      !allOffices.loading &&
      allOffices.data.offices.find((i) => i.name === selectedOffice);
    user.isPrimaryParentOffice === true
      ? setofficeid(user.office.id)
      : setofficeid(o.id);
    return () => {
      setofficeid(user.office.id);
    };
  }, [selectedOffice]);

  const handleOfficeChange = (e) => {
    let o =
      !allOffices.loading && allOffices.data.offices.find((i) => i.name === e);
    e === "All" ? setofficeid(user.office.id) : setofficeid(o.id);
    SetselectedOffice(e);
  };

  const [deleteEmployee] = useMutation(Delete_Employee, {
    update(cache, { data: { deleteEmployee } }) {
      cache.evict({
        id: `EmployeeNode:${deleteEmployee.deletedId}`,
      });
    },
    onCompleted() {
      refetch();
      setsnackBarMessage("Employee successfully deleted");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on Delete_Employee Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [showDialog, setshowDialog] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [useridtodelete, setuseridtodelete] = useState("");

  const handleDeleteUser = () => {
    deleteEmployee({ variables: { id: parseInt(useridtodelete) } });
    setuseridtodelete("");
    setshowDialog(false);
  };

  const getShiftType = (assignmentSet) => {
    const officeIdToMatch = officeid ? officeid : user.office.id;
    let matchingAssignment = assignmentSet.find(
      (assignment) => assignment.office.id === officeIdToMatch
    );
    matchingAssignment = matchingAssignment
      ? matchingAssignment
      : assignmentSet[0];
    return mapHiredShiftsManagerView(matchingAssignment.hiredshiftSet);
  };

  const editTemplate = () => {
    return (
      <div>
        <EditIcon color="secondary" />
      </div>
    );
  };

  if (loading || getChildOffices.loading || allOffices.loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    return <div>Something went wrong please try again</div>;
  } else {
    L10n.load({
      "en-US": {
        grid: {
          EmptyRecord: "Loading the data",
          enablePersistence: false,
          clearFilters: true,
        },
      },
    });
    let childOfficeNames = getChildOffices.data.getChildren.map((e) => e.id);
    const rows =
      user.isPrimaryParentOffice === true
        ? [
            ...data.offices[0].employeeSet,
            ...getChildOffices.data.getChildren
              .map((e) => e.employeeSet)
              .flat(),
          ]
        : data.offices[0].employeeSet;

    let officeList = user.isPrimaryParentOffice
      ? allOffices.data.offices.filter(
          (e) => childOfficeNames.includes(e.id) || e.id === user.office.id
        )
      : allOffices.data.offices;

    const clearFilters = () => {
      let gridObj = getComponent(document.getElementById("data-grid"), "grid");
      if (gridObj) {
        gridObj.enablePersistence = false;
        gridObj.clearFiltering();
        gridObj.searchSettings.key = "";
        window.localStorage.setItem("gridGrid", "");
        window.localStorage.setItem("griddata-grid", "");
      }
    };

    const toolbarClick = () => {
      let gridObj = getComponent(document.getElementById("data-grid"), "grid");
      if (gridObj) {
        gridObj.excelExport();
      }
    };

    const searchOptions = {
      ignoreCase: false,
      operator: "contains",
      key: "",
    };

    const rowselect = (args) => {
      let selRecord = rows.find((e) => e.id === args.data.id);
      setCurrentUser(selRecord);
      setOpenForm(!openForm);
      setTimeout(() => {
        args.data = null;
      }, 2000);
    };

    const filteredRows = rows
      .map((e) => {
        let empAssignment =
          e.assignmentSet &&
          e.assignmentSet.length > 0 &&
          e.assignmentSet.filter(
            (x) =>
              x.endDate === null ||
              format(new Date(x.endDate), "yyyy-MM-dd") >
                format(new Date(), "yyyy-MM-dd")
          );

        if (empAssignment && empAssignment.length > 0) {
          if (
            user.isPrimaryParentOffice === false &&
            empAssignment.find((x) => x.office.name === selectedOffice)
          ) {
            return {
              id: e.id,
              name: e.firstName + " " + e.lastName,
              email: e.email,
              phone: e.primaryPhone,
              hiredShift: getShiftType(e.assignmentSet),
              department: empAssignment.find(
                (x) => x.office.name === selectedOffice
              ).office.name,
              employmentType: e.employeeType.name,
              role: e.assignmentSet.find(
                (e) => e.office.name === selectedOffice
              ).role.name,
              certifications: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "CERTIFICATION" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
              training: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "TRAINING" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
              license: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "LICENSE" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
              jobType: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "JOB_TYPE" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
            };
          } else {
            return {
              id: e.id,
              name: e.firstName + " " + e.lastName,
              email: e.email,
              phone: e.primaryPhone,
              hiredShift: getShiftType(e.assignmentSet),
              department: empAssignment.find((x) => x.primary === true).office
                .name,
              employmentType: e.employeeType.name,
              role: empAssignment.find((x) => x.primary === true).role.name,
              certifications: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "CERTIFICATION" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
              training: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "TRAINING" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
              license: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "LICENSE" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
              jobType: e.employeeskillSet
                .filter(
                  (s) =>
                    s.skill.variety === "JOB_TYPE" &&
                    new Date(e.expiration) > new Date()
                )
                .map((s) => s.name)
                .join(","),
            };
          }
        } else {
          return false;
        }
      })
      .filter(Boolean);

    const toolbarOptions = ["Search", "ColumnChooser"];

    let uniqueEmployees = [];
    filteredRows.map(
      (emp) =>
        !uniqueEmployees.find((e) => e.id === emp.id) &&
        uniqueEmployees.push(emp)
    );

    return (
      <main variant="body">
        {!openForm ? (
          <Container className={classes.root} maxWidth={false}>
            <ErrorSnackBar
              open={openSnackBar}
              message={errorSnackBarMessage}
              close={() => setOpenSnackBar(false)}
            />
            <Snackbar
              open={snackbaropen}
              autoHideDuration={3000}
              onClose={() => setsnackbaropen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => {
                  setsnackbaropen(false);
                  setsnackBarMessage("");
                }}
                severity="success"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Snackbar
              open={errorsnackbaropen}
              autoHideDuration={6000}
              onClose={() => seterrorsnackbaropen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => seterrorsnackbaropen(false)}
                severity="error"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Dialog open={showDialog} fullWidth maxWidth="xs">
              <DialogContent
                style={{
                  padding: 20,
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                <Typography>
                  Are you sure you want to delete this Employee?
                </Typography>
                <br />
                <br />
                <Button
                  onClick={handleDeleteUser}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() => setshowDialog(false)}
                  color="primary"
                  variant="outlined"
                >
                  No
                </Button>
              </DialogContent>
            </Dialog>

            <Grid container className={classes.headerSpacing}>
              <Grid item xs={6}>
                <Typography variant="h3">Manage Team</Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={2}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setOpenForm(!openForm);
                      setCurrentUser(null);
                    }}
                  >
                    Add User
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {!user.isAdmin
                    ? selectedOffice
                      ? selectedOffice
                      : user.office.name
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box mt={2} mb={1}>
                  {user.isAdmin && user.isPrimaryParentOffice === false && (
                    <Select
                      id="selectoffice"
                      name="selectoffice"
                      value={selectedOffice}
                      variant="outlined"
                      onChange={(e) => handleOfficeChange(e.target.value)}
                      disabled={user.isPrimaryParentOffice ? true : false}
                    >
                      <MenuItem key="All" value="All">
                        All
                      </MenuItem>
                      {officeList.map((i) => (
                        <MenuItem key={i.id} value={i.name}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item style={{ textAlign: "right" }}>
              <Button onClick={clearFilters} color="secondary">
                <ClearIcon /> Clear Filters
              </Button>
              <Button onClick={toolbarClick} color="secondary">
                <ImportExportIcon />
                Excel Export
              </Button>
            </Grid>
            <GridComponent
              id="data-grid"
              dataSource={uniqueEmployees}
              allowPaging={true}
              pageSettings={{
                pageSize: 10,
                pageSizes: true,
              }}
              allowSorting={true}
              rowSelected={rowselect}
              enableHover={true}
              rowHeight={35}
              height={400}
              allowExcelExport={true}
              enablePersistence={true}
              showColumnChooser={true}
              toolbar={toolbarOptions}
              searchSettings={searchOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  textAlign="Center"
                  visible={false}
                  isPrimaryKey={true}
                  showInColumnChooser={false}
                ></ColumnDirective>
                <ColumnDirective
                  field="name"
                  headerText="Name"
                  textAlign="Center"
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="email"
                  headerText="Email"
                  textAlign="Center"
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="phone"
                  headerText="Phone"
                  textAlign="Center"
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="role"
                  headerText="Application Role"
                  textAlign="Center"
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="employmentType"
                  headerText="Employment Type"
                  textAlign="Center"
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="department"
                  headerText="Department"
                  textAlign="Center"
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="certifications"
                  headerText="Certifications"
                  textAlign="Center"
                  visible={false}
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="training"
                  headerText="Training"
                  textAlign="Center"
                  visible={false}
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="license"
                  headerText="License"
                  textAlign="Center"
                  visible={false}
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  field="jobType"
                  headerText="Job Type"
                  textAlign="Center"
                  visible={false}
                  width="200"
                ></ColumnDirective>
                <ColumnDirective
                  headerText="Edit"
                  template={editTemplate}
                  textAlign="Center"
                  showInColumnChooser={false}
                  width="200"
                ></ColumnDirective>
              </ColumnsDirective>

              <Inject
                services={[
                  Sort,
                  Filter,
                  CommandColumn,
                  Toolbar,
                  ColumnChooser,
                  ExcelExport,
                  Page,
                ]}
              />
            </GridComponent>
          </Container>
        ) : (
          <UpdateUser
            refetch={refetch}
            user={currentUser}
            open={true}
            selectedOfc={officeid ? officeid : user.office.id}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setErrorSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            getChildOffices={getChildOffices}
            environment={environment}
          />
        )}
      </main>
    );
  }
}
export default MangUsers;
