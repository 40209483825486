import React, { useState } from "react";
import { Typography, Box, CircularProgress, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  GET_ONLY_USER_SHIFTS,
  GET_EMPLOYEE_AVAILABILITY,
  GET_PREFERENCES,
  TOTAL_HOURS_THIS_SCHEDULE_FOR_EMPLOYEE,
  GET_SCHEDULE_PERIODS,
} from "../../api/gqlQueries";
import { schedulePeriodToUpdateAfterUpdateSlacks, userVar } from "../../cache";
import { format, add, sub } from "date-fns";
import { useQuery, useLazyQuery } from "@apollo/client";

const ScheduleInfo = (props) => {
  const history = useHistory();
  const user = userVar();
  const today = format(new Date(), "yyyy-MM-dd") + "T00:00:00";
  const twoweeksfromtoday =
    format(add(new Date(), { weeks: 1 }), "yyyy-MM-dd") + "T00:00:00";
  const userShifts = useQuery(GET_ONLY_USER_SHIFTS, {
    variables: {
      employeeId: parseInt(user.id),
      rangeStart: today,
      rangeEnd: twoweeksfromtoday,
    },
  });

  const [currentSchedulePeriodData, SetCurrentSchedulePeriodData] = useState();

  const dateToday = format(new Date(), "MM-dd-yyyy");

  const employeeAvailability = useQuery(GET_EMPLOYEE_AVAILABILITY, {
    variables: {
      employeeId: parseInt(user.id),
    },
  });

  const employeePreferences = useQuery(GET_PREFERENCES, {
    variables: {
      id: parseInt(user.id),
      office: parseInt(user.office.id),
    },
  });

  const [totalHoursThisSchedule] = useLazyQuery(
    TOTAL_HOURS_THIS_SCHEDULE_FOR_EMPLOYEE,
    {
      onCompleted(d) {
        SetCurrentSchedulePeriodData(d.shiftAnalytics);
      },
    }
  );

  const SchedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId: parseInt(user.office.id),
    },
    onCompleted(d) {
      let currentSchedulePeriod = d.schedulePeriods.find(
        (e) => new Date(e.start) <= new Date() && new Date(e.end) >= new Date()
      );

      currentSchedulePeriod &&
        totalHoursThisSchedule({
          variables: {
            period: parseInt(currentSchedulePeriod.id),
            employee: parseInt(user.id),
          },
        });
    },
  });

  if (
    userShifts.loading ||
    employeeAvailability.loading ||
    employeePreferences.loading ||
    SchedulePeriods.loading
  ) {
    return <CircularProgress color="primary" />;
  } else {
    const userShiftPrefs =
      employeePreferences.data.preferences &&
      employeePreferences.data.preferences.length > 0 &&
      employeePreferences.data.preferences.filter(
        (e) =>
          e.type.type === "DAYS" ||
          e.type.type === "NIGHTS" ||
          e.type.type === "SWINGS" ||
          e.type.type === "24HR"
      );
    const preferedShift =
      userShiftPrefs &&
      userShiftPrefs.reduce((a, b) => (a = a.value > b.value ? a : b), 0);

    const availabilityAfterToday =
      employeeAvailability.data.availability &&
      employeeAvailability.data.availability.length > 0 &&
      employeeAvailability.data.availability.filter(
        (e) => new Date(e.firstday) >= new Date()
      );

    availabilityAfterToday &&
      availabilityAfterToday.length > 0 &&
      availabilityAfterToday.sort(
        (a, b) => new Date(b.firstday) > new Date(a.firstday)
      );

    let mostRecentSchedule =
      SchedulePeriods.data.schedulePeriods &&
      SchedulePeriods.data.schedulePeriods.length > 0 &&
      SchedulePeriods.data.schedulePeriods.filter(
        (e) =>
          e.status === "PUBLISHED" ||
          e.status === "MANAGER_DRAFT" ||
          e.status === "MANAGER DRAFT" ||
          e.status === "DRAFT" ||
          e.status === "REQUESTING_FLOATS" ||
          e.status === "REQUESTING_ADDITIONAL_FLOATS"
      );
    mostRecentSchedule &&
      mostRecentSchedule.length > 0 &&
      mostRecentSchedule.sort((a, b) => new Date(b.start) - new Date(a.start));

    return (
      <div>
        <div>
          <Box m={2}>
            <Typography variant="h4">SCHEDULE INFO {}</Typography>
          </Box>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => history.push("/Schedule")}
        >
          {" "}
          <Typography variant="body1">
            Shifts This Week : {userShifts.data.userShifts.length}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => history.push("/Schedule")}
        >
          {" "}
          <Typography variant="body1">
            Next Break :{" "}
            {availabilityAfterToday[0]
              ? format(
                  new Date(availabilityAfterToday[0].firstday),
                  "MMM-dd-yyyy"
                ) +
                "(" +
                availabilityAfterToday[0]?.type.name +
                ")"
              : "None Scheduled"}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() =>
            user.isSchedulable
              ? history.push("/Preferences")
              : history.push("/Schedule")
          }
        >
          {" "}
          <Typography variant="body1">
            Preferred Shift :{" "}
            {user.isSchedulable
              ? preferedShift.type
                ? preferedShift.type.type
                : "N/A"
              : "N/A"}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => history.push("/Schedule")}
        >
          {" "}
          <Typography variant="body1">
            Scheduled Through :{" "}
            {mostRecentSchedule &&
              mostRecentSchedule.length > 0 &&
              format(new Date(mostRecentSchedule[0].end), "MMM-dd-yyyy")}
          </Typography>
        </div>
        <div
          style={{
            margin: 10,
            background: props.lightBackground,
            border: "1px solid" + props.borderColor,
            padding: 8,
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={() => history.push("/Schedule")}
        >
          {" "}
          <Typography variant="body1">
            Hours This Schedule :
            {user.isSchedulable
              ? currentSchedulePeriodData &&
                currentSchedulePeriodData.length > 0 &&
                currentSchedulePeriodData[0].officeHours
                  .map((e) => e.totalHours)
                  .reduce((a, b) => a + b, 0)
              : "N/A"}{" "}
          </Typography>
        </div>
      </div>
    );
  }
};

export default ScheduleInfo;
