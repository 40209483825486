export const mapPrefsFromBackend = (preferences) => {
    const newPreferences = {};

    const mappedPreferences = {
        'ADDITIONAL': 'workAdditionalShifts',
        'DAYS': 'day',
        'NIGHTS': 'night',
        'SWINGS': 'swing',
        '24-HRS': 'allDayShift',
        'ONEDAY': 'oneDay',
        'TWODAYS': 'twoDays',
        'THREEDAYS': 'threeDays',
        'FOURDAYS': 'fourDays',
        'MONDAY': 'monday',
        'TUESDAY': 'tuesday',
        'WEDNESDAY': 'wednesday',
        'THURSDAY': 'thursday',
        'FRIDAY': 'friday',
        'SATURDAY': 'saturday',
        'SUNDAY': 'sunday',
        'OT': 'overtime'
    }
    
    preferences.forEach(preference => {
        const newType = mappedPreferences[preference.type.type];
        newPreferences[newType] = preference.value;
    });
    return newPreferences;
};

export const mapPrefsToBackend = (
    newPreferences, originalPrefs, prefTypesToBeCreated, userId
) => {
    const original = [...originalPrefs];
    const mappedPreferences = {
        'ADDITIONAL': 'workAdditionalShifts',
        'DAYS': 'day',
        'NIGHTS': 'night',
        'SWINGS': 'swing',
        '24-HRS': 'allDayShift',
        'ONEDAY': 'oneDay',
        'TWODAYS': 'twoDays',
        'THREEDAYS': 'threeDays',
        'FOURDAYS': 'fourDays',
        'MONDAY': 'monday',
        'TUESDAY': 'tuesday',
        'WEDNESDAY': 'wednesday',
        'THURSDAY': 'thursday',
        'FRIDAY': 'friday',
        'SATURDAY': 'saturday',
        'SUNDAY': 'sunday',
        'OT': 'overtime'
    }

    const update = original.map(preference => {
        const newType = mappedPreferences[preference.type.type];
        let value;
        if (parseInt(newPreferences[newType]) < -2) {
            value = -2;
        } else if (parseInt(newPreferences[newType]) > 2) {
            value = 2;
        } else if (parseInt(newPreferences[newType]) >= -2 && parseInt(newPreferences[newType]) <= 2) {
            value = parseInt(newPreferences[newType]);
        } else {
            value = 0;
        }
        return {id: preference.id, value: value}
    });

    const create = prefTypesToBeCreated.map(type => {
        const newType = mappedPreferences[type.type];
        let value;
        if (parseInt(newPreferences[newType]) < -2) {
            value = -2;
        } else if (parseInt(newPreferences[newType]) > 2) {
            value = 2;
        } else if (parseInt(newPreferences[newType]) >= -2 && parseInt(newPreferences[newType]) <= 2) {
            value = parseInt(newPreferences[newType]);
        } else {
            value = 0;
        }
        return {employee: userId, type: type.id, value: value}
    });

    return {update, create};
};

export const formatSoftRequests = (requests) => {
    return requests.map(request => {
        const withPriority = {
            id: request.id,
            start: request.date,
            eventTitle: 'Soft Time Off',
            allDay: true,
            type: 'softRequest',
            highPriority: false,
            value: request.value,
            employee_id: request.employee_id
        };

        if (request.value > 5) {
            withPriority.highPriority = true;
        }
        return withPriority;
    });
};

export const handleMostImportantChanges = (values) => {        
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const categories = ['Shift Type', 'Days Off', 'Days Most Prefer', 'Days Least Prefer'];

    const categoriesToValueNames = {
        'Shift Type': ['day', 'night', 'swing', 'allDayShift'],
        'Days Most Prefer': days,
        'Days Least Prefer': days,
        'Days Off': ['oneDay', 'twoDays', 'threeDays', 'fourDays']
    }
    const newValues = {};
    categories.forEach(category => {
        if (values.mostImportant.includes(category)) {
            categoriesToValueNames[category].forEach(valueName => {
                if (category === 'Days Most Prefer' && values[valueName] > 0){
                    newValues[valueName] = 2
                } else if (category === 'Days Least Prefer' && values[valueName] < 0){
                    newValues[valueName] = -2
                } else if (category !== 'Days Most Prefer' && category !== 'Days Least Prefer') {
                    newValues[valueName] = values[valueName] * 2
                }
                
                if (newValues[valueName] > 2){
                    newValues[valueName] = 2
                } else if (newValues[valueName] < -2){
                    newValues[valueName] = -2
                }
            });
        } else {
            categoriesToValueNames[category].forEach(valueName => {
                if (values[valueName] === 0){
                    newValues[valueName] = 0
                } else if (category === 'Days Most Prefer' && values.mostImportant.includes('Days Least Prefer') && values[valueName] > 0){
                    newValues[valueName] = 1;
                } else if (category === 'Days Least Prefer' && values.mostImportant.includes('Days Most Prefer') && values[valueName] < 0){
                    newValues[valueName] = -1;
                } else if (category !== 'Days Most Prefer' && category !== 'Days Least Prefer') {
                    newValues[valueName] = values[valueName] > 0 ? 1 : -1
                } else if (category === 'Days Least Prefer' && !values.mostImportant.includes('Days Most Prefer')){
                    newValues[valueName] = values[valueName] > 0 ? 1 : -1
                } else if (category === 'Days Most Prefer' && !values.mostImportant.includes('Days Least Prefer')){
                    newValues[valueName] = values[valueName] > 0 ? 1 : -1
                }
            });
        }
    });
    return newValues;
}

// export const mapPrefsFromGuide = (prefs) => {
//     const {
//         mostImportant,
//         shiftTypes,
//         weekends,
//         daysInARow,
//         daysMostPrefer,
//         daysLeastPrefer,
//         starsPlaced
//     } = prefs;

//     const preferenceObject = {};

//     const numberOfImportant = mostImportant.length;
//     let points = [];
//     let days = [];

//     const dayNameStrings = [
//         'Monday',
//         'Tuesday',
//         'Wednesday',
//         'Thursday',
//         'Friday',
//         'Saturday',
//         'Sunday'
//     ];

//     if (shiftTypes.length > 0) {
//         if (shiftTypes.includes('Day')){
//             preferenceObject.day = true;
//         } else {
//             preferenceObject.day = false;
//         }

//         if (shiftTypes.includes('Night')){
//             preferenceObject.night = true;
//         } else {
//             preferenceObject.night = false;
//         }

//         if (shiftTypes.includes('Swing')){
//             preferenceObject.swing = true;
//         } else {
//             preferenceObject.swing = false;
//         }
//     } else {
//         preferenceObject.day = false;
//         preferenceObject.night = false;
//         preferenceObject.swing = false;
//     }
    
//     preferenceObject.weekends = (weekends === 'nothing' || weekends === false) ? false : true;
//     preferenceObject.daysInARow = daysInARow ? parseInt(daysInARow) : 1;

//     dayNameStrings.forEach((day, index) => {
//         if (daysMostPrefer.includes(day) && !daysLeastPrefer.includes(day)) {
//             days[index] = 1;
//         } else if (daysMostPrefer.includes(day) && daysLeastPrefer.includes(day) && mostImportant.includes('Days Most Prefer')) {
//             days[index] = 1;
//         } else if (daysLeastPrefer.includes(day) && !daysMostPrefer.includes(day)) {
//             days[index] = -1;
//         } else if (daysMostPrefer.includes(day) && daysLeastPrefer.includes(day) && mostImportant.includes('Days Least Prefer')) {
//             days[index] = -1;
//         } else {
//             days[index] = 0;
//         }
//     });

//     preferenceObject.days = days;

//     switch (numberOfImportant) {
//         case (0):
//             points = [0, 0, 0, 0, 0];
//             break;
//         case (1):
//             points = [7, 0, 0, 0, 0];
//             break;
//         case (2):
//             points = [7, 4, 0, 0, 0];
//             break;
//         case (3):
//             points = [7, 4, 1, 0, 0];
//             break;
//         case (4):
//             points = [6, 4, 2, 1, 0];
//             break;
//         case (5):
//             points = [5, 4, 3, 2, 1];
//             break;
//     }

//     mostImportant.forEach((category, index) => {
//         if (category === 'Shift Type') {
//             const stars = starsPlaced.filter(starCategory => starCategory === category).length;
//             preferenceObject.shiftTypeValue = points[index] + stars;
//         } else if (category === 'Weekends') {
//             const stars = starsPlaced.filter(starCategory => starCategory === category).length;
//             preferenceObject.weekendValue = points[index] + stars;
//         } else if (category === 'Days in a Row') {
//             const stars = starsPlaced.filter(starCategory => starCategory === category).length;
//             preferenceObject.daysInARowValue = points[index] + stars;
//         } else if (category === 'Days Most Prefer') {
//             const stars = starsPlaced.filter(starCategory => starCategory === category).length;
//             preferenceObject.daysMostPrefer = points[index] + stars;
//         } else if (category === 'Days Least Prefer') {
//             const stars = starsPlaced.filter(starCategory => starCategory === category).length;
//             preferenceObject.daysLeastPrefer = points[index] + stars;
//         }
//     });

//     return preferenceObject;
// };

// export const defaultTempPreferences = {
//     willingToWorkDifShift: false,
//     day: false,
//     night: false,
//     swing: false,
//     weekends: false,
//     daysInARow: 1,
//     days: [0,0,0,0,0,0,0],
//     categories: [
//         {
//             name: 'Shift Type',
//             mostImportant: false,
//             order: 0,
//             stars: 0
//         },
//         {
//             name: 'Days in a Row', 
//             mostImportant: false,
//             order: 1,
//             stars: 0
//         },
//         {
//             name: 'Weekends',
//             mostImportant: false,
//             order: 2,
//             stars: 0
//         },
//         {
//             name: 'Days Most Prefer', 
//             mostImportant: false,
//             order: 3,
//             stars: 0
//         },
//         {
//             name: 'Days Least Prefer', 
//             mostImportant: false,
//             order: 4,
//             stars: 0
//         }
//     ]
// };

// export const mapPrefsToQuizAnswers = (preferences) => {
//     // console.log(preferences)
//     const {
//         day, 
//         night, 
//         swing, 
//         weekends, 
//         daysInARow, 
//         days
//     } = preferences;

//     const shiftTypes = [];
//     if (day || night || swing) {
//         day && shiftTypes.push('Day');
//         night && shiftTypes.push('Night');
//         swing && shiftTypes.push('Swing');
//     }

//     const dayNameStrings = [
//         'Monday',
//         'Tuesday',
//         'Wednesday',
//         'Thursday',
//         'Friday',
//         'Saturday',
//         'Sunday'
//     ];

//     const daysMostPreferArray = [];
//     const daysLeastPreferArray = [];

//     days.forEach((day, index) => {
//         if (day === 1) {
//             daysMostPreferArray.push(dayNameStrings[index]);
//         } else if (day === -1) {
//             daysLeastPreferArray.push(dayNameStrings[index]);
//         }
//     });

//     const final = {
//         shiftTypes, 
//         weekends: weekends, 
//         daysInARow: parseInt(daysInARow), 
//         daysMostPrefer: daysMostPreferArray, 
//         daysLeastPrefer: daysLeastPreferArray
//     };
//     return final;
// };

// export const formatJSON = (prefs) => {
//     const newCategories = [
//         {
//             name: 'Shift Type',
//             mostImportant: prefs.mostImportant.includes('Shift Type'),
//             order: prefs.mostImportant.indexOf('Shift Type'),
//             stars: prefs.starsPlaced.filter(category => category === 'Shift Type').length
//         },
//         {
//             name: 'Days in a Row', 
//             mostImportant: prefs.mostImportant.includes('Days in a Row'),
//             order: prefs.mostImportant.indexOf('Days in a Row'),
//             stars: prefs.starsPlaced.filter(category => category === 'Days in a Row').length
//         },
//         {
//             name: 'Weekends',
//             mostImportant: prefs.mostImportant.includes('Weekends'),
//             order: prefs.mostImportant.indexOf('Weekends'),
//             stars: prefs.starsPlaced.filter(category => category === 'Weekends').length
//         },
//         {
//             name: 'Days Most Prefer', 
//             mostImportant: prefs.mostImportant.includes('Days Most Prefer'),
//             order: prefs.mostImportant.indexOf('Days Most Prefer'),
//             stars: prefs.starsPlaced.filter(category => category === 'Days Most Prefer').length
//         },
//         {
//             name: 'Days Least Prefer', 
//             mostImportant: prefs.mostImportant.includes('Days Least Prefer'),
//             order: prefs.mostImportant.indexOf('Days Least Prefer'),
//             stars: prefs.starsPlaced.filter(category => category === 'Days Least Prefer').length
//         }
//     ];

//     return {
//         willingToWorkDifShift: prefs.willingToWorkDifShift,
//         day: prefs.day,
//         night: prefs.night,
//         swing: prefs.swing,
//         weekends: prefs.weekends,
//         daysInARow: prefs.daysInARow,
//         days: prefs.days,
//         categories: [...newCategories]
//     };
// };