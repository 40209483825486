import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  IconButton,
  List,
  ListItem,
  Box,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { format, addDays, isSameDay } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useQuery } from "@apollo/client";
import { Get_Employees, FIND_SKILL_TYPE } from "../../api/gqlQueries";
import DateFnsUtils from "@date-io/date-fns";
import { userVar } from "../../cache";
import moment from "moment-timezone";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles(() => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const EmployeeAllocation = (props) => {
  const classes = useStyles();
  const user = userVar();
  const [selectedDate, SetSelectedDate] = useState(new Date());
  const [rows, setrows] = useState([]);
  const [selectSkill, SetSelectSkill] = useState(-1);
  const [rowIdExpanded, SetRowIdExpanded] = useState();
  const [showMoreSkills, SetShowMoreSkills] = useState(false);
  const [empId, SetEmpId] = useState("");
  const [rowHeight, SetRowHeight] = useState(40);
  const [selectedEmployee, SetSelectedEmployee] = useState(null);

  const { loading, data, error, refetch } = useQuery(Get_Employees, {
    variables: {
      id: parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
    },
  });

  const allSkills = useQuery(FIND_SKILL_TYPE);

  const columns = [
    {
      field: "employee",
      width: 200,
      headerName: "Employee",
      valueGetter: (params) =>
        `${params.row.lastName || ""} ${params.row.firstName || ""}`,
    },
    {
      field: "skills",
      width: 200,
      headerName: "Skills",
      renderCell: (params) => {
        let list = (
          <List>
            {params.row.skills.length > 0 &&
              params.row.skills
                .filter(
                  (i) => i.variety === "JOB_TYPE" || i.variety === "TRAINING"
                )
                .map((x) => x.name)[0]}
          </List>
        );
        return list;
      },
    },
    {
      field: "department",
      width: 250,
      headerName: "Primary Department",
      renderCell: (params) => (
        <Typography>
          {
            params.row.assignmentSet.filter((x) => x.primary === true)[0].office
              .name
          }
        </Typography>
      ),
    },
    {
      field: "secondary department",
      width: 250,
      headerName: "Secondary Departments",
      renderCell: (params) => (
        <Typography>
          {params.row.assignmentSet
            .filter((x) => x.primary === false)
            .map((e) => e.office.name)}
        </Typography>
      ),
    },
    {
      field: "shift",
      width: 300,
      headerName: "Hired Shift",
      renderCell: (params) => (
        <Typography>
          {params.row.assignmentSet.filter((x) => x.primary === true)[0]
            .hiredshiftSet.length > 0
            ? params.row.assignmentSet
                .filter((x) => x.primary === true)[0]
                .hiredshiftSet.map((x) => x.type.type)
            : "Any"}
        </Typography>
      ),
    },
    // {
    //   field: "viewMore",
    //   width: 300,
    //   headerName: "View More Skills",
    //   renderCell: (params) => (
    //     <IconButton
    //       onClick={() => {
    //         SetRowIdExpanded(params.row.id);
    //         SetShowMoreSkills(true);
    //       }}
    //     >
    //       <VisibilityIcon />
    //     </IconButton>
    //   ),
    // },
  ];

  useEffect(() => {
    !loading && setrows(data.offices[0].employeeSet);
  }, [!loading]);

  useEffect(() => {
    SetSelectedEmployee(null);
    let newRows =
      !loading &&
      (selectSkill != -1
        ? data.offices[0].employeeSet.filter((e) =>
            e.skills.find((x) => parseInt(x.id) === parseInt(selectSkill))
          )
        : data.offices[0].employeeSet);
    !loading && setrows(newRows);
  }, [selectSkill]);

  useEffect(() => {
    let emp =
      !loading && data.offices[0].employeeSet.find((e) => e.id === empId);
    SetSelectedEmployee(emp);
  }, [empId != "", empId]);

  const filterbySkills = (val) => {
    SetSelectSkill(parseInt(val));
  };
  if (loading || allSkills.loading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Grid container className={classes.headerSpacing}>
          <Grid item xs={6}>
            <Typography variant="h3">Employee Allocation</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h5">Filter By Skill</Typography>
            <Select
              variant="outlined"
              name="selectSkill"
              value={selectSkill}
              onChange={(e) => filterbySkills(e.target.value)}
            >
              <MenuItem key={-1} value={-1}>
                Select Skill
              </MenuItem>
              {allSkills.data &&
                allSkills.data.skills &&
                allSkills.data.skills.length > 0 &&
                allSkills.data.skills
                  .filter(
                    (e) =>
                      (e.office === null ||
                        (e.office && e.office.id === user.office.id)) &&
                      (e.variety === "JOB_TYPE" || e.variety === "TRAINING")
                  )
                  .map((x, index) => (
                    <MenuItem key={index} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 400 }}>
              <DataGrid
                className={classes.dt}
                rows={rows}
                columns={columns}
                autoPageSize
                //rowsPerPageOptions={[10, 20, 30]}
                rowHeight={40}
              />
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            {selectedEmployee && (
              <>
                <Box style={{ border: "1px solid black" }}>
                  <Typography>Selected Employee Skills:</Typography>
                  <br />
                  <Typography variant="h5">
                    <u>
                      {selectedEmployee.firstName +
                        " " +
                        selectedEmployee.lastName}
                    </u>
                  </Typography>
                  <List>
                    {selectedEmployee.skills.map((e, index) => (
                      <MenuItem key={index}>{e.name}</MenuItem>
                    ))}
                  </List>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
};

export default EmployeeAllocation;
