import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Grid,
  CssBaseline,
  InputAdornment,
  IconButton,
  Snackbar,
  Paper,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@material-ui/icons/Error";
import { useMutation, useApolloClient } from "@apollo/client";
import { UPDATE_PASSWORD, NOTIFY_DEVELOPERS } from "../../api/gqlQueries";
import { isLoggedInVar, userVar } from "../../cache";
import { useHistory } from "react-router-dom";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "160px",
    display: "flex",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "450px",
    margin: "auto",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "40px",
    height: "40px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  inputWidth: {
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().required("Required"),
});

const FirstTimeUser = ({ user }) => {
  const history = useHistory();
  const client = useApolloClient();
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [showPassword, setShowPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });
  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_PASSWORD Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <main variant="body">
        <Grid className={classes.root}>
          <CssBaseline />
          <Grid className={classes.paper} component={Paper}>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                updatePassword({
                  variables: {
                    id: parseInt(user.id),
                    input: {
                      password: values.password,
                      hasChangedDefaultPassword: true,
                    },
                  },
                });
                setShowToast(true);
                values.password = "";
                values.confirmPassword = "";
                isLoggedInVar(false);
                userVar({});
                sessionStorage.removeItem("jwt");
                sessionStorage.clear("jwt");
                client.cache.reset();
                history.push("/");
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={3}>
                    <Snackbar
                      open={showToast}
                      autoHideDuration={3000}
                      onClose={() => setShowToast(false)}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <MuiAlert
                        elevation={6}
                        onClose={() => setShowToast(false)}
                        severity="success"
                      >
                        <Typography>
                          Password Changed! Redirecting you to login.
                        </Typography>
                      </MuiAlert>
                    </Snackbar>
                    <Grid item align="center">
                      <Typography variant="h3">Change Password</Typography>
                    </Grid>
                    <Grid item>
                      <Grid>
                        <Typography variant="subtitle2">
                          New Password*
                        </Typography>
                      </Grid>
                      <TextField
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        placeholder="New Password"
                        className={classes.inputWidth}
                        error={!errors.password === null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {errors.password != null ? (
                                  <ErrorIcon color="primary" />
                                ) : showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.email}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Grid container direction="row">
                        <Grid item xs={12} align="left">
                          <Typography variant="subtitle2">
                            Confirm Password*
                          </Typography>
                        </Grid>
                      </Grid>
                      <TextField
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        onChange={handleChange}
                        value={values.confirmPassword}
                        placeholder="Confirm New Password"
                        className={classes.inputWidth}
                        error={!errors.password === null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {errors.password != null ? (
                                  <ErrorIcon color="primary" />
                                ) : showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography color="primary" variant="subtitle2">
                        {errors.password}
                      </Typography>
                    </Grid>

                    <Grid item align="center" mt={8}>
                      <Button type="submit" variant="contained" color="primary">
                        Change Password
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </main>
      <ErrorSnackBar
        open={openSnackBar}
        message={snackBarMessage}
        close={() => setOpenSnackBar(false)}
      />
    </>
  );
};

export default FirstTimeUser;
