import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { SketchPicker } from "react-color";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  UPDATE_LOCATION,
  PUBLICLOCATION,
  FILE_UPLOAD,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import { appsettingsVar } from "../../cache";
import UploadImageToS3 from "./UploadImagetoS3";
import ErrorSnackBar from "../errors/errorSnackBar";

function ApplicationUISettings() {
  const environment = process.env.NODE_ENV;
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });
  const locationSettings = useQuery(PUBLICLOCATION, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again.."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on PUBLICLOCATION Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const currentColor = useReactiveVar(appsettingsVar);
  const [clr, setClr] = useState(currentColor.color);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_LOCATION mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleColorChange = () => {
    appsettingsVar({ color: clr });
    updateLocation({
      variables: {
        id: parseInt(locationSettings.data.locationPublicSettings[0].id),
        input: { hexcolor: currentColor.color },
      },
    });
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
  ];

  const FILE_SIZE = 160 * 1024;

  useEffect(() => {
    currentColor.color = clr;
  }, [clr]);

  const [fileUpload] = useMutation(FILE_UPLOAD, {
    onCompleted() {
      locationSettings.refetch();
      appsettingsVar({
        logo: locationSettings.data.locationPublicSettings[0].logo,
      });
      setsnackBarMessage("Logo Successfully Changed");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setsnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      seterrorsnackbaropen(true);
      notifyDevelopers({
        variables: {
          message:
            "Error on FILE_UPLOAD Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleFileUpload = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      fileUpload({
        variables: {
          id: parseInt(locationSettings.data.locationPublicSettings[0].id),
          file,
        },
      });
    }
  };

  return (
    <Grid
      container
      direction="row"
      spacing={4}
      display="flex"
      data-testid="loctionGrid"
    >
      <ErrorSnackBar
        open={snackBarOpen}
        message={errorSnackBarMessage}
        close={() => setSnackBarOpen(false)}
      />
      <Snackbar
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={() => setsnackbaropen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => {
            setsnackbaropen(false);
            setsnackBarMessage("");
          }}
          severity="success"
        >
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorsnackbaropen}
        autoHideDuration={3000}
        onClose={() => seterrorsnackbaropen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert onClose={() => seterrorsnackbaropen(false)} severity="error">
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
      {/* <Grid item xs={12}>
          <Typography>
            <b>Logo</b>
          </Typography>
        </Grid> */}
      {/* <Grid item xs={12}>
          <UploadImageToS3 />
        </Grid> */}
      {/* <Grid item xs={2}>
          <Box ml={4}>
            <Typography>Upload Logo</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <input
            id="file"
            name="file"
            type="file"
            variant="outlined"
            onChange={handleFileUpload}
          />
        </Grid>
  
        <Grid item xs={4}></Grid> */}
      <Grid item xs={12}>
        <Typography>
          <b>Colors</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Choose your accent color for the application bar:
        </Typography>
      </Grid>
      <Grid item container direction="row" xs={6}>
        <Grid item xs={8}>
          <Box component={Paper} p={5} align="left">
            <Box mb={2}>
              <Typography>
                <b>Select Color</b>
              </Typography>
            </Box>
            <SketchPicker
              color={clr}
              onChangeComplete={(e) => {
                setClr(e.hex);
                handleColorChange(e.hex);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2} align="right">
          <Box mt={3}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                updateLocation({
                  variables: {
                    id: parseInt(
                      locationSettings.data.locationPublicSettings[0].id
                    ),
                    input: { hexcolor: "#F15A25" },
                  },
                });
              }}
            >
              Reset
            </Button>
          </Box>
        </Grid>
        <Grid item xs={2} align="right">
          <Box mt={3}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleColorChange(currentColor.color)}
            >
              Apply
            </Button>
          </Box>
        </Grid>

        <Grid item xs={4}></Grid>
      </Grid>
      <Grid item xs={6}></Grid>
    </Grid>
  );
}
export default ApplicationUISettings;
