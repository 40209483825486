import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import { userVar } from "../../cache";
import {
  ANALYTICS_DATA,
  EMPLOYEE_AVAILABILITY,
  AVAILABILITY_TYPES,
  NOTIFY_DEVELOPERS,
  GET_ALL_CHILD_OFFICES,
  GET_SCHEDULE_PERIODS,
} from "../../api/gqlQueries";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import AnalyticsWidget from "./AnalyticsGraphWidget";
// import Plot from "react-plotly.js";
import { format } from "date-fns";
import isSameDay from "date-fns/isSameDay";
import ErrorSnackBar from "../errors/errorSnackBar";
const useStyles = makeStyles(() => ({
  select: {
    minWidth: 225,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRight: "5",
    paddingLeft: "5",
    borderBottom: "none",
    borderTop: "1px solid lightgrey",
  },
}))(TableCell);

// function onlyUnique(value, index, self) {
//   return self.indexOf(value) === index;
// }

const TimeOffAnalytics = () => {
  const classes = useStyles();

  const user = userVar();
  const environment = process.env.NODE_ENV;
  const [timeOffOpen, setTimeOffOpen] = useState(true);
  const [selectSchedulePeriod, SetSelectSchedulePeriod] = useState("0");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [selectedChildOffice, SetSelectedChildOffice] = useState("0");
  const [allSchedulePeriods, SetallSchedulePeriods] = useState([]);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });
  // const [totalWorkHours, SetTotalWorkHours] = useState();

  const schedulePeriods = useQuery(GET_SCHEDULE_PERIODS, {
    variables: {
      officeId:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onCompleted(d) {
      SetallSchedulePeriods(d.schedulePeriods);
    },
    onError(error) {
      console.log(error);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SCHEDULE_PERIODS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const { data: timeOffData } = useQuery(EMPLOYEE_AVAILABILITY, {
    variables: {
      officeId:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar2(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_AVAILABILITY Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const { data, loading, error } = useQuery(ANALYTICS_DATA, {
    variables: {
      officeId:
        user.isPrimaryParentOffice && selectedChildOffice != "0"
          ? parseInt(selectedChildOffice)
          : parseInt(user.office.id),
      source: "ALGORITHM",
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ANALYTICS_DATA Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allChildOffices = useQuery(GET_ALL_CHILD_OFFICES, {
    variables: {
      parent: user.office.id,
    },
    onCompleted(d) {
      console.log(d);
    },
  });

  const availabilityTypes = useQuery(AVAILABILITY_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar2(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleScheduleSelect = (e) => {
    SetSelectSchedulePeriod(e.target.value);
  };

  if (loading || allChildOffices.loading || schedulePeriods.loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    return (
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="h3">Time Off</Typography>
          </Box>
        </Grid>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </Grid>
    );
  } else {
    const parsed = [];
    data.analyticsDatums.map((datum) => {
      if (datum.data) {
        const parsedData = JSON.parse(datum.data);
        parsed.push(parsedData);
      }
    });

    const schedulePeriodIdsWithData = parsed.map((datum) =>
      parseInt(datum.schedulePeriod)
    );

    let allSchedules = data.offices[0].scheduleperiodSet.filter((schedPeriod) =>
      schedulePeriodIdsWithData.includes(parseInt(schedPeriod.id))
    );

    let schedulePeriodLabels = [...allSchedules];
    //schedulePeriodLabels.sort((a, b) => a.start - b.start);
    schedulePeriodLabels = schedulePeriodLabels.map((schedule) => {
      const title =
        format(schedule.start, "dd MMM yyyy") +
        " to " +
        format(schedule.end, "dd MMM yyyy");
      return {
        id: schedule.id,
        title,
      };
    });
    const allTimeOff = timeOffData
      ? timeOffData.availability.map((timeOff) => {
          const start = new Date(`${timeOff.firstday}T08:00:00`);
          const end = new Date(`${timeOff.lastday}T08:00:00`);
          return {
            ...timeOff,
            start,
            end,
          };
        })
      : [];

    const calculateTotalHours = (scheduleId, employeeId) => {
      const match = parsed.find(
        (datum) => parseInt(datum.schedulePeriod) === parseInt(scheduleId)
      );

      if (match) {
        const workHourEntries = employeeId
          ? match.employeeWorkDistribution.filter(
              (entry) =>
                entry.type === "work" &&
                parseInt(entry.employee) === parseInt(employeeId)
            )
          : match.employeeWorkDistribution.filter(
              (entry) => entry.type === "work"
            );

        let total = 0;
        workHourEntries.forEach((entry) => {
          if (entry.hours.includes(",")) {
            const parseDaysFromHours = entry.hours.split(",");
            if (parseDaysFromHours[0].includes("days")) {
              const days = parseDaysFromHours[0].split(" ")[0];
              total += parseInt(days) * 24;
            }

            const hours = parseDaysFromHours[1].split(":")[0];
            total += parseInt(hours);
          } else {
            const hours = entry.hours.split(":")[0];
            total += parseInt(hours);
          }
        });

        return total;
      } else {
        return 0;
      }
    };

    const groupedBySchedule = allSchedules.map((schedule) => {
      const timeOffs = allTimeOff.filter(
        (timeOff) =>
          (timeOff.start > schedule.start ||
            isSameDay(timeOff.start, schedule.start)) &&
          (timeOff.start < schedule.end ||
            isSameDay(timeOff.start, schedule.end))
      );

      return {
        ...schedule,
        totalTimeOffHours: calculateTotalHours(schedule.id),
        timeOffs,
      };
    });

    const getDataForOneSchedule = () => {
      const match = groupedBySchedule.find(
        (schedule) => parseInt(schedule.id) === parseInt(selectSchedulePeriod)
      );

      let graphData = match
        ? [
            {
              x: [],
              y: [],
              name:
                format(match.start, "dd MMM yyyy") +
                " to " +
                format(match.end, "dd MMM yyyy"),
              type: "bar",
            },
          ]
        : [];

      if (match) {
        match.timeOffs.forEach((timeOff) => {
          const index = graphData[0].x.indexOf(timeOff.employee.id);
          if (index === -1) {
            graphData[0].x.push(timeOff.employee.id);
            graphData[0].y.push(parseInt(timeOff.workHours));
          } else {
            graphData[0].y[index] =
              graphData[0].y[index] + parseInt(timeOff.workHours);
          }
        });
      }
      graphData[0].x.forEach((employeeId, index) => {
        const total = calculateTotalHours(selectSchedulePeriod, employeeId);
        graphData[0].y[index] = (graphData[0].y[index] / total) * 100;
      });

      graphData[0].x = graphData[0].x.map((employeeId) => {
        const employeeMatch = data.offices[0].employeeSet.find(
          (employee) => parseInt(employee.id) === parseInt(employeeId)
        );
        return `${employeeMatch?.firstName} ${employeeMatch?.lastName}`;
      });
      return graphData;
    };

    const getDataForAllSchedules = () => {
      let graphData = [];
      availabilityTypes.data &&
        availabilityTypes.data.availabilityTypes.forEach((type) => {
          const name =
            type.name === "PTO" || type.name === "UPTO"
              ? type.name
              : type.name.slice(0, 1) + type.name.slice(1).toLowerCase();

          const totalsBySchedule = schedulePeriodLabels.map((label) => {
            const match = groupedBySchedule.find(
              (group) => parseInt(group.id) === parseInt(label.id)
            );
            if (match) {
              const matchingTypes = match.timeOffs.filter(
                (timeOff) => parseInt(timeOff.type.id) === parseInt(type.id)
              );
              const total = matchingTypes.reduce((sum, timeOff) => {
                return sum + parseInt(timeOff.workHours);
              }, 0);
              return (total / match.totalTimeOffHours) * 100;
            } else {
              return null;
            }
          });

          const graphObject = {
            x: schedulePeriodLabels.map((label) => label.title),
            y: totalsBySchedule,
            name: name,
            type: "line",
          };
          graphData.push(graphObject);
        });
      return graphData;
    };

    const getData = () => {
      if (selectSchedulePeriod === "0") {
        return getDataForAllSchedules();
      } else {
        return getDataForOneSchedule();
      }
    };

    const organizeByEmployee = (timeOffs) => {
      const byEmployee = [];
      timeOffs.forEach((timeOff) => {
        const employeeExists = byEmployee.find(
          (employee) => parseInt(employee.id) === parseInt(timeOff.employee.id)
        );
        if (employeeExists) {
          employeeExists.totalHours =
            employeeExists.totalHours + parseInt(timeOff.workHours);

          const typeExists = employeeExists.types.find(
            (type) => parseInt(type.id) === parseInt(timeOff.type.id)
          );
          if (typeExists) {
            typeExists.hours = typeExists.hours + timeOff.workHours;
          } else {
            employeeExists.types = [
              ...employeeExists.types,
              {
                id: timeOff.type.id,
                name: timeOff.type.name,
                hours: parseInt(timeOff.workHours),
              },
            ];
          }
        } else {
          byEmployee.push({
            id: timeOff.employee.id,
            name: `${timeOff.employee.firstName} ${timeOff.employee.lastName}`,
            totalHours: parseInt(timeOff.workHours),
            types: [
              {
                id: timeOff.type.id,
                name: timeOff.type.name,
                hours: parseInt(timeOff.workHours),
              },
            ],
          });
        }
      });
      return byEmployee;
    };

    const overviewData = () => {
      let topFive = [];
      if (selectSchedulePeriod === "0") {
        const ordered = organizeByEmployee(allTimeOff);
        ordered.sort((a, b) => b.totalHours - a.totalHours);
        topFive = ordered.slice(0, 5);
      } else {
        const match = groupedBySchedule.find(
          (schedule) => parseInt(schedule.id) === parseInt(selectSchedulePeriod)
        );
        if (match) {
          const ordered = organizeByEmployee(match.timeOffs);
          ordered.sort((a, b) => b.totalHours - a.totalHours);
          topFive = ordered.slice(0, 5);
        }
      }

      return topFive.map((employee) => {
        let mostCommonType = employee.types.sort(
          (a, b) => b.hours - a.hours
        )[0];
        if (mostCommonType.name !== "PTO" && mostCommonType.name !== "UPTO") {
          mostCommonType.name =
            mostCommonType.name.slice(0, 1) +
            mostCommonType.name.slice(1).toLowerCase();
        }

        return (
          <TableRow key={employee.id}>
            <StyledTableCell>{employee.name}</StyledTableCell>
            <StyledTableCell>{employee.totalHours}</StyledTableCell>
            <StyledTableCell>{mostCommonType.name}</StyledTableCell>
          </TableRow>
        );
      });
    };

    return (
      <div>
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h3">Time Off</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box m={2}>
              <Select
                variant="outlined"
                value={selectSchedulePeriod}
                onChange={(e) => {
                  handleScheduleSelect(e);
                }}
                className={classes.select}
              >
                <MenuItem key="0" value="0">
                  All Schedule Periods
                </MenuItem>
                {allSchedulePeriods.length > 0 &&
                  allSchedulePeriods
                    .filter((e) => e.status != "READY" || e.status != "OPEN")
                    .map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {format(
                          new Date(`${e.start}T08:00:00`),
                          "dd MMM yyyy"
                        ) +
                          " to " +
                          format(new Date(`${e.end}T08:00:00`), "dd MMM yyyy")}
                      </MenuItem>
                    ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            {user.isPrimaryParentOffice && (
              <Box m={2}>
                <Select
                  variant="outlined"
                  value={selectedChildOffice}
                  onChange={(e) => {
                    SetSelectedChildOffice(e.target.value);
                  }}
                  className={classes.select}
                >
                  <MenuItem key="0" value="0">
                    Select Child Office
                  </MenuItem>
                  {allChildOffices.data.getChildren.length > 0 &&
                    allChildOffices.data.getChildren.map((e, index) => (
                      <MenuItem key={index} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="h5">
                Employees with Most Time Off Hours
              </Typography>
              <br />
              <br />
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Employee</StyledTableCell>
                    <StyledTableCell>Time Off Hours Taken</StyledTableCell>
                    <StyledTableCell>
                      Most Common Type of Request
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{overviewData()}</TableBody>
              </Table>
            </Box>
          </Grid>
          <AnalyticsWidget
            gridSize={12}
            open={timeOffOpen}
            SetOpen={setTimeOffOpen}
            data={getData()}
            chartType={4}
            SetChartType={null}
            header="Employee Time Off"
            layout={{
              yaxis: {
                title: {
                  text: "Percentage of Total Hours Worked",
                },
                rangemode: "tozero",
              },
            }}
          />
        </Grid>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </div>
    );
  }
};

export default TimeOffAnalytics;
