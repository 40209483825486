import React from "react";
import { Typography, Grid, Avatar, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { userVar } from "../../cache";
import { GET_ASSIGNMENT } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  highlightedEmployee: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  coloredAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  greyAvatar: {
    backgroundColor: "white",
    height: 27,
    width: 27,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  subtitleBlack: {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
}));
/*employees with their First and last name initial is returned
optional job_title */

const EventParticipant = (props) => {
  const classes = useStyles();

  const {
    participant,
    showAvatar,
    selected,
    showJobTitle,
    singleLine,
    widthMatters,
    color,
    addOption,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const user = userVar();

  const { data } = useQuery(GET_ASSIGNMENT, {
    variables: {
      employeeId: parseInt(participant.employeeId),
    },

    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_ASSIGNMENT Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const firstInitial = participant.firstName ? participant.firstName[0] : "";
  const lastInitial = participant.lastName ? participant.lastName[0] : "";
  const initials = firstInitial + lastInitial;
  const fullName = participant.firstName + " " + participant.lastName;

  let matchingAssignment;
  let jobTitle;
  if (data) {
    matchingAssignment = data.assignments.find(
      (assignment) => assignment.office.id === user.office.id
    );
    jobTitle = data.employees[0].employeeskillSet.filter(
      (skill) =>
        skill.skill.variety === "JOB_TYPE" || skill.skill.variety === "TRAINING"
    );
    jobTitle = jobTitle ? jobTitle.map((e) => e.skill.name).join(", ") : "";
  }

  // let jobTitle = matchingAssignment ? matchingAssignment.role.name : '';
  // jobTitle = jobTitle.slice(0, 1).toUpperCase() + jobTitle.slice(1).toLowerCase();

  const schedulable = matchingAssignment
    ? matchingAssignment.schedulable
    : true;

  if (!addOption || (addOption && schedulable)) {
    if (showAvatar && showJobTitle && widthMatters && color) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              className={color ? classes.coloredAvatar : classes.greyAvatar}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid item style={{ width: 175 }}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && showJobTitle && !widthMatters) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              className={color ? classes.coloredAvatar : classes.greyAvatar}
            >
              {initials}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && !showJobTitle && color) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar className={classes.coloredAvatar}>{initials}</Avatar>
          </Grid>
          <Grid item style={{ width: 175 }}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (showAvatar && showJobTitle && !color) {
      return (
        <Grid item container direction="row" spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Avatar className={classes.greyAvatar}>{initials}</Avatar>
          </Grid>
          <Grid item style={{ width: 100 }} xs={9}>
            <Typography
              className={selected ? classes.highlightedEmployee : null}
              noWrap
            >
              {fullName}
            </Typography>
            <Typography className={classes.subtitleBlack}>
              {jobTitle}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (!showAvatar && showJobTitle && singleLine) {
      return (
        <Grid item style={{ width: 255 }}>
          <Typography noWrap>
            {fullName} {jobTitle ? " - " + jobTitle : null}
          </Typography>
        </Grid>
      );
    } else if (!showAvatar && showJobTitle && !singleLine) {
      return (
        <Grid item style={{ width: 255 }}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
          <Typography className={classes.subtitleBlack}>{jobTitle}</Typography>
        </Grid>
      );
    } else if (!widthMatters) {
      return (
        <Grid item style={{ width: 230 }}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
        </Grid>
      );
    } else if (widthMatters) {
      return (
        <Grid item style={{ width: 130 }}>
          <Typography
            className={selected ? classes.highlightedEmployee : null}
            noWrap
          >
            {fullName}
          </Typography>
        </Grid>
      );
    } else {
      return <Typography>Loading...</Typography>;
    }
  } else {
    return null;
  }
};

EventParticipant.propTypes = {
  participant: PropTypes.object,
  showAvatar: PropTypes.bool,
  selected: PropTypes.bool,
  showJobTitle: PropTypes.bool,
  singleLine: PropTypes.bool,
  widthMatters: PropTypes.bool,
  color: PropTypes.bool,
  addOption: PropTypes.bool,
};

EventParticipant.defaultProps = {
  showAvatar: false,
  selected: false,
  showJobTitle: false,
  singleLine: false,
  widthMatters: true,
  color: true,
  addOption: false,
};

export default EventParticipant;
