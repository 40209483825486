import React, { useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useMutation } from "@apollo/client";
import {
  DELETE_OFFICE,
  LOCATION,
  ADD_OFFICE,
  SHIFT_DEFINITION_TYPE,
  WEEKDAY,
  ALL_OFFICES,
  GET_CONSTRAINT_TYPE,
  EMPLOYEE_TYPES,
  BATCH_CREATE_CONSTRAINT,
  NOTIFY_DEVELOPERS,
  GET_ALL_CHILD_OFFICES,
} from "../../api/gqlQueries";
import MuiAlert from "@material-ui/lab/Alert";
import OfficeSetupGuide from "./OfficeSetupGuide";
import { userVar } from "../../cache";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { format } from "date-fns";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles((theme) => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  root: {
    width: "100%",
  },
  shape: {
    border: theme.shape.border,
    boxSizing: theme.shape.boxSizing,
    boxShadow: theme.shape.boxShadow,
    borderRadius: theme.shape.borderRadius,
  },
  table: {
    border: theme.shape.border,
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 80,
  },
}));

const d = new Date();

function ManageOffice(props) {
  const environment = process.env.NODE_ENV;
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_OFFICES Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const offices = !loading && !error && data?.offices;

  const user = userVar();

  const { loading: childOfficesLoading, data: childOfficeData } = useQuery(
    GET_ALL_CHILD_OFFICES,
    {
      variables: {
        parent: parseInt(user.office.id),
      },
      onError(error) {
        console.log(error);
        setSnackBarOpen(true);
        setErrorSnackBarMessage(
          "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on ALL_OFFICES Mutation. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const userOffice =
    !loading &&
    offices.length > 0 &&
    offices.filter((e) => e.id === user.office.id);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 350,
    },
    {
      field: "Total Employees",
      width: 350,
      headerName: "Total Employees",
      valueGetter: (params) => `${params.row.assignmentSet.length || ""}`,
    },
    {
      field: "Edit Office",
      width: 200,
      headerName: "Edit Office",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setOpenForm(true);
            setCurrentOffice(params.row);
            setofficeid(params.row.id);
          }}
          color="secondary"
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "Delete Office",
      width: 200,
      headerName: "Remove Office",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setofficeid(params.row);
            setshowDialog(true);
          }}
          color="secondary"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const [batchCreateConstraints] = useMutation(BATCH_CREATE_CONSTRAINT, {
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_CREATE_CONSTRAINT Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [deleteOffice] = useMutation(DELETE_OFFICE, {
    update(cache, { data: { deleteOffice } }) {
      cache.evict({
        data: { id: deleteOffice.deletedId },
      });
    },
    onCompleted() {
      refetch();
      setsnackBarMessage("Department successfully deleted!");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on DELETE_OFFICE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [openForm, setOpenForm] = useState(
    props.open != null ? props.open : false
  );
  const [currentOffice, setCurrentOffice] = useState(null);
  const [showDialog, setshowDialog] = useState(false);
  const [officeid, setofficeid] = useState("");
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [addDepartment, setAddDepartment] = useState(false);
  const [officeInput, setOfficeInput] = useState("");
  const [FloatOffice, SetFloatOffice] = useState();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const locationDetails = useQuery(LOCATION, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on LOCATION Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleDeleteOffice = () => {
    deleteOffice({ variables: { id: parseInt(officeid.id) } });
    setofficeid("");
    setshowDialog(false);
  };

  const shiftDefinitionTypes = useQuery(SHIFT_DEFINITION_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on SHIFT_DEFINITION_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const weekdays = useQuery(WEEKDAY, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on WEEKDAY Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allEmployeeTypes = useQuery(EMPLOYEE_TYPES, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const allConstraintTypes = useQuery(GET_CONSTRAINT_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_CONSTRAINT_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const [addOffice] = useMutation(ADD_OFFICE, {
    onCompleted(data) {
      //create shifts based on the model type selected
      let w = !weekdays.loading && weekdays.data;
      let sd = !shiftDefinitionTypes.loading && shiftDefinitionTypes.data;
      let empTypes =
        allEmployeeTypes.data.employeeTypes &&
        allEmployeeTypes.data.employeeTypes.length > 0 &&
        allEmployeeTypes.data.employeeTypes;
      let filteredConstraintDef =
        allConstraintTypes.data.constraintDefinitions.filter(
          (constraintDef) =>
            constraintDef.name === "FTEMAXHRWK" ||
            constraintDef.name === "FTEMINHRWK" ||
            constraintDef.name === "MAXDAYS" ||
            constraintDef.name === "MAXDAILYHR" ||
            constraintDef.name === "MAXDAYSOFF"
        );
      let inputData = filteredConstraintDef.map((constraintDef) => {
        let input = empTypes.map((type) => {
          return {
            type: parseInt(constraintDef.id),
            value:
              constraintDef.name === "FTEMAXHRWK"
                ? type.name === "FULL-TIME"
                  ? 44
                  : 24
                : constraintDef.name === "FTEMINHRWK"
                ? type.name === "FULL-TIME"
                  ? 40
                  : 20
                : constraintDef.name === "MAXDAYS"
                ? 3
                : constraintDef.name === "MAXDAILYHR"
                ? 16
                : constraintDef.name === "MAXDAYSOFF"
                ? 2
                : 0,
            office: parseInt(data.createOffice.office.id),
            employeetype: parseInt(type.id),
            effectstart: format(d, "yyyy-MM-dd"),
          };
        });
        return input.flat();
      });

      batchCreateConstraints({
        variables: {
          input: inputData.flat(),
        },
      });

      refetch();
      setAddDepartment(!addDepartment);
      setOfficeInput("");
      setsnackBarMessage("Department successfully added!");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ADD_OFFICE Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleAddOffice = () => {
    var officeInputLen = officeInput.trim().length;

    if (officeInputLen > 0) {
      addOffice({
        variables: {
          input: {
            name: officeInput,
            address1: "",
            address2: "",
            address3: "",
            locationId: parseInt(locationDetails.data.locations[0].id),
            floatStatus: FloatOffice ? true : null,
          },
        },
      });
    }
  };

  if (loading || childOfficesLoading) {
    return <CircularProgress color="primary" />;
  } else {
    const rows = user.isAdmin
      ? !loading && !error && data?.offices
      : user.isPrimaryParentOffice
      ? [...userOffice, ...childOfficeData.getChildren]
      : userOffice;
    return (
      <main container="true" className={classes.root}>
        {!openForm ? (
          <>
            <ErrorSnackBar
              open={snackBarOpen}
              message={errorSnackBarMessage}
              close={() => setSnackBarOpen(false)}
            />

            <Snackbar
              open={snackbaropen}
              autoHideDuration={3000}
              onClose={() => setsnackbaropen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => {
                  setsnackbaropen(false);
                  setsnackBarMessage("");
                }}
                severity="success"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Snackbar
              open={errorsnackbaropen}
              autoHideDuration={6000}
              onClose={() => seterrorsnackbaropen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => seterrorsnackbaropen(false)}
                severity="error"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Dialog open={showDialog} fullWidth maxWidth="xs">
              <DialogContent
                style={{
                  padding: 20,
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                <Typography>
                  Are you sure you want to delete this department?
                </Typography>
                <br />
                <br />
                <Button
                  onClick={handleDeleteOffice}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() => setshowDialog(false)}
                  color="primary"
                  variant="outlined"
                >
                  No
                </Button>
              </DialogContent>
            </Dialog>
            <Box m={2}>
              <Grid container className={classes.headerSpacing}>
                <Grid item xs={6}>
                  <Typography variant="h3">Departments</Typography>
                </Grid>
                <Grid item container justifyContent="flex-end" xs={6}>
                  {(user.isAdmin || user.isPrimaryParentOffice )&& (
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setAddDepartment(!addDepartment)}
                        className={classes.button}
                      >
                        Add
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <div
                style={{ height: user.isAdmin || user.isPrimaryParentOffice ? 600 : 300 }}
                data-testid="officeDataGrid"
              >
                <DataGrid
                  className={classes.dt}
                  rows={rows}
                  columns={columns}
                  //pageSize={20}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>
            </Box>
          </>
        ) : (
          <OfficeSetupGuide office={currentOffice} />
        )}
        <Dialog open={addDepartment} fullWidth maxWidth="md">
          <DialogContent
            style={{ padding: 20, overflowX: "hidden", textAlign: "center" }}
          >
            <Box style={{ textAlign: "right" }}>
              <Button onClick={() => setAddDepartment(!addDepartment)}>
                x
              </Button>
            </Box>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h3">
                  {!locationDetails.loading &&
                    locationDetails.data &&
                    locationDetails.data.locations[0].name}
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h4">Department</Typography>
                <Typography variant="body1">
                  Add Departments for Hospital
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  value={officeInput ? officeInput : ""}
                  onChange={(e) => setOfficeInput(e.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleAddOffice();
                    }
                  }}
                  className={classes.inputWidth}
                  placeholder="New Department"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={FloatOffice}
                      onChange={(e) => SetFloatOffice(true)}
                      name="gilad"
                    />
                  }
                  label="Is Float"
                />
                <Typography variant="body2">
                  (Check if this Department is a Float Department)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    disabled={officeInput.length === 0 ? true : false}
                    onClick={handleAddOffice}
                    color="primary"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </main>
    );
  }
}

export default ManageOffice;
