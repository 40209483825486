import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import EditTimeOffRequestForm from "../shiftBasedCalendar/EditTimeOffRequestForm";
import TimeOffRequestForm from "../shiftBasedCalendar/TimeOffRequestForm";
import OffRequestItem from "./OffRequestItem";
import { locationConfigVar, userVar } from "../../cache";
import { GET_EMPLOYEE_NAMES } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isSameDay } from "date-fns";

const TimeOff = (props) => {
  const {
    timeOffRequests,
    scheduleEnd,
    invalidDates,
    softRequests,
    setToast,
    setShowToast,
    refetch,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,

  } = props;

  const user = userVar();
  const locationConfig = locationConfigVar();
  const shiftDisplay = locationConfig.shiftBasedDisplay;

  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const [editTimeOffRequest, setEditTimeOffRequest] = useState(false);
  const [timeOffRequestIdToEdit, setTimeOffRequestIdToEdit] = useState("");
  const [dateToView, setDateToView] = useState(new Date());
  const [employeeResources, setEmployeeResources] = useState([]);

  const { data } = useQuery(GET_EMPLOYEE_NAMES, {
    variables: { office: parseInt(user.office.id) },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage("We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again.");
        notifyDevelopers({variables: {message: "Error on GET_EMPLOYEE_NAMES Query. Environment: " + environment + ". Graphql " + error}});
      },
  });

  useEffect(() => {
    if (data) {
      addEmployeeData(data);
    }
  }, [data]);

  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);
  const toggleEditTimeOffForm = () =>
    setEditTimeOffRequest(!editTimeOffRequest);

  const closeTimeOffForm = () => {
    setTimeOffRequestForm(false);
    refetch();
  };

  const closeEditTimeOffForm = () => {
    setEditTimeOffRequest(false);
    refetch();
  };

  const timeOffRequestsToView = () => {
    const filtered = timeOffRequests.filter(
      (request) =>
        request.start > dateToView || isSameDay(request.start, dateToView)
    );
    filtered.length > 0 &&
      filtered.sort((a, b) => new Date(a.firstday) - new Date(b.firstday));
    return filtered;
  };

  const addEmployeeData = (data) => {
    const resources = data.offices[0].employeeSet.map((employee) => {
      if (parseInt(employee.id) === parseInt(user.id)) {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#F5E1DB",
          skills: employee.skills.map((e) => e.name),
        };
      } else {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#DFE0E2",
          skills: employee.skills.map((e) => e.name),
        };
      }
    });
    setEmployeeResources(resources);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid
            item
            container
            justifyContent="space-between"
            xs={11}
            style={{ marginTop: 8, marginBottom: 8 }}
          >
            <Grid item container xs={9} alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h5">
                  See requests from this date on:
                </Typography>
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-to-view"
                  value={dateToView}
                  onChange={setDateToView}
                />
              </Grid>
            </Grid>
            <Grid item>
              {user.isSchedulable && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleTimeOffForm}
                >
                  Add Request
                </Button>
              )}
            </Grid>
          </Grid>
          {timeOffRequestsToView().length > 0 ? (
            timeOffRequestsToView().map((request) => (
              <OffRequestItem
                request={request}
                setIdToEdit={setTimeOffRequestIdToEdit}
                toggleEditForm={toggleEditTimeOffForm}
                type="timeOff"
                key={request.eventId}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}

              />
            ))
          ) : (
            <Typography variant="h5" style={{ marginTop: 16 }}>
              No time off requests after minimum date
            </Typography>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      <Dialog open={timeOffRequestForm} fullWidth maxWidth="sm">
        <DialogContent
          style={{ padding: 30, height: 650, position: "relative" }}
        >
          <TimeOffRequestForm
            closeDialog={closeTimeOffForm}
            scheduleEndDate={shiftDisplay ? scheduleEnd : null}
            invalidDates={invalidDates}
            setToast={setToast}
            setShowToast={setShowToast}
            employees={employeeResources}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            notifyDevelopers={notifyDevelopers}
            environment={environment}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={editTimeOffRequest} fullWidth maxWidth="sm">
        <DialogContent
          style={{ padding: 30, height: 650, position: "relative" }}
        >
          {timeOffRequestIdToEdit && (
            <EditTimeOffRequestForm
              closeDialog={closeEditTimeOffForm}
              userSoft={softRequests}
              setToast={setToast}
              setShowToast={setShowToast}
              userTimeOff={timeOffRequests}
              timeOffRequestIdToEdit={timeOffRequestIdToEdit}
              setTimeOffRequestIdToEdit={setTimeOffRequestIdToEdit}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TimeOff;
