import React, { useEffect, useState } from 'react';
import { 
    Button, Grid, Typography, Checkbox,
    makeStyles, InputLabel, IconButton, TextField 
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { format, isSameDay, sub } from 'date-fns';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SHIFT_ASSIGNMENT, EMPLOYEE_CALL_IN } from '../../api/gqlQueries';
import { selectedDateVar, userVar } from '../../cache';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 138,
        maxWidth: 225,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    spaceBelow: {
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.primary.main
    },
    textInput: {
        width: 375,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    dateError: {
        color: theme.palette.primary.main,
        width: 225, 
        marginTop: -4,
        paddingLeft: 14,
        paddingRight: 14
    }
}));

const EmpCallInForm = (props) => {
    const classes = useStyles();

    const {
        closeDialog, 
        userEvents, 
        view,
        setToast, 
        setShowToast,
        shiftToChange, 
        refetch, 
        setOpenSnackBar,
        setSnackBarMessage,
        notifyDevelopers,
        environment,
    } = props;

    const user = userVar();
    const date = selectedDateVar();

    const userScheduleDates = userEvents.map(event => (
        format(new Date(event.start), 'MM/dd/yyyy')
    ));
    const [currentDate, setCurrentDate] = useState(new Date(date));
    const [note, setNote] = useState('');
    const [shiftAssignmentId, setShiftAssignmentId] = useState('');
    const [selectedShiftAssignments, setSelectedShiftAssignments] = useState([]);
    const [multipleShifts, setMultipleShifts] = useState([]);
    // const [shiftId, setShiftId] = useState('');
    const [error, setError] = useState('');
    const [dateError, setDateError] = useState('');

    const [getShiftAssignmentId] = useLazyQuery(GET_SHIFT_ASSIGNMENT, {
        onCompleted(data) {
            console.log(data);
            if (data.shiftAssignments.length > 0) {
                setShiftAssignmentId(data.shiftAssignments[0].id);
                setError('');
            } else {
                setShiftAssignmentId('');
                setError('We could not find a matching shift. Please select a different date.')
            }
        },
        onError(error){
            console.log(error);
            setShiftAssignmentId('');
            setError('We could not find a matching shift. Please select a different date.');
            notifyDevelopers({variables: {message: "Error on GET_SHIFT_ASSIGNMENT lazyQuery. Environment: " + environment + ". Graphql " + error}});

        }
    });

    const [callIn] = useMutation(EMPLOYEE_CALL_IN, {
        onCompleted() {
            refetch();            
            setToast('Call In');
            setShowToast(true);
            setError('');
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Error with request. Please ensure you chose the correct date and try again.');
            notifyDevelopers({variables: {message: "Error on EMPLOYEE_CALL_IN Query. Environment: " + environment + ". Graphql " + error}});

        }
    });

    const getShiftAssignments = (shift, index) => {
        console.log(shift)
        let matchingShiftId;
        let matchingShiftAssignments;
        if (shift.tasks) {
            let shiftAssignments = [];
            if (shift.eventTitle === 'GHOC') {
                shift.tasks.forEach(task => {
                    let matchingAssignment = task.shiftassignmentSet.find(assignment => (
                        parseInt(assignment.employee.id) === 
                        parseInt(user.id)
                    ));
                    if (matchingAssignment) {
                        matchingAssignment = {...matchingAssignment};
                        matchingAssignment.shift = {
                            start: task.start,
                            end: task.end
                        }
                        shiftAssignments = [
                            ...shiftAssignments, 
                            matchingAssignment
                        ];
                    }
                });
            } else {
                const matches = shift.tasks.filter(task => (
                    parseInt(task.shiftAssignment.employee.id) === 
                    parseInt(user.id)
                ));
                
                if (matches.length > 0) {
                    shiftAssignments = matches.map(match => (
                        match.shiftAssignment
                    ));
                }
            }
            matchingShiftAssignments = shiftAssignments.length > 0;
            const shiftAssignmentIds = shiftAssignments.map(shiftAssign => (
                shiftAssign.id
            ));
            if (index === 0) {
                setMultipleShifts(shiftAssignments);
                setSelectedShiftAssignments(shiftAssignmentIds);
            } else {
                setMultipleShifts([...multipleShifts, shiftAssignments]);
                setSelectedShiftAssignments([
                    ...selectedShiftAssignments, 
                    ...shiftAssignmentIds
                ]);
            }
        } else {
            matchingShiftId = shift.eventId;
        }

        if (matchingShiftAssignments) {
            return;
        } else if (matchingShiftId) {
            // setShiftId(matchingShiftId);
            getShiftAssignmentId({variables: {
                employeeId: parseInt(user.id), 
                shiftId: parseInt(matchingShiftId)
            }});
        } else {
            setError('We could not find a matching shift. Please select a different date.');
        }
    }

    useEffect(() => {
        const newDate = new Date(date);
        if (newDate < sub(new Date(), {days: 1})){
            setDateError('Date should not be before today');
            return;
        }
        setDateError('');
        
        const filteredByView = userEvents.filter(event => (
            event.calendars.includes(view)
        ));
        const shifts = filteredByView.filter(event => (
            isSameDay(new Date(event.start), newDate) &&
            event.eventId === shiftToChange
        ));
        
        if (shifts.length > 0) {
            shifts.forEach((shift, index) => {
                getShiftAssignments(shift, index);
            });
        }
    }, []);

    const handleSubmit = () => {
        //mutation to change isArchived value on shift to true to indicate employee called off
        if (selectedShiftAssignments.length > 0) {
            selectedShiftAssignments.forEach(shiftAssign => {
                callIn({variables: {
                    id: parseInt(shiftAssign),
                    note: note
                }});
            })
        } else {
            callIn({variables: {
                id: parseInt(shiftAssignmentId),
                note: note
            }});
        }
    };

    const handleDateChange = (date) => {
        if(date && !isNaN(date.getTime())){
            setSelectedShiftAssignments([]);
            if (date < sub(new Date(), {days: 1})){
                setCurrentDate(date);
                setDateError('Date should not be before today');
                return;
            } else {
                setCurrentDate(date);

                const filteredByView = userEvents.filter(event => (
                    event.calendars.includes(view)
                ));
                const shifts = filteredByView.filter(event => (
                    isSameDay(new Date(event.start), date)
                ));
                // const shift = userEvents.find(event => (
                //     new Date(event.start).toDateString() === date.toDateString()
                // ));
                if (shifts.length > 0) {
                    setDateError('');
                    shifts.forEach((shift, index) => {
                        getShiftAssignments(shift, index);
                    });
                    // const shiftId = shift.eventId;
                    // setShiftId(shiftId);
                    //query to get shift assignment ID every time date changes so value is stored for submit
                    // getShiftAssignmentId({variables: {
                    //     employeeId: parseInt(user.id), 
                    //     shiftId: parseInt(shiftId)
                    // }});
                } else {
                    setDateError('You must choose a date that you are working');
                }
            }
        } else {
            setDateError('Invalid date');
        }
    };

    const checkInvalid = (date) => {
        const formatted = format(date, 'MM/dd/yyyy');
        return !userScheduleDates.includes(formatted);
    };

    const handleCheckboxToggle = (shift) => {
        const shiftAssignmentId = shift.id;
        const newIds = [...selectedShiftAssignments]
        const index = newIds.indexOf(shiftAssignmentId);
        if (index === -1) {
            newIds.push(shiftAssignmentId);
        } else {
            newIds.splice(index, 1);
        }
        setSelectedShiftAssignments(newIds);
    }

    const eligibleToSave = Boolean(
        !error && !dateError && 
        (shiftAssignmentId || selectedShiftAssignments.length > 0)
    );

    return (  
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid 
                container 
                component='form' 
                direction='column' 
                alignItems='flex-start' 
                justifyContent='center' 
                spacing={3}
            >
                <Grid container item justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h2' >Call In</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color='secondary' onClick={closeDialog}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <InputLabel htmlFor="current-date" shrink={false}>
                        <Typography variant='h5'>Date To Call In</Typography>
                    </InputLabel>
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant='inline'
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        id='current-date'
                        shouldDisableDate={checkInvalid}
                        value={currentDate}
                        onChange={handleDateChange}
                        className={classes.input}
                    />
                    {dateError && 
                        <Typography variant='body2' className={classes.dateError}>
                            {dateError}
                        </Typography>
                    }
                </Grid>
                <Grid item>
                    {multipleShifts.length > 0 && 
                        <> 
                            <Typography>Select times to call in:</Typography>
                            {multipleShifts.map(shift => (
                                <Grid 
                                    item 
                                    key={shift.id}
                                    container
                                    alignItems='center' 
                                    onClick={() => handleCheckboxToggle(shift)}
                                    style={{marginTop: -5, marginBottom: -5, cursor: 'pointer'}}
                                >
                                    <Grid item>
                                        <Checkbox checked={selectedShiftAssignments.indexOf(shift.id) !== -1}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {format(new Date(shift.shift.start), 'HH:mm')}{' - '}
                                            {format(new Date(shift.shift.end), 'HH:mm')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    }
                </Grid>
                <Grid item>
                    <InputLabel htmlFor="current-date" shrink={false}>
                        <Typography variant='h5'>Note to Manager</Typography>
                    </InputLabel>
                    <TextField 
                        variant='outlined'
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        className={classes.textInput}
                    />
                </Grid>
                <Grid item>
                    {error && <Typography className={classes.error}>{error}</Typography>}
                </Grid>
                <Grid 
                    item 
                    container 
                    justifyContent="flex-end" 
                    style={{zIndex: 4, position: 'absolute', bottom: 25, right: 30}}
                >
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={handleSubmit} 
                            disabled={!eligibleToSave}
                        >
                            Call in
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};
 
export default EmpCallInForm;