import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Box,
  Snackbar,
  LinearProgress,
  withStyles,
  TextField,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import {
  FIND_SKILL_TYPE,
  EMPLOYEE_TYPES,
  ALL_OFFICES,
  ALL_SHIFT_TYPES,
  Get_Employees,
  ROLES,
  BATCH_ADD_EMPLOYEE,
  BATCH_CREATE_EMPLOYEE_ASSIGNMENT,
  BATCH_CREATE_HIRED_SHIFTS,
  BATCH_CREATE_EMPLOYEE_SKILLS,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import {
  addEmpDetails,
  handlefileValdation,
} from "../../helpers/updateUserDetails";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Formik, Form, FieldArray } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

// const SUPPORTED_FORMATS = [
//   "application/vnd.ms-excel",
//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   "application/vnd.csv",
// ];

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
  table: {
    border: "none",
    boxSizing: "none",
    boxShadow: "none",
    borderRadius: "none",
    width: "100%",
  },
  deleteButton: {
    color: "#333333",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "5px",
    paddingBottom: "0",
    paddingRottom: "0",
    border: "none",
    textAlign: "center",
  },
}))(TableCell);

const ImportEmployees = (props) => {
  const {
    setOpenSnackBar,
    setSnackBarMessage,
    // notifyDevelopers,
    environment,
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const [values, SetValues] = useState({});
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [addedEmps, SetAddedEmps] = useState([]);
  const [errMsg, SetErrMsg] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const alloffices = useQuery(ALL_OFFICES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_OFFICES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allEmployeeTypes = useQuery(EMPLOYEE_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on EMPLOYEE_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allShiftTypes = useQuery(ALL_SHIFT_TYPES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ALL_SHIFT_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const allskilltypes = useQuery(FIND_SKILL_TYPE, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });
  const roles = useQuery(ROLES, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ROLES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const { refetch } = useQuery(Get_Employees, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on Get_Employees Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateEmployeeAssignment] = useMutation(
    BATCH_CREATE_EMPLOYEE_ASSIGNMENT,
    {
      onCompleted(data) {
        data.batchCreateAssignment.assignments.map((e) => {
          const emp = values.find((a) => a.Email === e.employee.email);
          const assignmentId = e.id;
          const officeid =
            !alloffices.loading &&
            alloffices.data.offices.find((i) => i.name === emp.Department).id;

          let currEmpShifts = [];
          if (emp.ShiftType != "" || emp.ShiftType != "Any") {
            currEmpShifts = emp.ShiftType.split(",");
            currEmpShifts.map((x) => {
              let shiftTypes = allShiftTypes.data.shiftDefinitions.filter(
                (e) => e.description === x && e.office.id === officeid
              );
              if (shiftTypes.length > 0) {
                let shiftTypeData = shiftTypes.map((s) => {
                  return {
                    assignment: assignmentId,
                    shiftDefinition: s.id,
                  };
                });
                batchCreateHiredShifts({ variables: { input: shiftTypeData } });
              }
            });
          }
        });
        refetch();
        setsnackBarMessage("Employees successfully added!");
        setsnackbaropen(true);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_EMPLOYEE_ASSIGNMENTQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchCreateEmployeeSkills] = useMutation(
    BATCH_CREATE_EMPLOYEE_SKILLS,
    {
      onCompleted(data) {
        console.log(data);
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on BATCH_CREATE_SKILLS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  const [batchCreateEmployee] = useMutation(BATCH_ADD_EMPLOYEE, {
    onCompleted(data) {
      const assignmentData = data.batchCreateEmployee.employees.map((e) => {
        const emp = values.find((a) => a.Email === e.email);
        const officeid =
          !alloffices.loading &&
          alloffices.data.offices.find((i) => i.name === emp.Department).id;
        const roleid =
          !roles.loading &&
          roles.data.roles.find((i) => i.name === emp.Role).id;
        return {
          employee: parseInt(e.id),
          office: parseInt(officeid),
          primary: true,
          role: parseInt(roleid),
          startDate: format(new Date(emp.AssignmentStartDate), "yyyy-MM-dd"),
        };
      });
      batchCreateEmployeeAssignment({ variables: { input: assignmentData } });
      const createSkillInputData = data.batchCreateEmployee.employees.map(
        (e) => {
          const emp = values.find((a) => a.Email === e.email);
          const skill = allskilltypes.data.skills.find(
            (e) => e.name === emp.JobType && e.variety === "JOB_TYPE"
          );
          let todaysDate = format(new Date(), "yyyy-MM-dd");
          return {
            employeeId: parseInt(e.id),
            skillId: skill.id,
            effective: todaysDate,
          };
        }
      );
      batchCreateEmployeeSkills({
        variables: {
          input: createSkillInputData,
        },
      });
      refetch();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_ADD_EMPLOYEE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateHiredShifts] = useMutation(BATCH_CREATE_HIRED_SHIFTS, {
    onCompleted() {
      refetch();
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_CREATE_HIRED_SHIFTS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleFileRead = (data, fileLoaded) => {
    setFileFormatError(false);

    if (handlefileValdation(data, alloffices, roles.data.roles).length > 0) {
      setsnackBarMessage(
        handlefileValdation(data, alloffices, roles.data.roles)
      );
      seterrorsnackbaropen(true);
      setFileLoaded(false);
      SetErrMsg(true);
      setShowLoader(false);
    } else {
      SetValues(data);
      setFileLoaded(true);
      setShowLoader(false);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const headerData = [
    [
      "Name",
      "Email",
      "Phone",
      "Role",
      "JobType",
      "ShiftType",
      "EmploymentType",
      "Department",
      "AssignmentStartDate",
    ],
  ];
  if (allEmployeeTypes.loading) {
    return <CircularProgress color="primary" />;
  } else {
    const handleImportEmployees = (vals) => {
      SetValues(vals.rows);
      if (fileLoaded) {
        setShowLoader(true);
        addEmpDetails(
          vals.rows,
          allEmployeeTypes.data.employeeTypes,
          batchCreateEmployee
        );
        refetch();
      }
    };
    return (
      <Grid
        container
        direction="row"
        className={classes.headerSpacing}
        spacing={4}
      >
        <Snackbar
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={() => {
            setsnackbaropen(false);
            setShowLoader(false);
            refetch();
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => {
              setsnackbaropen(false);
              setsnackBarMessage("");
            }}
            severity="success"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={errorsnackbaropen}
          autoHideDuration={6000}
          onClose={() => {
            seterrorsnackbaropen(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => seterrorsnackbaropen(false)}
            severity="error"
          >
            {snackBarMessage}
          </MuiAlert>
        </Snackbar>
        <Grid item xs={12}>
          <Typography variant="h3">Import Employees</Typography>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid item xs={6}>
            <Box
              m={2}
              p={2}
              style={{
                border: "1px solid gray",
                width: "100%",
                borderRadius: "5px",
              }}
            >
              <CSVReader
                onFileLoaded={handleFileRead}
                parserOptions={papaparseOptions}
              />
            </Box>

            <Typography color="error" variant="body2">
              {fileFormatError &&
                "File format not supported.Upload a .csv or excel file"}
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={3}>
            <Box m={2} p={2}>
              <Button
                color="secondary"
                component={CSVLink}
                data={headerData}
                target="_blank"
                variant="outlined"
              >
                Download CSV Format
              </Button>
            </Box>
          </Grid>
          <Grid item xs={9}>
            {showLoader ? <LinearProgress color="primary" /> : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={{
              rows: values,
            }}
            onSubmit={(values) => {
              handleImportEmployees(values);
            }}
          >
            {({ values, handleChange, setFieldValue, dirty }) => (
              <Form>
                <Grid container direction="row" item xs={12} spacing={4}>
                  <Grid item xs={12}>
                    <Box m={4} style={{ textAlign: "right" }}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={errMsg || dirty}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="rows">
                      {({ push, remove }) => (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>First Name</StyledTableCell>
                              <StyledTableCell>Last Name</StyledTableCell>
                              <StyledTableCell>Email</StyledTableCell>
                              <StyledTableCell>Phone</StyledTableCell>
                              <StyledTableCell>
                                Application Role
                              </StyledTableCell>
                              <StyledTableCell>Job Type</StyledTableCell>
                              <StyledTableCell>Shift Type</StyledTableCell>
                              <StyledTableCell>Employment Type</StyledTableCell>
                              <StyledTableCell>Department</StyledTableCell>
                              <StyledTableCell>
                                AssignmentStartDate
                              </StyledTableCell>
                              <StyledTableCell>Remove</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.rows.length > 0 &&
                              values.rows.map((e, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Name`}
                                      name={`rows.${index}.Name`}
                                      value={
                                        e.Name.includes(",")
                                          ? e.Name.split(",")[0]
                                          : e.Name.split(" ")[0]
                                      }
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Name2`}
                                      name={`rows.${index}.Name`}
                                      value={
                                        e.Name.includes(",")
                                          ? e.Name.split(",")[1]
                                          : e.Name.split(" ")[1]
                                      }
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      variant="outlined"
                                      id={`rows.${index}.Email`}
                                      name={`rows.${index}.Email`}
                                      value={e.Email}
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Phone`}
                                      name={`rows.${index}.Phone`}
                                      value={e.Phone}
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Role`}
                                      name={`rows.${index}.Role`}
                                      value={e.Role}
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.JobType`}
                                      name={`rows.${index}.JobType`}
                                      value={e.JobType}
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.ShiftType`}
                                      name={`rows.${index}.ShiftType`}
                                      value={e.ShiftType}
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.EmploymentType`}
                                      name={`rows.${index}.EmploymentType`}
                                      value={e.EmploymentType}
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      id={`rows.${index}.Department`}
                                      name={`rows.${index}.Department`}
                                      value={e.Department}
                                      variant="outlined"
                                      onChange={handleChange}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <Grid
                                        container
                                        justifyContent="space-around"
                                      >
                                        <DatePicker
                                          variant="inline"
                                          id={`rows.${index}.AssignmentStartDate`}
                                          name={`rows.${index}.AssignmentStartDate`}
                                          value={
                                            new Date(e.AssignmentStartDate)
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `rows.${index}.AssignmentStartDate`,
                                              e
                                            );
                                          }}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton>
                                                  <CalendarIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                    </MuiPickersUtilsProvider>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <IconButton
                                      color="secondary"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            <TableRow>
                              <StyledTableCell>
                                <Button
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  onClick={() =>
                                    push({
                                      Name: "",
                                      Email: "xyz@example.com",
                                      Phone: "(123)4567890",
                                      Role: "",
                                      JobType: "",
                                      ShiftType: "",
                                      EmploymentType: "FULL-TIME",
                                      Department: "",
                                      AssignmentStartDate: new Date(),
                                    })
                                  }
                                >
                                  Add Another
                                </Button>
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    );
  }
};
export default ImportEmployees;
