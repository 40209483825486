import React from 'react';
import {
    Typography, Grid, makeStyles
} from '@material-ui/core';
import StarRateIcon from '@material-ui/icons/StarRate';

const useStyles = makeStyles(() =>({
    key: {
        backgroundColor: "#F5E1DB", 
        borderRadius: 4, 
        padding: 8,
        paddingLeft: 12, 
        paddingRight: 12,
        maxWidth: 510,
        marginRight: 15
    },
    icon: {
        padding: 0,
        marginLeft: -7
    }
}))

const RescheduleOptionsKey = ({type}) => {
    const classes = useStyles();

    return (
        <Grid item container className={classes.key}>
            <Grid item xs={12} style={{marginBottom: 8}}>
                {type === 'switch' &&
                    <Typography>
                        Our algorithm may have identified a few shift switch options for you **
                    </Typography>
                }
                {type === 'addDrop' &&
                    <Typography>
                        Our algorithm may have identified a few add and drop options for you **
                    </Typography>
                }
                {type === 'add' &&
                    <Typography>
                        Our algorithm may have identified a few add options for you **
                    </Typography>
                }
            </Grid>
            <Grid container item xs={12} justifyContent='space-evenly' style={{marginBottom: 8}}>
                <Grid item xs={3} container spacing={1} alignContent='center'>
                    <Grid item>
                        <StarRateIcon className={classes.icon} />
                        <StarRateIcon className={classes.icon} />
                        <StarRateIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <Typography>Best</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} container spacing={1} alignContent='center'>
                    <Grid item>
                        <StarRateIcon className={classes.icon} />
                        <StarRateIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <Typography>Good</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} container spacing={1} alignContent='center'>
                    <Grid item>
                        <StarRateIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <Typography>Acceptable</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    ** based on the shift settings for this office
                </Typography>
            </Grid>
        </Grid>
    );
}
 
export default RescheduleOptionsKey;