import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  Dialog,
  DialogContent,
  Snackbar,
  Divider,
  makeStyles,
  Box,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMutation, useQuery } from "@apollo/client";
import {
  BATCH_CREATE_SKILLS,
  DELETE_SKILL,
  FIND_SKILL_TYPE,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik, Form, FieldArray } from "formik";
import AddIcon from "@material-ui/icons/Add";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  divider: {
    border: "0.5px solid thin #333333",
    verticalAlign: "bottom",
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#EAEAEA",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20px",
  },
  body: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    paddingTop: "0",
    paddingBottom: "0",
    paddingRottom: "0",
    paddingLeft: "20px",
  },
}))(TableCell);

function DepartmentTrainings(props) {
  const classes = useStyles();
  const [showDialog, setshowDialog] = useState(false);
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [trainingidtodelete, setTrainingidtodelete] = useState("");
  const [vals, SetVals] = useState([]);
  const environment = process.env.NODE_ENV;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState(false);
  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const allSkills = useQuery(FIND_SKILL_TYPE, {
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't retrive the information you are looking for and are working hard to fix the error. Please refresh and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    allSkills.refetch();
  }, []);

  const [deleteSkill] = useMutation(DELETE_SKILL, {
    update(cache, { data: { deleteSkill } }) {
      cache.evict({
        data: { id: deleteSkill.deletedId },
      });
    },
    onCompleted() {
      allSkills.refetch();
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on (DELETE_SKILL Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [batchCreateSkills] = useMutation(BATCH_CREATE_SKILLS, {
    onCompleted(d) {
      allSkills.refetch();
      setsnackBarMessage("Department trainings successfully added");
      setsnackbaropen(true);
    },
    onError(error) {
      console.log(error);
      setSnackBarOpen(true);
      setErrorSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on BATCH_CREATE_SKILLS Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const handleAddTraining = (values) => {
    SetVals(values.trainings.filter((e) => !e.id));
    let inputData =
      values.trainings.length > 0 &&
      values.trainings
        .map((element) => {
          if (element.id || element.name === "") {
            return;
          } else {
            return {
              name: element.name,
              variety: "TRAINING",
              office: parseInt(props.office),
            };
          }
        })
        .filter(Boolean);
    batchCreateSkills({
      variables: {
        input: inputData,
      },
    });
  };

  const handleDeleteTraining = () => {
    deleteSkill({ variables: { id: parseInt(trainingidtodelete) } });
    setTrainingidtodelete("");
    setsnackBarMessage("Department job title successfully deleted");
    setsnackbaropen(true);
    setshowDialog(false);
  };

  if (allSkills.loading) {
    return <CircularProgress color="primary" />;
  } else {
    return (
      <div style={{ marginTop: "20px" }} data-testid="departmentTrainingGrid">
        <Grid
          container
          display="flex"
          style={{ border: "0.5px solid lightgray" }}
        >
          <Grid container direction="row" spacing={4} display="flex">
            <ErrorSnackBar
              open={snackBarOpen}
              message={errorSnackBarMessage}
              close={() => setSnackBarOpen(false)}
            />
            <Snackbar
              open={snackbaropen}
              autoHideDuration={3000}
              onClose={() => setsnackbaropen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => {
                  setsnackbaropen(false);
                  setsnackBarMessage("");
                }}
                severity="success"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Snackbar
              open={errorsnackbaropen}
              autoHideDuration={6000}
              onClose={() => seterrorsnackbaropen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                onClose={() => seterrorsnackbaropen(false)}
                severity="error"
              >
                {snackBarMessage}
              </MuiAlert>
            </Snackbar>
            <Dialog open={showDialog} fullWidth maxWidth="xs">
              <DialogContent
                style={{
                  padding: 20,
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                <Typography>
                  Are you sure you want to delete this Job Type?
                </Typography>
                <br />
                <br />
                <Button
                  onClick={handleDeleteTraining}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleDeleteTraining();
                    }
                  }}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  onClick={() => setshowDialog(false)}
                  color="primary"
                  variant="outlined"
                >
                  No
                </Button>
              </DialogContent>
            </Dialog>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Typography variant="h3">{props.office.name}</Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            <Grid item xs={11}>
              <Typography variant="h4">Unit Specific Training</Typography>{" "}
              <Typography>
                Add Unit Specific Trainings for <b>{props.officename}</b>
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Formik
                enableReinitialize
                initialValues={{
                  trainings:
                    !allSkills.loading &&
                    allSkills.data &&
                    allSkills.data.skills &&
                    allSkills.data.skills.length > 0 &&
                    allSkills.data.skills.filter(
                      (e) =>
                        e.variety === "TRAINING" &&
                        (e.office === null ||
                          (e.office && e.office.id === props.office))
                    ),
                }}
                onSubmit={(values) => {
                  handleAddTraining(values);
                }}
              >
                {({ values, handleChange, dirty, setFieldValue }) => (
                  <Form>
                    <Grid container direction="row" spacing={4}>
                      <Grid item xs={10}>
                        {" "}
                        <FieldArray name="trainings">
                          {({ push, remove }) => (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    colSpan={5}
                                  ></StyledTableCell>
                                </TableRow>
                                <TableRow>
                                  <StyledTableCell>Training</StyledTableCell>
                                  <StyledTableCell>
                                    Is Unit Specific
                                  </StyledTableCell>
                                  <StyledTableCell>Delete</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody data-testid="trainingTable">
                                {values.trainings &&
                                  values.trainings.length > 0 &&
                                  values.trainings.map((element, index) => (
                                    <TableRow key={index}>
                                      <StyledTableCell>
                                        <TextField
                                          type="text"
                                          variant="outlined"
                                          name={`trainings.${index}.name`}
                                          id={`trainings.${index}.name`}
                                          disabled={element.id != null && true}
                                          value={element.name}
                                          onChange={handleChange}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <Typography>
                                          {element.office === null
                                            ? "No"
                                            : "Yes"}
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <IconButton
                                          onClick={() => {
                                            setTrainingidtodelete(element.id);
                                            setshowDialog(true);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                                <TableRow>
                                  <StyledTableCell colSpan={2}>
                                    <Button
                                      color="primary"
                                      startIcon={<AddIcon />}
                                      onClick={() =>
                                        push({
                                          name: "",
                                        })
                                      }
                                    >
                                      Add Another
                                    </Button>
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          )}
                        </FieldArray>
                      </Grid>
                    </Grid>
                    <Box m={4} style={{ textAlign: "right" }}>
                      <Button
                        type="submit"
                        disabled={!dirty}
                        color="primary"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default DepartmentTrainings;
