import React from 'react';
import {
    makeStyles, Tabs, Tab, Grid, Typography
} from '@material-ui/core';
// import HospitalSettings from './HospitalSettings';
import ApplicationUISettings from './ApplicationUISettings';
import Integrations from './Integrations';
import ApplicationConfig from './ApplicationConfig';
import { locationConfigVar } from "../../cache";

const useStyles = makeStyles(() => ({
    headerSpacing: {
        marginTop: 30,
        marginBottom: 20
    }
}));



function AppSettings(props) {
    const classes = useStyles();
    const tab = props.location.props!=null && props.location.props.tab;
    const [value, setValue] = React.useState(tab? tab:0);
    const locationConfig = locationConfigVar();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Typography variant="h3" className={classes.headerSpacing}>Settings</Typography>
            <Grid container direction='column' spacing={2}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        textColor="primary"
                        onChange={handleChange}
                    >
                        <Tab label="Application Config" /> 
                        <Tab label="Application UI" />
                        {locationConfig.integrationsTab && <Tab label="Integrations" />}
                    </Tabs>
                </Grid>
                <Grid item xs={12} style={{marginLeft: 15}}>
                    {/* {value === 0 && (
                        <HospitalSettings />
                    )}*/}
                    {value === 0 && (
                        <ApplicationConfig />
                    )}  
                    {value === 1 && (
                        <ApplicationUISettings />
                    )}
                    {value === 2 && locationConfig.integrationsTab && (
                        <Integrations />
                    )}
                </Grid>
            </Grid>
        </div >
    );
}

export default AppSettings;