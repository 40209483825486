import React from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import moon from '../../../assets/quizImages/moon.png';
import sun from '../../../assets/quizImages/sun.png';
import swing from '../../../assets/quizImages/swing.png';

const useStyles = makeStyles(() => ({
    tile: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        border: '1px solid rgba(134, 134, 137, 0.4)'
    },
    clicked: {
        height: 175,
        width: 175,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'rgb(241, 221, 215)',
        border: '1px solid rgba(134, 134, 137, 0.2)'
    }
}));

const ShiftsLike = ({values, setFieldValue}) => {
    const classes = useStyles();

    const handleChange = (fieldName) => {
        if (values[fieldName] <= 0) {
            setFieldValue(fieldName, 1);
        } else {
            setFieldValue(fieldName, 0)
        }
    };

    return (  
        <Grid container item direction='column' alignItems='center' spacing={5} style={{padding: 25}}>
            <Grid item>
                <Typography style={{fontSize: 24}}>Which shifts do you like working?</Typography>
            </Grid>
            <Grid 
                item 
                container 
                justifyContent='center' 
                style={{padding: 50, paddingTop: 16, paddingBottom: 0}}
            >
                <Grid 
                    component={Paper} 
                    className={values.day > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('day')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Day Shift</Typography>
                    <img 
                        alt='day shift' 
                        src={sun} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.night > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('night')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Night Shift</Typography>
                    <img 
                        alt='night shift' 
                        src={moon} 
                        style={{position: 'absolute', bottom: 10, left: 25, height: 125, width: 125}}
                    />
                </Grid>
                <Grid 
                    component={Paper} 
                    className={values.swing > 0 ? classes.clicked : classes.tile} 
                    onClick={() => handleChange('swing')}
                    item
                    style={{position: 'relative'}}
                >
                    <Typography>Swing Shift</Typography>
                    <img 
                        alt='swing shift' 
                        src={swing} 
                        style={{position: 'absolute', bottom: 10, left: 32, height: 125}}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
 
export default ShiftsLike;