import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
} from "@material-ui/core";
import CalendarIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EditIcon from "@material-ui/icons/Edit";
import BusinessIcon from "@material-ui/icons/Business";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DomainIcon from "@material-ui/icons/Domain";
import QueueIcon from "@material-ui/icons/Queue";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import ListAltIcon from '@material-ui/icons/ListAlt';
import { userVar, locationConfigVar } from "../../cache";
import Ability from "../../Roles/defineAbility";
import WorkIcon from "@material-ui/icons/Work";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import HomeIcon from "@material-ui/icons/Home";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { Tooltip } from "@mui/material";
import { Assignment } from "@material-ui/icons";

function DashBoardSideNav(props) {
  let setDrawerOpen = props.setDrawerOpen;
  // const user = useSelector(state => state.user.userData);
  // const userRole = user ? user.role : '';
  const location = useLocation();
  const history = useHistory();

  const mapPathnames = {
    "/": 0,
    "/Schedule": 1,
    "/Preferences": 2,
    "/CreateSchedule": 3,
    "/ManageTeam": 4,
    "/ManageMentees": 5,
    "/Admin": 6,
    "/HospitalSettings": 7,
    "/Settings": 8,
    "/RolesandPermissions": 9,
    "/Requests": 10,
    "/FloatSettings": 11,
    "/FloatNeeds": 12,
    "/DepartmentStatus": 13,
  };

  const defaultIndex = mapPathnames[location.pathname]
    ? mapPathnames[location.pathname]
    : 0;

  const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);
  const [managerShowmore, setManageTeamShowMore] = React.useState(false);
  const [floatShowMore, setFloatShowMore] = React.useState(false);

  const [departmentShowmore, setdepartmentShowmore] = React.useState(false);
  const [analyticsShowmore, setanalyticstShowmore] = React.useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const user = userVar();
  const ability = Ability(user);
  const locationConfig = locationConfigVar();

  return (
    <List>
      {ability.can("manage", "Calendar") &&
        ability.can("manage", "Preferences") && (
          <>
            <ListItem
              button
              component={Link}
              to="/"
              selected={selectedIndex === 0}
              onClick={(event) => {
                handleListItemClick(event, 0);
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Tooltip title="Home" placement="right">
                  <HomeIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/Schedule"
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>
                <Tooltip title="Schedule" placement="right">
                  <CalendarIcon />
                </Tooltip>{" "}
              </ListItemIcon>

              <ListItemText>Schedule</ListItemText>
            </ListItem>
            {user.isSchedulable && (
              <ListItem
                button
                component={Link}
                to="/Preferences"
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
              >
                <ListItemIcon>
                  <Tooltip title="Preferences" placement="right">
                    <EditIcon />
                  </Tooltip>{" "}
                </ListItemIcon>
                <ListItemText>Preferences</ListItemText>
              </ListItem>
            )}
          </>
        )}
      {ability.can("manage", "Requests") && (
        <>
          <ListItem
            button
            component={Link}
            to="/Requests"
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon>
              <Tooltip title="Requests" placement="right">
                <QueueIcon />
              </Tooltip>{" "}
            </ListItemIcon>
            <ListItemText>Requests</ListItemText>
          </ListItem>
        </>
      )}
      {(ability.can("manage", "Users") ||
        ability.can("manage", "Schedule")) && (
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Team" placement="right">
              <PersonIcon
                onClick={() => setManageTeamShowMore(!managerShowmore)}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>Team</ListItemText>

          <ListItemIcon>
            <Tooltip title="Team" placement="right">
              <IconButton
                onClick={() => setManageTeamShowMore(!managerShowmore)}
              >
                {" "}
                {!managerShowmore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Tooltip>
          </ListItemIcon>
        </ListItem>
      )}
      {managerShowmore && (
        <Box ml={3}>
          {ability.can("manage", "Schedule") && user.office.parent === null && (
            <>
              <ListItem
                button
                component={Link}
                to="/CreateSchedule"
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <Tooltip title="Create Schedule" placement="right">
                  <ListItemIcon>
                    <ScheduleIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Create Schedule</ListItemText>
              </ListItem>
            </>
          )}
          {ability.can("manage", "Users") && (
            <>
              <ListItem
                button
                component={Link}
                to="/ManageTeam"
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
              >
                <Tooltip title="Manage Team" placement="right">
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText>Manage Team</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/ManageMentees"
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <Tooltip title="Manage Mentees" placement="right">
                  <ListItemIcon>
                    <SupervisorAccountIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText>Manage Mentor/Mentees</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/ImportData"
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
              >
                <Tooltip title="Import Data" placement="right">
                  <ListItemIcon>
                    <SystemUpdateAltIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText>Import Data</ListItemText>
              </ListItem>
            </>
          )}
        </Box>
      )}
      {(ability.can("manage", "Users") || ability.can("manage", "Schedule")) &&
        user.floatOffice && (
          <ListItem button>
            <Tooltip title="Manage Team" placement="right">
              <ListItemIcon>
                <PeopleIcon onClick={() => setFloatShowMore(!floatShowMore)} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Float</ListItemText>
            <ListItemIcon>
              <IconButton onClick={() => setFloatShowMore(!floatShowMore)}>
                {!floatShowMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </ListItemIcon>
          </ListItem>
        )}
      {floatShowMore && user.floatOffice && (
        <Box ml={3}>
          <ListItem
            button
            component={Link}
            to="/EmployeeAllocation"
            selected={selectedIndex === 8}
            onClick={(event) => handleListItemClick(event, 8)}
          >
            <Tooltip title="Employee Allocation" placement="right">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Employee Allocation</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/FloatSettings"
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 9)}
          >
            <Tooltip title="Settings" placement="right">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Settings</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/DepartmentStatus"
            selected={selectedIndex === 10}
            onClick={(event) => handleListItemClick(event, 10)}
          >
            <Tooltip title="Department Status" placement="right">
              <ListItemIcon>
                <CheckCircleOutlineIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Department Status</ListItemText>
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/FloatNeeds"
            selected={selectedIndex === 11}
            onClick={(event) => handleListItemClick(event, 11)}
          >
            <Tooltip title="Float Needs" placement="right">
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Department Needs</ListItemText>
          </ListItem>
        </Box>
      )}
      {(ability.can("manage", "AllOffices") ||
        ability.can("manage", "LocationSettings") ||
        ability.can("manage", "AppSettings") ||
        ability.can("manage", "RolesandPermissions")) && (
        <ListItem button>
          <Tooltip title="Departments" placement="right">
            <ListItemIcon>
              <BusinessIcon
                onClick={() => setdepartmentShowmore(!departmentShowmore)}
              />{" "}
            </ListItemIcon>
          </Tooltip>

          <ListItemText>Department</ListItemText>
          <ListItemIcon>
            <IconButton
              onClick={() => setdepartmentShowmore(!departmentShowmore)}
            >
              {!departmentShowmore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </ListItemIcon>
        </ListItem>
      )}
      {departmentShowmore && (
        <Box ml={3}>
          {ability.can("manage", "AllOffices") && (
            <>
              <ListItem
                button
                component={Link}
                to="/Admin"
                selected={selectedIndex === 12}
                onClick={(event) => handleListItemClick(event, 12)}
              >
                <Tooltip title="Departments" placement="right">
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Departments</ListItemText>
              </ListItem>
              {!locationConfig.shiftBasedDisplay && (
                <ListItem
                  button
                  component={Link}
                  to="/MissionDetails"
                  selected={selectedIndex === 13}
                  onClick={(event) => handleListItemClick(event, 13)}
                >
                  <Tooltip title="Mission Details" placement="right">
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>Mission Details</ListItemText>
                </ListItem>
              )}
            </>
          )}
          {ability.can("manage", "LocationSettings") && (
            <>
              <ListItem
                button
                component={Link}
                to="/LocationSettings"
                selected={selectedIndex === 14}
                onClick={(event) => handleListItemClick(event, 14)}
              >
                <Tooltip title="Location Settings" placement="right">
                  <ListItemIcon>
                    <LocalLibraryIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Setup SkillSet </ListItemText>
              </ListItem>
            </>
          )}
          {ability.can("manage", "AppSettings") && (
            <>
              <ListItem
                button
                component={Link}
                to="/AppSettings"
                selected={selectedIndex === 15}
                onClick={(event) => handleListItemClick(event, 15)}
              >
                <Tooltip title="App Settings" placement="right">
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText>Settings</ListItemText>
              </ListItem>
            </>
          )}

          {ability.can("manage", "RolesandPermissions") && (
            <>
              <ListItem
                button
                component={Link}
                to="/RolesandPermissions"
                selected={selectedIndex === 16}
                onClick={(event) => handleListItemClick(event, 16)}
              >
                <Tooltip title="Roles and Permissions" placement="right">
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Roles and Permissions</ListItemText>
              </ListItem>
            </>
          )}
        </Box>
      )}

      {(ability.can("manage", "Users") || ability.can("manage", "Schedule")) &&
        locationConfig.analyticsTab && (
          <ListItem button>
            <Tooltip title="Analytics" placement="right">
              <ListItemIcon>
                <AssessmentIcon
                  onClick={() => setanalyticstShowmore(!analyticsShowmore)}
                />
              </ListItemIcon>
            </Tooltip>

            <ListItemText>Analytics</ListItemText>
            <Tooltip title="Analytics" placement="right">
              <ListItemIcon>
                <IconButton
                  onClick={() => setanalyticstShowmore(!analyticsShowmore)}
                >
                  {!analyticsShowmore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        )}

      {analyticsShowmore && locationConfig.analyticsTab && (
        <Box ml={3}>
          {ability.can("manage", "AllOffices") && user.floatOffice && (
            <ListItem
              button
              component={Link}
              to="/FloatAnalytics"
              selected={selectedIndex === 17}
              onClick={(event) => handleListItemClick(event, 17)}
            >
              <Tooltip title="Float Analytics" placement="right">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
              </Tooltip>

              <ListItemText>Float Analytics</ListItemText>
            </ListItem>
          )}
          {ability.can("manage", "AllOffices") && !user.floatOffice && (
            <>
              <ListItem
                button
                component={Link}
                to="/ScheduleQualityAnalytics"
                selected={selectedIndex === 18}
                onClick={(event) => handleListItemClick(event, 18)}
              >
                <Tooltip title="Schedule Quality Analytics" placement="right">
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Schedule Quality</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/PreferenceAnalytics"
                selected={selectedIndex === 19}
                onClick={(event) => handleListItemClick(event, 19)}
              >
                <Tooltip title="Preference Analytics" placement="right">
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Preferences</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/SkillSetAnalytics"
                selected={selectedIndex === 20}
                onClick={(event) => handleListItemClick(event, 20)}
              >
                <Tooltip title="Skillset Analytics" placement="right">
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                </Tooltip>

                <ListItemText>Skill Set</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/TimeOffAnalytics"
                selected={selectedIndex === 21}
                onClick={(event) => handleListItemClick(event, 21)}
              >
                <Tooltip title="Time Off Analytics" placement="right">
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText>Time Off</ListItemText>
              </ListItem>
              {user.isPrimaryParentOffice && (
                <ListItem
                  button
                  component={Link}
                  to="/LocationPreferenceAnalytics"
                  selected={selectedIndex === 22}
                  onClick={(event) => handleListItemClick(event, 22)}
                >
                  <Tooltip
                    title="Location Preference Analytics"
                    placement="right"
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                  </Tooltip>

                  <ListItemText>Location Preferences</ListItemText>
                </ListItem>
              )}
            </>
          )}
        </Box>
      )}
    </List>
  );
}

export default DashBoardSideNav;
