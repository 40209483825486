import React from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { GET_FLOAT_DETAILS } from "../../api/gqlQueries";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    width: 150,
  },
}));

const DepartmentStatus = () => {
  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_FLOAT_DETAILS);

  const statusToText = {
    'DRAFT': 'Draft',
    'REQUESTING_FLOATS': 'Requesting Floats for Initial Schedule',
    'REQUESTING_ADDITIONAL_FLOATS': 'Requesting Floats for Gaps',
    'PUBLISHED': 'Published'
  };

  const columns = [
    {
      field: "name",
      headerName: "Department",
      width: 300,
    },
    {
      field: "Schedule Period",
      width: 300,
      headerName: "Schedule Period",
      valueGetter: (params) => {
        const mostRecent = getMostRecent(params.row);
        if (mostRecent) {
          const start = format(
            new Date(`${mostRecent.start}T08:00:00`),
            'dd MMM yyyy'
          );
          const end = format(
            new Date(`${mostRecent.end}T08:00:00`),
            'dd MMM yyyy'
          );
          return `${start} to ${end}`;
        } else {  
          return 'No schedules generated';
        }
      }
    },
    {
      field: "Schedule Status",
      width: 300,
      headerName: "Schedule Status",
      filterable: false,
      sortable: true,
      valueGetter: (params) => {
        const mostRecent = getMostRecent(params.row);
        if (mostRecent) {
          return statusToText[mostRecent.status] || 'Draft';
        } else {  
          return '';
        }
      }
    }
  ];

  function getMostRecent(office) {
    const schedules = [...office.scheduleperiodSet];
    const mostRecent = schedules.length > 0
      ? schedules.sort((a,b) => (
        new Date(b.start) - new Date(a.start)
      ))[0]
      : null;
    return mostRecent;
  }

  if (loading) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return <Typography>Something went wrong. Please try again.</Typography>;
  } else {
    const rows = data.offices.filter(office => (
      office.floatStatus === false
    ));

    return (
      <Grid container>
        <Grid item xs={12} className={classes.headerSpacing}>
            <Typography variant="h3">Department Status</Typography>
        </Grid>
        <Grid item xs={10}>
          <DataGrid
            className={classes.dt}
            rows={rows}
            columns={columns}
            autoHeight
            autoPageSize
          />
        </Grid>
      </Grid>
    );
  }
};

export default DepartmentStatus;
