import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tab,
  Tabs,
  makeStyles,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import TimeOff from "./TimeOff";
import ManagerTimeOff from "./ManagerTimeOff";
import SoftTimeOff from "./SoftTimeOff";
import ShiftSwitch from "./ShiftSwitch";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { userVar, locationConfigVar } from "../../cache";
import { eachDayOfInterval, format } from "date-fns";
import {
  formatSoftRequests,
  formatTimeOff,
} from "../../helpers/formatShiftEvents";
import {
  GET_INCOMING_TO_REQUESTS,
  GET_REQUESTS,
  MANAGER_GET_INCOMING_SS_REQUESTS,
  EMPLOYEE_GET_INCOMING_SS_REQUESTS,
  NOTIFY_DEVELOPERS,
} from "../../api/gqlQueries";
import Roles from "../../Roles/roles";
import ErrorSnackBar from "../errors/errorSnackBar";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
}));

const Requests = () => {
  const classes = useStyles();

  const user = userVar();
  const managerAccess =
    user.role === Roles.MANAGER ||
    user.role === Roles.SCHEDULER ||
    user.role === Roles.ADMIN;

  const locationConfig = locationConfigVar();
  const shiftDisplay = locationConfig.shiftBasedDisplay;
  const environment = process.env.NODE_ENV;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const { loading, error, data, refetch } = useQuery(GET_REQUESTS, {
    variables: {
      officeId: parseInt(user.office.id),
      userId: parseInt(user.id),
    },
    fetchPolicy: "cache-and-network",
    onError(err) {
      setOpenSnackBar2(true);
      setSnackBarMessage("Graphql " + err);
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_REQUESTS Query. Environment: " +
            environment +
            " Graphql " +
            err,
        },
      });
    },
  });

  const [view, setView] = useState(0);
  const [toast, setToast] = useState("");
  const [errorToast, setErrorToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [getTORequests, { data: timeOffRequestData, loading: timeOffLoading,refetch: timeOffRequestsRefetch }] =
    useLazyQuery(GET_INCOMING_TO_REQUESTS, {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_INCOMING_TO_REQUESTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    });

  const [
    getManagerSSRequests,
    {
      data: managerSwapRequestData,
      called: managerSwapCalled,
      loading: managerSwapLoading,
      refetch: refetchManager,
    },
  ] = useLazyQuery(MANAGER_GET_INCOMING_SS_REQUESTS, {
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on MANAGER_GET_INCOMING_SS_REQUESTS lazyQuery. Environment: " +
            environment +
            " Graphql " +
            error,
        },
      });
    },
  });

  const [
    getEmployeeSSRequests,
    {
      data: employeeSwapRequestData,
      called: employeeSwapCalled,
      loading: employeeSwapLoading,
      refetch: refetchEmployee,
    },
  ] = useLazyQuery(
    EMPLOYEE_GET_INCOMING_SS_REQUESTS,
    {
      onError(error) {
        console.log(error);

        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on EMPLOYEE_GET_INCOMING_SS_REQUESTS lazyQuery. Environment: " +
              environment +
              " Graphql " +
              error,
          },
        });
      },
    },
    {
      onCompleted(data) {
        console.log(data);
      },
    }
  );

  useEffect(() => {
    if (managerAccess) {
      getManagerSSRequests({
        variables: {
          employee: parseInt(user.id),
          officeId: parseInt(user.office.id),
        },
      });
      getTORequests({
        variables: {
          officeId: parseInt(user.office.id),
        },
      });
    } else {
      getEmployeeSSRequests({
        variables: {
          employee: parseInt(user.id),
        },
      });
    }
  }, []);

  const toastMessage = () => {
    const toasts = {
      "Employee Shift Switch": "Shift switch request sent!",
      "Manager Shift Switch": "Shift switch successful! Employees notified.",
      "New Time Off Request": "Time off request sent for approval!",
      "Edit Time Off Request":
        "Time off request updated and sent for approval!",
      "Delete Time Off Request": "Time off request deleted!",
      "New Soft Request": "Soft request entered successfully!",
      "Delete Soft Request": "Soft request deleted!",
      "Manager Approve Time Off Request": "Time off request approved!",
      "Manager Deny Time Off Request": "Time off request denied!",
      "Manager Approve Shift Switch": "Shift switch request approved!",
      "Manager Deny Shift Switch": "Shift switch request denied!",
    };
    return toasts[toast] ? toasts[toast] : "Success!";
  };

  const errorToastMessage = () => {
    const errorToasts = {
      "Non Eligible Shift Switch": "No eligible shifts to switch.",
      "Error Delete Soft Request":
        "Unable to delete soft request. Please try again.",
      "Error Delete Time Off":
        "Unable to delete time off request. Please try again.",
      "Error Approve Time Off":
        "Unable to approve time off request. Please try again.",
      "Error Deny Time Off":
        "Unable to deny time off request. Please try again.",
      "Error Approve Shift Switch":
        "Unable to approve shift switch request. Please try again.",
      "Error Deny Shift Switch":
        "Unable to deny shift switch request. Please try again.",
    };
    return errorToasts[errorToast]
      ? errorToasts[errorToast]
      : "Error. Please try again.";
  };

  if (
    loading ||
    timeOffLoading ||
    (managerSwapCalled && managerSwapLoading) ||
    (employeeSwapCalled && employeeSwapLoading) 
  ) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    return (
      <>
        <Grid container className={classes.headerSpacing}>
          <Grid item xs={12}>
            <Typography variant="h3">Requests</Typography>
          </Grid>
          <Grid
            component={Tabs}
            item
            value={view}
            onChange={(e, value) => setView(value)}
            indicatorColor="primary"
            xs={12}
          >
            <Tab label="Time Off" />
            {shiftDisplay && <Tab label="Soft Time Off" />}
            <Tab label="Shift Switch" />
          </Grid>
        </Grid>
        <ErrorSnackBar
          open={openSnackBar2}
          message={snackBarMessage}
          close={() => setOpenSnackBar2(false)}
        />
      </>
    );
  } else {
    let allViewableSchedules = [...data.schedulePeriods];
    allViewableSchedules = allViewableSchedules.filter(
      (schedule) =>
        schedule.status === "PUBLISHED" ||
        schedule.status === "DRAFT" ||
        schedule.status === "MANAGER_DRAFT"
    );
    const mostRecentIncludingDraft =
      allViewableSchedules.length > 0
        ? allViewableSchedules.sort(
            (a, b) => new Date(b.start) - new Date(a.start)
          )[0]
        : null;

    let scheduleEnd;
    if (mostRecentIncludingDraft) {
      scheduleEnd = new Date(mostRecentIncludingDraft.end);
    } else {
      scheduleEnd = new Date();
    }

    const maxGreyout = data.offices[0].maxGreyoutRequests;
    const schedulePeriodWeeks = data.offices[0].scheduleDuration
      ? data.offices[0].scheduleDuration
      : 4;

    const timeOffRequestsNotCancelled = data.me.timeOff.filter(
      (request) => request.userCancelled === false
    );

    const userTimeOff = formatTimeOff(timeOffRequestsNotCancelled, user.id);

    let invalidDates = [];

    userTimeOff.forEach((request) => {
      let firstDay = request.start;
      let lastDay = request.end;
      let arrayOfTimeOff = eachDayOfInterval({ start: firstDay, end: lastDay });
      invalidDates = [...invalidDates, ...arrayOfTimeOff];
    });

    const userSoft = formatSoftRequests(data.me.softRequests, user.id);
    userSoft.forEach((softRequest) => {
      invalidDates.push(softRequest.start);
    });
    invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

    return (
      <>
        <Grid container className={classes.headerSpacing}>
          <Grid item xs={12}>
            <Typography variant="h3">Requests</Typography>
          </Grid>
          <Grid
            component={Tabs}
            item
            value={view}
            onChange={(e, value) => setView(value)}
            indicatorColor="primary"
            xs={12}
          >
            <Tab label="Time Off" />
            {shiftDisplay && <Tab label="Soft Time Off" />}
            <Tab label="Shift Switch" />
          </Grid>
          {view === 0 && !managerAccess && (
            <TimeOff
              timeOffRequests={userTimeOff}
              scheduleEnd={scheduleEnd}
              invalidDates={invalidDates}
              softRequests={userSoft}
              setToast={setToast}
              setShowToast={setShowToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )}
          {view === 0 && managerAccess && timeOffRequestData && (
            <ManagerTimeOff
              outgoingTimeOff={userTimeOff}
              incomingTimeOff={formatTimeOff(
                timeOffRequestData.timeOff,
                user.id
              )}
              timeOffRequestsRefetch={timeOffRequestsRefetch}
              scheduleEnd={scheduleEnd}
              invalidDates={invalidDates}
              softRequests={userSoft}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )}
          {view === 1 && shiftDisplay && (
            <SoftTimeOff
              softRequests={userSoft}
              scheduleEnd={scheduleEnd}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              schedulePeriodWeeks={schedulePeriodWeeks}
              maxGreyout={maxGreyout}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          )}
          {((view === 2 && shiftDisplay) || (view === 1 && !shiftDisplay)) &&
            ((managerAccess && managerSwapRequestData) ||
              (!managerAccess && employeeSwapRequestData)) && (
              <ShiftSwitch
                shiftSwitchData={
                  managerAccess
                    ? managerSwapRequestData
                    : employeeSwapRequestData
                }
                scheduleEnd={scheduleEnd}
                refetch={managerAccess ? refetchManager : refetchEmployee}
                setToast={setToast}
                setShowToast={setShowToast}
                setErrorToast={setErrorToast}
                setShowErrorToast={setShowErrorToast}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
        </Grid>
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>{toastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>{errorToastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
        <ErrorSnackBar
          open={openSnackBar}
          message={snackBarMessage}
          close={() => setOpenSnackBar(false)}
        />
      </>
    );
  }
};

export default Requests;
