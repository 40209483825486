import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  InputLabel,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { findEmployeesToSwitch } from "../../helpers/shiftSwitch";
// import EmpSelectEmployeesForm from "./EmpSelectEmployeesForm";
import EmpSelectEmployeesandDates from "./EmpSelectEmployeesandDates";
import { add, format, isSameDay } from "date-fns";
import {
  GET_SHIFT_ASSIGNMENT,
  RESCHEDULE_SWAP_OPTIONS,
  ACCEPT_OPTION,
  CREATE_RESCHEDULE_OPTION,
} from "../../api/gqlQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { userVar } from "../../cache";

const useStyles = makeStyles((theme) => ({
  input: {
    width: 225,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.primary.main,
  },
  coloredText: {
    color: "#8CADE1",
    fontWeight: 700,
  },
  dateError: {
    color: theme.palette.primary.main,
    width: 225,
    paddingLeft: 14,
    paddingRight: 14,
  },
  select: {
    width: 273,
    marginTop: theme.spacing(1),
  },
}));

const EmpShiftSwitchRequestForm = (props) => {
  const classes = useStyles();

  const {
    allEvents,
    userEvents,
    closeDialog,
    date,
    setToast,
    setShowToast,
    setErrorToast,
    setShowErrorToast,
    shiftSwitchBuffer,
    setOpenSnackBar,
    setSnackBarMessage,
    notifyDevelopers,
    environment,
  } = props;

  const user = userVar();

  //get user's skills with variety JOB_TYPE
  const userJobTypes = user.skills
    .filter((skill) => skill.variety === "JOB_TYPE")
    .map((skill) => skill.name);
  // get month events and filter out duplicates
  const allMonthEvents = allEvents;
  // get minimum Date for which the employee can create a shift swicth. This is an office setting
  const minimumDate = add(new Date(), { days: shiftSwitchBuffer });

  // get the shift assignment ID of the selected shift
  const [getShiftAssignmentId] = useLazyQuery(GET_SHIFT_ASSIGNMENT, {
    onCompleted(data) {
      setRescheduleSwapAddOptions([]);
      if (data.shiftAssignments.length > 0) {
        setError("");
        rescheduleSwapOptions({
          variables: {
            shiftAssignments: parseInt(data.shiftAssignments[0].id),
          },
        });
      }
    },
    onError(error) {
      console.log(error);
      setError("Please choose different dates and try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_SHIFT_ASSIGNMENT lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [acceptOption] = useMutation(ACCEPT_OPTION, {
    onCompleted(data) {
      setError("");
      setToast("Employee Shift Switch");
      setShowToast(true);
      closeDialog();
    },
    onError(error) {
      console.log(error);
      setError(
        "Unable to create shift switch request. Please check details and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on ACCEPT_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [createSwapOption] = useMutation(CREATE_RESCHEDULE_OPTION, {
    onCompleted(data) {
      setError("");
      acceptOption({
        variables: {
          acceptor: parseInt(user.id),
          option: parseInt(data.createRescheduleOption.option.id),
        },
      });
    },
    onError(error) {
      setError(
        "Unable to create shift switch request. Please check details and try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on CREATE_RESCHEDULE_OPTION Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [
    rescheduleSwapOptions,
    { called: rescheduleCalled, loading: rescheduleLoading },
  ] = useLazyQuery(RESCHEDULE_SWAP_OPTIONS, {
    onCompleted(data) {
      if (data.rescheduleSwaps.length > 0) {
        const swapsWithOptionStatus = data.rescheduleSwaps.filter((swap) => {
          const past = swap.rescheduleindividualSet[0].rescheduleactionSet.find(
            (action) => new Date(action.shift.start) < minimumDate
          );

          return swap.status === "OPTION" && !past;
        });
        setRescheduleOptionIds([]);
        setRescheduleSwapAddOptions(swapsWithOptionStatus);
        setManualEntry(false);
      } else {
        setRescheduleSwapAddOptions([]);
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on RESCHEDULE_SWAP_OPTIONS lazyQuery. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [manualEntry, setManualEntry] = useState(false);
  const [error, setError] = useState("");
  const [desiredDateError, setDesiredDateError] = useState("");
  const [currentDateError, setCurrentDateError] = useState("");
  const [currentDate, setCurrentDate] = useState();
  const [desiredDate, setDesiredDate] = useState();

  const [currentDateEmployees, setCurrentDateEmployees] = useState([]);
  const [desiredDateEmployees, setDesiredDateEmployees] = useState([]);
  const [eligibleEmployees, setEligibleEmployees] = useState([]);
  const [employeeToNotify, setEmployeeToNotify] = useState("select");

  const [rescheduleSwapAddOptions, setRescheduleSwapAddOptions] = useState([]);
  const [rescheduleOptionIds, setRescheduleOptionIds] = useState([]);

  const toggleManualEntry = () => setManualEntry(!manualEntry);

  const formattedUserScheduleDates = userEvents.map((event) =>
    format(new Date(event.start), "MM/dd/yyyy")
  );

  // get participants of an event for a date passed
  const getParticipants = (date) => {
    const eventsOnDate = allMonthEvents.filter((event) =>
      isSameDay(new Date(event.start), date)
    );

    return eventsOnDate
      .map((event) => {
        if (event.participants) {
          return event.participants.map((participant) => {
            return {
              ...participant,
              shift: {
                start: event.start,
                end: event.end,
                id: event.eventId,
              },
            };
          });
        } else {
          return event;
        }
      })
      .flat();
  };

  useEffect(() => {
    //grab date that is passed in from user selection on calendar
    const selectedDate =
      date && new Date(date) > minimumDate ? new Date(date) : minimumDate;
    selectedDate.setHours(0, 0, 0, 0);

    //find first user event and first nonUser event
    const userFirstEvent = userEvents.filter(
      (event) =>
        event.start > selectedDate || isSameDay(event.start, selectedDate)
    )[0];

    const nonUserFirstEvent = allMonthEvents.filter(
      (event) =>
        !formattedUserScheduleDates.includes(
          format(event.start, "MM/dd/yyyy")
        ) && event.start > minimumDate
    )[0];
    //find employees eligible to make switch based on events
    if (userFirstEvent && nonUserFirstEvent) {
      const firstDateParticipants = getParticipants(userFirstEvent.start);
      const secondDateParticipants = getParticipants(nonUserFirstEvent.start);

      const eligibleEmployees = findEmployeesToSwitch(
        firstDateParticipants,
        secondDateParticipants,
        userJobTypes
      );

      setCurrentDate(new Date(userFirstEvent.start));
      setDesiredDate(new Date(nonUserFirstEvent.start));

      //get shift assignment ID for first shift
      getShiftAssignmentId({
        variables: {
          employeeId: parseInt(user.id),
          shiftId: parseInt(userFirstEvent.eventId),
        },
      });

      //set state with all starting data
      setCurrentDateEmployees(firstDateParticipants);
      setDesiredDateEmployees(secondDateParticipants);
      setEligibleEmployees(eligibleEmployees);
    } else {
      setErrorToast("Non Eligible Shift Switch");
      setShowErrorToast(true);
      closeDialog();
      return;
    }
  }, []);

  const handleCurrentDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      const currentDateShift = userEvents.find((event) =>
        isSameDay(event.start, date)
      );
      if (currentDateShift) {
        setCurrentDate(new Date(currentDateShift.start));

        getShiftAssignmentId({
          variables: {
            employeeId: parseInt(user.id),
            shiftId: parseInt(currentDateShift.eventId),
          },
        });

        const newCurrentDateEmployees = getParticipants(currentDateShift.start);

        setCurrentDateEmployees(newCurrentDateEmployees);
        const newEligible = findEmployeesToSwitch(
          newCurrentDateEmployees,
          desiredDateEmployees,
          userJobTypes
        );

        setEmployeeToNotify("select");
        setEligibleEmployees(newEligible);
        setCurrentDateError("");
        setError("");
      } else {
        setCurrentDateError("You must choose a date you are working");
        setEmployeeToNotify("select");
        setCurrentDate(date);
        setRescheduleOptionIds([]);
        setRescheduleSwapAddOptions([]);
      }
    }
  };

  const handleDesiredDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      if (!checkSecondDateInvalid(date)) {
        setDesiredDate(date);
        setEmployeeToNotify("select");

        const newEmployees = getParticipants(date);
        setDesiredDateEmployees(newEmployees);
        const newEligible = findEmployeesToSwitch(
          currentDateEmployees,
          newEmployees,
          userJobTypes
        );
        setEligibleEmployees(newEligible);
        setError("");
        setDesiredDateError("");
      } else {
        setDesiredDateEmployees([]);
        setEligibleEmployees([]);
        setEmployeeToNotify("select");
        setDesiredDateError(
          "You must choose a desired date that you are not working"
        );
        setDesiredDate(date);
      }
    }
  };

  const handleSubmit = () => {
    if (rescheduleOptionIds.length > 0) {
      rescheduleOptionIds.forEach((id) => {
        acceptOption({
          variables: {
            acceptor: parseInt(user.id),
            option: parseInt(id),
          },
        });
      });
    } else if (employeeToNotify !== "select") {
      //manually create swap options
      const userShift = userEvents.find((event) =>
        isSameDay(event.start, currentDate)
      );
      const employee = eligibleEmployees.find(
        (employee) => parseInt(employee.id) === parseInt(employeeToNotify)
      );

      if (userShift && employee) {
        const individuals = [
          {
            employee: parseInt(employee.id),
            cost: 0,
            benefit: 0,
            actions: [
              {
                actionType: "ADD",
                shift: userShift.eventId,
              },
              {
                actionType: "DROP",
                shift: employee.shift.id,
              },
            ],
          },
          {
            employee: parseInt(user.id),
            cost: 0,
            benefit: 0,
            actions: [
              {
                actionType: "DROP",
                shift: userShift.eventId,
              },
              {
                actionType: "ADD",
                shift: employee.shift.id,
              },
            ],
          },
        ];

        createSwapOption({
          variables: {
            optionType: "SWAP",
            individuals: individuals,
            userGenerated: true,
          },
        });
      }
    } else {
      setError("You must choose an employee to notify");
    }
  };

  const checkFirstDateInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return !formattedUserScheduleDates.includes(formatted);
  };

  const checkSecondDateInvalid = (date) => {
    const formatted = format(date, "MM/dd/yyyy");
    return formattedUserScheduleDates.includes(formatted);
  };

  const eligibleToSave = Boolean(
    (rescheduleOptionIds.length > 0 || employeeToNotify !== "select") &&
      currentDate > minimumDate
  );

  const eligibleEmployeesPresent = eligibleEmployees.length > 0;
  const rescheduleSwapOptionsPresent = rescheduleSwapAddOptions.length > 0;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <Grid item style={{ marginTop: 16 }}>
          <Typography variant="h5">Dates to Switch</Typography>
        </Grid>
        <Grid item container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <InputLabel htmlFor="current-date">
              <Typography variant="h5">From</Typography>
            </InputLabel>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="current-date"
              shouldDisableDate={checkFirstDateInvalid}
              value={currentDate}
              onChange={handleCurrentDateChange}
              minDate={minimumDate}
              className={classes.input}
            />
            {currentDateError && (
              <Typography variant="body2" className={classes.dateError}>
                {currentDateError}
              </Typography>
            )}
          </Grid>

          {manualEntry && (
            <Grid item>
              <InputLabel htmlFor="desired-date">
                <Typography variant="h5">To</Typography>
              </InputLabel>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                id="desired-date"
                shouldDisableDate={checkSecondDateInvalid}
                value={desiredDate}
                onChange={handleDesiredDateChange}
                minDate={minimumDate}
                className={classes.input}
              />
              {desiredDateError && (
                <Typography variant="body2" className={classes.dateError}>
                  {desiredDateError}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item style={{ marginTop: 16 }}>
          {(rescheduleSwapOptionsPresent ||
            (manualEntry && eligibleEmployeesPresent)) && (
            <Typography className={classes.spaceBelow}>
              Select the employees you would like to send the request to:
            </Typography>
          )}
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="space-evenly"
            spacing={1}
          >
            {error && (
              <Grid item>
                <Typography className={classes.error}>{error}</Typography>
              </Grid>
            )}
            {rescheduleCalled && rescheduleLoading && <CircularProgress />}
            {
              manualEntry && (
                <Select
                  id="select-employee"
                  variant="outlined"
                  value={employeeToNotify}
                  onChange={(e) => setEmployeeToNotify(e.target.value)}
                  className={classes.select}
                >
                  <MenuItem value="select">Select Employee</MenuItem>
                  {eligibleEmployees.length > 0 ? (
                    eligibleEmployees.map((employee, index) => (
                      <MenuItem
                        key={`${employee.lastName}-${index}`}
                        value={employee.id}
                      >
                        <Typography>
                          {employee.firstName + " " + employee.lastName}
                          <span style={{ fontSize: 15, fontWeight: 500 }}>
                            {format(
                              new Date(employee.shift.start),
                              " dd MMM "
                            ) +
                              format(new Date(employee.shift.start), "HH:mm") +
                              "-" +
                              format(new Date(employee.shift.end), "HH:mm")}
                          </span>
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={"none"}>
                      <Typography>No Eligible Employees</Typography>
                    </MenuItem>
                  )}
                </Select>
              )
              // <EmpSelectEmployeesForm
              //   employees={eligibleEmployees}
              //   setEmployees={setEmployeesToNotify}
              //   setError={setError}
              // />
            }
            {rescheduleSwapOptionsPresent && !manualEntry && (
              <EmpSelectEmployeesandDates
                rescheduleOptions={rescheduleSwapAddOptions}
                setRescheduleOptionIds={setRescheduleOptionIds}
                setError={setError}
              />
            )}
            {!rescheduleSwapOptionsPresent && !eligibleEmployeesPresent && (
              <Grid item>
                <Typography>
                  There are no shift switch options available. Please try
                  different dates.
                </Typography>
              </Grid>
            )}
            {!rescheduleSwapOptionsPresent && !manualEntry && (
              <Grid item>
                <Typography>
                  We could not find any algorithm-generated shift switch options
                  for this date.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{
            marginTop: 20,
            zIndex: 4,
            position: "absolute",
            bottom: 25,
          }}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleManualEntry}
              // disabled={
              //   !rescheduleSwapOptionsPresent &&
              //   !eligibleEmployeesPresent
              // }
            >
              {manualEntry ? "View Generated Options" : "View Manual Options"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!eligibleToSave}
              style={{ width: 169, height: 39, marginRight: 60 }}
            >
              Switch Shifts
            </Button>
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default EmpShiftSwitchRequestForm;
