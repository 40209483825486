import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Reset_Password } from "../../api/gqlQueries";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ErrorSnackBar from "../errors/errorSnackBar";
import { NOTIFY_DEVELOPERS } from "../../api/gqlQueries";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    marginTop: "200px",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string().required("Required").email(),
});

function ForgotPassword() {
  let history = useHistory();
  const classes = useStyles();
  const environment = process.env.NODE_ENV;
  const [snackbaropen, setsnackbaropen] = useState(false);
  const [errorsnackbaropen, seterrorsnackbaropen] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [loader, setloader] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessageGql, setSnackBarMessageGql] = useState(false);

  const [notifyDevelopers] = useMutation(NOTIFY_DEVELOPERS, {
    onError(error) {
      console.log(error);
    },
  });

  const handleemailsend = (email) => {
    const url =
      "https://amitjoxyq4.execute-api.us-east-1.amazonaws.com/Prod/sendpasswordreset";
    const data = {
      email: email,
      backend: window.location.origin,
    };
    axios({
      method: "post",
      url: url,
      data: data,
    })
      .then(function () {
        setsnackBarMessage("Password Reset Email Sent");
        setsnackbaropen(true);
      })
      .catch(function () {
        setsnackBarMessage(
          "Something went wrong.Could not send password reset email"
        );
        seterrorsnackbaropen(true);
      });
  };

  const [resetPassword] = useMutation(Reset_Password, {
    onCompleted(data) {
      if (data.resetPassword.success) {
        handleemailsend(emailToSend);
        setsnackBarMessage("Password Reset Email Sent");
        setsnackbaropen(true);
      } else {
        setsnackBarMessage(
          "Something went wrong.Could not send password reset email"
        );
        seterrorsnackbaropen(true);
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessageGql("We couldn't save your changes and are working hard to fix the error. Please refresh to try again.");
      notifyDevelopers({
        variables: {
          message:
            "Error on Reset_Password Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          resetPassword({
            variables: {
              email: values.email,
              newPassword: "Test@123",
            },
          });
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              className={classes.root}
              spacing={4}
            >
              <Snackbar
                open={snackbaropen}
                autoHideDuration={3000}
                onClose={() => {
                  setsnackbaropen(false);
                  history.push("/");
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MuiAlert
                  onClose={() => {
                    setsnackbaropen(false);
                    setsnackBarMessage("");
                  }}
                  severity="success"
                >
                  {snackBarMessage}
                </MuiAlert>
              </Snackbar>
              <Snackbar
                open={errorsnackbaropen}
                autoHideDuration={6000}
                onClose={() => {
                  seterrorsnackbaropen(false);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MuiAlert
                  onClose={() => seterrorsnackbaropen(false)}
                  severity="error"
                >
                  {snackBarMessage}
                </MuiAlert>
              </Snackbar>
              <Grid item xs={6}>
                <Typography variant="h3">Forgot Password</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  component={Link}
                  to="/"
                >
                  <IconButton color="primary">
                    <HomeIcon />
                  </IconButton>
                  Back to Login
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="email"
                  name="email"
                  style={{ width: "100%" }}
                  value={values.email}
                  onChange={(e) => {
                    setEmailToSend(e.target.value);
                    setFieldValue("email", e.target.value);
                  }}
                  variant="outlined"
                  placeholder="Enter Login Email"
                />
                {loader && <CircularProgress color="primary" />}
                <Typography color="primary">{errors.email}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  A temporary password will be sent to your email. You can
                  change your password after login.
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorSnackBar
        open={openSnackBar}
        message={snackBarMessageGql}
        close={() => setOpenSnackBar(false)}
      />
    </>
  );
}

export default ForgotPassword;
