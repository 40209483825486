import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Grid, TextField, Typography, IconButton, InputLabel, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { UPDATE_PROFILE } from '../../api/gqlQueries';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 273
    },
    error: {
        color: theme.palette.primary.main
    }
}));

const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().required('Required').email('Must be a valid email address'),
    primaryPhone: Yup.string().required('Required'),
    secondaryPhone: Yup.string()
    // primaryPhone: Yup.string().matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/g, 'Must be a valid phone number').required('Required'),
    // secondaryPhone: Yup.string().matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/g, 'Must be a valid phone number')
});

const UpdateProfileForm = (props) => {
    const classes = useStyles();
    
    const {
        user, 
        closeDialog, 
        refetch, 
        setShowToast
    } = props;

    const [error, setError] = useState('');

    const [updateProfile] = useMutation(UPDATE_PROFILE, {
        onCompleted(data) {
            console.log(data);
            refetch();
            setShowToast(true);
            closeDialog();
        },
        onError(error) {
            console.log(error);
            setError('Unable to update profile. Please try again.');
        }
    });

    const handlePhoneFormat = (phoneNumber) => {
        let input = phoneNumber.replace(/\D/g, '');
        if (input.length === 10) {
            input = '(' + input.slice(0, 3) + ') ' + input.slice(3, 6) + '-' + input.slice(6, 10);
        } else if (input.length > 10 && input[0] === '1') {
            input = '+1 ' + '(' + input.slice(1, 4) + ') ' + input.slice(4, 7) + '-' + input.slice(7, 11);
        } else {
            input = phoneNumber;
        }
        return input;
    };

    return (
        <Formik
            initialValues={
                (user !== null 
                    ? {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.personalEmail ? user.personalEmail : '',
                        primaryPhone: user.primaryPhone ? handlePhoneFormat(user.primaryPhone) : '',
                        secondaryPhone: user.secondaryPhone ? handlePhoneFormat(user.secondaryPhone) : '',
                    }
                    : {
                        firstName: '',
                        lastName: '',
                        email: '',
                        primaryPhone: '',
                        secondaryPhone: '',
                    }
                )
            }
            validationSchema={validationSchema}
            onSubmit={values => {
                updateProfile({
                    variables: {
                        id: user.id, 
                        input: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            personalEmail: values.email,
                            primaryPhone: values.primaryPhone,
                            secondaryPhone: values.secondaryPhone
                        }
                    }
                });
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <Grid
                    container
                    direction='row'
                    align='flex-start'
                    justifyContent='flex-start'
                    spacing={1}
                >
                    <Grid item style={{ position: 'relative' }} xs={12}>
                        <Typography variant='h2' >Update Profile</Typography>
                        <IconButton
                            color='secondary'
                            onClick={closeDialog}
                            style={{ position: 'absolute', top: 8, right: 2 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 15}}>
                        <Typography variant='h5' >Contact Details</Typography>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 10, height: 78}}>
                        <InputLabel htmlFor='first-name'>
                            <Typography variant='h5'>First Name</Typography>
                        </InputLabel>
                        <TextField
                            id='first-name'
                            variant='outlined'
                            name='firstName'
                            value={values.firstName}
                            onChange={handleChange}
                            className={classes.input}
                        />
                        <Typography variant='body2' className={classes.error}>
                            {errors.firstName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 10, height: 78}}>
                        <InputLabel htmlFor='last-name'>
                            <Typography variant='h5'>Last Name</Typography>
                        </InputLabel>
                        <TextField
                            id='last-name'
                            variant='outlined'
                            name='lastName'
                            value={values.lastName}
                            onChange={handleChange}
                            className={classes.input}
                        />
                        <Typography variant='body2' className={classes.error}>
                            {errors.lastName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 10, height: 78}}>
                        <InputLabel htmlFor='email'>
                            <Typography variant='h5'>Personal Email</Typography>
                        </InputLabel>
                        <TextField
                            id='email'
                            variant='outlined'
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            className={classes.input}
                        />
                        <Typography variant='body2' className={classes.error}>
                            {errors.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 10, height: 78}}>
                        <InputLabel htmlFor='primary-phone'>
                            <Typography variant='h5'>Primary Phone</Typography>
                        </InputLabel>
                        <TextField
                            id='primary-phone'
                            variant='outlined'
                            name='primaryPhone'
                            value={values.primaryPhone}
                            onChange={e => setFieldValue('primaryPhone', handlePhoneFormat(e.target.value))}
                            className={classes.input}
                            placeholder="(xxx) xxx-xxxx"
                        />
                        <Typography variant='body2' className={classes.error}>
                            {errors.primaryPhone}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 10, height: 78}}>
                        <InputLabel htmlFor='secondary-phone'>
                            <Typography variant='h5'>Secondary Phone</Typography>
                        </InputLabel>
                        <TextField
                            id='secondary-phone'
                            variant='outlined'
                            name='secondaryPhone'
                            value={values.secondaryPhone}
                            onChange={e => setFieldValue('secondaryPhone', handlePhoneFormat(e.target.value))}
                            className={classes.input}
                            placeholder="(xxx) xxx-xxxx"
                        />
                        <Typography variant='body2' className={classes.error}>
                            {errors.secondaryPhone}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12} style={{height: 20}}>
                        <Typography className={classes.error}>
                            {error}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent='flex-end' xs={12}>
                        <Grid item>
                            <Button 
                                variant='contained' 
                                color='primary' 
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Formik >
    );
};

UpdateProfileForm.propTypes = {
    closeDialog: PropTypes.func
};

export default UpdateProfileForm;