import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  Paper,
  makeStyles,
  DialogContent,
  Dialog,
  Popover,
  Snackbar,
  CircularProgress,
  Box,
  Badge,
  MenuItem,
  IconButton,
  SnackbarContent,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import TuneIcon from "@material-ui/icons/Tune";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import ShiftSwitchRequestForm from "../shiftSwitch/ShiftSwitchRequestForm";
import EmpCallInForm from "../shiftBasedCalendar/EmpCallInForm";
import Scheduler from "./Scheduler";
import SoftTimeOff from "../shiftBasedCalendar/SoftTimeOff";
import EditSoftTimeOff from "../shiftBasedCalendar/EditSoftTimeOff";
import TimeOffRequestForm from "../shiftBasedCalendar/TimeOffRequestForm";
import EditTimeOffRequestForm from "../shiftBasedCalendar/EditTimeOffRequestForm";
import MangEditShift from "../shiftBasedCalendar/MangEditShift";
import Roles from "../../Roles/roles";
import {
  formatTimeOff,
  formatSoftRequests,
  orderShifts,
} from "../../helpers/formatShiftEvents";
import {
  format,
  eachDayOfInterval,
  lastDayOfWeek,
  startOfWeek,
  add,
  addDays,
  endOfWeek,
  sub,
  addMonths,
  subMonths,
} from "date-fns";
import FilterMenu from "./FilterMenu";
import {
  useLazyQuery,
  useQuery,
  useReactiveVar,
  useMutation,
  NetworkStatus,
} from "@apollo/client";
import {
  filterListVar,
  userVar,
  selectedDateVar,
  getOfficeScheduleStatus,
  setShiftIDToEdit,
  schedulePeriodToUpdateAfterUpdateSlacks,
} from "../../cache";
import {
  FLOAT_MANAGER_GET_SHIFTS,
  AVAILABILITY_TYPES,
  EMPLOYEE_AVAILABILITY,
  FIND_SKILL_TYPE,
  GET_OFFICE_SCHEDULE_STATUS,
  UPDATE_SCHEDULE_PERIOD,
  EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
} from "../../api/gqlQueries";
import StaffRequirementForm from "./StaffRequirementForm";

const useStyles = makeStyles(() => ({
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    marginTop: -7,
  },
  tabs: {
    minWidth: 100,
    width: 125,
  },
  downloadPopover: {
    textAlign: "left",
  },
}));

function EmpCalendar({
  notifyDevelopers,
  environment,
  setOpenSnackBar,
  setSnackBarMessage,
}) {
  const classes = useStyles();

  const user = userVar();

  const primaryUnit = user.office;
  const userRole = user.role;

  const managerAccess =
    userRole === Roles.MANAGER ||
    userRole === Roles.SCHEDULER ||
    userRole === Roles.LEAD ||
    userRole === Roles.ADMIN;

  // function to get the events between startDate and endDate to increase performance of calendar
  function getVariables(startDate, endDate) {
    let variables;
    //if not released for employees and logged in user has manager access
    variables = {
      officeId: parseInt(primaryUnit.id),
      //rangeStart: startDate.toISOString(),
      //rangeEnd: endDate.toISOString(),
    };
    return variables;
  }

  useQuery(AVAILABILITY_TYPES, {
    onCompleted(data) {
      let sortedTypes = [];
      const ordered = ["PTO", "UPTO", "TRAINING", "Medical", "Admin", "OTHER"];
      ordered.forEach((name) => {
        let match = data.availabilityTypes.find((type) => type.name === name);
        if (match) {
          match = { name: match.name, id: match.id };
          if (match.name === "TRAINING" || match.name === "OTHER") {
            match.name =
              match.name.slice(0, 1) + match.name.slice(1).toLowerCase();
            sortedTypes.push(match);
          } else {
            sortedTypes.push(match);
          }
        }
      });
      setSortedAvailabilityTypes(sortedTypes);
      const requestFilters = sortedTypes.map((type) => type.name);

      if (!managerAccess) {
        const all = [...filterList.allFilters, ...requestFilters];
        const requests = [...filterList.requestsFilters, ...requestFilters];
        filterListVar({
          ...filterList,
          allFilters: all,
          requestsFilters: requests,
        });
      }
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on AVAILABILITY_TYPES Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const [employeeAvailability, SetEmployeeAvailability] = useState();

  useQuery(EMPLOYEE_AVAILABILITY, {
    variables: {
      officeId: parseInt(primaryUnit.id),
    },
    onCompleted(data) {
      SetEmployeeAvailability(data.availability);
    },
  });

  useQuery(FIND_SKILL_TYPE, {
    onCompleted(data) {
      const allSkills = data.skills.filter(
        (skill) => skill.variety === "JOB_TYPE" || skill.variety === "TRAINING"
      );
      setJobTypes(allSkills);
      const jobTypeNames = jobTypes.map((skill) => skill.name);

      const all = [...filterList.allFilters, ...jobTypeNames, "All Job Type"];

      filterListVar({
        ...filterList,
        allFilters: all,
        jobTypeFilters: ["All Job Type", ...jobTypeNames],
      });
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on FIND_SKILL_TYPE Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  let today = new Date();
  let firstDayOfCurrentView = startOfWeek(today);
  let lastDayOfCurrentView = addDays(endOfWeek(today), 1);

  const variablesForInitialQuery = getVariables(
    firstDayOfCurrentView,
    lastDayOfCurrentView
  );

  const { loading, error, data, refetch, networkStatus } = useQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      variables: variablesForInitialQuery,
      onCompleted(d) {
        console.log(d);
        const fourmonthsago = subMonths(firstDayOfCurrentView, 4);
        const fourMonthsFromNow = addMonths(lastDayOfCurrentView, 4);
        const futureVariables = getVariables(
          lastDayOfCurrentView,
          fourMonthsFromNow
        );
        const pastVariables = getVariables(
          fourmonthsago,
          firstDayOfCurrentView
        );
        // getFutureShifts({
        //   variables: {
        //     office: parseInt(primaryUnit.id),
        //     rangeStart: lastDayOfCurrentView,
        //     rangeEnd: fourMonthsFromNow,
        //   },
        // });
        // getPastShifts({
        //   variables: {
        //     office: parseInt(primaryUnit.id),
        //     rangeStart: fourmonthsago,
        //     rangeEnd: firstDayOfCurrentView,
        //   },
        // });
      },
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on SQL_SHIFT_ASSIGNMENTS Query. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  //get shifts before first day of current view
  const [getFutureShifts, { data: futureData }] = useLazyQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_MORE_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  //get shifts after last day of current view
  const [getPastShifts, { data: pastData }] = useLazyQuery(
    EFFICIENT_SQL_SHIFT_ASSIGNMENTS,
    {
      onError(error) {
        console.log(error);
        setOpenSnackBar(true);
        setSnackBarMessage(
          "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
        );
        notifyDevelopers({
          variables: {
            message:
              "Error on GET_MORE_SHIFTS lazyQuery. Environment: " +
              environment +
              ". Graphql " +
              error,
          },
        });
      },
    }
  );

  useEffect(() => {
    let allShifts = [];
    let shiftNames = [];
    let uniqueEmployees = [];

    data &&
      data.sqlShiftAssignments2.map((shft) => {
        console.log(shft);
        const {
          id: id,
          start,
          end,
          description,
          shiftassignmentSet,
          officeName,
        } = shft;

        let matchingShiftName = shiftNames.find(
          (e) => e.description && e.description === description
        );
        if (!matchingShiftName) {
          shiftNames.push({
            description: description,
            title:
              format(new Date(start), "HH:mm") +
              "-" +
              format(new Date(end), "HH:mm"),
          });
        }
        const match = allShifts.find((shift) => id === shift.eventId);
        if (!match) {
          allShifts.push({
            eventId: id,
            start: new Date(start),
            end: new Date(end),
            participants: shiftassignmentSet,
            eventTitle: description,
            employeeIds: shiftassignmentSet.map((e) => e.employeeId),
            shiftId: description,
            officeId: officeName,
          });
        }
      });

    const individualShifts = createIndividualShifts(allShifts);
    allShifts = [...allShifts, ...individualShifts];
    const userShifts = allShifts.filter(
      (shift) => shift.employeeIds && shift.employeeIds.includes(user.id)
    );
    setScheduleData({
      formatted: allShifts,
      userShifts,
      shiftNames,
    });
    addEmployeeData(uniqueEmployees);
  }, [data]);
  const [editShiftSnackBar, SetEditShiftSnackBar] = useState(false);

  const [updateSchedulePeriod] = useMutation(UPDATE_SCHEDULE_PERIOD, {
    onCompleted(d) {
      console.log(d);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't save your changes and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on UPDATE_SCHEDULE_PERIOD Mutation. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  const officeScheduleStatusvar = getOfficeScheduleStatus();

  const shiftToEditvar = setShiftIDToEdit();

  const schedulePeriodToUpdateVar = schedulePeriodToUpdateAfterUpdateSlacks();

  const {
    data: floatScheduleStatus,
    startPolling,
    stopPolling,
  } = useQuery(GET_OFFICE_SCHEDULE_STATUS, {
    variables: {
      id: parseInt(user.office.id),
    },
    onCompleted() {
      setTimeout(() => {}, 2000);
    },
    onError(error) {
      console.log(error);
      setOpenSnackBar(true);
      setSnackBarMessage(
        "We couldn't retrieve some data on this screen and are working hard to fix the error. Please refresh to try again."
      );
      notifyDevelopers({
        variables: {
          message:
            "Error on GET_OFFICE_SCHEDULE_STATUS Query. Environment: " +
            environment +
            ". Graphql " +
            error,
        },
      });
    },
  });

  useEffect(() => {
    startPolling(1000);
    if (
      floatScheduleStatus.offices[0].schedulingStatus != "READING" &&
      floatScheduleStatus.offices[0].schedulingStatus != "ERROR" &&
      officeScheduleStatusvar.officeId != "" &&
      schedulePeriodToUpdateVar.schedulePeriodId != ""
    ) {
      updateSchedulePeriod({
        variables: {
          id: parseInt(schedulePeriodToUpdateVar.schedulePeriodId),
          input: { status: "PUBLISHED" },
        },
      });
      stopPolling();
      setShiftToEdit(shiftToEditvar);
      SetEditShiftSnackBar(true);
    }
  }, [floatScheduleStatus && floatScheduleStatus.offices[0].schedulingStatus]);

  useEffect(() => {
    stopPolling();
    return () => {
      stopPolling();
    };
  }, [stopPolling]);

  const selectedDate = selectedDateVar();

  const ref = React.useRef();
  const params = useParams();

  const filterList = useReactiveVar(filterListVar);
  const allFilters = filterList.allFilters;

  const [scheduleData, setScheduleData] = useState({
    formatted: [],
    userShifts: [],
    shiftNames: [],
  });
  const [sortedAvailabilityTypes, setSortedAvailabilityTypes] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [view, setView] = useState("ShiftTimeline");
  const [shiftSwitch, setShiftSwitch] = useState(false);
  const [callIn, setCallIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editShift, setEditShift] = useState(false);
  const [shiftToEdit, setShiftToEdit] = useState();
  const [softRequest, setSoftRequest] = useState(false);
  const [softRequestIdToEdit, setSoftRequestIdToEdit] = useState();
  const [editSoftRequest, setEditSoftRequest] = useState(false);
  const [timeOffRequestIdToEdit, setTimeOffRequestIdToEdit] = useState();
  const [timeOffRequestForm, setTimeOffRequestForm] = useState(false);
  const [staffRequirementForm, setStaffRequirementForm] = useState(false);
  const [editTimeOffRequest, setEditTimeOffRequest] = useState(false);
  const [openDownload, setopenDownload] = useState(null);
  const [toast, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [errorToast, setErrorToast] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const openD = Boolean(openDownload);
  const anchorId = openD ? "download-popover" : undefined;
  const [employeeResources, setEmployeeResources] = useState([]);
  const [addRequestPopoverAnchor, setAddRequestPopoverAnchor] = useState(null);
  const [showEditShiftToast, SetShowEditShiftToast] = useState(false);
  const addEmployeeData = (employees) => {
    const resources = employees.map((employee) => {
      if (parseInt(employee.id) === parseInt(user.id)) {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#F5E1DB",
          //skills: employee.skills.map((e) => e.name),
        };
      } else {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          color: "#DFE0E2",
          //skills: employee.skills.map((e) => e.name),
        };
      }
    });
    setEmployeeResources(resources);
  };

  const createIndividualShifts = (shifts) => {
    let individualShifts = [];
    shifts.forEach((shift) => {
      shift.participants.forEach((participant) => {
        individualShifts.push({
          eventId: shift.eventId,
          start: shift.start,
          end: shift.end,
          participant,
          eventTitle: `${participant.firstName} ${participant.lastName}`,
          shiftId: shift.shiftId,
          calendars: ["shift"],
          originalParticipants: [...shift.participants],
        });
      });
    });
    return individualShifts;
  };

  const toggleShiftSwitchForm = () => setShiftSwitch(!shiftSwitch);
  const toggleCallInForm = () => setCallIn(!callIn);
  const toggleEditShift = () => setEditShift(!editShift);
  const toggleSoftRequest = () => setSoftRequest(!softRequest);
  const toggleTimeOffForm = () => setTimeOffRequestForm(!timeOffRequestForm);
  const toggleStaffRequirementForm = () =>
    setStaffRequirementForm(!staffRequirementForm);
  const toggleEditTimeOffForm = () =>
    setEditTimeOffRequest(!editTimeOffRequest);
  const toggleEditSoftRequestForm = () => setEditSoftRequest(!editSoftRequest);

  const handleEditClick = (shift, e) => {
    setShiftToEdit(shift);
    toggleEditShift();
  };

  const toastMessage = () => {
    const toasts = {
      "Employee Shift Switch": "Shift switch request sent!",
      "Manager Shift Switch": "Shift switch successful! Employees notified.",
      "New Time Off Request": "Time off request sent for approval!",
      "Edit Time Off Request":
        "Time off request updated and sent for approval!",
      "Delete Time Off Request": "Time off request deleted!",
      "New Soft Request": "Soft request entered successfully!",
      "Edit Soft Request": "Soft request updated successfully!",
      "Delete Soft Request": "Soft request deleted!",
      "Call In": "Call in successful!",
      "Manager Edit Shift": "Shift updated and employees notified!",
      "Manager Approve Time Off Request": "Time off request approved!",
      "Manager Deny Time Off Request": "Time off request denied!",
      "Manager Approve Shift Switch": "Shift switch request approved!",
      "Manager Deny Shift Switch": "Shift switch request denied!",
    };
    return toasts[toast] ? toasts[toast] : "Success!";
  };

  const errorToastMessage = () => {
    const errorToasts = {
      "Non Eligible Shift Switch": "No eligible shifts to switch.",
      "Error Edit Shift": "Unable to edit shift. Please try again.",
      "Error Call In": "Unable to call in. Please try again.",
      "Error Approve Time Off":
        "Unable to approve time off request. Please try again.",
      "Error Deny Time Off":
        "Unable to deny time off request. Please try again.",
      "Error Approve Shift Switch":
        "Unable to approve shift switch request. Please try again.",
      "Error Deny Shift Switch":
        "Unable to deny shift switch request. Please try again.",
      "Error Delete Soft Request":
        "Unable to delete soft request. Please try again.",
      "Error Delete Time Off":
        "Unable to delete time off request. Please try again.",
    };
    return errorToasts[errorToast]
      ? errorToasts[errorToast]
      : "Error. Please try again.";
  };

  if (loading || networkStatus === NetworkStatus.refetch) {
    return <CircularProgress color="primary" />;
  } else if (error) {
    console.log(error);
    return (
      <Grid container direction="row" spacing={4} style={{ marginTop: 20 }}>
        <Grid item>
          <Scheduler
            events={[]}
            employeeResources={[]}
            shiftResources={[]}
            view={view}
            setView={setView}
            issueDates={[]}
            slackIssues={[]}
            handleEditClick={handleEditClick}
            ref={ref}
          />
        </Grid>
      </Grid>
    );
  } else {
    const events = scheduleData.formatted;
    const userEvents = events.filter(
      (event) => event.employeeIds && event.employeeIds.includes(user.id)
    );

    const allTimeOffRequests =
      employeeAvailability &&
      employeeAvailability.filter(
        (timeOff) =>
          timeOff.deniedText === null && timeOff.userCancelled === false
      );

    const allTimeOffEvents = formatTimeOff(allTimeOffRequests, user.id);

    const userTimeOff =
      allTimeOffEvents &&
      allTimeOffEvents.filter((event) => event.category === "personal");

    //const userSoftRequests = formatSoftRequests(data.softRequests, user.id);
    const shiftNames = scheduleData.shiftNames
      ? scheduleData.shiftNames.map((e) => e.description)
      : [];
    let shiftResources = scheduleData.shiftNames.map((name) => {
      return {
        id: name.description,
        name: name.description + " Time " + name.title,
      };
    });
    shiftResources = [
      ...shiftResources,
      // {
      //   id: "Requests",
      //   name: "Requests",
      // },
    ];

    let invalidDates = [];
    // userSoftRequests.forEach((request) => {
    //   invalidDates.push(request.start);
    // });

    userTimeOff &&
      userTimeOff.forEach((request) => {
        const arrayOfTimeOff = eachDayOfInterval({
          start: request.start,
          end: request.end,
        });
        invalidDates = [...invalidDates, ...arrayOfTimeOff];
      });

    invalidDates = invalidDates.map((date) => format(date, "MM/dd/yyyy"));

    const maxGreyout = primaryUnit.maxGreyoutRequests;
    const allowCallIns = primaryUnit.allowCallIns;
    const schedulePeriodWeeks = primaryUnit.scheduleDuration
      ? primaryUnit.scheduleDuration
      : 4;

    const eventsToView = () => {
      let eventsToView = [];
      let requests = [];
      if (allFilters) {
        if (allFilters.includes("All Shifts")) {
          eventsToView = [...events];
          if (allFilters.includes("All Requests")) {
            //requests = [...userSoftRequests, ...allTimeOffEvents];
            requests = [...allTimeOffEvents];
          } else {
            if (allFilters.includes("Soft Requests")) {
              // requests.push(userSoftRequests);
            }

            sortedAvailabilityTypes.forEach((type) => {
              if (filterList.requestsFilters.includes(type.name)) {
                let matchingTimeOffs = allTimeOffEvents.filter(
                  (timeOff) => timeOff.typeId === type.id
                );

                if (
                  allFilters.includes("Pending") &&
                  !allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => !timeOff.approvedby
                  );
                } else if (
                  !allFilters.includes("Pending") &&
                  allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => timeOff.approvedby
                  );
                }

                requests.push(matchingTimeOffs);
              }
            });

            requests = requests.flat();
          }
        } else if (allFilters.includes("Personal")) {
          eventsToView = [...userEvents];
          if (allFilters.includes("All Requests")) {
            // requests = [...userSoftRequests, ...userTimeOff];
          } else {
            if (allFilters.includes("Soft Requests")) {
              // requests.push(userSoftRequests);
            }

            sortedAvailabilityTypes.forEach((type) => {
              if (filterList.requestsFilters.includes(type.name)) {
                let matchingTimeOffs = userTimeOff.filter(
                  (timeOff) => timeOff.typeId === type.id
                );

                if (
                  allFilters.includes("Pending") &&
                  !allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => !timeOff.approvedby
                  );
                } else if (
                  !allFilters.includes("Pending") &&
                  allFilters.includes("Approved")
                ) {
                  matchingTimeOffs = matchingTimeOffs.filter(
                    (timeOff) => timeOff.approvedby
                  );
                }

                requests.push(matchingTimeOffs);
              }
            });

            requests = requests.flat();
          }
        }

        if (!allFilters.includes("All Shift Type")) {
          const filteredList = [];
          shiftNames &&
            shiftNames.forEach((shiftName) => {
              if (allFilters.includes(shiftName)) {
                const filtered = eventsToView.filter(
                  (event) => event.shiftId === shiftName
                );
                filteredList.push(filtered);
              }
            });
          console.log(filteredList);
          eventsToView = filteredList.flat();
        }

        if (!allFilters.includes("All Job Type")) {
          const filteredList = [];
          const jobTypeNames = filterList.jobTypeFilters
            ? filterList.jobTypeFilters
            : [];

          eventsToView.forEach((event) => {
            let participantsWithJobType = [];
            if (event.participants) {
              participantsWithJobType = event.participants.filter(
                (participant) =>
                  participant.skills?.find((skill) =>
                    jobTypeNames.includes(skill.name)
                  )
              );
            } else if (event.participant) {
              const hasSkill = event.participant.skills?.find((skill) =>
                jobTypeNames.includes(skill.name)
              );
              hasSkill && participantsWithJobType.push(event.participant);
            }
            console.log(participantsWithJobType);
            if (participantsWithJobType.length > 0) {
              filteredList.push({
                ...event,
                participants: participantsWithJobType,
              });
            }
          });

          eventsToView = filteredList;
        }
      }
      console.log(eventsToView);
      if (view === "ShiftTimeline") {
        console.log(eventsToView);
        eventsToView = eventsToView
          .filter(
            (event) => event.calendars && event.calendars.includes("shift")
          )
          .map((e) => e);
      } else {
        eventsToView = eventsToView.filter((event) => !event.calendars);
      }
      return [...eventsToView, ...requests];
    };

    const PrintIconClick = () => {
      ref.current.print();
    };

    const ExportToICS = () => {
      const scheduleObj =
        document.querySelector(".e-schedule").ej2_instances[0];
      scheduleObj.exportToICalendar();
    };

    // const ExportToExcel = () => {
    //   const scheduleObj =
    //     document.querySelector(".e-schedule").ej2_instances[0];
    //   let exportValues = {
    //     dataSource: events,
    //   };
    //   let a =
    //     allSchedules.length > 0 &&
    //     allSchedules.find(
    //       (x) =>
    //         new Date(x.start) <= new Date() && new Date(x.end) >= new Date()
    //     );
    //   scheduleObj.exportToExcel(events);
    // };

    const hideFilterBadge = Boolean(
      allFilters.includes("All Shifts") &&
        allFilters.includes("All Shift Type") &&
        allFilters.includes("All Requests")
    );

    const toggleEditRequest = (type, id) => {
      if (type === "softRequest") {
        // const matchingRequest = userSoftRequests.find(
        //   (request) => parseInt(request.eventId) === parseInt(id)
        // );
        //if (matchingRequest) {
        // setSoftRequestIdToEdit(matchingRequest.eventId);
        // setEditSoftRequest(true);
        //}
      } else {
        const matchingRequest = managerAccess
          ? allTimeOffEvents.find(
              (request) => parseInt(request.eventId) === parseInt(id)
            )
          : userTimeOff.find(
              (request) => parseInt(request.eventId) === parseInt(id)
            );
        if (matchingRequest) {
          setTimeOffRequestIdToEdit(matchingRequest.eventId);
          setEditTimeOffRequest(true);
        }
      }
    };

    const environment = process.env.NODE_ENV;
    let backend = "";
    if (environment === "development") {
      backend = "https://backendtest.balancescheduler.com";
    } else {
      backend = "https://backenddemo.balancescheduler.com";
    }
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headerSpacing}
        >
          <Grid item xs={4}>
            <Typography variant="h3">Schedule</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2} xs={8}>
            {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleStaffRequirementForm}
              >
                Add Staff Requirement
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleTimeOffForm}
                style={{ width: 170 }}
              >
                Add Calendar Event
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleSoftRequest}
                style={{ width: 170 }}
              >
                Add Soft Request
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <div
          className="col-lg-12 property-section"
          style={{ marginBottom: 10 }}
        >
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                color="secondary"
                aria-describedby={anchorId}
                onClick={(event) => setopenDownload(event.currentTarget)}
              >
                <GetAppIcon style={{ marginRight: 5 }} /> Download
              </Button>
              <Popover
                id={anchorId}
                anchorEl={openDownload}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                open={openD}
                onClose={() => setopenDownload(null)}
                style={{ overflow: "auto" }}
              >
                {/* <MenuItem
                  color="secondary"
                  target="_blank"
                  filename="schedule.csv"
                  onClick={ExportToExcel}
                  className={classes.downloadPopover}
                  style={{ marginBottom: -8 }}
                >
                  Export to CSV File
                </MenuItem> 
                <br /> */}
                <MenuItem
                  color="secondary"
                  target="_blank"
                  // href={`${backend}/scheduler/employee_calendar/${parseInt(
                  //   user.id
                  // )}`}
                  onClick={ExportToICS}
                  className={classes.downloadPopover}
                  style={{ marginTop: -8 }}
                >
                  Export to my Calendar
                </MenuItem>
              </Popover>
            </Grid>
            <Grid item>
              <Button id="print" color="secondary" onClick={PrintIconClick}>
                <PrintIcon style={{ marginRight: 5 }} /> Print
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Badge
                  variant="dot"
                  color="primary"
                  style={{ marginRight: 5 }}
                  invisible={hideFilterBadge}
                >
                  <TuneIcon />
                </Badge>
                Filter
              </Button>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                style={{ overflow: "auto" }}
              >
                <Paper style={{ padding: 10 }}>
                  <FilterMenu
                    jobTypes={jobTypes}
                    shiftNames={shiftNames}
                    availabilityTypes={sortedAvailabilityTypes}
                  />
                </Paper>
              </Popover>
            </Grid>
          </Grid>
        </div>
        <Scheduler
          events={eventsToView()}
          employeeResources={employeeResources}
          shiftResources={shiftResources}
          view={view}
          setView={setView}
          toggleEditRequest={toggleEditRequest}
          toggleCallInForm={toggleCallInForm}
          toggleShiftSwitchForm={toggleShiftSwitchForm}
          handleEditClick={handleEditClick}
          allowCallIns={allowCallIns}
          ref={ref}
          setOpenSnackBar={setOpenSnackBar}
          setSnackBarMessage={setSnackBarMessage}
          notifyDevelopers={notifyDevelopers}
          environment={environment}
        />
        <Dialog open={shiftSwitch} fullWidth maxWidth="sm">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
              position: "relative",
            }}
          >
            <ShiftSwitchRequestForm
              allEvents={events}
              userEvents={userEvents}
              closeDialog={toggleShiftSwitchForm}
              shiftNames={shiftNames}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={callIn} fullWidth maxWidth="xs">
          <DialogContent
            style={{
              padding: 30,
              overflowX: "hidden",
              height: 400,
              position: "relative",
            }}
          >
            <EmpCallInForm
              closeDialog={toggleCallInForm}
              userEvents={userEvents}
              setToast={setToast}
              setShowToast={setShowToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editShift} fullWidth maxWidth="md">
          <Grid
            container
            component={DialogContent}
            direction="column"
            wrap="nowrap"
            justifyContent="space-between"
            style={{
              padding: 30,
              overflowX: "hidden",
              overflowY: "auto",
              height: 675,
            }}
          >
            {shiftToEdit ? (
              <MangEditShift
                shiftEvent={shiftToEdit}
                closeDialog={toggleEditShift}
                setToast={setToast}
                setShowToast={setShowToast}
                setErrorToast={setErrorToast}
                setShowErrorToast={setShowErrorToast}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            ) : null}
          </Grid>
        </Dialog>
        <Dialog open={softRequest} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            <SoftTimeOff
              closeDialog={toggleSoftRequest}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              maxGreyout={maxGreyout}
              //softRequests={userSoftRequests}
              schedulePeriodWeeks={schedulePeriodWeeks}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editSoftRequest} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 30 }}>
            {softRequestIdToEdit && (
              <EditSoftTimeOff
                closeDialog={toggleEditSoftRequestForm}
                invalidDates={invalidDates}
                setToast={setToast}
                setShowToast={setShowToast}
                maxGreyout={maxGreyout}
                // softRequests={userSoftRequests}
                softRequestIdToEdit={softRequestIdToEdit}
                setSoftRequestIdToEdit={setSoftRequestIdToEdit}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={timeOffRequestForm} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
          >
            <TimeOffRequestForm
              closeDialog={toggleTimeOffForm}
              // scheduleEndDate={scheduleEndDate}
              invalidDates={invalidDates}
              setToast={setToast}
              setShowToast={setShowToast}
              employees={employeeResources}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={editTimeOffRequest} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, height: 650, position: "relative" }}
          >
            {timeOffRequestIdToEdit && (
              <EditTimeOffRequestForm
                closeDialog={toggleEditTimeOffForm}
                // userSoft={userSoftRequests}
                setToast={setToast}
                setShowToast={setShowToast}
                userTimeOff={userTimeOff}
                timeOffRequestIdToEdit={timeOffRequestIdToEdit}
                setTimeOffRequestIdToEdit={setTimeOffRequestIdToEdit}
                refetch={refetch}
                setOpenSnackBar={setOpenSnackBar}
                setSnackBarMessage={setSnackBarMessage}
                notifyDevelopers={notifyDevelopers}
                environment={environment}
              />
            )}
          </DialogContent>
        </Dialog>
        <Dialog open={staffRequirementForm} fullWidth maxWidth="md">
          <DialogContent
            style={{ padding: 30, height: 600, position: "relative" }}
          >
            <StaffRequirementForm
              closeDialog={toggleStaffRequirementForm}
              selectedDate={selectedDate}
              setToast={setToast}
              setShowToast={setShowToast}
              setErrorToast={setErrorToast}
              setShowErrorToast={setShowErrorToast}
              refetch={refetch}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              notifyDevelopers={notifyDevelopers}
              environment={environment}
            />
          </DialogContent>
        </Dialog>
        {/* <Dialog open={censusDataForm} fullWidth maxWidth="sm">
          <DialogContent
            style={{ padding: 30, position: "relative" }}
          >
            <CensusDataForm 
              closeDialog={toggleCensusDataForm} 
              selectedDate={selectedDate}
            />
          </DialogContent>
        </Dialog> */}

        <Popover
          id="add-request"
          anchorEl={addRequestPopoverAnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(addRequestPopoverAnchor)}
          onClose={() => setAddRequestPopoverAnchor(null)}
        >
          <Paper style={{ padding: 16 }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <Grid item>
                <Typography variant="h5">Add Request</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close"
                  color="secondary"
                  size="small"
                  onClick={() => setAddRequestPopoverAnchor(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    toggleSoftRequest();
                    setAddRequestPopoverAnchor(null);
                  }}
                  style={{ width: 150 }}
                >
                  Soft Request
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    toggleTimeOffForm();
                    setAddRequestPopoverAnchor(null);
                  }}
                  style={{ width: 150 }}
                >
                  Time Off Request
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
        {/* <Dialog open={showShiftSlackBySkill} maxWidth={false}>
          <DialogContent
            style={{ padding: 30, overflowX: "hidden", overflowY: "auto" }}
          >
            <ShiftSlackBySkill
              slacks={allSlacks}
              closeDialog={() => setShowShiftSlackBySkill(false)}
            />
          </DialogContent>
        </Dialog> */}
        <Snackbar
          open={showToast}
          autoHideDuration={3000}
          onClose={() => setShowToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowToast(false)}
            severity="success"
          >
            <Typography>{toastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={3000}
          onClose={() => setShowErrorToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => setShowErrorToast(false)}
            severity="error"
          >
            <Typography>{errorToastMessage()}</Typography>
          </MuiAlert>
        </Snackbar>

        <Snackbar
          open={editShiftSnackBar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            onClose={() => {
              setShiftIDToEdit();
              getOfficeScheduleStatus({ officeId: "" });
              SetEditShiftSnackBar(false);
              stopPolling();
            }}
            severity="success"
          >
            <Typography>
              Our algorithm has new options for you to edit shift based on your
              requirement.
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  toggleEditShift();
                  getOfficeScheduleStatus({ officeId: "" });
                  SetEditShiftSnackBar(false);
                  stopPolling();
                }}
                size="small"
              >
                Edit Shift and View Options
              </Button>
            </Typography>
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default EmpCalendar;
