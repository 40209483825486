import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const ErrorSnackBar = (props) => {
  console.log(props);
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={20000}
      onClose={props.close}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert onClose={props.close} severity="error">
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};
export default ErrorSnackBar;
