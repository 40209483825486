import React from 'react';
import { Link } from '@material-ui/core';

function Copyright() {
    return (
        <div style={{fontSize:'10px'}} align="center">
            <Link style={{color:'gray'}} href="https://www.dualitysys.com" target="blank">
                {'Copyright © '} Duality Systems{' '}{new Date().getFullYear()}      
            </Link>
        </div>
    );
}

export default Copyright;