import React from 'react';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    coloredText: {
        color: '#8CADE1'
    }
}));

const Summary = ({hiredForShift, values, moveToStep}) => {
    const classes = useStyles();

    const shiftValueNames = ['day', 'night', 'swing'];

    const shiftsLiked = [];
    const shiftsDisliked = [];
    shiftValueNames.forEach(valueName => {
        const capitalized = valueName.charAt(0).toUpperCase() + valueName.slice(1);
        if (values[valueName] > 0){
            shiftsLiked.push(capitalized);
        } else if (values[valueName] < 0){
            shiftsDisliked.push(capitalized);
        }
    });

    const daysOff = {'oneDay': 1, 'twoDays': 2, 'threeDays': 3, 'fourDays': 4};
    const daysOffValueNames = ['oneDay', 'twoDays', 'threeDays', 'fourDays'];

    const daysOffLiked = [];
    daysOffValueNames.forEach(valueName => {
        if (values[valueName] > 0){
            daysOffLiked.push(daysOff[valueName]);
        } 
    });
    const formatDaysOffForSentence = () => {
        switch(daysOffLiked.length) {
            case 1:
                return `${daysOffLiked[0]}`;
            case 2:
                return `${daysOffLiked[0]} or ${daysOffLiked[1]}`
            case 3:
                return `${daysOffLiked[0]}, ${daysOffLiked[1]} or ${daysOffLiked[2]}`
            case 4:
                return `${daysOffLiked[0]}, ${daysOffLiked[1]}, ${daysOffLiked[2]} or ${daysOffLiked[3]}`
        };
    }

    const dayValueNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const daysMostPrefer = [];
    const daysLeastPrefer = [];
    dayValueNames.forEach(valueName => {
        const capitalized = valueName.charAt(0).toUpperCase() + valueName.slice(1);
        if (values[valueName] > 0){
            daysMostPrefer.push(capitalized);
        } else if (values[valueName] < 0){
            daysLeastPrefer.push(capitalized);
        }
    });
    
    return ( 
        <Grid container item direction='column' spacing={2} wrap='wrap'>
            <Grid item>
                <Typography variant='h5'>Let&apos;s look at the information you have provided so far.</Typography>
            </Grid>
            {/* <Grid item>
                <Typography variant='h6'>Here are the preferences most important to you.</Typography>
            </Grid> */}
            <Grid item>
                <Typography variant='h4'>
                    Shift Type
                    <Button 
                        color='primary' 
                        onClick={() => moveToStep(hiredForShift === 'Any Shift' ? 'Shifts Like' : 'Shift Question')}
                    >
                        Edit
                    </Button>
                </Typography>
                {hiredForShift !== 'Any Shift'
                    ? <>
                        {values.workAdditionalShifts 
                            ? <>
                                <Typography>You like to work:</Typography>
                                <ul>
                                    {shiftsLiked.map(shiftType => (
                                        <li key={shiftType}>
                                            <Typography>{shiftType} Shift</Typography>
                                        </li>
                                    ))}
                                </ul>
                                {shiftsDisliked.length > 0 &&
                                    <>
                                        <Typography>You do not like to work:</Typography>
                                        <ul>
                                            {shiftsDisliked.map(shiftType => (
                                                <li key={shiftType}>
                                                    <Typography>{shiftType} Shift</Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                }
                            </>
                            : <Typography>You would not be willing to work a different shift than {hiredForShift}.</Typography>
                        }
                    </>
                    : <>
                        <Typography>You like to work:</Typography> 
                        <ul>
                            {shiftsLiked.map(shiftType => (
                                <li key={shiftType}>
                                    <Typography>{shiftType} Shift</Typography>
                                </li>
                            ))}
                        </ul>
                        {shiftsDisliked.length > 0 &&
                            <>
                                <Typography>You do not like to work:</Typography> 
                                <ul>
                                    {shiftsDisliked.map(shiftType => (
                                        <li key={shiftType}>
                                            <Typography>{shiftType} Shift</Typography>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        }
                    </>
                }
            </Grid>
            <Grid item>
                <Typography variant='h4'>
                    Days Off in a Row
                    <Button color='primary' onClick={() => moveToStep('Days Off')}>Edit</Button>
                </Typography>
                {daysOffLiked.length === 0
                    ? <Typography>You did not indicate how many days off you like to have</Typography>
                    : <Typography>You like to have {formatDaysOffForSentence()} days off in a row</Typography>
                }
            </Grid>
            <Grid item>
                <Typography variant='h4'>
                    Days Most Prefer to Work
                    <Button color='primary' onClick={() => moveToStep('Days Most Prefer')}>Edit</Button>
                </Typography>
                <Typography>The days you like to work are:</Typography> 
                <ul>
                    {daysMostPrefer.map(day => <li key={day + 'most'}><Typography>{day}</Typography></li>)}
                </ul>
            </Grid>
            <Grid item>
                <Typography variant='h4'>
                    Days Least Prefer to Work
                    <Button color='primary' onClick={() => moveToStep('Days Least Prefer')}>Edit</Button>
                </Typography>
                <Typography>The days you do not like to work are:</Typography> 
                <ul>
                    {daysLeastPrefer.map(day => <li key={day + 'least'}><Typography>{day}</Typography></li>)}
                </ul>
            </Grid>
            {values.overtime !== 0 &&
                <Grid item>
                    <Typography variant='h4'>
                        Overtime
                        <Button color='primary' onClick={() => moveToStep('Overtime')}>Edit</Button>
                    </Typography>
                    <Typography>You {values.overtime > 0 ? 'would' : 'would not'} be willing to pick up overtime</Typography> 
                </Grid>
            }
            <Grid item>
                <Typography variant='h5' className={classes.coloredText} style={{marginBottom: 15}}>
                    To make any changes, go back now.
                </Typography>
            </Grid>
        </Grid>
    );
}
 
export default Summary;
