import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Slider,
  TextField,
} from "@material-ui/core";
import { FieldArray } from "formik";

const useStyles = makeStyles(() => ({
  sliderBox: {
    maxWidth: 425,
    marginTop: 16,
  },
}));

const LocationPreferences = (props) => {
  const classes = useStyles();
  const { values, setFieldValue, setStatus, name } = props;
  return (
    <Grid container data-testid="locationPreferences">
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        xs={9}
        spacing={2}
      >
        <Grid item>
          <Typography style={{ marginBottom: 16, lineHeight: 1, fontSize: 18 }}>
            <b>Location Preferences</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>Which location would you prefer to work at?</Typography>
        </Grid>
        <FieldArray name="locationPreferences">
          {() => (
            <Grid
              item
              container
              justifyContent="space-between"
              className={classes.sliderBox}
            >
              {values.locationPreferences &&
                values.locationPreferences.length > 0 &&
                values.locationPreferences.map((e, index) => (
                  <>
                    <Grid item xs={2} style={{ marginTop: 4 }}>
                      <Typography>{e.office}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: 4 }}>
                      <TextField
                        id={`locationPreferences.${index}.value`}
                        name={`locationPreferences.${index}.value`}
                        variant="outlined"
                        value={(e.value * 100).toFixed(0)}
                        onChange={(event) => {
                          setFieldValue(
                            `locationPreferences.${index}.value`,
                            event.target.value / 100
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: 4 }}></Grid>
                    <Grid item xs={7}>
                      <Slider
                        id={`locationPreferences.${index}.value`}
                        name={`locationPreferences.${index}.value`}
                        value={e.value * 100}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="off"
                        step={1}
                        marks={[
                          {
                            value: 0,
                            label: 0,
                          },
                          {
                            value: 100,
                            label: "100%",
                          },
                        ]}
                        min={0}
                        max={100}
                        onChange={(event, value) => {
                          setFieldValue(
                            `locationPreferences.${index}.value`,
                            value / 100
                          );
                        }}
                      />
                    </Grid>
                  </>
                ))}
              {(values.locationPreferences &&
                values.locationPreferences.length > 0 &&
                values.locationPreferences
                  .map((e) => parseFloat(e.value))
                  .reduce((a, b) => a + b, 0) > 1) ||
              (values.locationPreferences &&
                values.locationPreferences.length > 0 &&
                values.locationPreferences
                  .map((e) => e.value)
                  .reduce((a, b) => a + b, 0) < 1) ? (
                <Typography variant="subtitle1" color="primary">
                  Make sure the values you enter are not more or less than 100%
                  for all locations combined
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
};

export default LocationPreferences;
